<template>
    <div class="platform">
        <div class="platform-header">
            <h1 class="platform-name">
                <component :is="icon" />
                {{ platformName }}
            </h1>
        </div>
        <div class="platform-body">
            <template v-if="platformId == 1 && tryAgain">
                <div>
                    <h3>Oops...</h3>
                    <p>
                        Did you forget to click "Got it" in the Facebook Login window?
                    </p>
                </div>
            </template>
            <template v-if="loading">
                <ts-loading-spinner />
            </template>
            <template v-else>
                <div class="platform-image">
                    <template v-if="linked && (type == 'potential' || hasService)">
                        <a :href="href" target="_blank">
                            <img width="78" height="78" class="connected-img" :src="picture" @error="handleBadImage">
                            <div class="connected">Connected</div>
                        </a>
                    </template>
                    <template v-else-if="tryAgain">
                        <img class="meta-popup" src="/images/meta_login_popup.png">
                        <template v-if="!connectHelpLink && canLink && !availableAccounts.length">
                            <div class="connect-button-wrapper">
                                <button class="connect-button" @click="link">
                                    <arrow-pointer /> Try Again
                                </button>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <img width="78" height="78" src="/images/profile_picture_icon.png">
                        <template v-if="!connectHelpLink && canLink && !availableAccounts.length">
                            <div class="connect-button-wrapper">
                                <button
                                    class="connect-button"
                                    :disabled="roleStore.viewMode || (!external && type != 'potential') && !userStore.hasWriteAccess({ permission: 'retailer-settings-content-destination-login(s)' })"
                                    @click="link"
                                >
                                    <arrow-pointer /> Connect
                                </button>
                            </div>
                        </template>
                        <template v-else-if="connectHelpLink">
                            <div class="connect-button-wrapper">
                                <a
                                    class="connect-button"
                                    :href="roleStore.viewMode || (!external && type != 'potential') && !userStore.hasWriteAccess({ permission: 'retailer-settings-content-destination-login(s)' }) ? '#!' : connectHelpLink"
                                    target="_blank"
                                >
                                    <arrow-pointer /> Connect
                                </a>
                            </div>
                        </template>
                        <template v-else-if="availableAccounts.length">
                            <div>
                                <label v-if="platformId == 4" for="availableAccounts" class="location-group-label">Location Group</label>
                                <filter-dropdown
                                    id="availableAccounts"
                                    option-key="external_id"
                                    :selected="selectedAccount"
                                    :multiple="false"
                                    :use-autoselect="false"
                                    :options="availableAccounts"
                                    @update:selected="event => selectAccount(event, 0)"
                                />
                            </div>
                            <template v-if="availableLocations.length">
                                <div>
                                    <label for="availableLocations" class="location-label">Location</label>
                                    <filter-dropdown
                                        id="availableLocations"
                                        option-key="external_id"
                                        :selected="selectedLocation"
                                        :multiple="false"
                                        :use-autoselect="false"
                                        :options="availableLocations"
                                        @update:selected="event => selectAccount(event, 1)"
                                    />
                                </div>
                            </template>
                        </template>
                    </template>
                </div>
                <div class="platform-message">
                    <template v-if="linked && (type == 'potential' || hasService)">
                        <div class="name">{{ name }}</div>
                        <template v-if="(!external && type != 'potential')">
                            <a v-if="!roleStore.viewMode && userStore.hasWriteAccess({ permission: 'retailer-settings-content-destination-login(s)' })" class="disconnect" href="#" @click.prevent="unlink">
                                Disconnect
                            </a>
                        </template>
                        <template v-else>
                            <a class="disconnect" href="#" @click.prevent="unlink">
                                Disconnect
                            </a>
                        </template>
                    </template>
                    <template v-else-if="type == 'dealership' && !hasService">
                        <p class="message">
                            Click
                            <a href="#" @click.prevent="roleStore.viewMode ? '#' : toggleNewPlatformModal">
                                Here
                            </a>
                            if you are interested in adding this platform as a service.
                        </p>
                    </template>
                    <template v-else>
                        <template v-if="platformId == 1 && !tryAgain">
                            <p class="message">
                                As an admin of your business page, you must login with your personal Facebook credentials. This will only be used to grant
                                access to select and connect your business page. You will not be asked to connect your personal account.
                                <a href="#" @click.prevent="openFbInfoModal">Learn More</a>
                            </p>
                        </template>
                        <template v-else-if="platformId == 3">
                            <p class="message">
                                Please ensure that your <b>Instagram&nbsp;Business&nbsp;Account</b> is connected to your Facebook Business page.
                            </p>
                        </template>
                    </template>
                </div>
            </template>
            <div v-if="!tryAgain" class="platform-footer">
                <template v-if="linked && (type == 'potential' || hasService) && !hasPause">
                    <div class="retailer-destination-logins">
                        <p>Service is logged in and connected.</p>
                    </div>
                </template>
                <template v-else-if="hasService && linked && hasPause">
                    <div class="retailer-destination-pause">
                        <span>Service is connected and paused. </span>
                        <span><circle-info-gray-icon class="info-icon-pause" :tool-tip="toolTipText" placement="top" /></span>
                    </div>
                </template>
                <template v-else-if="hasService && !hidePause && snoozed">
                    <div class="retailer-destination-pause">
                        <span>Service is disconnected and snoozed.</span>
                        <circle-info-gray-icon class="info-icon-pause" placement="top" :tool-tip="snoozeToolTipText" />
                    </div>
                </template>
                <template v-else-if="hasService || type == 'potential'">
                    <div class="retailer-destination-logins">
                        <p>Service is not currently connected.</p>
                    </div>
                </template>
                <template v-else>
                    <div class="retailer-destination-logins">
                        <p>Service is currently not active.</p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import ArrowPointer from '@/components/app/ArrowPointer.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import TsLoadingSpinner from '@/components/app/TsLoadingSpinner.vue';

import FacebookIcon from '@/components/icons/platforms/FacebookIcon.vue';
import TwitterIcon from '@/components/icons/platforms/TwitterIcon.vue';
import InstagramIcon from '@/components/icons/platforms/InstagramIcon.vue';
import GoogleIcon from '@/components/icons/platforms/GoogleIcon.vue';
import LinkedinIcon from '@/components/icons/platforms/LinkedinIcon.vue';

import { mapStores } from 'pinia';
import { liveConnectStore } from '@/stores/pinia.js';
import Platform from '@/platform.js';
import CircleInfoGrayIcon from '@/components/icons/CircleInfoGrayIcon.vue';
import { userStore, roleStore } from '@/stores/pinia';
import dayjs from 'dayjs';

export default {
    components: {
        CircleInfoGrayIcon,
        ArrowPointer,
        FilterDropdown,
        TsLoadingSpinner,
        FacebookIcon,
        TwitterIcon,
        InstagramIcon,
        GoogleIcon,
        LinkedinIcon,
    },
    props: {
        type: {
            type:     String,
            required: true,
        },
        platformId: {
            type:     Number,
            required: true,
        },
        platformName: {
            type:     String,
            required: true,
        },
        name: {
            type:     String,
            required: true,
        },
        linked: {
            type:     Boolean,
            required: true,
        },
        badImage: {
            type:     Boolean,
            required: true,
        },
        picture: {
            type:     String,
            required: true,
        },
        externalId: {
            type:     String,
            required: true,
        },
        username: {
            type:     String,
            required: true,
        },
        agency: {
            type:     Boolean,
            required: true,
        },
        hasService: {
            type:     Boolean,
            required: true,
        },
        snoozed: {
            type:     Boolean,
            required: true,
        },
        snoozedFrom: {
            type:     String,
            required: true,
        },
        snoozedTo: {
            type:     String,
            required: true,
        },
        pauses: {
            type:     Array,
            required: true,
        },
        dealershipId: {
            type:     Number,
            required: true,
        },
        availableAccounts: {
            type:     Array,
            required: true,
        },
        availableLocations: {
            type:     Array,
            required: true,
        },
        parentPlatform: {
            type:     Number,
            required: true,
        },
        canLink: {
            type:     Boolean,
            required: true,
        },
        href: {
            type:     String,
            required: true,
        },
        connectHelpLink: {
            type:     String,
            required: true,
        },
        icon: {
            type:     String,
            required: true,
        },
        hidePause: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        nonce: {
            type:     Number,
            required: false,
            default:  0,
        },
        external: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    data() {
        return {
            loading:          false,
            selectedAccount:  null,
            selectedLocation: null,
            tryAgain:         false,
        };
    },
    computed: {
        ...mapStores(liveConnectStore),
        ...mapStores(userStore, roleStore),
        hasPause() {
            if (this.pauses.length > 0) {
                if (this.pauses[0].type_id === 1) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        toolTipText() {
            if (this.userStore.isAdmin) {
                return `This destination has Randomized Posts paused until ${dayjs(this.pauses[0].unpause_at).format('M/D/YYYY')}. Go to Post Settings to manage your pause settings.`;
            } else {
                return `This destination has Randomized Posts paused until ${dayjs(this.pauses[0].unpause_at).format('M/D/YYYY')}.`;
            }
        },
        snoozeToolTipText() {
            if (this.userStore.isAdmin) {
                return `This destination is Snoozed until ${dayjs(this.snoozedTo).format('M/D/YYYY')}. Go to Central Command to manage your snooze settings.`;
            } else {
                return `This destination is Snoozed until ${dayjs(this.snoozedTo).format('M/D/YYYY')}.`;
            }
        },
        ...mapStores(liveConnectStore, userStore),
    },
    watch: {
        availableAccounts() {
            this.tryAgain = false;
            this.loading = false;
        },
        availableLocations() {
            this.loading = false;
        },
        externalId() {
            this.loading = false;
        },
        linked() {
            this.selectedAccount = null;
            this.selectedLocation = null;
        },
        nonce() {
            this.loading = false;
        },
    },
    methods: {
        handleBadImage() {
            this.$emit('update:badImage', true);
        },
        link() {
            if (this.platformId !== 1) {
                this.loading = true;
            } else {
                this.loading = true;

                setTimeout(() => {
                    this.loading = false;
                    this.tryAgain = this.availableAccounts.length <= 0;
                }, 10000);
            }
            this.$emit('link');
        },
        openFbInfoModal() {
            this.$emit('openFbInfoModal');
        },
        selectAccount(event, index) {
            if (event == this.selectedAccount && index == 0) {
                return;
            } else if (event == this.selectedLocation && index == 1) {
                return;
            }
            this.loading = true;
            if (index == 0) {
                this.selectedAccount = event;
            } else if (index == 1) {
                this.selectedLocation = event;
            }

            if (this.platformId == Platform.FACEBOOK) {
                let selectAccount = this.availableAccounts.find(aa => aa.external_id == event);

                if (selectAccount?.phone) {
                    this.liveConnectStore.businessPhone = selectAccount.phone;
                }
            }

            this.$emit('update:selectedAccount', {
                value:        event,
                handlerIndex: index,
            });
        },
        toggleNewPlatformModal() {
            this.$emit('toggleNewPlatformModal');
        },
        toggleSnoozeResumeModal() {
            this.$emit('toggleSnoozeResumeModal');
        },
        unlink() {
            this.loading = true;
            this.$emit('unlink');
        },

    },
};
</script>

<style lang="scss">
@media(max-width: 950px) {
    .platform {
        background-color: white;
        border-radius: 10px;
        border-style: solid;
        border-width: 2px;
        height: 360px;
        margin-top: 20px;
        position: relative;
        text-align: center;
        width: 365px;

        &.narrow {
            height: auto;
            width: 235px;

            .platform-header {
                left: 41px;
                width: 146px;
            }

            .platform-body {
                .platform-image {
                    .connect-button-wrapper {
                        .pulsing-arrow-container {}
                    }
                    .connected-img{}
                }
                .platform-message {
                    .message {
                        margin: 8px 8px 10px !important;
                        max-height: 130px;
                        overflow-y: auto;
                    }
                }
            }

            .platform-footer {
                display: none;
            }
        }

        &.linked {
            border-color: #34a853;

            .platform-header {
                border-color: #34a853;
            }
            .platform-body {
                .platform-image {
                    img {
                        border: 2px solid #0066ff;
                    }
                }
            }
            .platform-footer {
                border-top-color: #34a853;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .info-icon {
                display: flex;
                margin-left: 10px;
            }
        }

        &.unlinked {
            border-color: #d10000;

            .platform-header {
                border-color: #d10000;

                .generic-icon-container {
                    opacity: 0.25;
                }
            }
            .platform-body {
                .platform-message {
                    .message {
                        margin: 8px 0 10px;
                        max-height: 130px;
                        overflow-y: auto;
                    }
                }
            }
            .platform-footer {
              border-top-color: #d10000;
              display: flex;
              justify-content: center;
              align-items: center;
            }
        }

        &.unavailable {
            border-color: #85878f;
            border-style: dashed;

            .platform-header {
                border-color: #85878f;
                border-style: dashed;

                .generic-icon-container {
                    opacity: 0.25;
                }
            }
            .platform-body {
                .platform-message {
                    .message {
                        margin: 8px 75px 10px;
                        max-height: 130px;
                        overflow-y: auto;
                    }
                }
            }
            .platform-footer {
                border-top-color: #85878f;
                border-top-style: dashed;
            }
        }

        &.snoozed {
            border-color: #d10000;

            .platform-header {
                border-color: #d10000;
            }
            .platform-footer {
                border-top-color: #d10000;

                .info-icon {
                    display: flex;
                    margin-left: 10px;
                }
            }
        }

        .platform-header {
            background-color: white;
            border-radius: 10px;
            border-style: solid;
            border-width: 2px;
            height: 38px;
            left: 82px;
            position: relative;
            top: -24px;
            width: 198px;

            .generic-icon-container {
                display: inline-block;
                vertical-align: middle;
            }
            .platform-name {
                font-size: 16px;
                font-weight: bold;
                position: relative;
                top: -14px;
            }
        }
        .platform-body {
            h3 {
                margin: 5px 0px;
            }
            .platform-image {
                height: 125px;

                img {
                    border-radius: 100%;
                }
                .generic-icon-container {
                    display: inline-block;
                    vertical-align: middle;
                }
                select:first-of-type {
                    margin-top: 10px;
                }
                .meta-popup {
                    border-radius: 0;
                    padding: 5px 0px;
                    width: 180px;
                    height: 110px;
                }
                .connected {
                    color: #0066ff;
                    font-size: 16px;
                    font-weight: bold;
                    margin-top: 20px;
                    text-decoration: underline;
                    .platform-name {
                        font-size: 16px;
                        font-weight: bold;
                        position: relative;
                        top: -14px;
                    }
                }
                .connect-button-wrapper {
                    margin-top: 13px;

                    .connect-button {
                        position: relative;
                        background-color: white;
                        border-radius: 10px;
                        border: 2px solid #0066ff;
                        color: #0066ff;
                        display: inline-block;
                        font-size: 16px;
                        font-weight: bold;
                        height: 34px;
                        padding-top: 4px;
                        width: fit-content;
                        &:hover {
                            background-color: #0066ff;
                            color: white;
                            text-decoration: none;
                        }
                    }
                    .pulsing-arrow-container {
                        position: absolute;
                        left: 3px;
                    }
                }
            }
            .filter-dropdown {
                margin: 3px 20px;
                width: calc(100% - 40px);

                &:first-of-type {
                    margin-top: 5px;
                }

                .filter-dropdown-menu {
                    .menu-item {
                        display: inline-table;
                        text-align: left;
                        span {
                            height: 20px;
                            overflow: hidden;
                        }
                    }
                }
            }
            .platform-message {
                font-size: 12px;

                .name {
                    font-weight: bold;
                    margin-top: 15px;
                }
                .disconnect {
                    color: #85878f;
                    display: block;
                    font-size: 10px;
                    margin-top: 16px;
                    text-decoration: underline;
                }
            }
        }
        .platform-footer {
            position: absolute;
            border-top-style: solid;
            border-top-width: 2px;
            bottom: 10px;
            font-size: 14px;
            height: 40px;
            padding-top: 13px;
            width: 100%;

            .generic-icon-container {
                display: inline-block;
                position: absolute;
                right: 10px;
                vertical-align: top;

                svg {
                    circle {
                        fill: white;
                        stroke: #85878f;
                    }
                    path {
                        fill: #85878f;
                        stroke: none;
                    }
                }
            }
        }
    }
}
@media(min-width: 950px) {
    .platform {
        background-color: white;
        border-radius: 10px;
        border-style: solid;
        border-width: 2px;
        height: 370px;
        margin-top: 20px;
        position: relative;
        text-align: center;
        width: 365px;

      .retailer-destination-logins {
        display: flex;
        place-items: center;
        justify-items: center;
        justify-content: center;
        margin-top: 3%;
      }
      .retailer-destination-pause{
        display: flex;
        place-items: center;
        justify-items: center;
        justify-content: center;
        margin-top: 3%;
        z-index: 99999;

        .info-icon-pause{
          display: inline-block;
          max-width: 500px;
          margin-left: 7px;
          z-index: 99999;

        }
      }

        &.narrow {
            height: 336px;
            width: 190px;

            .platform-header {
                left: 42px;
                width: 146px;
            }

            .platform-body {
                .platform-image {
                    .connect-button-wrapper {
                        .pulsing-arrow-container {}
                    }
                    .connected-img{}
                }
                .platform-message {
                    .message {
                        margin: 8px 8px 10px !important;
                        max-height: 130px;
                        overflow-y: auto;
                    }
                }
            }

            .platform-footer {
                display: none;
            }
        }

        &.linked {
            border-color: #34a853;

            .platform-header {
                border-color: #34a853;
            }
            .platform-body {
                .platform-image {
                    img {
                        border: 2px solid #0066ff;
                    }
                }
            }
            .platform-footer {
                border-top-color: #34a853;
            }
            .info-icon {
                display: flex;
                margin-left: 10px;
            }
        }

        &.unlinked {
            border-color: #d10000;

            .platform-header {
                border-color: #d10000;

                .generic-icon-container {
                    opacity: 0.25;
                }
            }
            .platform-body {
                .platform-message {
                    .message {
                        margin: 8px 0 10px;
                        max-height: 130px;
                        overflow-y: auto;
                    }
                }
            }
            .platform-footer {
                border-top-color: #d10000;
            }
        }

        &.unavailable {
            border-color: #85878f;
            border-style: dashed;

            .platform-header {
                border-color: #85878f;
                border-style: dashed;

                .generic-icon-container {
                    opacity: 0.25;
                }
            }
            .platform-body {
                .platform-message {
                    .message {
                        margin: 8px 75px 10px;
                        max-height: 130px;
                        overflow-y: auto;
                    }
                }
            }
            .platform-footer {
                border-top-color: #85878f;
                border-top-style: dashed;
            }
        }

        &.snoozed {
            border-color: #fa960f;

            .platform-header {
                border-color: #fa960f;
            }
            .platform-footer {
                border-top-color: #fa960f;

                .info-icon {
                    display: flex;
                    margin-left: 10px;
                }
            }
        }

        .platform-header {
            background-color: white;
            border-radius: 10px;
            border-style: solid;
            border-width: 2px;
            height: 38px;
            left: 82px;
            position: relative;
            top: -24px;
            width: 198px;

            .generic-icon-container {
                display: inline-block;
                vertical-align: middle;
            }
            .platform-name {
                font-size: 16px;
                font-weight: bold;
                position: relative;
                top: -14px;
            }
        }
        .platform-body {
            h3 {
                margin: 5px 0px;
            }
            .platform-image {
                margin: 2rem;

                img {
                    border-radius: 100%;
                }
                select:first-of-type {
                    margin-top: 10px;
                }
                .meta-popup {
                    border-radius: 0;
                    padding: 5px 0px;
                    width: 180px;
                    height: 110px;
                }
                .connected {
                    color: #0066ff;
                    font-size: 16px;
                    font-weight: bold;
                    margin-top: 20px;
                    text-decoration: underline;
                }
                .connect-button-wrapper {
                    margin-top: 13px;

                    .connect-button {
                        position: relative;
                        background-color: white;
                        border-radius: 10px;
                        border: 2px solid #0066ff;
                        color: #0066ff;
                        display: inline-block;
                        font-size: 16px;
                        font-weight: bold;
                        height: 34px;
                        padding-top: 4px;
                        width: fit-content;
                        &:hover {
                            background-color: #0066ff;
                            color: white;
                            text-decoration: none;
                        }
                    }
                    .pulsing-arrow-container {
                        position: absolute;
                        left: 3px;
                    }
                }
            }
            .filter-dropdown {
                margin: 3px 20px;
                width: calc(100% - 40px);

                &:first-of-type {
                    margin-top: 5px;
                }

                .filter-dropdown-menu {
                    .menu-item {
                        display: inline-table;
                        text-align: left;
                        span {
                            height: 20px;
                            overflow: hidden;
                        }
                    }
                }
            }
            .platform-message {
                font-size: 12px;

                .name {
                    font-weight: bold;
                    margin-top: 15px;
                }
                .disconnect {
                    color: #85878f;
                    display: block;
                    font-size: 10px;
                    margin-top: 16px;
                    text-decoration: underline;
                }
            }
        }
        .platform-footer {
            position: absolute;
            border-top-style: solid;
            border-top-width: 2px;
            bottom: 0;
            font-size: 16px;
            height: 50px;
            width: 100%;

            .generic-icon-container {
                display: inline-block;
                position: absolute;
                right: 4px;
                vertical-align: top;

                svg {
                    circle {
                        fill: white;
                        stroke: #85878f;
                    }
                    path {
                        fill: #85878f;
                        stroke: none;
                    }
                }
            }
        }
    }
}
.tooltip-inner {
    max-width: 100% !important;
}
.location-group-label {
    margin-bottom: 0;
}

.location-label {
    margin-bottom: 0;
}

</style>

<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="my-profile-menu-icon-container"
    >
        <template #content>
            <svg :width="13" :height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.97121 8.90674H5.02879C2.25208 8.90674 0 11.1803 0 13.9842C0 14.545 0.450357 15 1.00576 15H11.9948C12.5502 15.0008 13 14.5467 13 13.9842C13 11.1803 10.7482 8.90674 7.97121 8.90674Z" :fill="fillColorTop"/>
                <path d="M10.2142 3.75022C10.2142 5.82134 8.55149 7.50044 6.49993 7.50044C4.44837 7.50044 2.78564 5.82163 2.78564 3.75022C2.78564 1.67881 4.44866 0 6.49993 0C8.55149 0 10.2142 1.6791 10.2142 3.75022Z" :fill="fillColorBottom"/>
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'MyProfileMenuIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
    props:      {
        fillColorTop: {
            type:     String,
            required: false,
            default:  '#85878F',
        },
        fillColorBottom: {
            type:     String,
            required: false,
            default:  '#DEE0E2',
        },
    },
};

</script>
<style lang='scss'>
.my-profile-menu-icon-container {}
</style>

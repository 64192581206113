export default {
    clearMultiSelect(state) {
        state.canMultiSelect = false;
        state.customValues = {
            dates:             null,
            products:          null,
            retailer_grouping: null,
            languages:         null,
        },
        state.multiSelectActive = false;
        state.selectedPostType = null;
        state.selectedStories = [];
        state.keepExistingValues = {
            dates:             false,
            products:          false,
            retailer_grouping: false,
            languages:         false,
        };
        state.selectedBucketToMoveTo = {text: 'Move Content'};
    },
    clearModalValues(state) {
        state.customValues = {
            dates:             null,
            products:          null,
            retailer_grouping: null,
            languages:         null,
        },
        state.keepExistingValues = {
            dates:             false,
            products:          false,
            retailer_grouping: false,
            languages:         false,
        };
        state.selectedBucketToMoveTo = {text: 'Move Content'};
    },
    /**
     *  Payload contains an object with the input names to be set on "Keep Existing" state
     *  Object 'key' is the type (dates, products, etc.) and 'value' is the value to be set (true or false)
     *  E.g., this.setKeepExistingValues({ dates: true, languages: true });
     *  This will show "Keep Existing" on dates and languages inputs.
     */
    setKeepExistingValues(state, payload) {
        for (const [type, value] of Object.entries(payload)) {
            state.keepExistingValues[type] = value;
        }
    },
    setIsCancelling(state, value) {
        state.isCancelling = value;
    },
    toggleMultiSelectActive(state, value) {
        state.multiSelectActive = value;
    },
    toggleCanMultiSelect(state, value) {
        state.canMultiSelect = value;
    },
    updateSelectedPostType(state, postType) {
        state.selectedPostType = postType;
    },
    updateStorySelected(state, { storyId, selectedStoryBucket }) {
        const foundStoryIndex = state.selectedStories.findIndex(story => story === storyId);
        if (foundStoryIndex >= 0) {
            state.selectedStories.splice(foundStoryIndex, 1);
        } else {
            const config = state.bucketsConfig[selectedStoryBucket] ?? false;
            if (state.selectedStories.length === config.maxStories) {
                return;
            } else {
                state.selectedStories.push(storyId);
            }
        }
    },
    updateCustomValues(state, {type, value}) {
        state.customValues[type] = value;
    },
    deleteStory(state, storyId) {
        state.selectedStories = state.selectedStories.filter(story => story != storyId);
    },
    updateSelectedBucketToMoveTo(state, option) {
        state.selectedBucketToMoveTo = option;
    },
    toggleSaveConfirmation(state, {currentBucket, filledCompletelyStories = 0}) {
        const incompleteStories = state.selectedStories.length - filledCompletelyStories;
        const readyForApprovalText = filledCompletelyStories > 0 ?
            `${incompleteStories > 0 ? 'and' : '' } ${filledCompletelyStories} ${filledCompletelyStories > 1 ?
                'pieces of content have been moved' : 'piece of content has been moved'}
            from ${currentBucket} to Ready For Approval` : '';
        state.saveConfirmationText = `
            Your edits have been saved.
            ${ ( incompleteStories > 0 ?  (filledCompletelyStories > 0 ? incompleteStories : state.selectedStories.length) +
            ( incompleteStories > 1 ? ' pieces of content have been moved ' : ' piece of content has been moved ') +
             'from ' + currentBucket + ' to ' + state.selectedBucketToMoveTo.text + ' ' : '' ) + readyForApprovalText }
        `;
        state.showSaveConfirmation = true;
        setTimeout(() => {
            state.showSaveConfirmation = false;
        }, 5000);
    },
    toggleShowBucketChangeAlert(state, value) {
        state.showBucketChangeAlert = value;
    },
};

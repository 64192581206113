<template>
    <div :class="{'btn-group': buttonStyle, 'toggle-switch-container': true}">
        <template v-if="buttonStyle">
            <label class="btn" :class="toggleOnClass" :style="customButtonStyleOn && customButtonStyleOff && on ? customButtonStyleOn : customButtonStyleOff">
                <input v-model="on" type="radio" :name="id" :value="true" :disabled="disabled" @change="emitInput">
                <span v-if="activateSwitchLabel" v-html="onText" />
            </label>
            <label class="btn" :class="toggleOffClass" :style="customButtonStyleOn && customButtonStyleOff && !on ? customButtonStyleOn : customButtonStyleOff">
                <input v-model="on" type="radio" :name="id" :value="false" :disabled="disabled" @change="emitInput">
                <span v-if="activateSwitchLabel" v-html="offText" />
            </label>
        </template>
        <template v-else-if="slideStyle">
            <div class="slide-wrapper" :style="slideWrapperStyle" :class="{on, disabled }" @click="slide">
                <span
                    v-if="activateSwitchLabel"
                    :style="slideWrapperStyleText"
                    class="toggle-switch-text-new-design"
                    v-html="on ? onText : offText"
                />
                <div class="slide-switch" :style="slideSwitchStyle" />
            </div>
        </template>
        <template v-else-if="checkboxStyle">
            <label class="toggle-switch-checkbox-container">
                <div v-if="hasArrow && !hideArrow" class="arrow-wrapper" :style="{'margin-top': '-34px','margin-left': '8px'}">
                    <arrow-pointer :pointing-to="arrowDirection" :is-animated="isAnimated" />
                </div>
                <input
                    type="checkbox"
                    :name="id"
                    :disabled="disabled"
                    :checked="modelValue"
                    :style="hidden"
                    @click="emitInput"
                >
                <span
                    class="toggle-switch-checkbox-input"
                    :class="{
                        'toggle-switch-checkbox-input-disabled': disabled,
                        'toggle-switch-without-check-disabled': disabledWithoutCheck ? true : false,
                        'toggle-switch-checkbox-input-safari': browserName === 'safari' ? true : false
                    }"
                    :style="{ 'background' : (on && !enabled ? '#7b7b7b' : on == true ? '#0066FF' : 'none' ) }"
                />
                <slot name="label" />
            </label>
        </template>
    </div>
</template>

<script>
import ArrowPointer from '@/components/app/ArrowPointer.vue';

import BrowserName from '@/browser_name.js';
export default {
    components: {
        ArrowPointer,
    },
    props: {
        modelValue: {
            default: false,
            type:    Boolean,
        },
        onText: {
            default: 'Yes',
            type:    String,
        },
        offText: {
            default: 'No',
            type:    String,
        },
        enabled: {
            default: true,
        },
        reverse: {
            default: false,
        },
        usePrimaryClassForOff: {
            default: false,
        },
        useWarningClassForOff: {
            default: false,
        },
        switchStyle: {
            type:     String,
            required: false,
            default:  'buttons',
        },
        // These are for the 'slide' style
        diameter: {
            type:     Number,
            required: false,
            default:  16,
        },
        length: {
            type:     Number,
            required: false,
            default:  30,
        },
        bgcolor: {
            type:     String,
            required: false,
            default:  '#5cb85b',
        },
        arrowDirection: {
            type:     String,
            default:  'right',
            required: false,
        },
        isAnimated: {
            type:     Boolean,
            default:  true,
            required: false,
        },
        hasArrow: {
            type:     Boolean,
            default:  false,
            required: false,
        },
        hidden: {
            type:     Object,
            default:  () => {},
            required: false,
        },
        disabledWithoutCheck: {
            type:     Boolean,
            default:  false,
            required: false,
        },
        customButtonStyleOn: {
            type:     String,
            default:  '',
            required: false,
        },
        customButtonStyleOff: {
            type:     String,
            default:  '',
            required: false,
        },
        hideArrow: {
            type:    Boolean,
            default: false,
        },
        activateSwitchLabel: {
            type:    Boolean,
            default: false,
        },
        hasTsDesign: {
            type:    Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            browserName: '',
            id:          'id-' + ('' + Math.random()).substr(2),
            on:          this.reverse ? !this.modelValue : this.modelValue,
        };
    },
    computed: {
        buttonStyle() {
            return this.switchStyle == 'buttons';
        },
        disabled() {
            return (this.enabled === false || this.enabled === 'false' || this.disabledWithoutCheck === true);
        },
        slideStyle() {
            return this.switchStyle == 'slide';
        },
        checkboxStyle() {
            return this.switchStyle == 'checkbox';
        },
        toggleOnClass() {
            return (this.disabled ? 'disabled ' : '') + (this.on ? 'btn-primary' : 'btn-default');
        },
        toggleOffClass() {
            let onClass;

            if (this.usePrimaryClassForOff) {
                onClass = 'btn-primary';
            } else if (this.useWarningClassForOff) {
                onClass = 'btn-warning';
            } else {
                onClass = 'btn-secondary';
            }
            return (this.disabled ? 'disabled ' : '') + (this.on ? 'btn-default' : onClass);
        },
        slideWrapperStyle() {
            if (this.hasTsDesign) {
                return;
            }
            return {
                'border-radius':    Math.ceil((this.diameter + 4) / 2) + 'px',
                'height':           (this.diameter + 4) + 'px',
                'width':            this.length + 'px',
                'background-color': this.disabled ? '#7b7b7b' : (this.on ? this.bgcolor : '#cfd1d3'),

            };
        },
        slideWrapperStyleText() {
            if (this.on) {
                return {
                    position: 'absolute',
                    left:     '6px',
                };
            } else {
                return {
                    position: 'absolute',
                    left:     '18px',
                };
            }
        },
        slideSwitchStyle() {
            if (this.hasTsDesign) {
                return {
                    height: this.diameter + 'px',
                    width:  this.diameter + 'px',
                    left:   this.on ? ((this.length - this.diameter - 3) + 'px') : '3px',
                };
            }
            return {
                'box-shadow': `0 ${this.diameter/4}px 4px rgba(0, 0, 0, 0.25)`,
                'height':     this.diameter + 'px',
                'width':      this.diameter + 'px',
                'left':       this.on ? ((this.length - this.diameter - 1) + 'px') : '1px',
            };
        },
    },
    watch: {
        modelValue() {
            this.on = this.reverse ? !this.modelValue : this.modelValue;
        },
    },
    mounted() {
        if (this.usePrimaryClassForOff && this.useWarningClassForOff) {
            console && console.error && console.error('Can only set one Off class.');
        }

        this.browserName = BrowserName;
    },
    methods: {
        slide() {
            if (this.disabled) {
                return;
            }

            this.on = !this.on;
            this.emitInput();
        },
        emitInput() {
            this.$emit('update:modelValue', this.reverse ? !this.on : this.on)
        }
    },
};
</script>

<style lang="scss">
.toggle-switch-container{
    &.btn-group {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.btn-group input[type=radio] {
        position: fixed;
        opacity: 0;
    }

    .btn {
        width: auto;
    }

    .slide-wrapper {
        position: relative;
    }

    .slide-wrapper .slide-switch {
        background-color: white;
        border-radius: 100%;
        position: absolute;
        transition: left 0.1s;
        top: 2px;
        z-index: 1;
    }

    .slide-wrapper.disabled .slide-switch {
        background-color: #eee;
    }
    input[type=checkbox] {
        opacity: 0;
    }
    .toggle-switch-checkbox-input {
        border: 2px solid #0066FF;
        border-radius: 3px;
        height: 19px;
        width: 19px;
        position: absolute;
        margin-top: 4px;
        margin-left: -13px;
    }
    .toggle-switch-checkbox-input-disabled {
        border: 2px solid #7b7b7b;
    }
    .toggle-switch-without-check-disabled {
        border: 2px solid #7b7b7b;
        opacity: 0.3;
    }
    .toggle-switch-checkbox-input:after {
        content: "";
        position: absolute;
        display: none;
    }
    .toggle-switch-checkbox-input:after {
        content: "";
        position: absolute;
        display: none;
    }
    .toggle-switch-checkbox-container input:checked ~ .toggle-switch-checkbox-input:after {
        display: block;
    }
    .toggle-switch-checkbox-container .toggle-switch-checkbox-input:after {
        left: 5px;
        bottom: 3px;
        width: 6px;
        height: 11px;
        border: solid white;
        border-width: 0 3px 3px 0px;
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        border-radius: 1px;
    }
}

</style>

// External entry point

import App from '@/components/App.vue';
import 'core-js/stable';
import { createPinia } from 'pinia';
import 'regenerator-runtime/runtime';
import { createApp } from 'vue';
import { createStore } from 'vuex';
import './bootstrap';
import './global-stuff';
import './jwplayer-loader';
import HistoryPlugin from './stores/historyPlugin.js';
import store from './stores/store';

const app = createApp();

const vuex = createStore(store);
const pinia = createPinia();
pinia.use(HistoryPlugin);

app.use(vuex);
app.use(pinia);

app.component('App', App);

import PageNotFound from '@/components/app/404Pages/PageNotFound.vue';
import GeneralError from '@/components/app/GeneralError.vue';
import LocalizeWrapper from '@/components/app/LocalizeWrapper.vue';
import LiveConnect from '@/components/connects/LiveConnect.vue';
import InvoicingSummary from '@/components/invoicing/InvoicingSummary.vue';
import DestinationLogins from "@/components/platforms/DestinationLogins.vue";
import RetailerProductTiers from "@/components/retailer_experience/settings/RetailerProductTiers.vue";

app.component('GeneralError', GeneralError);
app.component('InvoicingSummary', InvoicingSummary);
app.component('LiveConnect', LiveConnect);
app.component('LocalizeWrapper', LocalizeWrapper);
app.component('PageNotFound', PageNotFound);
app.component('DestinationLogins', DestinationLogins);
app.component('RetailerProductTiers', RetailerProductTiers);

app.mount('#app');

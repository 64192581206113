<template>
    <div style="display: flex; flex-direction: column">
        <div class="panel panel-default summary-panel">
            <div class="panel-heading flex-row" style="font-weight:bold;">
                <div class="flex-item">Total Unpaid Invoices</div>
                <div class="flex-item" />
                <div class="flex-item" />
                <div class="flex-item text-right">
                    Brand: {{ invoiceAccountName }}
                </div>
            </div>

            <div class="panel-body">
                <div v-if="invoices && unpaidInvoices" class="flex-container">
                    <div class="flex-row header">
                        <div class="flex-item">Item</div>
                        <div class="flex-item" />
                        <div class="flex-item" />
                        <div class="flex-item text-right">Total</div>
                    </div>
                    <div class="horizontal-divider" />
                    <div class="flex-row">
                        <div v-if="unpaidInvoices.length < 1" class="flex-item" v-text="totalInvoicesDisplay" />
                        <template v-else>
                            <div class="flex-item">
                                <a href="javascript:void(0)" @click="changeTab" v-text="totalInvoicesDisplay" />
                            </div>
                            <div class="flex-item" />
                            <div class="flex-item" />
                            <div class="flex-item text-right"><i class="fa fa-dollar" />{{ sumInvoices }}</div>
                        </template>
                    </div>
                    <div class="horizontal-divider" />
                    <div v-if="sumInvoices" style="display: inline-block;" class="text-right"><span class="amount-due-text">Amount Due</span> <i class="fa fa-dollar" />{{ sumAmountDue }}</div>
                </div>
                <div v-else-if="invoices">
                    You currently have no unpaid invoices.
                </div>
                <div v-else>
                    <i class="fa fa-circle-o-notch fa-spin fa-fw" /> Loading invoices...
                </div>
            </div>
        </div>

        <div style="margin-top: 5px">
            <div v-if="showPayButton" class="pull-right">
                <ts-button :show-gradient-border="true" size="sm" style="margin: 0 10px 15px 0;" @click="payInvoices">
                    <template #buttonName>Pay Now</template>
                </ts-button>
            </div>
        </div>

        <template v-if="requiresCoOp || requiresPO">
            <div class="panel panel-default summary-panel">
                <div class="panel-heading flex-row" style="font-weight:bold;">
                    <div class="flex-item">Default Invoice Information</div>
                    <div class="flex-item" />
                    <div class="flex-item" />
                    <div class="flex-item text-right">
                        Brand: {{ invoiceAccountName }}
                    </div>
                </div>

                <div class="panel-body">
                    <div v-if="invoices && unpaidInvoices" class="flex-container">
                        <div class="flex-row header">
                            <div class="flex-item">Reference Numbers</div>
                            <div class="flex-item" />
                            <div class="flex-item" />
                            <div class="flex-item text-right">
                                <ts-button style="margin: 0 -5px 0 0;" size="sm" @click="showInvoiceSaveModal">
                                    <template #buttonName>{{ dataSavedText }}</template>
                                </ts-button>
                            </div>
                        </div>
                        <div class="horizontal-divider" />
                        <div>
                            <div v-if="requiresPO" class="flex-item">
                                P.O. Reference Number
                                <input v-model="localPONumber" type="text" class="po-number-input" @input="() => isSaved = false">
                                <div style="margin-top: 5px;" class="horizontal-divider" />
                            </div>
                            <div v-if="requiresCoOp" class="flex-item">
                                Co-Op Reference Number
                                <input v-model="localCoOpNumber" type="text" class="co-op-input" @input="() => isSaved = false">
                                <div style="margin-top:5px;" class="horizontal-divider" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script type="text/javascript">
import axios from '@/axios';
import TsButton from '@/components/app/TsButton.vue';
import SaveReferenceModal from '@/components/invoicing/SaveReferenceModal.vue';
import ShowModal from '@/mixins/ShowModal.js';
export default {
    components: {TsButton},
    mixins:     [
        ShowModal,
    ],
    props: {
        invoices: {
            type: Array,
            required: true,
        },
        oemManagerId: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            oemManager:      {},
            localPONumber:   null,
            localCoOpNumber: null,
            isSaved:         false,
        };
    },
    computed: {
        unpaidInvoices() {
            if (!this.invoices) {
                return [];
            }

            return this.invoices.filter(function (invoice) {
                return !invoice.paid_at;
            });
        },
        totalInvoicesDisplay() {
            if (this.unpaidInvoices.length === 0) {
                return 'You currently have no unpaid invoices.';
            }

            if (this.unpaidInvoices.length === 1) {
                return '1 Unpaid Invoice';
            }

            return this.unpaidInvoices.length + ' Unpaid Invoices';
        },
        sumInvoices() {
            if (this.unpaidInvoices.length === 0) {
                return 0;
            }

            var sum = 0;

            this.unpaidInvoices.forEach(function (invoice) {
                sum += invoice.total_amount_due;
            });

            return sum;
        },
        invoiceAccountName() {
            return this.unpaidInvoices[0]?.account_name;
        },
        requiresPO() {
            if (this.unpaidInvoices.length === 0) {
                return false;
            }

            return this.oemManager?.invoice_requires_po || false;
        },
        requiresCoOp() {
            if (this.unpaidInvoices.length === 0) {
                return false;
            }

            return this.oemManager?.co_op || false;
        },
        poInvoiceNumber() {
            if (this.unpaidInvoices.length === 0) {
                return '';
            }

            return this.oemManager?.invoice_po_number;
        },
        coOpInvoiceNumber() {
            if (this.unpaidInvoices.length === 0) {
                return '';
            }

            return this.oemManager?.co_op_number;
        },

        sumAmountDue() {
            return this.sumInvoices;
        },
        showPayButton() {
            return (this.unpaidInvoices.length > 0);
        },
        dataSavedText() {
            return (this.isSaved) ? 'Saved' : 'Save';
        },
    },
    watch: {
        poInvoiceNumber() {
            this.localPONumber = this.poInvoiceNumber;
        },
        coOpInvoiceNumber() {
            this.localCoOpNumber = this.coOpInvoiceNumber;
        },
    },
    mounted() {
        if (this.oemManagerId) {
            this.getOemManager();
        }
    },
    methods: {
        async getOemManager() {
            try {
                const oemManager = axios.get(`/api/oem-managers/${this.oemManagerId}/posting-defaults`).then(response => response.data);
                this.oemManager = await oemManager;
            } catch (error) {
                console.error('Error: ', error);
                window.flash.show({
                    type: 'error',
                    text: 'Unable to load Oem Manager.',
                });
            }
        },
        payInvoices() {
            this.$emit('pay-invoices', this.unpaidInvoices);
        },
        changeTab() {
            this.$emit('change-tab', 'Unpaid Invoices');
        },
        async handleSaveInvoiceInformation() {
            this.localPONumber = this.localPONumber === '' ? null : this.localPONumber;
            this.localCoOpNumber = this.localCoOpNumber === '' ? null : this.localCoOpNumber;

            axios.put(`/api/brands/${this.oemManagerId}/billing`, {oemManager: {invoice_po_number: this.localPONumber, co_op_number: this.localCoOpNumber}})
                .then(() => {
                    this.isSaved = true;
                })
                .catch(() => {
                    this.isSaved = false;
                });
        },
        async showInvoiceSaveModal() {
            const props = {
                title: 'Default',
            };

            return this.$showModal(SaveReferenceModal, props)
                .then((payload) => {
                    if (payload) {
                        this.handleSaveInvoiceInformation();
                    }
                })
                .then(() => this.isSaved = true);
        },
    },
};
</script>

<style lang="scss">
@media screen and (max-width: 992px) {
  .summary-panel {
    min-width: 580px;
  }
    .po-text {
        font-size: 12px;
        font-weight: bold;
        width: 100%;
    }

    .po-number-input {
        width: 24%;
    }

    .amount-due-text {
        font-weight: bold;
    }

    .td-border {
        border: 1px solid black;
        border-radius: 4px;
        padding: 3px;
        width: 48px
    }

    .flex-container {
        display: flex;
        flex-direction: column;
    }

    .flex-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .flex-row-invoice{
        display: flex;
        align-items: center;
    }

    .header .flex-item {
        font-weight: bold;
    }

    .horizontal-divider {
        width:100%;
        height: 1px;
        background: #E5E5E5;
        margin-bottom: 6px;
    }

    .btn-gradient-container .btn-gradient-inner-container .btn-wrapper {
        padding: 0 !important;
    }


    .btn-gradient-container {
        display: inline-block !important;
    }

    .btn-save {
        height: 41px;
        line-height: 14px;
        text-align: center;
        width: 100px;

        & + .tooltip {
            transform: translate(-80px 15px);
            white-space: nowrap;

            .tooltip-inner {
                max-width: 100%;
            }
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }
    }

}

@media print {
  a[href]:after {
    content: none !important;
  }
}

.summary-panel {
    .td-border {
        border: 1px solid black;
        border-radius: 4px;
        padding: 3px;
        width: 48px
    }

    .po-text {
        font-size: 10px;
        font-weight: bold;
        width: 100%;
        margin-left: 10px;
        margin-bottom: 3px;
    }

    .co-op-text {
        font-size: 10px;
        font-weight: bold;
        width: 100%;
    }

    .po-number-input {
        width: 24%;
        margin-left: 6px;
    }

    .co-op-input {
        width: 24%;
    }

    .amount-due-text {
        font-weight: bold;
    }


    .flex-container {
        display: flex;
        flex-direction: column;
    }

    .flex-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .flex-row-po-coop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    .flex-row-invoice{
        display: flex;
        flex-direction: row;
    }


    .header .flex-item {
        font-weight: bold;
    }


    .flex-item-end {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .horizontal-divider {
        border-bottom: 1px solid grey;
        width: 100%;
        margin-bottom: 6px;
    }
}
</style>

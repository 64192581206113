<template>
    <div class="export-wizard-container">
        <modal
            class="export-wizard-modal"
            :header="false"
            :footer="false"
            :class="{'modal-with-sidebar-overflowed': true}"
            :modal-close-scale="0.8"
            :show-gradient-icons="true"
            @close="close"
        >
            <template #fixed>
                <div v-if="currentRoute.selected[currentView] !== 'export_options' && currentRoute.selected[currentView] !== 'export_preview'" class="header">
                    <h4 class="header-text">Export Creator</h4>
                </div>
            </template>
            <template #body>
                <div v-if="isLoading" class="loading">
                    <ts-loading-spinner width="150" />
                </div>
                <div v-else-if="currentRoute.selected[currentView] !== 'export_options' && currentRoute.selected[currentView] !== 'export_preview'" class="parent-body-container">
                    <div
                        v-if="currentRoute.selected[currentView] == 'select_option' ||
                            currentRoute.selected[currentView] == 'select_brand' ||
                            currentRoute.selected[currentView] == 'select_date_range'"
                        class="parent-body-container-header" @click="handleStateMachine('previous')"
                    >
                        <previous-arrow class="previous-arrow-container" />
                    </div>
                    <div v-if="currentRoute.selected[currentView] == 'welcome'" class="welcome-inner-container">
                        <div v-if="userRole === 'brand'">
                            <export-brand
                                :button-select="buttonSelect"
                                :select-options="oemManagerOptions.filter(om => om.id)"
                                :selected-brands="selectedBrands"
                                :quick-future-dates="quickFutureDates"
                                :range="range"
                                :selected-quick-date-id="selectedQuickDateId"
                                @get-path="getPath($event)"
                                @selected-brands="handleSelectedBrands($event)"
                                @preview-click="handlePreview($event)"
                                @update:range="(event) => handleDateRangeChange(event)"
                                @update:selected-quick-date="(event) => handleSelectedQuickDate(event)"
                            />
                        </div>
                        <!-- Internal Admin Views                        -->
                        <div v-else>
                            <export-internal
                                :button-select="buttonSelect"
                                :select-options="oemManagerOptions.filter(om => om.id)"
                                :selected-brands="selectedBrands"
                                :quick-future-dates="quickFutureDates"
                                :range="range"
                                :selected-quick-date-id="selectedQuickDateId"
                                :oem-manager-options="oemManagerOptions"
                                @get-path="getPath($event)"
                                @selected-brands="handleSelectedBrands($event)"
                                @update:range="(event) => handleDateRangeChange(event)"
                                @update:selected-quick-date="(event) => handleSelectedQuickDate(event)"
                            />
                        </div>
                    </div>
                    <div v-if="currentRoute.selected[currentView] == 'done_view'" class="done-inner-container">
                        <export-done @close="close" />
                    </div>
                    <div
                        v-if="currentRoute.selected[currentView] == 'select_brand' ||
                            currentRoute.selected[currentView] == 'select_option' ||
                            currentRoute.selected[currentView] == 'select_date_range' " class="parent-body-container-footer"
                    >
                        <div class="hr" />
                        <div class="parent-body-footer-inner-container">
                            <button
                                :class="disableButton ? 'disabled' : ''" @click="handleStateMachine('next')"
                            >
                                {{ getButtonLabel }}
                            </button>
                            <arrow-pointer
                                v-if="!disableButton"
                                style="transform: translate(0px, -36px);" class="arrow-pointer-export-modal" :pointing-to="'right'" :is-animated="true"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="currentRoute.selected[currentView] == 'welcome'" class="welcome-next-button-container">
                    <arrow-pointer
                        v-if="showNextButtonArrow"
                        class="arrow-pointer-next"
                        :pointing-to="'right'"
                        :is-animated="true"
                    />
                    <ts-button
                        :disabled="nextDisabled(buttonSelect)"
                        :style="nextDisabled(buttonSelect) ? 'opacity: 0.5;' : ''"
                        @click="handleStateMachine('next')"
                    >
                        <template #buttonName>Next</template>
                    </ts-button>
                </div>
                <div
                    v-if="currentRoute.selected[currentView] == 'export_options' || currentRoute.selected[currentView] == 'export_preview' || welcomePreview"
                    class="export-options-container"
                >
                    <export-options-brand-c-c
                        v-if="buttonSelect == 0 && userRole !== 'retailer'"
                        :filters="brandFilters"
                        :brands="multipleBrandsSelection"
                        :oem-manager-id="oemManagerId"
                        :user-role="userRole"
                        :oem-manager-options="oemManagerOptions.filter(om => om.id)"
                        :sidebar-call="sidebarCall"
                        @next-view="handleStateMachine('next')"
                        @previous-view="handleStateMachine('previous')"
                    />
                    <export-options-retailer-c-c
                        v-else-if="buttonSelect == 1"
                        class="export-options-retailer-cc-container"
                        :filters="retailerFilters"
                        :brands="multipleBrandsSelection"
                        :user-role="userRole"
                        :oem-manager-id="oemManagerId"
                        :oem-manager-options="oemManagerOptions.filter(om => om.id)"
                        :sidebar-call="sidebarCall"
                        @next-view="handleStateMachine('next')"
                        @previous-view="handleStateMachine('previous')"
                    />
                    <export-options-retailer-total-c-c
                        v-else-if="buttonSelect == 2"
                        class="export-options-retailer-total-cc-container"
                        :range="range"
                        :filters="retailerTotalFilters"
                        :quick-future-dates="quickFutureDates"
                        :selected-quick-date-id="selectedQuickDateId"
                        :brands="multipleBrandsSelection"
                        :user-role="userRole"
                        :oem-manager-options="oemManagerOptions.filter(om => om.id)"
                        :oem-manager-id="oemManagerId"
                        :sidebar-call="sidebarCall"
                        :welcome-preview="welcomePreview"
                        @next-view="handleStateMachine('next')"
                        @previous-view="handleStateMachine('previous')"
                        @from-preview="handleStateMachine('from-preview')"
                        @update:range="(event) => handleDateRangeChange(event)"
                        @update:selected-quick-date="(event) => handleSelectedQuickDate(event)"
                    />
                    <export-options-individual-post-c-c
                        v-else-if="buttonSelect == 3 || userRole =='retailer'"
                        class="export-options-individual-post-cc"
                        :range="range"
                        :filters="individualPostFilters"
                        :quick-future-dates="quickFutureDates"
                        :selected-quick-date-id="selectedQuickDateId"
                        :brands="multipleBrandsSelection"
                        :user-role="userRole"
                        :oem-manager-options="oemManagerOptions.filter(om => om.id)"
                        :selected-route="buttonSelect"
                        :oem-manager-id="oemManagerId"
                        :dealership-id="dealershipId"
                        :sidebar-call="sidebarCall"
                        :welcome-preview="welcomePreview"
                        @next-view="handleStateMachine('next')"
                        @previous-view="handleStateMachine('previous')"
                        @from-preview="handleStateMachine('from-preview')"
                        @update:range="(event) => handleDateRangeChange(event)"
                        @update:selected-quick-date="(event) => handleSelectedQuickDate(event)"
                    />
                </div>
            </template>
            <template #footer />
        </modal>
    </div>
</template>

<script>
import axios from '@/axios';
import ArrowPointer from '@/components/app/ArrowPointer.vue';
import BrandManagerFilterDropdown from '@/components/app/BrandManagerFilterDropdown.vue';
import DateRange from '@/components/app/DateRange.vue';
import Modal from '@/components/app/Modal.vue';
import TsButton from '@/components/app/TsButton.vue';
import TsLoadingSpinner from '@/components/app/TsLoadingSpinner.vue';
import ExportBrand from '@/components/app/exports/ExportBrand.vue';
import ExportDone from '@/components/app/exports/ExportDone.vue';
import ExportInternal from '@/components/app/exports/ExportInternal.vue';
import ExportOptionsBrandCC from '@/components/app/exports/ExportOptionsBrandCC.vue';
import ExportOptionsIndividualPostCC from '@/components/app/exports/ExportOptionsIndividualPostCC.vue';
import ExportOptionsRetailerCC from '@/components/app/exports/ExportOptionsRetailerCC.vue';
import ExportOptionsRetailerTotalCC from '@/components/app/exports/ExportOptionsRetailerTotalCC.vue';
import PreviousArrow from '@/components/icons/PreviousArrow.vue';
import { userStore } from '@/stores/pinia.js';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { mapStores } from 'pinia';
dayjs.extend(localizedFormat);
dayjs.extend(quarterOfYear);


export default {
    components: {
        ExportInternal,
        ExportBrand,
        ExportDone,
        TsButton,
        ArrowPointer,
        BrandManagerFilterDropdown,
        DateRange,
        ExportOptionsBrandCC,
        ExportOptionsIndividualPostCC,
        ExportOptionsRetailerCC,
        ExportOptionsRetailerTotalCC,
        Modal,
        PreviousArrow,
        TsLoadingSpinner,
    },
    props: {
        oemManagerId: {
            type:     [String, Number],
            default:  '',
            required: false,
        },
        dealershipId: {
            type:     Number,
            required: false,
            default:  0,
        },
        modalOverflows: {
            type:    Boolean,
            default: false,
        },
        sidebarCall: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    data() {
        return {
            bodyFilters:          [],
            buttonSelect:         0,
            selectedBrands:       [],
            internalButtonSelect: null,
            routes:               {
                admin: {
                    brand:      ['welcome', 'export_options', 'export_preview', 'done_view'],
                    retailer:   ['welcome', 'export_options', 'export_preview', 'done_view'],
                    total:      ['welcome', 'export_options', 'export_preview', 'done_view'],
                    individual: ['welcome', 'export_options', 'export_preview', 'done_view'],
                },
                brand: {
                    brand:      ['welcome', 'export_options', 'export_preview', 'done_view'],
                    retailer:   ['welcome', 'export_options', 'export_preview', 'done_view'],
                    total:      ['welcome', 'export_options', 'export_preview', 'done_view'],
                    individual: ['welcome', 'export_options', 'export_preview', 'done_view'],
                },
                retailer: {
                    individual: ['welcome', 'export_options', 'export_preview', 'done_view'],
                },
            },
            currentRoute:          null,
            previousView:          null,
            currentView:           0,
            nextView:              1,
            retailerFilters:       {},
            brandFilters:          {},
            brandFiltersBh:        {},
            retailerFiltersBh:     {},
            individualPostFilters: {},
            isLoading:             true,
            range:                 {start: '', end: ''},
            quickFutureDates:      [
                {
                    name:  'Custom Range',
                    start: null,
                    end:   null,
                    id:    0,
                },
                {
                    name:  'Today',
                    start: dayjs(),
                    end:   dayjs(),
                    id:    1,
                },
                {
                    name:  'Month-to-Date',
                    start: dayjs().startOf('month'),
                    end:   dayjs(),
                    id:    2,
                },
                {
                    name:  'Last 30 Days',
                    start: dayjs().subtract(30, 'day'),
                    end:   dayjs(),
                    id:    3,
                },
                {
                    name:  'Last Month',
                    start: dayjs().subtract(1, 'month').startOf('month'),
                    end:   dayjs(),
                    id:    4,
                },
                {
                    name:  'Last Quarter',
                    start: dayjs().subtract(1, 'quarter').startOf('quarter'),
                    end:   dayjs().subtract(1, 'quarter').endOf('quarter'),
                    id:    5,
                },
                {
                    name:  'Year To Date',
                    start: dayjs().startOf('year'),
                    end:   dayjs(),
                    id:    6,
                },
                {
                    name: '',
                    id:   7,
                },

            ],
            selectedQuickDateId:  7,
            retailerTotalFilters: {},
            userRole:             '',
            oemManagerOptions:    '',
            disableButton:        false,
            exportType:           null,
            welcomePreview:       false,
        };
    },
    computed: {
        ...mapStores(userStore),
        getButtonLabel() {
            if (this.currentRoute.selected[this.currentView] == 'select_date_range') {
                return 'Create';
            } else if (this.currentRoute.selected[this.currentView] == 'select_brand'
            && this.currentRoute.selected[this.currentView + 1] !== 'select_date_range') {
                return 'Create';
            }
            return 'Next';
        },
        showNextButtonArrow() {
            if (this.buttonSelect === 0 || this.buttonSelect === 1) {
                return this.selectedBrands.length > 0;
            }
            return this.selectedBrands.length > 0 && (this.range.start && this.range.end);
        },
        multipleBrandsSelection() {
            if (this.userStore.isAdmin || this.userStore.isParentBrand || this.userStore.isBrand) {
                return this.selectedBrands;
            }
            return [this.oemManagerId];
        },
    },
    beforeMount() {
        this.getRoute();
    },
    mounted() {
        this.isLoading = true;
        this.getOemManagerOptions();
        this.loadFilters();
    },
    methods: {
        async loadFilters() {
            if (this.userStore.isAdmin || this.userStore.isBrand || this.userStore.isParentBrand) {

                await axios.get('/api/reports/?report=retailer')
                    .then((response) => {
                        this.retailerFilters = response.data.options;
                    });
                if (this.userRole == 'brand') {
                    await axios.get('/api/reports/?report=brand-health')
                        .then((response) => {
                            this.brandFilters = response.data.options;
                        });
                } else {
                    await axios.get('/api/reports/?report=brand')
                        .then((response) => {
                            this.brandFilters = response.data.options;
                        });
                }
                await axios.get('/api/reports/?report=retailer-total')
                    .then((response) => {
                        this.retailerTotalFilters = response.data.options;
                    });
            }
            await axios.get('/api/reports/?report=individual-post')
                .then((response) => {
                    this.individualPostFilters = response.data.options;
                    this.isLoading = false;
                });
        },
        async getOemManagerOptions () {
            await axios({
                method: 'get',
                url:    '/api/oem-managers',
            }).then((response) => {
                if (this.userStore.isParentBrand || this.userStore.isBrand) {
                    this.oemManagerOptions = response.data.filter(oem => oem.is_parent !== true);
                } else {
                    this.oemManagerOptions = response.data;
                }
            });
        },
        close() {
            this.$emit('close');
        },
        getRoute() {
            this.userRole = this.userStore.roleTypeVerbose === 'parent-brand' ? 'brand' : this.userStore.roleTypeVerbose;
            this.currentRoute = this.routes[this.userRole];
            this.getPath();
        },
        getPath(path) {
            if (!path && path !== 0) {
                this.currentRoute.selected = Object.values(this.currentRoute)[this.buttonSelect];
            } else {
                this.currentRoute.selected = Object.values(this.currentRoute)[path];
                this.buttonSelect = path;
                this.internalButtonSelect = path;
            }
        },
        handlePreview(type) {
            if (type === 'brand/retailer') {
                this.currentView = 1;
                this.previousView = 0;
                this.buttonSelect = 2;
                this.welcomePreview = true;
            } else if (type === 'contentData') {
                this.currentView = 1;
                this.previousView = 0;
                this.buttonSelect = 3;
                this.welcomePreview = true;
            }
        },
        handleStateMachine(state) {
            if (state == 'next') {
                this.currentView += 1;
                this.nextView += 1;
                this.previousView = this.currentView == 1 ? 0 : this.previousView + 1;
                this.validateDisabled();
            } else if (state == 'from-preview') {
                this.currentView = 0;
                this.buttonSelect = 0;
                this.nextView = 1;
                this.previousView = 0;
                this.welcomePreview = false;
                this.internalButtonSelect = null;
            } else {
                this.currentView -= 1;
                this.nextView -= 1;
                this.previousView = this.currentView == 0 ? 0 : this.currentView -1;
                this.internalButtonSelect = null;
                this.selectedBrands = [];
                this.validateDisabled();
            }
        },
        handleSelectedBrands(event) {
            if (event.value === 'all') {
                if (this.selectedBrands.length === this.oemManagerOptions.length) {
                    this.selectedBrands = [];
                } else {
                    this.selectedBrands = this.oemManagerOptions.map(option => option.id);
                }
            } else {
                this.selectedBrands = event.value;
            }
            this.validateDisabled();
        },
        handleDateRangeChange(range) {
            this.range.start = dayjs(range.start).format('YYYY-MM-DD');
            this.range.end = dayjs(range.end).format('YYYY-MM-DD');
            this.validateDisabled();
        },
        handleSelectedQuickDate(event) {
            this.selectedQuickDateId = event.id;
            this.validateDisabled();
        },
        validateDisabled() {
            if (this.selectedBrands.length == 0 && this.currentRoute.selected[this.currentView] == 'select_brand') {
                this.disableButton = true;
            } else if (this.selectedQuickDateId == 7 && this.currentRoute.selected[this.currentView] == 'select_date_range') {
                this.disableButton = true;
            } else {
                this.disableButton = false;
            }
        },
        nextDisabled(buttonSelect) {
            if (buttonSelect === 0 || buttonSelect === 1) {
                return this.selectedBrands.length === 0;
            }
            return this.selectedBrands.length === 0 || (this.range.start === '' || this.range.end === '');
        },
    },
};
</script>

<style lang="scss">
@import '../../../../sass/variables.scss';
.export-wizard-container {
    .loading {
        width: 100%;
        height: 100%;
    }
    #closeModalButton{
        top: 7px !important;
        right: 25px;
    }
    .modal-container{
        margin-top: 30px !important;
    }
    .export-wizard-modal {
        .modal-content {
            background-color: #F0F3F6;
            width: 101%;
            .header {
                display: flex;
                justify-content: flex-start;
                .header-text {
                    margin: 0;
                    padding: 3rem 1.5rem;
                    border-radius: 0px 0px 4px 4px;
                    font-size: 18px;
                    height: 35px;
                    font-weight: 700;
                }
            }
            .modal-body {
                display: flex;
                flex-direction: column;
                justify-content: center;
                overflow-y: unset;
                max-height: fit-content;
                .parent-body-container {
                    display: flex;
                    background-color: #FFFFFF;
                    border-radius: 4px;
                    //height: 550px;
                    flex-direction: column;
                    align-items: center;
                    width: 98%;
                    .parent-body-container-header {
                        position: absolute;
                        left: 40px;
                        top: 40px;
                        .previous-arrow-container {
                            cursor: pointer;
                        }
                    }
                    .parent-body-container-footer {
                        display: flex;
                        flex-direction: column;
                        width: 85%;
                        align-items: flex-end;
                        margin-top: auto;
                        margin-bottom: 3rem;
                        .hr {
                            border: 2px solid #F0F3F6;
                            margin-top: 2rem;
                            margin-bottom: 3rem;
                            width: 100%;
                        }
                        .parent-body-footer-inner-container {
                            button {
                                background: #0066FF;
                                border: 2px solid #0066FF;
                                border-radius: 4px;
                                color: #fff;
                                padding: 10px 40px;
                                font-weight: 700;
                                font-size: 20px;
                            }
                        }
                    }
                    .welcome-inner-container {
                        padding: 40px;
                        .body-container-title {
                            display: flex;
                            justify-content: center;
                            margin-bottom: 50px;
                            color: #000;

                            font-family: "Open Sans";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 140%; /* 25.2px */
                        }
                        .body-container-footer {
                            margin-top: 4em;
                            button {
                                border: 2px solid #0066FF;
                                background: #0066FF;
                                border-radius: 4px;
                                padding: 8px 40px;
                                color: #fff;
                                font-weight: 500;
                                font-size: 20px;
                            }
                        }
                    }
                    .select-inner-container {
                        padding: 4em 0;
                        .select-container-title {
                            font-family: arial;
                            h2 {
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 36.6px;
                            }
                        }
                        .select-container-content {
                            margin-top: 3rem;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            span {
                                color: #85878F;
                                font-size: 15px;
                                opacity: 0.6;
                                font-weight: 400;
                            }
                            button {
                                padding: 5px 20px;
                                border: 1px solid #85878F;
                                border-radius: 15px;
                                background: #fff;
                                margin-top: 6px;
                            }
                            .active {
                                background: #0066FF;
                                color: #fff;
                                border: 1px solid #0066FF;
                            }
                            .select-container-content-top {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                            }
                            .select-container-content-bottom {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                margin-top: 2rem;
                            }
                        }
                        .select-container-footer {
                            display: flex;
                            justify-content: flex-end;
                            button {
                                margin-bottom: 2rem;
                            }
                        }
                    }
                    .brand-select-inner-container {
                        padding: 4em 0;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .brand-select-container-content {
                            margin-top: 2rem;
                            .brand-manager-filter-dropdown {
                                .filter-dropdown {
                                    width: 100% !important;
                                    .btn-combobox {
                                        width: 250px;
                                        font-size: 18px;
                                        height: 40px;
                                    }
                                }
                            }
                            .pulsing-arrow-container {
                                display: none;
                            }
                        }
                    }
                    .date-range-inner-container {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        .date-range-component-container {
                            margin-top: 2rem;
                            background: #fff;
                            border: 2px solid #D6D9DB;
                            border-radius: 5px;
                            padding: 5px;
                            position: relative;
                            .date-range-container {
                                top: 0px;
                                left: 0px;
                                margin-top: 55px;
                                z-index: 10;
                            }
                            .input-group {
                                height: 100%;
                                display: flex;
                                .purple-caret-down {
                                    margin-right: 10px;
                                }
                            }
                            .placeholder-label {
                                margin-left: 0px;
                            }
                            .form-control {
                                background: #0166FF;
                                border-radius: 6px !important;
                                border: none;
                                color: #eee;
                                font-size: 16px;
                                font-weight: bold;
                                height: 40px;
                                padding: 7px 1ch;
                                text-align: center;
                                width: 133px;
                            }
                            @media only screen and (max-width: 1800px) {
                                .form-control, .placeholder-label {
                                    font-size: 14px;
                                    width: 40%;
                                }
                            }
                            .form-control:focus {
                                box-shadow: none;
                            }
                            .form-control::placeholder {
                                color: #fff;
                            }
                            .reverse-input .selected-regions {
                                justify-content: space-between;
                            }
                            .row-right-container .date-input-container{
                                padding: 3px 5px 4px 5px;
                            }
                            .purple-caret-down {
                                margin-right: 10px;
                            }
                            .row-right-container .modal-date-picker-input-container{
                                border-radius: 6px;
                                padding: 4px 0px;
                            }
                            .row-right-container .modal-date-picker-input{
                                height: 2.8rem;
                            }
                            .placeholder-label {
                                color: #000;
                                font-size: 16px;
                                padding-left: 12px;
                                margin-right: auto;
                                white-space: nowrap;
                                height: 40px;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                }
                .welcome-next-button-container {
                    align-self: end;
                    margin-right: 30px;
                    .pulsing-arrow-container{
                        position: absolute;
                        bottom: 50px;
                    }
                }
                .export-options-container {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    .exports-modal-container {
                        width: 100%;
                    }
                    .export-options-retailer-total-cc-container {
                        .modal-body-container {
                            .body-container {
                                .body-row-container {
                                    .body-group-container {
                                        .body-group {
                                            .body-items-main-container {
                                                .body-rows-container {
                                                    display: grid;
                                                    grid-template-columns: repeat(3, 1fr); /* This creates 4 columns */
                                                    grid-template-rows: repeat(4, auto); /* This creates 3 rows */
                                                    .body-item-container {
                                                       // width: 16.666%;
                                                        .body-row-filter-item {
                                                            margin-block-end: 0.5rem;
                                                            label {
                                                               margin-bottom: 0;
                                                            }
                                                            .label-modal {
                                                                .toggle-switch-container {
                                                                    .toggle-switch-checkbox-container {
                                                                        .toggle-switch-checkbox-input {
                                                                            margin-top: 0;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .export-options-retailer-cc-container {
                        .modal-body-container {
                            .body-container {
                                .body-row-container {
                                    .body-group-container {
                                        .body-group {
                                            .body-items-main-container {
                                                .body-rows-container {
                                                    flex-wrap: wrap;
                                                    width: 100%;
                                                    .body-item-container {
                                                        .body-row-filter-item {
                                                            margin-block-end: 0.5rem;
                                                            label {
                                                               margin-bottom: 0;
                                                            }
                                                            .label-modal {
                                                                .toggle-switch-container {
                                                                    .toggle-switch-checkbox-container {
                                                                        .toggle-switch-checkbox-input {
                                                                            margin-top: 0;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .export-options-retailer-total-bh-container {
                        .modal-body-container {
                            .body-container {
                                .body-row-container {
                                    .body-group-container {
                                        .body-group {
                                            .body-items-main-container {
                                                .body-rows-container {
                                                    flex-wrap: wrap;
                                                    width: 100%;
                                                    .body-item-container {
                                                        .body-row-filter-item {
                                                            margin-block-end: 0.5rem;
                                                            label {
                                                               margin-bottom: 0;
                                                            }
                                                            .label-modal {
                                                                .toggle-switch-container {
                                                                    .toggle-switch-checkbox-container {
                                                                        .toggle-switch-checkbox-input {
                                                                            margin-top: 0;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .export-options-individual-post-bh-container {
                        .modal-body-container {
                            .body-container {
                                .body-row-container {
                                    .body-group-container {
                                        .body-group {
                                            .body-items-main-container {
                                                .body-rows-container {
                                                    flex-wrap: wrap;
                                                    width: 100%;
                                                    .body-item-container {
                                                        width: 20%;
                                                        .body-row-filter-item {
                                                            margin-block-end: 0.5rem;
                                                            label {
                                                               margin-bottom: 0;
                                                            }
                                                            .label-modal {
                                                                .toggle-switch-container {
                                                                    .toggle-switch-checkbox-container {
                                                                        .toggle-switch-checkbox-input {
                                                                            margin-top: 0;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .export-options-individual-post-cc {
                        .modal-body-container {
                            .body-container {
                                .body-row-container {
                                    .body-group-container {
                                        .body-group {
                                            .body-items-main-container {
                                                .body-rows-container {
                                                    display: grid;
                                                    grid-template-columns: repeat(4, 1fr); /* This creates 4 columns */
                                                    grid-auto-rows: auto; /* This makes the rows size based on their content */

                                                    .body-rows-container .body-item-container:nth-child(-n+8) {
                                                        grid-column: span 1; /* First 8 items span 1 column */
                                                    }

                                                    .body-rows-container .body-item-container:nth-child(n+9):nth-child(-n+11) {
                                                        grid-column: span 2; /* Next 3 items span 2 columns */
                                                    }

                                                    .body-rows-container .body-item-container:nth-child(n+12) {
                                                        grid-column: span 4; /* Remaining items span 4 columns */
                                                    }
                                                    .body-item-container {
                                                        .body-row-filter-item {
                                                            margin-block-end: 0.5rem;
                                                            label {
                                                               margin-bottom: 0;
                                                            }
                                                            .label-modal {
                                                                .toggle-switch-container {
                                                                    .toggle-switch-checkbox-container {
                                                                        .toggle-switch-checkbox-input {
                                                                            margin-top: 0;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .modal-content {
                        background-color: #F0F3F6;
                        width: 900px;
                        .modal-body {
                            padding: 0;
                            max-height: fit-content;
                            .info-header {
                                display: flex;
                                justify-content: center;
                                .info-header-text {
                                    margin: 0;
                                    padding: 1rem 2rem;
                                    background-color: #FFFFFF;
                                    border-radius: 0px 0px 4px 4px;
                                    font-size: 14px;
                                    height: 35px;
                                }
                            }
                        }
                        .export-options-brand-container {
                            .title-container {
                                padding: 25px 0;
                            }
                            .modal-body-container {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .modal-with-sidebar-overflowed {
        .modal-wrapper {
            display: block;
            margin: 0px;
            height: inherit;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
        /* Hide scrollbar for Chrome, Safari and Opera */
        .modal-wrapper::-webkit-scrollbar {
            display: none;
        }
        .modal-lg {
            max-height: initial;
            margin: 30px auto;
        }
    }
}
</style>

<template>
    <div class="page-not-found-3-container">
        <div class="page">
            <div class="page-container">
                <img class="cry-emoji" src="/images/cry_emoji.svg">
                <div class="container">
                    <div class="header">Error: 404</div>
                    <div class="subheader">
                        Oh no, I think you might be lost!
                    </div>
                    <div class="content">
                        The page you are looking for cannot be found.<br>
                        Click below to go to your Content dashboard.
                    </div>
                    <button
                        type="button" class="dashboard-button"
                        @click="userStore.goToDashboard({ payload: { activeSession }})"
                    >
                        <span class="button-text">{{ buttonText }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { userStore } from '@/stores/pinia.js';
import { mapStores } from 'pinia';

export default {
    name:  'PageNotFound',
    props: {
        brandId: {
            type: Number,
            required: true,
        },
        activeSession: {
            type: Boolean,
            required: true,
        },
        buttonText: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapStores(userStore),
    },
    mounted() {
        this.userStore.setUser({ payload: { ...this.user, selectedDealership: this.dealership }});
    },
};
</script>

<style scoped>
.page-not-found-3-container {
    .page {
        background: linear-gradient(180.08deg, #3139FF -28.63%, #5000B8 66.25%);
        height: 100vh;
        position: absolute;
        width: 100%;
    }

    .page-container {
        position: absolute;
        display: flex;
        flex-direction: row;
        top: 35%;
        left: 20%;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content {
        position: absolute;
        top: 200px;

        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;

        text-align: center;

        color: #FFFFFF;
        font-family: 'Geomanist';
    }

    .cry-emoji {
        width: 300px;
        height: 300px;
        display: flex;
    }

    .header {
        position: absolute;
        font-style: normal;
        font-weight: 700;
        font-size: 68px;
        display: flex;
        color: #FFFFFF;
        font-family: 'Geomanist';
    }

    .subheader {
        position: absolute;
        top: 120px;

        font-weight: 500;
        font-size: 36px;
        line-height: 20px;
        /* identical to box height, or 56% */

        text-align: center;

        color: #FFFFFF;
        font-family: 'Geomanist';
    }

    .dashboard-button {
        position: absolute;
        height: 54px;
        top: 350px;
        padding-right: 20px;
        padding-left: 20px;

        background: #FFFFFF;
        border-radius: 7px;
        display: flex;
    }

    .button-text {
        font-size: 24px;
        font-weight: 500;
        color: #000000;
        padding-top: 5px;
        font-family: 'Geomanist';
    }

    .upper-bar {
        align-items: center;
        background: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        display: flex;
        position: fixed;
        width: 100%;
        z-index: 1;
    }

    .retailer-name {
        margin-left: 3rem;
        font-size: 14px;
        padding-top: 16px;
    }
}
</style>


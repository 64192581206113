import state from '@/stores/role/state.js';
import getters from '@/stores/role/getters.js';
import actions from '@/stores/role/actions.js';
import { defineStore } from 'pinia';

export default defineStore({
    id: 'role',
    state,
    getters,
    actions,
});

/**
 * Created on 10/28/15.
 */
$(function () {
    $('.include.dealership.icon').on('click', function (event) {
        event.stopPropagation();
        $.ajax({
            url: '/dealerships/change_starred/' + $(this).data('dealership-id'),
        });
    });

    // So, if one of the dumb chat divs change, we need to be able to see it.
    // Here's a wonky way to do that.
    $('.js-dom-watch').each(function () {
        var $element = $(this);
        if ($element.html().length == 0) {
            $element.parent().remove();
        }
    });

    $('label.stop-prop').on('click', function(event){
        event.stopPropagation();
    });

    //Global tooltip setup
    $('[data-toggle="tooltip"]').tooltip();
    $(document).on('shown.bs.modal', function () {
        $('[data-toggle="tooltip"]').tooltip();
    });
});

$(function() {
    function setPlaceholder(text)
    {
        $('#search_terms').attr('placeholder', text);
    }

    function setType(type)
    {
        $('#search_type').val(type);
    }

    function submitSearchIfReady()
    {
        if ($('#search_terms').val()) {
            submitSearch();
        }
    }

    function submitSearch()
    {
        $('#search_form').submit();
    }

    $('#search_menu_make_model').click(function()
    {
        setPlaceholder($('#search_menu_make_model').text());
        setType('makemodel');
        submitSearchIfReady();
    });
    $('#search_menu_stock_vin').click(function()
    {
        setPlaceholder($('#search_menu_stock_vin').text());
        setType('stockvin');
        submitSearchIfReady();
    });
    $('#search_menu_leads').click(function()
    {
        setPlaceholder($('#search_menu_leads').text());
        setType('leads');
        submitSearchIfReady();
    });

    setPlaceholder($('#search_menu_stock_vin').text());
    setType('stockvin');
});

$(function() {
    $('[data-toggle="tooltip"]').mouseleave(function() {
        $('[data-toggle="tooltip"]').tooltip('hide');
    });
});

<template>
    <div class="pause-note" :class="{'note-small': small, 'note-wide': wide}">
        <div class="date-range">
            {{ start }} - {{ end }}
        </div>
        <div class="message">
            {{ verb }} for {{ platform }} {{ delivery }} Posts
        </div>
        <div class="note-wrapper">
            <div class="note">
                {{ note }}
            </div>
            <div class="limit">
                {{ note.length }}/250
            </div>
        </div>
        <div class="created">
            <div class="user">
                Created By: {{ user }}
            </div>
            <div class="time">
                {{ created }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        note: {
            type:     String,
            required: true,
        },
        start: {
            type:     String,
            required: false,
            default:  '',
        },
        end: {
            type:     String,
            required: false,
            default:  '',
        },
        created: {
            type:     String,
            required: true,
        },
        user: {
            type:     String,
            required: true,
        },
        platform: {
            type:     String,
            required: false,
            default:  '',
        },
        delivery: {
            type:     String,
            required: false,
            default:  '',
        },
        verb: {
            type:     String,
            required: false,
            default:  '',
        },
        format: {
            type:     String,
            required: false,
            default:  'full',
        },
    },
    computed: {
        small() {
            return this.format == 'small';
        },
        wide() {
            return this.format == 'wide';
        },
    },
};
</script>

<style lang="scss">
.pause-note {
    background-color: white;
    border-radius: 4px;
    border: 1px solid #d6d9db;
    margin-bottom: 10px;
    min-height: 110px;
    padding: 7px 10px 55px 10px;
    position: relative;
    text-align: left;

    .date-range {
        background-color: #505050;
        border-radius: 4px;
        color: white;
        font-size: 10px;
        font-weight: 700;
        left: 10px;
        padding: 5px 10px;
        position: absolute;
        top: 7px;
        width: 130px;
    }

    .message {
        border-bottom: 1px solid #f0f3f6;
        font-size: 10px;
        font-style: italic;
        font-weight: 700;
        left: 150px;
        padding-bottom: 2px;
        position: absolute;
        top: 7px;
        width: calc(100% - 179px);
    }

    .note-wrapper {
        border-bottom: 1px solid #f0f3f6;
        min-height: 56px;
        left: 150px;
        position: relative;
        top: 30px;
        width: calc(100% - 179px);

        &::before {
            color: #adb0b3;
            content: 'Notes:';
            left: -50px;
            position: absolute;
            top: 2px;
        }

        .note {
            padding-bottom: 12px;
        }

        .limit {
            bottom: 3px;
            color: #b1b1b1;
            font-size: 10px;
            position: absolute;
            right: -20px;
        }
    }

    .created {
        bottom: 5px;
        color: #85878f;
        font-size: 10px;
        left: 150px;
        position: absolute;
        width: calc(100% - 179px);

        .time {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    &.note-wide {
        min-height: 87px;
        padding-bottom: 30px;

        .date-range {
            display: none;
        }
        .message {
            display: none;
        }

        .note-wrapper {
            left: 10px;
            top: 7px;
            width: calc(100% - 25px);

            &::before {
                display: none;
            }
            .limit {
                right: -5px;
            }
        }

        .created {
            left: 10px;
            width: calc( 100% - 25px);
        }
    }

    &.note-small {
        min-height: 87px;
        padding-bottom: 34px;
        width: 350px;

        .date-range {
            display: none;
        }
        .message {
            display: none;
        }

        .note-wrapper {
            left: 10px;
            top: 7px;
            width: calc(100% - 25px);

            &::before {
                display: none;
            }
            .note {
                &::before {
                    content: 'Pause Note:';
                    font-weight: 700;
                }
            }
            .limit {
                right: -5px;
            }
        }

        .created {
            left: 10px;
            width: calc( 100% - 25px);
        }
    }
}
</style>

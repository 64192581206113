<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="location-dot-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 5.63963C7.5 6.41802 6.82812 7.04953 6 7.04953C5.17188 7.04953 4.5 6.41802 4.5 5.63963C4.5 4.86125 5.17188 4.22974 6 4.22974C6.82812 4.22974 7.5 4.86125 7.5 5.63963Z" fill="#0066FF" />
                <path opacity="0.4" d="M5.25938 14.6629C3.62813 12.7772 0 8.20678 0 5.63959C0 2.52489 2.68625 0 6 0C9.3125 0 12 2.52489 12 5.63959C12 8.20678 8.34375 12.7772 6.74062 14.6629C6.35625 15.1124 5.64375 15.1124 5.25938 14.6629ZM6 7.98943C7.38125 7.98943 8.5 6.93788 8.5 5.63959C8.5 4.34131 7.38125 3.28976 6 3.28976C4.61875 3.28976 3.5 4.34131 3.5 5.63959C3.5 6.93788 4.61875 7.98943 6 7.98943Z" fill="#0066FF" />
            </svg>
        </template>
    </generic-icon>
</template>


<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'LocationDotIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>
<style lang='scss'>
.location-dot-icon-container {}
</style>

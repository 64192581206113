<template>
    <base-date-range
        :class-name="className"
        v-bind="$props"
        @update:range="($event) => $emit('update:range',$event)"
        @update:date="($event) => $emit('update:date', $event)"
        @update:selected-quick-date="(event) => $emit('update:selected-quick-date',event.id)"
    >
        <template #arrow>
            <div v-if="showArrow">
                <ArrowPointer class="arrow-pointer-brand" :pointing-to="arrowPointDirection" :is-animated="showArrowAnimation" />
            </div>
        </template>
        <template #divided-input>
            <date-range-icon-with-gradient :scale="1" />
        </template>
        <template #date-range-buttons="{ apply, cancel}">
            <ts-button color="secondary" @click="cancel">
                <template #buttonName>Cancel</template>
            </ts-button>
            <ts-button @click="apply">
                <template #buttonName>Submit</template>
            </ts-button>
        </template>
    </base-date-range>
</template>

<script>
import BaseDateRange from '@/components/app/BaseDateRange.vue';
import TsButton from '@/components/app/TsButton.vue';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);
dayjs.extend(quarterOfYear);
import DateRangeIconWithGradient from '@/components/icons/DateRangeIconWithGradient.vue';
import ArrowPointer from '@/components/app/ArrowPointer.vue';

export default {
    components: {
        ArrowPointer,
        BaseDateRange,
        TsButton,
        DateRangeIconWithGradient,
    },
    emits: [
        'update:range',
        'update:date',
        'update:selected-quick-date',
    ],
    props: {
        attrs: {
            type:     Array,
            default:  () => [],
            required: false,
        },
        minDate: {
            type:     [Date, Object, String],
            default:  null,
            required: false,
        },
        maxDate: {
            type:     [Date, Object, String],
            default:  null,
            required: false,
        },
        format: {
            type:     String,
            required: false,
            default:  'MM/DD/YYYY',
        },
        label: {
            type:     String,
            required: false,
            default:  '',
        },
        leftContainerTitle: {
            type:     String,
            required: false,
            default:  'Date',
        },
        quickFutureDates: {
            type:     Array,
            required: false,
            default:  () => [],
        },
        range: {
            type:     Object,
            default:  () => ({}),
            required: false,
        },
        openDropdown: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        placeholder: {
            type:     String,
            required: false,
            default:  'Select a Date',
        },
        singleInput: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        showAdjustDate: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        showCalendarIcon: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        showCaretDown: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        useDividedInput: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        showLabel: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        ignoreSelectedDateRangeWatch: {
            type:    Boolean,
            require: false,
            default: false,
        },
        clearInput: {
            type:    Boolean,
            require: false,
            default: false,
        },
        highlightSelectedQuickDate: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        selectedQuickDateId: {
            type:     Number,
            required: false,
            default:  2,
        },
        dateInputLabel: {
            type:     String,
            required: false,
            default:  'Adjust Date',
        },
        replaceDateWithLabel: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        disableCustomDateRange: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        quickDateLabel: {
            type:     String,
            required: false,
            default:  '',
        },
        onlyReadStartDate: {
            type:    Boolean,
            default: false,
        },
        disabled: {
            type:    Boolean,
            default: false,
        },
        blackoutDates: {
            type:    Array,
            default: () => [],
        },
        isRange: {
            type:     Boolean,
            default:  false,
            required: false,
        },
        value: {
            type:     [Date, Object, String],
            default:  null,
            required: false,
        },
        columns: {
            type:     Number,
            required: false,
            default:  2,
        },
        showQuickDateOptions: {
            type:     Boolean,
            default:  true,
            required: false,
        },
        className: {
            type:    String,
            default: 'ts-date-group',
        },
        showArrow: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        arrowPointDirection: {
            type:     String,
            required: false,
            default:  'right',
        },
        showArrowAnimation: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        quickDates: {
            type:    Array,
            default: () => [
                {
                    name:  'Today',
                    start: dayjs(),
                    end:   dayjs(),
                },
                {
                    name:  'Month-to-Date',
                    start: dayjs().startOf('month'),
                    end:   dayjs(),
                },
                {
                    name:  'Last 30 Days',
                    start: dayjs().subtract(30, 'days'),
                    end:   dayjs(),
                },
                {
                    name:  'Last Month',
                    start: dayjs().subtract(1, 'month').startOf('month'),
                    end:   dayjs().subtract(1, 'month').endOf('month'),
                },
                {
                    name:  'Last Quarter',
                    start: dayjs().subtract(1, 'quarter').startOf('quarter'),
                    end:   dayjs().subtract(1, 'quarter').endOf('quarter'),
                },
                {
                    name:  'Year-to-Date',
                    start: dayjs().startOf('year'),
                    end:   dayjs(),
                },
            ],
            required: false,
        },
    },
};
</script>


<style lang="scss">
@import '../../../sass/variables.scss';
.ts-date-group {
    .form-control {
        border: none;
        z-index: unset;
    }
    .date-range-container {
        border: 3px solid #D6D9DB;
        border-radius: 9px;
        top: unset;
        left: unset;
        z-index: 4;
        .quick-date-buttons {
            order: 2;
            display: flex;
            flex-wrap: wrap;
            padding: 0px 10px;
            .quick-date-label {
                flex: 1 2 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 15px;
                font-weight: 500;
                color: #000;
                font-size: 16px;
            }
            .quick-date-label::after {
                content: '';
                position: absolute;
                bottom: calc(20% + 10px);
                left: 40%;
                width: 20%;
                height: 1.5px;
                background: #CFD8DC;
            }
            .quick-date-button {
                border: 1.5px solid #D6D9DB;
            }
        }
        .date-range-buttons {
            order: 3;
            padding: unset;
            gap: 0px;
            .btn-wrapper {
                padding: 6px 8px;
            }
        }
        .vc-container {
            order: 1;
            padding-top: 25px;
            .vc-weekday {
                border: unset !important;
                outline: none;
                background: $ts-gradient-default;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 400 !important;
            }
            .vc-weeks::after {
                content: '';
                position: absolute;
                bottom: calc(82.5% + 10px);
                left: 0;
                width: 100%;
                height: 1.5px;
                background: $ts-gradient-default;
            }
            .vc-title {
                padding-bottom: unset;
            }
            .vc-arrow {
                background: $ts-gradient-default;
                color: white;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border-style: unset !important;
                svg {
                    stroke-width: 1px;
                    height: 21px;
                    padding-bottom: 2px;
                }
            }
            .column-from-end-2::after {
                content: '';
                position: absolute;
                top: 18%;
                left: 50%;
                width: 1.5px;
                height: 80%;
                background: $ts-gradient-default;
            }
        }
    }
}
.ts-date-picker-container {
    display: flex;
    flex-direction: row;
    width: 350px;
    min-height: 38px;
    max-height: 100px;
    background: #fff;
    border: 1px solid $ts-gray-border-color;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    .input-group {
        display: flex;
        align-items: center;
        width: 100%;
        padding-right: 0.5em;
        .form-control {
            border: 0;
            box-shadow: none;
            cursor: pointer;
            width: 100px;
        }
        .clear-search-button {
            display: flex;
            top: 0;
            left: 0;
            padding-right: 5px;
        }
        .caret-down-purple-icon-container {
            margin-left: auto;
            padding-right: 0;
        }
        .selected-date {
            display: flex;
            width: 100px;
            border-radius: 4px;
            background: #DADADA;
            input {
                border-radius: 4px;
                background: #DADADA;
                height: 25px;
                padding: 10px;
            }
        }
        .generic-icon-container {
            margin-left: auto;
        }
    }
    .clear-search-button {
        position:relative;
        z-index: 2;
        left: 323px;
        top: -39px;
        button {
            text-decoration: none;
            padding: 0px;
            .fa {
                color: #84878d;
                padding: 4px;
            }
            .fa:hover {
                background: #f1f4f7;
                color: #84878d;
            }
        }
    }
    .placeholder-label {
        color: #ADB0B3;
        cursor: pointer;
        font-weight: normal;
        margin: auto 0px auto 25px;
        padding: 0px 20px;
        text-align: end;
    }
    .disable-custom-date-range.vc-container {
        pointer-events: none;
    }
    .date-range-container {
        display: unset;
        left: 0;
        .vc-container {
            border: none;
            margin: auto;
            padding: 0px 30px;
            order: 1;
            padding-top: 25px;

            order: 1;
            padding-top: 25px;
            .vc-weekday {
                border: unset !important;
                outline: none;
                background: $ts-gradient-default;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 400 !important;
            }
            .vc-weeks::after {
                content: '';
                position: absolute;
                bottom: calc(82.5% + 10px);
                left: 0;
                width: 100%;
                height: 1.5px;
                background: $ts-gradient-default;
            }
            .vc-title {
                padding-bottom: unset;
            }
            .vc-arrow {
                background: $ts-gradient-default;
                color: white;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border-style: unset !important;
                svg {
                    stroke-width: 1px;
                    height: 21px;
                    padding-bottom: 2px;
                }
            }
            .column-from-end-2::after {
                content: '';
                position: absolute;
                top: 18%;
                left: 50%;
                width: 1.5px;
                height: 80%;
                background: $ts-gradient-default;
            }

            .vc-pane {
                font-family: "Helvetica Neue";
                &:not(:first-of-type) {
                    padding-left: 20px;
                }
                &:not(:last-of-type) {
                    padding-right: 20px;
                }

                .vc-title {
                    font-family: "Helvetica Neue";
                    font-size: 16px;
                    font-weight: bold;
                    padding-bottom: 8px;
                }

                .vc-day.is-not-in-month {
                    * {
                        opacity: 0.25;
                    }
                }
                .vc-highlight-base-middle, .vc-highlight-base-start, .vc-highlight-base-end {
                    height: 32px !important;
                    padding-left: 36px;
                }
                .vc-highlight-base-middle {
                    border-radius: 50%;
                }
                .vc-highlight-base-start {
                    border-radius: 50% 0 0 50% !important;
                }
                .vc-highlight-base-end {
                    border-radius: 0 50% 50% 0 !important;
                }
                .vc-highlight {
                    width: 28px;
                    height: 28px;
                }
                .vc-weeks {
                    gap: 4px 0px;
                    .vc-day {
                        padding: 0px 4px;
                        height: 32px;
                        .vc-day-content {
                            font-weight: 500 !important;
                            font-size: 14px;
                        }
                    }
                    .vc-day:has(.selected-date) {
                        .is-disabled {
                            opacity: 0.25;
                        }
                        .vc-highlights {
                            background: #CFD8DC !important;
                            border-radius: 0 !important;
                        }
                    }
                    .is-last-day {
                        .vc-day-layer {
                            background: #CFD8DC !important;
                            border-radius: 0 !important;
                        }
                    }
                    .vc-weekday {
                        border-bottom: 2px solid #999;
                        font-size: 14px;
                        color: #000;
                        font-weight: 400 !important;
                        padding-bottom: 10px;
                    }
                }
            }
            .vc-arrows-container {
                top: 7px;
            }
        }
        .date-range-buttons {
            display: flex;
            gap: 0;
            justify-content: center;
            padding: 0px;
            .btn-gradient-container {
                .btn-gradient-inner-container {
                    .btn-wrapper {
                        padding: 5px 10px;
                    }
                    .primary:hover {
                        padding: 7px 13px;
                    }
                }
            }
        }
        .disabled-input {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }
}
</style>

import Platforms from '@/platform.js';
import tiers from '@/stores/modules/tiers.js';
import axios from '@/axios';
import Curator from '../../curator.js';
import StoriesHandler from '../../stories.js';

const getters = {
    curatorPool(state) {
        let o = {};
        Object.assign(o, state.pool, { regions: state.regions });
        return o;
    },
    customFilterSelected: (state) => (key) => {
        return state.customFilter[key] || [];
    },
    activeFilters(state) {
        let activeKeys = Object.keys(state).filter((key) => {
            let filterKey =
                'filteredBy' + key.slice(0, 1).toUpperCase() + key.slice(1);
            let ret =
                state.hasOwnProperty(filterKey) &&
                state[key].length != state[filterKey].length;

            // Special case for regions
            if (key == 'regions') {
                let filterByRegions = state.filteredByRegions.filter(
                    (region) => region,
                );
                ret =
                    state.regions &&
                    state.regions.length != filterByRegions.length;
            }

            return ret;
        });

        let reducer = (acc, cur) => {
            let filterKey =
                'filteredBy' + cur.slice(0, 1).toUpperCase() + cur.slice(1);
            if (typeof state[cur][0] == 'object') {
                acc[cur] = state[filterKey].map((val) => {
                    return state[cur].find((v) => v.id == val);
                });
            } else {
                acc[cur] = state[filterKey];
            }

            return acc;
        };

        activeKeys = activeKeys.reduce(reducer, {});

        if (state.publishedDate[0] && state.publishedDate[1]) {
            activeKeys.publishedDate = [
                `${state.publishedDate[0]} - ${state.publishedDate[1]}`,
            ];
        }

        if (state.expirationDate[0] && state.expirationDate[1]) {
            activeKeys.expirationDate = [
                `${state.expirationDate[0]} - ${state.expirationDate[1]}`,
            ];
        }

        if (
            activeKeys.hasOwnProperty('classifiers') &&
            activeKeys.classifiers.length
        ) {
            activeKeys.classifiers = activeKeys.classifiers.map((id) => {
                return tiers.getters.getClassifierById(tiers.state)(id);
            });

            let hasAll = tiers.getters
                .getAllClassifiers(tiers.state)
                .every((c) => {
                    return activeKeys.classifiers.find(
                        (match) => match.id == c.id || match.parent_id == c.id,
                    );
                });

            if (hasAll) {
                delete activeKeys.classifiers;
            }
        }

        return activeKeys;
    },
    getStoryPlatforms: (state) => (tile) => {
        const $sorted = [1, 3, 4, 2, 5];
        return state.availablePlatforms.map((platforms, index) => {
            const platformId = $sorted[index];
            const platform = state.platforms.find(({ id }) => id == platformId);
            return {
                platform_id: platformId,
                enabled:     platform
                    ? tile.enabled_platforms.includes(platform.id)
                    : false,
                selected: platform
                    ? tile.enabled_platforms.includes(platform.id)
                    : false,
                available: platform ? true : false,
            };
        });
    },
    getMatchTipClassifierAndRegions: (tiles, tipClassifiers, tipRegions) => {
        if (!tiles && !tipClassifiers && !tipRegions) {
            return [];
        }
        return tiles.map((item) => {
            // Classifiers
            item['classifiers_tips'] = [];
            item['classifiers'].map((i) => {
                tipClassifiers.map((tip) => {
                    if (typeof i === 'object') {
                        if (i.id === tip.id) {
                            item['classifiers_tips'].push(tip.name);
                        }
                    } else {
                        if (i === tip.name) {
                            item['classifiers_tips'].push(tip.name);
                        }
                    }
                });
            });
            item['classifiers_tips'] = item['classifiers_tips'].sort();
            item['classifiers_tips_verbose'] =
                item['classifiers_tips'].length === 1
                    ? item['classifiers_tips'][0]
                    : `${item['classifiers_tips'].length}  Selected`;

            // Regions
            item['regions_tips'] = [];
            item['regions'].map((i) => {
                tipRegions.map((tip) => {
                    if (typeof i === 'object') {
                        if (i.id === tip.id) {
                            item['regions_tips'].push(tip.name);
                        }
                    } else {
                        if (i === tip.id) {
                            item['regions_tips'].push(tip.name);
                        }
                    }
                });
            });
            item['regions_tips'] = item['regions_tips'].sort();
            item['regions_tips_verbose'] =
                item['regions_tips'].length === 1
                    ? item['regions_tips'][0]
                    : `${item['regions_tips'].length}  Selected`;

            return item;
        });
    },
};

export default {
    namespaced: true,

    state: {
        ownerId:                     null,
        dynamicFields:               {},
        isSearching:                 true,
        isFilteredByDealerDenied:    false,
        isFilteredByScheduledToPost: true,
        isFilteredByPosted:          false,
        filteredByLanguages:         [],
        filteredByPlatforms:         [],
        filteredByPostTypes:         [],
        filteredByRegions:           [],
        filteredBySources:           [],
        filteredByClassifiers:       [],
        filteredBySortOption:        '',
        languages:                   [],
        sortOption:                  '',
        offsetBuckets:               {},
        page:                        0,
        platforms:                   [],
        pool:                        null,
        poolId:                      null,
        postTypes:                   ['PHOTO', 'VIDEO', 'URL', 'TEXT'],
        publishedDate:               ['', ''],
        expirationDate:              ['', ''],
        searchText:                  '',
        searchType:                  '',
        sources:                     [],
        regions:                     [],
        classifiers:                 [],
        selectedStoryBucket:         '',
        selectedContentType:         'available',
        status:                      'approved',
        tiles:                       [],
        totalStories:                0,
        customFilter:                {},
        availablePlatforms:          [],
        tipClassifiers:              [],
        tipRegions:                  [],
        scheduled:                   false,
        inBrandManagerView:          false,
        scheduledType:               {
            retailer: true,
            brand:    true,
        },
        upcomingBucketsCount: {
            available: null,
            scheduled: null,
        },
        canMultiSelect: null,
        settings:       [],
        retailers:      [],
        initialized:    false,
    },

    getters,

    mutations: {
        initialize(state, payload) {
            for (const key in payload) {
                state[key] = payload[key];
            }
            if (payload.pool) {
                state.poolId = payload.pool ? state.pool.id : null;
            }
        },
        filterByLanguages(state, languages) {
            state.filteredByLanguages = languages;
        },
        filterByPlatforms(state, platforms) {
            state.filteredByPlatforms = platforms;
        },
        filterByPostTypes(state, postTypes) {
            state.filteredByPostTypes = postTypes;
        },
        filterByRegions(state, regions) {
            state.filteredByRegions = regions;
        },
        filterBySources(state, sources) {
            state.filteredBySources = sources;
        },
        filterByClassifiers(state, classifiers) {
            state.filteredByClassifiers = classifiers;
        },
        filterBySortOption(state, sortOption) {
            state.filteredBySortOption = sortOption;
        },
        filterCanMultiSelect(state, value) {
            state.canMultiSelect = value;
        },
        clearSearch(state) {
            state.expirationDate[0] = '';
            state.expirationDate[1] = '';
            state.publishedDate[0] = '';
            state.publishedDate[1] = '';

            state.searchType = 'Keywords';
            state.searchText = '';
        },
        resetFilters(state) {
            state.filteredByLanguages = state.languages.map((l) => l.id);
            state.filteredByPostTypes = state.postTypes.slice();
            state.filteredBySources = state.sources.map((s) => s.id);
            state.filteredByRegions = state.regions.map((r) => r.id).concat(0);
            state.filteredByPlatforms = state.platforms.map((p) => p.id);
            state.filteredBySortOption = null;
            state.canMultiSelect = null;
        },
        setSearchText(state, text) {
            state.searchText = text;
        },
        setSearchType(state, type) {
            state.searchType = type;
        },
        setExpirationDate(state, expirationDate) {
            state.expirationDate = expirationDate;
        },
        setPublishedDate(state, publishedDate) {
            state.publishedDate = publishedDate;
        },
        clearStories(state) {
            state.page = 0;
            state.tiles = [];
            state.totalStories = 0;
        },
        newStoryQuery(state, payload) {
            state.page = 1;
            state.tiles = payload.stories;
            state.totalStories = payload.total;
        },
        nextStoryPage(state, payload) {
            state.page++;
            state.tiles.push.apply(state.tiles, payload.stories);
        },
        setIsSearching(state, isSearching) {
            state.isSearching = isSearching;
        },
        selectStoryBucket(state, bucketName) {
            state.selectedStoryBucket = bucketName;
            state.status = bucketName;
            if (state.ownerId) {
                switch (bucketName) {
                    case 'upcoming':
                        state.isFilteredByScheduledToPost = false;
                        state.isFilteredByDealerDenied = false;
                        state.isFilteredByPosted = false;
                        break;
                    case 'retailer-denied':
                        state.isFilteredByScheduledToPost = false;
                        state.isFilteredByDealerDenied = true;
                        state.isFilteredByPosted = false;
                        break;
                    case 'posted':
                        state.isFilteredByScheduledToPost = false;
                        state.isFilteredByDealerDenied = false;
                        state.isFilteredByPosted = true;
                }
            }
        },
        updateTile(state, payload) {
            //let multiTiles = payload.multiTiles ? payload.multiTiles : false;
            //let resetOffset = payload.resetOffset ? payload.resetOffset : false;
            let tileReference;
            if (payload.multiTiles) {
                tileReference = state.tiles.filter((s) => s.story_id === payload.tileData.story_id);
            } else {
                tileReference = state.tiles.findIndex((s) => {
                    let match = s.story_id === payload.tileData.story_id;
                    if (match && payload.platformId) {
                        match = s.platform_id === payload.platformId;
                    }

                    return match;
                });
            }

            let refresh = () => {
                let n = {};
                Object.assign(n, state.offsetBuckets);
                state.offsetBuckets = n;
            };
            if (
                !state.offsetBuckets.hasOwnProperty(state.selectedStoryBucket)
            ) {
                state.offsetBuckets[state.selectedStoryBucket] = 0;
            }

            if (!state.offsetBuckets.hasOwnProperty(payload.bucket)) {
                state.offsetBuckets[payload.bucket] = 0;
            }

            if (payload.bucket !== state.selectedStoryBucket || payload.tileData.scheduled_at) {
                let currentBucket = state.selectedStoryBucket;
                if (currentBucket == 'upcoming' && payload.bucket == 'upcoming-scheduled') {
                    currentBucket = 'upcoming-available';
                    state.offsetBuckets[currentBucket] = 0;
                }

                // There are some scenarios where we need to update multiple tiles at the same time.
                // In those scenarios, we should apply a different logic around the tiles.
                if (payload.multiTiles) {
                    state.tiles = state.tiles.filter((s) => s.story_id != payload.tileData.story_id);
                    state.offsetBuckets[state.selectedStoryBucket] = state.offsetBuckets[state.selectedStoryBucket] - tileReference.length;
                    state.offsetBuckets[payload.bucket] = state.offsetBuckets[payload.bucket] + (tileReference.length - 1);
                } else if (tileReference >= 0) {
                    state.tiles.splice(tileReference, 1);
                    state.offsetBuckets[currentBucket]--;
                    state.totalStories--;
                }

                state.offsetBuckets[payload.bucket]++;
                refresh();
            } else if (tileReference >= 0) {
                state.tiles.splice(tileReference, 1, payload.tileData);
                state.tiles[tileReference] = payload.tileData;
                if (
                    (state.tipClassifiers && state.tipClassifiers.length > 0) ||
                    (state.tipRegions && state.tipRegions.length > 0)
                ) {
                    state.tiles = getters.getMatchTipClassifierAndRegions(
                        state.tiles,
                        state.tipClassifiers,
                        state.tipRegions,
                    );
                }
            } else {
                state.offsetBuckets[payload.bucket]++;
                refresh();
                state.tiles.unshift(payload.tileData);
                state.tiles[tileReference] = payload.tileData;
                if (
                    state.tipClassifiers.length > 0 ||
                    state.tipRegions.length > 0
                ) {
                    state.tiles = getters.getMatchTipClassifierAndRegions(
                        state.tiles,
                        state.tipClassifiers,
                        state.tipRegions,
                    );
                }
            }
            if (payload.resetOffset) {
                state.offsetBuckets = {};
                refresh();
            }
        },
        updateThumbnail(state, payload) {
            const tileReference = state.tiles.findIndex((s) => s.story_id === payload.story_id);
            state.tiles[tileReference].thumbnail_url = payload.thumbnail_url;
        },
        updateCustomFilter(state, { value, key }) {
            state.customFilter = Object.assign({}, state.customFilter, {
                [key]: value,
            });
        },
        setContentType(state, contentType) {
            state.selectedContentType = contentType;
            state.options;
        },
        setScheduledType(state, { type }) {
            state.scheduledType[type] = !state.scheduledType[type];
        },
        setUpcomingBucketsCount(state, { available, scheduled }) {
            state.upcomingBucketsCount.available = available;
            state.upcomingBucketsCount.scheduled = scheduled;
        },
    },

    actions: {
        initialize({ state, commit, dispatch }, payload) {
            let languages, regions, tips, settings;
            commit('initialize', payload);
            let poolQS = payload.pool
                ? `pool_id=${payload.pool.external_id}`
                : payload.ownerId
                    ? `owner_id=${payload.ownerId}`
                    : 'foo=1';
            // TODO: will probably have to be diff for multi-platform
            let dynamicFields = Promise.resolve({});

            let sources = Curator.get(`source?${poolQS}`).then((response) =>
                response.data.sources.filter(
                    (source) => source.is_active == true,
                ),
            );
            let platforms = payload.platforms.map((platform) => {
                return {
                    id:   platform,
                    name: Object.keys(Platforms).find(
                        (key) => Platforms[key] == platform,
                    ),
                };
            });

            let sortOption = payload.sortOption;

            if (payload.ownerId) {
                languages = Curator.get(
                    `owner/${payload.ownerId}/languages`,
                ).then((response) => response.data.data);
                regions = Promise.resolve([]);
                dynamicFields = Curator.get(
                    `owner/${payload.ownerId}/dynamic-fields/`,
                ).then((response) => {
                    // No account for owner
                    if (!response.data.fields) {
                        // TODO: figure out cleaner solution
                        return {
                            address:       '',
                            business_name: '',
                            phone_number:  '',
                            website:       '',
                        };
                    }

                    // Convert platform fields into dynamic fields
                    return {
                        address:       response.data.fields.address,
                        business_name: response.data.fields.business_name,
                        phone_number:  response.data.fields.phone_number,
                        website:       response.data.fields.website,
                    };
                });
            } else {
                if (payload.pool) {
                    const allLanguages = Curator.get('languages').then((response) => {
                        return response.data.languages;
                    });

                    languages = allLanguages.then((languagesData) => {
                        return axios.get(`/api/oem-managers/${payload.oemManagerId}/posting-defaults`).then((response) => {
                            const brandLanguagesData = response.data.languages;
                            return languagesData.filter(item => brandLanguagesData?.toString().split(',').map(Number).includes(item.id));
                        });
                    });

                    regions = Curator.get(`pool/${payload.pool.id}/regions`).then((response) => response.data.regions);
                } else {
                    languages = Promise.resolve([]);
                    regions = languages;
                }
            }

            if (payload.oemManagerId) {
                tips = axios
                    .get(`/api/oem-managers/${payload.oemManagerId}/hinges`)
                    .then((response) => response.data);
                settings = axios
                    .get(`/api/oem-managers/${payload.oemManagerId}/settings`)
                    .then((response) => response.data);
                dispatch('loadRetailers', payload.oemManagerId);
            } else {
                tips = Promise.resolve([]);
                settings = Promise.resolve([]);
            }

            return Promise.all([
                sources,
                languages,
                regions,
                dynamicFields,
                tips,
                settings,
                sortOption,
            ]).then((response) => {
                commit('initialize', {
                    platforms:      platforms,
                    sortOption:     sortOption,
                    sources:        response[0],
                    languages:      response[1],
                    regions:        response[2],
                    dynamicFields:  response[3],
                    tipClassifiers: response[4]['tipClassifiers'],
                    tipRegions:     response[4]['tipRegions'],
                    scheduled:      payload.type === 'scheduled',
                    settings:       response[5],
                });
                commit('resetFilters');
                commit('clearSearch');
                state.initialized = true;
                dispatch('search', {
                    isNewSearch: true,
                });

                if (payload.dealerId) {
                    commit('selectStoryBucket', state.selectedStoryBucket);
                }
            });
        },
        loadRetailers({ commit }, oemManagerId) {
            return axios
                .get(`/api/oem-managers/${oemManagerId}/dealerships`, { simple: 1, is_active: true, append_location: true })
                .then((response) => {
                    commit('initialize', {retailers: response.data.dealerships });
                    return response;
                });
        },
        selectStoryBucket(
            { state, commit, dispatch },
            { bucketName, ignoreSearch = false },
        ) {
            if (bucketName !== state.selectedStoryBucket) {
                commit('selectStoryBucket', bucketName);
                if (ignoreSearch !== false) {
                    dispatch('search', {
                        isNewSearch: true,
                    });
                }
            }
        },
        storyBucketWithPermission({commit}, {permissions}) {
            let bucketName;
            if (permissions.upcoming) {
                bucketName = 'upcoming';
            } else if (permissions.posted) {
                bucketName = 'posted';
            } else {
                bucketName = 'retailer-denied';
            }
            commit('selectStoryBucket', bucketName);
        },
        search({ state, commit, dispatch }, payload) {
            if (!state.status) {
                return;
            }

            commit('setIsSearching', true);
            let currentBucket = state.status;
            let currentContentType = state.selectedContentType;
            // Clear stories out so user can see loader, which normally
            // appears below the tiles
            if (payload.isNewSearch) {
                commit('clearStories');
            }

            const options = {
                status:      state.status,
                searchText:  state.searchText,
                searchType:  state.searchType,
                postTypes:   state.filteredByPostTypes,
                sources:     state.filteredBySources,
                page:        payload.isNewSearch ? 0 : state.page,
                classifiers: state.filteredByClassifiers,
                platforms:   state.filteredByPlatforms,
                bucket:      state.selectedStoryBucket,
                sort:        state.filteredBySortOption,
            };
            if (state.canMultiSelect) {
                options.can_multiselect = state.canMultiSelect;
            }
            if (state.ownerId) {
                options.bucket =
                    state.selectedStoryBucket === 'upcoming'
                        ? `${state.selectedStoryBucket}-${state.selectedContentType}`
                        : state.selectedStoryBucket;

                if (state.selectedStoryBucket === 'posted') {
                    if (state.publishedDate[0]) {
                        options.postDate = state.publishedDate;
                    }
                } else {
                    if (state.publishedDate[0]) {
                        options.startDate = state.publishedDate;
                    }
                    if (state.expirationDate[0]) {
                        options.endDate = state.expirationDate;
                    }
                }
                if (
                    state.scheduledType.brand !== state.scheduledType.retailer
                ) {
                    options.scheduled_type = state.scheduledType.brand
                        ? 'brand'
                        : 'retailer';
                }
            } else {
                options.publishedDate = state.publishedDate;
                options.expirationDate = state.expirationDate;
            }

            if (options.platforms.length === 0) {
                options.platforms = state.platforms.map(function (platform) {
                    return platform.id;
                });
            }

            options.scheduled = state.scheduled === true;

            if (Object.keys(state.dynamicFields).length) {
                options.dynamicFields = state.dynamicFields;
            }

            if (state.pool) {
                if (state.pool.id == 181) { // temp gate to allow admins fixes, will be removed shortly
                    options.bypass_platforms = true;
                }
                if (!state.ownerId && state.pool.has_regions_enabled) {
                    options.regions = state.filteredByRegions;
                }

                options.languages = state.filteredByLanguages;
            }

            const totals = {
                sources:     state.sources.length,
                regions:     state.regions.length + 1, // 1 accounts for id:0 representing content without regions
                languages:   state.languages.length,
                classifiers: state.classifiers.length,
            };

            for (const option in totals) {
                if (!options.hasOwnProperty(option)) {
                    continue;
                }

                // If nothing was selected, then nothing should show up...
                // todo: remove classifiers hack
                if (
                    options[option].length === 0 &&
                    option !== 'classifiers' &&
                    state.ownerId
                ) {
                    commit('clearStories');
                    commit('setIsSearching', false);
                    return;
                }

                // If all selected, find results even where none are selected
                // This only applies to brand manager to prevent losing stories in the void
                // dealer should never see a story they do not have in their filter.
                if (
                    !state.ownerId &&
                    totals[option] === options[option].length
                ) {
                    delete options[option];
                }
            }

            if (state.ownerId) {
                if (state.selectedStoryBucket === 'upcoming') {
                    options.bucket = `upcoming-${state.selectedContentType}`;
                }
                if (state.isFilteredByScheduledToPost) {
                    options.claim_type = 'claimed';
                } else if (state.isFilteredByDealerDenied) {
                    options.claim_type = 'denied';
                } else if (state.isFilteredByPosted) {
                    options.claim_type = 'posted';
                }
            }
            const curator = new StoriesHandler(
                state.pool ? state.pool.id ?? null : null,
            );

            if (!state.initialized) {
                return;
            }

            return curator
                .searchStories(state.ownerId, options)
                .then((results) => {
                    results.stories.forEach(result => {
                        if (result.assets.length > 0) {
                            result.assets = result.assets.sort((a, b) => a.pivot.sort_order - b.pivot.sort_order);
                        }
                    });
                    if (results.status === false) {
                        throw results.message;
                    }
                    if (payload.isNewSearch) {
                        if (state.isSearching) {
                            commit('newStoryQuery', results);
                        }
                    } else {
                        let warn = state.tiles.find((s) =>
                            results.stories.some((s2) => s.id === s2.id),
                        );
                        if (warn) {
                            results.stories = results.stories.filter(
                                (s) => !state.tiles.some((s2) => s.id === s2.id),
                            );
                        }

                        commit('nextStoryPage', results);

                        if (warn) {
                            throw {
                                audience: 'user',
                                type:     'warning',
                                message:
                                    'Story results have changed since you last visited the page, you may want to refresh',
                            };
                        }
                    }
                })
                .catch((e) => {
                    console.error(e);

                    let type = 'error';
                    let text =
                        typeof e === 'string' ? e : 'Failed to load stories';

                    if (e.audience === 'user') {
                        type = e.type;
                        text = e.message;
                    }

                    window.flash.show({
                        type,
                        text,
                    });
                })
                .finally(() => {
                    /* What we're trying to do here is to make sure that the content that is being displayed,
                    corresponds to the selected bucket. As the selection of the bucket and the stories of the
                    bucket are not synchronous, sometimes they do not coincide, what we're trying to do here is make sure,
                    if they are not the same, to reload the stories. This only works on BrandManager and ParentBrandManager views.
                    This is a hack, and should be refactored later.
                    */
                    if (currentBucket != state.selectedStoryBucket) {
                        dispatch('search', {
                            isNewSearch: true,
                        });
                        return;
                    } else {
                        if (currentContentType !== state.selectedContentType) {
                            dispatch('search', {
                                isNewSearch: true,
                            });
                            return;
                        }
                    }
                    commit('setIsSearching', false);
                    if (
                        state.tipClassifiers &&
                        (state.tipClassifiers.length > 0 ||
                            state.tipRegions.length > 0)
                    ) {
                        state.tiles = getters.getMatchTipClassifierAndRegions(
                            state.tiles,
                            state.tipClassifiers,
                            state.tipRegions,
                        );
                    }
                });
        },
    },
};

import axios from '@/axios';

function buildKey(path) {
    if (path.length === 1) {
        return path[0];
    } else {
        return path.slice(1)
            .map(k => `${path[0]}[${k}]`)
            .join('');
    }
}

function mapQueryKeyValue(options, path) {
    return Object.keys(options).map(key => {
        let npath = path.concat(key);

        if (options[key] === null) {
            return buildKey(npath) + '=';
        } else if (options[key] instanceof Array) {
            return buildKey(npath) + '=' + encodeURIComponent(options[key].join(','));
        } else if (typeof options[key] === 'object') {
            return mapQueryKeyValue(options[key], npath)
                .reduce((a, b) => a.concat(b), []);
        } else {
            return buildKey(npath) + '=' + encodeURIComponent(options[key]);
        }
    });
}

function buildQueryString(options) {
    return mapQueryKeyValue(options, [])
        .reduce((a, b) => a.concat(b), [])
        .join('&');
}

export default class StoriesHandler {
    constructor(pool_id) {
        this.pool_id = pool_id;
        this.stories = {};
    }

    /**
     * Returns an optimized set of minimum data required. If more info
     * is needed about a specific story, use getStory
     *
     * @param int dealerId If null, means search stories on whole pool
     * @param {Array} filterOptions
     */
    searchStories(ownerId, options = {}) {
        let query = buildQueryString(options);

        if (!ownerId && (this.pool_id === null || isNaN(this.pool_id))) {
            return Promise.reject('The pool_id is not set, where you at postmaster?');
        }

        let url   = ownerId
            ? `/stories/owner/${ownerId}?${query}`
            : `/stories/pool/${this.pool_id}?${query}`;

        let p = axios.get(url)
            .then(response => {
                if (response.data.status === false) {
                    window.flash.show({
                        type: 'error',
                        text: response.data.message,
                    });

                    return {
                        status:  true,
                        total:   0,
                        stories: [],
                    };
                }

                return response.data;
            });

        p.catch(e => {
            console.error('Error searching stories: ', e);
        });

        return p;
    }
}

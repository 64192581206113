import FacebookLinkerConfig from '@/linker_config/facebook.js';
import GBPLinkerConfig from '@/linker_config/gbp.js';
import LinkedinLinkerConfig from '@/linker_config/linkedin.js';
import TwitterLinkerConfig from '@/linker_config/twitter.js';
import Platforms from '@/platform.js';

export default {
    [Platforms.FACEBOOK]: FacebookLinkerConfig,
    [Platforms.GBP]:      GBPLinkerConfig,
    [Platforms.TWITTER]:  TwitterLinkerConfig,
    [Platforms.LINKEDIN]: LinkedinLinkerConfig,
};

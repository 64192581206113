export default {
    namespaced: true,

    state: {
        enabledPlatforms: [],
        enabledServices:  [],
    },

    mutations: {
        toggleServicePlatform(state, {enabled, platformId, serviceId}) {
            let index = state.enabledServices.findIndex(s => s.id === serviceId);
            if (index >= 0 && !enabled) {
                let platformHasOtherServices = state.enabledServices
                    .some(s => s.id !== serviceId && s.platformId === platformId);

                if (!platformHasOtherServices) {
                    state.enabledPlatforms = state.enabledPlatforms.filter(p => p !== platformId);
                }

                state.enabledServices.splice(index, 1);
            } else if (index === -1 && enabled) {
                let hasPlatformAlready = state.enabledPlatforms.some(p => p === platformId);
                if (!hasPlatformAlready) {
                    state.enabledPlatforms.push(platformId);
                }

                state.enabledServices.push({
                    id:         serviceId,
                    platformId: platformId,
                });
            }
        },
    },

};
const defaultTableData = [
    {
        name: 'Activity',
        data: [
            {
                name:     'Retailers',
                previous: 0,
                current:  0,
            },
            {
                name:     'Posts',
                previous: 0,
                current:  0,
                indent:   true,
            },
        ],
    },
    {
        name: 'Visibility',
        data: [
            {
                name:     'Impressions',
                previous: 0,
                current:  0,
            },
            {
                name:     'Organic Reach',
                previous: 0,
                current:  0,
                indent:   true,
            },
        ],
    },
    {
        name: 'Engagement',
        data: [
            {
                name:     'Post Clicks',
                previous: 0,
                current:  0,
            },
            {
                name:     'Reactions',
                previous: 0,
                current:  0,
            },
            {
                name:     'Comments',
                previous: 0,
                current:  0,
            },
            {
                name:     'Shares',
                previous: 0,
                current:  0,
            },
            {
                name:     'Video Views',
                previous: 0,
                current:  0,
            },
        ],
    },
];

const defaultPost = {
    highlights: [{name: 'Most Impressions', value: 'xxxxxx'}],
    image:      '/images/place-holders/no-link.svg',
    caption:    'Caption exists here',
    type:       'photo',
};

const defaultMetric = {
    name:     'Posts',
    previous: 5000,
    current:  7500,
};

export default () => ({
    year:                     (new Date).getFullYear().toString(),
    quarter:                  'Q' + Math.ceil((new Date).getMonth() / 3).toString(),
    QuarterlyReportTitlePage: {
        includePage: true,
    },
    QuarterlyReportTotalsPage: {
        includePage: true,
        title:       '',
        message:     '',
        posts:       0,
        impressions: 0,
        reach:       0,
    },
    FacebookPerformanceChartPage: {
        includePage: true,
        platformId:  1,
        tableData:   Array.from(defaultTableData),
        title:       '',
        message:     '',
    },
    FacebookTopPerformingPage: {
        includePage: true,
        platformId:  1,
        posts:       [
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
        ],
    },
    InstagramPerformanceChartPage: {
        includePage: true,
        platformId:  3,
        tableData:   Array.from(defaultTableData),
        title:       '',
        message:     '',
    },
    InstagramTopPerformingPage: {
        includePage: true,
        platformId:  3,
        posts:       [
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
        ],
    },
    LinkedInPerformanceChartPage: {
        includePage: true,
        platformId:  5,
        tableData:   Array.from(defaultTableData),
        title:       '',
        message:     '',
    },
    LinkedInTopPerformingPage: {
        includePage: true,
        platformId:  5,
        posts:       [
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
        ],
    },
    XPerformanceChartPage: {
        includePage: true,
        platformId:  2,
        tableData:   Array.from(defaultTableData),
        title:       '',
        message:     '',
    },
    XTopPerformingPage: {
        includePage: true,
        platformId:  2,
        posts:       [
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
            Object.assign({}, defaultPost),
        ],
    },
    GooglePerformanceChartPage: {
        includePage: true,
        platformId:  4,
        tableData:   [
            {
                name: 'Activity',
                data: [
                    {
                        name:     'Retailers',
                        previous: 0,
                        current:  0,
                    },
                    {
                        name:     'Posts',
                        previous: 0,
                        current:  0,
                        intent:   true,
                    },
                    {
                        name:     'Profile Views',
                        previous: 0,
                        current:  0,
                    },
                ],
            },
        ],
        title:   '',
        message: '',
        stars:   {
            value: 0,
            max:   5,
        },
    },
    QuarterlyReportAmplificationPage: {
        includePage: true,
        message:     '',
        leftPost:    Object.assign({}, defaultPost, { highlights: [
            {
                name:     'Lifetime Engagements',
                value:    '',
                editable: true,
            },
        ]}),
        rightPost: Object.assign({}, defaultPost, { highlights: [
            {
                name:  'Quarterly Engagements',
                value: '0',
            },
        ]}),
        url:   'https://thumbstopper.com',
        brand: {
            name:  'Your Brand',
            image: '/images/quarterly-reports/care.png',
        },
        retailers:      0,
        profilePicture: '',
    },
    QuarterlyReportProgramOpportunitiesPage: {
        includePage:  true,
        leftTitle:    '',
        leftMessage:  '',
        rightTitle:   '',
        rightMessage: '',
    },
    FacebookYoyEngagementsPage: {
        includePage: true,
        name:        'Engagements',
        description: 'Total number of engagements and reactions to distributed content. Reactions increase the probability that users will be server similar organic posts in their feed in the future.',
        title:       '',
        message:     '',
        metrics:     [
            Object.assign({}, defaultMetric),
            Object.assign({}, defaultMetric, { name: 'Engagements' }),
        ],
    },
    FacebookYoyImpressionsPage: {
        includePage: true,
        name:        'Impressions',
        description: 'Total number of impressions cultivated from distributed content (YOY). Impressions represented the total number of times content was displayed to social media users.',
        title:       '',
        message:     '',
        metrics:     [
            Object.assign({}, defaultMetric),
        ],
    },
    FacebookYoyOrganicReachPage: {
        includePage: true,
        name:        'Organic Reach',
        description: 'Total number of users who received content from distribution efforts (YOY). Reach represents the population of social media users who saw posts on retailer social destinations.',
        title:       '',
        message:     '',
        metrics:     [
            Object.assign({}, defaultMetric),
        ],
    },
    FacebookYearlyPerformancePage: {
        includePage: true,
        platformId:  1,
        tableData:   Array.from(defaultTableData),
        title:       '',
        message:     '',
        yearly:      true,
    },
    QuarterlyReportThankYouPage: {
        includePage: true,
    },
    completed_at: null,
    saveButton:   {
        text:   'Save',
        status: null,
    },
    reportId:          null,
    recipientsUserIds: [],
});
export default () => ({
    connectId:         null,
    retailerName:      '',
    firstName:         '',
    lastName:          '',
    dealerNumber:      '',
    businessEmail:     '',
    businessPhone:     '',
    otherPhone:        '',
    contactTerms:      false,
    classifiers:       [],
    stripeToken:       '',
    creditCardName:    '',
    creditCardAddress: '',
    creditCardZip:     '',
    creditTerms:       false,
    regionId:          null,
    languages:         [],
    ownerId:           null,
    loadOwnerId:       false,
    oemManager:        null,
    oemManagerId:      null,
    potentialId:       null,
    platformLinks:     {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
    },
    openFBModal: false,

    // Part of me feels like this one shouldn't be here, but I couldn't really come up with a better option ¯\_(ツ)_/¯
    regionOptions:                  [],
    languageOptions:                [],
    defaultRetailerLanguageOptions: [],
    country:                        null,
    dealershipId:                   null,
});

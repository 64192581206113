import axios from 'axios';
import qs from 'qs';

export default {
    handlers: [
        {
            getAccounts(url) {
                let accounts = [];
                return axios.get(url)
                    .then(response => {
                        let responseAccounts = response?.data?.accounts || [];

                        accounts = responseAccounts.map(account => {
                            return {
                                external_id: account.id,
                                name:        account.name,
                                picture:     account.profile_image_url,
                                username:    account.username,
                                website:     account.url,
                                address:     account.location,
                            };
                        });

                        if (response?.data?.after) {
                            let split = url.split('?');
                            let q = qs.parse(q[1]);
                            q.after = response.data.after;
                            split[1] = qs.stringify(q);

                            return accounts.concat(this.getAccounts(split.join('?')));
                        }

                        return accounts;
                    });
            },
        },
    ],
};

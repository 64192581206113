import _ from 'lodash';

import Platform from '@/platform.js';
import TYPES from './types';

export default {
    [TYPES.MUTATIONS.SHOW_CONTENT_INFO_ERRORS]: (state, payload) => {
        if (payload.type === 'asset') {
            state.showAssetError = true;
        } else if (payload.type === 'textarea') {
            state.showTextareaError = true;
        } else {
            state.showAssetError = true;
            state.showTextareaError = true;
        }
    },
    [TYPES.MUTATIONS.CLEAN_STORY_FOR_SELECTED_POST_TYPE]: (state) => {
        const postTypes = ['PHOTO', 'VIDEO', 'URL', 'TEXT'];
        const postTypeId =
            postTypes.findIndex((pt) => pt === state.story.post_type) + 1;
        let platformStories;

        if (['PHOTO', 'VIDEO'].includes(state.story.post_type)) {
            platformStories = state.story.platformStories.map((ps) => {
                ps.storyAssets = ps.storyAssets.filter(
                    (sa) => sa.type === postTypeId,
                );
                const storyAssetsIds = ps.storyAssets.map((sa) => sa.id);
                ps.selectedAssets = ps.selectedAssets.filter((sa) => {
                    return storyAssetsIds.find((id) => id === sa);
                });
                return ps;
            });
        }
        if (state.story.post_type === 'TEXT') {
            platformStories = state.story.platformStories.map((ps) => {
                ps.storyAssets = [];
                ps.selectedAssets = [];
                return ps;
            });
        }
        if (platformStories) {
            state.story.platformStories = platformStories;
        }
    },
    [TYPES.MUTATIONS.CLEAN_ALL_PLATFORMS_MENTIONS]: (state, plainText) => {

        let platformStories;
        platformStories = state.story.platformStories.map(ps => {
            if (ps.platformId !== 1) {
                ps.message = ps.message.replaceAll(/@\[(\d+)\]/g, function(a, b) {
                    return plainText[b];
                });
            }
            return ps;
        });
        state.story.platformStories = platformStories;
    },
    [TYPES.MUTATIONS.SET_ERRORS]: (state, payload) => {
        if (payload.errorType === 'limit') {
            state.errors.limit = payload.existing;
            return;
        }
        if (payload.errorType === 'mixed') {
            state.errors.media.mixed.enabled = payload.hasMixed;
            return;
        }
        if (payload.errorType === 'min') {
            state.errors.media[payload.minErrorType].min = true;
            return;
        }
        if (payload.noMedia) {
            state.errors.media[payload.type][payload.errorType] = [];
            return;
        }
        if (payload.existing) {
            state.errors.media[payload.type][payload.errorType].splice(
                state.errors.media[payload.type].aspectRatio.indexOf(
                    payload.existing,
                ),
                1,
            );
        } else {
            state.errors.media[payload.type][payload.errorType].push({
                id:   payload.id,
                text: payload.text,
            });
        }
    },
    [TYPES.MUTATIONS.SET_INPUT_ERRORS]: (state) => {
        state.inputError =
            Object.values(
                state.populatedInputsForSelectedPlatformStory,
            ).includes(false) &&
            state.showAssetError &&
            state.showTextareaError;
    },
    [TYPES.MUTATIONS.PARTIAL_STORY_UPDATE](state, payload) {
        Object.keys(payload).forEach((k) => {
            state.story[k] = _.cloneDeep(payload[k]);
        });
    },
    [TYPES.MUTATIONS.PARTIAL_UPDATE](state, payload) {
        Object.keys(payload).forEach((k) => {
            if (k === 'story') {
                return;
            }
            state[k] = _.cloneDeep(payload[k]);
        });
    },
    [TYPES.MUTATIONS.SET_APPROVAL]: (state) => {
        if (!this.oemIsParent) {
            return;
        }
        state.story.approval = state.story.is_copied
            ? 'curate_to_pending'
            : 'pending';
    },
    [TYPES.MUTATIONS.ADD_PLATFORM_STORY]: (state, payload) => {
        state.story.platformStories.push(payload);
    },
    [TYPES.MUTATIONS.PLATFORM_CAMPAIGN_DISABLED]: (state, payload) => {
        state.story.platformCampaignDisabled.push(payload);
    },
    [TYPES.MUTATIONS.ADD_POPULATED_INPUTS]: (state, payload) => {
        state.populatedInputs.push(payload);
    },
    [TYPES.MUTATIONS.UPDATE_POPULATED_INPUT]: (
        state,
        { populatedInputsForPlatformStory },
    ) => {
        for (let i = 0; i < state.populatedInputs.length; i += 1) {
            if (
                state.populatedInputs[i].platformId ===
                populatedInputsForPlatformStory.platformId
            ) {
                state.populatedInputs[i] = Object.assign(
                    {},
                    populatedInputsForPlatformStory,
                );
                break;
            }
        }
    },
    [TYPES.MUTATIONS.UPDATE_PLATFORM_STORY]: (
        state,
        { platformId, payload, forceSync },
    ) => {
        Object.keys(payload).forEach((k) => {
            if (k === 'selectedAssets') {
                return;
            }
            for (let i = 0; i < state.story.platformStories.length; i += 1) {
                if (k === 'assetPlatformStories') {
                    payload.assetPlatformStories = payload.assetPlatformStories.map((aps) => {
                        return {
                            ...aps,
                            platform_story_id: state.story.platformStories[i].id,
                        };
                    });
                }
                if (platformId === 'all') {
                    const platformToIgnore = state.story.exclude_from_sync.find(pf => pf == state.story.platformStories[i].platformId);
                    if (!platformToIgnore || forceSync) {
                        state.story.platformStories[i][k] = payload[k];
                    }
                } else if (
                    state.story.platformStories[i].platformId == platformId
                ) {
                    state.story.platformStories[i][k] = payload[k];
                    break;
                }
            }
        });
    },
    [TYPES.MUTATIONS.UPDATE_PLATFORM_STORY_SELECTED_ASSETS]: (
        state,
        { platformId, payload },
    ) => {
        Object.keys(payload).forEach((k) => {
            if (k !== 'selectedAssets') {
                return;
            }
            for (let i = 0; i < state.story.platformStories.length; i += 1) {
                if (platformId === 'all') {
                    state.story.platformStories[i][k] = payload[k];
                } else if (state.story.platformStories[i].platformId == platformId) {
                    state.story.platformStories[i][k] = payload[k];
                    break;
                }
            }
        });
    },
    [TYPES.MUTATIONS.UPDATE_ENABLED_PLATFORM_STORIES_FROM_SELECTED_POST_TYPE]: (
        state,
    ) => {
        if (
            state.story.post_type === 'URL' ||
            state.story.post_type === 'TEXT' ||
            state.story.post_type === 'VIDEO'
        ) {
            state.story.platformStories.forEach((platformStory, idx) => {
                // Enabled only if the post type is URL or TXT AND platform is FB or GBP
                const shouldEnableFacebook =
                    platformStory.platformId == Platform.FACEBOOK;
                const shouldEnableGbp =
                    platformStory.platformId == Platform.GBP &&
                    state.story.post_type === 'TEXT';
                const shouldEnableTwitter =
                    platformStory.platformId == Platform.TWITTER &&
                    state.story.post_type != 'VIDEO' &&
                    state.story.post_type != 'URL';
                const shouldEnableInstagram =
                    platformStory.platformId == Platform.INSTAGRAM &&
                    state.story.post_type === 'VIDEO';
                const shouldEnableLinkedin =
                    platformStory.platformId == Platform.LINKEDIN;
                state.story.platformStories[idx].enabled =
                    state.story.platformStories[idx].enabled &&
                    (shouldEnableInstagram ||
                        shouldEnableTwitter ||
                        shouldEnableFacebook ||
                        shouldEnableGbp ||
                        shouldEnableLinkedin);
            });
        }
    },

    [TYPES.MUTATIONS.FIX_ENABLED_ASSETS](
        state,
    ) {
        state.story.platformStories.forEach(function(ps, i) {
            if (ps.platformId !== 'all') {
                state.story.platformStories[i].assetPlatformStories = ps.assetPlatformStories?.map(asset => {
                    asset.enabled = ps.selectedAssets.includes(asset.asset_id);
                    return asset;
                });
            }
        });
    },

    [TYPES.MUTATIONS.UPDATE_ASSETS_FOR_URL](state, { storyAssets, selectedAssets, title }) {
        let storyAsset = storyAssets[0] || {};
        
        let assetPlatformStories = [{
            asset_id:   storyAsset?.id,
            enabled:    true,
            sort_order: 0,
        }];

        for (let i = 0; i < state.story.platformStories.length; i++) {
            state.story.platformStories[i].assetPlatformStories = assetPlatformStories;
            state.story.platformStories[i].storyAssets = storyAssets;
            state.story.platformStories[i].selectedAssets = selectedAssets;
            state.story.platformStories[i].misc.image_url = storyAsset.url;
            state.story.title = title;
        }
    },
    [TYPES.MUTATIONS.UPDATE_PLATFORM_TO_KEEP_CONTENT](state, platformIds) {
        const mergedPlatformIds = [...platformIds, ...state.story.exclude_from_sync];
        state.story.exclude_from_sync = platformIds.length ? [...new Set(mergedPlatformIds)] : [];
    },

    [TYPES.MUTATIONS.UPDATE_SELECTED_PLATFORM_STORY_CHANGES](state, payload) {
        state.selectedPlatformStoryIsNotModified = payload.isModified;

        if (!state.selectedPlatformStoryIsNotModified && !state.story.exclude_from_sync.includes(state.selectedPlatformStory)) {
            state.story.exclude_from_sync.push(state.selectedPlatformStory);
        } else if (state.selectedPlatformStoryIsNotModified && state.story.exclude_from_sync.includes(state.selectedPlatformStory)) {
            state.story.exclude_from_sync.splice(state.story.exclude_from_sync.indexOf(state.selectedPlatformStory, 1));
        }
    },
};

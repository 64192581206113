<template>
    <select v-model="selectedState">
        <optgroup v-for="(country) in countries" :key="country.id" :label="country.name">
            <option v-for="(state) in statesForCountry(country.id)" :key="state.id" :value="state[field]">{{ state.name }}</option>
        </optgroup>
    </select>
</template>

<script>
import axios from '@/axios';
import _ from 'lodash';

export default {
    props: {
        selectedCountry: {
            type:     String,
            required: false,
            default:  null,
        },
        selectedValue: {
            type:     String,
            required: false,
            default:  null,
        },
        modelValue: {
            type:     String,
            required: false,
            default:  '',
        },
        field: {
            type:     String,
            required: true,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            selected: this.selectedValue,
            states:   [],
        };
    },
    computed: {
        countries() {
            let countries = [
                {id: 1,  value: 'US',           name: 'United States of America'},
                {id: 2,  value: 'CA',           name: 'Canada'},
                {id: 3,  value: 'Argentina',    name: 'Argentina'},
                {id: 4,  value: 'Peru',         name: 'Peru'},
                {id: 5,  value: 'Chile',        name: 'Chile'},
                {id: 6,  value: 'Costa Rica',   name: 'Costa Rica'},
                {id: 7,  value: 'Brazil',       name: 'Brazil'},
                {id: 8,  value: 'Mexico',       name: 'Mexico'},
                {id: 9,  value: 'Germany',      name: 'Germany'},
                {id: 10, value: 'Switzerland',  name: 'Switzerland'},
                {id: 11, value: 'Austria',      name: 'Austria'},
                {id: 12, value: 'Poland',       name: 'Poland'},
                {id: 13, value: 'UK',           name: 'United Kingdom'},
                {id: 14, value: 'Australia',    name: 'Australia'},
                {id: 15, value: 'New Zealand',  name: 'New Zealand'},
                {id: 16, value: 'France',       name: 'France'},
                {id: 17, value: 'Colombia',     name: 'Colombia'},
                {id: 18, value: 'Honduras',     name: 'Honduras'},
                {id: 19, value: 'Nicaragua',    name: 'Nicaragua'},
                {id: 20, value: 'Saint Vincent and the Grenadines', name: 'Saint Vincent and the Grenadines'},
                {id: 21, value: 'Guatemala',    name: 'Guatemala'},
                {id: 22, value: 'Denmark',      name: 'Denmark'},
                {id: 23, value: 'Finland',      name: 'Finland'},
                {id: 24, value: 'Norway',       name: 'Norway'},
                {id: 25, value: 'Sweden',       name: 'Sweden'},
                {id: 26, value: 'Bulgaria',     name: 'Bulgaria'},
                {id: 27, value: 'Panama',       name: 'Panama'},
                {id: 28, value: 'Ecuador',      name: 'Ecuador'},
                {id: 29, value: 'El Salvador',  name: 'El Salvador'},
                {id: 30, value: 'Belgium',      name: 'Belgium'},
                {id: 31, value: 'Luxembourg',   name: 'Luxembourg'},
                {id: 32, value: 'Netherlands',  name: 'Netherlands'},
                {id: 33, value: 'Italy',        name: 'Italy'},
                {id: 34, value: 'Philippines',  name: 'Philippines'},
                {id: 35, value: 'India',        name: 'India'},
                {id: 36, value: 'Indonesia',    name: 'Indonesia'},
                {id: 37, value: 'Singapore',    name: 'Singapore'},
            ];
            let selectedCountry = countries.find(country => country.value === this.selectedCountry);
            return selectedCountry ? [selectedCountry] : countries;
        },
        selectedState: {
            get() {
                return this.modelValue === null ? this.selected : this.selectedValue;
            },
            set: function(newValue) {
                this.selected = newValue;
            },
        },
    },
    watch: {
        selected() {
            this.$emit('update:modelValue', this.selected)

            if (this.selectedCountry) {
                let country = this.countries.find(c => c.value === this.selectedCountry);

                this.$emit('selection-changed', _.find(this.states, state => state[this.field] == this.selected && state.country_id == country.id ));
            }
        },
    },
    mounted() {
        axios
            .get('/api/states')
            .then(response => {
                this.states = response.data.items;
            })
            .catch(err => {
                console.log('Error when getting states', err);
            });
    },
    methods: {
        statesForCountry(id) {
            return this.states.filter(state => state.country_id === id);
        },
    },
};
</script>

import axios from 'axios';
import qs from 'qs';

export default {
    handlers: [
        {
            getAccounts(url) {
                let accounts = [];
                let split;
                let q;
                return axios.get(url)
                    .then(async response => {
                        let responseAccounts = response?.data?.accounts || [];
                        accounts = responseAccounts.map(account => {
                            return {
                                external_id: account.id,
                                name:        account.name,
                                picture:     account.picture,
                                website:     account.website,
                            };
                        });

                        if (response?.data?.start) {
                            split = url.split('?');
                            q = qs.parse(split[1]);
                            q.start = response.data.start;
                            split[1] = qs.stringify(q);

                            return accounts.concat(await this.getAccounts(split.join('?')));
                        }

                        return accounts;
                    })
                    .catch(async error => {
                        // TypeError when we get an error from facebook, other errors mean other issues
                        if (error instanceof TypeError) {
                            split = url.split('?');
                            q = qs.parse(split[1]);
                            if (q.page_id) {
                                // We requested a bad page id if we got here and had one specified
                                return [];
                            }
                            q.limit = 50;
                            split[1] = qs.stringify(q);

                            return await axios.get(split.join('?'))
                                .then(async response => {
                                    let responseAccounts = response?.data?.accounts || [];
                                    accounts = responseAccounts.map(account => {
                                        return {
                                            external_id: account.id,
                                            name:        account.name,
                                            picture:     account.picture,
                                            website:     account.website,
                                        };
                                    });

                                    if (response?.data?.start) {
                                        split = url.split('?');
                                        q = qs.parse(split[1]);
                                        q.limit = 100;
                                        q.start = response.data.start;
                                        split[1] = qs.stringify(q);

                                        return accounts.concat(await this.getAccounts(split.join('?')));
                                    }

                                    return accounts;
                                });
                        } else {
                            throw error;
                        }
                    });
            },
        },
    ],
};

import state from '@/stores/user/state.js';
import getters from '@/stores/user/getters.js';
import actions from '@/stores/user/actions.js';
import { defineStore } from 'pinia';

export default defineStore({
    id: 'user',
    state,
    getters,
    actions,
});

<template>
    <div class="destination-logins-page">
        <connect-textarea
            name="destination-logins-header"
            class="header"
            :edit="edit"
            :focusable="!edit"
            :limit="80"
            :content="connectStore.destinationLoginsPage.header"
            @update:content="updateHeader"
            @commit="commitHeader"
        />
        <connect-textarea
            name="destiantion-logins-text"
            class="text"
            :edit="edit"
            :focusable="!edit"
            :limit="300"
            :content="connectStore.destinationLoginsPage.text"
            @update:content="updateText"
            @commit="commitText"
        />
        <template v-if="!edit || (liveConnectStore.ownerId && liveConnectStore.loadOwnerId)">
            <destination-logins :class="{editor: !edit}" v-bind="destinationLoginsProps" />
        </template>
    </div>
</template>

<script>
import ConnectTextarea from '@/components/connects/ConnectTextarea.vue';
import DestinationLogins from '@/components/platforms/DestinationLogins.vue';
import { mapStores } from 'pinia';
import { liveConnectStore, connectStore } from '@/stores/pinia.js';

export default {
    components: {
        ConnectTextarea,
        DestinationLogins,
    },
    props: {
        edit: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    data() {
        return {
            platforms: [
                {
                    id:     1,
                    name:   'Facebook',
                    active: false,
                },
                {
                    id:     3,
                    name:   'Instagram',
                    active: false,
                },
                {
                    id:     2,
                    name:   'Twitter',
                    active: false,
                },
                {
                    id:     4,
                    name:   'Google',
                    active: false,
                },
                {
                    id:     5,
                    name:   'LinkedIn',
                    active: false,
                },
            ],
        };
    },
    computed: {
        formValid() {
            // TODO: replace with code that checks for at least one linked platform
            return true;
        },
        destinationLoginsProps() {
            const platformIds = this.platforms.filter(p => p.active).map(p => p.id);

            return {
                type:        'potential',
                connectId:   this.liveConnectStore.connectId,
                id:          this.liveConnectStore.potentialId || 0,
                platformIds: platformIds,
                narrow:      true,
            };
        },
        ...mapStores(connectStore, liveConnectStore),
    },
    async mounted() {
        this.mapPlatforms();
        if (this.edit && !this.liveConnectStore.ownerId) {
            await this.liveConnectStore.createOwner();
        }
    },
    methods: {
        commitHeader(value) {
            this.connectStore.$patch({ destinationLoginsPage: { header: value }});
        },
        commitText(value) {
            this.connectStore.$patch({ destinationLoginsPage: { text: value }});
        },
        mapPlatforms() {
            this.platforms = this.platforms.map(platform => {
                this.connectStore.destinationLoginsPage.services.forEach(service => {
                    if (service.platform_id === platform.id && service.pivot.enabled === true) {
                        platform.active = true;
                    }
                });
                return platform;
            });
        },
        updateHeader(value) {
            this.connectStore.destinationLoginsPage.header = value;
        },
        updateText(value) {
            this.connectStore.destinationLoginsPage.text = value;
        },
    },
};
</script>

<style lang="scss">
@media(min-width: 950px) {
    .destination-logins-page {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .header {
            font-size: 36px;
            font-weight: 700;
            position: relative;
            width: 100%;
        }

        .text {
            font-size: 16px;
            position: relative;
            width: 100%;
        }

        .destination-logins {
            margin-top: 30px;
            width: 100%;

            &.editor {
                margin-top: 9px;
                pointer-events: none;
            }
        }
        .platform.narrow {
            height: auto;
            width: 234px;
        }
        .unauthorized-modal {
            width: 100vw;
            height: 100vh;
            left: unset;

            .modal-dialog {
                top: 100px;
            }
        }
    }
}

//inbetween phones and desktop (eg. tablets)
@media (max-width: 950px) and (min-width: 425px) {
    .destination-logins-page {
        margin-top: 70px !important;
    }
}

@media(max-width: 950px) {
    .destination-logins-page {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .header {
            font-weight: 700;
            margin-top: 20px;
        }

        .text {
            font-size: 16px;
        }
        .platform.narrow {
            height: 380px;
            width: 234px;
        }
    }
}
</style>


import parsePhoneNumber from 'libphonenumber-js';

const getRetailerViewSettings = () => {
    return [
        // Retailer info
        {
            disabled:  false,
            titleData: {
                title:       'Retailer info',
                description: '',
                id:          'retailer',
            },
            columnsData: [
                {
                    ['retailer-name']: {
                        id:           'retailer-name',
                        icon:         'building-icon',
                        label:        'RETAILER NAME',
                        type:         'text',
                        subType:      null,
                        placeholder:  'Retailer name',
                        options:      null,
                        value:        '',
                        required:     true,
                        postUpdateCb: ({ state, event }) => {
                            if (event.value) {
                                state.generalViewInputValues[event.id] = event.value;
                            } else if (event.value === '') {
                                if (state.dealership.dynamicValues.data.name == state.dealership.name || state.canDeleteDefaultFields.name) {
                                    return;
                                } else {
                                    state.canDeleteDefaultFields.name = true;
                                    state.generalViewInputValues['retailer-name'] = state.dealership.dynamicValues.data.name;
                                }
                            }
                        },
                    },
                    ['retailer-phone']: {
                        id:           'retailer-phone',
                        icon:         'building-icon',
                        label:        'PHONE',  // All caps
                        type:         'text', // types: dropdown, phone, text.
                        placeholder:  '(XXX) XXX-XXXX',
                        value:        '', // Phone format from input: tel:+5072222222
                        postUpdateCb: ({ state, event }) => {
                            if (event.value) {
                                state.generalViewInputValues[event.id] = event.value;
                            } else if (event.value === '') {
                                if (state.dealership.dynamicValues.data.phone == state.dealership.phone || state.canDeleteDefaultFields.phone) {
                                    return;
                                } else {
                                    state.canDeleteDefaultFields.phone = true;
                                    let phone = state.dealership.dynamicValues.data.phone;
                                    let phoneData = parsePhoneNumber(phone);

                                    if (phoneData) {
                                        if (phoneData.country === 'US') {
                                            phone = phoneData.formatNational();
                                        } else {
                                            phone = phoneData.formatInternational();
                                        }
                                    }

                                    state.generalViewInputValues['retailer-phone'] = phone;
                                }
                            }
                        },
                    },
                    ['default-website']: {
                        id:           'default-website',
                        icon:         'building-icon-default',
                        label:        'DEFAULT WEBSITE',
                        type:         'text',
                        subType:      null,
                        placeholder:  'Default Website',
                        options:      null,
                        value:        '',
                        postUpdateCb: ({ state, event }) => {
                            if (event.value) {
                                state.generalViewInputValues[event.id] = event.value;
                            } else if (event.value === '') {
                                if (state.dealership.dynamicValues.data.website == state.dealership.url || state.canDeleteDefaultFields.website) {
                                    return;
                                } else {
                                    state.canDeleteDefaultFields.website = true;
                                    state.generalViewInputValues['default-website'] = state.dealership.dynamicValues.data.website;
                                }
                            }
                        },
                    },
                    ['custom-website']: {
                        id:          'custom-website',
                        icon:        'building-icon-custom',
                        label:       'CUSTOM WEBSITE',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Custom Website',
                        options:     null,
                        value:       '',
                    },
                    ['enable-dynamic-field']: {
                        type:     'slot',
                        slotName: 'enable-dynamic-field',
                    },
                    ['external-retailer-id']: {
                        id:          'external-retailer-id',
                        label:       'EXTERNAL RETAILER ID',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Retailer ID',
                        options:     null,
                        value:       '',
                    },
                    ['powerchord-subdomain']: {
                        id:          'powerchord-subdomain',
                        label:       'POWERCHORD SUBDOMAIN',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Powerchord Subdomain',
                        options:     null,
                        value:       '',
                        showKey:     'show',
                    },
                },
            ],
        },
        // Retailer Address
        {
            id:        'retailer-address',
            disabled:  false,
            titleData: {
                title:       'Retailer Address',
                description: '',
            },
            columnsData: [
                {
                    ['retailer-address-1']: {
                        id:           'retailer-address-1',
                        icon:         'building-icon',
                        label:        'ADDRESS',
                        type:         'text',
                        subType:      null,
                        placeholder:  'Address 1',
                        options:      null,
                        value:        '',
                        postUpdateCb: ({ state, event }) => {
                            if (event.value) {
                                state.generalViewInputValues[event.id] = event.value;
                            } else if (event.value === '') {
                                if (state.dealership.dynamicValues.data.location.street == state.dealership.address || state.canDeleteDefaultFields.address) {
                                    return;
                                } else {
                                    state.canDeleteDefaultFields.address = true;
                                    state.generalViewInputValues['retailer-address-1'] = state.dealership.dynamicValues.data.location.street;
                                }
                            }
                        },
                    },
                    ['retailer-address-2']: {
                        id:          'retailer-address-2',
                        icon:        'building-icon',
                        label:       'ADDRESS 2',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Address 2',
                        options:     null,
                        value:       '',
                    },
                    ['retailer-city']: {
                        id:           'retailer-city',
                        icon:         'building-icon',
                        label:        'CITY',
                        type:         'text',
                        subType:      null,
                        placeholder:  'City',
                        options:      null,
                        value:        '',
                        postUpdateCb: ({ state, event }) => {
                            if (event.value) {
                                state.generalViewInputValues[event.id] = event.value;
                            } else if (event.value === '') {
                                if (state.dealership.dynamicValues.data.location.city == state.dealership.city || state.canDeleteDefaultFields.city) {
                                    return;
                                } else {
                                    state.canDeleteDefaultFields.city = true;
                                    state.generalViewInputValues['retailer-city'] = state.dealership.dynamicValues.data.location.city;
                                }
                            }
                        },
                    },
                    ['retailer-state-province-and-zip-code']: [
                        {
                            id:          'retailer-state-province',
                            icon:        'building-icon',
                            label:       'STATE/PROVINCE',
                            type:        'dropdown',
                            subType:     'single',
                            placeholder: 'State/Province',
                            options:     [],
                            value:       '',
                        },
                        {
                            id:           'retailer-zip-code',
                            icon:         'building-icon',
                            label:        'ZIP CODE',
                            type:         'text',
                            subType:      null,
                            placeholder:  'Zip Code',
                            options:      null,
                            value:        '',
                            postUpdateCb: ({ state, event }) => {
                                if (event.value) {
                                    state.generalViewInputValues[event.id] = event.value;
                                } else if (event.value === '') {
                                    if (state.dealership.dynamicValues.data.location.zip == state.dealership.zip || state.canDeleteDefaultFields.zip) {
                                        return;
                                    } else {
                                        state.canDeleteDefaultFields.zip = true;
                                        state.generalViewInputValues['retailer-zip-code'] = state.dealership.dynamicValues.data.location.zip;
                                    }
                                }
                            },
                        },
                    ],
                    ['retailer-country']: {
                        id:           'retailer-country',
                        label:        'COUNTRY',
                        type:         'dropdown',
                        subType:      'single',
                        placeholder:  'Country',
                        options:      [],
                        value:        [],
                        postUpdateCb: ({ state, event }) => {
                            const countryId = event.value;
                            const filteredStates = state.states.filter(country => country.country_id === countryId);
                            state.retailerGeneralViewSettings[1].columnsData[0]['retailer-state-province-and-zip-code'][0].options = filteredStates;
                        },
                    },
                    ['retailer-timezone']: {
                        id:          'retailer-timezone',
                        label:       'TIME ZONE',
                        type:        'dropdown',
                        subType:     'single',
                        placeholder: 'Time Zone',
                        options:     [], // Array of objects for filter dropdown with name and id
                        value:       [], // Array of ids
                    },
                },
            ],
        },
        {
            id:        'facebook-account',
            showKey:   'is_admin',
            disabled:  false,
            titleData: {
                title:       'Facebook Account',
                description: '',
            },
            columnsData: [
                {
                    ['manually-linked']: {
                        id:    'manually-linked',
                        label: 'Manually Linked',
                        type:  'title',
                    },
                    ['facebook-page-id']: {
                        id:              'facebook-page-id',
                        label:           'FACEBOOK-PAGE-ID',
                        type:            'text',
                        subType:         null,
                        placeholder:     'XXXXXXXXXX',
                        options:         null,
                        value:           '',
                        enabledKey:      'is_admin',
                        titleSlotName:   'page-token-found',
                        titleExtraStyle: 'display: flex; justify-content: space-between; width: 100%; text-transform: uppercase;',
                        postUpdateCb:    ({ state, event }) => {
                            state.dealership.lister.facebook_page_id = event.value;
                        },
                    },
                },
            ],
        },


    ];
};

export default getRetailerViewSettings();

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import _ from 'lodash';

import Platform from '@/platform.js';
import PlatformStoryConfig from '@/platform_story_config.js';

const PHOTO_TYPE_ID = 1;
const VIDEO_TYPE_ID = 2;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

export default {
    assetIsValidToBeSelected: (_, getters) => ({ platformId, assetId }) => {
        const platformStory = getters.getPlatformStory({ platformId });
        const asset = platformStory.storyAssets.find(asset => asset.id === assetId);

        // There are not any storyAssets with this `assetId`
        if (!asset) {
            return false;
        }

        // Validate the asset based on the type.
        if (asset.type === PHOTO_TYPE_ID) {
            return getters.photoIsValidToBeSelected({ platformId, photo: asset });
        } else if (asset.type === VIDEO_TYPE_ID) {
            return getters.videoIsValidToBeSelected({ platformId, video: asset });
        } else {
            return false;
        }
    },
    photoIsValidToBeSelected: (_, getters) => ({ platformId, photo }) => {
        const platformStoriesConfig = getters.getPlatformStoryConfig({ platformId: platformId });
        let mediaConfig = platformStoriesConfig.media();
        if (mediaConfig.photo.resolution) {
            let res = mediaConfig.photo.resolution;
            if (res.min) {
                if (res.min.width && photo.metadata.resolution.width < res.min.width) {
                    return false;
                }
                if (res.min.height && photo.metadata.resolution.height < res.min.height) {
                    return false;
                }
            }
            if (res.max) {
                if (res.max.width && photo.metadata.resolution.width > res.max.width) {
                    return false;
                }
                if (res.max.height && photo.metadata.resolution.height > res.max.height) {
                    return false;
                }
            }
        }
        if (mediaConfig.photo.aspectRatio) {
            let asp = mediaConfig.photo.aspectRatio;
            if (asp.min && (photo.metadata.resolution.width / photo.metadata.resolution.height) < asp.min) {
                return false;
            }
            if (asp.max && (photo.metadata.resolution.width / photo.metadata.resolution.height) > asp.max) {
                return false;
            }
        }
        if (mediaConfig.photo.size) {
            if (photo.metadata.format.size > mediaConfig.photo.size) {
                return false;
            }
        }
        if (mediaConfig.photo.formats) {
            let match = photo.metadata.format.format_name
                .split(',')
                .map(format => format.split('/').pop())
                .filter(format => mediaConfig.photo.formats.includes(format))
                .length;

            if (!match) {
                return false;
            }
        }
        return true;
    },
    videoIsValidToBeSelected: (_, getters) => ({ platformId, video }) => {
        const platformStoriesConfig = getters.getPlatformStoryConfig({ platformId: platformId });
        let mediaConfig = platformStoriesConfig.media();
        if (mediaConfig.video.resolution) {
            let res = mediaConfig.video.resolution;
            if (res.min) {
                if (res.min.width && video.metadata.resolution.width < res.min.width) {
                    return false;
                }
                if (res.min.height && video.metadata.resolution.height < res.min.height) {
                    return false;
                }
            }
            if (res.max) {
                if (res.max.width && video.metadata.resolution.width > res.max.width) {
                    return false;
                }
                if (res.max.height && video.metadata.resolution.height > res.max.height) {
                    return false;
                }
            }
        }
        if (mediaConfig.video.aspectRatio) {
            let asp = mediaConfig.video.aspectRatio;
            if (asp.min && (video.metadata.resolution.width / video.metadata.resolution.height) < asp.min) {
                return false;
            }
            if (asp.max && (video.metadata.resolution.width / video.metadata.resolution.height) > asp.max) {
                return false;
            }
        }
        if (mediaConfig.video.size) {
            if (video.metadata.format.size > mediaConfig.video.size) {
                return false;
            }
        }
        if (mediaConfig.video.formats) {
            let match = video.metadata.format.format_name
                .split(',')
                .map(format => format.split('/').pop())
                .filter(format => mediaConfig.video.formats.includes(format))
                .length;

            if (!match) {
                return false;
            }
        }
        if (mediaConfig.video.codecs) {
            let match = video.metadata.streams
                .map(stream => stream.codec_name)
                .every(codec => mediaConfig.video.codecs.includes(codec));

            if (!match) {
                return false;
            }
        }
        if (mediaConfig.video.duration) {
            let duration = mediaConfig.video.duration;
            if (duration.min || duration.max) {
                if (duration.min && video.metadata.duration < duration.min) {
                    return false;
                }
                if (duration.max && video.metadata.duration > duration.max) {
                    return false;
                }
            } else {
                if (video.metadata.duration > duration) {
                    return false;
                }
            }
        }
        if (mediaConfig.video.bitRate) {
            if (video.metadata.format.bit_rate > mediaConfig.video.bitRate) {
                return false;
            }
        }
        if (mediaConfig.video.framerate) {
            let framerate = mediaConfig.video.framerate;
            if (framerate.min && video.metadata.framerate < framerate.min) {
                return false;
            }
            if (framerate.max && video.metadata.framerate > framerate.max) {
                return false;
            }
        }
        return true;
    },
    assetsHasPhoto: (_, getters) => {
        const selectedPlatformId = getters.selectedPlatformId;
        if (!selectedPlatformId) {
            return false;
        }
        return getters.storyAssets({ platformId: selectedPlatformId }).filter(sa => sa.type == 1).length > 0;
    },
    assetsHasVideo: (_, getters) => {
        const selectedPlatformId = getters.selectedPlatformId;
        if (!selectedPlatformId) {
            return false;
        }
        return getters.storyAssets({ platformId: selectedPlatformId }).filter(sa => sa.type == 2).length > 0;
    },
    campaignOptions: ({ campaignOptions }) => {
        return campaignOptions;
    },
    inactiveCampaignOptions: ({ inactiveCampaignOptions }) => {
        return inactiveCampaignOptions;
    },
    canDisplayAllPlatformStory: ({ story, isOriginalState }, getters) => {
        if (getters.platformStoriesWithoutAll.length === 1 || isOriginalState) {
            return true;
        } else if (['TEXT', 'URL'].includes(story.post_type)) {
            return getters.platformStoriesHaveEqualMessages;
        } else if (story.post_type === 'PHOTO') {
            return (
                getters.platformStoriesHaveEqualMessages && getters.platformStoriesHaveEqualPhotos
            );
        } else if (story.post_type === 'VIDEO') {
            return (
                getters.platformStoriesHaveEqualMessages && getters.platformStoriesHaveEqualVideos
            );
        }
        return false;
    },
    canDisplayPlatformOptionsGbp: (_, getters) => {
        return getters.selectedPlatformId == Platform.GBP;
    },
    canDisplayDistributeByRetailerRadioButtons({ currentOemManager }) {
        return currentOemManager && currentOemManager.has_distribute_by_retailer && !currentOemManager.is_parent;
    },
    classifiers:           ({ story }) => story.classifiers,
    currentArrowPlacement: ({ selectedPlatformStory, populatedInputsForSelectedPlatformStory, destinationsWithArrows }) => {
        if (Object.values(destinationsWithArrows).some(d => d)) {
            return 0;
        } else if (!selectedPlatformStory) {
            return 1;
        }
        return (Object.values(populatedInputsForSelectedPlatformStory).findIndex(p => p === false)) + 1;
    },
    contentProvider: ({ story }) => {
        return story.source ? story.source.content_provider : '';
    },
    selectedDate: ({ story }) => {
        const formatDate = (_date) => {
            // Gets month of the year (0-11) according to local time
            return (_date.getUTCMonth() + 1) + '/' + _date.getUTCDate() + '/' + _date.getUTCFullYear();
        };
        const today = new Date().toDateString();
        if (story.scheduled_at === today) {
            let date = new Date(story.scheduled_at);
            date.setDate(date.getDate() + 1);
            return formatDate(new Date(date));
        }
        let date = new Date(story.scheduled_at);
        return formatDate(new Date(date));
    },
    dateRange: ({ story }) => {
        const formatDateRage = (_date) => {
            // Gets month of the year (0-11) according to local time
            return (_date.getMonth() + 1) + '/' + _date.getDate() + '/' + _date.getFullYear();
        };
        if (!story.expires_at) {
            const today = new Date();
            const endDate = new Date(new Date().setMonth(today.getMonth() + 6));
            return {
                start: formatDateRage(today),
                end:   formatDateRage(endDate), // Six months from today
            };
        }
        let endDate = story.expires_at ? story.expires_at : null;
        let startDate = story.published_at;
        return { end: formatDateRage(new Date(endDate)), start: formatDateRage(new Date(startDate)) };
    },
    disablePreview: ({ populatedInputs }) => (storyPostType) => {
        if (!populatedInputs || !storyPostType) {
            return {};
        }
        let disablePreview = {};
        if (storyPostType === 'TEXT') {
            populatedInputs.map(pi =>
                pi.platformId === 3 ?
                    disablePreview[Platform[pi.platformId]] = true :
                    disablePreview[Platform[pi.platformId]] = !pi[1]);
        } else if (storyPostType === 'URL') {
            populatedInputs.map(pi =>
                Number.isInteger(pi.platformId) && ![1, 5].includes(pi.platformId) ?
                    disablePreview[Platform[pi.platformId]] = true :
                    disablePreview[Platform[pi.platformId]] = !(pi[1] && pi[2]));
        } else {
            populatedInputs.map(pi => disablePreview[Platform[pi.platformId]] = !(pi[1] && pi[2]));
        }
        disablePreview.disablePreviewModal = !(Object.values(disablePreview).includes(false));
        return disablePreview;
    },
    enabledPlatformStories: ({story}) => {
        const url_platforms = [Platform.FACEBOOK, Platform.LINKEDIN];
        const text_platforms = [Platform.FACEBOOK, Platform.GBP, Platform.TWITTER, Platform.LINKEDIN];
        const photo_platforms = [Platform.FACEBOOK, Platform.GBP, Platform.INSTAGRAM, Platform.TWITTER, Platform.LINKEDIN];
        const default_platforms = [Platform.FACEBOOK, Platform.INSTAGRAM, Platform.LINKEDIN];

        let platforms = [];

        if (story.post_type === 'URL') {
            platforms = url_platforms;
        } else if (story.post_type === 'TEXT') {
            platforms = text_platforms;
        } else if (story.post_type === 'PHOTO') {
            platforms = photo_platforms;
        } else {
            platforms = default_platforms;
        }

        if (story.scheduled_at) {
            return platforms.filter((el) => story.platformCampaignDisabled && story.platformCampaignDisabled[0] && !story.platformCampaignDisabled[0].includes(el));
        } else {
            return platforms;
        }
    },
    getCampaignId: ({ campaignOptions }) => ({ id }) => {
        const campaignObj = campaignOptions.find(campaign => campaign.id === id);
        if (!campaignObj) {
            return null;
        }
        const campaignId = campaignObj.id;
        return campaignId;
    },
    getScheduleAt: ({ timeOptions }) => ({ id, scheduled_date, useUTC=true }) => {
        const timeObj = timeOptions.find(option => option.id === id);
        const localDate = dayjs(scheduled_date);
        const localTimezone = dayjs.tz.guess();
        const setDate = localDate.set('hour', timeObj ? timeObj.value : 0).set('minute', 0).set('second', 0);
        return useUTC ? setDate.utc().format() : setDate.tz(localTimezone).format();
    },
    getStory: ({ story }) => () => {
        return story;
    },
    isScheduledTimeValid: ({ timeOptions }) => ({ id, scheduled_date }) => {
        const timeObj = timeOptions.find(option => option.id === id);
        const localDate = dayjs(scheduled_date);
        const setDate = localDate.set('hour', timeObj ? timeObj.value : 0).set('minute', 0).set('second', 0);
        return dayjs() < setDate;
    },
    isStoryEditDisabled: ({ story }, getters) => {
        if (story.id !== null && story.scheduled_at !== null) {
            if (story.scheduled_time == null) {
                return false;
            }
            let scheduled_at = dayjs(getters.getScheduleAt({
                id:             story.scheduled_time,
                scheduled_date: story.scheduled_date,
            }));

            return scheduled_at <= dayjs();
        }

        return false;
    },
    getScheduledTimeFromScheduledAt: ({ timeOptions }) => ({ scheduled_at }) => {
        const localTimezone = dayjs.tz.guess();
        const date = dayjs(scheduled_at).tz(localTimezone);
        const hour = parseInt(date.format('HH'));
        const filterTime = timeOptions.find(times => times.value === hour || times.value + 1 === hour);
        return filterTime.id;
    },
    getScheduledDateFromScheduledAt: () => ({ scheduled_at }) => {
        const localTimezone = dayjs.tz.guess();
        const date = dayjs(scheduled_at).tz(localTimezone);
        return dayjs(date).format('YYYY-MM-DD');
    },
    getPlatformStory: ({ story }) => ({ platformId }) => {
        return story.platformStories.find(p => p.platformId == platformId);
    },
    getPlatformStoryConfig: ({ platformStoriesConfigs }) => ({ platformId }) => {
        return platformStoriesConfigs[platformId] ?? null;
    },
    getPlatformStoryErrors: ({ platformStoriesErrors }) => ({ platformId }) => {
        return platformStoriesErrors[platformId] ?? null;
    },
    getPopulatedInput: ({ populatedInputs }) => ({ platformId }) => {
        return populatedInputs.find(pi => pi.platformId == platformId) || {};
    },
    hasOrphanClassifiers: ({ selectedBuffers, story }, getters, rootState, rootGetters) => {
        if (getters.classifiers.length === 0 || story.brandManagers.length === 0) {
            return true;
        }
        for (const { bufferId } of selectedBuffers) {
            const tipTier = rootGetters['tiers/getLeafTier'](bufferId);
            if (!rootGetters['tiers/getSelectedClassifiersByTier'](bufferId, tipTier).length) {
                return true;
            }
            for (var i = 0; i <= tipTier; i++) {
                if (rootGetters['tiers/getOrphanPaths'](bufferId).findIndex(o => o.tier === i - 1) >= 0) {
                    return true;
                }
            }
        }
        return false;
    },
    hasErrors: (_, getters) => () => {
        return getters.platformStoriesWithoutAll
            .some(ps => {
                return getters.platformHasErrors({ platformId: ps.platformId });
            });
    },
    selectedPlatformHasCharErrors: ({ selectedPlatformStory, showTextAreaError }, getters)  => {
        return getters.hasCharErrors({ platformId: selectedPlatformStory, validateDynamicFieldError: showTextAreaError });
    },
    hasCharErrors: (_, getters) => ({ platformId }) => {
        const selectedPlatformStoryErrors = getters.getPlatformStoryErrors({ platformId });
        if (!selectedPlatformStoryErrors) {
            return false;
        }
        if (!selectedPlatformStoryErrors.char) {
            return false;
        }
        if (!getters.hasCharLimitTextError({ platformId })) {
            return false;
        }
        return selectedPlatformStoryErrors.char.limitText
            || selectedPlatformStoryErrors.char.link
            || selectedPlatformStoryErrors.char.emoji
            || selectedPlatformStoryErrors.char.hashtag
            || selectedPlatformStoryErrors.char.mention
            || selectedPlatformStoryErrors.char.min
            || selectedPlatformStoryErrors.char.brandRequiresDynamic;
    },
    inputNumbersForContentInfo: ({ story }) => {
        // We want to determine which numbers needs to be displayed on a modal, if
        // post_type is TEXT, then, there is one less input for the user to fill.
        return story.post_type === 'TEXT' ? [1] : [1, 2];
    },
    inputNumbersForContentAvailabilities: ({ story }) => {
        // We want to determine which numbers needs to be displayed on a modal, if
        // post_type is TEXT, then, there is one less input for the user to fill.
        return story.post_type === 'TEXT' ? [2] : [3];
    },
    inputNumbersForRetailerEligibility: ({ story }) => {
        // We want to determine which numbers needs to be displayed on a modal, if
        // post_type is TEXT, then, there is one less input for the user to fill.
        // Using number 99 for inputs with no validation (always checked)
        if (story.post_type === 'TEXT') {
            return story.scheduled_at === null ? [3, 99, 4] : [4, 99, 5];
        }
        return story.scheduled_at === null ? [4, 99, 5] : [5, 99, 6];
    },
    isFilledCompletely: ({ story }) => {
        return story.is_filled_completely;
    },
    isDistributeByOwner: ({ story }) => {
        return story.is_distribute_by_owner;
    },
    isLoading: ({ loading }) => {
        return loading;
    },
    languageOptions: ({ languageOptions }) => {
        return languageOptions;
    },
    dynamicLength: ({ dynamicLength }) => {
        return dynamicLength;
    },
    mediaErrors: ({ platformStoriesErrors }) => ({ platformId }) => {
        if (!platformId || !platformStoriesErrors || !platformStoriesErrors[platformId] || !platformStoriesErrors[platformId].media) {
            return [];
        }
        let mixed = Object.values(platformStoriesErrors[platformId].media.mixed);
        let video = Object.values(platformStoriesErrors[platformId].media.video);
        let photo = Object.values(platformStoriesErrors[platformId].media.photo);
        let all = mixed.concat(video).concat(photo);
        return all.flat().filter(e => !!e);
    },
    orderedAvailablePlatforms: ({ availablePlatforms }) => {
        if (!availablePlatforms || !availablePlatforms.length) {
            return [];
        }
        const priorities = {
            [Platform.FACEBOOK]:  0,
            [Platform.INSTAGRAM]: 1,
            [Platform.GBP]:       2,
            [Platform.TWITTER]:   3,
            [Platform.LINKEDIN]:  4,
        };
        return availablePlatforms.sort((ps1, ps2)=> priorities[ps1] - priorities[ps2]);
    },
    platformHasErrors: ({ populatedInputs, story }, getters) => ({ platformId }) => {
        const selectedPlatformStoryErrors = getters.getPlatformStoryErrors({ platformId });
        const platformStory = getters.getPlatformStory({ platformId });
        if (!selectedPlatformStoryErrors) {
            return false;
        }
        if (!platformStory.enabled) {
            return false;
        }
        if (!getters.enabledPlatformStories.includes(platformId)) {
            return false;
        }
        if (!populatedInputs.length) {
            return false;
        }
        const hasCharErrors = getters.hasCharErrors({ platformId });
        const hasMediaErrors = getters.mediaErrors({ platformId }).length > 0;
        const hasUnpopulatedMediaErrors = Object.values(
            populatedInputs.find(p => p.platformId === platformId))
            .slice(0, story.post_type === 'TEXT' ? 1 : 2)
            .includes(false);

        return (hasMediaErrors || hasCharErrors || hasUnpopulatedMediaErrors);
    },
    platformOptionsGbpCtaPhoneChecked: ({ story }) => {
        const gbpPlatformStory = story.platformStories.find(ps => ps.platformId == Platform.GBP);
        if (!gbpPlatformStory) {
            return false;
        }
        return PlatformStoryConfig[Platform.GBP].misc.cta.load(gbpPlatformStory.misc.cta);
    },
    platformOptionsGbpShow: ({ enabledPlatforms, story }, getters) => {
        return (
            enabledPlatforms.includes(Platform.GBP)
            || getters.selectedPlatformId == Platform.GBP
            || story.post_type === 'URL'
        );
    },
    platformStories: ({ story }) => {
        return story.platformStories;
    },
    platformStoriesToEnable: (_, getters) => {
        const priorities = {
            [Platform.FACEBOOK]:  0,
            [Platform.INSTAGRAM]: 1,
            [Platform.GBP]:       2,
            [Platform.TWITTER]:   3,
            [Platform.LINKEDIN]:  4,
        };
        const platformDisable = getters.platformStorieDisabled.map(item => {
            let element = {
                platformId:         item,
                enabledToBeClicked: false,
            };
            return element;
        });
        const platformEnable = getters.platformStoriesWithoutAll.map(item => {
            item.enabledToBeClicked = true;
            return item;
        });
        let allPlatform = [
            ...platformDisable,
            ...platformEnable,
        ];
        return allPlatform.sort((ps1, ps2)=> priorities[ps1.platformId] - priorities[ps2.platformId]);
    },
    platformStorieDisabled: (_, getters) => {
        const enabledPlatforms = getters.platformStoriesWithoutAll.map(ps => ps.platformId);
        return getters.orderedAvailablePlatforms.filter(p => !enabledPlatforms.includes(p));
    },
    platformStoriesWithoutAll: ({ story }) => {
        const priorities = {
            [Platform.FACEBOOK]:  0,
            [Platform.INSTAGRAM]: 1,
            [Platform.GBP]:       2,
            [Platform.TWITTER]:   3,
            [Platform.LINKEDIN]:  4,
        };
        const platformStories = story.platformStories.slice(0, -1);
        return platformStories.sort((ps1, ps2)=> priorities[ps1.platformId] - priorities[ps2.platformId]);
    },
    platformStoryAll: ({ story }) => {
        return story.platformStories[story.platformStories.length - 1];
    },
    platformStoriesHaveEqualAssetsOfType: (_, getters) => (mediaType) => {
        const availableAssets = getters.platformStoriesWithoutAll.map(ps => {
            return ps.storyAssets.filter(sa => sa.type == mediaType).map(sap => sap.id);
        });
        const allAvailableAssetsAreEqual = availableAssets.every((aa, i, availableAssets) => String(aa) === String(availableAssets[0]) || aa.length === 0);
        const allSelectedAssetsAreEqual = getters.platformStoriesHaveEqualSelectedAssets(availableAssets);
        return allSelectedAssetsAreEqual && allAvailableAssetsAreEqual;
    },
    platformStoriesHaveEqualSelectedAssets: ({ story }) => (availableAssets) => {
        const uniqueAvailableAssets = [...new Set(availableAssets.flat())];
        const selectedAssets = story.platformStories
            .map(({ selectedAssets }) => String(selectedAssets.filter(sa => uniqueAvailableAssets.includes(sa))));
        return selectedAssets.every((sa, _, selectedAssets) => sa === selectedAssets[0]);
    },
    platformStoriesHaveEqualMessages: ({ enabledPlatforms }, getters) => {
        return getters.platformStoriesWithoutAll
            .filter(ps => enabledPlatforms.includes(ps.platformId))
            .map(ps => ps.message)
            .every((ps, _, platformStories) => ps === platformStories[0]);
    },
    platformStoriesHaveEqualPhotos: (_, getters) => {
        return getters.platformStoriesHaveEqualAssetsOfType(PHOTO_TYPE_ID);
    },
    platformStoriesHaveEqualVideos: (_, getters) => {
        return getters.platformStoriesHaveEqualAssetsOfType(VIDEO_TYPE_ID);
    },
    platformStorieshasMediaErrors: (_, getters) =>{
        let obj = getters.platformStoriesMediaErrors;
        let filterMediaErrors = Object.keys(obj).filter(k=>obj[k] != '');
        return filterMediaErrors.length > 0 ;
    },
    platformStoriesMediaErrors: (_, getters) => {
        const mediaErrors = {};
        getters.platformStoriesWithoutAll.forEach(({ platformId }) => {
            mediaErrors[platformId] = getters.mediaErrors({ platformId });
        });
        return mediaErrors;
    },
    platformStoriesWithMediaErrors: (_, getters) => {
        const priorities = {
            Facebook:  0,
            Instagram: 1,
            Google:    2,
            Twitter:   3,
            Linkedin:  4,
        };
        const selectedPlatformId = getters.selectedPlatformId;
        let media = getters.platformStoriesMediaErrors;
        let arraymedia = [];
        Object.keys(media).map(platformId => {
            media[platformId].map(m => {
                if (getters.platformStories.find(ps => ps.platformId == platformId).enabled) {
                    if (typeof m == 'object' ) {
                        arraymedia.push(m.text);
                    } else {
                        arraymedia.push(m);
                    }
                }
            });
        });
        return selectedPlatformId === 'all' && arraymedia.length > 0 ?
            arraymedia.sort((ps1, ps2) => {
                return priorities[ps1.split(' ')[0]] - priorities[ps2.split(' ')[0]];
            }) :
            false;
    },
    mediaErrorFirstPlatformId: (_, getters) => {
        const platforms = {
            Facebook:  1,
            Instagram: 3,
            Google:    4,
            Twitter:   2,
            Linkedin:  5,
        };

        let arr = [];
        Object.values(platforms).forEach(platform => {
            arr.push([platform, getters.platformStoriesMediaErrors[platform], getters.platformHasErrors({platformId: platform})]);
        });
        const firstPlatformWithError = arr ? arr.find(p => p[1] && (p[1].length || p[2])) : null;
        return firstPlatformWithError ? Object.values(platforms).find(p => p == (firstPlatformWithError[0])) : null;
    },
    platformStoryForPlatformId: ({ story }) => ({ platformId }) => {
        return story.platformStories.find(ps => ps.platformId === platformId);
    },
    regionsOptions: ({ regions }) => {
        return regions;
    },
    retailersOptions: ({ retailersOptions }) => {
        return retailersOptions;
    },
    selectedRegions: ({ story }) => {
        return story.regions;
    },
    selectedRetailers: ({ story }) => {
        return story.distribute_by_owner_ids;
    },
    selectedAssets: (_, getters) => ({ platformId }) => {
        if (!platformId) {
            return [];
        }
        const platformStory = getters.getPlatformStory({ platformId });
        return platformStory.storyAssets.filter(s => platformStory.selectedAssets.includes(s.id));
    },
    selectedAssetsMediaErrors: (_, getters) => {
        return getters.mediaErrors({ platformId: getters.selectedPlatformId });
    },
    selectedAssetsMediaErrorsGeneric: (_, getters) => {
        return getters.selectedAssetsMediaErrors.filter(error => !(error instanceof Object));
    },
    selectedCampaign: ({ story, campaignOptions, inactiveCampaignOptions }) => {
        if (!story || !campaignOptions) {
            return;
        }
        if (typeof story.campaign_id === 'number') {
            let activeFilterCampaign = campaignOptions.find(campaign => story.campaign_id === campaign.id);
            if (typeof activeFilterCampaign === 'undefined') {
                let inactiveFilterCampaign = inactiveCampaignOptions.find(campaign => story.campaign_id === campaign.id);
                if (typeof inactiveFilterCampaign === 'undefined') {
                    return '';
                }
                campaignOptions.push({
                    color:       inactiveFilterCampaign.color,
                    end:         inactiveFilterCampaign.end,
                    id:          inactiveFilterCampaign.id,
                    name:        inactiveFilterCampaign.name,
                    start:       inactiveFilterCampaign.start,
                    story_count: inactiveFilterCampaign.story_count,
                    title:       inactiveFilterCampaign.title,
                });
                return inactiveFilterCampaign.id;
            }
            return activeFilterCampaign.id;
        }
        return story.selectedCampaign;
    },
    selectedCharLimitText: ({ selectedPlatformStory }, getters) => {
        return getters.hasCharLimitTextError({ platformId: selectedPlatformStory });
    },
    hasCharLimitTextError: (_, getters) => ({ platformId }) => {
        const selectedPlatformStoryErrors = getters.getPlatformStoryErrors({ platformId });
        return selectedPlatformStoryErrors && selectedPlatformStoryErrors.char ? (selectedPlatformStoryErrors.char.limitText || '') : '';
    },
    selectedCampaignDate: ({ story }) => {
        if (!story.campaigns[0]) {
            return null;
        }
        return dayjs(dayjs.utc(story.campaigns[0].scheduled_at)).local().format('MM/DD/YYYY hh:mm A');
    },
    selectedCampaignTitle: ({ story, campaignOptions }) => {
        if (!story.campaigns[0]) {
            return null;
        }
        const campaign = campaignOptions.find(c => c.external_id === parseInt(story.campaigns[0].id));
        return campaign ? campaign.title : null;
    },
    selectedClassifiers: ({ story }) => {
        return story.classifiers;
    },
    selectedLanguages: ({ story, languageOptions }) => {
        if (typeof story.languages[0] === 'string') {
            let filterLanguages = languageOptions.filter(item => story.languages.includes(item.name));
            return filterLanguages.map(item => item.id);
        }
        return story.languages;
    },
    selectedPhotoAssets: (_, getters) => ({ platformId }) => {
        let selected = getters.selectedAssets({ platformId }) || [];
        return selected.filter(s => s.type == 1);
    },
    selectedPlatformId({ selectedPlatform }) {
        return Object.keys(selectedPlatform).find(key => selectedPlatform[key] === true);
    },
    scheduled_time: ({ timeOptions, story }) => (payload) => {
        const convertScheduledTime = (scheduledAt) => {
            let time = scheduledAt.slice(11, 13);
            let hour = parseInt(time) % 12;
            let convertedHour = hour.toString().length === 1 ? `0${hour}` : hour;
            let filterTime = timeOptions.find(times => times.name.toString().slice(0, 2) === convertedHour.toString());
            if (typeof filterTime === 'undefined') {
                return null;
            }
            return filterTime.id;
        };

        if (payload) {
            return convertScheduledTime(payload.scheduled_at);
        }
        if (story.scheduled_time) {
            return story.scheduled_time;
        }
        if (story.scheduled_at) {
            return convertScheduledTime(story.scheduled_at);
        }

        return null;
    },
    selectedVideoAssets: (_, getters) => ({ platformId }) => {
        let selected = getters.selectedAssets({ platformId }) || [];
        return selected.filter(s => s.type == 2);
    },
    sourceName: ({ story }) => {
        return story.source ? story.source.name : '';
    },
    storyAssets: (_, getters) => ({ platformId }) => {
        if (!platformId) {
            return [];
        }
        const platformStory = getters.getPlatformStory({ platformId });
        return platformStory ? platformStory.storyAssets : [];
    },
    calculateStoryStatus: ({ story, oemIsParent }) => {
        if (story.approval === 'denied') {
            return 'denied';
        } else if (story.archived_at) {
            return 'archived';
        } else if (dayjs(new Date()).isAfter(dayjs(story.expires_at).add(1, 'days'))) {
            return 'expired';
        } else if (story.approval === 'approved' || story.approval === 'approved-for-distribution') {
            return 'approved-for-distribution';
        } else if (story.approval === 'pending-curation' || (oemIsParent && story.is_copied === false && story.approval === null)) {
            return 'pending-curation';
        } else if (story.approval === 'info-required' || (story.is_filled_completely === false && story.approval === null)) {
            return 'info-required';
        } else if (story.approval === 'ready-for-approval' || story.is_filled_completely === true && story.approval === null) {
            return 'ready-for-approval';
        }
        return null;
    },
    statusOptions: ({ statusOptions }, getters) => {
        const titleBucket = getters.storyStatusHumanized({ status: getters.calculateStoryStatus });
        const optionIndex = statusOptions.findIndex(ps => ps.text === titleBucket);
        const option = statusOptions.find(ps => ps.text === titleBucket);
        const newStatusOptions = _.cloneDeep(statusOptions);
        newStatusOptions.splice(optionIndex, 1);

        if (option) {
            newStatusOptions.push(option);
        }

        return newStatusOptions;
    },
    storyStatusHumanized: () => ({ status }) => {
        const STATUS_MAP = {
            'info-required':             'Info Required',
            'pending-curation':          'Pending Curation',
            'ready-for-approval':        'Ready For Approval',
            'approved-for-distribution': 'Approved For Distribution',
            'denied':                    'Denied',
            'expired':                   'Expired',
            'archived':                  'Archived',
            'scheduled':                 'Scheduled',
            'move-content':              'Move Content',
        };
        return STATUS_MAP[status];
    },
    statusButtonTitle: ({ storyStatus }, getters) => {
        return {text: getters.storyStatusHumanized({ status: storyStatus })};
    },
    timeOptions: ({ timeOptions }) => {
        return timeOptions;
    },
    enabledPlatformsToPost: (_, getters) => {
        return getters.platformStoriesToEnable.filter(ps => ps.enabled);
    },
    isFilledCompletelyAllPlatform: ({ isFilledCompletelyAllPlatform }) => {
        return isFilledCompletelyAllPlatform;
    },
    facebookHasMentions: ({ story }) => {
        const facebookPlatformStory = story.platformStories.find(platform => platform.platformId === Platform.FACEBOOK);
        if (!facebookPlatformStory) {
            return false;
        }
        if (facebookPlatformStory.message.search(/@\[([0-9]+)\]+/g) >= 0) {
            return true;
        }
        return false;
    },
    getRegexCta: ({ regex }) => ( key ) => {
        return regex.cta[key] ?? null;
    },
    removeUnwantedHTMLTagsFromCTA: () => ({ text = '' }) => {
        let temp = text.replace(/<pill/g, 'PILL_START').replace(/<\/pill>/g, 'PILL_END');
        temp = temp.replace(/<\/?[A-Za-z ].*?>/g, '')
            .replace(/<--/g, '')
            .replace(/-->/g, '')
            .replace(/<!--/g, '');
        return temp.replace(/PILL_START/g, '<pill').replace(/PILL_END/g, '</pill>');
    },
    thumbnail: ({ story }) => story.platformStories.filter(ps => ps.enabled == true)[0]?.storyAssets[0]?.url,
};

import axios from 'axios';
import qs from 'qs';

export default {
    handlers: [
        {
            getAccounts(url) {
                let accounts = [];
                return axios.get(url)
                    .then(async response => {
                        let responseAccounts = response?.data?.accounts?.accounts || [];
                        accounts = responseAccounts.map(account => {
                            return {
                                external_id: account.name,
                                name:        account.accountName,
                            };
                        });

                        if (response?.data?.accounts?.nextPageToken) {
                            let split = url.split('?');
                            let q = qs.parse(split[1]);
                            q.pageToken = response.data.accounts.nextPageToken;
                            split[1] = qs.stringify(q);
                            let nextUrl = split.join('?');
                            return accounts.concat(await this.getAccounts(nextUrl));
                        }

                        return accounts;
                    });
            },
        },
        {
            getAccounts(url, selected) {
                let locations = [];
                let split = url.split('?');
                let q = qs.parse(split[1]);
                q.account = selected.external_id;
                split[1] = qs.stringify(q);
                url = split.join('?');

                return axios.get(url)
                    .then(async response => {
                        let responseLocations = response?.data?.locations?.locations || [];
                        locations = responseLocations.map(location => {
                            return {
                                external_id: `${selected.external_id}/${location.name}`,
                                name:        location.title,
                                website:     location.websiteUri,
                                phone:       location.phoneNumbers.primaryPhone,
                                address:     location.storefrontAddress?.addressLines[0] || '',
                            };
                        });

                        if (response?.data?.locations?.nextPageToken) {
                            q.pageToken = response.data.locations.nextPageToken;
                            split[1] = qs.stringify(q);
                            let nextUrl = split.join('?');
                            return locations.concat(await this.getAccounts(nextUrl, selected));
                        }

                        return locations;
                    });
            },
        },
    ],
};

<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="gear-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 10.375C15 10.1981 14.9041 10.0272 14.7473 9.93531L13.3116 9.09437C13.3767 8.7375 13.4137 8.34688 13.4137 8C13.4137 7.65312 13.3754 7.26094 13.3108 6.90562L14.7465 6.06469C14.9033 5.97278 14.9992 5.80184 14.9992 5.625C14.9992 5.18437 13.7061 2.53 13.0321 2.53C12.9449 2.53 12.8569 2.55271 12.7781 2.59884L11.339 3.44197C10.7924 2.96812 10.1609 2.59688 9.47095 2.34875V0.665C9.47095 0.429906 9.31194 0.21875 9.08499 0.170625C8.57151 0.0617812 8.04479 0 7.49958 0C6.95438 0 6.42612 0.0617812 5.91387 0.170656C5.6884 0.218875 5.52822 0.43 5.52822 0.665V2.34875C4.83825 2.59688 4.20679 2.96812 3.66159 3.44062L2.22095 2.59875C2.14241 2.5525 2.05431 2.52969 1.96714 2.52969C1.35972 2.52969 9.23862e-05 5.09063 9.23862e-05 5.62469C9.23862e-05 5.80156 0.0959499 5.9725 0.252766 6.06437L1.68847 6.90531C1.62369 7.2625 1.5855 7.625 1.5855 8C1.5855 8.375 1.62376 8.73906 1.68838 9.09437L0.252673 9.93531C0.0958266 10.0272 0 10.1982 0 10.375C0 10.8156 1.29309 13.47 1.96705 13.47C2.05428 13.47 2.14225 13.4473 2.22105 13.4012L3.66014 12.558C4.20679 13.0312 4.83825 13.4031 5.52822 13.65V15.3337C5.52822 15.5688 5.68722 15.78 5.91418 15.8281C6.42766 15.9375 6.95438 16 7.47186 16C7.98934 16 8.54533 15.9382 9.05758 15.8293C9.31077 15.7531 9.47095 15.5688 9.47095 15.3344V13.6506C10.1615 13.4026 10.7933 13.0312 11.3391 12.5578L12.7782 13.4009C12.857 13.4471 12.945 13.4698 13.0322 13.4698C13.6385 13.4688 15 10.9094 15 10.375ZM7.49958 10.5C6.14088 10.5 5.03538 9.37844 5.03538 8C5.03538 6.62156 6.14119 5.5 7.49958 5.5C8.85798 5.5 9.96379 6.62156 9.96379 8C9.96379 9.37844 8.85798 10.5 7.49958 10.5Z" fill="#898989" />
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'BrandsMenuIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>
<style lang='scss'>
.gear-icon-container {}
</style>

<template>
    <div class="switch-menu-container" :class="{open: open}">
        <div class="closer-retailer" @click="close" />
        <div class="arrow" />
        <ul class="retailer-switch-menu scrollable">
            <template v-for="retailer in retailerSwitchList" :key="retailer.id">
                <li class="retailer-switch-option">
                    <SwitchRetailerIcon />
                    <a :href="'/dealerships/change/' + retailer.id" :title="retailer.name">
                        <div class="retailer-info">
                            <div class="brand-name">{{ retailer.brand }}</div>
                            <div class="retailer-name">{{ retailer.name }} ({{ retailer.city }}, {{ retailer.state }})</div>
                        </div>
                    </a>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import ClickOutside from '@/mixins/ClickOutside.js';
import SwitchRetailerIcon from '@/components/icons/SwitchRetailerIcon.vue';

export default {
    mixins: [
        ClickOutside,
    ],
    components: {
        SwitchRetailerIcon,
    },
    props: {
        open: {
            type:     Boolean,
            required: true,
        },
        user: {
            type:     Object,
            required: true,
        },
    },
    data() {
        return {
            isClosable:             false,
        };
    },
    computed: {
        retailerSwitchList() {
            return Object.values(
                this.user.multi_dealership_switching_list
            ).flatMap(brand => brand.dealerships.map(d => {
                return {
                    id:    d.id,
                    name:  d.name,
                    brand: brand.name,
                    city:  d.city,
                    state: d.state,
                };
            })).filter(retailer => {
                return retailer.id !== null;
            });
        },
    },
    watch: {
        // This async/await/nextTick stuff is needed to prevent ClickOutside
        // from auto-closing the tray when you click to open it
        async open(val) {
            this.isClosable = false;
            await this.$nextTick;
            if (val) {
                this.isClosable = true;
            }
        },
    },
    methods: {
        close() {
            if (this.open && this.isClosable) {
                this.$emit('update:open', false);
            }
        },
        handleClickOutside() {
            this.close();
        },
    },
};
</script>

<style lang="scss" scoped>
.switch-menu-container {
    display: none;

    &.open {
        display: block;
        position: fixed;
        z-index: 13;

        .closer-retailer {
            cursor: pointer;
            display: block;
            height: 66px;
            position: fixed;
            right: 50px;
            top: 0;
            width: 50px;
            z-index: 13;
        }
        .arrow {
            border-color: transparent;
            border-bottom-color: white;
            border-style: solid;
            border-width: 0 5px 5px 5px;
            position: fixed;
            right: 70px;
            top: 60px;
            z-index: 12;
        }
        .retailer-switch-menu {
            background-color: white;
            box-shadow: rgb(0 0 0 / 25%) 3px 3px 10px 0;
            border-radius: 5px;
            height: 272px;
            list-style: none;
            padding: 10px;
            position: fixed;
            right: 50px;
            top: 65px;
            width: 284px;
            scrollbar-width: thin;
            scrollbar-color: #85878F #fff;

            .retailer-info {
                display: inline-block;
                padding-top: 10px;
            }
        }

        .retailer-info {
            .retailer-name {
                line-height: 15px;
                font-weight: 400;
                color: #85878F;
                font-size: 14px;
            }
            .brand-name {
                line-height: 15px;
                font-weight: 700;
                font-size: 14px;
            }
        }
        a {
            color: #333333;
            display: block;
            height: 100%;
            text-decoration: none;
            width: 100%;
        }

        // scrollbar style for chrome and safari
        ::-webkit-scrollbar {
            width: 7px;
            height: 2px;
        }

        /* Style the thumb */
        ::-webkit-scrollbar-thumb {
            background-color: #85878F;
            border-radius: 7px;
        }

        /* Style the track */
        ::-webkit-scrollbar-track {
            background-color: #fff;
            border: 1px solid #DEE0E2;
            border-radius: 5px;
        }

        /* Style the thumb */
        ::-moz-scrollbar-thumb {
            background-color: #85878F;
            border-radius: 7px;
        }

        /* Style the track */
        ::-moz-scrollbar-track {
            background-color: #fff;
            border: 1px solid #DEE0E2;
            border-radius: 5px;
        }

        .retailer-switch-option {
            a {
                padding-left: 50px;
            }
            .switch-retailer-icon-container {
                position: absolute;
                margin-top: 14px;
                margin-left: 10px;
            }
        }
    }
}
</style>

<template>
    <div class="credit-card-wrapper">
        <div class="card-wrapper" />
        <form
            v-if="paymentProfile" id="dealership-edit-card-form"
            ref="dealership_edit_card_form"
            class="dealership-edit-card-form"
        >
            <input :value="paymentProfile.cardAccountNumber" type="hidden" name="number">
            <input :value="paymentProfile.firstName" type="hidden" name="first-name">
            <input :value="paymentProfile.lastName" type="hidden" name="last-name">
            <input :value="paymentProfile.expiry" type="hidden" name="expiry">
            <input value="" type="hidden" name="cvc">
        </form>
    </div>
</template>

<script>
import Card from 'card';

export default {
    props: {
        paymentProfile: {
            type: Object,
            required: false,
            default: null,
        },
    },
    mounted() {
        this.$nextTick(() => {
            new Card({
                // a selector or DOM element for the form where users will
                // be entering their information
                form:      this.$refs.dealership_edit_card_form, // *required*
                // a selector or DOM element for the container
                // where you want the card to appear
                container: '.card-wrapper', // *required*

                width:      200, // optional — default 350px
                formatting: true, // optional - default true

                // Strings for translation - optional
                messages: {
                    validDate: 'valid\ndate', // optional - default 'valid\nthru'
                    monthYear: 'mm/yyyy', // optional - default 'month/year'
                },

                formSelectors: {
                    nameInput: 'input[name="first-name"], input[name="last-name"]',
                },

                masks: {
                    cardNumber: '•', // optional - mask card number
                },

                // if true, will log helpful messages for setting up Card
                debug: false, // optional - default false
            });

            let inputs = this.$refs.dealership_edit_card_form.getElementsByTagName('input');
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].dispatchEvent(new Event('keyup'));
            }
        });
    },

};

</script>

function NavigationStoreError(message, args) {
    this.message = message;
    this.args = args;
}

const getters = {
    findAction: state => action => {
        let dfs = function(name, root) {
            if (root.name == name) {
                return root;
            }

            if (root.actions.length) {
                let out = null;
                for (let i = 0; i < root.actions.length; i++) {
                    out = dfs(name, root.actions[i]);
                    if (out) {
                        return out;
                    }
                }
            }

            return null;
        };
        return dfs(action.name, state);
    },
    getCurrent: state => {
        let filterState = state.history.filter(history => history.action.name).reverse()[0];
        if (typeof filterState === 'undefined' || !filterState.action.persist) {
            let filterStateNoDropdown = state.history.filter(history => history.action.name).reverse()[0];
            return filterStateNoDropdown;
        }
        return filterState;
    },
    getNavigationMenu: state => {
        function formatAction(action) {
            if (!action.visible) {
                return;
            }
            let out = {
                name:                   action.name,
                text:                   action.text || action.name,
                image:                  action.image,
                href:                   action.handle,
                call:                   false,
                conditions:             action.conditions,
                group:                  action.group,
                groupImage:             action.groupImage,
                tooltip:                action.tooltip,
                handleWithoutSelecting: action.handleWithoutSelecting,
                disabled:               action.disabled,
                divider:                action.divider,
                hidden:                 action.hidden,
                access:                 action.permission,
            };
            if (typeof action.handle == 'function') {
                out.href = action.handleLink || 'javascript:void(0)';
                out.call = true;
            }

            out.actions = action.actions.map(formatAction).filter(a => a);
            return out;
        }

        return state
            .actions
            .filter(action => action.visible)
            .map(formatAction)
            .filter(a => a);
    },
    isCurrent: state => action => {
        let current = getters.getCurrent(state);

        return current && current.action.name == action.name;
    },
};

export default {
    namespaced: true,

    state: {
        actions: [],
        history: [],
    },

    getters,

    mutations: {
        addAction(state, {action, replace}) {
            let existing = getters.findAction(state)(action);

            if (existing && !replace) {
                throw new NavigationStoreError('Action already exists', {existing: existing, new: action});
            } else if (existing) {
                state.actions.splice(state.actions.indexOf(existing), 1, action);
            } else {
                state.actions.push(action);
            }
        },
        addHistory(state, {action, args}) {
            let existing = getters.findAction(state)(action);

            // For compatibility with CottonTabs
            if (!existing) {
                state.history.push({action: action});
                return;
            }

            existing.args = args;
            state.history.push({action: existing});
        },
        clearData(state) {
            state.actions = [];
            state.history = [];
        },
    },

    actions: {
        callAction({state, commit}, action) {
            let existing = getters.findAction(state)(action);

            if (existing) {
                const conditions = (typeof existing.conditions == 'function' && existing.conditions()) || (typeof existing.conditions == 'boolean' && existing.conditions);
                const shouldRun = conditions || existing.handleWithoutSelecting;

                if (shouldRun) {
                    if (typeof existing.handle == 'function') {
                        existing.handle();
                    } else if (typeof existing.handle == 'string') {
                        window.location.replace(existing.handle);
                    }

                    if (existing.persist) {
                        commit('addHistory', {action: existing});
                    }
                }
            } else {
                throw new NavigationStoreError('Attempting to call unregistered action', {action: action});
            }
        },
        registerAction({commit, dispatch}, action) {
            let required = ['name', 'handle'];

            required.forEach(r => {
                if (!Object.prototype.hasOwnProperty.call(action, r)) {
                    throw new NavigationStoreError('Attempting to registered malformed action', {action: action});
                }
            });

            let template = {
                name:                   null,   // String:      Name/identifier for this action
                handle:                 null,   // String|Func: Either an href or a function to call when this action is called
                handleLink:             null,   // String       Only used if handle is a function. If falsey value, will use javascript:void(0) for link
                conditions:             true,   // Bool|Func:   Determine whether or not this action should run when called
                immediate:              false,  // Bool:        Should this action be called when it is registered
                visible:                true,   // Bool:        Whether to add this in navigation menu (false is useful for using the store to handle links elsewhere)
                text:                   '',     // String:      Text to display in navigation menu
                image:                  null,   // String:      Src for display image in navigation menu
                persist:                false,  // Bool:        For keeping track of what is currently active (active tab, underlined link, etc)
                actions:                [],     // Array:       Sub-array of actions just like these, for nested menus
                group:                  null,   // String:      Group name. For grouping menu items when needed
                groupImage:             null,    // String:      Group name. For grouping menu items when needed
                tooltip:                null,   // String:      Tooltip when hover show description text
                handleWithoutSelecting: false,   // boolean:     Override to not select but run another function instead
                disabled:               false,
                divider:                false,
                hidden:                 false,
                permission:             '',
            };

            let formatted = Object.assign({}, template, action);

            formatted.actions = formatted.actions.map(sub => Object.assign({}, template, sub));

            commit('addAction', {action: formatted, replace: false});
            if (formatted.immediate) {
                dispatch('callAction', formatted);
            }
        },
        setActive({commit}, action) {
            commit('addHistory', {action: action});
        },
    },
};

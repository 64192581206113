<template>
    <div class="panel panel-secondary retailer-product-tiers-content-container" :class="{ 'disabled': !hasAccess}">
        <div v-for="(label, tier) in getLabels(pool.id)" :key="tier">
            <div class="panel-subheading" style="margin-top: 0">
                <span :class="{ invalid: validatedOnce && cachedOrphans.findIndex(o => o.tier === tier - 1) >= 0 }" class="panel-subheading-icon title-font">
                    <img v-if="label === 'Brands'" src="/images/profile.svg" class="product-tier-icon">
                    <list-icon v-else :disabled="false" class="product-tier-icon" />
                    {{ label }}
                </span>
            </div>
            <div class="panel-body js-content js-loader">
                <div class="form-group">
                    <filter-dropdown
                        option-header-partition-by="parent_id"
                        :options="getAvailable(bufferId, tier, { activeOnly: true })"
                        :selected="getSelected(bufferId, tier, { activeOnly: true }).map(c => c.id)"
                        :selected-classes="{ 'invalid-option': item => validatedOnce && isOrphan(item.id, tier) }"
                        :header-classes="{ 'invalid': parentId => isInvalidGroup(parentId, tier) }"
                        :sub-header="returnSubheaderText(getSelected(bufferId, tier, { activeOnly: true }),
                                                         getAvailable(bufferId, tier, { activeOnly: true }).map(c => c.id))"
                        :option-header="getHeaderBuilderHelper(pool.id, tier)"
                        :count-selected="false"
                        :cannot-be-empty="true"
                        :show-summarize-selection="false"
                        @update:selected="updateSelectedClassifiersHandler(tier, $event)"
                    />
                    <div v-if="validatedOnce && cachedOrphans.findIndex(o => o.tier === tier - 1) >= 0" class="invalid" style="margin: 0.25em">
                        Please select {{ label }} for highlighted {{ getLabels(pool.id)[tier - 1] }}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="tab === 'oem_direct' && pool.has_regions_enabled" class="panel-subheading title-font">
            <location-dot-icon :disabled="false" />
            {{ resellerSettings.retailerGroupingCottonText ? resellerSettings.retailerGroupingCottonText : pool.primary_label }}
        </div>
        <div v-if="tab === 'oem_direct' && pool.has_regions_enabled" class="panel-body row">
            <div id="dealership-region-select-panel-container" class="col-xs-12">
                <dealership-region-select-panel
                    v-model="saveData.regionId"
                    class="regions-dropdown"
                    :dealership-id="dealershipId"
                    :pool="pool"
                    :user-regions="userRegions"
                    @update:modelValue="$emit('input-updated')"
                />
            </div>
        </div>
        <template v-if="tab === 'oem_direct'">
            <div class="panel-subheading positive-keywords title-font"><world-blue-icon :disabled="false" class="product-tier-icon" /> Languages</div>
            <div class="panel-body">
                <filter-dropdown
                    :count-selected="false"
                    :options="languages"
                    v-model:selected="saveData.selectedLanguages"
                    :background-color="'#41b883'"
                    :color="'#fff'"
                    :show-summarize-selection="false"
                    @update:selected="updateLanguages"
                />
            </div>
        </template>
    </div>
</template>


<script>
import axios from '@/axios';
import DealershipRegionSelectPanel from '@/components/dealerships/DealershipRegionSelectPanel.vue';
import arrayPad from '@/components/settings/social_media/helpers/array-pad.js';
import isEqual from '@/components/settings/social_media/helpers/is-equal.js';
import { userStore } from '@/stores/pinia.js';
import { mapStores } from 'pinia';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FilterDropdown from '../../FilterDropdown.vue';
import ListIcon from '../../icons/ListIcon.vue';
import LocationDotIcon from '../../icons/LocationDotIcon.vue';
import WorldBlueIcon from '../../icons/WorldBlueIcon.vue';

export default {
    components: {
        DealershipRegionSelectPanel,
        FilterDropdown,
        ListIcon,
        LocationDotIcon,
        WorldBlueIcon,
    },
    emits: ['input-updated'],
    props: {
        dealershipId: {
            type: Number,
            required: true,
        },
        pool: {
            type: Object,
            required: true,
        },
        regionId: {
            type: Number,
            required: false,
        },
        userRegions: {
            type: Array,
            required: true,
        },
        tab: {
            type: String,
            required: true,
        },
        exclusions: {
            type: Array,
            required: true,
        },
        languages: {
            type: Array,
            required: true,
        },
        selectedLanguages: {
            type: Array,
            required: true,
        },
        selectedClassifiers: {
            type: Array,
            required: true,
        },
        dealership: {
            type: Object,
            required: false,
        },
    },
    data() {
        let createSaveData = () => {
            const data = {
                classifiers:      [],
                negativeKeywords: arrayPad(this.exclusions, 8),
                regionId:         this.regionId,
            };

            if (this.languages) {
                data.selectedLanguages = this.languages.filter(l => this.selectedLanguages.includes(l.id));
            } else {
                data.selectedLanguages = [];
            }

            return data;
        };

        const data = {
            model:            {},
            saveData:         createSaveData(),
            originalData:     createSaveData(),
            bufferId:         null,
            validatedOnce:    false,
            resellerSettings: {},
        };

        return data;
    },

    computed: {
        ...mapStores(userStore),
        ...mapGetters('tiers', {
            getLabels:              'getLabels',
            getAvailable:           'getAvailableClassifiersByTier',
            getSelected:            'getSelectedClassifiersByTier',
            getHeaderBuilderHelper: 'getHeaderBuilderHelper',
            getOrphanPaths:         'getOrphanPaths',
        }),
        cachedOrphans() {
            return this.getOrphanPaths(this.bufferId);
        },
        hasAccess() {
            return this.userStore.hasWriteAccess({ permission: 'retailer-settings-content-tiers' });
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.createOptionsBuffer({pool_id: this.pool ? this.pool.id : null})
                .then(id => {
                    this.bufferId = id;
                    this.selectClassifiers({
                        bufferId: this.bufferId,
                        classifierIds: this.selectedClassifiers,
                        shouldOverwrite: true
                    });
                });

            this.updateLanguages(this.selectedLanguages, true);

            this.getResellerSettings(parseInt(this.dealership.reseller_id));
        });
    },

    methods: {
        ...mapActions('tiers', {
            toggleAll:           'toggleAll',
            selectClassifiers:   'selectClassifiers',
            createOptionsBuffer: 'createOptionsBuffer',
            toggleClassifier:    'toggleClassifier',
        }),

        ...mapMutations('tiers', [
            'selectAll',
        ]),

        returnSubheaderText(selectedClassifiers, availableClassifiers) {
            //returns the text for subheader
            return `${selectedClassifiers.length} / ${availableClassifiers.length} selected`;
        },

        englishJoin(arr) {
            if (arr.length <= 1) {
                return arr.length === 1 ? arr[0] : '';
            } else {
                let pivot  = arr.length - 1;
                let first  = arr.slice(0, pivot);
                let second = arr[pivot];

                return first.join(', ') + ' and ' + second;
            }
        },

        isInvalidGroup(parentId, tier) {
            // If parentId is an orphan, true
            return this.validatedOnce
                && this.cachedOrphans
                    .filter(o => o.tier === tier - 1)
                    .map(o => o.classifier_ids)
                    .flat()
                    .includes(parentId);
        },

        isOrphan(classifierId, tier) {
            tier = this.cachedOrphans.find(o => o.tier === tier);

            if (!tier) {
                return false;
            } else {
                return tier.classifier_ids.includes(classifierId);
            }
        },

        updateSelectedClassifiersHandler(tier, selected) {
            let allSelected = this.getSelected(this.bufferId).map(c => c.id);
            let oldValue = this.getSelected(this.bufferId, tier).map(c => c.id);
            let removedClassifiers = oldValue.filter(old => !selected.includes(old));
            allSelected = allSelected.filter(s => !removedClassifiers.includes(s));
            let newClassifiers = selected.filter(s => !oldValue.includes(s));
            allSelected = allSelected.concat(newClassifiers);

            this.selectClassifiers({
                bufferId: this.bufferId,
                classifierIds: Array.from(new Set(allSelected)),
                shouldOverwrite: true,
            });
            this.$emit('input-updated');
        },

        selectAllTierOptions() {
            this.selectAll({bufferId: this.bufferId});
        },

        toggleAllTierOptions(tier) {
            this.toggleAll({
                bufferId: this.bufferId,
                tier,
            });
        },

        toggleTierOption(classifierId) {
            this.toggleClassifier({
                bufferId: this.bufferId,
                classifierId,
            });
        },

        updateLanguages(languages, fromMounted) {
            this.saveData.selectedLanguages = languages;
            if (!fromMounted) {
                this.$emit('input-updated');
            }
        },

        hasChanges() {
            return !isEqual(this.saveData, this.originalData);
        },

        hasErrors() {
            this.validatedOnce = true;

            if (this.cachedOrphans.length) {
                let labels  = this.getLabels(this.pool.id);
                let orphans = this.cachedOrphans.map(orphan => labels[orphan.tier]);

                return `You are missing at least a child of ${this.englishJoin(orphans)}`;
            } else if (!this.getSelected(this.bufferId).length) {
                return 'You must have at least one classifier selected';
            } else {
                return false;
            }
        },

        save() {
            this.saveData.classifiers = this.getSelected(this.bufferId).map(c => c.id);

            return $.extend(true, {}, this.saveData);
        },

        getResellerSettings(resellerId) {
            return axios.get(`/reseller/settings/${resellerId}`).then(response => {
                this.resellerSettings = response.data.items;
            });
        },
    },
};

</script>

<style lang="scss">
@import '../../../../sass/variables';
.panel-subheading {
    display: flex;
    width: 100%;
    height: 30px;
    padding: 5px 5px 5px 15px;
    margin: 15px 0;
    color: #000;
    background-color: #F0F3F6;
    font-weight: 300;
}
.panel-subheading-icon {
    display: flex;
}
.title-font {
    font-weight: 700;
}
.retailer-product-tiers-content-container {
    display: flex;
    width: 50%;
    flex-direction: column;
    margin: auto;
    .product-tier-icon{
        padding-right: 7px;
        display: flex;
        vertical-align: middle;
    }
    .regions-dropdown {
        .preview-text {
            background-color: #0166FF;
            color: #eee;
            font-weight: bold;
            padding: 5px;
            border-radius: 5px;
            width: auto;
            flex-grow: 0 !important;
        }
        .dropdown {
            width: 100%;
             padding: 0px;
        }
        .dealership-region-select .dropdown-toggle {
            display: flex;
            align-content: center;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 36px;
            border: 1px solid #a5a5a5;
        }
        .btn {
            font-size: 0.8em;
        }
        .btn-default {
            background-color: #fff;
        }
    }
    @media only screen and (max-width: 1200px) {
        width: 100%;
    }

    .pm-category {
        cursor: pointer;
        user-select: none;
    }

    .invalid-option {
        border: 2px solid $brand-danger;
    }

    .invalid {
        color: $brand-danger;
    }
}

</style>

import Platform from '@/platform.js';
import All from '@/platform_story_config/all.js';
import Facebook from '@/platform_story_config/facebook.js';
import GoogleBusinessProfile from '@/platform_story_config/google_business_profile.js';
import Instagram from '@/platform_story_config/instagram.js';
import Linkedin from '@/platform_story_config/linkedin.js';
import Twitter from '@/platform_story_config/twitter.js';

/*
    Currently `mention` refers specifically to FB mentions
    so it's set to false for all non-FB platforms, though
    tagging with @pagename may still work for TW and IG
*/

/*
    `link` and `hashtag` refer to whether the platform will
    format those. Currently we're ignoring them
*/

/*
    Because of the way misc works with throwing objects around,
    it can't be a function. The `if` field on any misc field
    is a closer the vm can use to determine if that field
    should show
*/

export default {
    [Platform.ALL]:       All,
    [Platform.FACEBOOK]:  Facebook,
    [Platform.TWITTER]:   Twitter,
    [Platform.INSTAGRAM]: Instagram,
    [Platform.GBP]:       GoogleBusinessProfile,
    [Platform.LINKEDIN]:  Linkedin,
};

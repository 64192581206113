<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="switch-retailer-icon-container"
    >
        <template #content>
            <svg :width="29" :height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.2832 11.3754C25.2832 14.0679 23.0663 16.2507 20.3311 16.2507C17.5958 16.2507 15.3789 14.0683 15.3789 11.3754C15.3789 8.68258 17.5962 6.50012 20.3311 6.50012C23.0663 6.50012 25.2832 8.68297 25.2832 11.3754Z" :fill="fillColorBottom"/>
                <path d="M22.2948 18.0787H18.3717C14.6696 18.0787 11.667 21.0344 11.667 24.6794C11.667 25.4085 12.2674 26 13.0079 26H27.6593C28.3998 26.0011 28.9995 25.4107 28.9995 24.6794C28.9995 21.0344 25.9973 18.0787 22.2948 18.0787Z" :fill="fillColorTop"/>
                <path d="M11.6278 17.5788H7.70474C3.7339 17.5788 0.5 20.7509 0.5 24.6795C0.5 25.6918 1.33152 26.5 2.34095 26.5L16.9916 26.5C16.9918 26.5 16.992 26.5 16.9923 26.5C18.0025 26.5012 18.8325 25.6934 18.8325 24.6795C18.8325 20.751 15.599 17.5788 11.6278 17.5788Z" :fill="fillColorTop" stroke="white"/>
                <path d="M14.6162 11.3755C14.6162 14.068 12.3993 16.2508 9.66406 16.2508C6.92877 16.2508 4.71191 14.0683 4.71191 11.3755C4.71191 8.68265 6.92916 6.50018 9.66406 6.50018C12.3993 6.50018 14.6162 8.68303 14.6162 11.3755Z" :fill="fillColorBottom"/>
                <path d="M20.4167 5.16001C19.9631 5.29596 19.4815 5.06471 19.3362 4.64526C18.7892 3.0625 17.2207 2 15.435 2C14.081 2 12.8543 2.61375 12.0995 3.6H13.29C13.7638 3.6 14.148 3.95776 14.148 4.40001C14.148 4.84226 13.7638 5.20001 13.29 5.20001H9.858C9.38422 5.20001 9 4.84226 9 4.40001V0.799992C9 0.35774 9.38422 -1.14441e-05 9.858 -1.14441e-05C10.3318 -1.14441e-05 10.716 0.35774 10.716 0.799992V2.65225C11.7858 1.26249 13.5206 0.399991 15.4109 0.399991C17.9433 0.399991 20.1674 1.9085 20.9449 4.15501C21.1165 4.57501 20.8672 5.04751 20.4167 5.16001Z" :fill="fillColorTop"/>
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'SwitchRetailerIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
    props:      {
        fillColorTop: {
            type:     String,
            required: false,
            default:  '#85878F',
        },
        fillColorBottom: {
            type:     String,
            required: false,
            default:  '#DEE0E2',
        },
    },
};

</script>
<style lang='scss'>
.switch-retailer-icon-container {}
</style>

<template>
    <div :class="className" class="base-date-group">
        <div v-if="showLabel" class="line-break">
            <label>{{ label ? `${label}:` : '' }}</label>
        </div>
        <div v-if="singleInput" class="input-group" @click="handleOpenDropdown">
            <div v-show="useDividedInput" class="input-left-container">
                <slot name="divided-input">
                    <img src="/images/calendar-blue.svg">
                    {{ leftContainerTitle }}
                    <div class="vertical-divider">|</div>
                </slot>
            </div>
            <slot name="arrow" />
            <input
                v-if="singleInputValue !== ''"
                :value="singleInputValue"
                type="text"
                class="form-control"
                :class="{'form-control-value': !hasInputPlaceHolder}"
                placeholder="Select a Date"
            >
            <div v-if="showAdjustDate" class="placeholder-label">{{ dateInputLabel }}</div>
            <caret-down-purple-icon
                v-if="showCaretDown"
                :scale="0.6"
            />
            <div v-if="showCalendarIcon" class="input-group-btn">
                <button class="btn btn-default" @click.prevent="open = !open">
                    <i class="fa fa-calendar" />
                </button>
            </div>
        </div>
        <div v-if="clearInput" class="clear-search-button">
            <button type="button" class="btn btn-link" @click="clear">
                <i class="fa fa-close" />
            </button>
        </div>
        <div v-if="!singleInput" class="input-group">
            <input :value="selectedRange.start" type="text" class="form-control" :placeholder="placeholder" @click="open = true" @input="handleStartInput">
            <div class="input-group-btn">
                <button class="btn btn-default" @click.prevent="open = true">
                    <i class="fa fa-calendar" />
                </button>
            </div>
        </div>
        <div v-if="!singleInput" class="input-group">
            <input :value="selectedRange.end" type="text" class="form-control" :placeholder="placeholder" @click="open = true" @input="handleEndInput">
            <div class="input-group-btn">
                <button class="btn btn-default" @click.prevent="open = true">
                    <i class="fa fa-calendar" />
                </button>
            </div>
        </div>
        <div v-show="open" class="date-range-container" :class="direction">
            <div v-if="showQuickDateOptions" class="quick-date-buttons">
                <div v-if="quickDateLabel !== ''" class="quick-date-label">
                    {{ quickDateLabel }}
                </div>
                <template v-for="(date) in quickDatesToSelect" :key="date.name">
                    <button
                        v-if="date.id !== 0"
                        class="btn btn-default btn-sm quick-date-button"
                        :class="{'quick-date-button-highlighted': highlightSelectedQuickDate && currentSelectedQuickDateId === date.id}"
                        @click.prevent="handleQuickDate(date)"
                    >
                        {{ date.name }}
                    </button>
                </template>
            </div>
            <date-picker
                ref="datePicker"
                :class="{'disable-custom-date-range': disableCustomDateRange}"
                :attributes="attrs"
                :model-value="getDate"
                :model-modifiers="{range: isRange}"
                :select-attribute="selectedAttr"
                color="gray"
                :min-date="minDate"
                :max-date="maxDate"
                :disabled-dates="blackouts"
                :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
                :columns="columns"
                @update:model-value="handlePickerInput"
            />
            <div class="date-range-buttons">
                <slot name="date-range-buttons" :apply="apply" :cancel="cancel">
                    <button class="btn btn-default" @click.prevent="cancel">Cancel</button>
                    <button class="btn btn-primary" @click.prevent="apply">Select</button>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(localizedFormat);
dayjs.extend(quarterOfYear);

import { DatePicker } from 'v-calendar';

import CaretDownPurpleIcon from '@/components/icons/CaretDownPurpleIcon.vue';
import ClickOutside from '@/mixins/ClickOutside.js';

export default {
    components: {
        DatePicker,
        CaretDownPurpleIcon,
    },
    mixins: [
        ClickOutside,
    ],
    emits: [
        'update:range',
        'update:date',
        'update:selected-quick-date',
    ],
    props: {
        className: {
            type:    String,
            default: 'date-group',
        },
        attrs: {
            type:     Array,
            default:  () => [],
            required: false,
        },
        minDate: {
            type:     [Date, Object, String],
            default:  null,
            required: false,
        },
        maxDate: {
            type:     [Date, Object, String],
            default:  null,
            required: false,
        },
        format: {
            type:     String,
            required: false,
            default:  'MM/DD/YYYY',
        },
        label: {
            type:     String,
            required: false,
            default:  '',
        },
        leftContainerTitle: {
            type:     String,
            required: false,
            default:  'Date',
        },
        quickFutureDates: {
            type:     Array,
            required: false,
            default:  () => [],
        },
        range: {
            type:     Object,
            default:  () => ({}),
            required: false,
        },
        openDropdown: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        placeholder: {
            type:     String,
            required: false,
            default:  'Select a Date',
        },
        singleInput: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        showAdjustDate: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        showCalendarIcon: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        showCaretDown: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        useDividedInput: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        showLabel: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        ignoreSelectedDateRangeWatch: {
            type:    Boolean,
            require: false,
            default: false,
        },
        clearInput: {
            type:    Boolean,
            require: false,
            default: false,
        },
        highlightSelectedQuickDate: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        selectedQuickDateId: {
            type:     Number,
            required: false,
            default:  2,
        },
        dateInputLabel: {
            type:     String,
            required: false,
            default:  'Adjust Date',
        },
        replaceDateWithLabel: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        disableCustomDateRange: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        quickDateLabel: {
            type:     String,
            required: false,
            default:  '',
        },
        onlyReadStartDate: {
            type:    Boolean,
            default: false,
        },
        disabled: {
            type:    Boolean,
            default: false,
        },
        blackoutDates: {
            type:    Array,
            default: () => [],
        },
        isRange: {
            type:     Boolean,
            default:  false,
            required: false,
        },
        columns: {
            type:     Number,
            required: false,
            default:  2,
        },
        value: {
            type:     [Date, Object, String],
            default:  null,
            required: false,
        },
        showQuickDateOptions: {
            type:     Boolean,
            default:  true,
            required: false,
        },
        quickDates: {
            type:    Array,
            default: () => [
                {
                    name:  'Today',
                    start: dayjs(),
                    end:   dayjs(),
                },
                {
                    name:  'Month-to-Date',
                    start: dayjs().startOf('month'),
                    end:   dayjs(),
                },
                {
                    name:  'Last 30 Days',
                    start: dayjs().subtract(30, 'days'),
                    end:   dayjs(),
                },
                {
                    name:  'Last Month',
                    start: dayjs().subtract(1, 'month').startOf('month'),
                    end:   dayjs().subtract(1, 'month').endOf('month'),
                },
                {
                    name:  'Last Quarter',
                    start: dayjs().subtract(1, 'quarter').startOf('quarter'),
                    end:   dayjs().subtract(1, 'quarter').endOf('quarter'),
                },
                {
                    name:  'Year-to-Date',
                    start: dayjs().startOf('year'),
                    end:   dayjs(),
                },
            ],
            required: false,
        },
    },
    data() {
        return {
            datePattern:   /\d{4}-\d{2}-\d{2}/,
            open:          false,
            selectedRange: {
                start: this.range.start,
                end:   this.range.end,
            },
            rangeChanged:               false,
            previousQuickDateSelected:  2,
            currentSelectedQuickDateId: 0,
            quickDateUsed:              false,
            date:                       this.value,
            quickDatesToSelect:         this.quickDates,
            selectedAttr:               {
                color:     'gray',
                isDark:    false,
                highlight: {
                    color:        'gray',
                    fillMode:     'light',
                    contentClass: 'selected-date',
                },
            },
        };
    },
    computed: {
        getDate() {
            return this.isRange ? this.selectedRange: this.date;
        },
        singleInputValue: function() {
            if (!this.isRange) {
                return this.date ? dayjs(this.date).format(this.format) : this.placeholder;
            }
            if (this.replaceDateWithLabel) {
                const selected = this.quickFutureDates.find(qfd => qfd.id === this.selectedQuickDateId);
                return selected.name;
            }
            if (this.selectedRange.start.length !== 0 && this.selectedRange.end.length !== 0 && this.range.start.length !== 0 && this.range.end.length !== 0) {
                const start = dayjs(this.selectedRange.start).format(this.format);
                const end = this.selectedRange.end ? dayjs(this.selectedRange.end).format(this.format) : '';
                return `${start} - ${end}`;
            }
            return this.placeholder;
        },
        direction() {
            // Re-cache computed prop if this value changes
            this.open;

            if (!this.$el) {
                return 'below';
            }

            let boundingRect   = this.$el.getBoundingClientRect();
            let viewportHeight = window.innerHeight;

            let availableBelow = viewportHeight - boundingRect.top;
            let availableAbove = boundingRect.top;

            if (availableAbove > availableBelow) {
                return 'above';
            } else {
                return 'below';
            }
        },
        blackouts() {
            return this.blackoutDates.map(item => new Date(item.pause_at));
        },
        hasInputPlaceHolder() {
            return this.singleInputValue === this.placeholder;
        },
    },
    watch: {
        range(value) {
            this.selectedRange = Object.assign({}, value);
            this.rangeChanged = false;
        },
        selectedRange(value) {
            if (value.start && value.end && !this.ignoreSelectedDateRangeWatch) {
                this.$refs.datePicker.value_ = {
                    start: dayjs(value.start).valueOf(),
                    end:   dayjs(value.end).valueOf(),
                };
            }
        },
        openDropdown() {
            this.open = !this.open;
        },
    },
    mounted() {
        this.currentSelectedQuickDateId = this.selectedQuickDateId;
        if (this.quickFutureDates.length > 0) {
            this.quickDatesToSelect = this.quickFutureDates.filter(i => i.name !== '');
            this.previousQuickDateSelected = this.selectedQuickDateId;
        }
    },
    methods: {
        apply() {
            this.open = false;
            this.previousQuickDateSelected = this.selectedQuickDateId;
            if (this.isRange) {
                this.$emit('update:range', this.selectedRange);
            } else {
                if (this.date) {
                    this.$emit('update:date', { date: this.date });
                } else {
                    this.open = true;
                }
            }
            if (this.currentSelectedQuickDateId > 0) {
                this.$emit('update:selected-quick-date', {
                    id:            this.currentSelectedQuickDateId,
                    selectedRange: this.selectedRange,
                });
            }
        },
        cancel() {
            this.open = false;
            this.selectedRange = Object.assign({}, this.range);
            this.date = this.value;
            this.rangeChanged = false;
            this.$emit('update:selected-quick-date', {
                id:            this.previousQuickDateSelected,
                selectedRange: this.selectedRange,
            });
        },
        handleClickOutside() {
            if (this.rangeChanged) {
                this.apply();
                return;
            }

            this.cancel();
        },
        handleStartInput(value) {
            this.rangeChanged = true;
            if (value.match(this.datePattern)) {
                this.selectedRange = {
                    start: value,
                    end:   this.selectedRange.end,
                };
            }
        },
        handleEndInput(value) {
            this.rangeChanged = true;
            if (this.range.start.match(this.datePattern)) {
                this.selectedRange = {
                    start: this.selectedRange.end,
                    end:   value,
                };
            }
        },
        handlePickerInput(value) {
            if (this.onlyReadStartDate) {
                this.$refs.datePicker.value_.start = new Date();
                value.start = this.range.start;
            }
            if (this.isRange) {
                this.selectedRange = {
                    start: value !== null ? value.start : dayjs(),
                    end:   value !== null ? value.end : dayjs(),
                };
            } else {
                this.date = dayjs(value).startOf('day').format('YYYY-MM-DD');
            }
            this.currentSelectedQuickDateId = 0;
            this.quickDateUsed = false;
        },
        handleQuickDate(date) {
            this.quickDateUsed = true;
            this.currentSelectedQuickDateId = date.id;
            this.selectedRange = {
                start: date.start.startOf('day').format('YYYY-MM-DD'),
                end:   date.end.endOf('day').format('YYYY-MM-DD'),
            };
            this.$refs.datePicker.value_ = {
                start: date.start.startOf('day').format('YYYY-MM-DD'),
                end:   date.end.endOf('day').format('YYYY-MM-DD'),
            };
            this.rangeChanged = !((this.range.start === this.selectedRange.start && this.range.end === this.selectedRange.end) ?? false);
        },
        clear() {
            let clearObject = {
                start: '',
                end:   '',
            };
            this.$refs.datePicker.value_ = clearObject;
            this.selectedRange = clearObject;
            this.$emit('update:range', clearObject);
            this.$emit('update:date', { date: null});
        },
        handleOpenDropdown() {
            if (this.disabled) {
                return;
            }
            this.open = !this.open;
            this.previousQuickDateSelected = this.selectedQuickDateId;
        },
    },
};
</script>


<style lang="scss">
@import '../../../sass/variables.scss';
.base-date-group {
    border: 1px solid $ts-gray-border-color;
    border-radius: 5px;
    width: 100%;
    align-items: center;

    .vc-arrow {
        background-color: transparent;
    }

    .vc-title {
        background-color: transparent;
    }

    .vc-weeks {
        .vc-week:not(:last-of-type) {
            margin-bottom: 5px;
            margin-top: 5px;
        }
    }

    .input-group {
        display: flex;
        align-items: center;
        width: 100%;
        .input-left-container {
            align-items: center;
            cursor: pointer;
            display: flex;
            font-size: 12px;
            font-weight: bold;
            height: 100%;
            justify-content: center;
            vertical-align: middle;

            img {
                height: 20px;
                margin: 10px;
            }
        }
        .form-control {
            height: auto;
            width: 160px;
            color: #000;
            font-family: $ts-font-family;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px; /* 140% */
        }
        .form-control-value {
            height: auto;
            border-radius: 4px;
            background: $ts-selected-options-background;
            padding: 4px 10px;
            margin-left: 10px;
            font-size: 12px;
            color: #000;
        }
        .form-control:not(first-child):not(:last-child) {
            border-radius: 4px;
        }
        .placeholder-label {
            margin-left: auto;
            padding: 0px 6px;
            color: #B1B1B1;
            font-family: $ts-font-family;
            font-size: 12px;
            font-weight: 400;
        }
        .caret-down-purple-icon-container {
            padding-right: 0.5em;
            div {
                display: flex;
            }
        }
        .vertical-divider {
            color: #ebebed;
            font-size: 34px;
            font-weight: 300;
            margin-left: 10px;
        }
        .clear-search-button {
            z-index: 10;
        }
    }
    .clear-search-button {
        position:relative;
        z-index: 2;
        left: 323px;
        top: -39px;
        button {
            text-decoration: none;
            padding: 0px;
            .fa {
                color: #84878d;
                padding: 4px;
            }
            .fa:hover {
                background: #f1f4f7;
                color: #84878d;
            }
        }
    }
    .purple-caret-down {
        align-self: center;
    }
    .disable-custom-date-range.vc-container {
        pointer-events: none;
    }
    .date-range-container {
        display: flex;
        flex-direction: column;
        left: 5px;
        background: #fff;
        border: 1px solid #cbd5e0;
        position: absolute;
        top: 40px;
        z-index: 1;
        .vc-container {
            border: none;
            margin: auto;
            padding: 0px 30px;

            .vc-pane {
                font-family: $ts-font-family;
                &:not(:first-of-type) {
                    padding-left: 20px;
                }
                &:not(:last-of-type) {
                    padding-right: 20px;
                }

                .vc-title {
                    font-family:$ts-font-family;
                    font-size: 16px;
                    font-weight: bold;
                    padding-bottom: 8px;
                }

                .vc-day.is-not-in-month {
                    * {
                        opacity: 0.25;
                    }
                }
                .vc-highlight-base-middle, .vc-highlight-base-start, .vc-highlight-base-end {
                    height: 32px !important;
                    padding-left: 36px;
                }
                .vc-highlight-base-middle {
                    border-radius: 50%;
                }
                .vc-highlight-base-start {
                    border-radius: 50% 0 0 50% !important;
                }
                .vc-highlight-base-end {
                    border-radius: 0 50% 50% 0 !important;
                }
                .vc-highlight {
                    width: 28px;
                    height: 28px;
                }
                .vc-weeks {
                    gap: 4px 0px;
                    .vc-day {
                        padding: 0px 4px;
                        height: 32px;
                        .vc-day-content {
                            font-weight: 400 !important;
                            font-size: 14px;
                        }
                        .selected-date {
                            background: white;
                        }
                    }
                    .is-last-day {
                        .vc-day-layer {
                            border-top-right-radius: 50% !important;
                            border-bottom-right-radius: 50% !important;
                        }
                    }
                    .vc-weekday {
                        border-bottom: 2px solid #999;
                        font-size: 14px;
                        color: #000;
                        font-weight: 400 !important;
                        padding-bottom: 10px;
                    }
                }
            }
            .vc-arrows-container {
                top: 7px;
            }
        }

        .quick-date-buttons {
            left: 15px;
            top: 25px;
            font-size: 18px;
            display: flex;
            justify-content: center;
            left: unset;
            padding: 20px 10px;
            width: 100%;
            margin-top: 25px;
            .btn-sm {
                margin-left: 5px;
            }
            .quick-date-button-highlighted {
                background: #0066FF;
                border: none;
                color: #fff;
                border: 1px solid #0066ff;
            }
            .quick-date-button {
                border-radius: 8px;
                border: 3px solid #D6D9DB;
                font-size: 14px;
                font-weight: 500;
                margin: 0px 4px;
                padding: 2px 7px;
            }
        }

        .date-range-buttons {
            bottom: 25px;
            display: flex;
            gap: 20px;
            justify-content: center;
            padding: 25px;

            .btn-primary {
                background-color: #0066ff;
            }
            .btn-default {
                font-weight: bold;
            }
            .btn {
                font-size: 13px;
                padding: 6px 18px;
            }
        }
        .disabled-input {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }
}
</style>

export default function isEqual(thing1, thing2) {
    if ((thing1 === undefined && thing2 !== undefined) ||
        (thing1 !== undefined && thing2 === undefined)) {
        return false;
    }

    if (thing1 instanceof Object) {
        return Object.keys(thing1).every(k => isEqual(thing1[k], thing2[k]));
    }

    if (thing1 instanceof Array) {
        if (thing1.length !== thing2.length) {
            return false;
        }

        for (let i = 0; i < thing1.length; i++) {
            if (!isEqual(thing1[i], thing2[i])) {
                return false;
            }
        }

        return true;
    }

    return thing1 === thing2;
}
<template>
    <div
        class="filter-dropdown"
        :class="{'show-selected' : !countSelected, 'disabled' : disabled, 'disable-events': !!tooltipMessage}"
        tabIndex="0"
        @click="toggle"
        @focus="(e) => $emit('focus', e)"
        @blur="(e) => $emit('blur', e)"
    >
        <input tabindex="0" class="filter-dropdown-input" @focus="()=>$el.focus()">
        <template v-if="!multiple">
            <span v-if="isLoading" class="is-loading">
                <i class="fa fa-spin fa-spinner" />
            </span>
            <div class="select-one">
                <div
                    ref="filterSelect"
                    data-toggle="tooltip"
                    data-placement="top"
                    :data-original-title="!!tooltipMessage ? tooltipMessage : ''"
                    class="btn-left-content"
                    :class="!!tooltipMessage ? 'btn-disabled' : ''"
                >
                    <span v-if="labelInsideInput" class="btn-label">{{ labelInsideInput }}</span>
                    <div
                        v-if="placeholder === 'Not Campaign Specific' || placeholder === 'Change time slot'"
                        :class="noSelected ? 'text-campaign' : 'text'"
                    >
                        <span>{{ noSelected ? placeholder : valueFromKey(selected) }}</span>
                        <i class="fa fa-close scheduled" :class="displayCloseIcon" @click="removeSelected" />
                    </div>
                    <slot v-else name="singlePlaceholderText" :noSelected="noSelected" :placeholder="placeholder">
                        <span
                            class="text"
                            :class="placeholderCss"
                            v-html="noSelected ? placeholder : valueFromKey(selected)"
                        />
                    </slot>
                </div>
                <caret-down-purple-icon
                    class="purple-caret-down"
                    :scale="0.6"
                />
            </div>
        </template>
        <template v-else-if="countSelected && comboBox">
            <button
                class="btn btn-default btn-combobox"
                :class="{highlighted: selected.length !== options.length && highlight}"
                type="button"
            >
                <div class="btn-combobox-left-content" :class="{ 'option-selected': selected.length}">
                    <span v-if="labelInsideInput" class="btn-combobox-label">{{ labelInsideInput }}</span>
                    <template v-if="selected.length == options.length">
                        <span :class="{ 'caret-divider': showCaretDivider }">All {{ comboBox }} {{ selectedItem }}</span>
                        <i v-if="showFaClose" class="fa fa-close scheduled" @click="removeAllSelected" />
                    </template>
                    <template v-else>
                        <span :class="{ 'caret-divider': showCaretDivider }">{{ selectedText }}</span>
                        <i v-if="showFaClose && selected.length" class="fa fa-close scheduled" @click="removeAllSelected" />
                    </template>
                </div>
                <caret-down-purple-icon
                    class="purple-caret-down"
                    :scale="0.6"
                />
            </button>
        </template>
        <template v-else-if="countSelected">
            <button class="btn btn-default" type="button">
                <i class="fa fa-caret-down caret-down pull-right" />
                <span class="badge">{{ selected.length }}</span>
            </button>
        </template>
        <template v-else>
            <div class="selected-options" :class="{'selected-options-placeholder-spacing': sortedSelectedOptions.length === 1 && showPlaceholder}">
                <div v-if="!isCustomLabelSelection">
                    <div v-if="showSummarizeSelection && selected.length > 0" class="summarize-selected-option" @click.stop>
                        <span>{{ `${selected.length} Selected ${summarizeSelectionType}` }} <i class="fa fa-close" @click="removeAllOptions()" /></span>
                    </div>
                    <div v-for="key in sortedSelectedOptions" v-else :key="key" class="selected-option" :class="classFromKey(key)" @click.stop>
                        <span>{{ valueFromKey(key) }} <i class="fa fa-close" @click="removeOption(key)" /></span>
                    </div>
                </div>

                <div v-else-if="sortedSelectedOptions.length > 0 && isCustomLabelSelection" class="selected-option" @click.stop>
                    <span>{{ sortedSelectedOptions.length + ' Selected' }}</span>
                </div>
                <span v-if="isLoading" class="is-loading">
                    <i class="fa fa-spin fa-spinner" />
                </span>
                <span v-else-if="sortedSelectedOptions.length <= 1 && showPlaceholder && customPlaceHolderLabel == ''" class="placeholder-text">
                    {{ sortedSelectedOptions.length === 0 ? `Select ${label.toLowerCase()}` : (showPlaceholderText) ? `Add more ${label.toLowerCase()}` : '' }}
                </span>
                <span v-else-if="showCustomPlaceHolderText" class="custom-placeholder-text">
                    {{ sortedSelectedOptions.length === 0 ? customPlaceHolderLabel : customAddMoreText }}
                </span>
            </div>
            <div v-if="showSummarizeSelection" class="sub-title-text">
                Add more
            </div>
            <caret-down-purple-icon
                class="purple-caret-down"
                :scale="0.6"
            />
        </template>
        <div
            ref="dropdownMenu"
            class="filter-dropdown-menu"
            :style="dropdownStyles"
            :class="dropdownClasses"
            @click.stop
            @keydown.tab="toggle"
            @keydown.escape.prevent="toggle"
        >
            <div class="filter-dropdown-buttons">
                <slot name="dropdownButtons" :updateSelected="updateSelected" :searchedOptions="searchedOptions" :focus="focus" :searchTextChanged="searchTextChanged">
                    <input
                        v-if="!hideSearch"
                        v-model="searchText"
                        type="text"
                        class="search-bar menu-item"
                        placeholder="Search..."
                        autocomplete="off"
                        tabindex="0"
                        @input="searchTextChanged"
                        @mouseenter="focus($event.target)"
                        @keydown.up.prevent="focus($event.target, 'up')"
                        @keydown.down.prevent="focus($event.target, 'down')"
                        @keydown.enter.prevent="updateSelected(searchedOptions[0][optionKey])"
                        @blur="$event.target.tabIndex = -1"
                        @focus="(e) => $emit('focus-filter-dropdown-menu', e)"
                    >
                </slot>
                <slot name="action" :focus="focus" :searchTextChanged="searchTextChanged" :toggleOptions="toggleOptions" :searchedOptions="searchedOptions" :filteredSelected="filteredSelected">
                    <button
                        v-if="multiple && selectAllButton"
                        class="btn menu-item"
                        tabindex="-1"
                        type="button"
                        @mouseenter="focus($event.target)"
                        @input="searchTextChanged"
                        @click.prevent="toggleOptions"
                        @keydown.enter.prevent="toggleOptions"
                        @keydown.up.prevent="focus($event.target, 'up')"
                        @keydown.down.prevent="focus($event.target, 'down')"
                        @blur="$event.target.tabIndex = -1"
                    >
                        <b v-if="!searchText ? selected.length === options.length : filteredSelected.length === searchedOptions.length">Deselect All</b>
                        <b v-else>Select All</b>
                    </button>
                </slot>
                <span v-if="subHeader">{{ subHeader }}</span>
                <h6 v-if="optionHeaderString" class="menu-header-string">{{ optionHeaderString }}</h6>
            </div>
            <div class="filter-dropdown-options-list" :class="{multiple}" tabindex="-1">
                <div v-for="header in headers" :key="header['']" class="option-group">
                    <div class="option-group-header">
                        <i
                            v-if="multiple && headerCanBeSelected"
                            tabindex="-1"
                            class="checkbox fa fa-check"
                            :class="{'checked': headersSelected.find(hs => hs === header[0]) || validateHeaderCheckOnLoad()}"
                            @focus="()=>$el.focus()"
                            @click="optionsForHeader(header[0], true)"
                        />
                        <h6 v-if="optionHeader" :class="classFromHeaderPartitionGroup(header[0])">{{ header[1] }}</h6>
                    </div>
                    <ol class="group-list">
                        <li
                            v-for="option in optionsForHeader(header[0])"
                            :key="option[optionKey]"
                            class="menu-item"
                            :class="
                                optionCanBeDisabled &&
                                    (
                                        option.disabled || (
                                            !selectedArray.find(ov => ov === option[optionKey]) &&
                                            maxSelectableOptions === selectedArray.length
                                        )
                                    )
                                    ?
                                        'menu-item-selected disabled' :
                                        ''"
                            tabindex="-1"
                            @mouseenter="focus($event.target)"
                            @keydown.up.prevent="focus($event.target, 'up')"
                            @keydown.down.prevent="focus($event.target, 'down')"
                            @click="updateSelected(option[optionKey], $event.target, header[0])"
                            @keydown.enter.prevent="updateSelected(option[optionKey])"
                            @blur="$event.target.tabIndex = -1"
                        >
                            <i
                                v-if="multiple"
                                tabindex="-1"
                                class="checkbox fa fa-check"
                                :class="{'checked': selectedArray.find(ov => ov === option[optionKey])}"
                            />
                            <slot name="displayTimeOptions" :option="option">
                                <span v-html="option[optionValue]" />
                            </slot>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CaretDownPurpleIcon from '@/components/icons/CaretDownPurpleIcon.vue';


export default {
    components: {
        CaretDownPurpleIcon,
    },
    emits: [
        'blur',
        'closed',
        'focus-filter-dropdown-menu',
        'focus',
        'update:selected',
    ],
    props: {
        multiple: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        comboBox: {
            type:     String,
            required: false,
            default:  null,
        },
        enableHeaderSearch: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        placeholder: {
            type:     String,
            required: false,
            default:  'Select One',
        },
        countSelected: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        useLabelAsSelectedText: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        customPlaceHolderLabel: {
            type:     String,
            required: false,
            default:  '',
        },
        isCustomLabelSelection: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        options: {
            type:     Array, // of objects
            required: true,
        },
        allOptions: {
            type:     Array,
            required: false,
            default:  () => ([]),
        },
        optionKey: {
            type:     String, // property on options object that is unique
            required: false,
            default:  'id',
        },
        selected: {
            // type: Array of optionKey values or single optionKey
            required: true,
        },
        selectedClasses: {
            type:     Object, // Apply classes to selected items on default face using class name as key, bool|function as value ex {error: true}
            required: false,
            default:  () => ({}),
        },
        showCaretDivider: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        subHeader: {
            type:     String,
            required: false,
            default:  null,
        },
        headerClasses: {
            type:     Object, // Apply classes to headers using class name as key, bool|function as value ex {error: true},
            required: false,
            default:  () => ({}),
        },
        optionHeaderString: {
            type:     String,
            required: false,
            default:  null,
        },
        optionHeader: {
            type:     Function, // passed option as argument
            required: false,
            default:  null,
        },
        optionHeaderPartitionBy: {
            type:     String, // the property on the option object to use for grouping under header
            required: false, // required if optionHeader supplied
            default:  null,
        },
        optionValue: {
            type:     String, // the property on the option object to output next to the checkmark
            required: false,
            default:  'name',
        },
        hideSearch: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        sort: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        styleLine: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        label: {
            type:    String,
            default: '',
        },
        description: {
            type:    String,
            default: '',
        },
        showPlaceholder: {
            type:     Boolean,
            default:  false,
            required: false,
        },
        isLoading: {
            type:     Boolean,
            default:  false,
            required: false,
        },
        useAutoselect: {
            type:     Boolean,
            default:  true,
            required: false,
        },
        labelInsideInput: {
            type:     String,
            default:  '',
            required: false,
        },
        closeFromParent: {
            type:     Boolean,
            default:  false,
            required: false,
        },
        openDropdownOnSingleOption: {
            type:     Boolean,
            default:  true,
            required: false,
        },
        forceDropdownDirection: {
            type:     String,
            default:  null,
            required: false,
        },
        disabled: {
            type:    Boolean,
            default: false,
        },
        headerCanBeSelected: {
            type:     Boolean,
            default:  false,
            required: false,
        },
        tooltipMessage: {
            type:     String,
            default:  '',
            required: false,
        },
        optionCanBeDisabled: {
            type:     Boolean,
            default:  false,
            required: false,
        },
        tabIndex: {
            type:     Number,
            required: false,
            default:  0,
        },
        selectAllButton: {
            type:     Boolean,
            default:  true,
            required: false,
        },
        maxSelectableOptions: {
            type:     Number,
            default:  null,
            required: false,
        },
        defaultSortSelected: {
            type:     Boolean,
            default:  false,
            required: false,
        },
        customAddMoreText: {
            type:    String,
            default: '',
        },
        showSummarizeSelection: {
            type:     Boolean,
            default:  true,
            required: false,
        },
        highlight: {
            type:     Boolean,
            default:  true,
            required: false,
        },
        showFaClose: {
            type:     Boolean,
            default:  false,
            required: false,
        },
        summarizeSelectionType: {
            type:     String,
            default:  'Products',
            required: false,
        },
        searchTextFromParent: {
            type:     String,
            default:  '',
            required: false,
        },
        selectedItem: {
            type:     String,
            default:  '',
            required: false,
        },
    },

    data() {
        return {
            dropdownStyles:  '',
            focused:         {},
            open:            false,
            searchText:      '',
            selectedValue:   this.selected,
            headersSelected: [],
            firstValidation: true,
        };
    },

    computed: {
        cselected: {
            get() {
                return this.selectedArray;
            },
            set() {
                // see event instead
            },
        },
        noSelected() {
            return this.selected === null || this.selected === undefined || this.selected === '' || (Array.isArray(this.selected) && !this.selected.length);
        },
        selectedArray() {
            if (this.multiple) {
                return this.selected;
            } else {
                return [this.selected];
            }
        },
        sortedSelectedOptions() {
            if (this.defaultSortSelected) {
                return this.selectedArray;
            }
            return this.sortedOptions.filter(o => this.selectedArray.includes(o[this.optionKey])).map(o => o[this.optionKey]);
        },
        sortedOptions() {
            if (!this.sort) {
                return this.options;
            }

            return this.sortOptions(this.options);
        },
        headers() {
            if (this.optionHeader === null) {
                return [''];
            }

            let groups = new Set(this.searchedOptions.map(option => option[this.optionHeaderPartitionBy]));
            let output = Array.from(groups).map(group => [group, this.optionHeader(group)]);

            output.sort((a, b) => {
                a = a[1];
                b = b[1];

                if (a === b) {
                    return 0;
                }

                return a > b ? 1 : -1;
            });

            return output;
        },
        searchedOptions() {
            let search = this.searchText.toLowerCase().split(' ');
            const searchedOptions = this.sortedOptions.filter(o =>{
                return search.every((s) => {
                    let value  = String(o[this.optionValue]).toLowerCase();
                    let validation = value.indexOf(s) >= 0;
                    if (this.enableHeaderSearch && this.optionHeader && this.optionHeaderPartitionBy && typeof o[this.optionHeaderPartitionBy] === 'string') {
                        let headerValue = o[this.optionHeaderPartitionBy].toLowerCase();
                        validation = validation || headerValue.indexOf(s) >= 0;
                    }
                    return validation;
                });
            });
            return searchedOptions;
        },
        filteredSelected() {
            if (!Array.isArray(this.selected)) {
                return this.selected;
            }
            return this.searchedOptions.filter(o => this.selected.find(e => e === o[this.optionKey]));
        },
        direction() {
            // Re-cache computed prop if this value changes
            this.open;
            if (this.forceDropdownDirection != null ) {
                return this.forceDropdownDirection;
            }
            if (!this.$el) {
                return 'below';
            }

            let boundingRect   = this.$el.getBoundingClientRect();
            let viewportHeight = window.innerHeight;

            let availableBelow = viewportHeight - boundingRect.top;
            let availableAbove = boundingRect.top;

            if (availableAbove > availableBelow) {
                return 'above';
            } else {
                return 'below';
            }
        },

        dropdownClasses() {
            let classes = [this.direction];
            if (this.open) {
                classes.push('open');
            }

            return classes;
        },
        focusable() {
            return (this.searchedOptions && this.open) ? Array.from(this.$el.querySelectorAll('.menu-item')): [];
        },
        placeholderCss() {
            return this.selected === null ? 'placeholder' : '';
        },
        isAllSelected() {
            return this.options.length === this.selected.length;
        },
        displayCloseIcon() {
            return this.selected === '' ? 'hidden' : '';
        },
        isEnabled() {
            return this.sortedOptions.filter(o => this.selectedArray.includes(o.id)).some(o => o.enabled);
        },
        selectedText() {
            if (this.selected.length === 0 && this.useLabelAsSelectedText) {
                return `Select ${this.label}`;
            } else {
                const selectedCount = this.selected.length;
                const useLabel = this.useLabelAsSelectedText && selectedCount > 1;
                const comboBoxText = selectedCount === 1 ? this.comboBox.replace(/s$/, '') : this.comboBox;
                return `${selectedCount} ${this.selectedItem} ${useLabel ? this.label : comboBoxText} ${this.description}`;
            }
        },
        showPlaceholderText() {
            return !this.isAllSelected && !this.showSummarizeSelection;
        },
        showCustomPlaceHolderText() {
            return this.sortedSelectedOptions.length <= 1 && this.showPlaceholder && this.customPlaceHolderLabel !== '' && !this.showSummarizeSelection;
        },
    },
    watch: {
        selected() {
            this.selectedValue = this.selected;
        },
        closeFromParent() {
            if (this.closeFromParent === true && this.open) {
                this.toggle();
            }
        },
        searchTextFromParent() {
            this.searchText = this.searchTextFromParent;
        },
    },
    mounted() {
        document.addEventListener('click', this.checkOutsideClick);
        this.searchTextChanged(); // Recompute position
        this.autoSelect();
    },
    unmounted() {
        document.removeEventListener('click', this.checkOutsideClick);
    },

    methods: {
        autoSelect() {
            if (!this.multiple && this.options.length == 1 && this.useAutoselect) {
                this.updateSelected(this.options[0][this.optionKey]);
            }
        },
        searchTextChanged() {
            this.$nextTick(() => {
                let style = '';

                if (!this.open) {
                    style = 'display: none;';
                }

                this.dropdownStyles = style;
            });
        },
        checkOutsideClick(event) {
            if (this.open && !this.$el.contains(event.target)) {
                this.toggle();
            }
        },
        sortOptions(options) {
            return Array.from(options).sort(function(a, b) {
                var nameA = a.name.toUpperCase();
                var nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });
        },
        async toggle() {
            if (this.disabled) {
                return;
            }
            this.open = !this.open;
            this.searchText = '';
            this.searchTextChanged();

            if (this.open) {
                this.focused = await this.focusable[0];
                if (this.focused) {
                    this.focused.tabIndex = await 0;
                    this.focused.focus({preventScroll: true});
                }
            }

            if (!this.open) {
                this.$emit('closed');
            }
        },
        toggleOptions() {
            let selected = this.selectedArray;
            let searched = this.searchedOptions.map(o => o[this.optionKey]);
            let exists   = selected.filter(value => searched.includes(value));

            if (exists.length === searched.length) {
                selected = selected.filter(value => !searched.includes(value));
                if (this.headerCanBeSelected) {
                    this.headersSelected = [];
                }
            } else {
                selected = Array.from(new Set(selected.concat(searched)));
                if (this.headerCanBeSelected) {
                    this.headers.forEach(h => {
                        this.headersSelected.push(h[0]);
                    });
                }
            }

            if (this.selectedArray.length === selected.length) {
                this.$emit('update:selected', []);
            } else {
                this.$emit('update:selected', selected);
            }
        },
        optionsForHeader(headerValue, eventByHeader) {
            let optionHeaderPartitionBy = (this.optionHeader === null) ? null : this.optionHeaderPartitionBy;
            if (eventByHeader) {
                let newSelectedHeaderValues = [];
                let headerFilter = this.searchedOptions.filter(option => option[optionHeaderPartitionBy] === headerValue);
                headerFilter.forEach(element => newSelectedHeaderValues.push(element.id));
                if (this.headersSelected.find(hs => hs === headerValue)) {
                    const index = this.headersSelected.indexOf(headerValue);
                    this.headersSelected.splice(index, 1);
                    this.updateSelected(newSelectedHeaderValues, true);
                } else {
                    this.headersSelected.push(headerValue);
                    this.updateSelected(newSelectedHeaderValues);
                }
            } else {
                return this.searchedOptions.filter(option => option[optionHeaderPartitionBy] === headerValue);
            }
        },
        validateHeaderCheckOnLoad() {
            if (this.firstValidation) {
                this.headers.forEach(h => {
                    const childs = this.optionsForHeader(h[0]);
                    const childsFilter = childs.filter(item => this.selectedValue.includes(item.id));
                    if (childsFilter.length === childs.length) {
                        this.headersSelected.push(h[0]);
                    }
                });
                this.firstValidation = false;
                return true;
            }
            return false;
        },
        optionFromKey(key) {
            let option = this.options.find(option => option[this.optionKey] === key);
            if (!option) {
                return null;
            }

            return option;
        },
        classFromHeaderPartitionGroup(groupId) {
            let classes = ['option-header'];

            for (let k in this.headerClasses) {
                if (typeof this.headerClasses[k] === 'function') {
                    if (this.headerClasses[k](groupId)) {
                        classes.push(k);
                    }
                } else {
                    if (this.headerClasses[k]) {
                        classes.push(k);
                    }
                }
            }

            return classes;
        },
        classFromKey(key) {
            let option = this.optionFromKey(key);
            if (!option) {
                return {};
            }

            let classes = [];
            for (let k in this.selectedClasses) {
                if (typeof this.selectedClasses[k] === 'function') {
                    if (this.selectedClasses[k](option)) {
                        classes.push(k);
                    }
                } else {
                    if (this.selectedClasses[k]) {
                        classes.push(k);
                    }
                }
            }

            return classes;
        },
        valueFromKey(key) {
            let option = this.optionFromKey(key);
            if (!option) {
                if (!this.isEnabled) {
                    let disabledBrand = this.allOptions.find(o => o.id === this.selected);
                    if (disabledBrand) {
                        return `${disabledBrand.name} (Brand Disabled)`;
                    }
                }
                return 'Not Found';
            }
            return option[this.optionValue];
        },
        updateSelected(optionValue, event, header) {
            if (!this.multiple) {
                this.$emit('update:selected', optionValue);
                if (this.openDropdownOnSingleOption) {
                    this.toggle();
                }
            } else if (event === true) {
                let selected =  this.selectedArray;
                optionValue.forEach(element => {
                    selected = selected.filter(ov => ov !== element);
                });
                this.$emit('update:selected',  selected);
                this.focus(this.focused);
            } else {
                let selected =  this.selectedArray;

                if (selected.find(ov => ov === optionValue)) {
                    selected = selected.filter(ov => ov !== optionValue);
                } else {
                    selected = this.selectedArray.concat(optionValue);
                }
                this.$emit('update:selected',  selected);
                this.focus(this.focused);
                if (this.headerCanBeSelected) {
                    const childs = this.optionsForHeader(header);
                    const childsFilter = childs.filter(item => this.selectedValue.includes(item.id));
                    if (childsFilter.length === childs.length) {
                        this.headersSelected.push(header);
                    } else {
                        const index = this.headersSelected.indexOf(header);
                        if (index !== -1) {
                            this.headersSelected.splice(index, 1);
                        }
                    }
                }
            }
        },
        removeOption(key) {
            let option = this.optionFromKey(key);
            if (option) {
                this.updateSelected(option[this.optionKey]);
            }
        },
        removeAllOptions() {
            this.$emit('update:selected', []);
        },
        focus(element, focusDirection) {
            let index = this.focusable.indexOf(element);

            switch (focusDirection) {
                case 'up':
                    index = (this.direction === 'above')
                        ? this.focusForward(index)
                        : this.focusBackward(index);
                    break;
                case 'down':
                    index = (this.direction === 'above')
                        ? this.focusBackward(index)
                        : this.focusForward(index);
                    break;
            }

            this.focused = this.focusable[index];
            if (this.focused) {
                this.focused.tabIndex = 0;
                this.focused.focus();
            }
        },
        focusForward(index) {
            if (index >= this.focusable.length -1) {
                return 0;
            } else {
                return index + 1;
            }
        },
        focusBackward(index) {
            if (index <= 0) {
                return this.focusable.length -1;
            } else {
                return index - 1;
            }
        },
        removeSelected() {
            this.selectedValue = '';
            this.changeValue();
        },
        removeAllSelected() {
            this.$emit('update:selected', []);
        },
        changeValue() {
            this.$emit('update:selected', this.selectedValue, true );
        },
    },
};
</script>

<style lang="scss">
@import '../../sass/variables.scss';

.filter-dropdown {
    $selected: #eee;
    $border: 1px solid #00000026;
    $border-radius: 5px;
    position: relative;
    width: 100%;
    .filter-dropdown-input {
        width:0;
        height:0;
        opacity:0;
        position:absolute;
    }
    .line {
        border-left: 1px solid #e8e8e8;
        height: 100%;
        position: absolute;
        right: 0;
        background-color: white;
        width: 3em;
        border-radius: 0 3px 3px 0;
        z-index: 1;
    }

    .select-one {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .btn-disabled {
            opacity: 47%;
        }
    }

    .placeholder{
        color: #ADB0B3;
    }

    .hidden {
        display: none;
    }

    .text-campaign {
        color: #ADB0B3;
        margin: 0.4em;
        font-size: 14px;
        white-space: nowrap;
        padding: 0.1em 0;
        margin-right: 18px;
    }

    &.show-selected {
        display: flex;
        align-items: center;
        border: 1px solid #a5a5a5;
        border-radius: 5px;
        padding: 0.1em 0.5em;
        cursor: pointer;

        min-height: 36px;

        .purple-caret-down {
            cursor: pointer;
            z-index: 2;
            padding-top: 1px;
        }
        &.disabled {
            background-color: #eeeeee;
            color: #ccc;
            .purple-caret-down {
                color: #ccc;
            }
        }
    }

    &.disable-events:active {
        pointer-events: none;
    }
    .tooltip-inner {
        background: #252934;
        border-radius: 8px;
        color:white;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 10px !important;
        line-height: 1.428571429;
        margin-bottom: 10px;
        max-width: 200px !important;
        min-width:150px !important;
        padding: 12px 9px !important;
        width: 200px;
    }
    .tooltip.top .tooltip-arrow {
        bottom: 4px;
        left: 79px !important;
        margin-left: unset !important;
        border-width: 0;
        border-right-color: none;
    }
    .tooltip-arrow {
        display:flex;
        width: 40px !important;
        height: 12px !important;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='7' viewBox='0 0 24 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.17158 5.17158L6.34315 2.34315C4.84286 0.842855 2.80802 0 0.686292 0H23.3137C21.192 0 19.1571 0.842852 17.6569 2.34314L14.8284 5.17157C13.2663 6.73367 10.7337 6.73367 9.17158 5.17158Z' fill='%23262933'/%3E%3C/svg%3E") !important;
        background-repeat: no-repeat no-repeat !important;
        background-position: center center !important;
        background-size: contain !important;
    }

    .selected-options-placeholder-spacing {
        justify-content: space-between;
    }

    .selected-options {
        display: flex;
        flex-wrap: wrap;
        max-height: calc(2em * 4.5); // 4 lines max, show half a line to indicate need to scroll
        width: 100%;
        overflow: auto;
        overscroll-behavior: none;
        align-items: center;

        .selected-option {
            display: inline-block;
            cursor: default;
            background-color: #ddd;
            margin: 0.25em;
            border-radius: 5px;
            font-size: 0.8em;

            & > * {
                display: flex;
                align-items: center;
                gap: 5px;
                height: 24px;
                padding: 0px 1px 0px 10px;
            }

            .fa-close {
                cursor: pointer;
                padding: 0.25em;
                width: 17px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    background-color: rgb(204, 204, 204);
                    border-radius: 5px;
                    height: -webkit-fill-available;
                }
            }
        }
        .summarize-selected-option {
            border-radius: 4px;
            background: $ts-selected-options-background;
            padding: 4px 10px;
            margin-left: 10px;
            font-size: 12px;
            color: #000;
            .fa-close {
                margin-left: 10px;
            }
        }
        .is-loading {
            font-size: 14px;
            margin-left: 12px;
            padding: 0;
        }
        .placeholder-text {
            color: #ADB0B3;
            margin-left: 12px;
            margin-right: 12px;
            font-size: 14px;
            white-space: nowrap;
            padding: 0;
            align-self: center;
        }
        .custom-placeholder-text {
            color: #ADB0B3;
            margin-left: 12px;
            margin-right: 12px;
            font-size: 14px;
            white-space: nowrap;
            padding: 0;
            align-self: center;
        }
    }
    .sub-title-text {
        color: #B1B1B1;
        text-align: center;
        font-size: 12px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin-right: 10px;
    }
    .generic-icon-container  {
        display: flex;
        align-items: center;
        div {
            display: flex;
        }
    }

    &.disabled .selected-options .selected-option {
        background-color: #f3f3f3;
        color: #a9a9a9;
        .fa-close {
            &:hover {
                background-color: transparent;
            }
        }
    }

    button {
        width: 100%;
    }

    .btn-combobox {
        height: 35px;
        padding: 0;
        text-align: left;
        min-width: 150px;

        &.highlighted, &.highlighted span {
            border-color: #0066ff;
        }

        .caret-divider {
            border-right: 1px solid #ccc;
        }

        span {
            display: inline-block;
            padding: 7px 9px;
            width: calc(100% - 30px)
        }

        .purple-caret-down {
            padding-left: 7px;
            padding-right: 10px;
        }
    }

    .filter-dropdown-menu {
        display: flex;
        position: absolute;
        left: 0;


        &.below {
            flex-direction: column;
            top: 100%;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

            .filter-dropdown-buttons,
            .filter-dropdown-options-list{
                flex-direction: column;
                .option-group-header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-left: 0.5em;
                    .checkbox {
                        margin: 0 0.75em 0 0;
                        font-size: 0.75em;
                        padding: 0.125em;
                        color: transparent;
                        border: 1px solid #00000026;
                        border-radius: 5px;

                        &.checked{
                            background: $ts-checkbox-background;
                            border: 1px solid #0084bd;
                            color: white;
                        }
                    }
                    .option-header {
                        border-bottom: none;
                    }
                }
                ol {
                    flex-direction: column;
                }
            }
        }

        &.above {
            flex-direction: column-reverse;
            bottom: 100%;
            box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.175);

            .filter-dropdown-buttons,
            .filter-dropdown-options-list{
                flex-direction: column-reverse;
                .option-group-header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-left: 0.5em;
                    .checkbox {
                        margin: 0 0.75em 0 0;
                        font-size: 0.75em;
                        padding: 0.125em;
                        color: transparent;
                        border: 1px solid #00000026;
                        border-radius: 5px;

                        &.checked{
                            background-color: #0096D6;
                            border: 1px solid #0084bd;
                            color: white;
                        }
                    }
                    .option-header {
                        border-bottom: none;
                    }
                }
                ol {
                    flex-direction: column-reverse;
                }
            }
        }

        &.open {
            width: 100%;
            min-width: 225px;
            margin: 2px 0;
            padding: 2.5px 5px;
            border: $border;
            border-radius: $border-radius;
            background-color: white;
            z-index: 1000;
        }

        .menu-item{
            margin: 2.5px 0;
            padding: 0.5em;
            cursor: pointer;
            border-radius: $border-radius;
            width: 100%;

            &:focus{
                background-color: $selected !important;
                outline-style: none;
            }
        }

        .menu-header-string{
            margin: 2.5px 0;
            padding: 0.5em;
            border-radius: $border-radius;
            width: 100%;
            color: #BBBCBF;
        }

        .filter-dropdown-buttons {
            margin: 2.5px 0;
            padding: -2.5px 0;
            display: flex;


            .search-bar,
            .btn {
                background-color: transparent;
                border: $border;

                &:focus {
                    border-color: $selected;
                }
            }
        }

        .filter-dropdown-options-list {
            max-height: 400px;
            overflow: auto;
            overflow-x: -moz-hidden-unscrollable;
            overscroll-behavior: none;
            margin: 2.5px 0;
            padding: -2.5px 0;
            display: flex;


            h6 {
                white-space: nowrap;
                font-weight: bold;
                border-bottom: $border;
                padding: 0.35em 0;

                &:first-child {
                    margin-top: 0;
                }
            }

            ol {
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: flex;


                li {
                    display: flex;
                    justify-content: start;
                    align-items: center;

                    label {
                        align-items: center;
                        margin: 0;
                        padding-right: 0;
                        font-weight: normal;
                        cursor: pointer;
                    }

                    .fa-check{
                        margin: 0 .75em 0 0;
                        font-size: 0.75em;
                        padding: .125em;
                        color: transparent;
                        border: $border;
                        border-radius: $border-radius;
                        border: 1px #0066FF solid;

                        &.checked{
                            background: $ts-checkbox-background;
                            border: 1px solid #0084bd;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}
.filter-dropdown-options-list::-webkit-scrollbar {
  width: 5px;
}

.filter-dropdown-options-list::-webkit-scrollbar-track {
  background: #E8EEF1;
}

.filter-dropdown-options-list::-webkit-scrollbar-thumb {
  background: $ts-dropdown-scroll-thumb;
  border-radius: 30px;
}

.filter-dropdown-options-list::-webkit-scrollbar-thumb:hover {
  background: $ts-dropdown-scroll-thumb;
}

.selected-options::-webkit-scrollbar {
    width: 5px;
}
.selected-options::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.selected-options::-webkit-scrollbar-thumb {
    background: #888;
}
.selected-options::-webkit-scrollbar-thumb:hover {
    background: #888;
}
.scheduled {
    padding: 0.25em;

    &:hover {
        background-color: rgb(204, 204, 204);
        border-radius: 5px;
    }
}
</style>

<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="invoices-menu-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00017 0V3.75H12.0002L8.00017 0ZM6.17204 9.28125L5.97204 9.23438C5.25954 9.0293 5.27517 8.90918 5.30017 8.80371C5.34314 8.57484 5.81861 8.51897 6.24611 8.58032C6.42092 8.60504 6.61267 8.6627 6.79579 8.72224C7.12048 8.82753 7.47798 8.66546 7.59111 8.36071C7.70439 8.05485 7.53201 7.72087 7.20579 7.61452C6.9802 7.54175 6.79611 7.49505 6.62579 7.46004V7.26562C6.62579 6.94248 6.34601 6.67969 6.00079 6.67969C5.65557 6.67969 5.37579 6.94245 5.37579 7.26562V7.44117C4.67517 7.58203 4.18142 7.99219 4.06892 8.60449C3.83748 9.85957 5.10611 10.2085 5.64767 10.3576L5.82929 10.4071C6.74429 10.6524 6.72929 10.7349 6.70179 10.884C6.65882 11.1129 6.18336 11.1697 5.75461 11.1074C5.53779 11.0772 5.26054 10.9838 5.01523 10.9014L4.87557 10.8538C4.55026 10.7485 4.19339 10.9069 4.07807 11.2118C3.96332 11.5168 4.13373 11.8517 4.45932 11.9598L4.59264 12.0037C4.83507 12.0854 5.10451 12.1675 5.37576 12.2238V12.4219C5.37576 12.745 5.65554 13.0078 6.00076 13.0078C6.34598 13.0078 6.62576 12.745 6.62576 12.4219V12.2387C7.32451 12.0982 7.81982 11.6958 7.93295 11.0835C8.16579 9.81445 6.86892 9.46582 6.17204 9.28125Z" fill="#85878F" />
                <path d="M8 3.75V0H1.5C0.671562 0 0 0.62959 0 1.40625V13.5938C0 14.3701 0.671562 15 1.5 15H10.5C11.3284 15 12 14.3704 12 13.5938V3.75H8ZM2 2.10938C2 1.98135 2.11344 1.875 2.25 1.875H4.75C4.8875 1.875 5 1.98135 5 2.10938V2.57812C5 2.70645 4.8875 2.8125 4.75 2.8125H2.25C2.11344 2.8125 2 2.70645 2 2.57812V2.10938ZM2 4.45312V3.98438C2 3.85547 2.11344 3.75 2.25 3.75H4.75C4.8875 3.75 5 3.85547 5 3.98438V4.45312C5 4.58203 4.8875 4.6875 4.75 4.6875H2.25C2.11344 4.6875 2 4.58203 2 4.45312ZM7.93125 11.083C7.81803 11.6956 7.32281 12.0979 6.62406 12.2382V12.4219C6.62406 12.745 6.34428 13.0078 5.99906 13.0078C5.65384 13.0078 5.37406 12.745 5.37406 12.4219V12.2235C5.10275 12.1672 4.83344 12.0852 4.59094 12.0035L4.45762 11.9595C4.132 11.8515 3.96137 11.5165 4.07638 11.2116C4.19163 10.9066 4.54856 10.7484 4.87388 10.8536L5.01353 10.9012C5.25866 10.9835 5.53603 11.0769 5.75291 11.1071C6.18166 11.1694 6.65728 11.1126 6.70009 10.8838C6.72744 10.7345 6.74256 10.6521 5.82759 10.4068L5.64597 10.3574C5.10441 10.2081 3.83597 9.85931 4.06722 8.60423C4.18125 7.99219 4.675 7.58203 5.375 7.41504V7.26562C5.375 6.94248 5.65478 6.67969 6 6.67969C6.34522 6.67969 6.625 6.94245 6.625 7.26562V7.45995C6.79541 7.49496 6.97937 7.54166 7.205 7.61443C7.53125 7.72063 7.70344 8.05477 7.59031 8.36063C7.47703 8.66561 7.11969 8.82762 6.795 8.72215C6.61191 8.66265 6.42 8.60496 6.24531 8.58023C5.81875 8.51953 5.31563 8.5752 5.3 8.80371C5.275 8.90918 5.25938 9.0293 5.97188 9.23438L6.14522 9.28107C6.86875 9.46582 8.16562 9.81445 7.93125 11.083Z" fill="#DEE0E2" />
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'InvoicesMenuIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
    props:      {
        fillColorPaper: {
            type:     String,
            required: false,
            default:  '#DEE0E2',
        },
        fillColorMoney: {
            type:     String,
            required: false,
            default:  '#85878F',
        },
    },
};

</script>
<style lang='scss'>
.invoices-menu-icon-container {}
</style>

/**
 * Shims.js
 * Put all your pollyfills and shims in here.
 */


/**
 * Array.prototype.includes
 */
 
if (typeof Array.prototype.includes !== 'function') {
    Array.prototype.includes = function(i) { return (this.indexOf(i) > -1); };
}

if (typeof Array.prototype.find !== 'function') {
	Array.prototype.find = function (callback, thisArg) {
		for (var i = 0; i < this.length; i++) {
			if (callback.apply(thisArg, [this[i], i, this])) {
				return this[i];
			}
		}
		return null;
	};
}

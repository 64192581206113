<template>
    <div class="connect-image" @click="upload">
        <template v-if="url">
            <img :src="url">
        </template>
    </div>
</template>

<script>
import MediaUploader from '@/media-uploader.js';

export default {
    props: {
        disabled: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        url: {
            type:     String,
            required: true,
        },
    },
    methods: {
        upload() {
            if (this.disabled) {
                return;
            }
            try {
                let options = {
                    ...MediaUploader.Photo,
                    maxFiles:     1,
                    onUploadDone: ({filesUploaded}) => {
                        const urls = filesUploaded.map(({container, key}) => `https://s3.amazonaws.com/${container}/${encodeURIComponent(key)}`);

                        this.uploadComplete(urls);
                    },
                    onFileUploadFailed: (file, error) => {
                        window.flash.show({
                            type: 'error',
                            text: error.message || 'Could not upload photo.',
                        });
                    },
                };
                let uploader = new MediaUploader();
                uploader.loadFilestack(options);
            } catch (error) {
                window.flash.show({
                    type: 'error',
                    text: error || 'Could not upload file.',
                });
            }
        },
        uploadComplete(urls) {
            this.$emit('update:url', urls[0]);
        },
    },
};
</script>

<style lang="scss">
.connect-image {
    cursor: pointer;
}
</style>

import RetailerGeneralViewSettings from '@/components/retailer_experience/settings/retailer_settings_view/retailer_general_view_settings.js';
import UserProfileSettings from '@/components/retailer_experience/settings/user-profile-settings.js';
import ContactInformationViewSettings from '@/components/retailer_experience/settings/contact-info-view-settings.js';

export default {
    accountExecutive: {},
    contacts:         [
        {
            first_name: '',
            last_name:  '',
            type:       '',
            primary:    false,
            cc_invoice: false,
            email:      '',
            phone:      '',
            id:         'empty',
        },
    ],
    contactInfoViewSettings: ContactInformationViewSettings,
    contactInfo:             '',
    contactOptions:          [
        {
            name: 'General',
            id:   1,
        },
        {
            name: 'Legal',
            id:   2,
        },
        {
            name: 'Sales',
            id:   3,
        },
        {
            name: 'Support',
            id:   4,
        },
        {
            name: 'Tech',
            id:   5,
        },
    ],
    customerSuccess:             {},
    countries:                   [],
    dealership:                  {},
    dealer:                      null,
    dealershipName:              '',
    generalViewInputValues:      {},
    hasCustomWebsite:            false,
    inputValues:                 {},
    isActive:                    1,
    isSaving:                    false,
    newContactPhoneIsValid:      false,
    newContactIsValid:           false,
    originalPictureUrl:          '',
    pictureUrl:                  '',
    retailerUser:                {},
    retailerGeneralViewSettings: RetailerGeneralViewSettings,
    selectedView:                'general',
    selectedContactIndex:        null,
    updateComponent:             1,
    userInputValues:             {},
    userProfileSettings:         UserProfileSettings,
    state:                       [],
    timeZones:                   [],
    tokenAuthDate:               '',
    tokenExists:                 false,
    userRoles:                   [],
    newUserPassword:             null,
    has_product_tiers_access:    false,
    has_content_editing_access:  false,
    userRetailers:               [],
    entity:                      {},
    generalViewSaveData:         {},
    isLoading:                   true,
    inputUpdated:                false,
    isModalOpen:                 false,
    fbPageId:                    null,
    isAccountActive:             true,
    originalIsAccountActive:     null,
    dealershipServices:          null,
    canDeleteDefaultFields:      {
        website: false,
        phone:   false,
        name:    false,
        zip:     false,
        address: false,
        city:    false,
    },
    isSavingRetailerContact: false,
};

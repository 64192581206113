import connectThemes from '@/connectThemes.js';
import defaultState from '@/stores/connect/state.js';

export default {
    allProductsHidden(state) {
        if (state.productSelectionPage.products) {
            let hideable = state.productSelectionPage.products.filter(p => p.parentId);
            return hideable.length && !hideable.some(product => !product.hidden);
        }
    },
    welcomePageValid(state) {
        let themeImageCount = connectThemes.find(t => t.id == state.welcomePage.themeId).welcomeImages.length;

        return !!state.welcomePage.header.length
            && !!state.welcomePage.text.length
            && state.welcomePage.images.filter(i => !!i).length >= themeImageCount
            && !!state.logoUrl
            && !(state.costText == defaultState().costText || !state.costText)
            && !!state.footerText;
    },
    businessNamePageValid(state) {
        return !!state.businessNamePage.header.length;
    },
    destinationLoginsPageValid(state) {
        return !!state.destinationLoginsPage.header
            && !!state.destinationLoginsPage.text;
    },
    contactInfoPageValid(state) {
        return !(state.contactInfoPage.termsEnabled && (state.contactInfoPage.terms == defaultState().contactInfoPage.terms || !state.contactInfoPage.terms))
            && !!state.contactInfoPage.firstNameText
            && !!state.contactInfoPage.lastNameText
            && !!state.contactInfoPage.businessNameText
            && (!!state.contactInfoPage.dealerNumberText || !state.contactInfoPage.dealerNumberEnabled)
            && !!state.contactInfoPage.businessEmailText
            && !!state.contactInfoPage.businessPhoneText
            && !!state.contactInfoPage.otherPhoneText
            && (!!state.contactInfoPage.retailerGroupingText || !state.contactInfoPage.retailerGroupingEnabled);
    },
    productSelectionPageValid(state) {
        return !!state.productSelectionPage.header
            && !!state.productSelectionPage.text;
    },
    creditCardPageValid(state) {
        return !(state.creditCardPage.enabled && (state.creditCardPage.terms == defaultState().creditCardPage.terms || !state.creditCardPage.terms));
    },
    thankYouPageValid(state) {
        return !!state.thankYouPage.header.length
            && !!state.thankYouPage.text.length;
    },
    saveState(state) {
        return {
            data: {
                billing:               state.billing,
                costText:              state.costText,
                logoUrl:               state.logoUrl,
                footerText:            state.footerText,
                welcomePage:           state.welcomePage,
                businessNamePage:      state.businessNamePage,
                destinationLoginsPage: state.destinationLoginsPage,
                contactInfoPage:       state.contactInfoPage,
                productSelectionPage:  state.productSelectionPage,
                creditCardPage:        state.creditCardPage,
                thankYouPage:          state.thankYouPage,
                classifiers:           state.savedClassifiers,
                collapseClassifiersTo: state.collapseClassifiersTo,
                completed_at:          state.completed_at,
            },
        };
    },
};

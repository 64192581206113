export async function waitForLoad(obj, key, timeoutInMs = 60 * 1000) {
    const timeout = function(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    let timeoutCounter = 0;
    while(!obj[key]) {
        await timeout(50);
        timeoutCounter += 50;
        if (timeoutCounter >= timeoutInMs) {
            throw new Error(`Reached timeout of ${timeoutInMs}ms trying to load ${key}`);
        }
    }
}

export async function sleepTimeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
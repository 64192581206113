<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="circle-info-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M15 0C7.0932 0 0.684998 6.40763 0.684998 14.3137C0.684998 22.2198 7.0932 28.6275 15 28.6275C22.9068 28.6275 29.315 22.2198 29.315 14.3137C29.315 6.40763 22.9068 0 15 0ZM15 7.15687C15.9881 7.15687 16.7894 7.9581 16.7894 8.94609C16.7894 9.93407 15.9881 10.7353 15 10.7353C14.0119 10.7353 13.2106 9.93575 13.2106 8.94609C13.2106 7.95642 14.0102 7.15687 15 7.15687ZM17.2367 21.4706H12.7633C12.0252 21.4706 11.4212 20.8723 11.4212 20.1287C11.4212 19.385 12.0224 18.7868 12.7633 18.7868H13.658V15.2083H13.2106C12.4697 15.2083 11.8686 14.6073 11.8686 13.8664C11.8686 13.1256 12.4725 12.5245 13.2106 12.5245H15C15.7409 12.5245 16.342 13.1256 16.342 13.8664V18.7868H17.2367C17.9776 18.7868 18.5787 19.3878 18.5787 20.1287C18.5787 20.8695 17.9804 21.4706 17.2367 21.4706Z" fill="#0066FF" />
            </svg>
        </template>
    </generic-icon>
</template>


<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'CircleInfoIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>
<style lang='scss'>
.circle-info-icon-container {}
</style>


<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        :scale="scale"
        class="linkedin-icon-container"
    >
        <template #content>
            <svg :width="scale * 25" :height="scale * 25" xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="-64 -64 640 640">
                <path style="fill: #ffffff; stroke:none;" d="m37 35h443v450h-443z" />
                <path fill-rule="evenodd" style="fill: #0077b7; stroke:none;" d="m0 467c0-23.5 0-394.4 0-422 0-27.6 24.6-45 47-45 22.4 0 400.8 0 420 0 19.2 0 45 15 45 45 0 30 0 389.9 0 422 0 32.1-27.9 45-47 45-19.1 0-389.7 0-419 0-24.9 0-46-21.5-46-45zm116-301c31.9 0 55-25.9 55-55 0-29.1-23.2-55-55-55-31.8 0-54 24.8-54 55 0 30.2 22.7 55 54 55zm-42 278c0 5.3 4.8 12 12 12 7.2 0 57.4 0 61 0 3.6 0 11-4.5 11-13 0-8.5 0-235.8 0-242 0-6.2-3.7-13-12-13-8.3 0-55.2 0-60 0-4.8 0-12 3.8-12 14 0 10.2 0 236.7 0 242zm123 1c0 6.8 5.6 11 10 11 4.4 0 57.7 0 63 0 5.3 0 11-6.2 11-14 0-7.7 0-112.4 0-131 0-18.6 13.6-51.4 45.2-53.2 31.6-1.8 45.8 24.9 45.8 53.2 0 22.3 0 130.5 0 135 0 4.5 6.2 10 11 10 4.8 0 63.2 0 66 0 2.8 0 10-6 10-13 0-7-1-54.6-1-142 0-87.4-40.8-117-100-117-59.2 0-77 37-77 37 0 0 0-12.6 0-20 0-7.4-6.6-13-12-13-5.4 0-55.1 0-61 0-5.9 0-12 6.1-12 13 0 6.9 1 237.2 1 244z" />
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'LinkedinIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>
<style lang='scss'>
.linkedin-icon-container{}
</style>

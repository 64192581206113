<template>
    <div class="page-not-found-2-container">
        <div class="page">
            <div class="container">
                <div class="header">
                    <svg viewBox="0 0 450 70">
                        <text class="svgText" x="10" y="45">Error: 404</text>
                    </svg>
                </div>
                <div class="subheader">
                    Oh no, I think you might be lost!
                </div>
                <div class="content">
                    The page you are looking for cannot be found.<br>
                    Click below to go to your Content dashboard.
                </div>
                <button
                    type="button" class="dashboard-button"
                    @click="userStore.goToDashboard({ payload: { activeSession }})"
                >
                    <span class="button-text">{{ buttonText }}</span>
                </button>
            </div>
            <img class="map" src="/images/map_purple.svg" alt="404">
            <img class="thumbstopper-tack" src="/images/thumbstopper_tack2.svg">
        </div>
    </div>
</template>

<script>
import { userStore } from '@/stores/pinia.js';
import { mapStores } from 'pinia';

export default {
    name:  'PageNotFound',
    props: {
        brandId: {
            type: Number,
            required: true,
        },
        activeSession: {
            type: Boolean,
            required: true,
        },
        buttonText: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapStores(userStore),
    },
    mounted() {
        this.userStore.setUser({ payload: { ...this.user, selectedDealership: this.dealership }});
    },
};
</script>

<style scoped>
.page-not-found-2-container {
    .page {
        background: #5000B8;
        height: 100vh;
        position: absolute;
        width: 100%;
    }

    .container {
        display: flex;
        position: absolute;
    }

    .content {
        position: absolute;
        width: 520px;
        height: 60px;
        left: 185px;
        top: 475px;

        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        /* or 125% */

        text-align: center;

        color: #FFFFFF;
        font-family: 'Geomanist';
    }

    .map {
        float: right;
        height: 100%;
    }

    .thumbstopper-tack {
        position: absolute;
        left: 62.91%;
        right: 26.37%;
        top: 35%;
        bottom: 23.99%;
    }

    .header {
        position: absolute;
        left: 265px;
        top: 300px;
        font-style: normal;
        font-weight: 700;
        font-size: 68px;
        display: flex;
        letter-spacing: 0.09em;
        font-family: 'Geomanist';
    }

    svg {
        width: 450px;
        height: 100px;
    }

    .svgText {
        fill: #5000B8;
        stroke: #A36CEB;
        stroke-width: 10px;
        stroke-linejoin: round;
        paint-order: stroke;
    }

    .subheader {
        position: absolute;
        height: 20px;
        left: 194px;
        top: 400px;

        font-weight: 500;
        font-size: 36px;
        line-height: 20px;
        /* identical to box height, or 56% */

        text-align: center;

        color: #FFFFFF;
        font-family: 'Geomanist';
    }

    .dashboard-button {
        position: absolute;
        height: 54px;
        left: 393px;
        top: 625px;
        padding-right: 20px;
        padding-left: 20px;

        background: #FFFFFF;
        border-radius: 7px;
        display: flex;
    }

    .button-text {
        font-size: 24px;
        font-weight: 500;
        color: #000000;
        padding-top: 5px;
        font-family: 'Geomanist';
    }

    .upper-bar {
        align-items: center;
        background: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        display: flex;
        position: fixed;
        width: 100%;
        z-index: 1;
    }

    .retailer-name {
        margin-left: 3rem;
        font-size: 14px;
        padding-top: 16px;
    }
}
</style>

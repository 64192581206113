import axios from '@/axios';
import Platform from '@/platform.js';
import PlatformStoryConfig from '@/platform_story_config.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';

dayjs.extend(utc);

import {
    DEFAULT_PLATFORM_STORIES_ERRORS,
    DEFAULT_STORY,
    DEFAULT_STORY_OPTIONS,
} from './state';
import TYPES from './types';

const PHOTO_TYPE_ID = 1;
const VIDEO_TYPE_ID = 2;

export default {
    [TYPES.ACTIONS.CHECK_CHAR]: (
        { dispatch, getters, state },
        { platformId },
    ) => {
        const platformStory = getters.getPlatformStory({ platformId });
        if (!platformStory) {
            return;
        }
        const platformStoriesConfig = getters.getPlatformStoryConfig({
            platformId,
        });
        if (!platformStoriesConfig) {
            return;
        }
        const errors = getters.getPlatformStoryErrors({ platformId });
        dispatch(TYPES.ACTIONS.CHECK_CHAR_LIMIT, {
            message:    platformStory.message,
            config:     platformStoriesConfig,
            isRetailer: state.isRetailer,
            platformId,
            errors,
        });
        if (state.showMediaErrors) {
            dispatch(TYPES.ACTIONS.CHECK_CHAR_MINIMUM, {
                message: platformStory.message,
                config:  platformStoriesConfig,
                platformId,
                errors,
            });
        }
        dispatch(TYPES.ACTIONS.CHECK_CHAR_RESTRICTIONS, {
            message:    platformStory.message,
            config:     platformStoriesConfig,
            isRetailer: state.isRetailer,
            platformId,
            errors,
        });
    },
    [TYPES.ACTIONS.CHECK_CHAR_LIMIT]: (
        { commit, state, getters },
        { message, config, platformId, errors },
    ) => {
        const newErrors = Object.assign({}, errors);
        const patternPill = getters.getRegexCta('pillHtml');
        const patternPillAction = getters.getRegexCta('action');
        const messageTextLength = message.replace(patternPill, '').length;

        let messageActionLength = 0;
        let match = '';
        while ((match = patternPill.exec(message)) !== null) {
            let insideMatch = [];
            while ((insideMatch = patternPillAction.exec(match[1])) !== null) {
                messageActionLength += state.dynamicLength[insideMatch[1]];
            }
        }
        const messageLength = messageTextLength + messageActionLength;
        const charConfig = config.char(this);
        const limit = charConfig.limit;
        if (limit) {
            if (messageLength > limit) {
                newErrors.char.limitText = `${messageLength.toLocaleString()}/${limit.toLocaleString()}`;
                newErrors.char.limit = true;
            } else {
                newErrors.char.limitText = '';
                newErrors.char.limit = false;
            }
        } else {
            newErrors.char.limitText = '';
            newErrors.char.limit = false;
        }
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            platformStoriesErrors: {
                ...state.platformStoriesErrors,
                [platformId]: newErrors,
            },
        });
    },
    [TYPES.ACTIONS.CHECK_CHAR_MINIMUM]: (
        { commit, state },
        { message, config, platformId, errors },
    ) => {
        const newErrors = Object.assign({}, errors);
        let charConfig = config.char(this);
        let min = charConfig.min || 1;
        if (message.length < min) {
            newErrors.char.min = true;
        } else {
            newErrors.char.min = false;
        }
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            platformStoriesErrors: {
                ...state.platformStoriesErrors,
                [platformId]: newErrors,
            },
        });
    },
    [TYPES.ACTIONS.CHECK_CHAR_RESTRICTIONS]: (
        { commit, state },
        { message, config, errors, isRetailer, platformId },
    ) => {
        const newErrors = Object.assign({}, errors);
        /*
            This is valid ES, but is unsupported by our current JS loader, buble.
            Luckily, all of our platforms support emojis, but if we get one that
            doesn't we need to update our loader to make this regex work
        */
        // unicode escape to match an emoji
        //let hasEmoji = !!message.match(/\p{Extended_Pictograph}/u);
        //newErrors.char.emoji = (hasEmoji && config.emoji === false);

        // fb mention format, such as @[123]
        let hasMention = !!message.match(/@\[[0-9]+\]/);
        let charConfig = config.char(this);
        newErrors.char.mention = hasMention && config.mention === false;
        let hasDynamic = !!message.match(/\$\{[a-zA-Z0-9_]+\}/);
        newErrors.char.brandRequiresDynamic =
            !hasDynamic &&
            charConfig.brandRequiresDynamic === true &&
            !isRetailer;
        if (newErrors.char.brandRequiresDynamic) {
            newErrors.char.limitText = 'Call to Action required';
        }
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            platformStoriesErrors: {
                ...state.platformStoriesErrors,
                [platformId]: newErrors,
            },
        });
    },
    [TYPES.ACTIONS.CHECK_ERRORS]: ({ dispatch, getters }, { platformId }) => {
        dispatch(TYPES.ACTIONS.CLEAR_ERRORS);
        const platformIds =
            platformId !== 'all'
                ? [platformId]
                : getters.platformStoriesWithoutAll.map((ps) => ps.platformId);
        platformIds.forEach((platformId) => {
            dispatch(TYPES.ACTIONS.CHECK_CHAR, { platformId });
            dispatch(TYPES.ACTIONS.CHECK_MEDIA, { platformId });
        });
    },
    [TYPES.ACTIONS.CHECK_MEDIA]: (
        { dispatch, getters, state },
        { platformId, selectedAssets = null },
    ) => {
        const platformStory = getters.getPlatformStory({ platformId });
        if (!platformStory) {
            return;
        }
        const platformStoriesConfig = getters.getPlatformStoryConfig({
            platformId,
        });
        if (!platformStoriesConfig) {
            return;
        }
        const errors = getters.getPlatformStoryErrors({ platformId });
        const type = ['PHOTO', 'VIDEO'].includes(state.story.post_type)
            ? state.story.post_type.toLowerCase()
            : null;
        if (type === null) {
            return;
        }
        const payload = {
            platformId,
            type,
            config:         platformStoriesConfig,
            errors,
            selectedAssets:
                selectedAssets === null
                    ? type === 'photo'
                        ? getters.selectedPhotoAssets({ platformId })
                        : getters.selectedVideoAssets({ platformId })
                    : selectedAssets,
        };
        dispatch(TYPES.ACTIONS.CHECK_MEDIA_LIMIT, payload);
        dispatch(TYPES.ACTIONS.CHECK_MEDIA_RESOLUTION, payload);
        dispatch(TYPES.ACTIONS.CHECK_MEDIA_ASPECT_RATIO, payload);
        dispatch(TYPES.ACTIONS.CHECK_MEDIA_SIZE, payload);
        dispatch(TYPES.ACTIONS.CHECK_MEDIA_BITRATE, payload);
        dispatch(TYPES.ACTIONS.CHECK_MEDIA_FRAMERATE, payload);
        dispatch(TYPES.ACTIONS.CHECK_MEDIA_FORMAT, payload);
        dispatch(TYPES.ACTIONS.CHECK_MINIMUM_MEDIA_LIMITS, payload);

        if (type === 'video') {
            dispatch(TYPES.ACTIONS.CHECK_VIDEO_CODECS, payload);
            dispatch(TYPES.ACTIONS.CHECK_VIDEO_DURATION, payload);
        }
    },

    [TYPES.ACTIONS.CHECK_MEDIA_LIMIT]: (
        { commit, state },
        { config, errors, platformId, selectedAssets, type },
    ) => {
        const newErrors = Object.assign({}, errors);
        if (!selectedAssets.length) {
            newErrors.media[type].limit = false;
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                platformStoriesErrors: {
                    ...state.platformStoriesErrors,
                    [platformId]: newErrors,
                },
            });
            return;
        }
        let mediaConfig = config.media(this);

        if (mediaConfig[type] && mediaConfig[type].limit !== undefined) {
            let error = selectedAssets.length > mediaConfig[type].limit;

            let customError =
                mediaConfig[type].messages && mediaConfig[type].messages.limit;
            let defaultError = `${config.name} automation is limited to ${mediaConfig[type].limit} ${type}s per post.`;

            newErrors.media[type].limit =
                error && (customError || defaultError);
        }

        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            platformStoriesErrors: {
                ...state.platformStoriesErrors,
                [platformId]: newErrors,
            },
        });
    },
    [TYPES.ACTIONS.CHECK_MEDIA_RESOLUTION]: (
        { commit, state },
        { config, errors, platformId, selectedAssets, type },
    ) => {
        const newErrors = Object.assign({}, errors);

        if (!selectedAssets.length) {
            newErrors.media[type].resolution = [];
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                platformStoriesErrors: {
                    ...state.platformStoriesErrors,
                    [platformId]: newErrors,
                },
            });
            return;
        }

        let mediaConfig = config.media(this);

        if (mediaConfig[type].resolution) {
            let resErrors = {
                minW: false,
                maxW: false,
                minH: false,
                maxH: false,
            };
            let res = mediaConfig[type].resolution;
            selectedAssets.forEach((asset) => {
                if (res.min) {
                    if (
                        res.min.width &&
                        asset.metadata.resolution.width < res.min.width
                    ) {
                        resErrors.minW = true;
                    }
                    if (
                        res.min.height &&
                        asset.metadata.resolution.height < res.min.height
                    ) {
                        resErrors.minH = true;
                    }
                }
                if (res.max) {
                    if (
                        res.max.width &&
                        asset.metadata.resolution.width > res.max.width
                    ) {
                        resErrors.maxW = true;
                    }
                    if (
                        res.max.height &&
                        asset.metadata.resolution.height > res.max.height
                    ) {
                        resErrors.maxH = true;
                    }
                }
                let resString = '';
                if (
                    resErrors.minW &&
                    resErrors.maxW &&
                    resErrors.minH &&
                    resErrors.maxH
                ) {
                    resString = `between ${res.min.width}x${res.min.height} and ${res.max.width}x${res.max.height}`;
                } else {
                    if (resErrors.minW && resErrors.minH) {
                        resString = `at least ${res.min.width}x${res.min.height}`;
                    } else if (resErrors.minW) {
                        resString = `at least ${res.min.width}px wide`;
                    } else if (resErrors.minH) {
                        resString = `at least ${res.min.height}px tall`;
                    }

                    if ((resErrors.maxW || resErrors.maxH) && resString) {
                        resString += ' and ';
                    }

                    if (resErrors.maxW && resErrors.maxH) {
                        resString += `at most ${res.max.width}x${res.max.height}`;
                    } else if (resErrors.maxW) {
                        resString += `at most ${res.max.width}px wide`;
                    } else if (resErrors.maxH) {
                        resString += `at most ${res.max.height}px tall`;
                    }
                }

                if (resString) {
                    resString = `Resolution must be ${resString}.`;
                }

                let existing = newErrors.media[type].resolution.find(
                    (error) => error.id == asset.id,
                );
                if (existing) {
                    if (resString) {
                        existing.text = resString;
                    } else {
                        newErrors.media[type].resolution.splice(
                            newErrors.media[type].resolution.indexOf(existing),
                            1,
                        );
                    }
                } else {
                    if (resString) {
                        newErrors.media[type].resolution.push({
                            id:   asset.id,
                            text: resString,
                        });
                    }
                }
            });
        } else {
            newErrors.media[type].resolution = [];
        }
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            platformStoriesErrors: {
                ...state.platformStoriesErrors,
                [platformId]: newErrors,
            },
        });
    },
    [TYPES.ACTIONS.CHECK_MEDIA_ASPECT_RATIO]: (
        { commit, state },
        { config, errors, platformId, selectedAssets, type },
    ) => {
        const newErrors = Object.assign({}, errors);

        if (!selectedAssets.length) {
            newErrors.media[type].aspectRatio = [];
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                platformStoriesErrors: {
                    ...state.platformStoriesErrors,
                    [platformId]: newErrors,
                },
            });
            return;
        }

        let mediaConfig = config.media(this);

        if (mediaConfig[type] && mediaConfig[type].aspectRatio) {
            selectedAssets.forEach((asset) => {
                let aspErrors = {
                    min: false,
                    max: false,
                };
                let asp = mediaConfig[type].aspectRatio;
                if (
                    asp.min &&
                    asset.metadata.resolution.width /
                        asset.metadata.resolution.height <
                        asp.min
                ) {
                    aspErrors.min = true;
                }
                if (
                    asp.max &&
                    asset.metadata.resolution.width /
                        asset.metadata.resolution.height >
                        asp.max
                ) {
                    aspErrors.max = true;
                }

                let aspString = '';
                if (aspErrors.min && aspErrors.max) {
                    aspString = `between ${asp.min} and ${asp.max}.`;
                } else if (aspErrors.min) {
                    aspString = `at least ${asp.min}.`;
                } else if (aspErrors.max) {
                    aspString = `at most ${asp.max}.`;
                }

                let existing = newErrors.media[type].aspectRatio.find(
                    (error) => error.id == asset.id,
                );

                if (aspString) {
                    aspString = `Aspect ratio must be ${aspString}`;
                }

                if (existing) {
                    if (aspString) {
                        existing.text = aspString;
                    } else {
                        newErrors.media[type].aspectRatio.splice(
                            newErrors.media[type].aspectRatio.indexOf(existing),
                            1,
                        );
                    }
                } else {
                    if (aspString) {
                        newErrors.media[type].aspectRatio.push({
                            id:   asset.id,
                            text: aspString,
                        });
                    }
                }
            });
        } else {
            newErrors.media[type].aspectRatio = [];
        }
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            platformStoriesErrors: {
                ...state.platformStoriesErrors,
                [platformId]: newErrors,
            },
        });
    },
    [TYPES.ACTIONS.CHECK_MEDIA_SIZE]: (
        { commit, state },
        { config, errors, platformId, selectedAssets, type },
    ) => {
        const newErrors = Object.assign({}, errors);

        if (!selectedAssets.length) {
            newErrors.media[type].size = false;
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                platformStoriesErrors: {
                    ...state.platformStoriesErrors,
                    [platformId]: newErrors,
                },
            });
            return;
        }

        let mediaConfig = config.media(this);

        if (mediaConfig[type] && mediaConfig[type].size) {
            newErrors.media[type].size = [];
            selectedAssets.forEach((asset) => {
                let oversize =
                    asset.metadata.format.size > mediaConfig[type].size;
                let formatted = '';
                let sizeString = '';

                if (oversize) {
                    let exp = Math.floor(
                        Math.log(mediaConfig[type].size) / Math.log(1024),
                    );
                    let suffix;
                    switch (exp) {
                        case 4:
                            suffix = 'TB';
                            break;
                        case 3:
                            suffix = 'GB';
                            break;
                        case 2:
                            suffix = 'MB';
                            break;
                        case 1:
                            suffix = 'KB';
                            break;
                        default:
                            suffix = 'B';
                    }
                    formatted =
                        (mediaConfig[type].size / 1024 ** exp).toFixed(2) +
                        ' ' +
                        suffix;
                    sizeString = `Must be less than ${formatted}.`;
                }

                let existing =
                    newErrors.media[type].size &&
                    newErrors.media[type].size.find(
                        (error) => error.id == asset.id,
                    );

                if (existing) {
                    if (sizeString) {
                        existing.text = sizeString;
                    } else {
                        newErrors.media[type].size.splice(
                            newErrors.media[type].size.indexOf(existing),
                            1,
                        );
                    }
                } else {
                    if (sizeString) {
                        newErrors.media[type].size.push({
                            id:   asset.id,
                            text: sizeString,
                        });
                    }
                }
            });
        } else {
            newErrors.media[type].size = [];
        }
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            platformStoriesErrors: {
                ...state.platformStoriesErrors,
                [platformId]: newErrors,
            },
        });
    },
    [TYPES.ACTIONS.CHECK_MEDIA_BITRATE]: (
        { commit, state },
        { config, errors, platformId, selectedAssets, type },
    ) => {
        const newErrors = Object.assign({}, errors);
        if (!selectedAssets.length) {
            newErrors.media[type].bitRate = false;
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                platformStoriesErrors: {
                    ...state.platformStoriesErrors,
                    [platformId]: newErrors,
                },
            });
            return;
        }

        let mediaConfig = config.media(this);
        if (mediaConfig[type] && mediaConfig[type].bitRate) {
            selectedAssets.forEach((asset) => {
                let oversize =
                    asset.metadata.format.bit_rate > mediaConfig[type].bitRate;
                let bitRateString = '';
                let formatted = '';
                if (oversize) {
                    let exp = Math.floor(
                        Math.log(mediaConfig[type].bitRate) / Math.log(1024),
                    );
                    let suffix;
                    switch (exp) {
                        case 4:
                            suffix = 'TB';
                            break;
                        case 3:
                            suffix = 'GB';
                            break;
                        case 2:
                            suffix = 'MB';
                            break;
                        case 1:
                            suffix = 'KB';
                            break;
                        default:
                            suffix = 'B';
                    }
                    formatted =
                        (mediaConfig[type].bitRate / 1024 ** exp).toFixed(2) +
                        ' ' +
                        suffix;
                    bitRateString = `Bitrate must be less than ${formatted}.`;
                }
                let existing =
                    newErrors.media[type].bitRate &&
                    newErrors.media[type].bitRate.find(
                        (error) => error.id == asset.id,
                    );

                if (existing) {
                    if (bitRateString) {
                        existing.text = bitRateString;
                    } else {
                        newErrors.media[type].bitRate.splice(
                            newErrors.media[type].bitRate.indexOf(existing),
                            1,
                        );
                    }
                } else {
                    if (bitRateString) {
                        newErrors.media[type].bitRate = [];
                        newErrors.media[type].bitRate.push({
                            id:   asset.id,
                            text: bitRateString,
                        });
                    }
                }
            });
        } else {
            newErrors.media[type].bitRate = [];
        }
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            platformStoriesErrors: {
                ...state.platformStoriesErrors,
                [platformId]: newErrors,
            },
        });
    },
    [TYPES.ACTIONS.CHECK_MEDIA_FRAMERATE]: (
        { commit, state },
        { config, errors, platformId, selectedAssets, type },
    ) => {
        const newErrors = Object.assign({}, errors);

        if (!selectedAssets.length) {
            newErrors.media[type].framerate = [];
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                platformStoriesErrors: {
                    ...state.platformStoriesErrors,
                    [platformId]: newErrors,
                },
            });
            return;
        }

        let mediaConfig = config.media(this);

        if (mediaConfig[type] && mediaConfig[type].framerate) {
            selectedAssets.forEach((asset) => {
                let framerateErrors = {
                    min: false,
                    max: false,
                };
                let framerate = mediaConfig[type].framerate;
                if (framerate.min && asset.metadata.framerate < framerate.min) {
                    framerateErrors.min = true;
                }
                if (framerate.max && asset.metadata.framerate > framerate.max) {
                    framerateErrors.max = true;
                }

                let framerateString = '';
                if (framerateErrors.min && framerateErrors.max) {
                    framerateString = `between ${framerate.min} and ${framerate.max} FPS.`;
                } else if (framerateErrors.min) {
                    framerateString = `at least ${framerate.min} FPS.`;
                } else if (framerateErrors.max) {
                    framerateString = `at most ${framerate.max} FPS.`;
                }

                let existing = newErrors.media[type].framerate.find(
                    (error) => error.id == asset.id,
                );

                if (framerateString) {
                    framerateString = `Framerate must be ${framerateString}`;
                }

                if (existing) {
                    if (framerateString) {
                        existing.text = framerateString;
                    } else {
                        newErrors.media[type].framerate.splice(
                            newErrors.media[type].framerate.indexOf(existing),
                            1,
                        );
                    }
                } else {
                    if (framerateString) {
                        newErrors.media[type].framerate.push({
                            id:   asset.id,
                            text: framerateString,
                        });
                    }
                }
            });
        } else {
            newErrors.media[type].framerate = [];
        }
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            platformStoriesErrors: {
                ...state.platformStoriesErrors,
                [platformId]: newErrors,
            },
        });
    },
    [TYPES.ACTIONS.CHECK_MEDIA_FORMAT]: (
        { commit, state },
        { config, errors, platformId, selectedAssets, type },
    ) => {
        const newErrors = Object.assign({}, errors);

        if (!selectedAssets.length) {
            newErrors.media[type].format = [];
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                platformStoriesErrors: {
                    ...state.platformStoriesErrors,
                    [platformId]: newErrors,
                },
            });
            return;
        }

        let mediaConfig = config.media(this);

        if (
            mediaConfig[type] &&
            mediaConfig[type].formats &&
            mediaConfig[type].formats.length
        ) {
            selectedAssets.forEach((asset) => {
                let match = asset.metadata.format.format_name
                    .split(',')
                    .map((format) => format.split('/').pop())
                    .filter((format) =>
                        mediaConfig[type].formats.includes(format),
                    ).length;

                let existing = newErrors.media[type].format.find(
                    (error) => error.id == asset.id,
                );
                let formats = '';
                let formatString = '';

                let typeStr = type[0].toUpperCase() + type.substr(1);

                if (!match) {
                    formats = mediaConfig[type].formats.join(', ');
                    formatString = `${typeStr}s must be ${formats}.`;
                }

                if (existing) {
                    if (formatString) {
                        existing.text = formatString;
                    } else {
                        newErrors.media[type].format.splice(
                            newErrors.media[type].format.indeOf(existing),
                            1,
                        );
                    }
                } else {
                    if (formatString) {
                        newErrors.media[type].format.push({
                            id:   asset.id,
                            text: formatString,
                        });
                    }
                }
            });
        } else {
            newErrors.media[type].format = [];
        }
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            platformStoriesErrors: {
                ...state.platformStoriesErrors,
                [platformId]: newErrors,
            },
        });
    },
    [TYPES.ACTIONS.CHECK_VIDEO_CODECS]: (
        { commit, state },
        { config, errors, platformId, selectedAssets },
    ) => {
        const newErrors = Object.assign({}, errors);

        if (!selectedAssets.length) {
            newErrors.media.video.codecs = [];
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                platformStoriesErrors: {
                    ...state.platformStoriesErrors,
                    [platformId]: newErrors,
                },
            });
            return;
        }

        let mediaConfig = config.media(this);

        if (
            mediaConfig.video &&
            mediaConfig.video.codecs &&
            mediaConfig.video.codecs.length
        ) {
            selectedAssets.forEach((video) => {
                let match = video.metadata.streams
                    .map((stream) => stream.codec_name)
                    .every((codec) => mediaConfig.video.codecs.includes(codec));

                let existing = newErrors.media.video.codecs.find(
                    (error) => error.id == video.id,
                );

                let codecs;
                let codecString;

                if (!match) {
                    codecs = mediaConfig.video.codecs.join(', ');
                    codecString = `Videos must be ${codecs}.`;
                }

                if (existing) {
                    if (codecString) {
                        existing.text = codecString;
                    } else {
                        newErrors.media.video.codecs.splice(
                            newErrors.media.video.codecs.indexOf(existing),
                            1,
                        );
                    }
                } else {
                    if (codecString) {
                        newErrors.media.video.codecs.push({
                            id:   video.id,
                            text: codecString,
                        });
                    }
                }
            });
        } else {
            newErrors.media.video.codecs = [];
        }
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            platformStoriesErrors: {
                ...state.platformStoriesErrors,
                [platformId]: newErrors,
            },
        });
    },
    [TYPES.ACTIONS.CHECK_VIDEO_DURATION]: (
        { commit, state },
        { config, errors, platformId, selectedAssets },
    ) => {
        const newErrors = Object.assign({}, errors);

        if (!selectedAssets.length) {
            newErrors.media.video.duration = [];
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                platformStoriesErrors: {
                    ...state.platformStoriesErrors,
                    [platformId]: newErrors,
                },
            });
            return;
        }

        let mediaConfig = config.media(this);

        if (mediaConfig.video && mediaConfig.video.duration) {
            selectedAssets.forEach((video) => {
                let durationErrors = {
                    min: false,
                    max: false,
                };
                let duration = mediaConfig.video.duration;
                if (duration.min || duration.max) {
                    if (
                        duration.min &&
                        video.metadata.format.duration < duration.min
                    ) {
                        durationErrors.min = true;
                    }
                    if (
                        duration.max &&
                        video.metadata.format.duration > duration.max
                    ) {
                        durationErrors.max = true;
                    }
                } else {
                    if (Number(video.metadata.format.duration) > duration) {
                        durationErrors.max = true;
                    }
                }

                let durationString = '';
                let minutes = Math.floor(duration.max / 60);
                if (durationErrors.min && durationErrors.max) {
                    durationString = `between ${duration.min} seconds and ${minutes} minutes.`;
                } else if (durationErrors.min) {
                    durationString = `at least ${duration.min} seconds.`;
                } else if (durationErrors.max) {
                    durationString = `at most ${minutes} minutes.`;
                }

                if (durationString) {
                    durationString = `Videos must be ${durationString}`;
                }

                let existing = newErrors.media.video.duration.find(
                    (error) => error.id == video.id,
                );

                if (existing) {
                    if (durationString) {
                        existing.text = durationString;
                    } else {
                        newErrors.media.video.duration.splice(
                            newErrors.media.video.duration.indexOf(existing),
                            1,
                        );
                    }
                } else {
                    if (durationString) {
                        newErrors.media.video.duration.push({
                            id:   video.id,
                            text: durationString,
                        });
                    }
                }
            });
        } else {
            newErrors.media.video.duration = [];
        }
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            platformStoriesErrors: {
                ...state.platformStoriesErrors,
                [platformId]: newErrors,
            },
        });
    },
    [TYPES.ACTIONS.CHECK_MINIMUM_MEDIA_LIMITS]: (
        { commit, state },
        { config, errors, platformId, selectedAssets, type },
    ) => {
        const newErrors = Object.assign({}, errors);

        let mediaConfig = config.media(this);

        if (
            mediaConfig[type].min &&
            selectedAssets.length < mediaConfig[type].min
        ) {
            let customError =
                mediaConfig[type].messages && mediaConfig[type].messages.min;
            let plural = mediaConfig[type].min > 1 ? 's' : '';
            let defaultError = `${config.name} should have at least ${mediaConfig[type].min} ${type}${plural} per post.`;
            newErrors.media[type].min = customError || defaultError;
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                platformStoriesErrors: {
                    ...state.platformStoriesErrors,
                    [platformId]: newErrors,
                },
            });
        }
    },
    [TYPES.ACTIONS.AUTO_SELECT_MEDIA]: ({ dispatch, state }) => {
        if (state.story.post_type === 'PHOTO') {
            dispatch(TYPES.ACTIONS.AUTO_SELECT_PHOTOS);
        } else if (state.story.post_type === 'VIDEO') {
            dispatch(TYPES.ACTIONS.AUTO_SELECT_VIDEOS);
        }
    },
    [TYPES.ACTIONS.AUTO_SELECT_PHOTOS]: ({ dispatch, getters }) => {
        getters.platformStoriesWithoutAll.forEach((ps) => {
            const assets = [];
            const platformStoriesConfig = getters.getPlatformStoryConfig({
                platformId: ps.platformId,
            });
            let mediaConfig = platformStoriesConfig.media(this);
            ps.storyAssets
                .filter((asset) => asset.type == 1)
                .filter((asset) => ps.selectedAssets.includes(asset.id))
                .forEach((photo) => {
                    if (
                        !getters.assetIsValidToBeSelected({
                            platformId: ps.platformId,
                            assetId:    photo.id,
                        })
                    ) {
                        return;
                    }
                    if (assets.length < mediaConfig.photo.limit) {
                        assets.push(photo.id);
                    }
                });
            dispatch(TYPES.ACTIONS.UPDATE_PLATFORM_STORY_SELECTED_ASSETS, {
                platformId: ps.platformId,
                payload:    {
                    selectedAssets:      assets,
                    emptySelectedAssets: true,
                },
            });
            dispatch(TYPES.ACTIONS.CALCULATE_ALL_POPULATED_INPUTS);
        });
    },
    [TYPES.ACTIONS.AUTO_SELECT_VIDEOS]: ({ dispatch, getters }) => {
        getters.platformStoriesWithoutAll.forEach((ps) => {
            const assets = [];
            const platformStoriesConfig = getters.getPlatformStoryConfig({
                platformId: ps.platformId,
            });
            let mediaConfig = platformStoriesConfig.media(this);
            ps.storyAssets
                .filter((asset) => asset.type == 2)
                .filter((asset) => ps.selectedAssets.includes(asset.id))
                .forEach((video) => {
                    if (
                        !getters.assetIsValidToBeSelected({
                            platformId: ps.platformId,
                            assetId:    video.id,
                        })
                    ) {
                        return;
                    }
                    if (assets.length < mediaConfig.video.limit) {
                        assets.push(video.id);
                    }
                });
            const validSelectedAssets = ps.selectedAssets.filter((sa) =>
                assets.includes(sa),
            );
            dispatch(TYPES.ACTIONS.UPDATE_PLATFORM_STORY_SELECTED_ASSETS, {
                platformId: ps.platformId,
                payload:    { selectedAssets: validSelectedAssets },
            });
            dispatch(TYPES.ACTIONS.CALCULATE_ALL_POPULATED_INPUTS);
        });
    },
    [TYPES.ACTIONS.PRE_LOAD_STORY]: async (
        { dispatch },
        { curatorHandler, listenerHandler, poolId, oemManagerId },
    ) => {
        await dispatch(TYPES.ACTIONS.LOAD_LANGUAGES, { curatorHandler, oemManagerId });
        await dispatch(TYPES.ACTIONS.LOAD_DYNAMIC_LENGTH, { curatorHandler });
        await dispatch(TYPES.ACTIONS.LOAD_REGIONS, { curatorHandler, poolId });
        await dispatch(TYPES.ACTIONS.LOAD_CAMPAIGNS_OPTIONS, { curatorHandler, poolId });
        await dispatch(TYPES.ACTIONS.LOAD_PLATFORM, { listenerHandler });
    },
    [TYPES.ACTIONS.LOAD_STORY]: async (
        { commit, dispatch, getters, state },
        {
            curatorHandler,
            storyId,
            poolId,
            enabledPlatforms = [],
            isNewStory = false,
            oemIsParent = false,
            oemManagerId = null,
            scheduledAt = null,
        },
    ) => {
        if (!storyId || !poolId) {
            console.error('StoryID and PoolID are required to load a story.');
        }

        // Initializing story
        await dispatch(TYPES.ACTIONS.INITIALIZE_STORY);
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { loading: true });

        if (!isNewStory) {
            const url = `/stories/${storyId}?oem_is_parent=${oemIsParent}`;
            const {
                data: { story },
            } = await axios.get(url);
            story.platformStories = story.platformStories
                .filter((ps) => enabledPlatforms.includes(ps.platformId))
                .sort((a, b) => a.platformId - b.platformId);
            if (story.scheduled_at) {
                story.selectedCampaign = story.campaign_id;
                story.scheduled_time = getters.getScheduledTimeFromScheduledAt({
                    scheduled_at: story.scheduled_at,
                    isNewStory,
                });
                story.scheduled_date = getters.getScheduledDateFromScheduledAt({
                    scheduled_at: story.scheduled_at,
                    isNewStory,
                });
                story.published_at = null;
                story.expires_at = null;
                story.old_expires_at = null;
            } else {
                story.scheduled_at = null;
                story.published_at =
                    story.published_at != null
                        ? dayjs(
                            story.published_at.slice(0, 10),
                            'YYYY-MM-DD',
                        ).toDate()
                        : null;
                story.expires_at =
                    story.expires_at != null
                        ? dayjs(
                            story.expires_at.slice(0, 10),
                            'YYYY-MM-DD',
                        ).toDate()
                        : null;
                story.old_expires_at = story.expires_at;
            }
            commit(TYPES.MUTATIONS.PARTIAL_STORY_UPDATE, story);
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                is_distribute_by_owner: !!story.distribute_by_owner_ids?.length,
            });
        } else {
            if (scheduledAt !== null) {
                commit(TYPES.MUTATIONS.PARTIAL_STORY_UPDATE, {
                    published_at:   null,
                    expires_at:     null,
                    scheduled_at:   scheduledAt,
                    scheduled_date: getters.getScheduledDateFromScheduledAt({
                        scheduled_at: scheduledAt,
                        isNewStory,
                    }),
                    scheduled_time:
                        scheduledAt.length > 10
                            ? getters.getScheduledTimeFromScheduledAt({
                                scheduled_at: scheduledAt,
                                isNewStory,
                            })
                            : null,
                });
            }
        }

        await dispatch(TYPES.ACTIONS.LOAD_SELECTED_LANGUAGES, {
            curatorHandler,
            poolId,
            storyId,
            isNewStory,
        });
        await dispatch(TYPES.ACTIONS.LOAD_CLASSIFIERS_AND_POOL_IDS, {
            curatorHandler,
            poolId,
            storyId,
            isNewStory,
        });
        await dispatch(TYPES.ACTIONS.ADD_MISSING_PLATFORM_STORIES, {
            enabledPlatforms,
            defaultEnabled: isNewStory,
            options:        {
                gbpPlatformId:          Platform.GBP,
                gpbPlatformDefaultMisc: { cta: 'CALL' },
            },
        });
        await dispatch(TYPES.ACTIONS.PLATFORMS_SCHEDULED_POSTS_DISABLED, {
            curatorHandler,
            enabledPlatforms,
            poolId,
            storyId,
            isNewStory,
        });
        await dispatch(TYPES.ACTIONS.ADD_PLATFORM_STORY_ALL);
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            oemIsParent,
            enabledPlatforms,
            isNewStory,
        });
        await dispatch(TYPES.ACTIONS.LOAD_SELECTED_PLATFORM);
        await dispatch(TYPES.ACTIONS.LOAD_CAMPAIGNS_PLATFORMS);
        await dispatch(TYPES.ACTIONS.LOAD_POPULATED_INPUTS);
        await dispatch(TYPES.ACTIONS.LOAD_INITIAL_STORY_STATUS);
        await dispatch(TYPES.ACTIONS.LOAD_HISTORY);
        await dispatch(TYPES.ACTIONS.CALCULATE_ALL_POPULATED_INPUTS);
        await dispatch(TYPES.ACTIONS.CALCULATE_IS_FILLED_COMPLETELY);
        dispatch(TYPES.ACTIONS.LOAD_PLATFORM_STORIES_CONFIGS);
        dispatch(TYPES.ACTIONS.CLEAR_ERRORS);
        dispatch(TYPES.ACTIONS.AUTO_SELECT_MEDIA);
        dispatch(TYPES.ACTIONS.CHECK_ERRORS, { platformId: 'all' });
        dispatch(TYPES.ACTIONS.INITIALIZE_STORY_ENABLED_BY_USER);
        dispatch(TYPES.ACTIONS.LOAD_NOTES_UNREAD);
        dispatch(TYPES.ACTIONS.LOAD_RETAILERS, { preloading: false, oemManagerId });
        dispatch(TYPES.ACTIONS.LOAD_OEM_MANAGER, { oemManagerId });
        dispatch(TYPES.ACTIONS.LOAD_OEM_MANAGER_SETTINGS, { oemManagerId });

        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { loading: false });
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { isOriginalState: true });
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { initialDataSnapshot: _.cloneDeep(state.story) });
    },
    [TYPES.ACTIONS.INITIALIZE_STORY]: ({ commit, state }) => {
        const defaultStory = _.cloneDeep(DEFAULT_STORY);
        const defaultStoryOptions = _.cloneDeep(DEFAULT_STORY_OPTIONS);

        if (Object.keys(defaultStoryOptions).includes('selectedStoryId')) {
            delete defaultStoryOptions.selectedStoryId;
        }

        commit(TYPES.MUTATIONS.PARTIAL_STORY_UPDATE, defaultStory);
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            ...defaultStoryOptions,
            retailersOptions: state.retailersOptions,  // Let's keep the preloaded retailersOptions
        });
    },
    [TYPES.ACTIONS.ADD_MISSING_PLATFORM_STORIES]: (
        { commit, state },
        {
            enabledPlatforms,
            defaultEnabled,
            options = {
                gbpPlatformId:          4,
                gpbPlatformDefaultMisc: { cta: 'CALL' },
            },
        },
    ) => {
        if (!enabledPlatforms.length) {
            return;
        }

        // TODO: Make this better. But as it stands, storyAssets should be the same for all of them
        // and we just need a copy
        const storyAssets = state.story.platformStories.length
            ? state.story.platformStories[0].storyAssets
            : [];

        // Filter existing platformStories
        const platformStories = state.story.platformStories.filter((ps) =>
            enabledPlatforms.includes(ps.platformId),
        );

        // Add missing platforms
        enabledPlatforms
            .filter((platform) => {
                return !platformStories
                    .map((ps) => ps.platformId)
                    .includes(platform);
            })
            .forEach((platformId) => {
                commit(TYPES.MUTATIONS.ADD_PLATFORM_STORY, {
                    platformId:     platformId,
                    enabled:        defaultEnabled,
                    message:        state.story.message ?? state.story.contents,
                    storyAssets:    storyAssets,
                    selectedAssets: [],
                    misc:
                        platformId == options.gbpPlatformId
                            ? options.gpbPlatformDefaultMisc
                            : {},
                });
            });
    },
    [TYPES.ACTIONS.PLATFORMS_SCHEDULED_POSTS_DISABLED]: async (
        {commit},
        {
            enabledPlatforms,
            poolId,
        },
    ) => {
        if (!enabledPlatforms.length) {
            return;
        }
        let response = await axios.get(`/api/master_dashboard/children?pool_id=${poolId}`);
        let facebook_has_campaigns = response.data.data.facebook_has_campaigns;
        let twitter_has_campaigns = response.data.data.twitter_has_campaigns;
        let instagram_has_campaigns = response.data.data.instagram_has_campaigns;
        let google_has_campaigns = response.data.data.google_has_campaigns;
        let linkedin_has_campaigns = response.data.data.linkedin_has_campaigns;
        let platformCampaigns = [
            {platformId: 1, hasCampaigns: facebook_has_campaigns},
            {platformId: 2, hasCampaigns: twitter_has_campaigns},
            {platformId: 3, hasCampaigns: instagram_has_campaigns},
            {platformId: 4, hasCampaigns: google_has_campaigns},
            {platformId: 5, hasCampaigns: linkedin_has_campaigns},
        ];

        let noCampaign = platformCampaigns
            .filter((platform) => {
                return platform.hasCampaigns === false;
            }).map(platform => platform.platformId);

        commit(TYPES.MUTATIONS.PLATFORM_CAMPAIGN_DISABLED,
            noCampaign,
        );
    },
    [TYPES.ACTIONS.ADD_PLATFORM_STORY_ALL]: ({ commit, state }) => {
        const allSelectedAssets = [
            ...new Set(
                state.story.platformStories
                    .filter((ps) => !state.story.exclude_from_sync.includes(ps.platformId))
                    .map((ps) => ps.selectedAssets)
                    .flat(),
            ),
        ];
        const allPlatformWereExcludes = state.story.platformStories.find((ps) => !state.story.exclude_from_sync.includes(ps.platformId) && ps.enabled) === undefined;
        const { storyAssets, assetPlatformStories, message } = !allPlatformWereExcludes
            ? state.story.platformStories.find((ps) => !state.story.exclude_from_sync.includes(ps.platformId))
            : state.story.platformStories.find((ps) => ps.enabled);
        let url = '';
        const ps = state.story.platformStories[0] || {};
        if (state.story.exclude_from_sync.length > 0) {
            url = ps.misc && ps.misc.url ? ps.misc.url : state.story.url;
        } else {
            url = state.story.url;
        }
        commit(TYPES.MUTATIONS.ADD_PLATFORM_STORY, {
            platformId:     'all',
            enabled:        true,
            message,
            storyAssets,
            selectedAssets: allSelectedAssets,
            assetPlatformStories,
            misc:           {
                url,
            },
        });
    },
    [TYPES.ACTIONS.LOAD_LANGUAGES]: async ({ commit }, { curatorHandler, oemManagerId }) => {
        const allLanguages = await curatorHandler.get('languages');
        const defaultLanguages = await axios.get(`/api/oem-managers/${oemManagerId}/posting-defaults`);
        const brandLanguages = allLanguages.data.languages.filter(item => defaultLanguages.data.languages?.toString().split(',').map(Number).includes(item.id));
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { languageOptions: brandLanguages });
    },
    [TYPES.ACTIONS.LOAD_REGIONS]: async (
        { commit },
        { curatorHandler, poolId },
    ) => {
        const {
            data: { regions },
        } = await curatorHandler.get(`pool/${poolId}/regions`);
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { regions });
    },
    [TYPES.ACTIONS.LOAD_DYNAMIC_LENGTH]: async (
        { commit },
        { curatorHandler },
    ) => {
        const {
            data: { data },
        } = await curatorHandler.get('/dynamic-fields');
        let dynamicLength = {};
        data.forEach(dynamic => {
            dynamicLength[dynamic.field] = dynamic.count_value;
        });
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { dynamicLength });
    },
    [TYPES.ACTIONS.LOAD_PLATFORM]: async ({ commit }, { listenerHandler }) => {
        const {
            data: { platforms },
        } = await listenerHandler.get('platforms');
        const availablePlatforms = platforms.map((p) => p.id);
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { availablePlatforms });
    },
    [TYPES.ACTIONS.LOAD_CLASSIFIERS_AND_POOL_IDS]: async (
        { commit },
        { curatorHandler, poolId, storyId, isNewStory = false },
    ) => {
        const oldStoryQs = `?pool_id=${poolId}&includes=classifiers,pools`;
        const url = isNewStory
            ? `pool/${poolId}/sources/default`
            : `/v2/story/${storyId}${oldStoryQs}`;
        const {
            data: {
                [isNewStory ? 'data' : 'story']: { classifiers, pools },
            },
        } = await curatorHandler.get(url);
        commit(TYPES.MUTATIONS.PARTIAL_STORY_UPDATE, {
            classifiers: [...classifiers.map(({ id }) => id)],
            pools:       [...pools.map(({ id }) => id)],
        });
    },
    [TYPES.ACTIONS.LOAD_SELECTED_LANGUAGES]: async (
        { commit },
        { curatorHandler, poolId, isNewStory = false },
    ) => {
        if (!isNewStory) {
            return;
        }
        const url = `pool/${poolId}/sources/default`;
        const {
            data: {
                ['data']: { languages },
            },
        } = await curatorHandler.get(url);
        commit(TYPES.MUTATIONS.PARTIAL_STORY_UPDATE, {
            languages: [...languages.map(({ id }) => id)],
        });
    },
    [TYPES.ACTIONS.LOAD_CAMPAIGNS_OPTIONS]: async (
        { commit },
        { curatorHandler, poolId },
    ) => {
        const {
            data: { active: campaigns },
        } = await curatorHandler.get(`pool/${poolId}/campaigns`);
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            campaignOptions: campaigns.map((c) => ({ ...c, name: c.title })),
        });
        const {
            data: { inactive: inactiveCampaigns },
        } = await curatorHandler.get(`pool/${poolId}/campaigns`);
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            inactiveCampaignOptions: inactiveCampaigns.map((c) => ({
                ...c,
                name: c.title,
            })),
        });
    },
    [TYPES.ACTIONS.LOAD_CAMPAIGNS_PLATFORMS]: ({ commit, state, getters }) => {
        if (getters.campaign && state.story.platforms) {
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                platformTwitter:  state.story.platforms.indexOf('FACEBOOK') >= 0,
                platformFacebook: state.story.platforms.indexOf('TWITTER') >= 0,
            });
        }
    },
    [TYPES.ACTIONS.LOAD_CAMPAIGNS_SCHEDULED_AT]: ({ commit, getters }) => {
        if (getters.campaign && getters.campaign.scheduled_at) {
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                scheduledAt: dayjs.utc(getters.campaign.scheduled_at),
            });
        }
    },
    [TYPES.ACTIONS.LOAD_POPULATED_INPUTS]: ({ commit, dispatch, state }) => {
        let populatedInputs = undefined;
        state.story.platformStories.forEach((ps) => {
            populatedInputs = {
                1: false,
                2: false,
                3: false,
                4: false,
            };
            if (state.story.post_type !== 'TEXT') {
                populatedInputs[5] = false;
            }
            commit(TYPES.MUTATIONS.ADD_POPULATED_INPUTS, {
                ...populatedInputs,
                platformId: ps.platformId,
            });
            dispatch(TYPES.ACTIONS.CALCULATE_POPULATED_INPUTS, {
                platformId: ps.platformId,
            });
        });
    },
    [TYPES.ACTIONS.LOAD_INITIAL_STORY_STATUS]: ({ commit }) => {
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { storyStatus: 'move-content' });
    },
    [TYPES.ACTIONS.CALCULATE_ALL_POPULATED_INPUTS]: ({
        commit,
        dispatch,
        getters,
        state,
    }) => {
        state.story.platformStories.forEach((ps) => {
            dispatch(TYPES.ACTIONS.CALCULATE_POPULATED_INPUTS, {
                platformId: ps.platformId,
            });
        });
        if (state.selectedPlatformStory === null) {
            return {};
        }
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            populatedInputsForSelectedPlatformStory: getters.getPopulatedInput({
                platformId: state.selectedPlatformStory,
            }),
        });
        dispatch(TYPES.ACTIONS.CALCULATE_IS_FILLED_COMPLETELY);
        dispatch(TYPES.ACTIONS.CALCULATE_DESTINATIONS_ARROWS);
    },
    [TYPES.ACTIONS.CALCULATE_POPULATED_INPUTS]: (
        { commit, state, getters },
        { platformId },
    ) => {
        if (!state.story.platformStories) {
            return;
        }
        const populatedInputsForPlatformStory = JSON.parse(
            JSON.stringify(getters.getPopulatedInput({ platformId })),
        );
        if (state.story.post_type.toUpperCase() === 'TEXT') {
            delete populatedInputsForPlatformStory[state.storyStatus !== 'scheduled' ? 5 : 6];
        }
        let reduce = state.story.post_type.toUpperCase() === 'TEXT' ? 1 : 0;
        let platformStory = getters.getPlatformStory({ platformId });

        // URL
        if (state.story.post_type === 'URL') {
            if (platformStory && platformStory.misc.url) {
                populatedInputsForPlatformStory[1] = true;
            } else if (
                platformId === 'all' &&
                state.story.platformStories[0].misc.url
            ) {
                populatedInputsForPlatformStory[1] = true;
            } else {
                populatedInputsForPlatformStory[1] = false;
            }
        }

        // Photo
        if (state.story.post_type === 'PHOTO') {
            if (getters.selectedPhotoAssets({ platformId }).length) {
                populatedInputsForPlatformStory[1] = true;
            } else {
                populatedInputsForPlatformStory[1] = false;
            }
        }

        // Video
        if (state.story.post_type === 'VIDEO') {
            if (getters.selectedVideoAssets({ platformId }).length) {
                populatedInputsForPlatformStory[1] = true;
            } else {
                populatedInputsForPlatformStory[1] = false;
            }
        }

        // Caption message
        if (platformStory.message && !getters.hasCharErrors({ platformId })) {
            populatedInputsForPlatformStory[2 - reduce] = true;
        } else {
            populatedInputsForPlatformStory[2 - reduce] = false;
        }

        // Date range
        if (state.story.scheduled_at === null) {
            if (state.story.expires_at && state.story.published_at) {
                populatedInputsForPlatformStory[3 - reduce] = true;
            } else {
                populatedInputsForPlatformStory[3 - reduce] = false;
            }
        } else {
            if (state.story.scheduled_date) {
                populatedInputsForPlatformStory[3 - reduce] = true;
            } else {
                populatedInputsForPlatformStory[3 - reduce] = false;
            }
            if (state.story.scheduled_time) {
                populatedInputsForPlatformStory[4 - reduce] = true;
            } else {
                populatedInputsForPlatformStory[4 - reduce] = false;
            }
        }

        reduce = state.story.scheduled_at === null ? reduce : reduce - 1;
        if (state.story.is_distribute_by_owner) {
            // Campaign by owner ids
            if (state.story.distribute_by_owner_ids.length) {
                populatedInputsForPlatformStory[4 - reduce] = true;
            } else {
                populatedInputsForPlatformStory[4 - reduce] = false;
            }

            // Regions - Ignored validations
            populatedInputsForPlatformStory[99] = true;

            // Languages - Ignored validations
            populatedInputsForPlatformStory[6 - reduce] = true;

            //Products - Ignored validations
            populatedInputsForPlatformStory[5 - reduce] = true;
        } else {
            // Classifiers
            if (!getters.hasOrphanClassifiers) {
                populatedInputsForPlatformStory[4 - reduce] = true;
            } else {
                populatedInputsForPlatformStory[4 - reduce] = false;
            }

            // Regions - Ignored validations
            // Using 99 for inputs with no validation
            populatedInputsForPlatformStory[99] = true;

            // Languages
            if (state.story.languages && state.story.languages.length) {
                populatedInputsForPlatformStory[5 - reduce] = true;
            } else {
                populatedInputsForPlatformStory[5 - reduce] = false;
            }
        }

        commit(TYPES.MUTATIONS.UPDATE_POPULATED_INPUT, {
            populatedInputsForPlatformStory,
        });
    },
    [TYPES.ACTIONS.CALCULATE_DESTINATIONS_ARROWS]: ({
        commit,
        getters: {
            platformHasErrors,
            platformStoriesToEnable,
            selectedPlatformId,
        },
        state: { enabledPlatforms },
    }) => {
        const destinationsWithArrows = {};
        platformStoriesToEnable
            .filter((ps) => enabledPlatforms.includes(ps.platformId))
            .forEach(
                (ps) =>
                    (destinationsWithArrows[ps.platformId] = platformHasErrors({
                        platformId: ps.platformId,
                    })),
            );
        if (Object.values(destinationsWithArrows).every((k) => k)) {
            platformStoriesToEnable.forEach(
                (ps) => (destinationsWithArrows[ps.platformId] = false),
            );
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                destinationsWithArrows: destinationsWithArrows,
            });
            return;
        }
        const filteredDestinations = Object.keys(destinationsWithArrows).filter(
            (ps) => enabledPlatforms.includes(ps.platformId),
        );
        const newDestinationsWithArrows = {};
        filteredDestinations.forEach(
            (d) => (newDestinationsWithArrows[d] = false),
        );
        platformStoriesToEnable
            .filter((ps) => enabledPlatforms.includes(ps.platformId))
            .forEach((ps) => {
                if (ps.platformId == selectedPlatformId) {
                    newDestinationsWithArrows[ps.platformId] = false;
                } else if (ps.enabled === false) {
                    newDestinationsWithArrows[ps.platformId] = false;
                } else if (
                    platformHasErrors({ platformId: ps.platformId }) &&
                    Object.values(newDestinationsWithArrows).every(
                        (k) => k === false,
                    ) &&
                    platformHasErrors({
                        platformId: Number(selectedPlatformId),
                    }) === false
                ) {
                    newDestinationsWithArrows[ps.platformId] = true;
                } else {
                    newDestinationsWithArrows[ps.platformId] = false;
                }
            });
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            destinationsWithArrows: newDestinationsWithArrows,
        });
    },
    [TYPES.ACTIONS.CALCULATE_SELECTED_PLATFORM_DIFFERENCES]: _.debounce((
        { commit, state, getters },
    ) => {
        const platformStoryHaveEqualAssetsOfType = (mediaType) => {
            const availableAssets = getters.platformStoriesWithoutAll.filter(ps => ps.platformId == getters.selectedPlatformId).map(ps => {
                return ps.storyAssets.filter(sa => sa.type == mediaType).map(sap => sap.id);
            });
            const allAvailableAssetsAreEqual = availableAssets.every((aa, i, availableAssets) => String(aa) === String(availableAssets[0]) || aa.length === 0);
            const allSelectedAssetsAreEqual = platformStorysHaveEqualSelectedAssets(availableAssets);
            return allSelectedAssetsAreEqual && allAvailableAssetsAreEqual;
        };

        const platformStorysHaveEqualSelectedAssets = (availableAssets) => {
            const uniqueAvailableAssets = [...new Set(availableAssets.flat())];
            let selectedAssets = state.story.platformStories.filter(ps => ps.platformId == getters.selectedPlatformId)
                .map(({ selectedAssets }) => String(selectedAssets.filter(sa => uniqueAvailableAssets.includes(sa))))[0];
            if (selectedAssets.length > 0) {
                selectedAssets = selectedAssets.split(',').map(item => item.trim());
            }
            if (selectedAssets.length !== getters.platformStoryAll.selectedAssets.length) {
                return false;
            }
            for (let i = 0; i < getters.platformStoryAll.selectedAssets.length; i++) {
                if (selectedAssets[i] != getters.platformStoryAll.selectedAssets[i]) {
                    return false;
                }
            }
            return true;
        };
        let isModified = true;
        if (getters.platformStoriesWithoutAll.length === 1 || state.isOriginalState) {
            return true;
        } else if (state.story.post_type === 'TEXT') {
            const messageIsEqual = getters.platformStoriesWithoutAll.filter(ps => ps.platformId == getters.selectedPlatformId)[0]?.message === getters.platformStoryAll.message;
            isModified = messageIsEqual;
            commit(TYPES.MUTATIONS.UPDATE_SELECTED_PLATFORM_STORY_CHANGES, {
                isModified,
            });
        } else if (state.story.post_type === 'URL') {
            const messageIsEqual = getters.platformStoriesWithoutAll.filter(ps => ps.platformId == getters.selectedPlatformId)[0]?.message === getters.platformStoryAll.message;
            const urlIsEqual = getters.platformStoriesWithoutAll.filter(ps => ps.platformId == getters.selectedPlatformId)[0]?.misc?.url === getters.platformStoryAll.misc.url;
            isModified = (messageIsEqual && urlIsEqual);
            commit(TYPES.MUTATIONS.UPDATE_SELECTED_PLATFORM_STORY_CHANGES, {
                isModified,
            });
        } else if (state.story.post_type === 'PHOTO') {
            const messageIsEqual = getters.platformStoriesWithoutAll.filter(ps => ps.platformId == getters.selectedPlatformId)[0]?.message == getters.platformStoryAll.message;
            const photosAreEqual = platformStoryHaveEqualAssetsOfType(PHOTO_TYPE_ID);
            isModified = (messageIsEqual && photosAreEqual);
            commit(TYPES.MUTATIONS.UPDATE_SELECTED_PLATFORM_STORY_CHANGES, {
                isModified,
            });
        } else if (state.story.post_type === 'VIDEO') {
            const messageIsEqual = getters.platformStoriesWithoutAll.filter(ps => ps.platformId == getters.selectedPlatformId)[0]?.message == getters.platformStoryAll.message;
            const videosAreEqual = platformStoryHaveEqualAssetsOfType(VIDEO_TYPE_ID);
            isModified = (messageIsEqual && videosAreEqual);
            commit(TYPES.MUTATIONS.UPDATE_SELECTED_PLATFORM_STORY_CHANGES, {
                isModified,
            });
        }

        if (!isModified && getters.selectedPlatformId != 'all') {
            commit(TYPES.MUTATIONS.UPDATE_PLATFORM_TO_KEEP_CONTENT,
                [parseInt(getters.selectedPlatformId)],
            );
        }

        return false;
    }, 500),
    [TYPES.ACTIONS.CALCULATE_STORY_STATUS]: (
        { commit, dispatch },
        { status },
    ) => {
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { storyStatus: status });
        dispatch(TYPES.ACTIONS.UPDATE_STORY_BASED_ON_STORY_STATUS, { status });
    },
    [TYPES.ACTIONS.UPDATE_STORY_BASED_ON_STORY_STATUS]: (
        { commit, state },
        { status },
    ) => {
        const forward = [
            'denied',
            'approved',
            'approved-for-distribution',
            'ready-for-approval',
        ];

        commit(TYPES.MUTATIONS.PARTIAL_STORY_UPDATE, { archived_at: null });
        if (forward.includes(status)) {
            commit(TYPES.MUTATIONS.PARTIAL_STORY_UPDATE, {
                approval:   status,
                expires_at: state.story.old_expires_at,
            });
        } else if (status === 'expired') {
            commit(TYPES.MUTATIONS.PARTIAL_STORY_UPDATE, {
                approval:   null,
                expires_at: dayjs(new Date())
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD'),
            });
        } else if (status === 'pending' || status === 'pending-curation') {
            commit(TYPES.MUTATIONS.PARTIAL_STORY_UPDATE, {
                approval:             null,
                is_filled_completely: false,
                expires_at:           state.story.old_expires_at,
            });
        } else if (status === 'info-required') {
            commit(TYPES.MUTATIONS.PARTIAL_STORY_UPDATE, {
                approval:             null,
                is_copied:            true,
                is_filled_completely: false,
                expires_at:           state.story.old_expires_at,
            });
        } else if (status === 'archived') {
            commit(TYPES.MUTATIONS.PARTIAL_STORY_UPDATE, {
                approval:    null,
                archived_at: dayjs(new Date()).format('YYYY-MM-DD'),
            });
        }
    },
    [TYPES.ACTIONS.LOAD_HISTORY]: ({
        commit,
        state: { story, isNewStory },
    }) => {
        if (isNewStory) {
            return;
        }
        const url = `/api/story-actions?story_id=${story.id}`;
        return axios.get(url).then(({ data }) =>
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                history: data.history,
            }),
        );
    },
    [TYPES.ACTIONS.LOAD_SELECTED_PLATFORM]: ({
        commit,
        getters,
        state: { enabledPlatforms: enabledPlatformsForOwner },
    }) => {
        const enabledPlatforms = {};
        enabledPlatformsForOwner.forEach((v, i) => {
            enabledPlatforms[v] =
                !getters.canDisplayAllPlatformStory && i === 0;
        });
        const selectedPlatform = {
            all: getters.canDisplayAllPlatformStory,
            ...enabledPlatforms,
        };
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { selectedPlatform });
        const selectedPlatformStory = Object.entries(selectedPlatform).find(
            (val) => val[1] === true,
        )[0];
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { selectedPlatformStory });
    },
    [TYPES.ACTIONS.UPDATE_PLATFORM_STORY_OPTIONS_GBP_CTA]: (
        { commit, state },
        { enabled },
    ) => {
        const gbpPlatform = _.cloneDeep(
            state.story.platformStories.find(
                (ps) => ps.platformId == Platform.GBP,
            ),
        );
        if (gbpPlatform) {
            gbpPlatform.misc.cta =
                PlatformStoryConfig[Platform.GBP].misc.cta.save(enabled);
            commit(TYPES.MUTATIONS.UPDATE_PLATFORM_STORY, {
                platformId: Platform.GBP,
                payload:    { misc: gbpPlatform.misc },
            });
        }
    },
    [TYPES.ACTIONS.UPDATE_PLATFORM_STORY_ENABLE]: (
        { commit, dispatch },
        { platformId, payload },
    ) => {
        commit(TYPES.MUTATIONS.UPDATE_PLATFORM_STORY, { platformId, payload });
        dispatch(TYPES.ACTIONS.UPDATE_STORIES_ENABLED_BY_USER, {
            platformId,
            payload,
        });
        //enable google cta
        if (platformId == Platform.GBP) {
            dispatch(TYPES.ACTIONS.UPDATE_PLATFORM_STORY_OPTIONS_GBP_CTA, {
                enabled: payload.enabled,
            });
        }
    },
    [TYPES.ACTIONS.FILTER_SELECTED_ASSETS_FROM_POST_TYPE]: ({
        state,
        commit,
    }) => {
        state.story.platformStories.forEach((ps, i) => {
            const postTypeId = ['PHOTO', 'URL'].includes(state.story.post_type) ? 1 : (state.story.post_type === 'VIDEO' ? 2 : null);

            const selectedAssets = ps.storyAssets
                .filter(
                    (sa) =>
                        ps.selectedAssets.includes(sa.id) &&
                        sa.type === postTypeId,
                )
                .map(({ id }) => id);
            state.story.platformStories[i].selectedAssets = selectedAssets;
        });
    },
    [TYPES.ACTIONS.CALCULATE_IS_FILLED_COMPLETELY]: ({
        commit,
        getters,
        state,
    }) => {
        const is_filled_completely = state.populatedInputs
            .filter((pi) => {
                const platformStory = getters.getPlatformStory({
                    platformId: pi.platformId,
                });
                return platformStory.enabled && pi.platformId != 'all';
            })
            .map((p) => Object.values(p))
            .flat()
            .every((s) => Boolean(s));
        const isFilledCompletelyAllPlatform = is_filled_completely;
        commit(TYPES.MUTATIONS.PARTIAL_STORY_UPDATE, { is_filled_completely });
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            isFilledCompletelyAllPlatform,
        });
    },
    [TYPES.ACTIONS.SYNCHRONIZE_PLATFORM_STORIES]: ({
        commit,
        getters,
        dispatch,
        state,
    }) => {
        const allPlatformStory = _.cloneDeep(getters.platformStoryAll);
        const gbp = _.cloneDeep(
            state.story.platformStories.find(
                (ps) => ps.platformId == Platform.GBP,
            ),
        );

        if (gbp) {
            dispatch(TYPES.ACTIONS.UPDATE_PLATFORM_STORY_OPTIONS_GBP_CTA, {
                enabled: gbp.misc.cta,
            });
        }
        commit(TYPES.MUTATIONS.UPDATE_PLATFORM_STORY, { platformId: 'all', payload: { message: allPlatformStory.message } });
        const synchronizedSelectedAssets = allPlatformStory.selectedAssets;
        state.story.platformStories.forEach((platformStory, i) => {
            state.story.platformStories[i].selectedAssets = [];
        });

        dispatch(TYPES.ACTIONS.UPDATE_PLATFORM_STORY_SELECTED_ASSETS, {
            platformId:          'all',
            payload:             { selectedAssets: synchronizedSelectedAssets },
            emptySelectedAssets: true,
        });

        dispatch(TYPES.ACTIONS.CALCULATE_ALL_POPULATED_INPUTS);
    },
    [TYPES.ACTIONS.LOAD_PLATFORM_STORIES_CONFIGS]: ({ commit, state }) => {
        let platformStoriesConfigs = {};
        state.story.platformStories.forEach((ps) => {
            platformStoriesConfigs = {
                ...platformStoriesConfigs,
                [ps.platformId]: _.cloneDeep(
                    PlatformStoryConfig[ps.platformId],
                ),
            };
        });
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { platformStoriesConfigs });
    },
    [TYPES.ACTIONS.CLEAR_ERRORS]: ({ commit, state }) => {
        let platformStoriesErrors = {};
        if (state.selectedPlatformStory === 'all') {
            state.story.platformStories.forEach((ps) => {
                platformStoriesErrors = {
                    ...platformStoriesErrors,
                    [ps.platformId]: _.cloneDeep(
                        DEFAULT_PLATFORM_STORIES_ERRORS,
                    ),
                };
            });
        } else {
            platformStoriesErrors = _.cloneDeep(state.platformStoriesErrors);
            platformStoriesErrors[state.selectedPlatformStory] = _.cloneDeep(
                DEFAULT_PLATFORM_STORIES_ERRORS,
            );
        }
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { platformStoriesErrors });
    },
    [TYPES.ACTIONS.UPDATE_CLASSIFIERS_BASED_ON_REMOVED_BRAND_MANAGER]: (
        { commit, dispatch, state, rootGetters },
        { brandManagers, oldBrandManagers },
    ) => {
        const brandManagerToRemove = oldBrandManagers.find(
            (o) => !brandManagers.includes(o),
        );
        const bufferToRemove = state.buffers.find(
            (b) => b.oemManagerId === brandManagerToRemove,
        );
        if (!bufferToRemove) {
            return;
        }
        const tipTier = rootGetters['tiers/getLeafTier'](
            bufferToRemove.bufferId,
        );
        let classifiersToRemove = [];

        for (let i = 0; i <= tipTier; i++) {
            const classifiersFromTier = rootGetters[
                'tiers/getClassifiersByTier'
            ](bufferToRemove.poolId, i).map(({ id }) => id);
            classifiersToRemove = [
                ...new Set([...classifiersToRemove, ...classifiersFromTier]),
            ];
        }

        const newClassifiers = state.story.classifiers.filter(
            (c) => !classifiersToRemove.includes(c),
        );

        dispatch(
            'tiers/selectClassifiers',
            {
                bufferId:        bufferToRemove.bufferId,
                classifierIds:   newClassifiers,
                shouldOverwrite: true,
            },
            { root: true },
        );

        commit(TYPES.MUTATIONS.PARTIAL_STORY_UPDATE, {
            classifiers: newClassifiers,
        });
        dispatch(TYPES.ACTIONS.CALCULATE_ALL_POPULATED_INPUTS);
    },
    [TYPES.ACTIONS.CLEAR_PLATFORMS_SELECTED_ASSETS]: ({ state }) => {
        state.story.platformStories.forEach((ps, i) => {
            if (ps.platformId !== 'all' && !state.story.exclude_from_sync.includes(state.story.platformStories[i].platformId)) {
                state.story.platformStories[i].selectedAssets = [];
            }
        });
    },
    [TYPES.ACTIONS.CLEAR_SELECTED_ASSET]: (
        { state },
        { assetId },
    ) => {
        state.story.platformStories.forEach((ps, id) => {
            if (state.story.exclude_from_sync.includes(state.story.platformStories[i].platformId)) {
                return;
            }
            ps.storyAssets.forEach((sa, said) => {
                if (sa.id === assetId) {
                    state.story.platformStories[id].storyAssets.splice(said, 1);
                    state.story.platformStories[id].selectedAssets = state.story.platformStories[id].selectedAssets.filter(id => id !== assetId );
                }
            });
        });
    },
    [TYPES.ACTIONS.UPDATE_PLATFORM_STORY_SELECTED_ASSETS]: (
        { state, getters, dispatch },
        { platformId, payload, emptySelectedAssets = false, forceSync= false},
    ) => {
        const newSelectedAssets = payload.selectedAssets;
        if (emptySelectedAssets) {
            dispatch(TYPES.ACTIONS.CLEAR_PLATFORMS_SELECTED_ASSETS);
        }
        const calculateAssetsToUpdate = ({ i }) => {
            if (emptySelectedAssets === false) {
                return { assets: payload.selectedAssets, isAdding: false };
            }

            const oldSelectedAssets =
                state.story.platformStories[i].selectedAssets;
            const selectingAssets =
                newSelectedAssets.length > oldSelectedAssets.length;
            if (selectingAssets) {
                const selectedPlatformId =
                    state.story.platformStories[i].platformId;
                const mediaConfig = getters
                    .getPlatformStoryConfig({
                        platformId: selectedPlatformId,
                    })
                    .media();
                const difference = newSelectedAssets.filter(
                    (asset) => !oldSelectedAssets.includes(asset),
                );

                const differenceFiltered = difference.filter((sa) =>
                    getters.assetIsValidToBeSelected({
                        platformId: selectedPlatformId,
                        assetId:    sa,
                    }),
                );

                if (mediaConfig.photo.limit <= oldSelectedAssets.length) {
                    return { assets: null, isAdding: false };
                }
                const assetsToAdd =
                    mediaConfig.photo.limit - oldSelectedAssets.length;
                const selectedAssets = [
                    ...oldSelectedAssets,
                    ...differenceFiltered.splice(0, assetsToAdd),
                ];
                const assetPlatformStories = state.story.platformStories[i].assetPlatformStories == null
                    ? []
                    : state.story.platformStories[i].assetPlatformStories.map(asset => {
                        asset.enabled = selectedAssets.includes(asset.asset_id);
                        return asset;
                    });
                return {
                    assets:   selectedAssets,
                    assetPlatformStories,
                    isAdding: true,
                };
            } else {
                return {
                    assets:   payload.selectedAssets,
                    isAdding: false,
                };
            }
        };
        for (let i = 0; i < state.story.platformStories.length; i += 1) {
            if (
                state.story.platformStories[i].platformId == platformId || (platformId === 'all' &&
                !state.story.exclude_from_sync.includes(state.story.platformStories[i].platformId
                || forceSync))
            ) {
                const { assets, assetPlatformStories, isAdding } = calculateAssetsToUpdate({ i });
                if (assets === null) {
                    continue;
                }
                state.story.platformStories[i].selectedAssets = assets;
                if (assetPlatformStories) {
                    state.story.platformStories[i].assetPlatformStories = assetPlatformStories;
                }
                const type = ['PHOTO', 'VIDEO'].includes(state.story.post_type)
                    ? state.story.post_type.toLowerCase()
                    : null;
                const selectedAssets =
                    type === 'photo'
                        ? getters.selectedPhotoAssets({ platformId })
                        : getters
                            .selectedVideoAssets({ platformId })
                            .filter((asset) => assets.includes(asset.id));
                if (!emptySelectedAssets || (isAdding && assets.length)) {
                    dispatch(TYPES.ACTIONS.CHECK_MEDIA, {
                        platformId: state.story.platformStories[i].platformId,
                        selectedAssets,
                    });
                }
            }
        }
        if (!state.checkedAssetsOnLoad) {
            state.story.platformStories.forEach((platform, i) => {
                if (platform.platformId === 'all') {
                    return;
                } else {
                    if (!platform.selectedAssets.length && (!state.story.exclude_from_sync.includes(state.story.platformStories[i].platformId) || forceSync)) {
                        state.story.platformStories[0].storyAssets.forEach(
                            (asset) => {
                                state.story.platformStories[
                                    i
                                ].selectedAssets.push(asset.id);
                            },
                        );
                    }
                }
            });
        }
    },
    [TYPES.ACTIONS.CHECK_PLATFORM_HAS_MODIFIED_MEDIA]: ({
        state,
        getters,
        commit,
    }) => {
        let platformStories = _.cloneDeep(state.story.platformStories);
        const newSelectedAssets = platformStories.find(
            (ps) => ps.platformId === 'all',
        ).selectedAssets;
        platformStories.forEach((ps, i) => {
            if (ps.platformId !== 'all') {
                platformStories[i].selectedAssets = [];
            }
        });
        const calculateAssetsFromAll = ({ i }) => {
            const oldSelectedAssets = platformStories[i].selectedAssets;
            const selectedPlatformId =
                state.story.platformStories[i].platformId;
            const mediaConfig = getters
                .getPlatformStoryConfig({
                    platformId: selectedPlatformId,
                })
                .media();
            const difference = newSelectedAssets.filter(
                (asset) => !oldSelectedAssets.includes(asset),
            );
            const differenceFiltered = difference.filter((sa) =>
                getters.assetIsValidToBeSelected({
                    platformId: selectedPlatformId,
                    assetId:    sa,
                }),
            );
            if (mediaConfig.photo.limit <= oldSelectedAssets.length) {
                return { assets: null, isAdding: false };
            }
            const assetsToAdd =
                mediaConfig.photo.limit - oldSelectedAssets.length;
            const selectedAssets = [
                ...oldSelectedAssets,
                ...differenceFiltered.splice(0, assetsToAdd),
            ];
            const assetPlatformStories = state.story.platformStories[i].assetPlatformStories == null
                ? []
                : state.story.platformStories[i].assetPlatformStories?.map(asset => {
                    asset.enabled = selectedAssets.includes(asset.asset_id);
                    return asset;
                });
            return {
                assets: selectedAssets,
                assetPlatformStories,
            };
        };

        let platformHasModifiedMedia = false;
        for (let i = 0; i < platformStories.length; i += 1) {
            if (platformStories[0].platformId === 'all') {
                continue;
            }
            const { assets, assetPlatformStories } = calculateAssetsFromAll({ i });
            if (assets === null) {
                continue;
            }
            const selectedAssets = _.cloneDeep(
                state.story.platformStories[i].selectedAssets,
            );
            if (!assets.every((a, idx) => a == selectedAssets[idx])) {
                platformHasModifiedMedia = true;
            }
            platformStories[i].selectedAssets = assets;
            if (assetPlatformStories) {
                platformStories[i].assetPlatformStories = assetPlatformStories;
            }
        }
        if (
            platformHasModifiedMedia ||
            !getters.platformStoriesHaveEqualMessages
        ) {
            commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { isOriginalState: false });
        }
    },
    [TYPES.ACTIONS.LOAD_NOTES_UNREAD]: ({ commit, state: { story } }) => {
        if (story.id == null) {
            return;
        }
        axios
            .get(`/api/stories/${story.id}/notes/unread`)
            .then((response) => {
                commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
                    unreadNotes: response.data.unread,
                });
            })
            .catch((e) => {
                console.error(e);
            });
    },
    [TYPES.ACTIONS.CALCULATE_UPDATE_ENABLED_PLATFORM_STORIES_POST_TYPE]: ({
        getters,
        state,
        commit,
    }) => {
        let enabledPlatformStories = Object.values(
            getters.enabledPlatformStories,
        );
        let platformEnabled = [];

        if (state.updateEnabledPlatformStoriesByUser.length == 0) {
            platformEnabled = Object.values(
                state.enabledPlatformStoriesSelectedByUser,
            );
        } else {
            platformEnabled = Object.values(
                state.updateEnabledPlatformStoriesByUser,
            );
        }

        const platformStories = enabledPlatformStories.filter(
            (elemento) => platformEnabled.indexOf(elemento) != -1,
        );

        const calculateUpdateEnablePlatform =
            getters.platformStoriesWithoutAll.map((platform) => {
                if (platformStories.includes(platform.platformId)) {
                    platform.enabled = true;
                }
                return platform;
            });
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            calculateUpdateEnablePlatform: calculateUpdateEnablePlatform,
        });
    },
    [TYPES.ACTIONS.INITIALIZE_STORY_ENABLED_BY_USER]: ({ getters, commit }) => {
        let enabledPlatformStoriesSelectedByUser = [];
        getters.platformStoriesWithoutAll.forEach((platform) => {
            if (platform.enabled) {
                enabledPlatformStoriesSelectedByUser.push(platform.platformId);
            }
        });
        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            enabledPlatformStoriesSelectedByUser:
                enabledPlatformStoriesSelectedByUser,
        });
    },
    [TYPES.ACTIONS.UPDATE_STORIES_ENABLED_BY_USER]: (
        { state, commit },
        { platformId },
    ) => {
        let updateEnabledPlatformStoriesByUser = [];
        if (state.updateEnabledPlatformStoriesByUser.length == 0) {
            updateEnabledPlatformStoriesByUser = Object.values(
                state.enabledPlatformStoriesSelectedByUser,
            );
        } else {
            updateEnabledPlatformStoriesByUser = Object.values(
                state.updateEnabledPlatformStoriesByUser,
            );
        }

        let index = updateEnabledPlatformStoriesByUser.indexOf(platformId);
        if (index == -1) {
            updateEnabledPlatformStoriesByUser.push(platformId);
        } else {
            updateEnabledPlatformStoriesByUser.splice(index, platformId);
        }

        commit(TYPES.MUTATIONS.PARTIAL_UPDATE, {
            updateEnabledPlatformStoriesByUser:
                updateEnabledPlatformStoriesByUser,
        });
    },
    [TYPES.ACTIONS.LOAD_ASSETS_FOR_URL]: async ({ state, commit }) => {
        const platformStories = state.story.platformStories.filter(
            (ps) => ps.enabled,
        );
        const psData = platformStories ? platformStories[0] : null;

        if (psData) {
            let api_url = '/api/curator/assets';
            const image_url = psData.misc.image_url;
            const url = psData.misc.url;
            const title = psData.misc.title;
            const type = 'photo';

            const id = state.story.id;
            const create = !id ? true : false;
            const update = id && url != state.story.url;

            if (create || update) {
                if (update) {
                    api_url = `/api/curator/assets/?story_id=${id}&updating=1`;
                }

                const asset = await axios.post(api_url, {
                    assets: [{ url: image_url, type: type }],
                });

                commit(TYPES.MUTATIONS.UPDATE_ASSETS_FOR_URL, {
                    storyAssets:    asset.data.assets,
                    selectedAssets: asset.data.assets.map((asset) => asset.id),
                    title,
                });
            }
        }
    },
    [TYPES.ACTIONS.LOAD_RETAILERS]: async ({ commit }, { oemManagerId }) => {
        const params = { simple: 1, is_active: true, append_location: true };

        return axios.get(`/api/oem-managers/${oemManagerId}/dealerships`, { params })
            .then(({ data: { dealerships } }) => {
                commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { retailersOptions: dealerships });
            })
            .catch(error => console.error({ error }));
    },
    [TYPES.ACTIONS.LOAD_OEM_MANAGER]: async ({ commit }, { oemManagerId }) => {
        return axios.get(`/api/oem-managers/${oemManagerId}`)
            .then(({ data }) => {
                commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { currentOemManager: data });
            })
            .catch(error => console.error({ error }));
    },
    [TYPES.ACTIONS.LOAD_OEM_MANAGER_SETTINGS]: async ({ commit }, { oemManagerId }) => {
        return axios.get(`/api/oem-managers/${oemManagerId}/settings`)
            .then(({ data }) => {
                commit(TYPES.MUTATIONS.PARTIAL_UPDATE, { oemManagerSettings: data });
            })
            .catch(error => console.error({ error }));
    },
};

import api from "!../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../node_modules/css-loader/dist/cjs.js!../../../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-3.use[3]!../../../../../../node_modules/vue-loader/dist/index.js??ruleSet[1].rules[8].use[0]!./PageNotFound2.vue?vue&type=style&index=0&id=313fd3d6&scoped=true&lang=css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};
export default {
    name: 'Facebook',
    char() {
        return {
            limit:                63206,
            link:                 true,
            emoji:                true,
            hashtag:              true,
            mention:              true,
            brandRequiresDynamic: false,
        };
    },
    media(vm = null) {
        let limits = {};
        if (vm && vm.hasUrl) {
            limits = {
                photo: {
                    limit:    0,
                    messages: {
                        limit: `URL posts use default associated photo, if applicable.
                                URL posts will not use uploaded imagery per Facebook Community Guidelines.`,
                    },
                },
                video: {
                    limit:    0,
                    messages: {
                        limit: `URL posts use default associated photo, if applicable.
                                URL posts will not use uploaded imagery per Facebook Community Guidelines.`,
                    },
                },
            };
        } else {
            limits = {
                photo: {
                    resolution: {
                        min: {
                            width:  400,
                            height: 400,
                        },
                    },
                    size:  4 * 1024 * 1024, // 4 mb
                    limit: 1000,
                    // Minimum 1 photo required for some of the new modal stuff
                    // but we want photo.min = 0 if there are videos or if TEXT post
                    min:   vm && vm.video && vm.video.length ? 0 : vm && vm.postType==='TEXT' ? 0 : 1,
                },
                video: {
                    // Need to figure out messages to display in these cases
                    size:     5 * 1024 * 1024 * 1024, // 5gb
                    duration: 240 * 60, // 240 min
                    formats:  [
                        '3g2',
                        '3gp',
                        '3gpp',
                        'asf',
                        'avi',
                        'dat',
                        'divx',
                        'dv',
                        'f4v',
                        'flv',
                        'm2ts',
                        'm4v',
                        'mkv',
                        'mod',
                        'mov',
                        'mp4',
                        'mpe',
                        'mpeg',
                        'mpeg4',
                        'mpg',
                        'mts',
                        'nsv',
                        'ogm',
                        'ogv',
                        'qt',
                        'tod',
                        'ts',
                        'vob',
                        'wmv',
                    ],
                    limit:    1,
                    min:      vm && vm.postType === 'VIDEO' ? 1 : 0,
                    messages: {
                        limit: 'Facebook automation is limited to one video per post.',
                        min:   'Facebook should have at least 1 video per post.',
                    },
                },
            };
        }
        return Object.assign(
            limits,
            {
                mixed: {
                    enabled: false,
                },
            },
        );
    },
    misc: {
        url: {
            type:        'text',
            icon:        'fa-link',
            placeholder: 'URL',
        },
    },
};

<template>
    <div class="blue-arrow-left-icon">
        <svg :width="width" :height="height" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.80452 0.195201C5.9295 0.320225 5.99972 0.489772 5.99972 0.666557C5.99972 0.843342 5.9295 1.01289 5.80452 1.13791L2.27586 4.66676H11.3332C11.51 4.66676 11.6796 4.737 11.8046 4.86203C11.9296 4.98706 11.9999 5.15664 11.9999 5.33346C11.9999 5.51027 11.9296 5.67985 11.8046 5.80488C11.6796 5.92991 11.51 6.00015 11.3332 6.00015H2.27586L5.80452 9.529C5.92596 9.65474 5.99316 9.82315 5.99164 9.99795C5.99012 10.1728 5.92001 10.34 5.7964 10.4636C5.6728 10.5872 5.50559 10.6573 5.33079 10.6588C5.15599 10.6604 4.98759 10.5932 4.86186 10.4717L0.195191 5.80481C0.0702102 5.67979 0 5.51024 0 5.33346C0 5.15667 0.0702102 4.98712 0.195191 4.8621L4.86186 0.195201C4.98688 0.0702138 5.15641 0 5.33319 0C5.50997 0 5.67951 0.0702138 5.80452 0.195201Z" fill="#0066FF" />
        </svg>
    </div>
</template>

<script>
import IconMixin from './IconMixin';
export default {
    name:   'BlueArrowLeftIcon',
    mixins: [ IconMixin ],
};
</script>

<style lang='scss'>
.blue-arrow-left-icon {}
</style>

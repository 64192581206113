<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="system-health-menu-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.2301 6C13.1305 6.13157 13.0394 6.26708 12.9202 6.38732L12.4537 6.85687L9.3879 6.85701L8.78541 5.67175L7.42263 8.33691C7.25829 8.65833 6.7824 8.64815 6.63171 8.32016L5.05014 4.93662L4.22831 6.85848L1.54541 6.85714L1.07884 6.38839C0.960151 6.24375 0.86908 6.13125 0.769531 6H3.64114L4.62377 3.68976C4.77441 3.34476 5.27084 3.33833 5.43165 3.67971L7.02361 7.14575L8.36561 4.52078C8.52653 4.20605 8.98861 4.20605 9.14969 4.52078L9.90643 6.00067H13.2301V6Z" fill="#85878F" />
                <path d="M0.769502 5.99999C-0.41442 4.43572 -0.236654 2.15628 1.3337 0.82665C2.82119 -0.43173 5.09386 -0.20459 6.46129 1.17647L7.00034 1.72102L7.53938 1.17647C8.90626 -0.20459 11.1789 -0.43173 12.6667 0.82665C14.2371 2.15574 14.4148 4.43465 13.2287 6.0016H9.90585L9.14912 4.5217C8.9882 4.20697 8.52612 4.20697 8.36503 4.5217L7.02304 7.14668L5.43299 3.68037C5.27207 3.33886 4.77553 3.34555 4.62511 3.69042L3.64247 6.00066H0.769502V5.99999ZM9.36298 6.85713L8.76049 5.67187L7.39771 8.33702C7.23337 8.65845 6.75748 8.64827 6.60679 8.32028L5.05011 4.93661L4.22828 6.85847L1.54538 6.85713L6.36968 11.7374C6.71591 12.0875 7.28203 12.0875 7.62853 11.7374L12.4528 6.85713H9.36298Z" fill="#DEE0E2" />
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'SystemHealthMenuIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
    props:      {
        fillColorBackground: {
            type:     String,
            required: false,
            default:  '#85878F',
        },
        fillColorPulse: {
            type:     String,
            required: false,
            default:  '#DEE0E2',
        },
    },
};

</script>
<style lang='scss'>
.system-health-menu-icon-container {}
</style>

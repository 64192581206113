export default {
    all:              'All',
    1:                'Facebook',
    2:                'X',
    3:                'Instagram',
    4:                'Google',
    5:                'Linkedin',
    ALL:              'all',
    FACEBOOK:         1,
    TWITTER:          2,
    INSTAGRAM:        3,
    GBP:              4,
    LINKEDIN:         5,
    ABBREVIATED_NAME: {
        1: 'FB',
        2: 'TW',
        3: 'IG',
        4: 'GBP',
        5: 'LI',
    },
    REPORTING_COLUMNS: {
        1: [
            {col: 'likes', name: 'Reactions'},
            {col: 'comments', name: 'Comments'},
            {col: 'shares', name: 'Shares'},
            {col: 'post_clicks', name: 'Post Clicks'},
            {col: 'total_video_views_unique', name: 'Total Video Views'},
            {col: 'post_impressions', name: 'Post Impressions'},
            {col: 'organic_reach', name: 'Organic Reach'},
        ],
        2: [
            {col: 'tw_like_count', name: 'Likes'},
            {col: 'tw_impression_count', name: 'Impressions'},
            {col: 'tw_retweet_count', name: 'Retweets'},
            {col: 'tw_reply_count', name: 'Replies'},
            {col: 'tw_user_profile_clicks', name: 'User Profile Clicks'},
        ],
        3: [
            {col: 'ig_like_count', name: 'Likes'},
            {col: 'ig_comments_count', name: 'Comments'},
            {col: 'ig_saved', name: 'Saved'},
            {col: 'ig_engagement', name: 'Engagement'},
            {col: 'ig_impressions', name: 'Impressions'},
            {col: 'ig_reach', name: 'Reach'},
            {col: 'ig_video_views', name: 'Video Views'},
        ],
        4: [
            { col: 'gbp_local_post_views_search', name: 'Views'},
            { col: 'gbp_local_post_actions_call_to_action', name: 'Call Now - Clicks'},
        ],
        5: [
            {col: 'li_like_count', name: 'Reactions'},
            {col: 'li_impression_count', name: 'Impressions'},
            {col: 'li_comment_count', name: 'Comments'},
            {col: 'li_click_count', name: 'Clicks'},
            {col: 'li_engagement', name: 'Engagement (%)'},
            {col: 'li_share_count', name: 'Shares'},
        ],
    },
    PARENT_PLATFORMS: {
        3: 1,
    },
    getAllPlatforms(withAll=true) {
        return Object.keys(this).filter(p => (withAll && p === 'all') || (typeof p === 'string' && !isNaN(p)));
    },
};

<template>
    <popup-modal
        display-no-text="No"
        display-yes-text="Yes"
        @close="$emit('close')"
        @on-yes-button-click="$emit('on-yes-button-click')"
        @on-no-button-click="$emit('close')"
    >
        <template #icon>
            <img src="/images/popup-icons/information_icon.svg">
        </template>
        <template #header>
            <h1>Exit MultiSelect Mode?</h1>
        </template>
        <template #content>
            <p> You are about to leave this page and lose all changes. </p>
            <p>Do you wish to proceed? </p>
        </template>
        <template #footer />
    </popup-modal>
</template>
<script>
import PopupModal from '@/components/app/PopupModal.vue';

export default {
    components: {
        PopupModal,
    },
    emits: ['on-yes-button-click', 'close'],
};
</script>

<style>

</style>

<template>
    <modal
        class="popup-modal"
        :default-body-class="false"
        :header="useTopHeader"
        :title="topHeaderTitle"
        :footer="false"
        :modal-size="modalSize"
        :padding-top="paddingTop"
        :modal-close-scale="modalCloseScale"
        :close-button="closeButton"
        :static-backdrop="staticBackdrop"
        :show-gradient-icons="showGradientIcons"
        @close="$emit('close')"
    >
        <template #body>
            <div class="popup-modal-body-container">
                <div class="row m-t-5">
                    <div class="text-center icon">
                        <slot name="icon" />
                    </div>
                </div>
                <div v-if="!hideHeader" class="m-t-1">
                    <div class="text-center header">
                        <slot name="header" />
                    </div>
                </div>
                <div class="row m-t-2">
                    <div class="text-center content">
                        <slot name="content" />
                    </div>
                </div>
                <div v-if="!hideFooter" class="row m-t-2">
                    <div class="text-center footer">
                        <slot name="footer">
                            <button
                                v-if="!hideNoButton"
                                class="btn btn-default btn-no"
                                :class="{ disabled: isLoading }"
                                @click="(e) => onButtonClickNo()"
                            >
                                <i
                                    v-if="isLoading"
                                    class="fa fa-spin fa-spinner"
                                />
                                <span>{{ displayNoText }}</span>
                            </button>
                            <button
                                v-if="!hideYesButton"
                                class="btn btn-primary btn-yes"
                                :class="{ disabled: isLoading }"
                                :style="styleButtonYes"
                                @click="(e) => onButtonClickYes()"
                            >
                                <i
                                    v-if="isLoading"
                                    class="fa fa-spin fa-spinner"
                                />
                                <span>{{ displayYesText }}</span>
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/app/Modal.vue';

export default {
    components: {
        Modal,
    },
    emits: ['close', 'on-yes-button-click', 'on-no-button-click', 'on-yes-button-click-block'],
    props: {
        hideFooter: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        hideHeader: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        showCloseButton: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        modalSize: {
            type:     String,
            required: false,
            default:  'md',
        },
        hideYesButton: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        hideNoButton: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        displayNoText: {
            required: false,
            default:  'No',
        },
        displayYesText: {
            required: false,
            default:  'Yes',
        },
        ignoreFinallyCallbackOnYes: {
            required: false,
            default:  false,
        },
        ignoreFinallyCallbackOnNo: {
            required: false,
            default:  false,
        },
        buttonYesWidth: {
            required: false,
            default:  70,
        },
        paddingTop: {
            type:     String,
            required: false,
            default:  '0px',
        },
        modalCloseScale: {
            type:     Number,
            required: false,
            default:  1.4,
        },
        useTopHeader: {
            required: false,
            default:  false,
        },
        topHeaderTitle: {
            type:     String,
            required: false,
            default:  '',
        },
        closeButton: {
            default: true,
        },
        staticBackdrop: {
            default:  false,
            required: false,
        },
        positionFromTop: {
            type:    String,
            default: '',
        },
        showGradientIcons: {
            type:    Boolean,
            Default: false,
        },
    },
    data() {
        return {
            isLoading:      false,
            styleButtonYes: {
                'min-width': this.buttonYesWidth + 'px',
            },
        };
    },
    methods: {
        async onButtonClickNo() {
            this.isLoading = true;
            try {
                await this.$emit('on-no-button-click', false);
            } catch (e) {
                console.error({ e });
            } finally {
                if (this.ignoreFinallyCallbackOnNo !== true) {
                    setTimeout(() => {
                        this.isLoading = false;
                        this.$emit('close');
                    }, 2000);
                } else {
                    this.$emit('close');
                }
            }
        },
        async onButtonClickYes() {
            this.isLoading = true;
            try {
                await this.$emit('on-yes-button-click', true);
            } catch (e) {
                console.error({ e });
            } finally {
                if (this.ignoreFinallyCallbackOnYes !== true) {
                    setTimeout(() => {
                        this.isLoading = false;
                        this.$emit('close');
                    }, 2000);
                } else {
                    this.$emit('close');
                }
            }
        },
    },
};
</script>

<style lang="scss">
.content-modal {
    background-color: #f0f3f6;
}
.m-t-1 {
    margin-top: 1rem;
}
.m-t-2 {
    margin-top: 2rem;
}
.m-t-3 {
    margin-top: 3rem;
}
.m-t-4 {
    margin-top: 4rem;
}
.m-t-5 {
    margin-top: 5rem;
}
.m-t-6 {
    margin-top: 6rem;
}
.popup-modal {
    .popup-modal-body-container {
        padding-bottom: 3rem;
    }
    .modal-content {
        background-color: #f0f3f6;
        border: 5px solid #fff;
    }
}
.icon {
    img {
        width: 50px;
    }
}
.header {
    h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        margin: 0;
    }
}
.content {
    .pop-input {
        margin-top: 1rem;
        border: 1px solid #d6d9db;
        border-radius: 3px;
        padding: 1rem;
        width: 40%;
    }
}
.footer {
    .btn-no {
        margin-right: 1rem;
        min-width: 70px;
    }
    .btn-yes {
        background-color: #0166ff;
        margin-left: 1rem;
    }
}
</style>

<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="circle-cross-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4471 5.74492C15.0127 5.37633 14.3609 5.43137 13.9935 5.86643L10.9999 9.40156L8.00493 5.86523C7.6397 5.43124 6.98657 5.37539 6.55259 5.74492C6.1186 6.11015 6.06274 6.76328 6.43228 7.19726L9.65064 11L6.43314 14.8027C6.06524 15.2371 6.11959 15.8885 6.55465 16.2564C6.74595 16.4184 6.98228 16.5 7.17993 16.5C7.47264 16.5 7.76388 16.3758 7.96798 16.1348L10.9999 12.5984L13.9935 16.1365C14.1967 16.3754 14.4889 16.5 14.7811 16.5C15.0161 16.5 15.2529 16.4201 15.4463 16.2563C15.8811 15.8884 15.9357 15.2371 15.5678 14.8026L12.3491 11L15.5666 7.19726C15.937 6.76328 15.8811 6.11015 15.4471 5.74492Z" :fill="svgColorChange" />
                <path opacity="0.4" d="M11 0C4.92422 0 0 4.92422 0 11C0 17.0758 4.92422 22 11 22C17.0758 22 22 17.0758 22 11C22 4.92422 17.0758 0 11 0ZM15.5676 14.8027C15.9355 15.2371 15.8811 15.8886 15.4461 16.2564C15.2539 16.4184 15.0176 16.5 14.7812 16.5C14.4885 16.5 14.1973 16.3758 13.9932 16.1348L11 12.5984L8.00508 16.1348C7.80313 16.3754 7.51094 16.5 7.18008 16.5C6.98242 16.5 6.74609 16.4184 6.55273 16.2551C6.11789 15.8872 6.06332 15.2359 6.43122 14.8014L9.65078 11L6.43242 7.19727C6.06289 6.76328 6.11875 6.11016 6.55273 5.74492C6.98672 5.37539 7.63984 5.43125 8.00508 5.86523L11 9.40156L13.9936 5.86352C14.3609 5.42867 15.0129 5.37324 15.4473 5.742C15.8821 6.1099 15.9367 6.76122 15.5688 7.19563L12.3492 11L15.5676 14.8027Z" :fill="svgColorChange" />
            </svg>
        </template>
    </generic-icon>
</template>


<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'CircleCrossIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>
<style lang='scss'>
.circle-cross-icon-container {}
</style>

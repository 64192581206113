
module.exports = {    
    load(playerId, callback) {
        if (typeof window.jwplayer === 'undefined') {
            const jwplayerScript = document.createElement('SCRIPT');
            jwplayerScript.setAttribute('src', 'https://content.jwplatform.com/libraries/' + playerId + '.js');
            jwplayerScript.setAttribute('async', '');
            jwplayerScript.setAttribute('defer', '');
            document.body.appendChild(jwplayerScript);

            var waiting = setInterval(function () { 
                if (window.jwplayer) {
                    clearInterval(waiting);
                    callback();
                }
            }, 100);
        }
    }
};

export default {
    getRolesWithHeaders(state) {
        return state.roles.map(function (role) {
            return {
                ...role,
                header: role.type_id,
            };
        });
    },
    ViewMode(state) {
        return state.viewMode;
    },
};

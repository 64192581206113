export default {
    pageValidity(state) {
        let validity = {
            QuarterlyReportTitlePage:  true,
            QuarterlyReportTotalsPage: (
                !!state.QuarterlyReportTotalsPage.message
            ),
            FacebookPerformanceChartPage: (
                !!state.FacebookPerformanceChartPage.title
                && !!state.FacebookPerformanceChartPage.message
            ),
            FacebookTopPerformingPage:     true,
            InstagramPerformanceChartPage: (
                !!state.InstagramPerformanceChartPage.title
                && !!state.InstagramPerformanceChartPage.message
            ),
            InstagramTopPerformingPage:   true,
            LinkedInPerformanceChartPage: (
                !!state.LinkedInPerformanceChartPage.title
                && !!state.LinkedInPerformanceChartPage.message
            ),
            LinkedInTopPerformingPage: true,
            XPerformanceChartPage:     (
                !!state.XPerformanceChartPage.title
                && !!state.XPerformanceChartPage.message
            ),
            XTopPerformingPage:         true,
            GooglePerformanceChartPage: (
                !!state.GooglePerformanceChartPage.title
                && !!state.GooglePerformanceChartPage.message
            ),
            QuarterlyReportAmplificationPage: (
                !!state.QuarterlyReportAmplificationPage.message
                && (
                    !state.QuarterlyReportAmplificationPage.url
                    || (
                        state.QuarterlyReportAmplificationPage.leftPost.highlights[0].value
                        && state.QuarterlyReportAmplificationPage.profilePicture
                    )
                )
            ),
            QuarterlyReportProgramOpportunitiesPage: (
                !!state.QuarterlyReportProgramOpportunitiesPage.leftMessage
                && !!state.QuarterlyReportProgramOpportunitiesPage.rightMessage
            ),
            FacebookYoyEngagementsPage: (
                !!state.FacebookYoyEngagementsPage.title
                && !!state.FacebookYoyEngagementsPage.message
            ),
            FacebookYoyImpressionsPage: (
                !!state.FacebookYoyImpressionsPage.title
                && !!state.FacebookYoyImpressionsPage.message
            ),
            FacebookYoyOrganicReachPage: (
                !!state.FacebookYoyOrganicReachPage.title
                && !!state.FacebookYoyOrganicReachPage.message
            ),
            FacebookYearlyPerformancePage: (
                !!state.FacebookYearlyPerformancePage.title
                && !!state.FacebookYearlyPerformancePage.message
            ),
            QuarterlyReportThankYouPage: true,
        };

        for (let page in validity) {
            validity[page] = validity[page] || !state[page].includePage;
        }

        return validity;
    },
};

<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="list-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0.75C0 0.335938 0.325101 0 0.725806 0H2.17742C2.57964 0 2.90323 0.335938 2.90323 0.75V2.25C2.90323 2.66563 2.57964 3 2.17742 3H0.725806C0.325101 3 0 2.66563 0 2.25V0.75ZM0 5.75C0 5.33437 0.325101 5 0.725806 5H2.17742C2.57964 5 2.90323 5.33437 2.90323 5.75V7.25C2.90323 7.66563 2.57964 8 2.17742 8H0.725806C0.325101 8 0 7.66563 0 7.25V5.75ZM2.17742 10C2.57964 10 2.90323 10.3344 2.90323 10.75V12.25C2.90323 12.6656 2.57964 13 2.17742 13H0.725806C0.325101 13 0 12.6656 0 12.25V10.75C0 10.3344 0.325101 10 0.725806 10H2.17742Z" fill="#0066FF" />
                <path opacity="0.4" d="M4.35486 1.5C4.35486 0.947812 4.78732 0.5 5.3226 0.5H14.0323C14.5676 0.5 15 0.947812 15 1.5C15 2.05313 14.5676 2.5 14.0323 2.5H5.3226C4.78732 2.5 4.35486 2.05313 4.35486 1.5ZM4.35486 6.5C4.35486 5.94687 4.78732 5.5 5.3226 5.5H14.0323C14.5676 5.5 15 5.94687 15 6.5C15 7.05313 14.5676 7.5 14.0323 7.5H5.3226C4.78732 7.5 4.35486 7.05313 4.35486 6.5ZM14.0323 10.5C14.5676 10.5 15 10.9469 15 11.5C15 12.0531 14.5676 12.5 14.0323 12.5H5.3226C4.78732 12.5 4.35486 12.0531 4.35486 11.5C4.35486 10.9469 4.78732 10.5 5.3226 10.5H14.0323Z" fill="#0066FF" />
            </svg>
        </template>
    </generic-icon>
</template>


<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'ListIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>
<style lang='scss'>
.list-icon-container {}
</style>

import _ from 'lodash';
import curator from '../../curator';

function poolId(pool_id) {
    if (pool_id === null) {
        return 'null';
    } else {
        return pool_id;
    }
}

const getters = {
    getLabels: state => pool_id => {
        if (!state.poolsLoaded.includes(pool_id)) {
            return [];
        }

        return internal.identifiersByPool[poolId(pool_id)].map(i => i.name);
    },

    isAncestor: state => (classifierChild, classifierAncestor) => {

        if (classifierChild === null || classifierAncestor == null || classifierChild.parent_id === null) {
            return false;
        }
        if (classifierChild.parent_id === classifierAncestor.id) {
            return true;
        }

        return getters.isAncestor(state)(internal.classifiersById[classifierChild.parent_id], classifierAncestor);
    },

    getOrphanPaths: state => (bufferId) => {
        if (!internal.buffers[bufferId]) {
            return false;
        }

        let orphanTiers = [];
        let totalTiers  = internal.identifiersByPool[poolId(internal.buffers[bufferId].pool_id)].length;
        let children    = getters.getSelectedClassifiersByTier(state)(bufferId, totalTiers - 1);

        children = children.map(c => c.parent_id);

        for (let t = totalTiers - 2; t >= 0; t--) {
            let selected = getters.getSelectedClassifiersByTier(state)(bufferId, t);
            let orphans  = selected.filter(s => !children.includes(s.id));

            if (orphans.length) {
                orphanTiers.push({
                    tier:           t,
                    classifier_ids: orphans.map(c => c.id),
                });
            }

            children = selected.map(c => c.parent_id);
        }

        return orphanTiers;
    },

    getHeaderBuilderHelper: state => (poolId, tier) => {
        if (tier === 0 || !state.poolsLoaded.includes(poolId)) {
            return null;
        }

        return (parentId) => {
            let names = [];
            while (parentId !== null) {
                let parent = internal.classifiersById[parentId];
                names.push(parent.name);
                parentId = parent.parent_id;
            }
            names.reverse();

            return names.join(' > ');
        };
    },

    getClassifiersByTier: state => (pool_id, tier, options={}) => {
        if (!state.poolsLoaded.includes(pool_id)) {
            return [];
        }

        let results = internal.classifiersByPool[poolId(pool_id)]
            .filter(c => internal.identifierById[c.identifier_id].tier === tier);

        if (options.activeOnly) {
            results = results.filter(c => c.is_active);
        }

        if (options.parentIds) {
            results = results.filter(c => {
                return options.parentIds.includes(c.parent_id);
            });
        }

        return results;
    },

    getAvailableClassifiersByTier: state => (bufferId, tier, options={}) => {
        let activeOnly = options.activeOnly ? options.activeOnly : false;

        if (!internal.buffers[bufferId]) {
            return [];
        }

        let {pool_id, dealer_id} = internal.buffers[bufferId];
        let available            = [];

        if (isNaN(tier)) {
            available = internal.identifiersByPool[pool_id]
                .reduce((accumulator, _, tier) => {
                    return accumulator.concat(getters.getAvailableClassifiersByTier(state)(bufferId, tier));
                }, [])
                .flat();

            if (activeOnly === true) {
                available = available.filter(o => o.is_active === true);
            }

            return available;
        }

        let classifiers = getters.getClassifiersByTier(state)(pool_id, tier);

        if (internal.ownerClassifiers[dealer_id]) {
            classifiers = classifiers.filter(c => internal.ownerClassifiers[dealer_id].some(oc => oc.id === c.id));
        }

        if (tier === 0) {
            available = classifiers;
        } else {
            available = getters.getSelectedClassifiersByTier(state)(bufferId, tier - 1)
                .map(parentClassifier => classifiers.filter(c => c.parent_id === parentClassifier.id))
                .flat();
        }

        if (activeOnly === true) {
            available = available.filter(o => o.is_active === true);
        }

        return available;
    },

    getSelectedClassifiersByTier: state => (bufferId, tier, options={}) => {
        let activeOnly = options.activeOnly ? options.activeOnly : false;

        if (!internal.buffers[bufferId]) {
            return [];
        }

        let selected = state.selected
            .filter(meta => meta.bufferId === bufferId)
            .map(meta => internal.classifiersById[meta.classifierId]);

        if (!isNaN(tier)) {
            selected = selected
                .filter(c => internal.identifierById[c.identifier_id].tier === tier);
        }

        if (activeOnly === true) {
            selected = selected.filter(o => o.is_active === true);
        }

        if (options.parentIds) {
            selected = selected.filter(c => options.parentIds.includes(c.parent_id));
        }

        return selected;
    },

    getClassifierById: state => id => {
        return state.internal.classifiersById[id];
    },

    /**
     * Special function for that Brand tier that is hard coded. Possibly going away in the future?
     */
    getBrandTier: () => pool_id => {
        let identifiers = internal.identifiersByPool[poolId(pool_id)];
        let tier        = identifiers.findIndex(i => i.tier === 0);

        return tier;
    },

    getTheTips: state => (bufferId) => {
        let { pool_id } = internal.buffers[bufferId];
        let selected    = [];

        for (let tier = internal.identifiersByPool[poolId(pool_id)].length; tier >= 0; tier--) {
            selected = getters.getSelectedClassifiersByTier(state)(bufferId, tier);
            if (selected.length > 0) {
                break;
            }
        }

        return selected.map(c => c.id);
    },

    getLeafTier: state => bufferId => {
        let {pool_id} = state.internal.buffers[bufferId];

        return Math.max(...Object.values(state.internal.identifiersByPool[pool_id]).map(i => i.tier));
    },

    getSelectedMeta: state => bufferId => {
        let liefTier = getters.getLeafTier(state)(bufferId);

        let meta = getters.getSelectedClassifiersByTier(state)(bufferId, liefTier).map(c => c.meta);

        if (meta.length !== 1) {
            return;
        }

        return meta[0];
    },

    getAllClassifiers: state => {
        let poolId = state.poolsLoaded.filter((value, index, arr) => arr.indexOf(value) == index);

        let classifiers = poolId.reduce((acc, cur) => {
            state.internal.classifiersByPool[cur].forEach(classifier => {
                acc.push(classifier);
            });
            return acc;
        }, []);

        return classifiers;
    },
    isAllSelected: state => (bufferId, tier, activeOnly=false) => {
        let classifiers = getters.getAvailableClassifiersByTier(state)(bufferId, tier, { activeOnly });
        let selected    = getters.getSelectedClassifiersByTier(state)(bufferId, tier);

        return classifiers.length === selected.length;
    },
};


const internal = {
    buffers:           {},
    classifiersById:   {},
    classifiersByPool: {},
    identifierById:    {},
    identifiersByPool: {},
    lastUniqueID:      0,
    ownerClassifiers:  {},
};

export default {
    namespaced: true,

    state: {
        internal, // For Debugging purposes in dev tools, don't use
        selected:    [],
        poolsLoaded: [],
    },

    getters,

    mutations: {
        createOptionsBuffer(state, {pool_id, dealer_id}) {
            internal.buffers[++internal.lastUniqueID] = {
                pool_id:   pool_id,
                dealer_id: dealer_id,
            };
        },

        poolLoaded(state, payload) {
            internal.classifiersByPool[poolId(payload.poolId)] = payload.classifiers;
            internal.identifiersByPool[poolId(payload.poolId)] = payload.identifiers.sort((a, b) => a.tier - b.tier);
            payload.classifiers.forEach(c => internal.classifiersById[c.id] = c);
            payload.identifiers.forEach(ident => internal.identifierById[ident.id] = ident);
            state.poolsLoaded.push(payload.poolId);
        },

        select(state, {bufferId, classifierIds}) {
            let poolId   = internal.buffers[bufferId].pool_id;

            classifierIds = classifierIds.filter(id => internal.classifiersByPool[poolId].findIndex(c => c.id === id) >= 0);

            let selected = classifierIds
                .filter(id => !state.selected.find(meta => meta.classifierId === id && meta.bufferId === bufferId))
                .map(id => ({
                    bufferId,
                    classifierId: id,
                }));

            state.selected = state.selected.concat(selected);
        },

        unselect(state, {bufferId, classifierIds}) {
            let remove = [...classifierIds];

            classifierIds.forEach(id => {
                let classifier = internal.classifiersById[id];
                let identifier = internal.identifierById[classifier.identifier_id];
                let poolId     = internal.buffers[bufferId].pool_id;
                let length     = internal.identifiersByPool[poolId].length;
                let tier       = identifier.tier;

                let prev = [id];
                for (let t = tier + 1; t < length; t++) {
                    prev = getters.getSelectedClassifiersByTier(state)(bufferId, t, {
                        parentIds: prev,
                    }).map(c => c.id);

                    remove = remove.concat(prev);
                }
            });

            state.selected = state.selected
                .filter(meta => meta.bufferId !== bufferId || !remove.includes(meta.classifierId));
        },

        unselectAll(state, payload) {
            if (!isNaN(payload.tier)) {
                state.selected = state.selected.filter(meta => {
                    if (meta.bufferId !== payload.bufferId) {
                        return true;
                    }

                    let classifier = internal.classifiersById[meta.classifierId];
                    let identifier = internal.identifierById[classifier.identifier_id];

                    return identifier.tier < payload.tier;
                });
            } else {
                state.selected = state.selected.filter(meta => meta.bufferId !== payload.bufferId);
            }
        },
        selectAll(state, payload) {

            let current = state.selected
                .filter(meta => meta.bufferId === payload.bufferId)
                .map(c => c.classifierId);

            let added = [];

            if (!isNaN(payload.tier)) {
                added = getters.getAvailableClassifiersByTier(state)(payload.bufferId, payload.tier, payload.options)
                    .map(c => c.id);
            } else {
                let {pool_id, dealer_id} = internal.buffers[payload.bufferId];

                added = internal.classifiersByPool[poolId(pool_id)].map(c => c.id);

                if (internal.ownerClassifiers[dealer_id]) {
                    added = added.filter(c => internal.ownerClassifiers[dealer_id].some(oc => oc.id === c));
                }
            }

            let update = _.difference(added, current)
                .map(c => ({
                    bufferId:     payload.bufferId,
                    classifierId: c,
                }));


            state.selected = state.selected.concat(update);
        },
    },

    actions: {
        loadPool({dispatch, state}, {pool_id}) {
            if (state.poolsLoaded.includes(pool_id)) {
                return Promise.resolve();
            }

            return dispatch('refreshPool', pool_id);
        },
        refreshPool({commit}, poolId) {
            return curator.get(`/pool/${poolId}/identifiers?omit_inactive=true`)
                .then((response) => {
                    let {classifiers, identifiers}  = response.data;

                    if (!identifiers) {
                        console.error('Missing identifiers.');
                        return null;
                    } else if (!classifiers) {
                        console.error('Missing classifiers.');
                        return null;
                    }

                    let payload = {
                        poolId,
                        identifiers,
                        classifiers,
                    };

                    commit('poolLoaded', payload);
                });
        },
        createOptionsBuffer({commit, dispatch}, {pool_id, dealer_id}) {
            let p = dispatch('loadPool', {pool_id});

            if (dealer_id) {
                p = p.then(() => curator.get(`owner/${dealer_id}/classifiers`));
                p = p.then(response => {
                    internal.ownerClassifiers[dealer_id] = response.data.data.filter(c => c.id);
                });
            }

            return p.then(() => {
                commit('createOptionsBuffer', {pool_id, dealer_id});
                return internal.lastUniqueID;
            });
        },
        toggleAll({commit, state}, {bufferId, tier, options}) {
            if (getters.isAllSelected(state)(bufferId, tier)) {
                commit('unselectAll', {bufferId, tier, options});
            } else {
                commit('selectAll', {bufferId, tier, options});
            }
        },
        toggleClassifier({dispatch, state}, {bufferId, classifierId}) {
            let classifierIds = state.selected
                .filter(meta => meta.bufferId === bufferId)
                .map(meta => meta.classifierId);

            classifierId = Number(classifierId);

            if (classifierIds.includes(classifierId)) {
                classifierIds = classifierIds.filter(id => id !== classifierId);
            } else {
                classifierIds.push(classifierId);
            }

            dispatch('selectClassifiers', {
                bufferId,
                classifierIds,
                shouldOverwrite: true,
            });
        },

        replaceTierClassifiers({commit, getters}, {bufferId, tier, classifierIds}) {
            let {pool_id}         = internal.buffers[bufferId];
            let classifiers       = internal.classifiersByPool[poolId(pool_id)].filter(c => classifierIds.includes(c.id));
            let classifiersByTier = {};

            classifiers.forEach(classifier => {
                let tier = internal.identifierById[classifier.identifier_id].tier;

                if (!classifiersByTier.hasOwnProperty(tier)) {
                    classifiersByTier[tier] = [];
                }
                classifiersByTier[tier].push(classifier);
            });

            if (Object.keys(classifiersByTier).length > 1) {
                throw 'You can only replace one tier at a time';
            }

            if (Object.keys(classifiersByTier).length === 0) {
                commit('unselectAll', {
                    bufferId,
                    tier,
                });
            }

            let selected = getters.getSelectedClassifiersByTier(bufferId, tier);
            let removed  = selected.filter(c => !classifierIds.includes(c.id));

            commit('unselect', {
                bufferId,
                classifierIds: removed.map(c => c.id),
            });
            commit('select', {
                bufferId,
                classifierIds,
            });
        },

        selectClassifiers({commit, getters}, {bufferId, classifierIds, shouldOverwrite}) {
            let {pool_id}         = internal.buffers[bufferId];
            let classifiers       = internal.classifiersByPool[poolId(pool_id)].filter(c => classifierIds.includes(c.id));
            let classifiersByTier = {};

            classifiers.forEach(classifier => {
                let tier = internal.identifierById[classifier.identifier_id].tier;

                if (!classifiersByTier.hasOwnProperty(tier)) {
                    classifiersByTier[tier] = [];
                }
                classifiersByTier[tier].push(classifier);
            });

            for (let tier = 0; tier <= internal.identifiersByPool[poolId(pool_id)].length; tier++) {
                if (!classifiersByTier.hasOwnProperty(tier)) {
                    if (shouldOverwrite) {
                        commit('unselectAll', { bufferId, tier });
                    }
                    continue;
                }

                // Note: direction of loop ensures available will always be based on the
                //       last iterations selected values
                let available = getters.getAvailableClassifiersByTier(bufferId, tier);
                let selected  = classifiersByTier[tier]
                    .filter(c => available.find(c2 => c.id === c2.id))
                    .map(c => c.id);

                if (shouldOverwrite) {
                    commit('unselectAll', { bufferId, tier });
                }

                commit('select', {
                    bufferId,
                    classifierIds: selected,
                });
            }
        },
    },
};

export default {
    props: {
        toolTipDirection: {
            type:     String,
            required: false,
            default:  'top',
        },
        disabled: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        scale: {
            type:     Number,
            required: false,
            default:  1,
        },
        toolTip: {
            type:     String,
            required: false,
            default:  '',
        },
        iconContainerStyle: {
            type:     Object,
            required: false,
            default:  () => {},
        },
        disabledOpacity: {
            required: false,
            default:  0.4,
        },
        svgColorChange: {
            type:     String,
            required: false,
            default:  '#0066FF',
        },
        defaultWidth: {
            type:    Number,
            default: 22,
        },
        defaultHeight: {
            type:    Number,
            default: 22,
        },
    },
    computed: {
        height: function () {
            return this.defaultHeight * this.scale;
        },
        width: function () {
            return this.defaultWidth * this.scale;
        },
        iconStyle: function() {
            return this.disabled ? `opacity: ${ this.disabledOpacity }; filter: grayscale(1);` : this.iconContainerStyle;
        },
        title: function () {
            return this.toolTip;
        },
        direction: function () {
            return this.toolTipDirection;
        },
        divStyle: function() {
            return `width: ${this.width}px; height:${this.height}px; align-items: center; justify-content: center;`;
        },

    },
};

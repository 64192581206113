<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="switch-retailer-menu-icon-container"
    >
        <template #content>
            <svg :width="15" :height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.7759 12.181L12.4574 14.771C12.0139 15.2606 11.2559 14.9132 11.2559 14.2205V12.6959H9.37967C9.0848 12.6959 8.80634 12.5421 8.62959 12.2811L3.28289 4.40583H0.937967C0.419447 4.40583 0 3.94275 0 3.36957C0 2.79639 0.419447 2.33331 0.937967 2.33331H3.75187C4.04674 2.33331 4.3252 2.48713 4.50195 2.74814L9.84866 10.6234H11.2547V9.03984C11.2547 8.34717 12.013 8.00067 12.4301 8.4903L14.7747 11.0803C15.0749 11.4135 15.0749 11.9057 14.7759 12.181Z" :fill="fillColorTop"/>
                <path d="M3.28289 10.6233H0.937967C0.419447 10.6233 0 11.0867 0 11.6596C0 12.2325 0.419447 12.6959 0.937967 12.6959H3.75187C4.04674 12.6959 4.3252 12.542 4.50195 12.281L6.56577 9.24059L5.39331 7.51457L3.28289 10.6233ZM14.7759 2.81903L12.4574 0.229034C12.0139 -0.260598 11.2559 0.0868402 11.2559 0.779546V2.33328H9.37967C9.0848 2.33328 8.80634 2.4871 8.62959 2.74811L6.56577 5.78856L7.71185 7.51457L9.84866 4.4058H11.2547V5.96116C11.2547 6.65383 12.013 7.00033 12.4301 6.5107L14.7487 3.9207C15.0778 3.61565 15.0749 3.12343 14.7759 2.81903Z" :fill="fillColorBottom"/>
            </svg>

        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'SwitchRetailerMenuIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
    props:      {
        fillColorTop: {
            type:     String,
            required: false,
            default:  '#85878F',
        },
        fillColorBottom: {
            type:     String,
            required: false,
            default:  '#DEE0E2',
        },
    },
};

</script>
<style lang='scss'>
.switch-retailer-menu-icon-container {}
</style>

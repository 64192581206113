<template>
    <div class="ts-checkbox" :class="{checked: checked, indeterminate: indeterminate, disabled: disabled}" @click="updateChecked">
        <template v-if="checkIcon">
            <component :is="checkIcon" :scale="scale" />
        </template>
    </div>
</template>

<script>
import CaretUpIcon from '@/components/icons/CaretUpIcon.vue';
import CheckmarkIcon from '@/components/icons/CheckmarkIcon.vue';

export default {
    components: {
        CaretUpIcon,
        CheckmarkIcon,
    },
    props: {
        checked: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        indeterminate: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        disabled: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        scale: {
            type:     Number,
            required: false,
            default:  .45,
        },
    },
    computed: {
        checkIcon() {
            if (this.checked) {
                if (!this.indeterminate) {
                    return 'CheckmarkIcon';
                }
            }
            return null;
        },
    },
    methods: {
        updateChecked() {
            if (this.disabled) {
                return;
            }
            if (this.checked) {
                this.$emit('update:checked', false);
            } else {
                this.$emit('update:checked', true);
            }
            this.$emit('update:indeterminate', false);
        },
    },
};
</script>

<style lang="scss">
.ts-checkbox {
    background: linear-gradient(30deg, #5000b8, #0066ff);
    border-radius: 3px;
    border: 1px solid white;
    display: inline-block;
    height: 14px;
    position: relative;
    width: 14px;

    .generic-icon-container {
        position: relative;
        left: 1px;
        top: -4px;
        filter: grayscale(1) brightness(2);
    }

    &:not(.checked)::before {
        background-color: white;
        border-radius: 1px;
        content: "";
        display: block;
        height: 10px;
        left: 1px;
        position: relative;
        top: 1px;
        width: 10px;
    }

    &.indeterminate {
        &::before {
            background-color: white;
            border-radius: 1px;
            content: "";
            display: block;
            height: 10px;
            left: 1px;
            position: absolute;
            top: 1px;
            width: 10px;
        }
        &::after {
            background: linear-gradient(30deg, #5000b880, #0066ff80);
            border-radius: 1px;
            content: "";
            display: block;
            height: 10px;
            left: 1px;
            position: absolute;
            top: 1px;
            width: 10px;
        }
    }

    &.disabled {
        opacity: 0.5%;
        pointer-events: none;
        cursor: not-allowed
    }
}
</style>


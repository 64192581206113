<template>
    <div class="dealership-region-select">
        <label :for="buttonId">{{ labelText }}</label>
        <button
            :id="buttonId"
            class="btn btn-default dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            :title="buttonText"
            aria-haspopup="true"
            aria-expanded="false"
        >
            <span class="preview-text" v-html="buttonText" />
            <i class="dropdown-caret fa fa-caret-down" />
        </button>
        <ul class="dropdown-menu" :aria-labelledby="buttonId">
            <li v-for="(region, index) in regions" :key="index" :class="{ disabled: !region.enabled }">
                <a href="#" @click.prevent="selectRegion(region)">{{ region.name }}</a>
            </li>
        </ul>
        <dealership-region-select-box
            v-if="hasChildren"
            ref="child"
            :regions="selectedRegion.children"
            :region-level="regionLevel + 1"
            :pool="pool"
            :path-to-initial-region="pathToInitialRegion"
            @select-region="$emit('select-region', $event)"
        />
    </div>
</template>

<script>
export default {
    props: {
        pathToInitialRegion: {
            type: Array,
            required: true,
        },
        regionLevel: {
            type: Number,
            required: true,
        },
        regions: {
            type: Array,
            required: true,
        },
        pool: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selectedRegion: null,
        }
    },
    computed: {
        labelText() {
            switch (this.regionLevel) {
                case 0:
                    return 'Select ' + this.pool.primary_column + ':';
                case 1:
                    return 'Select ' + this.pool.secondary_column + ':';
                default:
                    return 'Select Sub-Region Level ' + this.regionLevel + ':';
            }
        },
        buttonId() {
            return 'region[' + this.regionLevel + ']';
        },
        buttonText() {
            return (this.selectedRegion ? this.selectedRegion.name : '--Select--');
        },
        hasChildren() {
            return this.selectedRegion && this.selectedRegion.children && this.selectedRegion.children.length > 0;
        },
    },
    watch: {
        pathToInitialRegion(val) {
            if (val) {
                this.setRegionFromPath();
            }
        },
    },
    mounted() {
        if (this.pathToInitialRegion) {
            this.setRegionFromPath();
        }
    },
    methods: {
        selectRegion(region) {
            this.selectedRegion = region;

            if (this.hasChildren) {
                if (this.$refs.child) {
                    this.$refs.child.selectedRegion = null;
                }

                this.$emit('select-region', null);
            } else {
                this.$emit('select-region', region.id);
            }
        },
        setRegionFromPath() {
            this.selectedRegion = this.regions[this.pathToInitialRegion[this.regionLevel]];
        },
    },
}
</script>

<style scoped>
label {
    display: block;
}

.preview-text {
    flex-grow: 1;

    /* bootstrap limits to only 25% width, so hide the rest if too large */
    max-width: calc(100% - 22px); /* 22px is caret */
    overflow: hidden;
}

.dealership-region-select .dealership-region-select {
    margin-top: 0.5em;
}

div.dropdown {
    display: block;
    padding: 0.5em 0;
}
button.text-left {
    text-align: left;
}

.dropdown-menu {
    top: inherit;
    margin: 2px 1em;
}

.dropdown-toggle {
    display: flex;
    min-width: 25%;
    max-width: 100%;
}

.dropdown-caret {
    padding-left: 1em;
}
</style>
import { createApp, h } from 'vue';

export default {
    data() {
        return {
            modalWrapper:    null,
            modalInstance:   null,
            modalParentNode: null,
            modalPromise:    null,
        };
    },
    methods: {
        async $showModal(componentImport, props = {}, parentNode) {
            return new Promise((resolve, reject) => {
                if (!parentNode) {
                    parentNode = document.body;
                }
                let wrapper = document.createElement('div');
                parentNode.appendChild(wrapper);

                const modalWrapper = createApp({
                    render: () => h(componentImport, { ...props, 'on$confirm': resolve, 'on$cancel': reject }),
                });
                modalWrapper.use(this.$store);
                modalWrapper.use(this.$pinia);
                modalWrapper.mount(wrapper);

                this.modalInstance = modalWrapper;
                this.modalWrapper = wrapper;
                this.modalParentNode = parentNode;
            })
                .then(value => this.$closeModal(value, true))
                .catch(value => this.$closeModal(value, false));
        },
        async $closeModal(value, resolve = true) {
            const modalWrapper = this.modalWrapper;
            const parentNode = this.modalParentNode;
            if (modalWrapper && parentNode) {
                parentNode.removeChild(modalWrapper);
            } else {
                console.error('modalWrapper or parentNode is undefined in $closeModal');
            }

            this.modalInstance = null;

            if (resolve) {
                return Promise.resolve(value);
            } else {
                return Promise.reject(value);
            }
        }
    }
};

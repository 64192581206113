<template>
    <component
        :is="component"
        :brand-id="brandId"
        :active-session="activeSession"
        :button-text="buttonText"
    />
</template>

<script>
import PageNotFound1 from '@/components/app/404Pages/PageNotFound1.vue';
import PageNotFound2 from '@/components/app/404Pages/PageNotFound2.vue';
import PageNotFound3 from '@/components/app/404Pages/PageNotFound3.vue';
import { mapStores } from 'pinia';
import { userStore } from '@/stores/pinia.js';

export default {
    components: {
        PageNotFound1,
        PageNotFound2,
        PageNotFound3,
    },
    props: {
        random: {
            type:    Number,
            default: 1,
        },
        brandId: {
            default: null,
        },
        activeSession: {
            type:    Boolean,
            default: false,
        },
        dealership: {
            type:     Object,
            required: false,
            default:  () => ({}),
        },
        user: {
            type:     Object,
            required: false,
            default:  () => ({}),
        },
    },
    computed: {
        ...mapStores(userStore),
        buttonText() {
            if (this.activeSession) {
                return 'Return to Thumbstopper';
            }

            return 'Login';
        },
        component() {
            return `PageNotFound${this.random}`;
        },
    },
    mounted() {
        this.userStore.setUser({ payload: { ...this.user, selectedDealership: this.dealership }});
    },
};
</script>

<style>
</style>

<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        :scale="scale"
        class="facebook-icon-container"
    >
        <template #content>
            <svg :width="scale * 25" :height="scale * 25" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 35.0024C27.165 35.0024 35 27.1674 35 17.5024C35 7.83746 27.165 0.00244141 17.5 0.00244141C7.83502 0.00244141 0 7.83746 0 17.5024C0 27.1674 7.83502 35.0024 17.5 35.0024Z" fill="#1977F3" />
                <path d="M24.3116 22.5616L25.0868 17.5019H20.2337V14.2189C20.2337 12.8359 20.9105 11.4848 23.086 11.4848H25.2935V7.17807C25.2935 7.17807 23.2903 6.836 21.3756 6.836C17.379 6.836 14.7654 9.2576 14.7654 13.6455V17.5019H10.3209V22.5616H14.7654V34.7903C15.6563 34.9305 16.5693 35.0019 17.4996 35.0019C18.4298 35.0019 19.3429 34.9281 20.2337 34.7903V22.5616H24.3116Z" fill="white" />
            </svg>
        </template>
    </generic-icon>
</template>

<script>
import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'FacebookIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};
</script>
<style lang="scss">
.facebook-icon-container{}
</style>

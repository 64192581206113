// used on reseller settings page > basic tab
import '@/../../../vendor/nostalgiaz/bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css';
import '@/../../../vendor/nostalgiaz/bootstrap-switch/dist/js/bootstrap-switch.js';
import '@/../../../vendor/thatsus/jquery-nestable/src/jquery.nestable.css';
import '@/../../../vendor/thatsus/jquery-nestable/src/jquery.nestable.js';
import '@/../../../vendor/thatsus/radiosforbuttons/src/jquery.radiosforbuttons.js';

import '@/../sass/app.scss';
import '@/../sass/bootstrap-override.scss';
import '@/../sass/main.scss';
import '@/../sass/nav_menu.scss';
import '@/../sass/reseller_settings.scss';
import '@/../sass/social_dash.scss';
import '@/../sass/social_media.scss';

// Font Awesome stuff
import '@/../../../vendor/fortawesome/font-awesome/css/font-awesome.css';

// Toast
import '@/global/toast.js';
import '@/main.js';

// node modules
import '@/../../../node_modules/vue-multiselect/dist/vue-multiselect.css';

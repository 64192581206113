<template>
    <div class="ts-form-input">
        <label>{{ title }}</label>
        <filter-dropdown
            v-if="inputType === 'dropdown'"
            ref="filterDropdown"
            :summarize-selection-type="summarizeSelectionType"
            v-bind="dropdownProps"
            :use-auto-select="true"
            :show-summarize-selection="showSummarizeSelection"
            @blur="(e) => $emit('blur', e)"
            @focus="(e) => $emit('focus', e)"
            @update:selected="inputValue = $event, updateInputValue()"
            @closed="vuelidate.$touch()"
            @focus-filter-dropdown-menu="(e) => $emit('focus-filter-dropdown-menu', e)"
            @blur-filter-dropdown-menu="(e) => $emit('blur-filter-dropdown-menu', e)"
        />
        <v-phone
            v-else-if="inputType === 'tel'"
            :number="inputValue"
            :phone-icon="false"
            @update:isValid="isValid = $event"
            @update:number="inputValue = $event, updateInputValue()"
            @v-phone-updated="$emit('v-phone-updated', $event)"
        />
        <input
            v-else
            v-model="inputValue"
            :type="inputType"
            :class="{'error': vuelidate?.$error}"
            :name="inputName"
            @keydown.enter.prevent=""
            @input="updateInputValue()"
            @blur="vuelidate.$touch()"
        >
        <span v-if="vuelidate?.$error" class="red">
            {{ vuelidate.inputValue.$errors[0].$message }}
        </span>
    </div>
</template>

<script>
//Vuelidate
import vuelidate from '@/mixins/Vuelidate';
import { email, helpers, required, url } from '@vuelidate/validators';

//components
import FilterDropdown from '@/components/FilterDropdown.vue';
import VPhone from '@/components/app/VPhone.vue';

export default {
    components: {
        FilterDropdown,
        VPhone,
    },
    mixins: [vuelidate],
    props:  {
        title: {
            type:     String,
            required: false,
            default:  '',
        },
        inputType: {
            type:     String,
            required: true,
        },
        required: {
            type:     Boolean,
            required: false,
        },
        dropdownProps: { // allows all props for filter-dropdown
            type:    Object,
            default: null,
        },
        value: {
            type:     String,
            required: false,
            default:  null,
        },
        inputName: {
            type:     String,
            required: false,
            default:  null,
        },
        summarizeSelectionType: {
            type:     String,
            required: false,
            default:  null,
        },
        showSummarizeSelection: {
            type:     Boolean,
            required: false,
        },
    },
    data() {
        return {
            inputValue: '',
            isValid:    false,
        };
    },
    watch: {
        value(val) {
            this.inputValue = val;
        },
    },
    validations() {
        let validations = {};
        let type;

        switch (this.inputType) {
            case 'email':
                type = { email };
                break;
            case 'tel':
                type = { phone: helpers.withMessage('Value is not a valid phone number', this.validPhone)};
                break;
            case 'website':
                type = { url };
                break;
        }

        if (!this.required && type) {
            return validations = {inputValue: type};
        }

        if (this.required) {
            if (type) {
                return validations = {inputValue: {required, ...type}};
            }

            validations = {inputValue: {required}};
        }
        //Else no validation needed

        return validations;
    },
    mounted() {
        this.inputValue = this.value;
        this.$nextTick(() => {
            let phone = this.$el.querySelector('.maz-phone-number-input > div > input');
            if (phone) {
                phone.addEventListener('keydown', e => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                });

                phone.addEventListener('blur', () => {
                    this.vuelidate.$touch();
                });
            }
        });
    },
    methods: {
        updateInputValue () {
            this.$emit('input', this.inputValue); //v-model support
            this.$emit('update:inputValue', this.inputValue);
        },

        validPhone (value) {
            return !helpers.req(value) || this.isValid;
        },
        getFilterDropdown() {
            return this.$refs.filterDropdown;
        },
    },
};
</script>

<style lang="scss" scoped>
.ts-form-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    input{
        width: 100%;
        padding: 10px;
        background-color: transparent;
        border: 1px solid #eee;
        border-radius: 5px;
        color: #333333;
        font-weight: normal;
    }

    .red {
        color: red
    }
}
</style>

export default {
    mounted() {
        window.addEventListener('click', this.$clickOutsideEventHandler);
    },
    unmounted() {
        window.removeEventListener('click', this.$clickOutsideEventHandler);
    },
    methods: {
        $clickOutsideEventHandler(event) {
            if (!this.$el.contains(event.target)) {
                this.handleClickOutside();
            }
        },
        handleClickOutside() {
            // override me
        },
    },
};

<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="central-command-menu-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.84593 6.09534H8.3514C8.41001 6.24553 8.43932 6.40304 8.43932 6.56421C8.43932 7.34078 7.80927 7.97083 7.0327 7.97083C6.87152 7.97083 6.71387 7.94153 6.56383 7.88292V9.37745H4.68834V7.88292C4.53888 7.94153 4.38064 7.97083 4.21946 7.97083C3.44289 7.97083 2.81285 7.34078 2.81285 6.56421C2.81285 6.53491 2.82017 6.50927 2.82017 6.47996C2.17196 6.25652 1.77986 5.59716 1.89356 4.92023C2.01078 4.24622 2.59687 3.75098 3.28172 3.75098C3.28172 2.97441 3.91177 2.34436 4.68834 2.34436C4.9301 2.34436 5.16834 2.4103 5.37699 2.53118C5.63487 2.12458 6.0803 1.87549 6.56383 1.87549C7.04735 1.87549 7.49425 2.12458 7.75066 2.53132C8.10964 2.32253 8.54188 2.2859 8.93398 2.43242C9.32138 2.5788 9.62615 2.89001 9.75802 3.28211H9.84593C10.6225 3.28211 11.2526 3.91215 11.2526 4.68872C11.2526 5.46529 10.6225 6.09534 9.84593 6.09534Z" :fill="fillColorBrain"/>
                <path d="M4.68579 5.24258L4.68872 5.1576C4.68872 5.1869 4.68579 5.21327 4.68579 5.24258C4.68579 5.24258 4.68286 5.23965 4.68579 5.24258ZM14.9219 8.05874C14.3065 6.67792 13.5006 3.61032 12.7791 2.58466C11.6368 0.963533 9.77892 0 7.79793 0H5.62647C2.52019 0 0 2.52019 0 5.62647C0 7.22357 0.68133 8.7474 1.87549 9.81409V15.0039H9.37745L9.37729 13.1284H11.2525C12.2893 13.1284 13.1016 12.2897 13.1016 11.2793V9.37745H14.013C14.3318 9.37745 14.6251 9.21627 14.7975 8.94902C15.0274 8.68586 15.0508 8.34886 14.9219 8.05874ZM9.84632 6.09534H8.35179C8.4104 6.24553 8.4397 6.40304 8.4397 6.56421C8.4397 7.34078 7.80966 7.97083 7.03309 7.97083C6.87191 7.97083 6.71425 7.94153 6.56421 7.88292V9.37745H4.68872V7.88292C4.53927 7.94153 4.38103 7.97083 4.21985 7.97083C3.44328 7.97083 2.81323 7.34078 2.81323 6.56421C2.81323 6.53491 2.82056 6.50927 2.82056 6.47996C2.17234 6.25652 1.78025 5.59716 1.89395 4.92023C2.01117 4.24623 2.59726 3.75098 3.28211 3.75098C3.28211 2.97441 3.91215 2.34436 4.68872 2.34436C4.93049 2.34436 5.16873 2.4103 5.37738 2.53118C5.63526 2.12458 6.08069 1.87549 6.56421 1.87549C7.04774 1.87549 7.49463 2.12458 7.75105 2.53132C8.11003 2.32253 8.54227 2.2859 8.93436 2.43242C9.32177 2.5788 9.62654 2.89001 9.75841 3.28211H9.84632C10.6229 3.28211 11.2529 3.91215 11.2529 4.68872C11.2529 5.46529 10.6229 6.09534 9.84632 6.09534Z" :fill="fillColorSkull"/>
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'CentralCommandMenuIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
    props:      {
        fillColorBrain: {
            type:     String,
            required: false,
            default:  '#85878F',
        },
        fillColorSkull: {
            type:     String,
            required: false,
            default:  '#DEE0E2',
        },
    },
};

</script>
<style lang='scss'>
.central-command-menu-icon-container {}
</style>

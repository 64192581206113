<template>
    <div class="exports-modal-container">
        <div v-if="headerInfo" class="info-header">
            <ts-loading-spinner v-if="loadingHeaders" width="30" class="info-header-text" />
            <p v-else class="info-header-text">
                {{ headerInfo }}

                <span v-if="subtitleInfo" class="info-header-subtitle">
                    {{ subtitleInfo }}
                </span>
            </p>
        </div>
        <div class="modal-body-container">
            <div v-if="!showPreview" class="sidebar-outer-container">
                <div class="title-container">
                    <h3 class="title">{{ title }}</h3>
                </div>
                <slot name="starter" />
                <div v-for="(type, i) in sidebarFilters" :key="'filter_' + i">
                    <h5 v-if="type.title" class="sidebar-title">{{ type.title }}</h5>
                    <div v-for="(option, j) in type.options" :key="'option_' + j">
                        <div v-if="option.type === 'radio'" class="radio-buttons-container">
                            <div
                                v-for="(value, k) in option.options"
                                :key="'value_' + k"
                                class="radio-option"
                            >
                                <input
                                    type="radio"
                                    :name="'radio-buttons-' + j" :checked="k === 0"
                                    @input="event => $emit(
                                        'update:selected-filter',
                                        {
                                            data: {
                                                type: { title: type.title, id: i }, filter: { title: value, id: j, map: type.map }
                                            },
                                            filterType :'radio'
                                        }
                                    )"
                                >
                                <label style="margin-left: 4px;">{{ value }}</label>
                            </div>
                        </div>
                        <div
                            v-if="option.type === 'checkbox'" class="checkbox-input-container custom-tooltip-container-sidebar"
                        >
                            <label
                                class="label-modal"
                                data-toggle="tooltip"
                                :data-placement="'right'"
                                :data-original-title="option.disabledCheck ? option.tooltip_message : ''"
                            >
                                <ts-checkbox
                                    :checked="getCheckValue(option, i, j)"
                                    :disabled="option.disabled || option.disabledCheck"
                                    :scale=".35"
                                    @update:checked="event => $emit(
                                        'update:selected-filter',
                                        {
                                            data: {
                                                type: { title: type.title, id: i }, filter: { title: option.title, id: j, map: type.map ? type.map : option.map }
                                            },
                                            filterType :'row'
                                        }
                                    )"
                                />
                                <span class="span-title" :class="option.disabledCheck ? 'text-op' : ''">{{ option.title }}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showPreview" class="preview-container">
                <span class="preview-title">{{ title }}</span>

                <export-preview
                    v-if="showPreview"
                    :filters="previewData"
                    :export-type="exportType"
                />
            </div>
            <div v-else-if="!showPreview" class="body-container">
                <div class="body-container-title">
                    <span class="body-container-title-text">{{ modalTitle }}</span>
                </div>
                <div v-for="(filter, i) in bodyFilters" :key="'filter' + i" class="body-row-container">
                    <div class="body-group-container">
                        <div
                            v-for="(data, key, j) in filter.data"
                            :key="'data' + j" class="body-group"
                        >
                            <div class="body-items-main-container">
                                <div>
                                    <h5 class="body-items-main-title">{{ data.title }}</h5>
                                </div>
                                <div class="body-items" :class="filter.type === 'row' ? 'body-rows-container' : ''">
                                    <div v-for="(option, k) in data.options" :key="'option' + k" :class="!option.hide ? 'body-item-container' : 'body-item-container-hidden'">
                                        <div v-if="filter.type === 'row'" class="body-row-filter-item custom-tooltip-container">
                                            <label
                                                class="label-modal" data-toggle="tooltip"
                                                :data-placement="'right'"
                                                :data-original-title="option.disabledCheck ? option.tooltip_message : ''"
                                            >
                                                <ts-checkbox
                                                    :checked="checked(key, k, 'body')"
                                                    :disabled="option.disabled || option.disabledCheck"
                                                    :scale=".35"
                                                    @update:checked="event => $emit(
                                                        'update:selected-filter',
                                                        {
                                                            data: {
                                                                type: { title: data.title, id: key }, filter: { title: option.title, id: k, map: option.map ? option.map : data.map, sort_id: option.sort_id },
                                                                filterType: filter.type
                                                            },
                                                        }
                                                    )"
                                                />
                                                <span :style="filter.bold ? 'font-weight: bold;' : ''" :class="[option.disabledCheck ? 'text-op' : 'sp-text']">{{ option.title }} </span>
                                            </label>
                                        </div>
                                        <div v-if="filter.type === 'column'" class="body-column-filter-item">
                                            <div class="body-column-filter-item-title">{{ option.name }}</div>
                                            <div v-for="(opt, optKey, l) in option.options" :key="'opt' + l">
                                                <div v-for="(item, m) in opt" :key="'value' + m" class="body-item">
                                                    <label
                                                        v-if="!item.hide"
                                                        class="label-modal" data-toggle="tooltip"
                                                        :data-placement="'right'"
                                                        :data-original-title="item.disabledCheck ? item.tooltip_message : ''"
                                                    >
                                                        <ts-checkbox
                                                            :checked="checked(key, m, option.name.toLowerCase())"
                                                            :disabled="item.disabledCheck"
                                                            :scale=".35"
                                                            @update:checked="event => $emit(
                                                                'update:selected-filter',
                                                                {
                                                                    data:
                                                                        {
                                                                            type: {
                                                                                title: option.name, id: key,
                                                                            },
                                                                            filter: {
                                                                                title: item.title, id: m, map: item.map ? item.map : option.map, sort_id: item.sort_id
                                                                            }
                                                                        },
                                                                    filterType: filter.type
                                                                }
                                                            )"
                                                        />
                                                        <span :style="filter.space ? 'white-space: normal;' : ' '" :class="[item.disabledCheck ? 'text-op' : 'sp-text']">{{ item.title }}</span>
                                                    </label>
                                                </div>
                                                <div v-if="l+1 < Object.keys(option.options).length" class="horizontal-divider" />
                                            </div>
                                        </div>
                                        <div v-if="filter.type === 'column' && k+1 < data.options.length" class="vertical-divider" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="i+1 < bodyFilters.length" class="horizontal-divider" />
                    <slot name="body" />
                </div>
                <div v-if="exportType === 'Brand'" class="horizontal-divider-brand" />
            </div>
        </div>
        <div class="modal-footer">
            <div class="footer-buttons">
                <div class="select-button-container">
                    <ts-button v-if="welcomePreview" :button-disabled="false" @click="$emit('from-preview')">
                        <template #buttonName>Back to Export Options</template>
                    </ts-button>
                    <ts-button v-if="!showPreview && !welcomePreview" @click="handleSelectDeselectAll">
                        <template #buttonName>Select / Deselect All</template>
                    </ts-button>
                </div>
                <div class="next-button-container">
                    <ts-button color="secondary" @click="!welcomePreview ? $emit('previous-step') : $emit('from-preview')">
                        <template #buttonName>Back</template>
                    </ts-button>
                    <ts-button v-if="!welcomePreview" :disabled="exporting" @click="$emit('next-step')">
                        <template #buttonName><ts-loading-spinner v-if="exporting" width="15" style="display: inline-block; position: relative; top: 2px; margin-right: 5px;" />{{ nextStepButtonTitle }}</template>
                    </ts-button>
                    <arrow-pointer
                        v-if="showArrowPointer && !nextStepButtonDisabled"
                        :class="welcomePreview ? 'preview-pulsing-arrow-animation-active' : 'pulsing-arrow-animation-active'"
                        :pointing-to="'right'"
                        :is-animated="true"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ExportPreview from '@/components/app/exports/ExportPreview.vue';
import ArrowPointer from '@/components/app/ArrowPointer.vue';
import TsButton from '@/components/app/TsButton.vue';
import TsCheckbox from '@/components/app/TsCheckbox.vue';
import TsLoadingSpinner from '../TsLoadingSpinner.vue';

export default {
    components: {
        TsLoadingSpinner,
        TsCheckbox,
        TsButton,
        ExportPreview,
        ArrowPointer,
    },
    props: {
        currentStep: {
            type:     Number,
            required: true,
        },
        headerInfo: {
            type:     String,
            default:  null,
            required: false,
        },
        brandsSelected: {
            type:     Array,
            required: false,
            default:  () => [],
        },
        exportType: {
            type:     String,
            required: false,
            default:  '',
        },
        oemManagerOptions: {
            type:     Array,
            required: false,
            default:  () => [],
        },
        subtitleInfo: {
            type:     String,
            default:  null,
            required: false,
        },
        title: {
            type:     String,
            default:  'Export Creator',
            required: false,
        },
        previousButtonTitle: {
            type:     String,
            required: false,
            default:  '',
        },
        sidebarFilters: {
            type:     Object,
            required: true,
            default:  () => {},
        },
        bodyFilters: {
            type:     Array,
            required: true,
            default:  () => [],
        },
        showPreview: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        welcomePreview: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        nextStepButtonDisabled: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        nextStepButtonTitle: {
            type:     String,
            required: false,
            default:  'Next',
        },
        selectedFilters: {
            type:     Object,
            required: true,
            default:  () => {},
        },
        isLoading: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        previewData: {
            type:     Array,
            required: false,
            default:  () => [],
        },
        exporting: {
            type:     Boolean,
            required: true,
            default:  false,
        },
        loadingHeaders: {
            type:    Boolean,
            default: false,
        },
        hidden: {
            type:     Object,
            required: false,
            default:  () => ({
                display: 'none',
            }),
        },
        showArrowPointer: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        selectedRoute: {
            type:     Number,
            required: false,
            default:  0,
        },
        message: {
            type:     String,
            required: false,
            default:  '',
        },
    },
    data() {
        return {
            pointingTo:        'bottom',
            isAnimated:        true,
            selectDeselectAll: 'select',
            update:            0,
        };
    },
    computed: {
        modalTitle() {
            if (this.brandsSelected.length === 1 && this.exportType === 'Brand') {
                let selectedBrand = this.brandsSelected[0];
                let matchedOemManagerOption = this.oemManagerOptions.find(option => option.id === selectedBrand).name;
                return this.exportType + ' Export Option - ' + matchedOemManagerOption;
            }

            if (this.exportType === 'Brand/Retailer Data') {
                return this.exportType + ' Export Option';
            }

            if (this.exportType === 'Content Data') {
                return this.exportType + ' Export Option';
            }

            if (this.exportType === 'Individual Retailer') {
                return this.exportType + ' Export Option - ' + this.headerInfo;
            }

            return this.exportType + ' Export Option - ' + this.brandsSelected.length + ' Brands';
        },
        subtitle() {
            return this.subtitleInfo;
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        handleSelectDeselectAll() {
            if (this.selectDeselectAll === 'select') {
                this.selectDeselectAll = 'deselect';
                this.$emit('handle-select-deselect-all', {value: 'select'});
            } else {
                this.selectDeselectAll = 'select';
                this.$emit('handle-select-deselect-all', {value: 'deselect'});
            }
            this.update += 1;
        },
        getCheckValue(option, i, j) {
            if (option.title === 'Brand Tier(s)' && option.disabledCheck) {
                return false;
            }
            return option.disabled ? option.disabled : this.checked(i, j);
        },
        checked(type, filterId, optionName) {
            let value = false;
            if (!type || !filterId) {
                return false;
            }
            if (this.selectedFilters[type].filters.length) {
                value = this.selectedFilters[type].filters.find(filter => filter.id === filterId);
            } else {
                for (const [key, val] of Object.entries(this.selectedFilters[type].filters)) {
                    if (optionName === key) {
                        value = val.filters.find(filter => filter.id === filterId);
                    }
                }
            }
            return value ? true : false;
        },
    },
};
</script>

<style lang="scss">
    .exports-modal-container {
        .overlay-block {
            height: 100%;
            width: 100%;
            background: white;
            z-index: 1;
        }
        .arrow-pointer-export {
            display: flex;
            margin: 10px 10px;
        }
        .sidebar-outer-container {
            width: 25%;
            .title-container {
                margin-bottom: 1rem;
            }
            .sidebar-title {
                font-weight: bolder;
                margin-left: 5px;
            }
            .date-range-component-container {
                margin-bottom: 2rem;
                background: #fff;
                border-radius: 5px;
                position: relative;
                width: 95%;
                .date-range-container {
                    top: 0px;
                    left: 0px;
                    margin-top: 55px;
                    z-index: 10;
                }
                .input-group {
                    height: 35px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .input-left-container {
                        display: none;
                    }
                }
                .placeholder-label {
                    margin-left: 0px;
                }
                .form-control {
                    background: #DADADA;
                    border-radius: 6px;
                    border: none;
                    font-weight: 500;
                    padding: 2px 1ch;
                    text-align: center;
                    height: 25px;
                    margin-left: 7px;
                    font-family: arial;
                }
                @media only screen and (max-width: 1800px) {
                    .form-control, .placeholder-label {
                        font-size: 14px;
                    }
                }
                .form-control:focus {
                    box-shadow: none;
                }
                .form-control::placeholder {
                    color: #fff;
                }
                .reverse-input .selected-regions {
                    justify-content: space-between;
                }
                .row-right-container .date-input-container{
                    padding: 3px 5px 4px 5px;
                }
                .purple-caret-down {
                    margin-right: 10px;
                }
                .row-right-container .modal-date-picker-input-container{
                    border-radius: 6px;
                    padding: 4px 0px;
                }
                .row-right-container .modal-date-picker-input{
                    height: 2.8rem;
                }
                .placeholder-label {
                    display: none;
                }
            }
            .brand-manager-filter-dropdown {
                .filter {
                    .btn-combobox.highlighted {
                        border-color: transparent;
                    }
                }
            }
        }
        .modal-content {
            background-color: #F0F3F6;
            width: 972px;
        }
        .modal-body-container {
            display: flex;
            padding: 15px 0px;
            margin-top: 15px;

            .preview-container{
                display: flex;
                flex-direction: column;
                width: 100%;
                .preview-title {
                    color: #000;

                    font-family: "Open Sans";
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%; /* 30.8px */
                    margin-bottom: 12px;
                }

            }
        }
        .modal-footer{
            border-top: 0;
            padding: 15px 0px;
            margin-top: -25px;
            .footer-buttons{
                display: flex;
                justify-content: space-between;
                .next-button-container {
                    display: flex;
                    position: relative;
                    margin-right: 15px;
                    justify-content: flex-end;
                }
                .pulsing-arrow-animation-active {
                    top: 10px;
                    right: 150px;
                }
                .preview-pulsing-arrow-animation-active {
                    top: 10px;
                    right: 65px;
                }
                .select-button-container{
                    display: flex;
                    justify-content: flex-start;
                    margin-left: 15px;
                }
            }
        }
        .info-header {
            display: flex;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            justify-content: center;

            .info-header-text {
                margin: 0;
                padding: 1rem 2rem;
                background-color: #FFFFFF;
                border-radius: 0px 0px 4px 4px;
                font-size: 14px;
                height: 50px;
                font-weight: 700;

                .info-header-subtitle {
                    display: block;
                    font-size: 9px;
                    line-height: 1em;
                    text-align: center;
                }
            }
        }
        .title {
            margin: 0;
            margin-left: 0.5rem;
            font-size: 18px;
            font-weight: bolder;
        }
        .select-all-button, .previous-button {
            align-items: center;
            background: #fff;
            border-radius: 4px;
            border: 2px solid #ADADAD;
            display: flex;
            font-size: 14px;
            font-weight: bold;
            justify-content: center;
            padding: 7px 20px;
        }
        .next-button {
            align-items: center;
            border: 2px solid #0066FF;
            background: #0066FF;
            color: #FFFFFF;
            border-radius: 4px;
            display: flex;
            font-size: 14px;
            font-weight: bold;
            justify-content: center;
            padding: 7px 30px;
        }
        .spinner {
            display:flex;
            align-items:center;
            justify-content: center;
            width: 100%;
        }
        .body-container {
            background-color: #FFFFFF;
            padding: 2em 1em;
            margin-left: 1.5rem;
            width: 100%;
            border-radius: 10px;
            .body-items {
                display: flex;
                .body-item-container {
                    display: flex;
                    .body-column-filter-item {
                        //min-height: 300px;
                        h5 {
                            margin-bottom: 6px;
                        }
                        .body-column-filter-item-title {
                            margin-bottom: 6px;
                        }
                    }
                }
                .body-item-container-hidden {
                    display: none;
                }
            }
            .body-row-container:nth-of-type(3) {
                min-height: 300px;
            }
            .body-row-container {
                display: flex;
                flex-direction: column;

                .add-spacing{
                    margin-bottom: 6em;
                }
                .body-group-container {
                    display: flex;
                    .body-group {
                        display: flex;
                        flex-direction: row;
                        .body-item-container {
                            display: flex;
                            .body-item {
                                display: flex;
                                input {
                                    margin: 0;
                                    margin-right: 4px;
                                }
                                label {
                                    display: flex;
                                    font-weight: normal;
                                    margin-right: 4px;
                                    margin-bottom: 0Ïpx;
                                    font-size: 12px;
                                }
                            }
                            .body-row-filter-item {
                                display: flex;
                                white-space: nowrap;
                                align-items: center;
                                margin-right: 2rem;
                                input {
                                    margin: 0;
                                    margin-right: 4px;
                                }
                                label {
                                    display: flex;
                                    font-weight: normal;
                                    margin-right: 6px;
                                    font-size: 12px;
                                }
                            }
                        }
                        h5 {
                            margin-top: 0.1rem;
                            margin-bottom: 13px;
                        }
                    }
                }
                .right-vertical-divider {
                    border-right: 2px solid #F0F3F6;
                    margin: 0;
                }
                .vertical-divider {
                    border-right: 2px solid #F0F3F6;
                    margin: 0 1em;
                }
                .horizontal-divider {
                    border-bottom: 2px solid #F0F3F6;
                    margin: 1em 0;
                }

                .add-spacing {
                    margin-bottom: 6em;
                }
            }
            .body-items-main-container {
                align-self: flex-end;

                .body-items-main-title {
                    margin-bottom: 5px;
                    font-weight: bolder;
                }
            }
            .body-container-footer {
                font-size: 11px;
                display: flex;
                justify-content: flex-start;
                margin-top: 15em;
                margin-left: 0.5em;
                font-weight: 400;
                font-style: italic;
            }
            .body-container-title{
                margin-bottom: 2rem;
                .body-container-title-text {
                    color: #000;
                    font-family: "Open Sans";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%; /* 25.2px */
                    margin-bottom: 2rem;
                }
            }
        }
        .no-sidebar {
            width: 100%;
        }
        .radio-buttons-container {
            display: flex;
            flex-direction: column;
            white-space: nowrap;
            margin-bottom: 0.5rem;
            .radio-option {
                display: flex;
                align-items: center;
                margin-right: 8px;
                input {
                    margin: 2px;
                }
                label {
                    margin: 0px 0px 0px 0.5rem;
                    font-weight: bold;
                    font-size: 10px;
                }
            }
        }
        .checkbox-input-container {
            align-items: center;
            font-size: 12px;
            margin-left:5px;
            label {
                font-weight: normal;
                margin-right: 2px;
                white-space: nowrap;
                margin-bottom: 2px !important;
            }
            .sp-text {
                margin-top: 2px;
            }
        }
        .custom-tooltip-container {
            .tooltip-inner {
                background: #252934;
                border-radius: 8px;
                color:white;
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 10px !important;
                line-height: 1.428571429;
                margin-left: 20px;
                max-width: 150px !important;
                min-width:130px !important;
                padding: 12px 9px !important;
                width: 150px;
            }
            .tooltip.right .tooltip-arrow {
                top: 0;
                left: 0;
                margin-top: 0;
                border-width: 0;
                margin-top: -20px;
            }
            .tooltip-arrow {
                z-index:-1;
                display:flex;
                width: 40px !important;
                height: 40px !important;
                background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%227%22%20height%3D%2224%22%20viewBox%3D%220%200%207%2024%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M1.8294%2014.8284L4.65783%2017.6569C6.15812%2019.1571%207.00098%2021.192%207.00098%2023.3137L7.00098%200.686291C7.00098%202.80802%206.15813%204.84285%204.65783%206.34314L1.8294%209.17157C0.267308%2010.7337%200.267305%2013.2663%201.8294%2014.8284Z%22%20fill%3D%22%23262933%22%2F%3E%3C%2Fsvg%3E") !important;
                background-repeat: no-repeat no-repeat !important;
                background-position: center center !important;
                background-size: contain !important;
            }
        }
        .custom-tooltip-container-sidebar {
            .tooltip {
              left: 140px !important;
            }
            .tooltip-inner {
                background: #252934;
                border-radius: 8px;
                color:white;
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size: 10px !important;
                line-height: 1.428571429;
                margin-left: 20px;
                max-width: 150px !important;
                min-width:130px !important;
                padding: 12px 9px !important;
                width: 150px;
            }
            .tooltip.right .tooltip-arrow {
                top: 0;
                left: 0;
                margin-top: 0;
                border-width: 0;
                margin-top: -20px;
            }
            .tooltip-arrow {
                z-index:-1;
                display:flex;
                width: 40px !important;
                height: 40px !important;
                background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%227%22%20height%3D%2224%22%20viewBox%3D%220%200%207%2024%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M1.8294%2014.8284L4.65783%2017.6569C6.15812%2019.1571%207.00098%2021.192%207.00098%2023.3137L7.00098%200.686291C7.00098%202.80802%206.15813%204.84285%204.65783%206.34314L1.8294%209.17157C0.267308%2010.7337%200.267305%2013.2663%201.8294%2014.8284Z%22%20fill%3D%22%23262933%22%2F%3E%3C%2Fsvg%3E") !important;
                background-repeat: no-repeat no-repeat !important;
                background-position: center center !important;
                background-size: contain !important;
            }
        }
        .toggle-switch-checkbox-input {
            display:flex;
            position: relative !important;
            margin-left: 0px !important;
        }

        .blocked-modal {
            label, h5, span, button {
                opacity: 0.3;
                user-select: none;
                pointer-events: none;
            }
            .close-custom-modal-default {
                pointer-events: all;
            }
        }
        .label-modal {
            display:flex;
            align-items: flex-start;
            .text-op {
                opacity: 0.3;
            }
        }
        .ts-checkbox{
            height: 15px;
            width: 15px;
            padding-right: 5px;
            margin-right: 3px;
        }
        .ts-checkbox .generic-icon-container {
            left: 2.5px;
            top: -2px;
        }
        .ts-checkbox:not(.checked)::before{
            height: 11px;
            left: 1px;
            right: 1px;
            top: 1px;
            bottom: 1px;
            width: 11px;
        }
    }
</style>

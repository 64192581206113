const _     = require('lodash');
const axios = require('axios').default;

module.exports = {
    get(url, options = {}) {
        let endpoint = window.Lotvantage.curatorEndpoint;
        if (!url.startsWith('/')) {
            url = '/' + url;
        }

        if (endpoint[endpoint.length - 1] === '/') {
            endpoint = endpoint.substr(0, endpoint.length - 1);
        }

        url = endpoint + url;

        const defaultOptions = {
            'Content-Type': 'application/json',
            'headers':      {
                Token: window.Lotvantage.curatorToken,
            },
        };

        options = _.merge(defaultOptions, options);

        return axios.get(url, options);
    },
};

<template>
    <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
    >
        <div
            class="modal-dialog modal-lg"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <slot name="top-right-bar" />
                    <h4 class="modal-title text-left">Invoice Payment Confirmation</h4>
                </div>

                <div class="modal-body">
                    <label>Invoice(s) selected for payment.</label>
                    <div style="position: relative; padding-top: 3rem; padding-bottom: 3rem;">
                        <div style="overflow-y: auto; height: 275px;">
                            <table class="table table-condensed scrolling">
                                <thead>
                                    <tr>
                                        <th>
                                            ID
                                            <div>ID</div>
                                        </th>
                                        <th>
                                            Invoice Date
                                            <div>Invoice Date</div>
                                        </th>
                                        <th>
                                            Type
                                            <div>Type</div>
                                        </th>
                                        <th>
                                            Account
                                            <div>Account</div>
                                        </th>
                                        <th class="text-right">
                                            Total
                                            <div>Total</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="invoice in invoices" :key="invoice.id">
                                        <td v-text="invoice.id" />
                                        <td v-text="formatDate(invoice.invoice_date)" />
                                        <td v-text="invoice.human_type" />
                                        <td v-text="invoice.account_name" />
                                        <td class="text-right"><i class="fa fa-dollar" /> {{ total(invoice) | money }}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td />
                                        <td />
                                        <td />
                                        <td>
                                            <div
                                                style="padding-left: 17rem;"
                                                class="text-right"
                                            >
                                                <strong>Amount Due:</strong>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style="padding-left: 7.8rem"
                                                class="text-right"
                                            >
                                                <i class="fa fa-dollar" /> {{ sumInvoices() | money }}
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div
                        v-if="canMakePayment"
                        class="row-no-margin row"
                    >
                        <button
                            type="button"
                            class="btn btn-primary pull-right col-sm-6"
                            @click="handlePay($event)"
                        >
                            Process Payment
                        </button>
                        <div
                            id="payment-request-button"
                            class="col-sm-6"
                        />
                    </div>
                    <div
                        v-else
                        class="row-no-margin row"
                    >
                        <button
                            type="button"
                            class="btn btn-primary pull-right col-sm-2"
                            @click="handlePay($event)"
                        >
                            Process Payment
                        </button>
                        <div
                            id="paymentNotAvailable"
                            class="MainFormRow showapplebbtu col-sm-9"
                            style="text-align: left"
                        >
                            <span style="color: black; font-weight: 600;">
                                We can't currently detect an account for your GPay or Apple Pay.
                                Please click the process payment button to pay with your saved payment profile.
                            </span>
                        </div>
                    </div>
                    <div
                        class="row row-no-margin"
                        style="padding-top: 1%;"
                    >
                        <div class="col-sm-12">
                            <button
                                type="button"
                                class="btn btn-default pull-left"
                                data-dismiss="modal"
                                @click="closeModal"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div class="clearfix" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from '@/axios';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

import { waitForLoad } from '@/waitForLoad.js';
import $ from 'jquery';

let stripe, elements, paymentRequest, prButton;

const stripeLoaded = waitForLoad(window, 'Stripe').then(() => {
    stripe = window.Stripe(window.Laravel.stripeKey);
    elements = stripe.elements();
    paymentRequest = stripe.paymentRequest({
        country:  'US',
        currency: 'usd',
        total:    {
            label:  'Demo total',
            amount: 0, // cents
        },
        requestPayerName:  true,
        requestPayerEmail: true,
        requestPayerPhone: true,
    });
    prButton = elements.create('paymentRequestButton', {
        paymentRequest: paymentRequest,
        style:          {
            paymentRequestButton: {
                type:   'default', // default: 'default'
                theme:  'dark', // default: 'dark'
                height: '35px', // default: '40px', the width is always '100%'
            },
        },
    });
});
export default {
    props: {
        invoices: {
            type: Array,
            required: true,
        },
        paymentProfile: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            awaitingPayment:      false,
            canMakePayment:       false,
            paymentRequestButton: null,
            stripe: null,
            paymentRequest: null,
        };
    },
    computed: {
        profile() {
            return this.paymentProfile || {
                stripeToken:         '',
                cardType:            '',
                lastName:            '',
                firstName:           '',
                cardName:            '',
                cardExpirationMonth: '',
                cardExpirationYear:  '',
                lastFour:            '',
                cardAccountNumber:   '',
                email:               '',
                cardCvv:             '',
                address:             '',
                address_2:           '',
                city:                '',
                state:               '',
                zip:                 '',
            };
        },
        unpaidInvoices() {
            if (!this.invoices) {
                return [];
            }

            return this.invoices.filter(function (invoice) {
                return !invoice.paid_at;
            });
        },
    },
    async mounted() {
        await stripeLoaded;

        this.$nextTick().then(() => {
            $(this.$el).on('hidden.bs.modal', () => {
                this.$emit('close-modal');
            });

            $(this.$el).on('show.bs.modal', () => {
                // on show
            });

            $(this.$el).modal('toggle');
        });

        this.paymentRequest.canMakePayment().then((result) => {
            if (result) {
                this.canMakePayment = true;
                this.$nextTick().then(() => {
                    prButton.mount('#payment-request-button');
                });
            } else {
                this.canMakePayment = false;
            }
        });

        this.paymentRequest.on('token', (ev) => {
            // Send the customer's info to your server.
            this.profile.stripeToken = ev.token;
            this.profile.cardType = ev.token.card.brand;
            this.profile.firstName = ev.payerName.split(' ')[0];
            this.profile.lastName = ev.payerName.split(' ').slice(1, 4).join(' ');
            this.profile.cardExpirationMonth = ev.token.card.exp_month;
            this.profile.cardExpirationYear = ev.token.card.exp_year;
            this.profile.cardAccountNumber = ev.token.card.last4;
            this.profile.email = ev.payerEmail;
            this.profile.address = ev.token.card.address_line1;
            this.profile.address_2 = ev.token.card.address_line2;
            this.profile.city = ev.token.card.address_city;
            this.profile.state = ev.token.card.address_state;
            this.profile.zip = ev.token.card.address_zip;

            this.$emit('allow-save', true);
            this.$emit('save-profile', (truthy) => {
                if (truthy) {
                    this.pay().then(response => {
                        if (response) {
                            ev.complete('success');
                            window.flash.show({
                                text: 'Invoice(s) successfully paid.',
                                type: 'success',
                            });
                        } else {
                            ev.complete('fail');
                            window.flash.show({
                                text: 'Failed to pay. Please try again.',
                                type: 'warning',
                            });
                        }
                    });
                }
            });
        });
        prButton.on('click', () => {
            this.paymentRequest.update({
                total: {
                    label:  `Pay Invoice(s): ${this.invoices.reduce((n, invoice) => `${n} #${invoice.id}`, '')}`,
                    amount: parseInt(this.sumInvoices()) * 100,
                },
            });
        });
    },
    methods: {
        closeModal() {
            $(this.$el).modal('toggle');
            this.$emit('close-modal');
        },
        total(invoice) {
            if (!invoice.line_items) {
                return 0;
            }

            let sum = 0;

            invoice.line_items.forEach(function (line_item) {
                sum += (line_item.amount * 100);
            });

            return (sum / 100);
        },
        sumInvoices() {
            if (!this.invoices) {
                return 0;
            }

            let sum = 0;

            this.invoices.forEach(function (invoice) {
                if (invoice.line_items) {
                    invoice.line_items.forEach(function (line_item) {
                        sum += (line_item.amount * 100);
                    });
                }
            });

            return (sum / 100);
        },
        handlePay(event) {
            if (!this.paymentProfile) {
                this.closeModal();
                this.$emit('open-profile');
                return;
            }
            let old = event.target.innerHTML;
            $(event.target).append(' <i class="fa fa-circle-o-notch fa-spin"></i>');
            this.pay().then(response => {
                if (response) {
                    event.target.innerHTML = old;
                    window.flash.show({
                        text: 'Invoice(s) successfully paid.',
                        type: 'success',
                    });
                } else {
                    event.target.innerHTML = old;
                    window.flash.show({
                        text: 'Failed to pay. Please try again.',
                        type: 'warning',
                    });
                }
            });
        },
        pay() {
            let ids = this.invoices.map(function (invoice) {
                return invoice.id;
            });
            if (!this.awaitingPayment) {
                this.awaitingPayment = true;
                return axios.post('/invoices/pay?token=' + window.Laravel.invoicingToken, { ids: ids })
                    .then(() => {
                        this.awaitingPayment = false;
                        this.$emit('invoices-paid', this.invoices);
                        this.closeModal();
                        return true;
                    }).catch(() => {
                        this.awaitingPayment = false;
                        this.closeModal();
                        return false;
                    });
            } else {
                window.flash.show({
                    text: 'Please wait a moment while your previous request is processed.',
                    type: 'warning',
                });
            }
        },
        formatDate: function (date) {
            if (!date) {
                return 'n/a';
            }
            return dayjs(date).format('L');
        },
    },
};
</script>

<style>
table.scrolling {
  margin-bottom: 0;
}

table.scrolling th,
table.scrolling > tfoot td {
  height: 0 !important;
  line-height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: transparent !important;
  border: none !important;
  white-space: nowrap !important;
  padding-left: 25px !important;
}

table.scrolling th div {
  position: absolute !important;
  background: transparent !important;
  color: black !important;
  padding: 9px 5px;
  top: 0 !important;
  margin-left: -25px;
  line-height: normal !important;
}

table.scrolling tfoot div {
  position: absolute !important;
  background: transparent !important;
  color: black !important;
  padding: 9px 5px;
  top: 300px !important;
  margin-left: -25px;
  line-height: normal !important;
}

table.scrolling th:last-child div {
  width: 100%;
  left: 0;
  margin-left: 0;
  padding-right: 1.75rem;
}

div.row.row-no-margin {
  margin: 0;
}

.showapplebbtu {
  background: #f4f5f7;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 20px;
}
</style>

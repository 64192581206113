<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="users-menu-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.50234 7.5C6.1568 7.5 5.08594 6.40875 5.08594 5.0625C5.08594 3.71625 6.17672 2.625 7.50234 2.625C8.84789 2.625 9.91875 3.71625 9.91875 5.0625C9.93984 6.40781 8.84766 7.5 7.50234 7.5ZM6.33047 8.25H8.66953C10.5094 8.25 12 9.64922 12 11.3742C12 11.7211 11.7023 12 11.3344 12H3.66562C3.29766 12 3 11.7211 3 11.3742C3 9.64922 4.49063 8.25 6.33047 8.25Z" :fill="fillColorFirst"/>
                <path d="M12 3.75C10.9645 3.75 10.125 2.91047 10.125 1.875C10.125 0.839531 10.9641 0 12 0C13.0355 0 13.875 0.839531 13.875 1.875C13.875 2.91047 13.0355 3.75 12 3.75ZM3 3.75C1.96453 3.75 1.125 2.91047 1.125 1.875C1.125 0.839531 1.96406 0 3 0C4.03547 0 4.875 0.839531 4.875 1.875C4.875 2.91047 4.03594 3.75 3 3.75ZM2.06484 4.5H3.51422C3.81422 4.5 4.09734 4.57118 4.35469 4.69313C4.34062 4.81641 4.31719 4.93594 4.31719 5.0625C4.31719 5.85258 4.61672 6.56742 5.09437 7.125H0.413906C0.185156 7.125 0 6.92812 0 6.68672C0 5.47969 0.923437 4.5 2.06484 4.5ZM10.6523 4.68984C10.9078 4.54922 11.1891 4.5 11.4867 4.5H12.9356C14.0756 4.5 15 5.47969 15 6.68672C15 6.92812 14.8151 7.125 14.587 7.125H9.91125C10.3889 6.56742 10.6884 5.85258 10.6884 5.0625C10.6898 4.93594 10.6664 4.81406 10.6523 4.68984Z" :fill="fillColorSecond"/>
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'UsersMenuIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
    props:      {
        fillColorFirst: {
            type:     String,
            required: false,
            default:  '#85878F',
        },
        fillColorSecond: {
            type:     String,
            required: false,
            default:  '#DEE0E2',
        },
    },
};

</script>
<style lang='scss'>
.users-menu-icon-container {}
</style>

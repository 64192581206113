<template>
    <div>
        <div class="panel panel-default table-panel">
            <div class="panel-heading" style="font-weight:bold;">
                <div>
                    <div style="display: inline-block;" v-text="paid ? 'Paid Invoices' : 'Unpaid Invoices'" />
                </div>
            </div>
            <div class="panel-body" style="position: relative;">
                <div ref="paymentButton" :style="paymentButtonStyle" @scroll="positionButton">
                    <div v-if="showPayButton" class="pull-right">
                        <a v-if="paymentProfile" href="javascript:void(0);" class="btn btn-primary" @click="payInvoices">Pay <i class="fa fa-dollar" />{{ sumSelectedInvoices.toFixed(2) }}</a>
                    </div>
                </div>
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th v-if="!paid">
                                <input v-model="toggleAll" type="checkbox" value="false">
                            </th>
                            <th>Invoice #</th>
                            <th>Type</th>
                            <th>Account</th>
                            <th>Invoice Date</th>
                            <th v-if="paid">Paid On</th>
                            <th />
                            <th class="text-right" v-text="paid ? 'Total' : 'Amount Due'" />
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="invoice in filteredInvoices" :key="invoice.id" @modalClosed="setPreviewInvoice(null)">
                            <td class="invoice-table-td" v-if="!paid">
                                <input v-model="selectedInvoices" type="checkbox" :value="invoice">
                            </td>
                            <td class="invoice-table-td">
                                <a href="javascript:void(0)" @click="openInvoice(invoice)" v-text="invoice.id" />
                            </td>
                            <td class="invoice-table-td" v-text="invoice.human_type" />
                            <td class="invoice-table-td" v-text="invoice.account_name" />
                            <td class="invoice-table-td" v-text="formatDate(invoice.invoice_date || invoice.created_at)" />
                            <td class="invoice-table-td" v-if="paid" v-text="formatDate(invoice.paid_at)" />
                            <td class="invoice-table-td">
                                <modal v-if="getPreviewInvoice === invoice.id" ref="previewModal" title="Invoice Preview" @close="clearPreview">
                                    <template #body>
                                        <invoice :invoice="invoice" :payment-profile="paymentProfile" />
                                    </template>
                                    <template #footer>
                                        <a v-if="!invoice.is_paid && paymentProfile" href="javascript:void(0)" class="btn btn-primary" @click="payInvoice(invoice)">Pay Now</a>
                                    </template>
                                </modal>
                                <a href="javascript:void(0)" class="btn btn-xs btn-default pull-right" @click="setPreviewInvoice(invoice.id)"><i class="fa fa-search" /> Preview</a>
                            </td>
                            <td v-if="paid" class="text-right invoice-table-td">
                                <i class="fa fa-dollar" />{{ total(invoice).toFixed(2) }}
                            </td>
                            <td v-else class="text-right invoice-table-td">
                                <i class="fa fa-dollar" />{{ invoice.total_amount_due.toFixed(2) }}
                            </td>
                        </tr>
                        <tr v-if="paid && remaining > 0">
                            <td class="invoice-table-td" colspan="99">
                                <template v-if="loadingInvoices">
                                    <i class="fa fa-circle-o-notch fa-spin" /> Loading more invoices...
                                </template>
                            </td>
                        </tr>
                        <tr v-if="invoices && filteredInvoices.length === 0">
                            <td class="invoice-table-td" :colspan="colSpan">
                                You currently have no {{ paid ? 'paid' : 'unpaid' }} invoices.
                            </td>
                        </tr>
                        <tr v-else-if="!invoices">
                            <i class="fa fa-circle-o-notch fa-spin fa-fw" /> Loading invoices...
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr v-if="!paid && sumAmountDue">
                            <td class="invoice-table-td" :colspan="colSpan - 2" />
                            <th class="text-right invoice-table-td">Amount Due</th>
                            <td class="text-right invoice-table-td"><i class="fa fa-dollar" />{{ sumAmountDue.toFixed(2) }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">

import axios from '@/axios';
import Modal from '@/components/app/Modal.vue';
import Invoice from '@/components/invoicing/Invoice.vue';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

// Doing this to remove errors/warnings, but when we redesign the invoicing frontend,
// we should remove jquery altogether
import $ from 'jquery';

export default {
    components: {
        Invoice,
        Modal,
    },
    props: {
        paid: {
            type: Boolean,
            required: true,
        },
        invoices: {
            type: Array,
            required: true,
        },
        paymentProfile: {
            type: Object,
            required: false,
            default: null,
        },
        remaining: {
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            toggleAll: false,
            selectedInvoices: [],
            previewInvoice: null,
            csvFields: [
                'id',
                'type',
                'account',
                'date',
                'total',
                'amount_due',
                'paid_at',
            ],
            loadingInvoices: false,
            payButton: {
                position: 'relative',
            },
            initialButtonPostion: undefined,
            awaitingPayment: false,
        };
    },
    computed: {
        paymentButtonStyle() {
            let style = '';

            style += 'position: ' + this.payButton.position + ';';
            if (this.payButton.top) {
                style += ' top: ' + this.payButton.top + ';';
            }
            if (this.payButton.right) {
                style += ' left: auto; right: ' + this.payButton.right + ';';
            }

            return style;
        },
        showPayButton() {
            return (!this.paid && this.filteredInvoices.length > 0);
        },
        csvData() {
            return this.filteredInvoices.map((data) => {
                return {
                    id: data.id,
                    type: data.type,
                    account: data.account_name,
                    date: data.invoice_date,
                    total: this.total(data).toFixed(2),
                    amount_due: parseFloat(data.total_amount_due).toFixed(2),
                    paid_at: data.paid_at,
                };
            });
        },
        csvTitle() {
            return this.paid ? 'paid_invoices.csv' : 'unpaid_invoices.csv';
        },
        getPreviewInvoice() {
            return this.previewInvoice;
        },
        filteredInvoices() {
            if (!this.invoices) {
                return [];
            }

            if (this.paid) {
                return this.invoices.filter(function (invoice) {
                    return !!invoice.paid_at;
                });
            } else {
                return this.invoices.filter(function (invoice) {
                    return !invoice.paid_at;
                });
            }
        },
        sumInvoices() {
            if (this.filteredInvoices.length === 0) {
                return 0;
            }

            var sum = 0;

            this.filteredInvoices.forEach(function (invoice) {
                if (invoice.line_items) {
                    invoice.line_items.forEach(function (line_item) {
                        sum += (line_item.amount * 100);
                    });
                }
            });

            return (sum / 100);
        },
        sumSelectedInvoices() {
            if (this.selectedInvoices.length === 0) {
                return this.sumAmountDue;
            }

            let sum = 0;

            this.selectedInvoices.forEach(function (invoice) {
                if (invoice.total_amount_due) {
                    sum += (invoice.total_amount_due * 100);
                }
            });

            return (sum / 100);
        },
        sumAmountDue() {
            if (this.filteredInvoices.length === 0) {
                return 0;
            }

            var sum = 0;

            this.filteredInvoices.forEach(function (invoice) {
                if (invoice.total_amount_due) {
                    sum += (invoice.total_amount_due * 100);
                }
            });

            return (sum / 100);
        },
        colSpan() {
            return this.paid ? 6 : 7;
        },
    },
    watch: {
        toggleAll() {
            if (this.toggleAll) {
                this.selectedInvoices = this.filteredInvoices;
            } else {
                this.selectedInvoices = [];
            }
        },
        invoices() {
            this.loadingInvoices = false;
            this.infiniteScroll();
        },
    },
    mounted() {
        // This is not ideal, but not sure how else to do it.
        $(window).on('scroll', () => {
            this.positionButton();
            this.infiniteScroll();
        });
        this.$nextTick().then(() => {
            this.infiniteScroll();
            this.initialButtonPosition = $(this.$refs.paymentButton).offset().top;
        });
    },
    methods: {
        infiniteScroll() {
            if (this.remaining > 0 && !this.loadingInvoices) {
                let bodyHeight = $(document).height() - $(window).height();

                if ((($(document).scrollTop() / bodyHeight) > 0.9) || ($('body').height() <= $(window).height())) {
                    this.loadMorePaid();
                }
            }
        },
        positionButton() {
            let scrollTop = $(window).scrollTop();

            if (scrollTop > this.initialButtonPosition) {
                this.payButton = {
                    position: 'absolute',
                    top: (scrollTop + 10) + 'px',
                    right: '-150px',
                };
            } else {
                this.payButton = {
                    position: 'relative',
                };
            }

        },
        loadMorePaid() {
            this.loadingInvoices = true;
            this.$emit(
                'load-more-paid',
                this.filteredInvoices
                    .map(function (invoice) {
                        return invoice.id;
                    })
                    .join(','),
            );
        },
        payInvoice(invoice) {
            let ids = [invoice.id];

            if (!this.awaitingPayment) {
                this.awaitingPayment = true;
                let old = event.target.innerHTML;
                $(event.target).append(' <i class="fa fa-circle-o-notch fa-spin"></i>');
                axios.post('/invoices/pay?token=' + window.Laravel.invoicingToken, { ids: ids })
                    .then(
                        () => {
                            this.awaitingPayment = false;
                            event.target.innerHTML = old;
                            this.clearPreview();
                            this.$emit('invoices-paid', [invoice]);
                            window.flash.show({
                                text: 'Invoice(s) successfully paid.',
                                type: 'success',
                            });
                        },
                        () => {
                            this.awaitingPayment = false;
                            event.target.innerHTML = old;
                            window.flash.show({
                                text: 'Failed to pay. Please try again.',
                                type: 'warning',
                            });
                        },
                    );
            } else {
                window.flash.show({
                    text: 'Please wait a moment while your previous request is processed.',
                    type: 'warning',
                });
            }
        },
        payInvoices() {
            let invoices = this.selectedInvoices.length === 0 ? this.filteredInvoices : this.selectedInvoices;
            this.$emit('pay-invoices', invoices);
        },
        setPreviewInvoice(id) {
            this.previewInvoice = id;
        },
        total(invoice) {
            if (!invoice.line_items) {
                return 0;
            }

            var sum = 0;

            invoice.line_items.forEach(function (line_item) {
                sum += (line_item.amount * 100);
            });

            return (sum / 100);
        },
        openInvoice(invoice) {
            this.$emit('openInvoice', invoice);
        },
        formatDate: function (date) {
            if (!date) {
                return 'n/a';
            }
            return dayjs(date).format('L');
        },
        clearPreview() {
            this.setPreviewInvoice(null);
        },
    },
};
</script>

<style>
.invoice-table-td {
    max-width: 200px !important;
    overflow: hidden !important;
}

.table-panel {
    min-width: 580px;
}

.clickable {
    cursor: pointer;
}

.load.button {
    text-align: center;
    color: white;
    border: solid #337ab7;
    border-radius: 5px;
    padding: 0.5rem;
    background-color: #337ab7;
}
</style>

export default {
    calculatePosition(el) {
        let rect = el.getBoundingClientRect();
        return {
            width:  '100vw',
            height: '100vh',
            left:   (-rect.left) + 'px',
            top:    (-rect.top) + 'px',
        };
    },
};
export default [
    {
        id:            1,
        class:         'theme1',
        welcomeImages: [
            {
                '--left':  900,
                '--top':   142,
                '--scale': 0.57,
            },
            {
                '--left':  721,
                '--top':   182,
                '--scale': 0.565,
            },
            {
                '--left':  870,
                '--top':   310,
                '--scale': 0.625,
            },
            {
                '--left':  1058,
                '--top':   257,
                '--scale': 0.525,
            },
            {
                '--left':  777,
                '--top':   58,
                '--scale': 0.44,
            },
            {
                '--left':  1042,
                '--top':   100,
                '--scale': 0.35,
            },
        ],
        welcomeImagesMobile: [
            {
                '--left':  130,
                '--top':   50,
                '--scale': 0.47,
            },
            {
                '--left':  0,
                '--top':   90,
                '--scale': 0.47,
            },
            {
                '--left':  119,
                '--top':   190,
                '--scale': 0.47,
            },
            {
                '--left':  250,
                '--top':   157,
                '--scale': 0.40,
            },
            {
                '--left':  50,
                '--top':   0,
                '--scale': 0.30,
            },
            {
                '--left':  260,
                '--top':   20,
                '--scale': 0.26,
            },
        ],
    },
    {
        id:            2,
        class:         'theme2',
        welcomeImages: [
            {
                '--left':  200,
                '--top':   140,
                '--scale': 0.6,
            },
            {
                '--left':  414,
                '--top':   1,
                '--scale': 0.975,
            },
            {
                '--left':  685,
                '--top':   30,
                '--scale': 0.725,
            },
            {
                '--left':  912,
                '--top':   139,
                '--scale': 0.61,
            },
        ],
        welcomeImagesMobile: [
            {
                '--left':  0,
                '--top':   90,
                '--scale': 0.3,
            },
            {
                '--left':  80,
                '--top':   40,
                '--scale': 0.4,
            },
            {
                '--left':  185,
                '--top':   55,
                '--scale': 0.4,
            },
            {
                '--left':  290,
                '--top':   90,
                '--scale': 0.3,
            },
        ],
    },
];

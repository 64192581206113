import Cookies from 'js-cookie';
import {userStore} from '@/stores/pinia';

export function uniqueHash() {
    // If the user is logged in, we can use their ID as a unique hash.
    let uniqueHash = Cookies.get('unique-hash');


    if (!uniqueHash) {
        uniqueHash = userStore?.id
            ? Array.from(new String(userStore.id)).reduce((hash, char) => 31 * hash + char.charCodeAt(0), 0)
            : Date.now();
    }

    Cookies.set('unique-hash', uniqueHash.toString());

    return Math.abs(uniqueHash);
}

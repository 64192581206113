<template>
    <div class="stripe-control" />
</template>

<script>
import { waitForLoad } from '@/waitForLoad.js';

const stripeLoaded = waitForLoad(window, 'Stripe');

export default {
    data() {
        return {
            stripe:     null,
            stripeCard: null,
        };
    },
    async mounted() {
        await stripeLoaded;
        this.init();
    },
    methods: {
        init() {
            this.stripe = window.Stripe(window.Laravel.stripeKey);
            let elements = this.stripe.elements();
            this.stripeCard = elements.create('card');

            this.stripeCard.mount(this.$el);
            this.stripeCard.on('change', this.handleCardChange);
        },
        async handleCardChange(event) {
            if (event.complete) {
                let token = await this.registerStripeAccount();
                this.$emit('update:token', token);
            }
        },
        registerStripeAccount() {
            return new Promise((resolve, reject) => {
                this.stripe.createToken(this.stripeCard).then(result => {
                    if (result.error) {
                        // Inform the user if there was an error.
                        alert(result.error.message);
                        reject({
                            source: 'stripe',
                            error:  result,
                        });
                    } else {
                        resolve(result.token.id);
                    }
                });
            });
        },
    },
};
</script>

<style lang="scss">
.stripe-control {
    border-radius: 5px;
    border: 1px solid #ebebed;
    height: 41px;
    margin-top: 5px;
    padding: 10px;
}
</style>

// Tools & Methods
const getStateId = (state, states, country, countries) => {
    const statesProvincesFound = states.filter(st => st.abbreviation === state); // Some states from different countries have the same abbreviation
    if (statesProvincesFound) {
        const filteredState = statesProvincesFound.find(s => s.country_id === getCountryId(country, countries));
        return filteredState ? filteredState.id : null;
    }
    return null;
};

const getCountryId = (country, countries) => {
    if (!country) {
        return '';
    }
    if (country === 'US') {
        return 1;
    }
    if (country === 'CA') {
        return 2;
    }

    return countries.find(c => c.name === country || c.slug === country).id;
};

const getStateAbbreviation = (state, states) => {
    const selectedState = states.find(st => st.id === state);
    return selectedState ? selectedState.abbreviation : '';
};

const getCountryNameOrCode = (country, countries) => {
    const selectedCountry = countries.find(st => st.id === country);

    if (!selectedCountry) {
        return '';
    }

    if (selectedCountry.id === 1 || selectedCountry.id === 2) {
        return selectedCountry ? selectedCountry.code : '';
    }

    return selectedCountry ? selectedCountry.name : '';
};


// Mutations
export default {
    loadSettings(state, payload) {
        state.dealer = payload.dealer;
        state.dealershipName = payload.dealership.name;
    },
    setPictureUrl(state, url) {
        state.originalPictureUrl = state.pictureUrl;
        state.pictureUrl = url;
        state.updateComponent += 1;
    },
    updateSelectedView(state, id) {
        if (id === 'my-profile') {
            state.pictureUrl = state.retailerUser.image;
        }
        state.selectedView = id;
        state.isLoading = true;
    },
    setStates(state, states) {
        state.states = states;
        state.retailerGeneralViewSettings[1].columnsData[0]['retailer-state-province-and-zip-code'][0].options = states;
    },
    setTimeZones(state, timeZones) {
        state.retailerGeneralViewSettings[1].columnsData[0]['retailer-timezone'].options = timeZones;
    },
    setGeneralValues(state, dealership) {
        state.dealership = dealership;
        state.generalViewInputValues = {
            ['retailer-name']:           dealership.name,
            ['retailer-phone']:          dealership.phone ?? dealership.lister?.current_link?.account?.phone ?? '',
            ['default-website']:         dealership.url,
            ['custom-website']:          dealership.custom_url,
            ['external-retailer-id']:    dealership.vendor_id,
            ['retailer-address-1']:      dealership.address,
            ['retailer-address-2']:      dealership.address_2,
            ['retailer-city']:           dealership.city,
            ['retailer-country']:        getCountryId(dealership.country, state.countries),
            ['retailer-state-province']: getStateId(dealership.state, state.states, dealership.country, state.countries),
            ['retailer-zip-code']:       dealership.zip,
            ['retailer-timezone']:       parseInt(dealership.timezone_id),
            ['powerchord-subdomain']:    dealership.subdomain,
            ['enable-dynamic-field']:    dealership.has_custom_website ?? false,
        };

        state.hasCustomWebsite = dealership.has_custom_website;
        state.isAccountActive = dealership.is_active;

        if (state.generalViewInputValues['retailer-country']) {
            const countryId = state.generalViewInputValues['retailer-country'];
            const filteredStates = state.states.filter(country => country.country_id === countryId);
            state.retailerGeneralViewSettings[1].columnsData[0]['retailer-state-province-and-zip-code'][0].options = filteredStates;
        }
        state.updateComponent += 1; // Force re-render
    },
    setCountries(state, countries) {
        state.countries = countries;
        state.retailerGeneralViewSettings[1].columnsData[0]['retailer-country'].options = countries;
    },
    updateGeneralValue(state, { event }) {
        typeof event.preUpdateCb === 'function' && event.preUpdateCb({ state, event });
        state.generalViewInputValues[event.id] = event.value;
        typeof event.postUpdateCb === 'function' && event.postUpdateCb({ state, event });
        state.updateComponent = +1;
    },
    updateUserProfileValues(state, { event }) {
        state.userInputValues[event.id] = event.value;
        state.updateComponent = +1;
    },
    setHasCustomWebsite(state, value) {
        state.hasCustomWebsite = value;
    },
    setIsSaving(state, value) {
        state.isSaving = value;
    },
    updateGeneralIsValidPhone(state, {event, sectionIndex}) {
        state.userProfileSettings[sectionIndex].columnsData[event.columnIndex][event.inputIndex].isValid = event.isValid;
        state.updateComponent += 1;
    },
    updateContacts(state) {
        if (state.contacts[0].id === 'empty') {
            state.contacts.pop();
        }
        state.contacts.push({
            cc_invoice: false,
            primary:    state.contacts.length === 0 ? true : false,
            first_name: state.inputValues['contact-first-name'],
            last_name:  state.inputValues['contact-last-name'],
            email:      state.inputValues['contact-email'],
            phone:      state.inputValues['contact-phone'],
            type:       state.contactOptions.find(option => option.id === state.inputValues['contact-type']).name,
        });
        state.inputValues['contact-first-name'] = '';
        state.inputValues['contact-last-name'] = '';
        state.inputValues['contact-type'] = 1;
        state.inputValues['contact-phone'] = '';
        state.inputValues['contact-email'] = '';
        state.newContactIsValid = false;
        state.updateComponent += 1;
    },
    updateContactInfoValue(state, { event }) {
        state.inputValues[event.inputIndex] = event.value;
        // We need first and last name to be filled. But, if none are filled,
        // it should also enable the Add Contact button if email or phone is filled.
        const validFullName = Boolean(state.inputValues['contact-first-name']) === Boolean(state.inputValues['contact-last-name']);
        if (validFullName && state.inputValues['contact-first-name']) {
            state.newContactIsValid = true;
        } else if (validFullName && (state.inputValues['contact-email'] || state.inputValues['contact-phone'])) {
            state.newContactIsValid = true;
        } else {
            state.newContactIsValid = false;
        }
    },
    updateContactInfoIsValidPhone(state, { event }) {
        if (event.inputIndex === 'contact-phone') {
            state.newContactPhoneIsValid = event.isValid;
        }
    },
    setRetailerUser(state, user) {
        state.retailerUser = user;
    },
    setContactInfo(state, value) {
        state.contactInfo = value;
    },
    setActive(state) {
        state.isActive = state.retailerUser?.isActive === true ? 0 : 1;
        state.updateComponent += 1;
    },
    setFacebookPage(state, fbPage) {
        const fbEntityId = state.entity.platforms.find(platform => platform.platform_id === 1).entity_id;
        state.generalViewInputValues['facebook-page-id'] = fbPage[1].requestedPageId ? fbPage[1].requestedPageId : fbEntityId ? fbEntityId : '';
    },
    setCustomerSuccessManagers(state, customerSuccessManagers) {
        state.contactInfoViewSettings[3].columnsData[0]['customer-success-representative-contact-name'].options = customerSuccessManagers;
    },
    setAccountExecutives(state, accountExecutives) {
        state.contactInfoViewSettings[3].columnsData[1]['account-executive-contact-name'].options = accountExecutives;
    },
    setTokenExists(state, { tokenExists, tokenAuthDate }) {
        state.tokenExists = tokenExists;
        state.tokenAuthDate = tokenAuthDate;
        state.updateComponent += 1;
    },
    populateInputs(state) {
        const validateId = (id) => {
            if (id) {
                return parseInt(id) ?? '';
            }
            return null;
        };
        if (!state.contactInfo) {
            return;
        }
        state.inputValues['upload-profile-picture'] = state.retailerUser?.image ?? '';
        state.inputValues['user-role'] = state.retailerUser?.role_name ?? '';
        state.inputValues['user-profile-first-name'] = state.retailerUser?.first_name ??'';
        state.inputValues['user-profile-last-name'] = state.retailerUser?.last_name ??'';
        state.inputValues['user-name'] = state.retailerUser?.username ??'';
        state.inputValues['user-email'] = state.retailerUser?.email ??'';
        state.inputValues['user-phone'] = state.retailerUser?.phone ?? '';
        state.inputValues['billing-contact-first-name'] = state.contactInfo.billing_contact_first_name ?? '';
        state.inputValues['billing-contact-last-name'] = state.contactInfo.billing_contact_last_name ?? '';
        state.inputValues['billing-contact-email'] = state.contactInfo.billing_email ?? '';
        state.inputValues['billing-contact-phone'] = state.contactInfo.billing_phone ? state.contactInfo.billing_phone : '';
        state.inputValues['support-first-name'] = state.contactInfo.support_first_name ?? '';
        state.inputValues['support-last-name'] = state.contactInfo.support_last_name ?? '';
        state.inputValues['support-contact-phone'] = state.contactInfo.support_phone ? state.contactInfo.support_phone : '';
        state.inputValues['support-contact-email'] = state.contactInfo.support_email ?? '';
        state.inputValues['customer-success-representative-contact-email'] = state.contactInfo.customer_success_representative_email;
        state.inputValues['customer-success-representative-contact-name'] = validateId(state.contactInfo.customer_success_representative_contact_id);
        state.inputValues['account-executive-contact-email'] = state.contactInfo.account_executive_contact_email;
        state.inputValues['account-executive-contact-name'] = validateId(state.contactInfo.account_executive_contact_id);
        state.inputValues['contact-type'] = 1;
        state.inputValues['contact-first-name'] = '';
        state.inputValues['contact-last-name'] = '';
        state.inputValues['contact-email'] = '';
        state.inputValues['contact-phone'] = '';
        state.userInputValues['upload-profile-picture'] = state.retailerUser?.image ?? '';
        state.userInputValues['user-is-active'] = state.retailerUser?.isActive ?? false;
        state.userInputValues['user-role'] = state.retailerUser?.role_id ?? '';
        state.userInputValues['user-profile-first-name'] = state.retailerUser?.first_name ??'';
        state.userInputValues['user-profile-last-name'] = state.retailerUser?.last_name ??'';
        state.userInputValues['user-name'] = state.retailerUser?.username ??'';
        state.userInputValues['user-email'] = state.retailerUser?.email ??'';
        state.userInputValues['user-phone'] = state.retailerUser?.phone ? state.retailerUser.phone : '';
        state.updateComponent += 1; // Force re-render
    },
    populateInputsForSelectedUser(state, userData) {
        state.userInputValues['upload-profile-picture'] = userData.image ?? '';
        state.userInputValues['user-role'] = userData.role_id ?? '';
        state.userInputValues['user-profile-first-name'] = userData.first_name ??'';
        state.userInputValues['user-profile-last-name'] = userData.last_name ??'';
        state.userInputValues['user-name'] = userData.username ??'';
        state.userInputValues['user-email'] = userData.email ??'';
        state.userInputValues['user-phone'] = userData.phone ?? '';
        state.userInputValues['user-is-active'] = userData.is_active ?? '';
        state.userDealerships = userData.dealerships;
        state.pictureUrl = userData.image_url ?? '';
        state.newUserPassword = {};
        state.updateComponent += 1; // Force re-render
    },
    updateComponents(state) {
        state.updateComponent += 1;
    },
    handleAccountManagerEmail(state, account) {
        const account_name = state.inputValues['account-executive-contact-name'];
        const customer_name = state.inputValues['customer-success-representative-contact-name'];
        if (account_name) {
            state.inputValues['account-executive-contact-email'] = account.find(e => e.id === account_name).email;
        }
        if (customer_name) {
            state.inputValues['customer-success-representative-contact-email'] = account.find(e => e.id === customer_name).email;
        }
    },
    handleContactSelected(state, index) {
        state.selectedContactIndex = index;
        state.inputValues['contact-type'] = state.contactOptions.find(option => option.name === state.contacts[index].type).id;
        state.inputValues['contact-first-name'] = state.contacts[index].first_name;
        state.inputValues['contact-last-name'] = state.contacts[index].last_name;
        state.inputValues['contact-email'] = state.contacts[index].email;
        state.inputValues['contact-phone'] = state.contacts[index].phone;
        state.updateComponent += 1; // Force re-render
    },
    resetContactInputs(state) {
        state.inputValues['contact-first-name'] = '';
        state.inputValues['contact-last-name'] = '';
        state.inputValues['contact-type'] = 1;
        state.inputValues['contact-phone'] = null;
        state.inputValues['contact-email'] = '';
        state.selectedContactIndex = null;
        state.newContactIsValid = false;
        state.updateComponent += 1;
    },
    handleDefaultContact(state, index) {
        if (!state.contacts[index].primary) {
            state.contacts.forEach((contact, i) => {
                contact.primary = index === i ? true : false;
            });
        }
    },
    handleCcContact(state, index) {
        if (state.contacts[index].email) {
            state.contacts[index].cc_invoice = !state.contacts[index].cc_invoice;
        }
    },
    handleDeleteContact(state) {
        const isDeletedContactPrimary = state.contacts[state.selectedContactIndex].primary;
        state.contacts.splice(state.selectedContactIndex, 1);
        state.selectedContactIndex = null;
        state.inputValues['contact-first-name'] = '';
        state.inputValues['contact-last-name'] = '';
        state.inputValues['contact-type'] = 1;
        state.inputValues['contact-phone'] = '';
        state.inputValues['contact-email'] = '';
        if (!state.contacts.length) {
            state.contacts.push(
                {
                    name:       '',
                    type:       '',
                    primary:    false,
                    cc_invoice: false,
                    email:      '',
                    phone:      '',
                    id:         'empty',
                },
            );
        }
        if (isDeletedContactPrimary && state.contacts.length) {
            state.contacts[0].primary = true;
        }

        state.updateComponent += 1;
    },
    handleContactJson(state) {
        const contacts = state.contactInfo?.contacts ? JSON.parse(state.contactInfo.contacts) : [];
        if (!contacts || !contacts.length) {
            state.contacts = [{
                name:       '',
                type:       '',
                primary:    false,
                cc_invoice: false,
                email:      '',
                phone:      '',
                id:         'empty',
            }];
            return;
        }
        state.contacts = state.contactInfo?.contacts ?  JSON.parse(state.contactInfo.contacts) : [];

        if (state.contacts.length === 1) {
            state.contacts[0].primary = true;
        }
    },
    handleContactEdit(state) {
        if (state.selectedContactIndex !== null && state.selectedView === 'contact-information') {
            state.contacts[state.selectedContactIndex].first_name = state.inputValues['contact-first-name'];
            state.contacts[state.selectedContactIndex].last_name = state.inputValues['contact-last-name'];
            state.contacts[state.selectedContactIndex].type = state.contactOptions
                .find(option => option.id === parseInt(state.inputValues['contact-type']))
                .name;
            state.contacts[state.selectedContactIndex].phone = state.inputValues['contact-phone'];
            state.contacts[state.selectedContactIndex].email = state.inputValues['contact-email'];
        }
    },
    handleContactEmailEdit(state) {
        if (!state.inputValues['contact-email']) {
            state.contacts[state.selectedContactIndex].cc_invoice = false;
        }
        state.updateComponent += 1;
    },
    handleBillingDataChange(state, payload) {
        state.dealership[payload.key] = payload.value;
    },
    setUserRoles(state, userRoles) {
        state.userRoles = userRoles;
        state.userProfileSettings[1].columnsData[0]['user-role'].options = userRoles;
        state.userProfileSettings[1].columnsData[0]['user-role'].value = 4;
    },
    updateUserIsActive(state, value) {
        state.userInputValues['user-is-active'] = value;
    },
    setNewUserPassword(state, password) {
        state.newUserPassword = password;
    },
    setAccessRules(state, payload) {
        state.dealership[payload.type] = payload.value;
    },
    setEntity(state, entity) {
        state.entity = entity;
    },
    setSupportContactEmail(state, isAdmin) {
        const contactEmail = state.contactInfoViewSettings[2].columnsData[0]['support-contact-email'];
        contactEmail.type = isAdmin ? 'text' : 'link';
    },
    setSupportContactPhone(state, isAdmin) {
        const contactPhone = state.contactInfoViewSettings[2].columnsData[0]['support-contact-phone'];
        contactPhone.type = isAdmin ? 'text' : 'tel';
    },
    setSaveData(state) {
        const data = new Object(state.dealership);
        data.name = state.generalViewInputValues['retailer-name'];
        data.phone = state.generalViewInputValues['retailer-phone'];
        data.url = state.generalViewInputValues['default-website'];
        data.custom_url = state.generalViewInputValues['custom-website'];
        data.external_id = state.dealership.external_id;
        data.address = state.generalViewInputValues['retailer-address-1'];
        data.address_2 = state.generalViewInputValues['retailer-address-2'];
        data.city = state.generalViewInputValues['retailer-city'];
        data.state = getStateAbbreviation(state.generalViewInputValues['retailer-state-province'], state.states);
        data.zip = state.generalViewInputValues['retailer-zip-code'];
        data.country = getCountryNameOrCode(state.generalViewInputValues['retailer-country'], state.countries);
        data.timezone_id = state.generalViewInputValues['retailer-timezone'] ?? '';
        data.subdomain = state.generalViewInputValues['powerchord-subdomain'] ?? '';
        data.has_custom_website = state.hasCustomWebsite;
        data.is_active = state.isAccountActive;
        data.vendor_id = state.generalViewInputValues ['external-retailer-id'];

        if (state.fbPageId !== state.generalViewInputValues['facebook-page-id']) {
            data.lister.facebook_page_id = state.generalViewInputValues['facebook-page-id'];
        }

        state.generalViewSaveData = data;
    },
    setIsLoading(state, value) {
        state.isLoading = value;
    },
    setCurrentLink(state, payload) {
        if (state.dealership.lister) {
            state.dealership.lister.current_link = payload;
        }

        if (!payload) {
            state.retailerGeneralViewSettings[2].columnsData[0]['manually-linked'].label = '';
            state.retailerGeneralViewSettings[2].disabled = false;
            return;
        }
        if (payload.token?.is_agency) {
            state.retailerGeneralViewSettings[2].columnsData[0]['manually-linked'].label = 'Agency Linked';
            state.retailerGeneralViewSettings[2].disabled = false;
        } else {
            state.retailerGeneralViewSettings[2].columnsData[0]['manually-linked'].label = 'Manually Linked';
            state.retailerGeneralViewSettings[2].disabled = true;
        }
    },
    setStatusDates(state, payload) {
        state.dealership.cancelled_at = payload.cancelled_at;
        state.dealership.last_activated_at = payload.last_activated_at;
        state.dealership.deactivated_at = payload.deactivated_at;
    },
    handleModalTriggered(state) {
        state.isModalOpen = !state.isModalOpen;
    },
    setFbPageId(state, fbPageId) {
        state.fbPageId = fbPageId;
    },
    setIsAccountActive(state, value) {
        state.isAccountActive = value;
    },
    setOriginalActive(state, payload = null) {
        state.originalIsAccountActive = payload || state.isAccountActive;
    },
    setDealershipServices(state, payload) {
        state.dealershipServices = payload;
    },
    updateCurrentContacts(state, contacts) {
        state.contactInfo.contacts = JSON.stringify(contacts);
        state.dealership.contacts = JSON.stringify(contacts);
    },
    updateIsSavingRetailerContact(state, value) {
        state.isSavingRetailerContact = value;
    },
    saveContactEdit(state) {
        const contactIndex = state.selectedContactIndex;
        const contactValues = {
            first_name: state.inputValues['contact-first-name'],
            last_name:  state.inputValues['contact-last-name'],
            type:       state.contactOptions.find(type => type.id === state.inputValues['contact-type']).name,
            phone:      state.inputValues['contact-phone'],
            email:      state.inputValues['contact-email'],
        };
        Object.assign(state.contacts[contactIndex], contactValues);
    },
    restorePrimaryContact(state) {
        const contactIndex = JSON.parse(state.dealership.contacts).findIndex(contact => contact.primary);

        if (!state.contacts[contactIndex].primary) {
            state.contacts.forEach((contact, i) => {
                contact.primary = contactIndex === i ? true : false;
            });
        }
    },
};

<template>
    <popup-modal
        class="modal-save-reference-number"
        modal-size="sm"
        :close-button="false"
        @on-yes-button-click="confirm"
        @close="cancel"
    >
        <template #icon>
            <img src="/images/popup-icons/ts-information-icon.svg" alt="Information Icon">
        </template>
        <template #header>
            <h1>{{ title }}</h1>
        </template>
        <template #content>
            <p>
                You've set the default reference number(s) for all future invoices. To make changes, visit the "Unpaid
                Invoices" table and manually update the reference number(s).
            </p>

            <br>
            Do you wish to proceed?
        </template>
        <template #footer>
            <button class="btn btn-default btn-no" @click="cancel">
                No
            </button>
            <ts-button @click="confirm">
                <template #buttonName>
                    Yes
                </template>
            </ts-button>
        </template>
    </popup-modal>
</template>

<script>
import PopupModal from '@/components/app/PopupModal.vue';
import TsButton from '@/components/app/TsButton.vue';

export default {
    name:       'SaveReferenceModal',
    components: {TsButton, PopupModal},
    props:      {
        title: {
            type:     String,
            required: true,
        },
    },
    methods: {
        cancel() {
            this.$emit('$cancel');
        },
        confirm() {
            this.$emit('$confirm', true);
        },
    },
};
</script>

<style>
.modal-save-reference-number {
    .popup-modal-body-container {
        padding-left: 37px !important;
        padding-right: 36px !important;
        padding-bottom: 1rem !important;
    }

    .modal-dialog {
        margin-top: 275px;
        padding: 0;
    }

    .m-t-5 {
        margin-top: 1rem;
    }

    .btn-no {
        min-width: 55px;
        display: flex;
        height: 35px;
        padding: 8px 10px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
    }
}
</style>

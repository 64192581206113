<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="blue-popout-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 0.75V5C16 5.40438 15.7568 5.76906 15.3828 5.92375C15.0078 6.07706 14.5791 5.99259 14.2928 5.70694L13 4.41563L6.70625 10.7094C6.5125 10.9031 6.25625 11 6 11C5.74375 11 5.48812 10.9023 5.29312 10.707C4.9025 10.3164 4.9025 9.68359 5.29312 9.29297L11.5875 3L10.2944 1.70687C10.0082 1.42075 9.92218 0.990937 10.0776 0.616875C10.2312 0.243656 10.5969 0 11 0H15.25C15.6656 0 16 0.335625 16 0.75Z" fill="#0066FF" />
                <path opacity="0.4" d="M12 10C11.4478 10 11 10.4478 11 11V14H2V5H5C5.55219 5 6 4.5525 6 4C6 3.4475 5.55219 3 5 3H2C0.895312 3 0 3.89531 0 5V14C0 15.1044 0.895312 16 2 16H11C12.1047 16 13 15.1044 13 14V11C13 10.4469 12.5531 10 12 10Z" fill="#0066FF" />
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'BluePopoutIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>
<style lang="scss">
.blue-popout-icon-container{}
</style>

export default {
    methods: {
        getRoleName(roleId, roleName) {
            switch (roleId) {
                case 1:
                    return 'TS INTERNAL';
                case 4:
                    return 'RETAILER';
                case 8:
                    return 'TS INTERNAL - SUPPORT';
                case 9:
                    return 'BRAND MANAGER';
                case 10:
                    return 'TS INTERNAL - ACCOUNTING';
                case 12:
                    return 'BRAND MANAGER - REPORTING';
                case 13:
                    return 'BRAND MANAGER - ADMIN';
                default:
                    return roleName;
            }
        }
    }
};

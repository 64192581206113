import axios from '@/axios';

export default {
    async getStates({ commit }) {
        const states = await axios
            .get('/api/states')
            .then(response => response.data.items)
            .catch(err => {
                console.error('Error when getting states', err);
            });
        commit('setStates', states);
    },
    async getTimeZones({ commit }) {
        const timeZones = await axios
            .get('/api/timezones')
            .then(response => response.data.items)
            .catch(err => {
                console.error('Error when getting timezone list', err);
            });
        commit('setTimeZones', timeZones);
    },
    async checkTokenExists({ commit, state }, { platformId, platformName }) {
        const currentPageId = state.generalViewInputValues[`${platformName}-page-id`].replace(/[^0-9]+/g, '');
        if (currentPageId.length === 0) {
            commit('setTokenExists', { tokenExists: false, tokenAuthDate: '' });
            return;
        }

        const url = encodeURI(`/api/platforms/${platformId}/token-exists?page_id=${currentPageId}`);
        const payload = await axios
            .get(url)
            .then((response) => {
                const data = { tokenExists: response.data.exists };
                if (response.data && response.data.account && response.data.account.auth_date) {
                    data.tokenAuthDate = `(${response.data.account.auth_date})`;
                } else {
                    data.tokenAuthDate = '';
                }
                return data;
            })
            .catch((error) => {
                console.log(error);
                return { tokenExists: false, tokenAuthDate: '(Error getting token)' };
            });
        commit('setTokenExists', payload);
    },
    getFbPageId({ commit, state }) {
        //get FBpageID
        axios.get(`/api/dealerships/${state.dealer}/platforms`)
            .then(({data: {platforms }} ) => {
                const fbPageId = platforms.find(o => o.name === 'Facebook' && o.is_linked == true)?.entity_id;
                commit('setFbPageId', fbPageId);
            })
            .catch(() => {
                window.flash.show({
                    type: 'error',
                    text: 'Unable to access platform.',
                });
            });
    },
};

import axios from '@/axios';


let productSort = (a, b) => {
    return (a.sortOrder - b.sortOrder) || (a.id - b.id);
};

let addChildren = state => parent => {
    parent.children = state.productSelectionPage.products.filter(p => p.parentId === parent.id).map(addChildren(state));
    parent.children.sort(productSort);
    return parent;
};

export default {
    async loadData(oemManagerId, connectId) {
        return axios.get(`/api/brands/${oemManagerId}/connects/${connectId}`).then(async response => {
            let stateData = Object.assign({}, response.data.connect.data);
            stateData.programName = response.data.connect.brand_program_name;
            stateData.supportEmail = response.data.connect.support_email;
            stateData.collapseClassifiersTo = response.data.connect.data.collapseClassifiersTo;
            stateData.forceBillToRetailer = response.data.services.some(s => s.pivot.billed_to_dealership && s.pivot.price);
            stateData.resellerId = response.data.reseller.id;
            stateData.resellerName = response.data.reseller.name;
            stateData.profileDocument = response.data.reseller.profile_document;

            if (stateData.forceBillToRetailer) {
                if (!stateData.creditCardPage) {
                    stateData.creditCardPage = {
                        enabled: true,
                    };
                }
            }
            let classifiers = response.data.classifiers;

            if (stateData.classifiers) {
                if (!stateData.productSelectionPage) {
                    stateData.productSelectionPage = {
                        products: [],
                        enabled:  true,
                    };
                }
                stateData.classifiers.forEach(classifierId => {
                    let classifier = classifiers.find(c => c.id == classifierId);
                    let product = stateData.productSelectionPage.products.find(p => p.id == classifierId);
                    let productIndex = stateData.productSelectionPage.products.indexOf(product);
                    if (!classifier) {
                        if (product) {
                            stateData.productSelectionPage.products.splice(productIndex, 1);
                        }
                        return;
                    }
                    let insertClassifier = {
                        id:        classifier.id,
                        name:      classifier.name,
                        parentId:  classifier.parent_id,
                        sortOrder: classifier.sort_order,
                        required:  false,
                        hidden:    false,
                    };
                    if (!product) {
                        stateData.productSelectionPage.products.push(insertClassifier);
                    } else {
                        product.sortOrder = classifier.sort_order;
                        if (product.parentId != classifier.parent_id) {
                            stateData.productSelectionPage.products.splice(productIndex, 1, insertClassifier);
                        }
                    }
                });
                stateData = this.buildProductTree(stateData);
                delete stateData.classifiers;
            }

            if (!stateData.destinationLoginsPage) {
                stateData.destinationLoginsPage = {
                    services: [],
                };
            }
            stateData.destinationLoginsPage.services = response.data.services.filter(service => service.platform_id !== null && service.id !== 23);

            this.$patch(stateData);

            let foundRequiredHiddenAdjustment;
            do {
                foundRequiredHiddenAdjustment = false;
                if (this.productSelectionPage.products) {
                    for (let product of this.productSelectionPage.products) {
                        if (product.parentId) {
                            let parent = this.productSelectionPage.products.find(p => p.id == product.parentId);
                            if (parent) {
                                if (product.required && !parent.required) {
                                    foundRequiredHiddenAdjustment = true;
                                    await this.toggleRequired(parent.id, true);
                                    break;
                                }
                                if (!product.hidden && parent.hidden) {
                                    foundRequiredHiddenAdjustment = true;
                                    await this.toggleHidden(parent.id, false);
                                    break;
                                }
                            }
                        }
                    }
                }
            } while (foundRequiredHiddenAdjustment);

            this.$clearHistory();

            return response.data.connect;
        });
    },
    buildProductTree(stateData) {
        if (stateData.productSelectionPage.products) {
            stateData.productTree = stateData.productSelectionPage.products.filter(p => p.parentId === null).map(addChildren(stateData));
            stateData.productTree.sort(productSort);
        }
        return stateData;
    },
    async saveData(oemManagerId, connectId) {
        return axios.post(`/api/brands/${oemManagerId}/connects/${connectId}`, this.saveState);
    },
    async toggleRequired(productId, toggle, patchProductTree = true) {
        let product = this.productSelectionPage.products.find(p => p.id == productId);

        if (product) {
            this.$patch(() => {
                product.required = toggle;
            });
        }

        if (toggle) {
            if (product) {
                this.toggleRequired(product.parentId, toggle, false);
            }
        } else {
            this.productSelectionPage.products.filter(p => p.parentId == productId).forEach(p => this.toggleRequired(p.id, toggle));
            this.toggleHidden(productId, false, false);
        }
        if (patchProductTree) {
            this.$patch(this.buildProductTree(this.$state));
        }
    },
    async toggleHidden(productId, toggle, patchProductTree = true) {
        let product = this.productSelectionPage.products.find(p => p.id == productId);
        if (toggle && !product.required) {
            return;
        }

        if (product) {
            this.$patch(() => {
                product.hidden = toggle;
            });
        }

        if (toggle) {
            this.productSelectionPage.products.filter(p => p.parentId == productId).forEach(p => {
                this.toggleRequired(p.id, toggle, false).then(() => this.toggleHidden(p.id, toggle, false));
            });
        } else {
            if (product) {
                this.toggleHidden(product.parentId, toggle, false);
            }
        }
        if (patchProductTree) {
            this.$patch(this.buildProductTree(this.$state));
        }
    },
};

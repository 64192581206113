<template>
    <div>
        <div
            v-if="stripeMount()"
            class="payment profile"
        >
            <h5 class="text-muted">Card on File</h5>
            <credit-card v-if="paymentProfile" :payment-profile="profile" />
        </div>
        <div v-else>
            <div>
                <h5 class="text-muted">Credit Card Information</h5>
                <form
                    id="payment_profile_form"
                    class="payment_profile_form"
                    @submit.prevent="checkSave"
                >
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label>Name</label>
                                                <input
                                                    v-model="profile.firstName"
                                                    required
                                                    class="form-control"
                                                    name="first-name"
                                                    placeholder="First"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label>&nbsp;</label>
                                                <input
                                                    v-model="profile.lastName"
                                                    required
                                                    class="form-control"
                                                    name="last-name"
                                                    placeholder="Last"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div
                                    id="seCard"
                                    ref="cardInput"
                                    class="form-control"
                                >
                                    <!-- A Stripe Element will be inserted here. -->
                                </div>
                                <div v-if="profile.lastFour">**** **** **** {{ profile.lastFour }}</div>
                            </div>
                            <div class="row">
                                <div
                                    id="card-errors"
                                    role="alert"
                                />
                            </div>
                        </div>
                    </div>
                    <h5 class="text-muted">Billing Information</h5>
                    <div class="form-group">
                        <label>
                            Email
                            <i
                                class="fa fa-info-circle"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="This email will be the invoice recipient."
                            />
                        </label>
                        <input
                            v-model="profile.email"
                            class="form-control"
                        >
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Address</label>
                                <input
                                    v-model="profile.address"
                                    required
                                    class="form-control"
                                >
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Address 2</label>
                                <input
                                    v-model="profile.address_2"
                                    class="form-control"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>City</label>
                                <input
                                    v-model="profile.city"
                                    required
                                    class="form-control"
                                >
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label>State</label>
                                <state-select
                                    :model-value="profile.state"
                                    :selected-value="profile.state"
                                    class="form-control"
                                    field="abbreviation"
                                    @update:model-value="profile.state = $event"
                                />
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label>Postal Code</label>
                                <input
                                    v-model="profile.zip"
                                    required
                                    class="form-control"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="well well-sm">
                        <input
                            v-model="authorized"
                            required
                            type="checkbox"
                        >
                        I certify that I am an authorized agent or individual allowing me to
                        accept the
                        <a
                            href="https://s3.amazonaws.com/ts-lvlv/documents/thumbstopper_user_agreement.pdf"
                            target="_blank"
                        >terms of use</a>
                        of both the software and authorize payment and contractual
                        responsibilities within. I authorize the monthly invoice to be
                        charged to the credit card until the account is cancelled.
                    </div>
                    <div class="well well-sm">
                        <input
                            v-model="privacy"
                            required
                            type="checkbox"
                        >
                        I have read and agree to the payment notice /
                        <a
                            href="https://s3.amazonaws.com/ts-lvlv/documents/privacy_policy.pdf"
                            target="_blank"
                        >privacy policy</a>.
                    </div>
                    <input
                        type="submit"
                        class="btn btn-primary"
                        :disabled="roleStore.viewMode"
                        value="Save"
                    >
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import CreditCard from '@/components/app/CreditCard.vue';
import StateSelect from '@/components/app/StateSelect.vue';
import { roleStore, userStore } from '@/stores/pinia';
import { waitForLoad } from '@/waitForLoad.js';
import dayjs from 'dayjs';
import { mapStores } from 'pinia';

let stripe, elements, seCard;

const stripeLoaded = waitForLoad(window, 'Stripe').then(() => {
    stripe = window.Stripe(window.Laravel.stripeKey);
    elements = stripe.elements();
    seCard = elements.create('card');
});

export default {
    components: {
        CreditCard,
        StateSelect,
    },
    emits: ['allow-save', 'save-profile'],
    props: {
        paymentProfile: {
            type: Object,
            required: true,
        },
        create: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            privacy:      false,
            authorized:   false,
            shouldCreate: this.create,
            stripe:     null,
        };
    },
    computed: {
        ...mapStores(userStore,roleStore),
        currentYear() {
            return dayjs().year();
        },
        expiry() {
            return (
                this.profile.cardExpirationMonth +
        ' / ' +
        this.profile.cardExpirationYear
            );
        },
        profile() {
            return (
                this.paymentProfile || {
                    stripeToken:         '',
                    cardType:            '',
                    lastName:            '',
                    firstName:           '',
                    cardName:            '',
                    expiry:              '',
                    cardExpirationMonth: '',
                    cardExpirationYear:  '',
                    lastFour:            '',
                    cardAccountNumber:   '',
                    email:               '',
                    cardCvv:             '',
                    address:             '',
                    address_2:           '',
                    city:                '',
                    state:               '',
                    zip:                 '',
                }
            );
        },
    },
    watch: {
        create() {
            this.shouldCreate = this.create;
        },
    },
    async mounted() {
        await stripeLoaded;
        this.stripe = stripe;
        seCard.mount(this.$refs.cardInput);
    },
    methods: {
        generateToken() {
            return this.stripe.createToken(seCard).then(result => {
                if (result.error) {
                    // Inform the user if there was an error.
                    var errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                } else {
                    // Send the token to your server.
                    this.profile.stripeToken = result.token;
                }
            });
        },
        checkSave() {
            if (this.roleStore.viewMode){
                return;
            }
            this.generateToken().then(() => {
                if (this.privacy && this.authorized && this.profile.stripeToken) {
                    this.$emit('allow-save', true);
                    this.$emit('save-profile');
                } else {
                    this.$emit('allow-save', false);
                }
            });
        },
        stripeMount() {
            if (!this.shouldCreate) {
                this.$nextTick(() => {
                    seCard.unmount();
                });
            } else {
                this.$nextTick(() => {
                    seCard.mount(this.$refs.cardInput);
                });
            }

            return !this.shouldCreate;
        },
    },
};
</script>
<style>
select:invalid {
  color: gray;
}
</style>

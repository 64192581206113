export default {
    async initialize(options = {}) {
        const setup = options.setup ?? this.generateSetup(options.init, options.events || {});
        const payload = {
            ...this.config,
            ...options,
            setup,
        };
        this.$patch({ config: payload });
    },
    initializeApiKey(apiKey) {
        this.$patch({ apiKey });
    },
};

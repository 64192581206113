<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="user-roles-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 2C8 0.895313 7.10469 0 6 0C4.89531 0 4 0.895313 4 2C2.89531 2 2 2.89531 2 4V4.5C2 4.775 2.22375 5 2.5 5H9.5C9.775 5 10 4.775 10 4.5V4C10 2.89531 9.10313 2 8 2ZM6 2.75C5.58437 2.75 5.25 2.41406 5.25 2C5.25 1.58562 5.58594 1.25 6 1.25C6.41406 1.25 6.75 1.58562 6.75 2C6.75 2.41406 6.41563 2.75 6 2.75ZM7 11H5C3.61937 11 2.5 12.1194 2.5 13.5C2.5 13.775 2.72375 14 3 14H9C9.27612 14 9.5 13.7761 9.5 13.5C9.5 12.1187 8.38125 11 7 11ZM8 8C8 6.89531 7.10469 6 6 6C4.89531 6 4 6.89687 4 8C4 9.10312 4.89531 10 6 10C7.10469 10 8 9.10312 8 8Z" fill="#85878F" />
                <path d="M10.5 2H8C9.10469 2 10 2.89531 10 4V4.5C10 4.775 9.775 5 9.5 5H2.5C2.22375 5 2 4.775 2 4.5V4C2 2.89531 2.89531 2 4 2H1.5C0.671562 2 0 2.67156 0 3.5V14.5C0 15.3281 0.671562 16 1.5 16H10.5C11.3284 16 12 15.3284 12 14.5V3.5C12 2.67156 11.3281 2 10.5 2ZM6 6C7.10469 6 8 6.89531 8 8C8 9.10469 7.10469 10 6 10C4.89531 10 4 9.10313 4 8C4 6.89687 4.89687 6 6 6ZM9 14H3C2.72388 14 2.5 13.7761 2.5 13.5C2.5 12.1187 3.61875 11 5 11H7C8.38062 11 9.5 12.1194 9.5 13.5C9.5 13.775 9.275 14 9 14Z" fill="#DEE0E2" />
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'UserRolesIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>

<style lang="scss">
.user-roles-icon-container{
}
</style>

<template>
    <div class="product-selection-page">
        <connect-textarea
            name="product-selection-header"
            class="header"
            :edit="edit"
            :focusable="!edit"
            :limit="80"
            :content="connectStore.productSelectionPage.header"
            @update:content="updateHeader"
            @commit="commitHeader"
        />
        <connect-textarea
            name="product-selection-text"
            class="text"
            :edit="edit"
            :focusable="!edit"
            :limit="300"
            :content="connectStore.productSelectionPage.text"
            @update:content="updateText"
            @commit="commitText"
        />
        <div class="product-select-list col-xs-12">
            <button
                v-if="!connectStore.productSelectionPage.enabled || connectStore.productSelectionPage.selectAllEnabled"
                class="btn btn-default live-only"
                @click="selectAll"
            >
                Select All
            </button>
            <div class="products">
                <div class="col-xs-12">
                    <div class="col-xs-2 require-hide">
                        <!-- I know these look weird, but its for whitespace -->
                        <span class="checkbox-header edit-only">Require</span>&nbsp;<circle-info-gray-icon
                            class="edit-only" :tool-tip="requireTooltip"
                        />
                    </div>
                    <div class="col-xs-2">
                        <span class="checkbox-header edit-only">Hide</span>&nbsp;<circle-info-gray-icon
                            class="edit-only" :tool-tip="hideTooltip"
                        />
                    </div>
                </div>
                <template v-for="(tier1) in productTree" :key="tier1.name">
                    <div v-if="show([tier1.id], 1)" class="col-xs-12">
                        <div class="col-xs-12">
                            <div class="col-xs-6 product">
                                <input
                                    :checked="productSelected[tier1.id]"
                                    type="checkbox"
                                    class="live-only"
                                    :disabled="productRequired[tier1.id]"
                                    @change="event => toggleClassifier(tier1, event.target.checked)"
                                >
                                <h2 class="tier1-header">{{ tier1.name }}</h2>
                            </div>
                            <div class="col-xs-2 required-column">
                                <input
                                    type="checkbox" class="edit-only" :checked="tier1.required"
                                    @change="event => updateRequired(tier1, event)"
                                >
                            </div>
                            <div class="col-xs-2 hide-column">
                                <template v-if="tier1.required">
                                    <input
                                        type="checkbox" class="edit-only" :checked="tier1.hidden"
                                        @change="event => updateHidden(tier1, event)"
                                    >
                                </template>
                            </div>
                        </div>
                        <template v-for="tier2 in tier1.children" :key="tier1.name + tier2.name">
                            <template v-if="show([tier2.id], 2)">
                                <div class="col-xs-12">
                                    <div class="col-xs-6 product tier2">
                                        <input
                                            :checked="productSelected[tier2.id]"
                                            type="checkbox"
                                            class="live-only"
                                            :disabled="productRequired[tier2.id]"
                                            @change="event => toggleClassifier(tier2, event.target.checked)"
                                        >
                                        {{ tier2.name }}
                                    </div>
                                    <div class="col-xs-2 required-column">
                                        <input
                                            type="checkbox" class="edit-only" :checked="tier2.required"
                                            @change="event => updateRequired(tier2, event)"
                                        >
                                    </div>
                                    <div class="col-xs-2 hide-column">
                                        <template v-if="tier2.required">
                                            <input
                                                type="checkbox" class="edit-only" :checked="tier2.hidden"
                                                @change="event => updateHidden(tier2, event)"
                                            >
                                        </template>
                                    </div>
                                    <template v-for="tier3 in tier2.children" :key="tier1.name + tier2.name + tier3.name">
                                        <template v-if="show([tier3.id], 3)">
                                            <div class="col-xs-12">
                                                <div class="col-xs-6 product tier3">
                                                    <input
                                                        :checked="productSelected[tier3.id]"
                                                        type="checkbox"
                                                        class="live-only"
                                                        :disabled="productRequired[tier3.id]"
                                                        @change="event => toggleClassifier(tier3, event.target.checked)"
                                                    >
                                                    {{ tier3.name }}
                                                </div>
                                                <div class="col-xs-2 required-column">
                                                    <input
                                                        type="checkbox" class="edit-only" :checked="tier3.required"
                                                        @change="event => updateRequired(tier3, event)"
                                                    >
                                                </div>
                                                <div class="col-xs-2 hide-column tier3-hide">
                                                    <template v-if="tier3.required">
                                                        <input
                                                            type="checkbox" class="edit-only" :checked="tier3.hidden"
                                                            @change="event => updateHidden(tier3, event)"
                                                        >
                                                    </template>
                                                </div>
                                                <template v-for="tier4 in tier3.children" :key="tier1.name + tier2.name + tier3.name + tier4.name">
                                                    <template v-if="show([tier4.id], 4)">
                                                        <div class="col-xs-12">
                                                            <div class="col-xs-6 product tier4">
                                                                <input
                                                                    :checked="productSelected[tier4.id]"
                                                                    type="checkbox"
                                                                    class="live-only"
                                                                    :disabled="productRequired[tier4.id]"
                                                                    @change="event => toggleClassifier(tier4, event.target.checked)"
                                                                >
                                                                {{ tier4.name }}
                                                            </div>
                                                            <div class="col-xs-2 required-column">
                                                                <input
                                                                    type="checkbox" class="edit-only"
                                                                    :checked="tier4.required"
                                                                    @change="event => updateRequired(tier4, event)"
                                                                >
                                                            </div>
                                                            <div class="col-xs-2 hide-column tier4-hide">
                                                                <template v-if="tier4.required">
                                                                    <input
                                                                        type="checkbox" class="edit-only"
                                                                        :checked="tier4.hidden"
                                                                        @change="event => updateHidden(tier4, event)"
                                                                    >
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </template>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </template>
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {mapStores} from 'pinia';
import {connectStore, liveConnectStore} from '@/stores/pinia.js';
import CircleInfoGrayIcon from '@/components/icons/CircleInfoGrayIcon.vue';
import ConnectTextarea from '@/components/connects/ConnectTextarea.vue';

export default {
    components: {
        CircleInfoGrayIcon,
        ConnectTextarea,
    },
    props: {
        edit: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    data() {
        return {
            requireTooltip:     'Selection will be chosen by default.',
            hideTooltip:        'Selection will not be visible to retailers, but will be added to their Product Tiers Settings.',
            allSelected:        false,
            selectedClassifier: null,
            showChildren:       {},
        };
    },
    computed: {
        productHidden() {
            let products = {};
            this.connectStore.productSelectionPage.products.forEach(p => {
                products[p.id] = p.hidden;
            });
            return products;
        },
        productRequired() {
            let products = {};
            this.connectStore.productSelectionPage.products.filter(p => p.required).forEach(p => {
                products[p.id] = true;
            });
            return products;
        },
        productSelected() {
            let products = {};
            this.connectStore.productSelectionPage.products.filter(p => !!this.liveConnectStore.classifiers.includes(p)).forEach(p => {
                products[p.id] = true;
            });
            return products;
        },
        productTree() {
            if (this.connectStore.savedClassifiers && this.connectStore.savedClassifiers.length) {
                return this.connectStore.productTree.filter(
                    p => this.connectStore.savedClassifiers.includes(p.id),
                );
            }
            return this.connectStore.productTree;
        },
        collapseClassifiersTo() {
            return this.connectStore.collapseClassifiersTo;
        },
        ...mapStores(connectStore, liveConnectStore),
        formValid() {
            return this.liveConnectStore.classifiers.length > 0;
        },
    },
    mounted() {
        if (this.edit) {
            this.selectDefaultClassifiers(false);
        }
        if (this.connectStore.allProductsHidden) {
            this.$emit('next');
        }
    },
    methods: {
        commitHeader(value) {
            this.connectStore.$patch({ productSelectionPage: { header: value }});
        },
        commitText(value) {
            this.connectStore.$patch({ productSelectionPage: { text: value }});
        },
        selectAll() {
            this.allSelected = !this.allSelected;
            let allSelected = Object.values(this.productSelected).some(p => p === true);

            if (allSelected) {
                this.connectStore.productSelectionPage.products.forEach(p => {
                    if (!p.required) {
                        this.toggleClassifier(p, this.allSelected);
                    }
                });
                this.selectDefaultClassifiers();
            } else {
                this.connectStore.productSelectionPage.products.forEach(p => {
                    this.toggleClassifier(p, true);
                });
            }
        },
        selectDefaultClassifiers(toggleChildren = true) {
            this.connectStore.productSelectionPage.products.filter(p => p.required).forEach(p => {
                p.children.forEach(child => {
                    this.showChildren[child.id] = true;
                });
                this.toggleClassifier(p, true, toggleChildren);
            });
        },
        toggleClassifier(classifier, checked, toggleChildren = true) {
            this.selectedClassifier = classifier;
            let changed = !!this.productSelected[classifier.id] != checked;
            if (!changed) {
                return;
            }

            if (checked) {
                this.liveConnectStore.addClassifier(classifier);
                if (toggleChildren) {
                    classifier.children.forEach(child => {
                        if (child.parentId === this.selectedClassifier.id) {
                            this.showChildren[child.id] = true;
                            this.toggleClassifier(child, true);
                        }
                    });
                }
                let parent = this.connectStore.productSelectionPage.products.find(p => p.id == classifier.parentId);
                if (parent) {
                    this.toggleClassifier(parent, true, false);
                }
            } else {
                this.liveConnectStore.removeClassifier(classifier);
                classifier.children.forEach(child => {
                    this.toggleClassifier(child, false);
                    delete this.showChildren[child.id];
                });
                let parent = this.connectStore.productSelectionPage.products.find(p => p.id == classifier.parentId);
                if (parent && !parent.children.some(child => this.productSelected[child.id])) {
                    this.toggleClassifier(parent, false);
                }
            }
        },
        updateHeader(value) {
            this.connectStore.productSelectionPage.header = value;
        },
        updateText(value) {
            this.connectStore.productSelectionPage.text = value;
        },
        updateHidden(product, event) {
            this.connectStore.saveButton.status = null;
            this.connectStore.saveButton.text = 'Save';
            this.connectStore.toggleHidden(product.id, event.target.checked);
        },
        updateRequired(product, event) {
            this.connectStore.saveButton.status = null;
            this.connectStore.saveButton.text = 'Save';
            this.connectStore.toggleRequired(product.id, event.target.checked);
        },
        allChildrenHidden(children) {
            if (!this.edit) {
                return false;
            }

            let hidden = [];
            children.forEach((child) => {
                if (this.productHidden[child.id]) {
                    hidden.push(child.id);
                }
            });

            return hidden.length == children.length;
        },
        show(id, tier) {
            if (!this.edit) {
                return true;
            }
            if (!this.productHidden[id] && (this.productRequired[id] || this.showChildren[id] || !this.collapseClassifiersTo || this.collapseClassifiersTo >= tier)) {
                return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss">
@media(min-width: 950px) {
    .product-selection-page {
        height: 100%;
        max-width: 900px;
        min-width: 900px;

        .connect-layout {
            .connect-body {
                height: 110vh;
                padding-bottom: 45px;
            }
        }

        .header {
            font-size: 36px;
            font-weight: bold;
            left: 60px;
            position: relative;
            width: calc(100% - 105px);
        }

        .text {
            font-size: 16px;
            left: 60px;
            position: relative;
            width: calc(100% - 105px);
        }

        .product-select-list {
            height: calc(
                100%
                - var(--connect-textarea-product-selection-header-height)
                - var(--connect-textarea-product-selection-text-height)
            );
            left: 45px;
            position: relative;
            width: 900px;

            .products {
                max-height: 450px;
                left: -40px;
                position: relative;
                top: 0;
                white-space: nowrap;
                width: 835px;
                overflow-y: auto;

                .require-hide {
                    margin-left: 50%;
                }

                .tier1-header {
                    font-size: 22px;
                    font-weight: bold;
                    margin: 0 0 0 -5px;
                    display: inline;
                }

                .col-xs-12 {
                    padding: 5;
                }

                .col-xs-6, .col-xs-2 {
                    padding-top: 10px;
                }

                .tier2 {
                    padding-left: 45px;
                }

                .tier3 {
                    padding-left: 60px;
                }

                .tier3-hide {
                    margin-left: 5px;
                }

                .tier4 {
                    padding-left: 75px;
                }

                .tier4-hide {
                    margin-left: 10px;
                }

                .product {
                    input[type="checkbox"] {
                        margin-right: 20px;
                    }
                }

                .required-column input[type="checkbox"] {
                    margin-left: 20px;
                }

                .hide-column input[type="checkbox"] {
                    margin-left: 10px;
                }

                .checkbox-header {
                    font-size: 16px;

                    & ~ div {
                        display: inline-block;
                        height: 10px;
                    }
                }
            }
        }

        input[type="checkbox"] {
            transform: scale(1.5);
        }
    }
}

//inbetween phones and desktop (eg. tablets)
@media (max-width: 1025px) and (min-width: 425px) {
    .product-selection-page {
        margin-top: 20px !important;

        .header {
            margin-top: 70px !important;
        }
        .product-select-list {
            .products {
                height: calc(100% - 40px) !important;
                overflow-y: unset !important;
            }
        }
    }
}

@media(max-width: 950px) {
    .product-selection-page {
        height: 100%;

        .header {
            font-weight: bold;
            margin-top: 20px;
        }

        .text {
            font-size: 16px;
        }

        .product-select-list {
            height: calc(100% - 95px);

            .products {
                height: calc(100% - 32px);
                overflow-y: scroll;
                white-space: nowrap;

                .tier1-header {
                    font-size: 22px;
                    font-weight: bold;
                    margin: 0 0 0 -5px;
                    display: inline;
                }

                .col-xs-12 {
                    padding: 0;
                }

                .col-xs-6, .col-xs-2 {
                    padding-top: 10px;
                }

                .tier2 {
                    padding-left: 45px;
                }

                .tier3 {
                    padding-left: 60px;
                }

                .tier3-hide {
                    margin-left: 5px;
                }

                .tier4 {
                    padding-left: 75px;
                }

                .tier4-hide {
                    margin-left: 10px;
                }

                .product {
                    input[type="checkbox"] {
                        margin-right: 20px;
                    }
                }

                .required-column input[type="checkbox"] {
                    margin-left: 20px;
                }

                .hide-column input[type="checkbox"] {
                    margin-left: 10px;
                }

                .checkbox-header {
                    font-size: 16px;

                    & ~ div {
                        display: inline-block;
                        height: 10px;
                    }
                }
            }
        }

        input[type="checkbox"] {
            transform: scale(1.5);
        }
    }
}
</style>

<template>
    <div class="welcome-page" :class="theme.class">
        <div class="images-wrapper">
            <template v-for="image,index in connectStore.welcomePage.images" :key="index">
                <div
                    class="image-wrapper"
                    :class="{show: theme.welcomeImages[index], valid: image, invalid: !image, 'images-wrapper-connect': edit}"
                    :style="isMobile ? theme.welcomeImagesMobile[index] : theme.welcomeImages[index]"
                >
                    <div class="shadow-wrapper">
                        <connect-image :url="image" :disabled="edit" @update:url="url => handleImageUpload(url, index)" />
                    </div>
                </div>
            </template>
        </div>
        <connect-textarea
            name="welcome-header"
            class="header"
            :class="{'header-page': edit}"
            :limit="80"
            :content="connectStore.welcomePage.header"
            :focusable="!edit"
            :edit="edit"
            @update:content="updateHeader"
            @commit="commitHeader"
        />
        <connect-textarea
            name="welcome-text"
            class="text"
            :class="{'text-page': edit}"
            :limit="220"
            :content="connectStore.welcomePage.text"
            :focusable="!edit"
            :edit="edit"
            @update:content="updateText"
            @commit="commitText"
        />
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { connectStore } from '@/stores/pinia.js';
import ConnectThemes from '@/connectThemes.js';
import ConnectImage from '@/components/connects/ConnectImage.vue';
import ConnectTextarea from '@/components/connects/ConnectTextarea.vue';
import ScreenSize from '@/mixins/ScreenSize.js';

export default {
    components: {
        ConnectImage,
        ConnectTextarea,
    },
    mixins: [
        ScreenSize,
    ],
    props: {
        edit: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    data() {
        return {
            formValid: true,
            data:      '',
        };
    },
    computed: {
        theme() {
            return ConnectThemes.find(theme => theme.id == this.connectStore.welcomePage.themeId);
        },
        imagesValid() {
            return this.connectStore.welcomePage.images.length >= this.theme.welcomeImages.length;
        },
        isMobile() {
            return this.screenSizeX <= 950;
        },
        ...mapStores(connectStore),
    },
    watch: {
        screenSizeX(val) {
            if (val < 478) {
                document.querySelector(':root').style.setProperty('--theme-scale', val / 478);
            } else {
                document.querySelector(':root').style.setProperty('--theme-scale', 1);
            }
        },
    },

    methods: {
        commitHeader(value) {
            this.connectStore.$patch({ welcomePage: { header: value }});
        },
        commitText(value) {
            this.connectStore.$patch({ welcomePage: { text: value }});
        },
        handleImageUpload(url, index) {
            // Edit = edit data = retailer side
            if (this.edit) {
                return;
            }
            let copy = Array.from(this.connectStore.welcomePage.images);
            copy.splice(index, 1, url);
            this.connectStore.$patch({ welcomePage: { images: copy }});
        },
        next() {
            this.$emit('next');
        },
        updateHeader(value) {
            this.connectStore.welcomePage.header = value;
        },
        updateText(value) {
            this.connectStore.welcomePage.text = value;
        },
    },
};
</script>

<style lang="scss">
@font-face{
    font-family: Geomanist-Bold;
    src: url('/fonts/templates/geomanist/geomanist-bold-webfont.ttf');
}
@media(min-width: 950px) {
    .welcome-page {
         width: 1234px;
        height: 80vh;
        max-width: 100vw;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: hidden;

        .header {
            font-family: Geomanist;
            font-weight: bold;
            font-size: 36px;
            overflow: hidden;
        }

        .text {
            font-family: Geomanist;
            font-weight: 400;
            font-size: 20px;
            overflow: hidden;
        }

        .next-button-container {
            .learn-more {
                .btn {
                    padding: 1px 5px;
                    font-family: Geomanist-Bold;
                    color: #0066ff;
                }
            }
        }

        button.btn-primary {
            border-radius: 4px;
            background-color: #0066ff;
        }

        .images-wrapper {
            position: relative;
            margin-top: 20px;

            .image-wrapper {
                display: none;

                &::after {
                    border-radius: 10px;
                    content: '';
                    display: block;
                    height: calc(var(--scale) * 200px);
                    left: calc((var(--left) + (var(--scale) * 7)) * 1px);
                    pointer-events: none;
                    position: absolute;
                    top: calc((var(--top) + (var(--scale) * 31)) * 1px);
                    width: calc(var(--scale) * 220px);
                }

                &.valid::after {
                    border: 2px dashed #5cb85b;
                }

                &.invalid::after {
                    border: 2px dashed #e80061;
                }

                .shadow-wrapper {
                    background: url('/images/connects/post-wrapper.png');
                    box-shadow: 0 0 50px 20px #dedede;
                    display: block;
                    padding: 30px 6px 37px 6px;
                    position: absolute;
                    width: 232px;
                    height: 267px;
                    top: calc(var(--top) * 1px);
                    left: calc(var(--left) * 1px);
                    transform: scale(var(--scale));
                    transform-origin: 0 0;

                    .connect-image {
                        height: 100%;
                        width: 100%;

                        img {
                            height: 100%;
                            object-fit: cover;
                            width: 100%;
                        }
                    }
                }
            }
        }

        &.theme1 {
            .header {
                min-height: 124px;
                left: 56px;
                text-align: left;
                margin-top: 108px;
                width: 529px;
            }

            .text {
                min-height: 146px;
                left: 56px;
                text-align: left;
                margin-top: 4px;
                width: 529px;
            }

            .next-button-container {
                display: flex;
                flex-direction: row;
                margin-left: 62px;
                .learn-more {
                    display: flex;
                    align-items: center;
                    margin-left: 30px;
                    border: 2px solid  #0066ff;
                    border-radius: 4px;
                    padding: 3px 12px;
                }
                .popup-header {
                    font-family: Geomanist-Bold;
                    margin-right: 7px;
                    color: #0066ff;
                    font-size: 14px;
                    font-weight: bold;
                    margin-right: 4px;
                }
            }
        }

        &.theme2 {
            .header {
                margin-top: 108px;
                min-height: 98px;
                left: 376px;
                top: 230px;
                width: 493px;

                .align-bottom {
                    background: transparent;
                    bottom: 0;
                }
            }

            .text {
                min-height: 100px;
                left: 270px;
                top: 230px;
                width: 705px;
            }

            textarea {
                text-align: center;
            }

            button.btn-primary {
                left: 548px;
            }

            .static {
                text-align: center;
            }

            .next-button-container {
                display: flex;
                flex-direction: row;
                margin-top: 25rem;
                justify-content: center;
                .learn-more {
                    display: flex;
                    align-items: center;
                    margin-left: 20px;
                    border: 2px solid  #0066ff;
                    border-radius: 4px;
                    padding: 3px 12px;
                    background: white;
                }
                .popup-header {
                    font-family: Geomanist-Bold;
                    margin-right: 7px;
                    color: #0066ff;
                    font-size: 14px;
                    font-weight: bold;
                    margin-right: 4px;
                }
            }
        }
    }
}
//inbetween phones and desktop (eg. tablets)
@media (max-width: 950px) and (min-width: 425px) {
    .welcome-page {
        overflow-x: hidden;
        overflow-y: visible;
        .next-button-container {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            margin-left: 81px !important;
            .learn-more {
                display: flex;
                align-items: center;
                border: 2px solid  #0066ff;
                border-radius: 4px;
                padding: 5px;
                background: white;
                .popup-header {
                    font-family: Geomanist-Bold;
                    color: #0066ff;
                    font-style: normal;
                    font-weight: 900;
                    font-size: 16px;
                    line-height: 140%;
                    left: -45px;
                    position: inherit;
                    margin-right: 8px;
                }
            }
            button.btn-primary {
                top: 80%;
                border-radius: 4px;
                background-color: #0066ff;
            }
        }


        &.theme1 {
            top: 70px !important;
            .text {
                top: -5px !important;
            }
            .next-button-container {
                .learn-more {
                    display: flex;
                    align-items: center;
                    border: 2px solid  #0066ff;
                    border-radius: 4px;
                    padding: 3px 5px;
                    background: white;
                    margin-left: 350px;
                    .btn {
                        padding: 1px 5px;
                        font-family: Geomanist-Bold;
                        color: #0066ff;
                    }
                    .popup-header {
                        font-family: Geomanist-Bold;
                        color: #0066ff;
                        font-style: normal;
                        font-weight: 900;
                        font-size: 16px;
                        line-height: 140%;
                        margin-right: 4px;
                    }
                }
            }
        }
        &.theme2 {
            top: 60px !important;
            .text {
                top: -70px !important;
            }
            .header {
                top: -60px !important;
                margin-top: 0px !important;
            }
            .next-button-container {
                display: flex;
                flex-direction: row;
                top: 76%;
                position:fixed;
                margin-bottom: 31px;
                margin-left: 45px;
                .learn-more {
                    display: flex;
                    align-items: baseline;
                    font-size: 14px;
                    color:#0066ff;
                    border: 2px solid  #0066ff;
                    border-radius: 4px;
                    padding: 3px 12px;
                    background: white;
                }
                button.btn-primary {
                    font-family: Geomanist-Bold;
                    font-weight: bold;
                    height: 35px;
                    top: 82% !important;
                    border-radius: 4px;
                    margin-right: 10px;
                    background-color: #0066ff;
                }
            }
            .images-wrapper {
                margin-top: 55px;
                height: 68px;
            }
        }
        .images-wrapper {
            position: relative !important;
            left: calc(50% - 180px) !important;
            width: 1vw !important;
            top: 20px !important;
            margin-bottom: 233px !important;
        }
    }
}

@media (min-width: 820px) and (min-height: 1180px) {
    .welcome-page {
        &.theme1 {
            display: flex;
            flex-direction: column;
            width: 1300px;
            .header {
                margin-top: 108px;
                min-height: 98px;
                left: 280px;
                top: 430px;
                width: 493px;
                .align-bottom {
                    background: transparent;
                    bottom: 0;
                }
            }
            .images-wrapper {
              position: relative;
              right: 420px;
            }
            .text {
                min-height: 100px;
                left: 197px;
                top: 430px;
                width: 705px;
            }
        }
        &.theme2 {
            .header-page {
                top: 430px;
                width: 493px;
                left: 280px;
            }
            .text-page {
                min-height: 100px;
                left: 180px;
                top: 450px;
                width: 705px;
            }
            .images-wrapper {
                .images-wrapper-connect{
                    position: relative;
                    right: 93px;
                    top:200px;
                }
            }
        }

    }
}
@media (max-height: 820px) {
    .welcome-page {
        &.theme1 {
            display: flex;
            flex-direction: column;
        }
    }
}

@media(max-width: 950px) {
    .welcome-page {
        overflow-x: hidden;
        overflow-y: visible;
        height: 100%;
        max-width: 100vw;

        .header {
            font-family: Geomanist;
            font-weight: bold;
            font-size: 20px;
            margin-top: 5rem;
        }
        .text {
            font-family: Geomanist;
            font-weight: 400;
            font-size: 14px;
        }
        .next-button-container {
            display: flex;
            button.btn-primary {
                font-family: Geomanist-Bold;
                font-weight: bold;
                top: 80%;
                border-radius: 4px;
                margin-right: 10px;
                background-color: #0066ff;
            }
        }
        .images-wrapper {
            height: 47px;
            margin-bottom: 110px;
            position: relative;
            transform-origin: top left;
            transform: scale(var(--theme-scale));
            width: 100%;

            .image-wrapper {
                display: none;

                &::after {
                    border-radius: 10px;
                    content: '';
                    display: block;
                    height: calc(var(--scale) * 200px);
                    left: calc((var(--left) + (var(--scale) * 7)) * 1px);
                    pointer-events: none;
                    position: absolute;
                    top: calc((var(--top) + (var(--scale) * 31)) * 1px);
                    width: calc(var(--scale) * 220px);
                }

                &.valid::after {
                    border: 2px dashed #5cb85b;
                }

                &.invalid::after {
                    border: 2px dashed #e80061;
                }

                .shadow-wrapper {
                    background: url('/images/connects/post-wrapper.png');
                    box-shadow: 0 0 50px 20px #dedede;
                    display: block;
                    padding: 30px 6px 37px 6px;
                    position: absolute;
                    width: 232px;
                    height: 267px;
                    top: calc(var(--top) * 1px);
                    left: calc(var(--left) * 1px);
                    transform: scale(var(--scale));
                    transform-origin: 0 0;

                    .connect-image {
                        height: 100%;
                        width: 100%;

                        img {
                            height: 100%;
                            object-fit: cover;
                            width: 100%;
                        }
                    }
                }
            }
        }

        &.theme1 {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            width: 100%;
            position: relative;
            padding-left: 10px;
            padding-right: 10px;

            .images-wrapper {
                left: 10px;
                top: 30px;
                height: 140px;
                margin-bottom: 157px;
            }

            .header {
                text-align: left;
                position: relative;
            }
            .text {
                min-height: 109px;
                text-align: left;
                position: relative;
            }
            .next-button-container {
                display: flex;
                flex-direction: row;
                margin-top: 0px;
                margin-bottom: 31px;
                margin-left: 7rem;
                .learn-more {
                    display: flex;
                    align-items: baseline;
                    font-size: 14px;
                    color:#0066ff;
                    border: 2px solid  #0066ff;
                    border-radius: 4px;
                    padding: 3px 12px;
                    background: white;
                    margin-left: 120px;
                    position: fixed;
                    top: 81%;
                    .learn-more-btn {
                        font-family: Geomanist-Bold;
                        font-weight: bold;
                        padding: 1px 5px;
                        color:#0066ff;
                    }
                }
                button.btn-primary {
                    font-family: Geomanist-Bold;
                    font-weight: bold;
                    height: 35px;
                    position: fixed;
                    top: 81%;
                    border-radius: 4px;
                    background-color: #0066ff;
                }
            }
        }

        &.theme2 {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            width: 100%;
            position: relative;
            padding-left: 10px;
            padding-right: 10px;

            .images-wrapper {
                margin-top: 20px;
            }
            .header {
                text-align: left;
                position: relative;
            }
            .text {
                text-align: left;
                position: relative;
            }
            .next-button-container {
                justify-content: center;
                .learn-more {
                    display: flex;
                    align-items: baseline;
                    font-size: 14px;
                    color:#0066ff;
                    border: 2px solid  #0066ff;
                    border-radius: 4px;
                    padding: 3px 12px;
                    background: white;
                    margin-left: 20px;
                    top: 80%;
                    .learn-more-btn {
                        font-family: Geomanist-Bold;
                        font-weight: bold;
                        padding: 1px 5px;
                        color:#0066ff;
                    }
                }
            }
        }
    }
}
@media(max-height: 890px) and (min-width:1040px) {
    .welcome-page {
        width: 1234px;
        height: 120vh;
        max-width: 180vw;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: hidden;
    }
}
@media (max-width: 395px) {
    .welcome-page {
         &.theme1 {
            .next-button-container {
                display: flex;
                flex-direction: row;
                margin-top: 75%;
                position:fixed;
                margin-bottom: 31px;
                margin-left: 45px;
                .learn-more {
                    display: flex;
                    align-items: baseline;
                    font-size: 14px;
                    color:#0066ff;
                    border: 2px solid  #0066ff;
                    border-radius: 4px;
                    padding: 4px 12px;
                    background: white;
                    top: 81% !important;
                }
                button.btn-primary {
                    font-family: Geomanist-Bold;
                    font-weight: bold;
                    height: 35px;
                    top: 81% !important;
                    border-radius: 4px;
                    background-color: #0066ff;
                }
            }
        }
        &.theme2 {
            .next-button-container {
                display: flex;
                flex-direction: row;
                top: 80%;
                position:fixed;
                margin-bottom: 31px;
                margin-left: 45px;
                .learn-more {
                    display: flex;
                    align-items: baseline;
                    font-size: 14px;
                    color:#0066ff;
                    border: 2px solid  #0066ff;
                    border-radius: 4px;
                    padding: 4px 12px;
                    background: white;
                }
                button.btn-primary {
                    font-family: Geomanist-Bold;
                    font-weight: bold;
                    height: 35px;
                    top: 82% !important;
                    border-radius: 4px;
                    margin-right: 10px;
                    background-color: #0066ff;
                }
            }
        }
    }
}
@media (min-height: 840px)
{
    .welcome-page {
        &.theme1 {
            .next-button-container {
                .learn-more {
                    top: 86%!important;
                }
                button.btn-primary {
                    top: 86%!important;
                }
            }
        }
    }
}
@media (min-height: 930px)
{
    .welcome-page {
        &.theme1 {
            .next-button-container {
                .learn-more {
                    top: 88%!important;
                }
                button.btn-primary {
                    top: 88%!important;
                }
            }
        }
    }
}
</style>

import Cookies from 'js-cookie';
import $ from 'jquery';

window.flash = window.flash || {};
var flash = window.flash;

flash.flashing = false;
flash.queued_flashes = [];

window.flash.show = function (input) {
    if (flash.flashing === true) {
        flash.queued_flashes.push(input);
        return;
    }

    flash.flashing = true;
    var options;
    var defaults = {
        type:      'message',
        dismissed: false,
        text:      'Attention!',
        duration:  3,
    };

    if ($.type(input) === 'string') {
        options = {
            text: input,
        };
    } else {
        options = input;
    }

    var settings = $.extend({}, defaults, options);

    var $flash_message = $('.js-flash');

    $flash_message.find('.message').html(settings.text);

    if (settings.type === 'warning' || settings.type === 'error' || settings.type === 'success') {
        $flash_message.addClass(settings.type);
    }

    if (settings.dismissed) {
        $flash_message.find('.dismissal').css({
            display: 'inline-block',
        });
    }

    $flash_message.fadeIn(400);

    if (!settings.dismissed) {
        $flash_message.delay(settings.duration * 1000).fadeOut(400, function () {
            $flash_message.trigger('reset');
        });
    }
};

var buildTypeMethod = function(type) {
    return function(input) {
        if (typeof input == 'object') {
            input.type = type;
            flash.show(input);
        } else {
            flash.show({
                type: type,
                text: input,
            });
        }
    };
};

flash.error = buildTypeMethod('error');
flash.success = buildTypeMethod('success');
flash.warning = buildTypeMethod('warning');

flash.redirectAndShow = function (url, input, noCheck) {
    if (input) {
        Cookies.set('js-flash', JSON.stringify(input));
    }
    var redirected = app.redirect(url, noCheck);
    if (!redirected) {
        // redirect failed, just show the message now and clear cookie
        flash.show(input);
        Cookies.remove('js-flash');
    }
};

$(function () {
    $('.js-flash .dismissal').on('click', function () {
        $(this).closest('.js-flash').hide().trigger('reset');
    });

    var $flash = $('.js-flash');

    $flash.on('reset', function () {
        var $flash = $('.js-flash').removeClass('warning error success');
        $flash.find('.dismissal').css({
            display: 'none',
        });
        $flash.find('.message').html('Attention!');

        flash.flashing = false;

        if (0 < flash.queued_flashes.length) {
            $flash.trigger('next');
        }
    });

    $flash.on('next', function () {
        var next = flash.queued_flashes.shift();
        flash.show(next);
    });

    if (Cookies.get('js-flash')) {
        flash.show(JSON.parse(Cookies.get('js-flash')));
        Cookies.remove('js-flash');
    }
});

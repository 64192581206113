<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="world-blue-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.41613 14.7539C8.80675 14.915 8.16222 15 7.47374 15C6.838 15 6.19347 14.915 5.5841 14.7539C4.83702 13.752 4.25695 12.1846 3.96398 10.3125H0.545325C0.422571 10.0107 0.319153 9.6709 0.236389 9.375H3.84386C3.78234 8.77148 3.72374 8.11816 3.72374 7.5C3.72374 6.85547 3.78234 6.22852 3.84386 5.625H0.236389C0.319153 5.30273 0.422571 4.98926 0.545325 4.6875H3.96398C4.25695 2.8166 4.83702 1.24863 5.5841 0.247324C6.19347 0.085957 6.838 0 7.47374 0C7.28624 0 7.01378 0.0931055 6.68566 0.40459C6.3546 0.720996 6.01183 1.22285 5.70421 1.91455C5.3673 2.67715 5.09191 3.62109 4.9132 4.6875H10.087C9.90831 3.62109 9.63292 2.67715 9.29601 1.91455C8.98839 1.22285 8.64562 0.720996 8.31456 0.40459C7.98644 0.0931055 7.71398 0 7.47374 0C8.16222 0 8.80675 0.085957 9.41613 0.247324C10.1632 1.24863 10.7433 2.8166 11.0362 4.6875H14.4552C14.5782 4.98926 14.6808 5.30273 14.7628 5.625H11.1564C11.2179 6.22852 11.2501 6.85547 11.2501 7.5C11.2501 8.11816 11.2179 8.77148 11.1564 9.375H14.7628C14.6808 9.6709 14.5782 10.0107 14.4552 10.3125H11.0362C10.7433 12.1846 10.1632 13.752 9.41613 14.7539ZM4.9132 10.3125C5.09191 11.3789 5.3673 12.3223 5.70421 13.084C6.01183 13.7783 6.3546 14.2793 6.68566 14.5957C7.01378 14.9063 7.28624 15 7.47374 15C7.71398 15 7.98644 14.9063 8.31456 14.5957C8.64562 14.2793 8.98839 13.7783 9.29601 13.084C9.63292 12.3223 9.90831 11.3789 10.087 10.3125H4.9132ZM10.3126 7.5C10.3126 6.84961 10.2775 6.22266 10.2159 5.625H4.78429C4.72277 6.22266 4.66124 6.84961 4.66124 7.5C4.66124 8.15039 4.72277 8.77734 4.78429 9.375H10.2159C10.2775 8.77734 10.3126 8.15039 10.3126 7.5Z" fill="#0066FF" />
                <path opacity="0.4" d="M10.3125 7.5C10.3125 8.15039 10.2773 8.77734 10.2158 9.375H4.78418C4.72266 8.77734 4.66113 8.15039 4.66113 7.5C4.66113 6.84961 4.72266 6.22266 4.78418 5.625H10.2158C10.2773 6.22266 10.3125 6.84961 10.3125 7.5ZM14.7627 5.625C14.918 6.22559 15 6.85254 15 7.5C15 8.14746 14.918 8.77441 14.7627 9.375H11.1562C11.2178 8.77148 11.25 8.11816 11.25 7.5C11.25 6.85547 11.2178 6.22852 11.1562 5.625H14.7627ZM14.4551 4.6875H11.0361C10.7432 2.8166 10.1631 1.24863 9.41602 0.247324C11.7129 0.852246 13.5762 2.51777 14.4551 4.6875ZM10.0869 4.6875H4.91309C5.0918 3.62109 5.36719 2.67715 5.7041 1.91455C6.01172 1.22285 6.35449 0.720996 6.68555 0.40459C7.01367 0.0931055 7.28613 0 7.5 0C7.71387 0 7.98633 0.0931055 8.31445 0.40459C8.64551 0.720996 8.98828 1.22285 9.2959 1.91455C9.63281 2.67715 9.9082 3.62109 10.0869 4.6875ZM0.545215 4.6875C1.42354 2.51777 3.28711 0.852246 5.58398 0.247324C4.83691 1.24863 4.25684 2.8166 3.96387 4.6875H0.545215ZM3.84375 5.625C3.78223 6.22852 3.72363 6.85547 3.72363 7.5C3.72363 8.11816 3.78223 8.77148 3.84375 9.375H0.236279C0.0820312 8.77441 0 8.14746 0 7.5C0 6.85254 0.0820312 6.22559 0.236279 5.625H3.84375ZM5.7041 13.084C5.36719 12.3223 5.0918 11.3789 4.91309 10.3125H10.0869C9.9082 11.3789 9.63281 12.3223 9.2959 13.084C8.98828 13.7783 8.64551 14.2793 8.31445 14.5957C7.98633 14.9063 7.71387 15 7.47363 15C7.28613 15 7.01367 14.9063 6.68555 14.5957C6.35449 14.2793 6.01172 13.7783 5.7041 13.084ZM5.58398 14.7539C3.28711 14.1475 1.42354 12.4834 0.545215 10.3125H3.96387C4.25684 12.1846 4.83691 13.752 5.58398 14.7539ZM9.41602 14.7539C10.1631 13.752 10.7432 12.1846 11.0361 10.3125H14.4551C13.5762 12.4834 11.7129 14.1475 9.41602 14.7539Z" fill="#0066FF" />
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'WorldBlueIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};
</script>
<style lang='scss'>

</style>

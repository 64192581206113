<template>
    <div class="ts-loading-spinner">
        <div class="hero-animation" :style="spinner" />
        <span>{{ text }}</span>
    </div>
</template>
<script>
import lottie from 'lottie-web';
import animationData from '/resources/assets/lottie/loading/ts_loading.json';

export default {
    props: {
        size: {
            type:     Number,
            required: false,
            default:  3,
        },
        width: {
            type:     String,
            required: false,
            default:  '150',
        },
        text: {
            type:     String,
            required: false,
            default:  '',
        },
    },
    data: function() {
        return {
            spinner: {
                width:  this.width + 'px',
                margin: 'auto',
            },
        };
    },
    mounted() {
        lottie.loadAnimation({
            container: this.$el.getElementsByClassName('hero-animation')[0],
            renderer:  'svg',
            animationData,
        }).goToAndPlay(0, true);
    },
}
</script>

<style>
.ts-loading-spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
</style>

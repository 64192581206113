<template>
    <generic-icon
        :icon-style="iconStyle"
        :div-style="divStyle"
        :direction="direction"
        :title="title"
        class="checkmark-icon-container"
    >
        <template #content>
            <div :style="divStyle">
                <svg v-if="type === 'success'" :width="width" :height="height" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.4725 3.08455L7.2135 11.3436C6.85862 11.6985 6.4005 11.8727 5.93593 11.8727C5.47136 11.8727 5.01066 11.6972 4.65772 11.3443L0.528209 7.21484C-0.175744 6.50895 -0.175744 5.36365 0.528209 4.65777C1.2341 3.95188 2.37939 3.95188 3.08528 4.65777L5.93593 7.51087L12.9174 0.529416C13.6233 -0.176472 14.7686 -0.176472 15.4745 0.529416C16.1758 1.23272 16.1758 2.38124 15.4725 3.08455Z" fill="#5CB85B" />
                </svg>
                <svg v-else-if="type === 'warning'" :width="width" :height="height" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.4725 3.08455L7.2135 11.3436C6.85862 11.6985 6.4005 11.8727 5.93593 11.8727C5.47136 11.8727 5.01066 11.6972 4.65772 11.3443L0.528209 7.21484C-0.175744 6.50895 -0.175744 5.36365 0.528209 4.65777C1.2341 3.95188 2.37939 3.95188 3.08528 4.65777L5.93593 7.51087L12.9174 0.529416C13.6233 -0.176472 14.7686 -0.176472 15.4745 0.529416C16.1758 1.23272 16.1758 2.38124 15.4725 3.08455Z" fill="#FA960F" />
                </svg>
                <svg v-else-if="type === 'danger'" :width="width" :height="height" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.4725 3.08455L7.2135 11.3436C6.85862 11.6985 6.4005 11.8727 5.93593 11.8727C5.47136 11.8727 5.01066 11.6972 4.65772 11.3443L0.528209 7.21484C-0.175744 6.50895 -0.175744 5.36365 0.528209 4.65777C1.2341 3.95188 2.37939 3.95188 3.08528 4.65777L5.93593 7.51087L12.9174 0.529416C13.6233 -0.176472 14.7686 -0.176472 15.4745 0.529416C16.1758 1.23272 16.1758 2.38124 15.4725 3.08455Z" fill="#E80061" />
                </svg>
                <svg v-else-if="type === 'inactive'" :width="width" :height="height" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.4725 3.08455L7.2135 11.3436C6.85862 11.6985 6.4005 11.8727 5.93593 11.8727C5.47136 11.8727 5.01066 11.6972 4.65772 11.3443L0.528209 7.21484C-0.175744 6.50895 -0.175744 5.36365 0.528209 4.65777C1.2341 3.95188 2.37939 3.95188 3.08528 4.65777L5.93593 7.51087L12.9174 0.529416C13.6233 -0.176472 14.7686 -0.176472 15.4745 0.529416C16.1758 1.23272 16.1758 2.38124 15.4725 3.08455Z" fill="#ADB0B3" />
                </svg>
            </div>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';

export default {
    name:       'CheckmarkIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
    props:      {
        type: {
            type:     String,
            required: false,
            default:  'success',
        },
    },
};

</script>
<style lang='scss'>
.checkmark-icon-container{
}
</style>

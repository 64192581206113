<template>
    <settings-layout class="invoicing-summary">
        <template #title>Invoice History</template>
        <template #subtitle>{{ subHeaderText }}</template>
        <template #body>
            <div class="container">
                <div
                    class="row"
                    style="margin-top:20px;"
                >
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-12">
                                <dl v-if="currentTab !== 'Invoice' && hasPaymentProfile">
                                    <dt>{{ entity.name }}</dt>
                                    <dd>{{ paymentProfile.address }}</dd>
                                    <dd v-if="paymentProfile?.address_2">{{ paymentProfile.address_2 }}</dd>
                                    <dd>{{ cityStateZip }}</dd>
                                </dl>
                                <slot name="header" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="well well-sm">
                                    <div class="pull-right payment-profile">
                                        <calculator-finance-icon />
                                        <a
                                            href=""
                                            class="payment-profile-link"
                                            @click.stop.prevent="openPaymentProfile"
                                        ><span>Payment Profile</span></a>
                                        <modal
                                            v-if="showProfile"
                                            ref="profileModal"
                                            :title="entity.name + '\'s Payment Profile'"
                                            @close="clearProfile"
                                        >
                                            <template #body>
                                                <payment-profile
                                                    ref="profile"
                                                    #body
                                                    :payment-profile="paymentProfile"
                                                    :create="shouldCreateProfile"
                                                    @allow-save="allowSave"
                                                    @save-profile="saveProfile"
                                                />
                                            </template>

                                            <template v-if="!profileSaving" #footer>
                                                <div>
                                                    <a
                                                        href="javascript:void(0)"
                                                        :class="deleteCardClasses"
                                                        @click="deleteCard"
                                                    ><span v-if="isDeleting"><i class="fa fa-circle-o-notch fa-spin" /> </span>Delete Card</a>
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="btn btn-primary"
                                                        @click="setUpdateProfile"
                                                    >Update</a>
                                                </div>
                                            </template>
                                        </modal>
                                    </div>
                                    <div class="invoice-button-container">
                                        <ts-button
                                            v-for="tabName in tabNames"
                                            :key="tabName"
                                            size="md"
                                            style="display: inline-block; margin-right: 1em; padding: 0;"
                                            :override-theme="overrideTheme"
                                            :selected="tabName === selectedTab"
                                            :show-gradient-border="false"
                                            :show-gradient-text="false"
                                            @click.prevent="updateTab(tabName)"
                                        >
                                            <template #buttonName>
                                                <i v-if="tabName === 'Home'" class="fa fa-md fa-home" />
                                                {{ tabName }}
                                            </template>
                                        </ts-button>
                                    </div>
                                </div>
                                <div v-if="selectedTab === 'Home'">
                                    <invoicing-rollup
                                        :oem-manager-id="oemManagerId"
                                        :invoices="invoices"
                                        @pay-invoices="payInvoices"
                                        @change-tab="selectedTab = $event"
                                    />
                                </div>
                                <div v-if="selectedTab === 'Unpaid Invoices'">
                                    <invoicing-table
                                        :paid="false"
                                        :invoices="invoices"
                                        :payment-profile="paymentProfile"
                                        @openInvoice="openInvoice"
                                        @pay-invoices="payInvoices"
                                        @invoices-paid="markInvoicesPaid"
                                    />
                                </div>
                                <div v-if="selectedTab === 'Paid Invoices'">
                                    <invoicing-table
                                        :paid="true"
                                        :invoices="invoices"
                                        :remaining="invoicesToLoad"
                                        @openInvoice="openInvoice"
                                        @load-more-paid="loadRemainingPaidInvoices"
                                    />
                                </div>

                                <div v-if="selectedTab === 'Invoice'">
                                    <div>
                                        <invoice :invoice="invoiceToShow" :payment-profile="paymentProfile" />
                                        <div
                                            v-if="invoiceToShow && paymentProfile"
                                            class="pull-right"
                                        >
                                            <button
                                                v-if="!invoiceToShow.paid_at"
                                                class="btn btn-primary"
                                                @click="payInvoices([invoiceToShow])"
                                            >
                                                Pay
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <invoicing-payment-modal
                    v-if="displayModal"
                    ref="profile"
                    :invoices="toBePaidInvoices"
                    :payment-profile="paymentProfile"
                    @save-profile="handleSaveProfile"
                    @close-modal="closeModal"
                    @invoices-paid="markInvoicesPaid"
                    @allow-save="allowSave"
                    @pay-invoices="payInvoices"
                    @open-profile="openPaymentProfile"
                />
            </div>
            <div class="summary-container">
                <footer v-if="showFooter" class="footer text-center text-muted">
                    <div class="container">
                        <a
                            href="https://thumbstopper.com/"
                            target="_blank"
                        >ThumbStopper © {{ currentYear }}</a>
                        | 5001 W Lemon St | Tampa, FL 33609 | 813-337-7414 |
                        <a
                            href="https://s3.amazonaws.com/ts-lvlv/documents/thumbstopper_w9.pdf"
                            target="_blank"
                        >W9 Download</a>
                    </div>
                </footer>
            </div>
        </template>
    </settings-layout>
</template>
<script>
import axios from '@/axios';
import Modal from '@/components/app/Modal.vue';
import PaymentProfile from '@/components/app/PaymentProfile.vue';
import TsButton from '@/components/app/TsButton.vue';
import CalculatorFinanceIcon from '@/components/icons/CalculatorFinanceIcon.vue';
import Invoice from '@/components/invoicing/Invoice.vue';
import InvoicingPaymentModal from '@/components/invoicing/InvoicingPaymentModal.vue';
import InvoicingRollup from '@/components/invoicing/InvoicingRollup.vue';
import SettingsLayout from '@/layouts/SettingsLayout.vue';
import dayjs from 'dayjs';
import $ from 'jquery';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import InvoicingTable from "@/components/invoicing/InvoicingTable.vue";

export default {
    components: {
        InvoicingTable,
        CalculatorFinanceIcon,
        Invoice,
        InvoicingPaymentModal,
        InvoicingRollup,
        Modal,
        PaymentProfile,
        SettingsLayout,
        TsButton,
    },
    props: {
        entity: {
            type: Object,
            required: true,
        },
        isStripeCustomer: {
            type: Boolean,
            required: false,
            default: false,
        },
        cybersourceProfile: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        initialInvoiceId: {
            type: Number,
            required: false,
            default: null,
        },
        type: {
            type: String,
            required: true,
        },
        contextName: {
            type: String,
            required: false,
            default: '',
        },
        hideFooter: {
            type: Boolean,
            required: false,
            default: false,
        },
        oemManagerId: {
            type: Number,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            invoices:         [],
            displayModal:     false,
            toBePaidInvoices: [],
            selectedTab:      'Home',
            tabNames:         ['Home', 'Unpaid Invoices', 'Paid Invoices'],
            invoiceToShow:    null,
            showProfile:      false,
            updateProfile:    false,
            allowProfileSave: false,
            modalMessage:     '',
            paymentProfile:   null,
            invoicesToLoad:   undefined,
            isDeleting:       false,
            showFooter:       this.hideFooter === undefined ? true : !this.hideFooter,
            showSaveModal:    false,
            overrideTheme:    {
                'background':  'transparent',
                'border':      'unset',
                'color':       '#333',
                'line-height': '140%',
                'padding':     '10px', // This is the default padding for the button.
                '>span':       {
                    'background':              'transparent',
                    'color':                   '#000',
                    'font-weight':             400,
                    '-webkit-text-fill-color': '#000',
                },
                '&:hover': {
                    padding: '10px', // Needs to be +3px of the default padding.
                },
                '&.selected': {
                    padding: '10px', // Needs to be +3px of the default padding.
                },
            },
        };
    },
    computed: {
        ...mapGetters('NavigationStore', [
            'getCurrent',
        ]),
        hasPaymentProfile() {
            return this.paymentProfile && Object.keys(this.paymentProfile).length;
        },
        currentPage() {
            if (this.getCurrent && this.getCurrent.action && this.getCurrent.action.name) {

                return this.getCurrent.action.name;
            }
            if (this.pageTitle) {
                return this.pageTitle;
            }
            return '';
        },
        currentYear() {
            return dayjs().format('YYYY');
        },
        profileSaving() {
            return !this.paymentProfile || this.updateProfile;
        },
        currentTab() {
            return this.selectedTab;
        },
        subHeaderText() {
            return this.type === 'Dealership' ? 'Manage your invoices and view invoice history.' : 'Manage your brand\'s invoices and view invoice history.';
        },
        defaultTab() {
            return !this.selectedTab;
        },
        cityStateZip() {
            var array = [
                this.paymentProfile.city,
                this.paymentProfile.state,
            ];

            var string = array.filter(function (entry) {
                return entry && entry.trim() != '';
            }).join(', ');

            if (this.paymentProfile.zip) {
                string += ' ' + this.paymentProfile.zip;
            }

            return string;
        },
        deleteCardClasses() {
            return {
                'btn':        true,
                'btn-danger': true,
                'disabled':   this.isDeleting,
            };
        },
        shouldCreateProfile() {
            return this.updateProfile || !this.paymentProfile || !Object.keys(this.paymentProfile).length;
        },
        token() {
            if (window.Laravel.invoicingToken) {
                return window.Laravel.invoicingToken;
            } else {
                return this.entity.invoicing_token;
            }
        },
    },
    mounted() {
        this.loadProfile();
        this.loadInvoices();
    },
    methods: {
        updateTab(tabName) {
            this.selectedTab = tabName;
        },
        getCardNumber(brand, lastFour) {
            const brands = {
                'Visa': {
                    digits:    16,
                    firstFour: 4242,
                },
                'MasterCard': {
                    digits:    16,
                    firstFour: 5555,
                },
                'American Express': {
                    digits:    15,
                    firstFour: 3782,
                },
                'Discover': {
                    digits:    16,
                    firstFour: 6011,
                },
            };

            return `${brands[brand] ? brands[brand].firstFour : '0000'} ${brands[brand] ? '0'.repeat(brands[brand].digits - 8) : '0000 0000'} ${lastFour}`;
        },
        loadInvoices() {
            axios.get(
                '/invoicing/load/' + this.type.toLowerCase() + '/' + this.entity.id + '/unpaid?token=' + this.token,
            ).then(
                response => {
                    let invoices = response.data.invoices;
                    axios.get(
                        '/invoicing/load/' + this.type.toLowerCase() + '/' + this.entity.id + '/paid?token=' + this.token + '&limit=20',
                    ).then(
                        response => {
                            invoices = invoices.concat(response.data.invoices);
                            this.invoicesToLoad = response.data.remaining;
                        },
                        response => {
                            console.log(response);
                            window.flash.show({
                                text: 'Invoices only partially loaded.',
                                type: 'warning',
                            });
                        },
                    ).then(() => {
                        this.invoices = invoices;

                        if (this.initialInvoiceId) {
                            this.invoiceToShow = _.find(this.invoices, invoice => invoice.id == this.initialInvoiceId);
                            if (this.invoiceToShow) {
                                this.selectedTab = 'Invoice';
                            } else {
                                axios.get(
                                    '/invoicing/load/' + this.type.toLowerCase() + '/' + this.entity.id + '/?token='
                                    + this.token + '&invoice=' + this.initialInvoiceId,
                                ).then(
                                    response => {
                                        if (response.data.invoices.length) {
                                            this.invoices = this.invoices.concat(response.data.invoices);
                                            this.invoiceToShow = response.data.invoices[0];
                                            this.invoicesToLoad--;
                                            this.selectedTab = 'Invoice';
                                        } else {
                                            window.flash.show({
                                                text: 'Requested Invoice could not be found.',
                                                type: 'warning',
                                            });
                                        }
                                    },
                                    response => {
                                        console.log('Error loading invoices', response);
                                    },
                                );
                            }
                        }
                    });
                },
                response => {
                    console.log(response);
                    window.flash.show({
                        text: 'Failed to load invoices. Please try again.',
                        type: 'error',
                    });
                },
            );
        },
        loadRemainingPaidInvoices(exclude) {
            return axios.get(
                '/invoicing/load/' + this.type.toLowerCase() + '/' + this.entity.id + '/paid?limit=10&token='
                + this.token + '&exclude=' + exclude,
            ).then(
                response => {
                    this.invoices = this.invoices.concat(response.data.invoices);
                    this.invoicesToLoad = response.data.remaining;
                },
            ).catch(console.error);
        },
        allowSave(checked) {
            this.allowProfileSave = checked;
        },
        setUpdateProfile() {
            this.updateProfile = true;
        },
        deleteCard() {
            let url = '/spicyapi/paymentprofile/' + this.type.toLowerCase() + '/' + this.entity.id + '/delete';
            url += '?token=' + this.token;
            this.isDeleting = true;
            axios.post(url, [])
                .then(() => {
                    this.loadProfile();
                    window.flash.show({
                        text: 'Card succesfully deleted',
                        type: 'success',
                    });
                })
                .catch(error => {
                    console.error(error);
                    window.flash.show({
                        text: 'Unable to delete card.',
                        type: 'error',
                    });
                })
                .finally(() => {
                    this.isDeleting = false;
                });
        },
        openPaymentProfile() {
            this.showProfile = true;
            this.updateProfile = false;
        },
        openInvoice(invoice) {
            window.history.pushState({ tab: 'Invoice' }, '');
            this.selectedTab = 'Invoice';
            this.invoiceToShow = invoice;
        },
        payInvoices(invoices) {
            this.toBePaidInvoices = invoices;
            this.displayModal = true;
        },
        closeModal() {
            this.clearProfile();
            this.displayModal = false;
        },
        clearProfile() {
            this.showProfile = false;
        },
        markInvoicesPaid(invoices) {
            invoices.forEach(invoice => {
                invoice.paid_at = dayjs().format('YYYY-MM-DD HH:mm:ss');
                // This adds a mocked payment note until they page refreshes with real one.
                invoice.payments.push({
                    amount:      invoice.total_amount_due,
                    created_at:  dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    card:        this.paymentProfile.card,
                    card_suffix: this.paymentProfile.lastFour,
                });
            });
        },
        loadProfile() {
            if (this.isStripeCustomer) {
                let url = '/spicyapi/paymentprofile/' + this.type.toLowerCase() + '/' + this.entity.id;
                url += '?token=' + this.token;
                axios.get(url).then(response => {
                    if (response.data.lastFour) {
                        this.paymentProfile = {
                            firstName:           response.data.firstName,
                            lastName:            response.data.lastName,
                            cardName:            response.data.cardName,
                            lastFour:            response.data.lastFour,
                            cardExpirationMonth: response.data.expirationMonth,
                            cardExpirationYear:  response.data.expirationYear,
                            email:               response.data.email,
                            address:             response.data.address,
                            address_2:           response.data.address_2,
                            city:                response.data.city,
                            state:               response.data.state,
                            zip:                 response.data.zip,
                            expiry:              `${response.data.expirationMonth}/${response.data.expirationYear}`,
                            cardAccountNumber:   this.getCardNumber(response.data.cardName, response.data.lastFour),
                        };
                    } else {
                        this.paymentProfile = null;
                    }
                }).catch(error => {
                    console.log(error);
                });
            } else {
                this.paymentProfile = this.cybersourceProfile;
            }
        },
        saveProfile() {
            let modal = this.$refs.profileModal;
            let $modal = $(modal.$el);
            // Lock down the form while saving.
            $modal.find(':input').prop('disabled', true);
            this.handleSaveProfile().then((response) => {
                if (response.response) {
                    this.clearProfile();
                    this.openPaymentProfile();
                    this.updateProfile = false;
                    $modal.find(':input').prop('disabled', false);
                    window.flash.show({
                        text: 'Payment profile successfully updated',
                        type: 'success',
                    });
                } else {
                    this.modalMessage = (response.error) || 'Could not save';
                    $modal.find(':input').prop('disabled', false);
                    this.allowSave(true);
                    window.flash.show({
                        text: `Failed to update profile, ${response.error}`,
                        type: 'warning',
                    });
                }
            });
        },
        handleSaveProfile(callback = () => { }) {
            if (this.allowProfileSave) {
                this.modalMessage = null;
                let profileComponent = this.$refs.profile;
                let url = '/spicyapi/paymentprofile/' + this.type.toLowerCase() + '/' + this.entity.id;
                if (this.updateProfile) {
                    url += '/update';
                }
                url += '?token=' + this.token;
                this.allowSave(false);
                // Post the data.
                return axios.post(url, {
                    cardType:            profileComponent.profile.cardType,
                    firstName:           profileComponent.profile.firstName,
                    lastName:            profileComponent.profile.lastName,
                    cardExpirationMonth: profileComponent.profile.cardExpirationMonth,
                    cardExpirationYear:  profileComponent.profile.cardExpirationYear,
                    cardAccountNumber:   profileComponent.profile.cardAccountNumber,
                    email:               profileComponent.profile.email,
                    address:             profileComponent.profile.address,
                    address_2:           profileComponent.profile.address_2,
                    city:                profileComponent.profile.city,
                    state:               profileComponent.profile.state,
                    zip:                 profileComponent.profile.zip,
                    type:                this.type,
                    id:                  this.entity.id,
                    stripeToken:         profileComponent.profile.stripeToken.id,
                }).then(response => {
                    this.paymentProfile = {
                        firstName:           profileComponent.profile.firstName,
                        lastName:            profileComponent.profile.lastName,
                        cardName:            response.data.cardName,
                        lastFour:            response.data.lastFour,
                        cardExpirationMonth: response.data.expirationMonth,
                        cardExpirationYear:  response.data.expirationYear,
                        cardAccountNumber:   this.getCardNumber(response.data.cardName, response.data.lastFour),
                        email:               profileComponent.profile.email,
                        address:             profileComponent.profile.address,
                        address_2:           profileComponent.profile.address_2,
                        city:                profileComponent.profile.city,
                        state:               profileComponent.profile.state,
                        zip:                 profileComponent.profile.zip,
                    };
                    callback(true);
                    return { response: true };
                }).catch(error => {
                    callback(false);
                    return { response: false, error };
                });
            }
        },
    },
};
</script>

<style lang="scss">
.invoicing-summary {
    .no-background {
        background: none !important;
    }

    .account-name h3 {
    margin-top: 35px;
    font-size: 18px;
    }

    .well a:hover {
    text-decoration: none;
    }

    .well-sm {
    padding: 0 !important;

        .invoice-button-container {
            margin-left: 10px;
        }

        .payment-profile {
            margin-top: 11px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .calculator-icon {
            margin-top: 10px !important;
            margin-right: 4px;
        }
    }

    .well {
    padding: 0 !important;

    }

    .invoice-button-container .btn-gradient-container .btn-gradient-inner-container {
        padding: 8px;
    }

    .summary-container .container {
    margin-bottom: 100px;
    }

    .summary-container .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #f5f5f5;
    border-top: 1px solid #eee;
    box-shadow: 0px -10px 20px #fff;
    }

    .summary-container .footer .container {
    width: auto;
    max-width: 680px;
    padding: 20px 15px;
    }
    .payment-profile-link{
    text-decoration: none;
    color:black;
    margin-right: 15px;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    }
    a.undecorated:hover {
    text-decoration: none;
    color:black;
    }

    .account-name h3 {
    margin-top: 35px;
    font-size: 18px;
    }

    .well a:hover {
    text-decoration: none;
    }

    .summary-container .container {
    margin-bottom: 100px;
    }

    .summary-container .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #f5f5f5;
    border-top: 1px solid #eee;
    box-shadow: 0px -10px 20px #fff;
    }

    .summary-container .footer .container {
    width: auto;
    max-width: 680px;
    padding: 20px 15px;
    }
}
</style>

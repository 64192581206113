export default {
    name: 'Linkedin',
    char() {
        return {
            limit:                63206,
            link:                 true,
            emoji:                true,
            hashtag:              true,
            mention:              false,
            brandRequiresDynamic: false,
        };
    },
    media() {
        let limits = {
            photo: {
                resolution: {
                    min: {
                        width:  400,
                        height: 400,
                    },
                    max: {
                        width:  7360,
                        height: 4912,
                    },
                },
                limit:   5,
                //min:     1,
                size:    20 * 1024 * 1024, // 20mb
                formats: [
                    'jpeg',
                    'png',
                    'gif',
                    'jpg',
                ],
                messages: {
                    limit: 'Linkedin only allows for five images per post.',
                },
            },
            video: {
                size:     5 * 1024 * 1024 * 1024, // 5gb
                duration: 30 * 60, // 30 min
                formats:  [
                    'mp4',
                    'mov',
                    'avi',
                    'flv',
                    'mpeg-1',
                    'mpeg-4',
                    'mkv',
                    'webm',
                    'vp8',
                    'vp9',
                ],
                limit:    1,
                messages: {
                    limit: 'Linkedin automation is limited to one video per post.',
                },
            },
        };
        return Object.assign(
            limits,
            {
                mixed: {
                    enabled: false,
                },
            },
        );
    },
    misc: {
        url: {
            type:        'text',
            icon:        'fa-link',
            placeholder: 'URL',
        },
    },
};

<template>
    <div class="business-name-page">
        <connect-textarea
            class="header"
            name="business-name-header"
            :limit="50"
            :content="connectStore.businessNamePage.header"
            :focusable="!edit"
            :edit="edit"
            @update:content="updateHeader"
            @commit="commitHeader"
        />
        <input
            v-model="liveConnectStore.retailerName" type="text" placeholder="Type your answer here" class="form-control business-name live-only" required
        >
        <label class="label business-name-label" />
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { connectStore, liveConnectStore } from '@/stores/pinia.js';
import ConnectTextarea from '@/components/connects/ConnectTextarea.vue';

export default {
    components: {
        ConnectTextarea,
    },
    props: {
        edit: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    computed: {
        formValid() {
            return !!this.liveConnectStore.retailerName;
        },
        ...mapStores(connectStore, liveConnectStore),
    },
    methods: {
        commitHeader(value) {
            this.connectStore.$patch({ businessNamePage: { header: value }});
        },
        updateHeader(value) {
            this.connectStore.businessNamePage.header = value;
        },
    },
};
</script>

<style lang="scss">
@media(min-width: 950px) {
    .business-name-page {
        position: relative;
        top: 150px;
        height: 60vh;

        .header {
            font-family: Geomanist;
            font-size: 36px;
            font-weight: bold;
            height: 111px;
            overflow: hidden;
            padding-top: 25px;
            width: 918px;
            text-align: center;

            textarea {
                text-align: center;
            }
        }

        .business-name {
            left: 165px;
            position: relative;
            top: 15px;
            width: 587px;
        }

        .business-name-label {
            display: block;
            font-size: 14px;
            left: 165px;
            position: relative;
            text-transform: uppercase;
            top: -40px;
            width: 587px;
        }

    }
}

//inbetween phones and desktop (eg. tablets)
@media (max-width: 950px) and (min-width: 425px) {
    .business-name-page {
        .header {
            top: 60px !important;
        }
    }
}

@media(max-width: 950px) {
    .business-name-page {
        display: flex;
        flex-direction: column;
        min-width: 300px;

        .header {
            font-family: Geomanist;
            font-size: 20px;
            font-weight: bold;
            height: 111px;
            padding-top: 25px;
            position: relative !important;
            top: 40px;
            text-align: center;
            margin-bottom: 25px !important;

            textarea {
                text-align: center;
            }
        }
        .business-name {
            //left: 165px;
            //position: relative;
            //top: 15px;
            //width: 587px;
        }

        .business-name-label {
            display: block;
            font-size: 14px;
            text-transform: uppercase;
        }

    }
}
</style>



<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="retailers-menu-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.0789 0.333386L14.6589 2.63561C15.4779 3.82645 14.7527 5.47942 13.2223 5.66731C13.1147 5.68001 13.0044 5.68763 12.8886 5.68763C12.1716 5.68763 11.5319 5.39817 11.0934 4.95128C10.655 5.39817 10.0152 5.68763 9.29552 5.68763C8.5758 5.68763 7.93605 5.39817 7.4976 4.95128C7.05915 5.39817 6.42216 5.68763 5.69968 5.68763C4.98272 5.68763 4.34297 5.39817 3.90452 4.95128C3.46607 5.39817 2.82632 5.68763 2.1055 5.68763C1.99354 5.68763 1.88076 5.68001 1.77128 5.66731C0.245807 5.47942 -0.47598 3.82391 0.33984 2.63561L1.92074 0.333386C2.06137 0.126423 2.31259 0 2.5809 0H12.4198C12.6873 0 12.9382 0.126397 13.0789 0.333386Z" :fill="fillColorTop"/>
                <path d="M1.77079 5.66719C1.88054 5.67989 1.99333 5.6875 2.10528 5.6875C2.45659 5.6875 2.79026 5.61895 3.08807 5.49707V9.75009H11.9122V5.49707C12.21 5.61895 12.5409 5.6875 12.8884 5.6875C13.0042 5.6875 13.1145 5.67989 13.222 5.66719C13.3847 5.64688 13.5364 5.61133 13.677 5.56309V11.3751C13.677 12.2714 12.8856 13.0002 11.9122 13.0002H3.08807C2.11328 13.0002 1.32324 12.2714 1.32324 11.3751V5.56309C1.46333 5.61133 1.61278 5.64688 1.77107 5.66719H1.77079Z" :fill="fillColorBottom"/>
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'RetailersMenuIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
    props:      {
        fillColorTop: {
            type:     String,
            required: false,
            default:  '#85878F',
        },
        fillColorBottom: {
            type:     String,
            required: false,
            default:  '#DEE0E2',
        },
    },
};

</script>
<style lang='scss'>
.retailers-menu-icon-container {}
</style>

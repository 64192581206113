export default {
    name: 'X',
    char() {
        return {
            limit:                280,
            link:                 true,
            emoji:                true,
            hashtag:              true,
            mention:              false,
            brandRequiresDynamic: true,
        };
    },
    media(vm = null) {
        return {
            photo: {
                resolution: {
                    min: {
                        width:  400,
                        height: 400,
                    },
                },
                limit:   4,
                min:     vm && vm.postType==='TEXT' ? 0 : 1,
                size:    5 * 1024 * 1024, // 5 mb
                formats: [
                    'jpeg',
                    'png',
                    'webp',
                    'jpg',
                ],
                messages: {
                    limit: 'X only allows for four photo per post.',
                },
            },
            video: {
                limit:    0,
                min:      vm && vm.postType === 'VIDEO' ? 1 : 0,
                messages: {
                    limit: 'Video content is not supported, please include an photo.',
                    min:   'X should have at least 1 video per post.',
                },
            },
            mixed: {
                enabled: false,
            },
        };
    },
    misc: {
        /* Figure out personalization token message here, maybe? */
    },
};

<template>
    <div>
        <input type="hidden" name="region_id" :value="selectedRegionId">
        <div v-if="pool.has_regions_enabled" class="dropdown col-xs-12 col-sm-3" style="min-width: 200px">
            <dealership-region-select-box
                :regions="regions"
                :region-level="0"
                :pool="pool"
                :path-to-initial-region="pathToInitialRegion"
                @select-region="selectRegion"
            />
        </div>
        <p v-else class="text-center">Regions are disabled.</p>
    </div>
</template>

<script>
import DealershipRegionSelectBox from '@/components/dealerships/DealershipRegionSelectBox.vue';

export default {
    components: {
        DealershipRegionSelectBox,
    },
    props: {
        dealershipId: {
            type: Number,
            required: true,
        },
        pool: {
            type: Object,
            required: true,
        },
        regionId: {
            type: Number,
            required: false,
        },
        modelValue: {
            type: Number,
            required: true,
        },
        userRegions: {
            type: Array,
            required: true,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            pathToInitialRegion: false,
            regions:             [],
            selectedRegionId:    null,
        };
    },
    created() {
        this.selectedRegionId = this.regionId || this.modelValue;
        this.setupRegions();
    },
    methods: {
        createRegionList(rawRegions, parentId = null) {
            let regions = [];
            rawRegions.filter((rawRegion) => rawRegion.parent_id === parentId).forEach((rawRegion) => {
                let region = {
                    id:       rawRegion.id,
                    name:     rawRegion.name,
                    enabled:  this.userRegions.length === 0 || this.userRegions.includes(rawRegion.id),
                    children: this.createRegionList(rawRegions, rawRegion.id),
                };

                region.enabled = region.enabled || region.children.some(r => r.enabled);
                regions.push(region);
            });
            return regions;
        },
        findPath(regions, id) {
            if (id === null) {
                return [];
            }
            for (let i = 0; i < regions.length; i++) {
                if (regions[i].id == id) {
                    return [i];
                }
                if (regions[i].children.length) {
                    let pathInChildren = this.findPath(regions[i].children, id);
                    if (pathInChildren !== false) {
                        return [i].concat(pathInChildren);
                    }
                }
            }
            return false;
        },
        setupRegions() {
            this.regions = this.createRegionList(this.pool.regions);

            if (this.modelValue) {
                this.pathToInitialRegion = this.findPath(this.regions, this.modelValue);
            }
        },
        selectRegion(region) {
            this.selectedRegionId = region;
            this.pathToInitialRegion = false;

            this.$emit('update:modelValue', this.selectedRegionId);
        },
    },
};
</script>

import Vuex from 'vuex';
import MultiSelectStoryStore from './modules/MultiSelectStoryStore/MultiSelectStoryStore';
import ResellerSettingsStore from './modules/ResellerSettingsStore/ResellerSettingsStore';
import RetailerSettingsStore from './modules/RetailerSettingsStore/RetailerSettingsStore';
import NavigationStore from './modules/NavigationStore';
import NavigatorStore from './modules/NavigatorStore';
import tiers from './modules/tiers';
import RetailerEditStore from './modules/RetailerEditStore';
import StoryStore from './modules/StoryStore';

export default {
    modules: {
        MultiSelectStoryStore,
        NavigationStore,
        NavigatorStore,
        tiers,
        RetailerEditStore,
        ResellerSettingsStore,
        RetailerSettingsStore,
        StoryStore,
    },
};

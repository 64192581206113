import _ from 'lodash';

export default function({ options, store }) {
    let subscribeTypes = {
        direct:   false,
        object:   false,
        function: false,
    };

    if (options.history === true) {
        subscribeTypes.direct = true;
        subscribeTypes.object = true;
        subscribeTypes.function = true;

    } else if (options.history == 'direct') {
        subscribeTypes.direct = true;

    } else if (options.history == 'patch') {
        subscribeTypes.object = true;
        subscribeTypes.function = true;

    } else if (options.history == 'object') {
        subscribeTypes.object = true;

    } else if (options.history == 'function') {
        subscribeTypes.function = true;
    }

    let logState = _.cloneDeep(store.$state);

    if (subscribeTypes.direct || subscribeTypes.object || subscribeTypes.function) {
        if (!Object.prototype.hasOwnProperty.call(store, '$history')) {
            store.$history = [];
        }
        if (!Object.prototype.hasOwnProperty.call(store, '$logHistory')) {
            store.$logHistory = true;
        }
        if (!Object.prototype.hasOwnProperty.call(store, '$undo')) {
            store.$undo = () => {
                let oldLogHistory = store.$logHistory;
                store.$logHistory = false;
                store.$patch(store.$history.pop());
                store.$logHistory = oldLogHistory;
            };
        }
        if (!Object.prototype.hasOwnProperty.call(store, '$clearHistory')) {
            store.$clearHistory = (reset = false) => {
                if (reset) {
                    let oldLogHistory = store.$logHistory;
                    store.$logHistory = false;
                    store.$patch(store.$history[0]);
                    store.$logHistory = oldLogHistory;
                }
                store.$history = [];
                logState = _.cloneDeep(store.$state);
            };
        }
    }

    store.$subscribe((mutation, state) => {
        if (!store.$logHistory) {
            // History logging disabled
            return;
        }

        if (_.isEqual(state, logState)) {
            // Nothing changed, so no history
            return;
        }

        if (mutation.type == 'direct' && subscribeTypes.direct) {
            store.$history.push(logState);
        } else if (mutation.type == 'patch object' && subscribeTypes.object) {
            store.$history.push(logState);
        } else if (mutation.type == 'patch function' && subscribeTypes.function) {
            store.$history.push(logState);
        } else {
            // Don't update logState unless we're committing
            return;
        }

        logState = _.cloneDeep(store.$state);
    });
}

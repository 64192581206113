import actions from '@/stores/tinymce/actions.js';
import getters from '@/stores/tinymce/getters.js';
import state from '@/stores/tinymce/state.js';
import { defineStore } from 'pinia';

export default defineStore({
    id: 'tinymce',
    state,
    getters,
    actions,
});

export function tinymceInstance(id) {
    return defineStore({
        id: `tinymce-${id}`,
        state,
        getters,
        actions,
    });
}
<template>
    <export-modal
        :sidebar-filters="sidebarFilters"
        :body-filters="bodyFilters"
        :current-step="currentStep"
        :previous-button-title="currentStep === 3 ? 'Back to selection' : ''"
        :next-step-button-disabled="nextStepButtonDisabled"
        :show-arrow-pointer="true"
        :show-preview="showPreview"
        :next-step-button-title="currentStep === 3 ? 'Export' : 'Next'"
        :is-loading="isLoading"
        :selected-filters="selectedFilters"
        :preview-data="previewData"
        :welcome-preview="welcomePreview"
        :user-radio="userRadio"
        :exporting="exporting"
        :title="showPreview ? 'Export Preview' : 'Export Creator'"
        :selected-route="selectedRoute"
        export-type="Content Data"
        @close="close"
        @next-step="handleNextStep()"
        @update:selected-filter="updateSelectedFilters($event)"
        @previous-step="handlePreviusStep()"
        @from-preview="handlePreviusStep('from-preview')"
        @handle-select-deselect-all="handleSelectDeselectAll($event)"
    >
        <template #starter>
            <div class="date-range-component-container">
                <ts-date-range
                    :highlight-selected-quick-date="true"
                    :ignore-selected-date-range-watch="true"
                    :placeholder="'Select Date Range'"
                    :quick-future-dates="quickFutureDates"
                    :range="range"
                    :selected-quick-date-id="selectedQuickDateId"
                    :show-adjust-date="false"
                    :show-calendar-icon="false"
                    :show-label="false"
                    :single-input="true"
                    :columns="2"
                    :is-range="true"
                    label="Select Date Range"
                    @update:range="(event) => $emit('update:range', event)"
                    @update:selected-quick-date="(event) => $emit('update:selected-quick-date',event)"
                />
            </div>
            <brand-manager-filter-dropdown
                v-if="!isLoading && hasRoleAccess"
                :select-options="oemManagerOptions"
                :selected-brands="selectedBrands"
                :combo-box="selectedBrands.length > 1 || selectedBrands.length === oemManagerOptions.length ? 'Brands' : 'Brand'"
                :label="selectedBrands.length > 1 ? 'Brands' : 'Brand'"
                @selected-brands="handleSelectedBrands($event)"
            />
        </template>
        <template #body />
    </export-modal>
</template>

<script>
import _ from 'lodash';
import axios from '@/axios';
import dayjs from 'dayjs';
import ExportModal from '@/components/app/exports/ExportModal.vue';
import BrandManagerFilterDropdown from '@/components/app/BrandManagerFilterDropdown.vue';
import { mapStores } from 'pinia';
import { userStore } from '@/stores/pinia.js';
import TsDateRange from '@/components/app/TsDateRange.vue';

export default {
    components: {
        TsDateRange,
        ExportModal,
        BrandManagerFilterDropdown,
    },
    props: {
        oemManagerOptions: {
            type:    Array,
            default: () => [],
        },
        filters: {
            type:    Object,
            default: () => {},
        },
        range: {
            type:     Object,
            required: true,
        },
        quickFutureDates: {
            type:     Array,
            required: true,
        },
        selectedQuickDateId: {
            type:     Number,
            required: true,
        },
        brands: {
            type:     Array,
            required: true,
            default:  () => [],
        },
        selectedRoute: {
            type:     Number,
            required: false,
            default:  0,
        },
        oemManagerId: {
            type:     [String, Number],
            default:  '',
            required: true,
        },
        userRole: {
            type:     String,
            required: false,
            default:  '',
        },
        dealershipId: {
            type:     Number,
            required: false,
            default:  0,
        },
        sidebarCall: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        welcomePreview: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    data() {
        return {
            isLoading:         true,
            sidebarFilters:    {},
            bodyFilters:       [],
            selectedBrands:    this.brands,
            showPreview:       false,
            selectedFilters:   {},
            previewData:       [[], []],
            queryString:       '',
            userRadio:         'Default User',
            exporting:         false,
            defaultFieldsFlag: false,
        };
    },
    computed: {
        ...mapStores(userStore),
        currentStep() {
            if (this.showPreview) {
                return 3;
            }
            if (this.selectedBrands.length || this.userRole === 'brand' || this.userRole === 'retailer') {
                return 2;
            }
            return 1;
        },
        nextStepButtonDisabled() {
            return this.currentStep === 1;
        },
        hasRoleAccess() {
            return (this.userStore.isAdmin || this.userStore.isBrand || this.userStore.isParentBrand) ?? false;
        },
    },
    mounted() {
        this.isLoading = true;
        if (this.welcomePreview) {
            this.handleSelectDeselectAll({value: 'select'});
            this.showPreview = true;
        }

        this.setSidebarFilters();
        this.setBodyFilters();
        this.setInitialSelectedFilters();
        if (!this.welcomePreview) {
            this.setPreviewData();
        }
        this.isLoading = false;
    },
    methods: {
        close() {
            this.$emit('close');
        },
        setSidebarFilters() {
            this.sidebarFilters = {
                business_info: this.filters.business_info,
            };
        },
        handleSelectedBrands(event) {
            if (event.value === 'all') {
                if (this.selectedBrands.length === this.oemManagerOptions.length) {
                    this.selectedBrands = [];
                } else {
                    this.selectedBrands = this.oemManagerOptions.map(option => option.id);
                }
            } else {
                this.selectedBrands = event.value;
            }
        },
        setBodyFilters() {
            this.bodyFilters = [
                {
                    type: 'column',
                    data: {
                        platforms: this.filters.platforms,
                    },
                },
                {
                    type: 'row',
                    data: {
                        all_posts_metrics: this.filters.all_posts_metrics,
                    },
                },
            ];
        },
        setInitialSelectedFilters() {
            for (const [key, value] of Object.entries(this.filters)) {
                if (key === 'platforms') {
                    this.selectedFilters.platforms = {filters: {}};
                    value.options.forEach(option => {
                        this.selectedFilters.platforms.filters[option.name.toLowerCase()] = {filters: [], title: option.name};
                    });
                } else {
                    this.selectedFilters[key] = {title: value.title, filters: []};
                }
                for (const [key1, value1] of Object.entries(this.filters[key].options)) {
                    if (value1.disabled) {
                        this.selectedFilters[key].filters.push({title: value1.title, id: key1});
                    }
                }
            }
        },
        updateSelectedFilters(filterInfo) {
            if (filterInfo.filterType === 'radio') {
                this.userRadio = filterInfo.data.filter.title;
            } else if (filterInfo.filterType === 'column') {
                if (!this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()]) {
                    this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()] = {title: filterInfo.data.type.title, filters: []};
                }
                const filterIsSelected = this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.findIndex(f => f.id === filterInfo.data.filter.id);
                if (filterIsSelected >= 0) {
                    this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.splice(filterIsSelected, 1);
                    if (this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.length === 3) {
                        this.defaultFieldsFlag = true;
                        this.clearFiltersByType(filterInfo);
                    }
                } else {
                    this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.push(filterInfo.data.filter);
                    if (this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.length === 1 && !this.defaultFieldsFlag) {
                        this.addDefaultFields(filterInfo);
                    }
                }
            } else {
                const filterIsSelected = this.selectedFilters[filterInfo.data.type.id].filters.findIndex(f => f.id === filterInfo.data.filter.id);
                if (filterIsSelected >= 0) {
                    this.selectedFilters[filterInfo.data.type.id].filters.splice(filterIsSelected, 1);
                    if (this.selectedFilters[filterInfo.data.type.id].filters.length === 3) {
                        this.defaultFieldsFlag = true;
                        this.clearFiltersByType(filterInfo);
                    }
                } else {
                    this.selectedFilters[filterInfo.data.type.id].filters.push(filterInfo.data.filter);
                    if (this.selectedFilters[filterInfo.data.type.id].filters.length === 1 && !this.defaultFieldsFlag) {
                        this.addDefaultFields(filterInfo);
                    }
                }
            }
            this.setPreviewData();
        },
        addDefaultFields(filterInfo) {
            const defaultSelected = ['caption', 'media_type', 'preview_link'];
            for (const [key, value] of Object.entries(this.filters)) {
                if (key === 'platforms' && filterInfo.filterType === 'column') {
                    this.filters[key].options.forEach((platform) => {
                        for (const [key1, value1] of Object.entries(platform.options)) {
                            for (const [key2, value2] of Object.entries(value1)) {
                                if (defaultSelected.find(key => key === key2) && platform.name === filterInfo.data.type.title) {
                                    this.selectedFilters[key].filters[filterInfo.data.type.title.toLowerCase()].filters.push({ id: key2, title: value2.title, map: value2.map ? value2.map : '', hide: value2.hide, sort_id: value2.sort_id });
                                }
                            }
                        }
                    });
                } else if (filterInfo.data.type.id === 'all_posts_metrics' && filterInfo.data.filterType === 'row') {
                    for (const [key1, value1] of Object.entries(this.filters[key].options)) {
                        if (defaultSelected.find(key => key === key1)) {
                            this.selectedFilters[key].filters.push({ id: key1, title: value1.title, map: value1.map ? value1.map : '', hide: value1.hide, sort_id: value1.sort_id});
                        }
                    }
                }
            }
        },
        clearFiltersByType(filterInfo) {
            const defaultSelected = ['caption', 'media_type', 'preview_link'];
            for (const [key, value] of Object.entries(this.filters)) {
                if (key === 'platforms' && filterInfo.filterType === 'column') {
                    this.filters[key].options.forEach((platform) => {
                        for (const [key1, value1] of Object.entries(platform.options)) {
                            for (const [key2, value2] of Object.entries(value1)) {
                                if (defaultSelected.find(key => key === key2) && platform.name === filterInfo.data.type.title) {
                                    this.selectedFilters.platforms.filters[platform.name.toLowerCase()] = {filters: [], title: platform.name};
                                }
                            }
                        }
                    });
                } else if (key === 'all_posts_metrics' && filterInfo.data.filterType === 'row') {
                    this.selectedFilters[key] = {title: value.title, filters: []};
                }
            }
            this.defaultFieldsFlag = false;
        },
        setPreviewData() {
            let data = [{}, {}, {}];
            for (const key of Object.keys(this.selectedFilters)) {
                if (key === 'platforms') {
                    for (const k of Object.keys(this.selectedFilters[key].filters)) {
                        data[1][k] = (this.selectedFilters[key].filters[k]);
                        data[1][k].filters.sort((a, b) => a.sort_id - b.sort_id);
                    }
                } else {
                    data[0][key] = (this.selectedFilters[key]);
                }
            }
            data[0].all_posts_metrics.filters.sort((a, b) => a.sort_id - b.sort_id);
            this.previewData = data;
        },
        handleNextStep() {
            let start = dayjs(this.range.start).format('YYYY-MM-DD');
            let end = dayjs(this.range.end).format('YYYY-MM-DD');
            let dealershipId = this.dealershipId !== 0 ? `&dealership_id=${this.dealershipId}` : '';
            let query = `/api/reports/show?report=individual-post&oem_manager_id=${this.selectedBrands.join() || this.oemManagerId}${dealershipId}&start=${start}&end=${end}&columns=`;
            let allUsersInfo = '';
            if (!this.showPreview) {
                this.$emit('next-view');
                this.showPreview = true;
            } else {
                if (this.exporting === true) {
                    return;
                }
                this.exporting = true;
                for (const  [key, value, i] of Object.entries(this.selectedFilters)) {
                    if (key === 'platforms') {
                        for (const [key1, value1] of Object.entries(value.filters)) {
                            value1.filters.forEach(f => {
                                query += `${f.map},`;
                            });
                        }
                    } else {
                        if (key ==='users_info' && this.userRadio === 'All Users') {
                            query += 'all_users,';
                            if (value.filters.length) {
                                value.filters.forEach(f => {
                                    allUsersInfo += `${f.map ? f.map : f.id},`;
                                });
                            }
                        } else {
                            value.filters.forEach(f => {
                                query += `${f.map ? f.map : f.id},`;
                            });
                        }
                    }
                }
                query = query.slice(0, query.length-1);
                if (this.userRadio === 'All Users' && allUsersInfo) {
                    query += `&all_users_columns=${allUsersInfo.slice(0, query.length-1)}`;
                    query = query.slice(0, query.length - 1);
                }
                this.queryString = query + `&sort_field=name&sort_direction=D&sidebar=${this.sidebarCall}`;
                axios({
                    url:          this.queryString,
                    method:       'GET',
                    responseType: 'blob',
                }).then((response) => {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'retailers_export.csv');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    this.close();
                    this.$emit('next-view');
                });
            }
        },
        handleSelectDeselectAll(event) {
            this.setInitialSelectedFilters();
            const avoid = ['brand_name', 'program_name'];
            if (event.value === 'select') {
                for (const [key, value] of Object.entries(this.selectedFilters)) {
                    if (key === 'platforms') {
                        this.filters[key].options.forEach((platform) => {
                            for (const [key1, value1] of Object.entries(platform.options)) {
                                for (const [key2, value2] of Object.entries(value1)) {
                                    if (!avoid.find(key => key === key2)) {
                                        this.selectedFilters[key].filters[platform.name.toLowerCase()].filters.push({ id: key2, title: value2.title, map: value2.map ? value2.map : '', hide: value2.hide });
                                    }
                                }
                            }
                        });
                    } else {
                        for (const [key1, value1] of Object.entries(this.filters[key].options)) {
                            if (!avoid.find(key => key === key1)) {
                                const title = key1 === 'active_status' ? 'Include' : value1.title;
                                this.selectedFilters[key].filters.push({ id: key1, title, map: value1.map ? value1.map : '', hide: value1.hide });
                            }
                        }
                    }
                }
            }
            this.selectedFilters = _.cloneDeep(this.selectedFilters);
            this.setPreviewData();
        },
        handlePreviusStep(fromPreview = null) {
            this.showPreview = false;
            this.exporting = false;
            if (fromPreview) {
                this.$emit('from-preview');
            } else {
                this.$emit('previous-view');
            }
        },
    },
};
</script>

<style>

</style>

/* The code is exporting an object with various properties and values. */
import ContactInfoViewSettings from '@/components/resellers/settings/settings_views/contact-info-view-settings.js';
import GeneralViewSettings from '@/components/resellers/settings/settings_views/general-view-settings.js';

export default {
    accountExecutive:        {},
    contactInfoViewSettings: ContactInfoViewSettings,
    contactOptions:          [
        {
            name: 'General',
            id:   1,
        },
        {
            name: 'Legal',
            id:   2,
        },
        {
            name: 'Sales',
            id:   3,
        },
        {
            name: 'Support',
            id:   4,
        },
        {
            name: 'Tech',
            id:   5,
        },
    ],
    contacts: [
        {
            name:       '',
            type:       '',
            primary:    false,
            cc_invoice: false,
            email:      '',
            phone:      '',
            id:         'empty',
        },
    ],
    countries:                  [],
    customerSuccess:            {},
    generalViewSettings:        GeneralViewSettings,
    inputValues:                {},
    isAdmin:                    false,
    isParent:                   false,
    isSaving:                   false,
    languages:                  [],
    logoUrl:                    '',
    newContactIsValid:          false,
    newContactPhoneIsValid:     false,
    oemManagerChildren:         [],
    oemManagers:                [],
    oemSettings:                {},
    originalLogoUrl:            '',
    productCottonText:          'Products',
    reseller:                   {},
    resellerSettings:           {},
    retailerGroupingCottonText: 'Retailer Grouping',
    selectedContactIndex:       null,
    selectedView:               'general',
    socials:                    {},
    states:                     [],
    timeZone:                   [],
    tokenAuthDate:              'No Token Found',
    tokenExists:                false,
    updateComponent:            1,
};

<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="circle-cross-icon-with-gradient-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0C3.58125 0 0 3.58125 0 8C0 12.4187 3.58125 16 8 16C12.4187 16 16 12.4187 16 8C16 3.58125 12.4187 0 8 0ZM11.3219 10.7656C11.5894 11.0816 11.5499 11.5553 11.2335 11.8228C11.0938 11.9406 10.9219 12 10.75 12C10.5371 12 10.3253 11.9097 10.1769 11.7344L8 9.1625L5.82188 11.7344C5.675 11.9094 5.4625 12 5.22188 12C5.07813 12 4.90625 11.9406 4.76562 11.8219C4.44938 11.5543 4.40969 11.0806 4.67725 10.7647L7.01875 8L4.67813 5.23438C4.40938 4.91875 4.45 4.44375 4.76562 4.17812C5.08125 3.90937 5.55625 3.95 5.82188 4.26562L8 6.8375L10.1772 4.26438C10.4443 3.94813 10.9184 3.90781 11.2344 4.176C11.5506 4.44356 11.5903 4.91725 11.3228 5.23319L8.98125 8L11.3219 10.7656Z" fill="url(#paint0_linear_4708_32514)" />
                <defs>
                    <linearGradient id="paint0_linear_4708_32514" x1="16" y1="-9.53674e-07" x2="9.53674e-07" y2="16" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#0066FF" />
                        <stop offset="1" stop-color="#5000B8" />
                    </linearGradient>
                </defs>
            </svg>
        </template>
    </generic-icon>
</template>


<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'CircleCrossIconWithGradient',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>
<style lang='scss'>
.circle-cross-icon-with-gradient-container {}
</style>

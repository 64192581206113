<template>
    <div class="contact-info-page">
        <h1 class="header">Contact Info</h1>
        <form :id="hubSpotFormName" ref="hubspotForm" class="fields-form" @submit.prevent>
            <div class="fields">
                <div class="contact-field" :class="{'col-xs-6' : !isMobile} ">
                    <connect-textarea
                        name="first-name-text"
                        class="label"
                        :edit="true"
                        :focusable="false"
                        :limit="50"
                        :content="connectStore.contactInfoPage.firstNameText"
                        @update:content="value => updateText('firstNameText', value)"
                        @commit="value => commitText('firstNameText', value)"
                    />
                    <input v-model="liveConnectStore.firstName" name="firstname" class="live-only form-control" type="text" tabindex="0" required>
                </div>
                <div class="contact-field" :class="{'col-xs-6' : !isMobile}">
                    <connect-textarea
                        name="last-name-text"
                        class="label"
                        :edit="true"
                        :focusable="false"
                        :limit="50"
                        :content="connectStore.contactInfoPage.lastNameText"
                        @update:content="value => updateText('lastNameText', value)"
                        @commit="value => commitText('lastNameText', value)"
                    />    <input v-model="liveConnectStore.lastName" name="lastname" class="live-only form-control" type="text" tabindex="0" required>
                </div>
                <div class="contact-field" :class="{'col-xs-6' : !isMobile}">
                    <connect-textarea
                        name="business-name-text"
                        class="label"
                        :edit="true"
                        :focusable="false"
                        :limit="50"
                        :content="connectStore.contactInfoPage.businessNameText"
                        @update:content="value => updateText('businessNameText', value)"
                        @commit="value => commitText('businessNameText', value)"
                    />    <input v-model="liveConnectStore.retailerName" name="company" class="live-only form-control" type="text" tabindex="0" required>
                </div>
                <template v-if="connectStore.contactInfoPage.dealerNumberEnabled">
                    <div class="contact-field" :class="{'col-xs-6' : !isMobile}">
                        <template v-if="!edit">
                            <div class="required-wrapper">
                                <label>
                                    Required
                                    <input v-model="connectStore.contactInfoPage.dealerNumberRequired" type="checkbox" tabindex="0" @change="handleDealerNumberRequired">
                                </label>
                            </div>
                        </template>
                        <connect-textarea
                            name="dealer-number-text"
                            class="label"
                            :edit="edit"
                            :focusable="!edit"
                            :limit="50"
                            :content="connectStore.contactInfoPage.dealerNumberText"
                            @update:content="value => updateText('dealerNumberText', value)"
                            @commit="value => commitText('dealerNumberText', value)"
                        />
                        <input v-model="liveConnectStore.dealerNumber" class="live-only form-control" type="text" :required="connectStore.contactInfoPage.dealerNumberRequired">
                    </div>
                </template>
                <div class="contact-field" :class="{'col-xs-6' : !isMobile}">
                    <connect-textarea
                        name="business-email-text"
                        class="label"
                        :edit="true"
                        :focusable="false"
                        :limit="50"
                        :content="connectStore.contactInfoPage.businessEmailText"
                        @update:content="value => updateText('businessEmailText', value)"
                        @commit="value => commitText('businessEmailText', value)"
                    />
                    <input id="email" ref="emailInput" v-model="liveConnectStore.businessEmail" class="live-only form-control" :class="{'invalid-email': invalidEmail}" name="email" type="email" tabindex="0" required>
                </div>
                <div class="contact-field" :class="{'col-xs-6' : !isMobile}">
                    <connect-textarea
                        name="business-phone-text"
                        class="label"
                        :class="{'invalid-label': !liveConnectStore.businessPhone}"
                        :edit="true"
                        :focusable="false"
                        :limit="50"
                        :content="connectStore.contactInfoPage.businessPhoneText"
                        @update:content="value => updateText('businessPhoneText', value)"
                        @commit="value => commitText('businessPhoneText', value)"
                    />
                    <v-phone ref="businessPhone" v-model:number="liveConnectStore.businessPhone" name="phone" class="live-only" :class="{'invalid-phone': !liveConnectStore.businessPhone}" required @v-phone-updated="handlePhoneSelected" />
                </div>
                <div class="contact-field" :class="{'col-xs-6' : !isMobile}">
                    <connect-textarea
                        name="other-phone-text"
                        class="label"
                        :edit="true"
                        :focusable="false"
                        :limit="50"
                        :content="connectStore.contactInfoPage.otherPhoneText"
                        @update:content="value => updateText('otherPhoneText', value)"
                        @commit="value => commitText('otherPhoneText', value)"
                    /><v-phone v-model:number="liveConnectStore.otherPhone" name="otherphone" class="live-only" />
                </div>
                <template v-if="connectStore.contactInfoPage.retailerGroupingEnabled">
                    <div class="contact-field" :class="{'col-xs-6' : !isMobile}">
                        <template v-if="!edit">
                            <div class="required-wrapper">
                                <label>
                                    Required
                                    <input v-model="connectStore.contactInfoPage.retailerGroupingRequired" type="checkbox">
                                </label>
                            </div>
                        </template>
                        <connect-textarea
                            name="retailer-grouping-text"
                            class="label"
                            :edit="edit"
                            :focusable="!edit"
                            :limit="50"
                            :content="connectStore.contactInfoPage.retailerGroupingText"
                            @update:content="value => updateText('retailerGroupingText', value)"
                            @commit="value => commitText('retailerGroupingText', value)"
                        />
                        <ts-form-input
                            id="retailerGroupingId"
                            ref="retailerGroupingRef"
                            class="live-only"
                            :class="{'invalid-region': invalidRegion}"
                            input-type="dropdown"
                            :dropdown-props="regionProps"
                            @update:inputValue="handleRegionUpdate"
                            @focus="handleRetailerGrouping"
                        />
                    </div>
                </template>
                <template v-if="connectStore.contactInfoPage.languageEnabled">
                    <div class="contact-field" :class="{'col-xs-6' : !isMobile}">
                        <connect-textarea
                            name="languages-text"
                            class="label"
                            :edit="true"
                            :focusable="false"
                            :limit="50"
                            :content="connectStore.contactInfoPage.languageText"
                            @update:content="value => updateText('languageText', value)"
                            @commit="value => commitText('languageText', value)"
                        />
                        <ts-form-input
                            ref="languageRef"
                            class="live-only"
                            :class="{'invalid-language': invalidLanguage}"
                            input-type="dropdown"
                            :show-summarize-selection="false"
                            :dropdown-props="languageProps"
                            @update:inputValue="handleLanguageUpdate"
                            @focus="handleLanguage"
                            @focus-filter-dropdown-menu="handleLanguageOnDropdownMenu"
                        />
                    </div>
                </template>
                <input v-if="isMobile" tabindex="0" style="width:0; height:0; opacity:0; position:absolute;" @focus="handleFinalInput">

                <template v-if="connectStore.contactInfoPage.termsEnabled">
                    <div id="contactTermsContainer" class="terms-container">
                        <input ref="contactTermsContainer" v-model="liveConnectStore.contactTerms" tabIndex="0" type="checkbox" class="terms-checkbox live-only" required>
                        <connect-textarea
                            name="contact-terms"
                            class="terms"
                            :limit="400"
                            :content="connectStore.contactInfoPage.terms"
                            :valid="termsValid"
                            :focusable="!edit"
                            :edit="edit"
                            align-center
                            @update:content="updateTerms"
                            @commit="commitTerms"
                        />
                    </div>
                </template>
            </div>
        </form>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { connectStore, liveConnectStore } from '@/stores/pinia.js';
import DefaultConnectState from '@/stores/connect/state.js';
import ConnectTextarea from '@/components/connects/ConnectTextarea.vue';
import TsFormInput from '@/components/TsFormInput.vue';
import VPhone from '@/components/app/VPhone.vue';
import _ from 'lodash';
import { countriesList } from '@/stores/modules/ResellerSettingsStore/countries.js';
export default {
    components: {
        ConnectTextarea,
        TsFormInput,
        VPhone,
    },
    props: {
        edit: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        slug: {
            type:     String,
            required: false,
            default:  '',
        },
        dev: {
            type:     Boolean,
            required: false,
            default:  true,
        },
    },
    data() {
        return {
            countries:                 [],
            userAgent:                 '',
            languageFilterDropdownRef: '',
            isiPhone:                  '',
            isTouch:                   false,
        };
    },
    computed: {
        formValid() {
            return this.liveConnectStore.firstName
                && this.liveConnectStore.lastName
                && this.liveConnectStore.retailerName
                && this.isValidEmail(this.liveConnectStore.businessEmail)
                && this.liveConnectStore.businessPhone
                && (!this.connectStore.contactInfoPage.termsEnabled || this.liveConnectStore.contactTerms)
                && !this.invalidDealerNumber
                && !this.invalidRegion;
        },
        hubSpotFormName() {
            if (this.slug) {
                return `${this.slug}_contactform`;
            }
            return '';
        },
        invalidDealerNumber() {
            return this.connectStore.contactInfoPage.dealerNumberEnabled
                && this.connectStore.contactInfoPage.dealerNumberRequired
                && !this.liveConnectStore.dealerNumber;
        },
        invalidRegion() {
            return this.connectStore.contactInfoPage.retailerGroupingEnabled
                && this.connectStore.contactInfoPage.retailerGroupingRequired
                && !this.liveConnectStore.regionId;
        },
        invalidEmail() {
            return !this.isValidEmail(this.liveConnectStore.businessEmail);
        },
        invalidLanguage() {
            return this.connectStore.contactInfoPage.languageEnabled
                && this.connectStore.contactInfoPage.languageRequired
                && (this.liveConnectStore.languages?.length === 0);
        },
        regionProps() {
            return {
                multiple:                false,
                optionHeader:            parentId => parentId ? this.liveConnectStore.regionOptions.find(r => r.id === parentId).name : '',
                optionHeaderPartitionBy: 'parent_id',
                options:                 this.selectableRegions,
                selected:                this.liveConnectStore.regionId,
            };
        },
        languageProps() {
            return {
                multiple: true,
                options:  this.liveConnectStore.languageOptions,
                selected: this.liveConnectStore.languages,
            };
        },
        selectableRegions() {
            const regionsSecondTiers = this.liveConnectStore.regionOptions.filter(region => region.parent_id !== null);
            if (regionsSecondTiers) {
                let parentIds = [];
                const uniqueParentIds = new Set();
                regionsSecondTiers.filter(region => {
                    if (!uniqueParentIds.has(region.parent_id)) {
                        uniqueParentIds.add(region.parent_id);
                        parentIds.push(region.parent_id);
                        return true;
                    }

                    return false;
                });

                return this.liveConnectStore.regionOptions.filter(region => !parentIds.includes(region.id));
            }
            return this.liveConnectStore.regionOptions;
        },
        termsValid() {
            return this.connectStore.contactInfoPage.terms != DefaultConnectState().contactInfoPage.terms;
        },
        isMobile() {
            return screen.width <= 600;
        },
        ...mapStores(connectStore, liveConnectStore),
    },
    mounted() {
        this.countries = countriesList();
        const userAgent = navigator.userAgent;
        this.isiPhone = /iPhone/i.test(userAgent);
        if (this.isMobile) {
            window.addEventListener('touchstart', this.handleTouch);
            window.addEventListener('keydown', this.handleKeyDown);
        }
    },
    beforeMounted() {
        window.removeEventListener('touchstart', this.handleTouch);
    },
    methods: {
        handleTouch() {
            this.isTouch = true;
        },
        handleKeyDown() {
            this.isTouch = false;
        },
        handleRetailerGrouping() {
            if (!this.isMobile) {
                return;
            }
            if (this.isTouch) {
                return;
            } else {
                this.$refs.retailerGroupingRef.getFilterDropdown().toggle();
            }
        },
        handleLanguage() {
            if (!this.isMobile) {
                return;
            }
            if (this.isTouch) {
                return;
            } else {
                this.$refs.retailerGroupingRef.getFilterDropdown().toggle();
                this.$refs.languageRef.getFilterDropdown().toggle();
            }
        },
        handleLanguageOnDropdownMenu() {
            if (!this.isMobile) {
                return;
            }
            if (this.isiPhone) {
                this.$refs.retailerGroupingRef.getFilterDropdown().toggle();
            }
        },
        handleFinalInput() {
            if (!this.isMobile) {
                return;
            }
            this.$refs.languageRef.getFilterDropdown().toggle();
            this.$refs.contactTermsContainer.focus();
        },
        commitTerms(value) {
            this.connectStore.$patch({ contactInfoPage: { terms: value }});
        },
        commitText(field, value) {
            this.connectStore.$patch({ contactInfoPage: { [field]: value }});
        },
        handleDealerNumberRequired(event) {
            let val = event.target.checked;
            let text = this.connectStore.contactInfoPage.dealerNumberText.toLowerCase();
            if (val && text == 'dealer number (if applicable) - not required') {
                this.connectStore.$patch({ contactInfoPage: { dealerNumberText: 'Dealer Number (If Applicable)' }});
            } else if (!val && text == 'dealer number (if applicable)') {
                this.connectStore.$patch({ contactInfoPage: { dealerNumberText: 'Dealer Number (If Applicable) - Not Required' }});
            }
        },
        handleRegionUpdate(value) {
            this.liveConnectStore.regionId = value;
        },
        handleLanguageUpdate(value) {
            this.liveConnectStore.languages = value;
        },
        updateTerms(value) {
            this.connectStore.contactInfoPage.termsUpdated = true;
            this.connectStore.contactInfoPage.terms = value;
        },
        updateText(field, value) {
            this.connectStore.contactInfoPage[field] = value;
        },
        submitHubspotForm() {
            if (this.slug) {
                let event = new Event('submit', { cancelable: true});
                if (!this.dev) {
                    this.$refs.hubspotForm.dispatchEvent(event);
                }
            }
        },
        handlePhoneSelected: _.debounce(function(payload) {
            let country = this.countries.find(c => c.code == payload.countryCode);

            if (country) {
                this.liveConnectStore.country = country.slug ? country.slug : country.name;
            }
        }, 1000),
        handleRetailerGroupRequired() {
            this.connectStore.saveButton.status = null;
            this.connectStore.saveButton.text = 'Save';
        },
        handleLanguageRequired() {
            this.connectStore.saveButton.status = null;
            this.connectStore.saveButton.text = 'Save';
        },
        isValidEmail(email) {
            return /\S+@\S+\.\S+/.test(email);
        },
    },
};
</script>

<style lang="scss">
@media(min-width: 950px) {
    .contact-info-page {
        align-items: stretch;
        height: 58rem;
        overflow: visible;

        .header {
            font-weight: bold;
            left: 60px;
            position: relative;
            width: calc(100% - 60px);
        }

        .terms-container {
            display: flex;
            align-items: flex-start;
            width: 100%;
            height: 10rem;
            justify-content: center;

            div {
                align-items: start !important;
            }
        }

        .contact-field {
            margin-top: 10px;

            &:nth-of-type(-n+2) {
                margin-top: 0;
            }

            .required-wrapper {
                position: absolute;
                right: 65px;
                top: 22px;

                label {
                    color: #85878f;
                    font-weight: normal;

                    input[type="checkbox"] {
                        vertical-align: top;
                    }
                }
            }

            .label {
                color: #85878f;
                display: block;
                font-size: 14px;
                height: 26px;
                left: -8px;
                margin-bottom: 5px;
                padding: 0.2em 0.6em 0.3em;
                position: relative;
                text-align: left;
                text-transform: uppercase;
                top: 20px;
                width: calc(100% - 100px);

                textarea {
                    text-transform: uppercase;
                }
            }

            .form-control {
                border-color: #ebebed;
                height: 42px;
                position: relative;
                top: 20px;
            }

            .ts-form-input {
                position: relative;
                top: 18px;

                & ~ .label {
                    top: -42px;
                }
            }

            .v-phone {
                position: relative;
                top: 22px;

                & ~ .label {
                    top: -44px;
                }
            }

            .filter-dropdown {
                border-color: #ebebed;
                min-height: 42px;
            }

            .maz-input {
                --maz-border-color: #ebebed;
                --maz-border-radius: 4px;
                height: 42px !important;
            }

            .maz-phone-number-input__country-flag {
                bottom: 1.2rem;
            }

            &:nth-of-type(2n+1) {
                padding-left: 60px;
                padding-right: 38px;
            }

            &:nth-of-type(2n) {
                padding-left: 38px;
                padding-right: 60px;
            }
        }

        .terms {
            display: flex;
            align-items: center;
            font-size: 12px;
            height: 46px;
            left: 90px;
            position: relative;
            width: 1136px;
            top: 45px;

            textarea {
                height: 100% !important;
            }
        }

        .terms-checkbox {
            left: 80px;
            position: relative;
            top: 50px;
        }
    }
}

//inbetween phones and desktop (eg. tablets)
@media (max-width: 950px) and (min-width: 425px) {
    .contact-info-page {
        margin-top: 70px !important;
    }
}

@media (min-width: 820px) and (min-height: 1180px) {
    .contact-info-page {
        width: 1050px;
    }
}

@media(max-width: 950px) {
    .contact-info-page {
        padding-bottom: 20px;

        .connect-layout {
            .connect-bar {
                .connect-footer-bar {
                    position:relative;
                }
            }
        }
        .fields {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .terms-container {
                display: flex;
                align-items: flex-start;
                width: 100%;
                height: 130px;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }

        .header {
            font-weight: bold;
        }

        .contact-field {
            margin-top: 20px;
            width: 100%;

            .label {
                color: #85878f;
                display: block;
                font-size: 14px;
                text-align: left;
                text-transform: uppercase;
            }

            .form-control {
                border-color: #ebebed;
                height: 42px;
                top: 20px;
            }

            .ts-form-input {
                top: 18px;

                & ~ .label {
                    top: -42px;
                }
            }

            .v-phone {
                top: 22px;

                & ~ .label {
                    top: -44px;
                }

                .m-phone-number-input {
                    flex-direction: row;
                    .m-country-selector {
                        min-width: unset;
                    }
                    .m-phone-input {
                        flex: unset;
                    }
                }
            }

            .filter-dropdown {
                border-color: #ebebed;
                height: 42px;
            }

            .maz-input {
                --maz-border-color: #ebebed;
                --maz-border-radius: 4px;
                height: 42px !important;
            }

            .maz-phone-number-input__country-flag {
                bottom: 1.2rem;
            }
        }

        .terms {
            width: 100%;
            font-size: 12px;
            padding-left: 10px;
            padding-top: 0px;

            textarea {
                height: 135px !important;
            }
        }
    }
}
</style>

const getUserProfileSettings = () => {
    return [
        // User Profile
        {
            id:        'user-profile-picture',
            titleData: {
                title:       'Profile Picture',
                description: '',
            },
            columnsData: [
                {
                    ['upload-profile-picture']: {
                        type:     'slot',
                        slotName: 'upload-profile-picture',
                    },
                },
            ],
        },
        // user info
        {
            id:        'user-info',
            titleData: {
                title:       'User Info',
                description: '',
                popupTitle:  {
                    type:     'slot',
                    slotName: 'popup-title',
                    showKey:  'can_edit',
                },
            },

            columnsData: [
                {
                    ['user-role']: {
                        id:              'user-role',
                        label:           'USER ROLE',
                        type:            'dropdown',
                        subType:         'single',
                        placeholder:     'Retailer',
                        options:         [],
                        value:           '',
                        enabledKey:      'can_edit',
                        titleExtraStyle: 'font-size:14px;',

                    },
                    userNameData: [
                        {
                            id:              'user-profile-first-name',
                            label:           'FIRST NAME',
                            type:            'text',
                            titleSlotName:   'Required',
                            subType:         null,
                            placeholder:     'First Name',
                            options:         null,
                            value:           '',
                            viewProfile:     true,
                            titleExtraStyle: 'display: flex; justify-content: space-between; width: 35rem; font-size:14px;',

                        },
                        {
                            id:              'user-profile-last-name',
                            label:           'LAST NAME',
                            type:            'text',
                            titleSlotName:   'Required',
                            subType:         null,
                            placeholder:     'Last Name',
                            options:         null,
                            value:           '',
                            viewProfile:     true,
                            titleExtraStyle: 'display: flex; justify-content: space-between; width: 35rem; font-size:14px;',

                        },
                    ],
                    contact: [
                        {
                            id:              'user-email',
                            label:           'EMAIL',
                            type:            'text',
                            subType:         null,
                            placeholder:     'Email',
                            options:         null,
                            value:           '',
                            enabledKey:      'can_edit',
                            titleExtraStyle: 'font-size:14px;',
                        },
                        {
                            id:              'user-phone',
                            label:           'PHONE', // All caps
                            type:            'text', // types: dropdown, phone, text.
                            placeholder:     '(XXX)XXX-XXXX',
                            value:           '', // Phone format from input: tel:+5072222222
                            isValid:         false,
                            titleExtraStyle: 'font-size:14px;',
                        },
                    ],
                },
            ],
        },
    ];
};

export default getUserProfileSettings();

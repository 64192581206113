<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="eye-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.83337 6.00014C6.75268 6.00014 7.50011 5.23139 7.50011 4.28585C7.50011 3.97782 7.39854 3.68853 7.28135 3.43943C7.35427 3.43139 7.42719 3.42871 7.50011 3.42871C8.88038 3.42871 10.0002 4.55639 10.0002 6.00014C10.0002 7.41978 8.88038 8.57157 7.50011 8.57157C6.0964 8.57157 5 7.41978 5 6.00014C5 5.92514 5.0026 5.85014 5.01042 5.77514C5.25261 5.89567 5.53388 6.00014 5.83337 6.00014Z" fill="#85878F" />
                <path d="M2.48501 2.15893C3.7111 0.986786 5.39607 0 7.50033 0C9.60459 0 11.2896 0.986786 12.5162 2.15893C13.735 3.32143 14.5501 4.69018 14.9355 5.67054C15.0215 5.88214 15.0215 6.11786 14.9355 6.32946C14.5501 7.28571 13.735 8.65446 12.5162 9.84107C11.2896 11.0143 9.60459 12 7.50033 12C5.39607 12 3.7111 11.0143 2.48501 9.84107C1.2662 8.65446 0.451585 7.28571 0.0640946 6.32946C-0.0213649 6.11786 -0.0213649 5.88214 0.0640946 5.67054C0.451585 4.69018 1.2662 3.32143 2.48501 2.15893ZM7.50033 9.85714C9.57073 9.85714 11.2505 8.12946 11.2505 6C11.2505 3.87054 9.57073 2.14286 7.50033 2.14286C5.42993 2.14286 3.75017 3.87054 3.75017 6C3.75017 8.12946 5.42993 9.85714 7.50033 9.85714Z" fill="#D6D9DB" />
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'EyeIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>

<style lang="scss">
.eyes-slash-icon-container{
}
</style>







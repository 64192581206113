import actions from '@/stores/connect/actions.js';
import getters from '@/stores/connect/getters.js';
import state from '@/stores/connect/state.js';
import { defineStore } from 'pinia';

export default defineStore({
    id:      'connect',
    state:   state,
    getters: getters,
    actions: actions,
    history: 'patch',
});

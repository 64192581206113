<template>
    <div class="settings-layout">
        <h3>
            <slot name="title" />
        </h3>
        <span>
            <slot name="subtitle" />
        </span>
        <div class="horizontal-divider" />
        <slot name="body" />
    </div>
</template>
<script>
export default {
    name: 'SettingsLayout',
};
</script>

<style scoped lang="scss">
.settings-layout {
    border: 4px solid #F0F3F6;
    padding: 32px;
    width: 100%;
    display: inline-block;
    max-width: 100%;
    margin: 3px 32px 5px;
    overflow: auto;

    h3 {
        font-family: Arial;
        font-weight: 700;
        margin: 0;
        padding-bottom: 4px;
    }

    .horizontal-divider {
        border-bottom: 2px solid #F0F3F6;
        margin: 2em 0;
    }
}
</style>

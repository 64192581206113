import axios from '@/axios';

const performanceFields = {
    1: {
        Activity:   ['Retailers', 'Posts'],
        Visibility: ['Impressions', 'Organic Reach'],
        Engagement: ['Post Clicks', 'Reactions', 'Comments', 'Shares', 'Video Views'],
    },
    2: {
        Activity:   ['Retailers', 'Posts'],
        Visibility: ['Impressions'],
        Engagement: ['User Profile Clicks', 'Reactions', 'Comments', 'Shares'],
    },
    3: {
        Activity:   ['Retailers', 'Posts'],
        Visibility: ['Impressions', 'Organic Reach'],
        Engagement: ['Post Clicks', 'Reactions', 'Comments', 'Shares', 'Video Views'],
    },
    4: {
        Activity: ['Retailers', 'Posts', 'Profile Views'],
    },
    5: {
        Activity:   ['Retailers', 'Posts'],
        Visibility: ['Impressions'],
        Engagement: ['Post Clicks', 'Reactions', 'Comments', 'Shares', 'Video Views'],
    },
};

function buildTableData(platformId, insights) {
    let platform = insights.Performance[platformId];
    let tableData = [];
    for (let section in performanceFields[platformId]) {
        let index = tableData.push({name: section, data: []}) - 1;
        for (let key of performanceFields[platformId][section]) {
            tableData[index].data.push({
                name:     key,
                previous: platform?.previous?.[section]?.[key] ?? 'N/A',
                current:  platform?.current?.[section]?.[key] ?? 'N/A',
                indent:   key == 'Organic Reach' || (key == 'Posts' && platformId != 4),
            });
        }
    }
    return tableData;
}

function buildFans(platformId, insights) {
    let followers = insights?.Performance?.[platformId]?.followers?.current;

    if (typeof followers == 'number') {
        return { value: followers };
    }
    return null;
}

function buildEngagementRate(platformId, insights) {
    if (![1, 3].includes(platformId)) {
        return null;
    }

    let engagements = Object.assign({}, insights?.Performance?.[platformId]?.current?.Engagement);

    if (engagements['Video Views']) {
        delete engagements['Video Views'];
    }

    if (engagements['Post Clicks']) {
        delete engagements['Post Clicks'];
    }

    let engagementCount = Object.values(engagements).reduce((a, b) => a + b, 0);
    let organicReach = insights?.Performance?.[platformId]?.current?.Visibility?.['Organic Reach'];

    if (organicReach) {
        return (engagementCount / organicReach * 100).toFixed(2) + '%';
    }
    return null;
}

function buildYearlyTableData(year, insights) {
    let tableData = [];
    let data = insights['Full Year Overview']?.insights;
    if (data) {
        for (let section in data) {
            let index = tableData.push({ name: section, data: [] }) - 1;
            for (let key in data[section]) {
                tableData[index].data.push({
                    name:     key,
                    previous: data[section][key]?.[year - 1] || 'N/A',
                    current:  data[section][key]?.[year] || 0,
                    indent:   key == 'Organic Reach' || key == 'Posts',
                });
            }
        }
    }
    return tableData;
}

function buildYearlyEngagementRate(year, insights) {
    let engagements = Object.assign({}, insights?.['Full Year Overview']?.insights?.Engagement);

    if (engagements['Video Views']) {
        delete engagements['Video Views'];
    }
    if (engagements['Post Clicks']) {
        delete engagements['Post Clicks'];
    }
    let engagementCount = Object.values(engagements)
        .map(engagement => engagement[year])
        .reduce((a, b) => a + b, 0);
    let organicReach = insights?.['Full Year Overview']?.insights?.Visibility?.['Organic Reach']?.[year];

    if (engagementCount && organicReach) {
        return (engagementCount / organicReach * 100).toFixed(2) + '%';
    }
    return null;
}

function buildTopPerforming(platformId, insights) {
    let tops = insights?.['Top Performing']?.[platformId]?.tops?.impressions;
    if (!tops) {
        return [];
    }
    let posts = Object.values(tops).flat().sort((a, b) => b.counts - a.counts).slice(0, 6);

    return posts.map(post => {
        let postTypes = {
            1: 'text',
            2: 'url',
            3: 'photo',
            5: 'video',
        };

        let thumbnail = post.thumbnail;
        let postType = postTypes[post.post_type_id];

        if (postType == 'text') {
            thumbnail = '/images/place-holders/text.svg';
        }

        return {
            highlights: [{ name: 'Impressions', value: post.counts }],
            image:      thumbnail,
            caption:    post.message,
            type:       postType,
        };
    });
}

export default {
    loadData(oemManagerId, reportId) {
        return axios.get(`/api/oem-managers/${oemManagerId}/quarterly-reports/${reportId}`).then(response => {
            this.brandName = response.data.brand_program_name || response.data.oem_manager_name;
            this.oemManagerName = response.data.oem_manager_name;
            this.reportId = response.data.report.id;
            this.year = (new Date(response.data.report.reported_on)).getFullYear().toString(),
            this.quarter = 'Q' + Math.ceil((new Date(response.data.report.reported_on)).getMonth() / 3).toString(),
            this.patchInsights(response.data);
            let profilePicture = response.data?.profile_picture || '';
            this.patchText(response.data.report.content || {}, profilePicture);

            if (response.data.report?.content) {
                this.recipientsUserIds = response.data.report.content.recipients_user_ids;
            } else {
                this.recipientUserIds = [];
            }

            this.$clearHistory();
        });
    },
    patchInsights(data) {
        let insights = data.insights;
        let report = data.report?.content || {};

        this.$patch({
            QuarterlyReportTitlePage: {
                logoUrl: data.oem_manager_logo,
            },
            QuarterlyReportTotalsPage: {
                includePage: report['Totals Across All Destinations']?.include ?? true,
                posts:       insights['Totals Across All Destinations']?.[0]?.posts || 0,
                impressions: insights['Totals Across All Destinations']?.[0]?.impressions || 0,
                reach:       insights['Totals Across All Destinations']?.[0]?.organic_reach || 0,
            },
            FacebookPerformanceChartPage: {
                engagementRate: buildEngagementRate(1, insights),
                fans:           buildFans(1, insights),
                includePage:    report.Performances?.[1]?.include ?? !!insights?.Performance?.[1],
                tableData:      buildTableData(1, insights),
            },
            FacebookTopPerformingPage: {
                includePage: report['Top Performing']?.[1]?.include ?? !!insights?.['Top Performing']?.[1],
                posts:       buildTopPerforming(1, insights),
            },
            InstagramPerformanceChartPage: {
                engagementRate: buildEngagementRate(3, insights),
                fans:           buildFans(3, insights),
                includePage:    report.Performances?.[3]?.include ?? !!insights?.Performance?.[3],
                tableData:      buildTableData(3, insights),
            },
            InstagramTopPerformingPage: {
                includePage: report['Top Performing']?.[3]?.include ?? !!insights?.['Top Performing']?.[3],
                posts:       buildTopPerforming(3, insights),
            },
            LinkedInPerformanceChartPage: {
                engagementRate: buildEngagementRate(5, insights),
                fans:           buildFans(5, insights),
                includePage:    report.Performances?.[5]?.include ?? !!insights?.Performance?.[5],
                tableData:      buildTableData(5, insights),
            },
            LinkedInTopPerformingPage: {
                includePage: report['Top Performing']?.[5]?.include ?? !!insights?.['Top Performing']?.[5],
                posts:       buildTopPerforming(5, insights),
            },
            XPerformanceChartPage: {
                engagementRate: buildEngagementRate(2, insights),
                fans:           buildFans(2, insights),
                includePage:    report.Performances?.[2]?.include ?? !!insights?.Performance?.[2],
                tableData:      buildTableData(2, insights),
            },
            XTopPerformingPage: {
                includePage: report['Top Performing']?.[2]?.include ?? !!insights?.['Top Performing']?.[2],
                posts:       buildTopPerforming(2, insights),
            },
            GooglePerformanceChartPage: {
                includePage: report.Performances?.[4]?.include ?? !!insights?.Performance?.[4],
                tableData:   [
                    {
                        name: 'Activity',
                        data: [
                            {
                                name:     'Retailers',
                                previous: insights.Performance?.[4]?.previous?.Activity?.Retailers || 'N/A',
                                current:  insights.Performance?.[4]?.current?.Activity?.Retailers || 'N/A',
                            },
                            {
                                name:     'Posts',
                                previous: insights.Performance?.[4]?.previous?.Activity?.Posts || 'N/A',
                                current:  insights.Performance?.[4]?.current?.Activity?.Posts || 'N/A',
                            },
                            {
                                name:     'Profile Views',
                                previous: insights.Performance?.[4]?.Activity?.['Profile Views']?.previous || 'N/A',
                                current:  insights.Performance?.[4]?.Activity?.['Profile Views']?.current || 'N/A',
                            },
                        ],
                    },
                ],
                stars: {
                    value: parseFloat(insights.Performance?.[4]?.Rating?.Average.toFixed(1)) || 0,
                },
                fans:           buildFans(4, insights),
                engagementRate: buildEngagementRate(4, insights),
            },
            QuarterlyReportAmplificationPage: {
                includePage: report['The Power of Amplification']?.include ?? !!data.power_of_amplification,
                leftPost:    {
                    image:   data.power_of_amplification[0]?.thumbnail || '/images/place-holders/no-link.svg',
                    caption: data.power_of_amplification[0]?.caption || '',
                },
                rightPost: {
                    highlights: [
                        {
                            name:  'Quarterly Engagements',
                            value: data.power_of_amplification[0]?.engagements,
                        },
                    ],
                    image:   data.power_of_amplification[0]?.thumbnail || '/images/place-holders/no-link.svg',
                    caption: data.power_of_amplification[0]?.caption || '',
                },
                url:   data.power_of_amplification[0]?.original_url || '',
                brand: {
                    name:  data.oem_manager_name,
                    image: data.power_of_amplification[0]?.fb_business_profile_picture,
                },
                retailers: data.power_of_amplification[0]?.retailers_who_posted || 0,
                users:     data.power_of_amplification[0].organic_reach_verbose || 0,
            },
            FacebookYoyEngagementsPage: {
                includePage: report['YoY Engagements']?.include ?? (!!insights['YoY Engagements'] && this.quarter == 'Q4'),
                metrics:     [
                    {
                        name:     'Posts',
                        previous: insights['YoY Engagements']?.[this.year - 1]?.posts || 0,
                        current:  insights['YoY Engagements']?.[this.year]?.posts || 0,
                    },
                    {
                        name:     'Engagements',
                        previous: insights['YoY Engagements']?.[this.year - 1]?.engagements || 0,
                        current:  insights['YoY Engagements']?.[this.year]?.engagements || 0,
                    },
                ],
            },
            FacebookYoyImpressionsPage: {
                includePage: report['YoY Impressions']?.include ?? (!!insights['YoY Impressions'] && this.quarter == 'Q4'),
                metrics:     [
                    {
                        name:     'Impressions',
                        previous: insights['YoY Impressions']?.[this.year - 1]?.impressions || 0,
                        current:  insights['YoY Impressions']?.[this.year]?.impressions || 0,
                    },
                ],
            },
            FacebookYoyOrganicReachPage: {
                includePage: report['YoY Organic Reach']?.include ?? (!!insights['YoY Organic Reach'] && this.quarter == 'Q4'),
                metrics:     [
                    {
                        name:     'Organic Reach',
                        previous: insights['YoY Organic Reach']?.[this.year - 1]?.organic_reach || 0,
                        current:  insights['YoY Organic Reach']?.[this.year]?.organic_reach || 0,
                    },
                ],
            },
            FacebookYearlyPerformancePage: {
                engagementRate: buildYearlyEngagementRate(this.year, insights),
                fans:           buildFans(1, insights),
                includePage:    report['Full Year Overview']?.include ?? (!!insights?.['Full Year Overview']?.insights && this.quarter == 'Q4'),
                tableData:      buildYearlyTableData(this.year, insights),
            },
            QuarterlyReportThankYouPage: {
                oemManagerName: this.oemManagerName,
                supportEmail:   data.support_email,
            },
        });
    },
    patchText(report, profilePicture) {
        if (report) {
            this.$patch({
                QuarterlyReportTotalsPage: {
                    title:   report['Totals Across All Destinations']?.title || '',
                    message: report['Totals Across All Destinations']?.content || '',
                },
                FacebookPerformanceChartPage: {
                    title:   report.Performances?.[1]?.title || '',
                    message: report.Performances?.[1]?.content || '',
                },
                InstagramPerformanceChartPage: {
                    title:   report.Performances?.[3]?.title || '',
                    message: report.Performances?.[3]?.content || '',
                },
                LinkedInPerformanceChartPage: {
                    title:   report.Performances?.[5]?.title || '',
                    message: report.Performances?.[5]?.content || '',
                },
                XPerformanceChartPage: {
                    title:   report.Performances?.[2]?.title || '',
                    message: report.Performances?.[2]?.content || '',
                },
                GooglePerformanceChartPage: {
                    title:   report.Performances?.[4]?.title || '',
                    message: report.Performances?.[4]?.content || '',
                },
                QuarterlyReportAmplificationPage: {
                    message:  report['The Power of Amplification']?.content || '',
                    leftPost: {
                        highlights: [
                            {
                                name:     'Lifetime Engagements',
                                value:    report['The Power of Amplification']?.engagements || '',
                                editable: true,
                            },
                        ],
                    },
                    profilePicture: profilePicture,
                },
                QuarterlyReportProgramOpportunitiesPage: {
                    leftTitle:    report['Program Opportunities']?.left?.title || '',
                    leftMessage:  report['Program Opportunities']?.left?.content || '',
                    rightTitle:   report['Program Opportunities']?.right?.title || '',
                    rightMessage: report['Program Opportunities']?.right?.content || '',
                },
                FacebookYoyEngagementsPage: {
                    title:   report['YoY Engagements']?.title || '',
                    message: report['YoY Engagements']?.content || '',
                },
                FacebookYoyImpressionsPage: {
                    title:   report['YoY Impressions']?.title || '',
                    message: report['YoY Impressions']?.content || '',
                },
                FacebookYoyOrganicReachPage: {
                    title:   report['YoY Organic Reach']?.title || '',
                    message: report['YoY Organic Reach']?.content || '',
                },
                FacebookYearlyPerformancePage: {
                    title:   report['Full Year Overview']?.title || '',
                    message: report['Full Year Overview']?.content || '',
                },
            });
        }
    },
    saveData(oemManagerId) {
        let complete = Object.values(this.pageValidity).every(valid => valid);
        return axios.put('/reports/quarterly-reports/save', {
            report_id:      this.reportId,
            oem_manager_id: oemManagerId,
            final_save:     complete,
            content:        {
                'completed':                      complete,
                'Totals Across All Destinations': {
                    include: this.QuarterlyReportTotalsPage.includePage,
                    title:   this.QuarterlyReportTotalsPage.title,
                    content: this.QuarterlyReportTotalsPage.message,
                },
                'Performances': {
                    1: {
                        include: this.FacebookPerformanceChartPage.includePage,
                        title:   this.FacebookPerformanceChartPage.title,
                        content: this.FacebookPerformanceChartPage.message,
                    },
                    2: {
                        include: this.XPerformanceChartPage.includePage,
                        title:   this.XPerformanceChartPage.title,
                        content: this.XPerformanceChartPage.message,
                    },
                    3: {
                        include: this.InstagramPerformanceChartPage.includePage,
                        title:   this.InstagramPerformanceChartPage.title,
                        content: this.InstagramPerformanceChartPage.message,
                    },
                    4: {
                        include: this.GooglePerformanceChartPage.includePage,
                        title:   this.GooglePerformanceChartPage.title,
                        content: this.GooglePerformanceChartPage.message,
                    },
                    5: {
                        include: this.LinkedInPerformanceChartPage.includePage,
                        title:   this.LinkedInPerformanceChartPage.title,
                        content: this.LinkedInPerformanceChartPage.message,
                    },
                },
                'Top Performing': {
                    1: {
                        include:  this.FacebookTopPerformingPage.includePage,
                        captions: this.FacebookTopPerformingPage.posts.map(post => post.caption),
                    },
                    2: {
                        include:  this.XTopPerformingPage.includePage,
                        captions: this.XTopPerformingPage.posts.map(post => post.caption),
                    },
                    3: {
                        include:  this.InstagramTopPerformingPage.includePage,
                        captions: this.InstagramTopPerformingPage.posts.map(post => post.caption),
                    },
                    5: {
                        include:  this.LinkedInTopPerformingPage.includePage,
                        captions: this.LinkedInTopPerformingPage.posts.map(post => post.caption),
                    },
                },
                'The Power of Amplification': {
                    include:         this.QuarterlyReportAmplificationPage.includePage,
                    content:         this.QuarterlyReportAmplificationPage.message,
                    engagements:     this.QuarterlyReportAmplificationPage.leftPost.highlights[0].value,
                    profile_picture: this.QuarterlyReportAmplificationPage.profilePicture,
                    caption:         this.QuarterlyReportAmplificationPage.leftPost.caption,
                },
                'Program Opportunities': {
                    include: this.QuarterlyReportProgramOpportunitiesPage.includePage,
                    left:    {
                        title:   this.QuarterlyReportProgramOpportunitiesPage.leftTitle,
                        content: this.QuarterlyReportProgramOpportunitiesPage.leftMessage,
                    },
                    right: {
                        title:   this.QuarterlyReportProgramOpportunitiesPage.rightTitle,
                        content: this.QuarterlyReportProgramOpportunitiesPage.rightMessage,
                    },
                },
                'YoY Engagements': {
                    include: this.FacebookYoyEngagementsPage.includePage,
                    title:   this.FacebookYoyEngagementsPage.title,
                    content: this.FacebookYoyEngagementsPage.message,
                },
                'YoY Impressions': {
                    include: this.FacebookYoyImpressionsPage.includePage,
                    title:   this.FacebookYoyImpressionsPage.title,
                    content: this.FacebookYoyImpressionsPage.message,
                },
                'YoY Organic Reach': {
                    include: this.FacebookYoyOrganicReachPage.includePage,
                    title:   this.FacebookYoyOrganicReachPage.title,
                    content: this.FacebookYoyOrganicReachPage.message,
                },
                'Full Year Overview': {
                    include: this.FacebookYearlyPerformancePage.includePage,
                    title:   this.FacebookYearlyPerformancePage.title,
                    content: this.FacebookYearlyPerformancePage.message,
                },
            },
        });
    },
};

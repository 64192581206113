import { default as _axios } from 'axios';

const axios = _axios.create();

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('@/axios CSRF token not found.');
}

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.response.use(
    (response) => {
        if (response?.data?.redirect_to) {
            window.location = response.data.redirect_to;
        }
        return response;
    },
    (err) => {
        const status = err.response?.status;
        const message = err.response?.statusText || err.response?.data?.message || 'Unknown error!';
        console.error('axios.js -> ', err, err?.response);
        if (!status) {
            window.flash.error(message);
        } else {
            if (status == 403) {
                window.location = '/forbidden';
            } else if (status == 419) {
                // CSRF token mismatch
                window.location.reload();
            } else {
                window.flash.error(message);
            }
        }
    },
);
export default axios;

<template>
    <div class="connect-thank-you-page">
        <connect-textarea
            name="thank-you-header"
            class="header"
            :limit="120"
            :content="connectStore.thankYouPage.header"
            :focusable="!edit"
            :edit="edit"
            @update:content="updateHeader"
            @commit="commitHeader"
        />
        <connect-textarea
            name="thank-you-text"
            class="text"
            :limit="260"
            :content="connectStore.thankYouPage.text"
            :focusable="!edit"
            :edit="edit"
            @update:content="updateText"
            @commit="commitText"
        />
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import {connectStore, liveConnectStore} from '@/stores/pinia.js';
import ConnectTextarea from '@/components/connects/ConnectTextarea.vue';

export default {
    components: {
        ConnectTextarea,
    },
    props: {
        edit: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        dev: {
            type:     Boolean,
            required: false,
            default:  true,
        },
    },
    computed: {
        ...mapStores(connectStore, liveConnectStore),
    },
    methods: {
        commitHeader(value) {
            this.connectStore.$patch({ thankYouPage: { header: value }});
        },
        commitText(value) {
            this.connectStore.$patch({ thankYouPage: { text: value }});
        },
        updateHeader(value) {
            this.connectStore.thankYouPage.header = value;
        },
        updateText(value) {
            this.connectStore.thankYouPage.text = value;
        },
    },
};
</script>

<style lang="scss">
@media(min-width: 950px) {
    .connect-thank-you-page {
        position: relative;
        top: 128px;

        .header {
            font-family: Geomanist;
            font-size: 36px;
            font-weight: bold;
            overflow: hidden;
            padding-top: 25px;
            width: 1006px;

            textarea {
                text-align: center;
            }
        }

        .text {
            font-family: Geomanist;
            font-size: 16px;
            min-height: 81px;
            margin-top: 15px;
            overflow: hidden;
            width: 1006px;

            textarea {
                text-align: center;
            }
        }

    }
}

//inbetween phones and desktop (eg. tablets)
@media (max-width: 950px) and (min-width: 425px) {
    .connect-thank-you-page {
        margin-top: 100px !important;
        top: 50px !important;
        .text {
            margin-top: 50px !important;
        }
        .header {
            top: 40px !important;
        }
    }
}

@media(max-width: 950px) {
    .connect-thank-you-page {
        position: relative;
        top: 15px;
        min-height: 64vh;

        .header {
            font-family: Geomanist;
            font-size: 36px;
            font-weight: bold;

            textarea {
                text-align: center;
            }
        }

        .text {
            font-family: Geomanist;
            font-size: 16px;
            min-height: 81px;
            margin-top: 15px;
            height: 250px !important;

            textarea {
                text-align: center;
            }
        }

    }
}
</style>



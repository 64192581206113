export default {
    setUser({ payload }) {
        this.$patch(payload);
    },
    setViewAs({ payload }) {
        this.$patch({ viewAs: payload });
    },
    goToDashboard({ payload }) {
        if (
            (payload && !payload.activeSession) ||
            this.$state.default_landing_page === null
        ) {
            window.location.href = '/login';
        } else {
            window.location.href = this.$state.default_landing_page;
        }
    },
};

<template>
    <div class="export-preview-container">
        <div v-for="(row, i) in filters" :key="'row' + i">
            <div v-if="row['facebook']" class="export-preview-custom-title">Destination Metrics</div>
            <div v-if="row['retailer']" class="export-preview-custom-title">Content Classifiers</div>
            <div :class="'export-preview-row row-'+i">
                <div v-for="(filter, key2, j) in row" :key="'filter_'+ j" class="row-item">
                    <div v-if="j !== 0" class="vertical-divider" />
                    <div>
                        <div :class="isCustomTitle(filter.title) ? 'custom-row-item-title' : 'row-item-title'">{{ filter.title }}</div>
                        <div :class="{ 'column-container': i === 0 }">
                            <div v-for="(chunk,k) in chunkedItems(filter.filters)" :key="k" class="column">
                                <div v-for="(item, k1) in chunk" :key="'selected_'+k1" :class="item.hide ? 'column-label-style' : ''">
                                    <div class="column-title">{{ item.title }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="(filter, i) in filters" :key="'filter_'+ i" class="column">
                <h5>{{ filter.title }}</h5>
                <div v-for="(item, j) in filter.selectedValues" :key="'selected_'+j">
                    <span>{{ item.title }}</span>
                </div>
            </div>
            <div v-if="i < filters.length - 1" class="horizontal-divider" />
        </div>
    </div>
</template>
<script>
import _ from 'lodash';
export default {
    props: {
        filters: {
            type:     Array,
            required: true,
            default:  () => [],
        },
        exportType: {
            type:     String,
            required: true,
            default:  '',
        },
    },
    data() {
        return {
            custom: ['FACEBOOK', 'INSTAGRAM', 'LINKEDIN', 'X', 'GOOGLE', 'RETAILER', 'BRAND'],
        };
    },
    methods: {
        chunkedItems(array) {
            let size = this.exportType === 'Brand' ? 6 : 5;
            return _.chunk(array, size);
        },
        isCustomTitle(title) {
            if (title) {
                title = title.toUpperCase();
            }
            return this.custom.includes(title);
        },
    },
};
</script>
<style lang="scss">
.export-preview-container {
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 100%;
    min-height: 500px;
    padding: 20px;
    .modal-content{
        width: 90% !important;
    }

    .export-preview-row {
        display: flex;
        justify-content: flex-start;
        .vertical-divider {
            border-left: 2px solid #F0F3F6;
            height: 100%;
            margin: 0px 10px;
        }
        .row-item {
            display: flex;
            //margin-right: 5em;
            h5 {
                margin: 0px;
            }
            .custom-row-item-title {
                color: #000;
                font-family: "Open Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
                margin-bottom: 1rem;
            }
            .row-item-title {
                color: #000;
                font-family: "Open Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 19.6px */
                margin-bottom: 1rem;
            }
            &:last-child {
                margin-right: 40px;
            }
        }
        .column-container {
            display: flex;
            margin-top: 0.5rem;
            .column {
                flex: column;
                margin-right: 5px;
            }
        }
        .column-label-style {
            font-style: italic;
        }
    }
    .row-1 {
        justify-content: space-evenly !important;
        .row-item {
            min-height: 200px;
        }
    }
    .horizontal-divider {
        border-bottom: 4px solid #F0F3F6;
        width: 100%;
        margin: 16px 0px;
    }
    h5 {
        font-weight: bold;
    }
    .export-preview-custom-title {
        color: #000;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */
        margin-bottom: 1rem;
    }
    .column-title {
        color: #000;
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 12px */
        margin-bottom: 0.5rem;
    }
}
</style>

<template>
    <div class="brand-manager-filter-dropdown">
        <div v-if="showArrow">
            <ArrowPointer class="arrow-pointer-brand" :pointing-to="pointingTo" :is-animated="isAnimated" />
        </div>
        <filter-dropdown
            class="filter"
            :label="label"
            :multiple="multiple"
            :options="selectOptions"
            :show-placeholder="true"
            :count-selected="true"
            :combo-box="comboBox"
            :use-label-as-selected-text="true"
            :selected="selectedBrands"
            :show-caret-divider="false"
            :description="description"
            :highlight="highlight"
            :hide-search="hideSearch"
            :show-fa-close="true"
            @update:selected="handleSelect($event)"
        />
    </div>
</template>
<script>
import ArrowPointer from '@/components/app/ArrowPointer.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';

export default {
    components: {
        ArrowPointer,
        FilterDropdown,
    },
    props: {
        label: {
            type:    String,
            default: 'Brands',
        },
        multiple: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        comboBox: {
            type:    String,
            default: 'Brand',
        },
        description: {
            type:    String,
            default: 'selected',
        },
        selectOptions: {
            type:    Array,
            default: () => [],
        },
        selectedBrands: {
            type:    Array,
            default: () => [],
        },
        highlight: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        hideSearch: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    data() {
        return {
            pointingTo:         'right',
            isAnimated:         true,
            selectedBrandsData: [],
        };
    },
    computed: {
        showArrow() {
            return this.selectedBrands.length ? false : true;
        },
    },
    methods: {
        handleSelect(value) {
            this.$emit('selected-brands', { value });
        },
    },
};
</script>
<style lang="scss">
.brand-manager-filter-dropdown {
    display: flex;
    .purple-caret-down {
        padding-left: 7px;
        padding-right: 0 !important;
    }
    .form-control{
        font-size: 10px !important;
    }
    .filter-dropdown .btn-combobox {
       height: 31px !important;
    }
    div, button, span {
        opacity: 1;
        pointer-events: all;
        font-size: 10px;
    }
    .btn-combobox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 7px;
        color: #000;

        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        .btn-combobox-left-content.option-selected {
            height: 20px;
            border-style: solid;
            border-color: #DADADA;
            border-radius: 3px;
            background-color: #DADADA;
            margin-left: 5px;
            width: auto;
            color: #000;
            font-weight: normal;
            span {
                position: relative;
                bottom: 7px;
            }
            .fa-close {
                position: relative;
                bottom: 7px;
                left: 13px;
            }
        }
    }
    .arrow-pointer-brand {
            display: flex;
            margin: 10px 10px;
        }
    .filter {
        display: flex;
        width: 95%;
        .placeholder-text {
            font-weight: bold !important;
            color: #000 !important;
        }
        .filter-dropdown-menu {
            height: 315px;
            .filter-dropdown-options-list  ol  li .fa-check {
                border: 2px solid #0066FF;
                &.checked {
                    background-color:#0066FF;
                }
            }

        }
    }
}
</style>

export default () => ({
    assignments:                     [],
    dealerships:                     [],
    email:                           '',
    first_name:                      '',
    id:                              null,
    image_url:                       '',
    ip_address:                      '',
    is_admin:                        false,
    last_name:                       '',
    multi_dealership_switching_list: {},
    name:                            '',
    reseller_id:                     null,
    role_id:                         null,
    role_name:                       '',
    role_type:                       '',
    selectedDealership:              {},
    username:                        '',
    default_landing_page:            null,
    phone:                           '',
    isParentBrandManager:            false,
    viewAs:                          {},
    regions:                         [],
    hasTokenSet:                     null,
});

<template>
    <div class="pulsing-arrow-container">
        <div :class="arrowDirection">
            <div :class="{'pulsing-arrow-animation-active': isAnimated}">
                <img alt="arrow" src="/images/arrow-blue.svg">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:  'ArrowPointer',
    props: {
        pointingTo: {
            type:    String,
            default: 'right',
        },
        isAnimated: {
            type:    Boolean,
            default: true,
        },
    },
    computed: {
        arrowDirection() {
            if (this.pointingTo === 'left') {
                return 'arrow-point-to-left';
            }

            if (this.pointingTo === 'top') {
                return 'arrow-point-to-top';
            }

            if (this.pointingTo === 'bottom') {
                return 'arrow-point-to-bottom';
            }

            return 'arrow-point-to-right';
        },
    },
    mounted() {
        this.syncArrows();
    },
    methods: {
        syncArrows() {
            let myArrow = this.$el.querySelector('.pulsing-arrow-animation-active');
            if (!myArrow) {
                // Dont need to sync if we aren't showing
                return;
            }
            let firstArrow = Array.from(document.querySelectorAll('.pulsing-arrow-animation-active')).find(e => e != myArrow);
            if (!firstArrow) {
                // Nothing to sync with
                return;
            }

            let firstAnim = firstArrow.getAnimations()[0];
            let myAnim = myArrow.getAnimations()[0];

            if (!firstAnim || !myAnim) {
                // Some components are still in pre-render states. They'll sync up when the other components mount
                return;
            }

            if (firstAnim.startTime !== null) {
                myAnim.startTime = firstAnim.startTime;
            }
        },
    },
};
</script>

<style lang='scss'>
.pulsing-arrow-container{
    position: absolute;

        &.story-card-arrow {
            display: inline-block;
            left: 165px;
            height: 25px;
            position: relative;
            top: -13px;

            .arrow-point-to-bottom {
                display: inline-block;

                .pulsing-arrow-animation-active {
                    position: relative
                }
            }
        }

        .arrow-point-to-right {
            @keyframes pulsing {
                from {right: 5px}
                50% {right: 15px}
                to{right: 5px}
            }

            .pulsing-arrow-animation-active{
                position: absolute;
                animation: pulsing 1.8s infinite;
            }
        }

        .arrow-point-to-left {
            transform: rotate(180deg);
        }

        .arrow-point-to-top {
            transform: rotate(270deg);
        }

        .arrow-point-to-bottom {
            transform: rotate(90deg);

            @keyframes pulsingBottom {
                from {left: 5px}
                50% {left: 15px}
                to{left: 5px}
            }

            .pulsing-arrow-animation-active{
                position: absolute;
                animation: pulsingBottom 1.8s infinite;
            }
        }
}
</style>

export default {
    ACTIONS: {
        ADD_MISSING_PLATFORM_STORIES:                        'ADD_MISSING_PLATFORM_STORIES',
        PLATFORMS_SCHEDULED_POSTS_DISABLED:                  'PLATFORMS_SCHEDULED_POSTS_DISABLED',
        ADD_PLATFORM_STORY_ALL:                              'ADD_PLATFORM_STORY_ALL',
        AUTO_SELECT_MEDIA:                                   'AUTO_SELECT_MEDIA',
        AUTO_SELECT_PHOTOS:                                  'AUTO_SELECT_PHOTOS',
        AUTO_SELECT_VIDEOS:                                  'AUTO_SELECT_VIDEOS',
        CALCULATE_ALL_POPULATED_INPUTS:                      'CALCULATE_ALL_POPULATED_INPUTS',
        CALCULATE_DESTINATIONS_ARROWS:                       'CALCULATE_DESTINATIONS_ARROWS',
        CALCULATE_IS_FILLED_COMPLETELY:                      'CALCULATE_IS_FILLED_COMPLETELY',
        CALCULATE_POPULATED_INPUTS:                          'CALCULATE_POPULATED_INPUTS',
        CALCULATE_SELECTED_PLATFORM_DIFFERENCES:             'CALCULATE_SELECTED_PLATFORM_DIFFERENCES',
        CALCULATE_STORY_STATUS:                              'CALCULATE_STORY_STATUS',
        CALCULATE_UPDATE_ENABLED_PLATFORM_STORIES_POST_TYPE: 'CALCULATE_UPDATE_ENABLED_PLATFORM_STORIES_POST_TYPE',
        CLEAR_SELECTED_ASSET:                                'CLEAR_SELECTED_ASSET',
        CHECK_CHAR_LIMIT:                                    'CHECK_CHAR_LIMIT',
        CHECK_CHAR_MINIMUM:                                  'CHECK_CHAR_MINIMUM',
        CHECK_CHAR_RESTRICTIONS:                             'CHECK_CHAR_RESTRICTIONS',
        CHECK_CHAR:                                          'CHECK_CHAR',
        CHECK_ERRORS:                                        'CHECK_ERRORS',
        CHECK_MEDIA_ASPECT_RATIO:                            'CHECK_MEDIA_ASPECT_RATIO',
        CHECK_MEDIA_FORMAT:                                  'CHECK_MEDIA_FORMAT',
        CHECK_MEDIA_LIMIT:                                   'CHECK_MEDIA_LIMIT',
        CHECK_MEDIA_RESOLUTION:                              'CHECK_MEDIA_RESOLUTION',
        CHECK_MEDIA_SIZE:                                    'CHECK_MEDIA_SIZE',
        CHECK_MEDIA_BITRATE:                                 'CHECK_MEDIA_BITRATE',
        CHECK_MEDIA_FRAMERATE:                               'CHECK_MEDIA_FRAMERATE',
        CHECK_MEDIA:                                         'CHECK_MEDIA',
        CHECK_MINIMUM_MEDIA_LIMITS:                          'CHECK_MINIMUM_MEDIA_LIMITS',
        CHECK_MIXED_MEDIA:                                   'CHECK_MIXED_MEDIA',
        CHECK_PLATFORM_HAS_MODIFIED_MEDIA:                   'CHECK_PLATFORM_HAS_MODIFIED_MEDIA',
        CHECK_VIDEO_CODECS:                                  'CHECK_VIDEO_CODECS',
        CHECK_VIDEO_DURATION:                                'CHECK_VIDEO_DURATION',
        CLEAR_ERRORS:                                        'CLEAR_ERRORS',
        CLEAR_PLATFORMS_SELECTED_ASSETS:                     'CLEAR_PLATFORMS_SELECTED_ASSETS',
        FILTER_SELECTED_ASSETS_FROM_POST_TYPE:               'FILTER_SELECTED_ASSETS_FROM_POST_TYPE',
        INITIALIZE_STORY:                                    'INITIALIZE_STORY',
        INITIALIZE_STORY_ENABLED_BY_USER:                    'INITIALIZE_STORY_ENABLED_BY_USER',
        LOAD_CAMPAIGNS_OPTIONS:                              'LOAD_CAMPAIGNS_OPTIONS',
        LOAD_CAMPAIGNS_PLATFORMS:                            'LOAD_CAMPAIGNS_PLATFORMS',
        LOAD_CLASSIFIERS_AND_POOL_IDS:                       'LOAD_CLASSIFIERS_AND_POOL_IDS',
        LOAD_HISTORY:                                        'LOAD_HISTORY',
        LOAD_INITIAL_STORY_STATUS:                           'LOAD_INITIAL_STORY_STATUS',
        LOAD_LANGUAGES:                                      'LOAD_LANGUAGES',
        LOAD_DYNAMIC_LENGTH:                                 'LOAD_DYNAMIC_LENGTH',
        LOAD_NOTES_UNREAD:                                   'LOAD_NOTES_UNREAD',
        LOAD_PLATFORM_STORIES_CONFIGS:                       'LOAD_PLATFORM_STORIES_CONFIGS',
        LOAD_PLATFORM_STORIES_ERRORS:                        'LOAD_PLATFORM_STORIES_ERRORS',
        LOAD_PLATFORM:                                       'LOAD_PLATFORM',
        LOAD_POPULATED_INPUTS:                               'LOAD_POPULATED_INPUTS',
        LOAD_REGIONS:                                        'LOAD_REGIONS',
        LOAD_RETAILERS:                                      'LOAD_RETAILERS',
        LOAD_OEM_MANAGER:                                    'LOAD_OEM_MANAGER',
        LOAD_OEM_MANAGER_SETTINGS:                           'LOAD_OEM_MANAGER_SETTINGS',
        LOAD_SELECTED_LANGUAGES:                             'LOAD_SELECTED_LANGUAGES',
        LOAD_SELECTED_PLATFORM:                              'LOAD_SELECTED_PLATFORM',
        LOAD_STORY:                                          'LOAD_STORY',
        PRE_LOAD_STORY:                                      'PRE_LOAD_STORY',
        SYNCHRONIZE_PLATFORM_STORIES:                        'SYNCHRONIZE_PLATFORM_STORIES',
        UPDATE_CLASSIFIERS_BASED_ON_REMOVED_BRAND_MANAGER:   'UPDATE_CLASSIFIERS_BASED_ON_REMOVED_BRAND_MANAGER',
        UPDATE_PLATFORM_STORY_ENABLE:                        'UPDATE_PLATFORM_STORY_ENABLE',
        UPDATE_PLATFORM_STORY_OPTIONS_GBP_CTA:               'UPDATE_PLATFORM_STORY_OPTIONS_GBP_CTA',
        UPDATE_PLATFORM_STORY_SELECTED_ASSETS:               'UPDATE_PLATFORM_STORY_SELECTED_ASSETS',
        UPDATE_STORY_BASED_ON_STORY_STATUS:                  'UPDATE_STORY_BASED_ON_STORY_STATUS',
        UPDATE_STORIES_ENABLED_BY_USER:                      'UPDATE_STORIES_ENABLED_BY_USER',
        LOAD_ASSETS_FOR_URL:                                 'LOAD_ASSETS_FOR_URL',
    },
    MUTATIONS: {
        ADD_CAMPAIGN_OPTIONS:                                    'ADD_CAMPAIGN_OPTIONS',
        ADD_PLATFORM_STORY:                                      'ADD_PLATFORM_STORY',
        PLATFORM_CAMPAIGN_DISABLED:                              'PLATFORM_CAMPAIGN',
        ADD_POPULATED_INPUTS:                                    'ADD_POPULATED_INPUTS',
        CLEAN_STORY_FOR_SELECTED_POST_TYPE:                      'CLEAN_STORY_FOR_SELECTED_POST_TYPE',
        CLEAN_ALL_PLATFORMS_MENTIONS:                            'CLEAN_ALL_PLATFORMS_MENTIONS',
        CLEAR_SELECTED_ASSET:                                    'CLEAR_SELECTED_ASSET',
        INITIALIZE_ERRORS:                                       'INITIALIZE_ERRORS',
        PARTIAL_STORY_UPDATE:                                    'PARTIAL_STORY_UPDATE',
        PARTIAL_UPDATE:                                          'PARTIAL_UPDATE',
        SET_APPROVAL:                                            'SET_APPROVAL',
        SET_CAMPAIGNS:                                           'SET_CAMPAIGNS',
        SET_CAMPAIGN_INITIAL_ID:                                 'SET_CAMPAIGN_INITIAL_ID',
        SET_ERRORS:                                              'SET_ERRORS',
        SET_INPUT_ERRORS:                                        'SET_INPUT_ERRORS',
        SHOW_CONTENT_INFO_ERRORS:                                'SHOW_CONTENT_INFO_ERRORS',
        TRIED_TO_SAVE:                                           'TRIED_TO_SAVE',
        UPDATE_ENABLED_PLATFORM_STORIES_FROM_SELECTED_POST_TYPE: 'UPDATE_ENABLED_PLATFORM_STORIES_FROM_SELECTED_POST_TYPE',
        UPDATE_PLATFORM_STORY:                                   'UPDATE_PLATFORM_STORY',
        UPDATE_PLATFORM_STORY_SELECTED_ASSETS:                   'UPDATE_PLATFORM_STORY_SELECTED_ASSETS',
        UPDATE_POPULATED_INPUT:                                  'UPDATE_POPULATED_INPUT',
        UPDATE_PLATFORM_TO_KEEP_CONTENT:                         'UPDATE_PLATFORM_TO_KEEP_CONTENT',
        UPDATE_SELECTED_PLATFORM_STORY_CHANGES:                  'UPDATE_SELECTED_PLATFORM_STORY_CHANGES',
        UPDATE_ASSETS_FOR_URL:                                   'UPDATE_ASSETS_FOR_URL',
        FIX_ENABLED_ASSETS:                                      'FIX_ENABLED_ASSETS',
    },
};

import actions from '@/stores/liveConnect/actions.js';
import getters from '@/stores/liveConnect/getters.js';
import state from '@/stores/liveConnect/state.js';
import { defineStore } from 'pinia';

export default defineStore({
    id:      'liveConnect',
    state:   state,
    getters: getters,
    actions: actions,
});

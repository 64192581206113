<template>
    <div class="v-phone">
        <input v-if="browserName === 'firefox' || browserName === 'safari'" tabindex="0" class="v-phone-input" @focus="getPhoneNumberInput">
        <maz-phone-number-input
            ref="mazPhoneNumberInput"
            :model-value="number"
            :disabled="disabled"
            :translations="{
                countrySelector: {
                  placeholder: 'Country code',
                  searchPlaceholder: 'Search country',
                },
                phoneInput: {
                  placeholder: 'Phone number',
                },
              }"
            no-example
            no-validation-error
            no-validation-success
            :no-country-selector="noFlags"
            :preferred-countries="['US']"
            :size="small ? 'sm' : null"
            tabindex="0"
            @update="handleUpdate"
        >
            <template v-if="phoneIcon" #icon-left>
                <i class="fa fa-phone" />
            </template>
        </maz-phone-number-input>
    </div>
</template>


<script>
import BrowserName from '@/browser_name.js';
import { default as MazPhoneNumberInput } from 'maz-ui/components/MazPhoneNumberInput.mjs';
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import 'maz-ui/css/main.css';

export default {
    components: {
        MazPhoneNumberInput,
    },
    props: {
        number: {
            type: String,
            required: false,
            default: '',
        },
        allowInvalid: {
            type: Boolean,
            required: false,
            default: false,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        noFlags: {
            type: Boolean,
            required: false,
            default: false,
        },
        phoneIcon: {
            type: Boolean,
            required: false,
            default: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        name: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            browserName: '',
        };
    },
    mounted() {
        polyfillCountryFlagEmojis();
        this.browserName = BrowserName;
    },
    methods: {
        async handleUpdate(event) {
            // Needed for refs to be valid while the mazPhoneNumberInput is still mounting
            await this.$nextTick();

            if ((event.isValid || this.allowInvalid) && this.isRealNumber(event)) {
                if (this.shouldFormat(event)) {
                    // We need to do this to normalize the display. Unfortunately, updating the default-phone-number
                    // prop to another number that resolves to the same uri won't update the component.
                    this.$refs.mazPhoneNumberInput.asYouTypeNumber = event.formatNational;
                }
                this.$emit('update:isValid', true);

            } else {
                this.$emit('update:isValid', false);
            }

            let number = event.countryCode == 'US' ? event.formatNational : event.formatInternational; //

            this.$emit('update:number', number);
            this.$emit('v-phone-updated', event);
        },
        isRealNumber(event) {
            if (!event.nationalNumber || !event.phoneNumber) {
                return false;
            }

            // This keeps short numbers from being reevaluated with their country code to form a new
            // incorrect full length number.
            let strippedNationalNumber = event.nationalNumber.replaceAll(/[^0-9]/g, '');
            let strippedPhoneNumber = event.phoneNumber.replaceAll(/[^0-9]/g, '');
            let doubleStrippedPhone = strippedPhoneNumber.replace(event.countryCallingCode, '');

            return parseInt(strippedNationalNumber) == parseInt(strippedPhoneNumber)
                || parseInt(strippedNationalNumber) == parseInt(doubleStrippedPhone);
        },
        shouldFormat(event) {
            return event.formatNational != event.phoneNumber;
        },
        getPhoneNumberInput() {
            return this.$refs.mazPhoneNumberInput.$children[1].$refs.MazInput;
        },
    },
};
</script>

<style lang="scss">
:root {
    --maz-base-line-height: 1.428571429;
    --maz-color-bg: #fff;
    --maz-border-color: #ccc;
    --maz-overlay-color: rgba(86, 87, 117, 0.7);
    --maz-border-radius: 4px;
    --maz-border-width: 1px;
    --maz-text-color: #555555;
    --maz-base-font-size: 10px;
}
.v-phone {
    width: 100%;
    * {
        font-size: 16px;
        line-height: 20px;
    }

    .v-phone-input {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
    }

    .m-phone-number-input {
        width: 100%;
    }

    .maz-base-component {
        #MazInput-17 {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        #MazInput-20 {
            &:focus {
                border: 1px solid #eee;
            }
        }
        #MazInput-22 {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .maz-select__options-list {
            margin-top: 5px;
            border: 1px solid #eee;
            border-radius: 5px;
            padding: 5px;
        }
        .maz-select__options-list__item {
            border-radius: 5px;
        }

        .maz-select--primary .maz-select__options-list__item.selected {
            background-color: #eee;
        }
        .maz-text-color {
            color: #333333 !important;
        }
    }

    .m-input-label {
        color: #a9a9ac
    }

    .m-country-selector {
        font-family: "Twemoji Country Flags", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        flex: 0 0 7em;
        max-width: 7em;
        min-width: 7em;
        width: 7em;

        .m-country-selector__select {
            width: 7em !important;
        }

        .m-country-selector__country-flag {
            background: transparent;
            left: 10px;
            top: 5px;
        }

        .m-input-input {
            margin-left: 10px;
        }

        .m-input-label {
            display: none;
        }
    }
    .--should-up {
        .m-input-label {
            transform: scale(0) translateY(-0.65em) !important;
        }
    }
    .m-input.--has-label {
        .m-input-input {
            padding-bottom: 10px !important;
        }
    }

    .maz-input {
        height: 34px;
        min-height: 34px;
        margin: 0 0 5px 0;
        border: 1px solid #eee;
    }
    .maz-input__label {
        display: none;
    }
    .maz-input__input {
        padding-right: 0 !important;
        padding-top: 0 !important;
    }
    .maz-phone-number-input__country-flag {
        bottom: 0.7rem;
    }
    .maz-phone-number-input--sm {
        .maz-input {
            height: 28px;
            min-height: 28px;
        }
        .maz-phone-number-input__country-flag {
            bottom: 0.4rem;
        }
    }
}
</style>

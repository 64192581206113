import axios from '@/axios';

export default {
    async initialize() {
        if (this.roles.length > 0) {
            return this.roles;
        }
        const { data } = await axios.get('/api/roles?raw=1');
        this.$patch({ roles: data });
        return data;
    },
    setViewMode({ payload }) {
        if (payload.length === 0 && Object.keys(payload).length === 0){
            this.$patch({ viewMode: false });
        } else {
            this.$patch({ viewMode: true });
        }
    },
};

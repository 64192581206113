<template>
    <div class="live-connect" :class="{'hide-footer-buttons': hideFooterButtons}">
        <div class="localize-container">
            <localize-wrapper class="localize-widget-wrapper" />
        </div>
        <template v-if="loading">
            <ts-loading-spinner class="main-ts-loading-spinner" />
        </template>
        <template v-else>
            <template v-if="openFbModal">
                <popup-modal modal-size="md" :hide-yes-button="true" :display-no-text="'Close'" @close="handleFBModalClose(false)" @on-no-button-click="handleFBModalClose(false)">
                    <template #icon>
                        <circle-info-icon :scale="1.3" />
                    </template>
                    <template #header>
                        <span class="popup-header">Learn More</span>
                    </template>
                    <template #content>
                        <div class="popup-content">
                            <span class="content-title">How to Connect your Facebook Business Page:</span>
                            <br>
                            <br>
                            <ol>
                                <li>Click blue 'Connect' button</li>
                                <li>Login with your personal Facebook credentials</li>
                                <ol type="a">
                                    <li>Completing this action is not linking your personal account, it is only confirming admin status. Your private data is never stored or shared.</li>
                                </ol>
                                <li>Click 'Continue as' button</li>
                                <li>Select one of the 'Opt In' options for desired FB Businesses and click 'Continue'</li>
                                <li>Select one of the 'Opt In' options for desired FB Pages and click 'Continue'</li>
                                <li>Select one of the 'Opt In' options for desired Instagram Accounts and click 'Continue', if applicable</li>
                                <li>Click 'Save' on next prompt</li>
                                <li>Click 'Got it', you will be brought back to ThumbStopper's page</li>
                                <li>Select the Business Page from the dropdown you want to connect</li>
                            </ol>
                        </div>
                    </template>
                </popup-modal>
            </template>
            <connect-layout
                :step="pageSteps[currentPage]"
                :step-count="pageStepCount"
                :hide-footer-buttons="hideFooterButtons"
                :edit="true"
                :current-page="currentPage"
                :block-next-button="blockNextButton"
                @next="next"
                @back="back"
            >
                <component :is="currentPage" ref="page" :slug="connectSlug" :edit="true" :dev="dev" @next="next" @back="back" />
            </connect-layout>
        </template>
    </div>
</template>

<script>
import LocalizeWrapper from '@/components/app/LocalizeWrapper.vue';
import PopupModal from '@/components/app/PopupModal.vue';
import TsLoadingSpinner from '@/components/app/TsLoadingSpinner.vue';
import BusinessNamePage from '@/components/connects/BusinessNamePage.vue';
import ConnectLayout from '@/components/connects/ConnectLayout.vue';
import ContactInfoPage from '@/components/connects/ContactInfoPage.vue';
import CreditCardPage from '@/components/connects/CreditCardPage.vue';
import DestinationLoginsPage from '@/components/connects/DestinationLoginsPage.vue';
import ProductSelectionPage from '@/components/connects/ProductSelectionPage.vue';
import ThankYouPage from '@/components/connects/ThankYouPage.vue';
import WelcomePage from '@/components/connects/WelcomePage.vue';
import CircleInfoIcon from '@/components/icons/CircleInfoIcon.vue';
import { connectStore, liveConnectStore } from '@/stores/pinia.js';
import { mapStores } from 'pinia';

export default {
    components: {
        BusinessNamePage,
        CircleInfoIcon,
        ConnectLayout,
        ContactInfoPage,
        CreditCardPage,
        DestinationLoginsPage,
        LocalizeWrapper,
        PopupModal,
        ProductSelectionPage,
        ThankYouPage,
        TsLoadingSpinner,
        WelcomePage,
    },
    props: {
        oemManagerId: {
            type:     Number,
            required: true,
        },
        resellerId: {
            type:     Number,
            required: true,
        },
        poolId: {
            type:     Number,
            required: true,
        },
        connectId: {
            type:     Number,
            required: true,
        },
        connectSlug: {
            type:     String,
            required: true,
        },
        dev: {
            type:     Boolean,
            required: true,
        },
    },
    data() {
        return {
            currentPage: 'WelcomePage',
            loading:     false,
        };
    },
    computed: {
        hideFooterButtons() {
            return this.currentPage == 'WelcomePage' || this.currentPage == 'ThankYouPage';
        },
        pageSteps() {
            let pageSteps = {};
            let step = -1;
            this.pages.forEach(page => {
                let key = page[0].toLowerCase() + page.slice(1);
                pageSteps[page] = step;
                if (this.connectStore[key].enabled) {
                    step++;
                }
            });
            return pageSteps;
        },
        pageStepCount() {
            return Math.max(...Object.values(this.pageSteps)) + 1;
        },
        hubSpotFormName() {
            if (this.connectSlug) {
                return `${this.connectSlug}_contactform`;
            }
            return `reseller_id_${this.resellerId}_contactform`;
        },
        pages() {
            let pages = [
                'WelcomePage',
                'BusinessNamePage',
                'ContactInfoPage',
                'ProductSelectionPage',
                'DestinationLoginsPage',
                'CreditCardPage',
                'ThankYouPage',
            ];

            return pages.filter(page => {
                let camelCase = page[0].toLowerCase() + page.slice(1);

                return this.connectStore[camelCase].enabled;
            });
        },
        openFbModal() {
            return this.liveConnectStore.openFBModal;
        },
        ...mapStores(connectStore, liveConnectStore),
        blockNextButton() {
            if (this.currentPage == 'ProductSelectionPage') {
                return !this.liveConnectStore.classifiers.filter(c => !c.hidden).length;
            }
            if (this.currentPage == 'BusinessNamePage' && !this.liveConnectStore.potentialId ) {
                return true;
            }

            return false;
        },
    },
    mounted() {
        this.loadConnect().then(() => {
            this.loading = false;
            // This is a few pages away so it's fine to stop the loading spinner
            this.liveConnectStore.oemManagerId = this.oemManagerId;
            this.liveConnectStore.loadRegionOptions(this.poolId);
            this.liveConnectStore.loadLanguageOptions();
        });
    },
    methods: {
        back() {
            this.$el.classList.remove('highlight-invalid');
            let currentPageIndex = this.pages.indexOf(this.currentPage);
            this.currentPage = this.pages[currentPageIndex - 1];
        },
        loadConnect() {
            this.loading = true;
            return this.connectStore.loadData(this.oemManagerId, this.connectId).then(() => {
                this.liveConnectStore.connectId = this.connectId;
                this.loading = false;
            });
        },
        async next() {
            if (this.$refs.page.formValid) {
                if (this.currentPage === 'ContactInfoPage') {
                    await this.$refs.page.submitHubspotForm();
                }
                this.$el.classList.remove('highlight-invalid');
                let currentPageIndex = this.pages.indexOf(this.currentPage);
                this.currentPage = this.pages[currentPageIndex + 1];
                await this.liveConnectStore.savePotential(this.currentPage == 'ThankYouPage');
            } else if (this.$refs.page.handleValidationError) {
                this.$el.classList.add('highlight-invalid');
                this.$refs.page.handleValidationError();
            } else {
                this.$el.classList.add('highlight-invalid');
            }
        },
        handleFBModalClose(status) {
            this.liveConnectStore.openFBModal = status;
        },
    },

};
</script>

<style lang="scss">
@media(min-width: 950px) {
    .live-connect .destination-logins .fb-modal .modal-content {
        height: 510px;
        width: 510px;
    }

    .live-connect {
        .localize-container {
            display: flex;
            flex-direction: row-reverse;
            .localize-widget-wrapper{
                top: 0px;
                right: 50%;
                z-index: 3;
            }
        }

        .main-ts-loading-spinner {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &.highlight-invalid {
            input[type="checkbox"]:invalid::before {
                color: red;
                content: 'You must agree to the terms to continue.';
                position: relative;
                top: -25px;
                white-space: nowrap;
            }

            *:invalid:not(.fields-form) {
                border: 2px solid red;

                & ~ .label::after {
                    content: 'This field is required';
                    color: red;
                    position: absolute;
                    right: 0;
                }
            }

            .invalid-label::after {
                content: 'This field is required';
                color: red;
                position: absolute;
                right: 0;
            }

            .invalid-phone {
                border: 2px solid red;
                border-radius: 4px;
            }

            .invalid-email {
                border: 2px solid red;
            }

            .invalid-region {
                .filter-dropdown {
                    border: 2px solid red;
                }
            }

            .invalid-language {
                .filter-dropdown {
                    border: 2px solid red;
                }
            }

            .invalid-stripe {
                border: 2px solid red;
            }
        }

        .valid, .invalid {
            border: none !important;
            pointer-events: none;

            &::after {
                border: none !important;
                pointer-events: none;
            }

            img {
                border: none !important;
                pointer-events: none;
            }
        }

        .connect-textarea {
            border: none !important;
            pointer-events: none;

            textarea {
                pointer-events: none;
            }

            .character-limit {
                display: none;
            }
        }

        .edit-only {
            display: none !important;
        }

        .disabled-platform {
            display: none;
        }

        .platform-active {
            color: white;
            pointer-events: none;
        }
    }
}

@media(max-width: 950px) {
    .live-connect .destination-logins .fb-modal .modal-content {
        height: 510px;
        width: 510px;
    }

    .live-connect {
        .main-ts-loading-spinner {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .modal-md {
            margin: 200px auto;
        }

        .popup-modal-body-container {
            .popup-header {
                font-style: normal;
                font-weight: 900;
                font-size: 16px;
                line-height: 140%;
                left: -45px;
                position: inherit;
            }

            .popup-content {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;
                text-align: left;
                position: absolute;
                left: 40px;
                margin-right: 50px;

                .content-title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 140%;
                    margin-left: 75px;
                }
            }

            .footer {
                top: 288px;
                position: relative;
            }

            .m-t-5 {
                margin-top: 3rem;
            }
            .m-t-1 {
                margin-top: 0rem;
            }
        }

        &.highlight-invalid {
            input[type="checkbox"]:invalid::before {
                color: red;
                content: 'You must agree to the terms to continue.';
                position: relative;
                top: -25px;
                white-space: nowrap;
            }

            *:invalid:not(.fields-form) {
                border: 2px solid red;

                & ~ .label::after {
                    content: 'This field is required';
                    color: red;
                    padding-left: 10px;
                }
            }

            .invalid-label::after {
                content: 'This field is required';
                color: red;
                padding-left: 10px;
            }

            .invalid-phone {
                .maz-border {
                    border: 2px solid red;
                }
            }

            .invalid-email {
                border: 2px solid red;
            }

            .invalid-stripe {
                border: 2px solid red;
            }
        }
        .valid, .invalid {
            border: none !important;
            pointer-events: none;

            &::after {
                border: none !important;
                pointer-events: none;
            }

            img {
                border: none !important;
                pointer-events: none;
            }
        }

        .connect-textarea {
            border: none !important;
            pointer-events: none;

            textarea {
                pointer-events: none;
            }
            .character-limit {
                display: none;
            }
        }
        .edit-only {
            display: none !important;
        }

        .disabled-platform {
            display: none;
        }

        .platform-active {
            color: white;
            pointer-events: none;
        }
    }
}
</style>

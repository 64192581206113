<template>
    <div class="credit-card-page">
        <h1 class="header">Credit Card Info</h1>
        <div class="credit-field" :class="{'col-xs-6' : !isMobile} ">
            <label class="label" :class="{'invalid-label': !liveConnectStore.stripeToken}">Card Information</label>
            <stripe-control
                ref="stripeControl"
                class="live-only"
                :class="{'invalid-stripe': !liveConnectStore.stripeToken}"
                @update:token="handleUpdateToken"
            />
        </div>
        <div class="credit-field" :class="{'col-xs-6' : !isMobile} ">
            <label class="label">Name on Card</label>
            <input v-model="liveConnectStore.creditCardName" class="form-control live-only" type="text" required>
        </div>
        <div class="credit-field" :class="{'col-xs-6' : !isMobile} ">
            <label class="label">Billing Address</label>
            <input v-model="liveConnectStore.creditCardAddress" class="form-control live-only" type="text" required>
        </div>
        <div class="credit-field" :class="{'col-xs-6' : !isMobile} ">
            <label class="label">ZIP Code</label>
            <input v-model="liveConnectStore.creditCardZip" class="form-control live-only" type="text" required>
        </div>
        <div class="terms-container">
            <input v-model="liveConnectStore.creditTerms" type="checkbox" class="terms-checkbox live-only" required>
            <connect-textarea
                name="credit-terms"
                class="terms"
                :limit="400"
                :content="connectStore.creditCardPage.terms"
                :valid="termsValid"
                :focusable="!edit"
                :edit="edit"
                align-center
                @update:content="updateTerms"
                @commit="commitTerms"
            />
        </div>
    </div>
</template>

<script>
import StripeControl from '@/components/app/StripeControl.vue';
import ConnectTextarea from '@/components/connects/ConnectTextarea.vue';
import DefaultConnectState from '@/stores/connect/state.js';
import { connectStore, liveConnectStore } from '@/stores/pinia.js';
import { waitForLoad } from '@/waitForLoad.js';
import { mapStores } from 'pinia';

const stripeLoaded = waitForLoad(window, 'Stripe');

export default {
    components: {
        ConnectTextarea,
        StripeControl,
    },
    props: {
        edit: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    computed: {
        formValid() {
            return this.liveConnectStore.stripeToken
                && this.liveConnectStore.creditCardName
                && this.liveConnectStore.creditCardAddress
                && this.liveConnectStore.creditCardZip
                && this.liveConnectStore.creditTerms;
        },
        termsValid() {
            return this.connectStore.creditCardPage.terms != DefaultConnectState().creditCardPage.terms;
        },
        isMobile() {
            return screen.width <= 600;
        },
        ...mapStores(connectStore, liveConnectStore),
    },
    async mounted() {
        await stripeLoaded;
    },
    methods: {
        commitTerms(value) {
            this.connectStore.$patch({ creditCardPage: { terms: value }});
        },
        handleUpdateToken(token) {
            this.liveConnectStore.stripeToken = token;
        },
        updateTerms(value) {
            this.connectStore.creditCardPage.termsUpdated = true;
            this.connectStore.creditCardPage.terms = value;
        },
    },
};
</script>

<style lang="scss">
@media(min-width: 950px) {
    .credit-card-page {
        height: 100%;

        .header {
            font-weight: bold;
            left: 60px;
            position: relative;
        }

        .terms-container {
            display: flex;
            align-items: flex-start;
            width: 100%;
            height: 130px;
            margin-top: 20px;
            position: relative;
            top: 40px;
        }

        .credit-field {
            margin-top: 20px;

            & + .credit-field {
                margin-bottom: 4px;
            }

            .stripe-control {
                min-width: 528px;
                position: relative;
                top: 17px;

                & ~ .label {
                    top: -43px;
                }
            }

            &:nth-of-type(-n+2) {
                margin-top: 0;
            }

            .label {
                color: #85878f;
                display: block;
                font-size: 14px;
                left: -8px;
                position: relative;
                text-align: left;
                text-transform: uppercase;
                top: 20px;
            }

            .form-control {
                border-color: #ebebed;
                height: 42px;
                position: relative;
                top: 20px;
            }

            &:nth-of-type(2n+1) {
                padding-left: 60px;
                padding-right: 38px;
            }

            &:nth-of-type(2n) {
                padding-left: 38px;
                padding-right: 60px;
            }


        }

        .terms {
            display: flex;
            align-items: center;
            bottom: 6px;
            font-size: 12px;
            height: 46px;
            width: 1136px;
            left: 85px;
            position: relative;
            top: 33px;
        }

        .terms-checkbox {
            bottom: 23px;
            left: 80px;
            position: relative;
            top: 40px;
        }
    }
}

@media(max-width: 950px) {
    .credit-card-page {
        height: 100%;

        .terms-container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
            height: 130px;
            margin-top: 20px;
        }

        .header {
            font-weight: bold;
            margin-top: 10rem;
        }

        .credit-field {
            margin-top: 20px;

            & + .credit-field {
                margin-bottom: 4px;
            }

            .stripe-control {
                width: 100%;
                top: 17px;

                & ~ .label {
                    top: -43px;
                }
            }

            .label {
                color: #85878f;
                display: block;
                font-size: 14px;
                text-align: left;
                text-transform: uppercase;
                top: -40px;
            }

            .form-control {
                border-color: #ebebed;
                height: 42px;
                top: 20px;
            }


        }

        .terms {
            display: flex;
            align-items: center;
            font-size: 12px;
            width: 100%;
            height: 100%;

            textarea {
                display: block;
                overflow: scroll;
                height: 100% !important;
            }
        }

        .terms-checkbox {
            bottom: 23px;
        }
    }
}
</style>

<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="brands-menu-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.75 6.375C9.75 5.33906 10.5891 4.5 11.625 4.5C12.6609 4.5 13.5 5.33906 13.5 6.375C13.5 7.41094 12.6609 8.25 11.625 8.25C10.5891 8.25 9.75 7.41094 9.75 6.375ZM12.818 9C14.0227 9 15 9.97734 15 11.182C15 11.6344 14.6344 12 14.182 12H9.06797C8.61562 12 8.25 11.6344 8.25 11.182C8.25 9.97734 9.22734 9 10.432 9H12.818Z" :fill="fillColorPerson" />
                <path d="M0 1.125C0 0.503672 0.503672 0 1.125 0H7.875C8.49609 0 9 0.503672 9 1.125V8.62031C8.10469 9.12187 7.5 10.0781 7.5 11.1773C7.5 11.4797 7.58437 11.7609 7.73203 12H5.625V10.125C5.625 9.50391 5.12109 9 4.5 9C3.87891 9 3.375 9.50391 3.375 10.125V12H1.125C0.503672 12 0 11.4961 0 10.875V1.125ZM1.875 5.25C1.66781 5.25 1.5 5.41875 1.5 5.625V6.375C1.5 6.58125 1.66781 6.75 1.875 6.75H2.625C2.83125 6.75 3 6.58125 3 6.375V5.625C3 5.41875 2.83125 5.25 2.625 5.25H1.875ZM3.75 6.375C3.75 6.58125 3.91875 6.75 4.125 6.75H4.875C5.08125 6.75 5.25 6.58125 5.25 6.375V5.625C5.25 5.41875 5.08125 5.25 4.875 5.25H4.125C3.91875 5.25 3.75 5.41875 3.75 5.625V6.375ZM6.375 5.25C6.16875 5.25 6 5.41875 6 5.625V6.375C6 6.58125 6.16875 6.75 6.375 6.75H7.125C7.33125 6.75 7.5 6.58125 7.5 6.375V5.625C7.5 5.41875 7.33125 5.25 7.125 5.25H6.375ZM1.5 3.375C1.5 3.58125 1.66781 3.75 1.875 3.75H2.625C2.83125 3.75 3 3.58125 3 3.375V2.625C3 2.41875 2.83125 2.25 2.625 2.25H1.875C1.66781 2.25 1.5 2.41875 1.5 2.625V3.375ZM4.125 2.25C3.91875 2.25 3.75 2.41875 3.75 2.625V3.375C3.75 3.58125 3.91875 3.75 4.125 3.75H4.875C5.08125 3.75 5.25 3.58125 5.25 3.375V2.625C5.25 2.41875 5.08125 2.25 4.875 2.25H4.125ZM6 3.375C6 3.58125 6.16875 3.75 6.375 3.75H7.125C7.33125 3.75 7.5 3.58125 7.5 3.375V2.625C7.5 2.41875 7.33125 2.25 7.125 2.25H6.375C6.16875 2.25 6 2.41875 6 2.625V3.375Z" :fill="fillColorBuilding" />
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'BrandsMenuIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
    props:      {
        fillColorBuilding: {
            type:     String,
            required: false,
            default:  '#85878F',
        },
        fillColorPerson: {
            type:     String,
            required: false,
            default:  '#DEE0E2',
        },
    },
};

</script>
<style lang='scss'>
.brands-menu-icon-container {}
</style>

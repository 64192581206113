export const DEFAULT_STORY_OPTIONS = {
    // TODO: Avoid having to use this to calculate the changes in the brand managers.
    brandTierListFromTipsLoaded:             false,
    // buffers: Used to reference classifiers in tiers.js. Only takes into consideration
    // the buffers created on the BrandTierListFromTips component
    buffers:                                 [],
    calculateUpdateEnablePlatform:           [],
    checkedAssetsOnLoad:                     false,
    currentOemManager:                       {},
    destinationsWithArrows:                  {},
    enabledPlatformStoriesSelectedByUser:    [],
    enabledPlatforms:                        [],
    history:                                 [],
    initialCampaignId:                       null,
    initialDataSnapshot:                     {},
    inputError:                              false,
    isFilledCompletelyAllPlatform:           false,
    isLoadingEditPhoto:                      false,
    isLoadingRetailersOptions:               false,
    isNewStory:                              null,
    isOriginalState:                         true,
    isRetailer:                              false,
    isSavingDuplicate:                       false,
    latestSelectedPlatform:                  null,
    loading:                                 false,
    notes:                                   [],
    oemIsParent:                             false,
    oemManagers:                             [], // Used for BrandTierList
    platformFacebook:                        false,
    platformStoriesConfigs:                  {},
    platformStoriesErrors:                   {},
    platformTwitter:                         false,
    populatedInputs:                         [],
    populatedInputsForSelectedPlatformStory: {},
    retailersOptions:                        [],
    oemManagerSettings:                      [],
    selectedBuffers:                         [],
    selectedByRetailers:                     [],
    selectedParents:                         [[], [], []],
    selectedPlatform:                        {},
    selectedPlatformStory:                   null,
    selectedPlatformStoryIsNotModified:      true,
    selectedStoryId:                         null,
    selectedTips:                            [],
    showAssetError:                          false,
    showTextareaError:                       false,
    statusOptions:                           [
        {text: 'Ready For Approval'},
        {text: 'Approved For Distribution'},
        {text: 'Denied'},
        {text: 'Expired'},
        {text: 'Archived'},
        {text: 'Pending Curation'},
        {text: 'Info Required'},
    ],
    storyStatus: '',
    timeOptions: [
        { id: 1, name: '12:00', second_name: '02:00', value: 0, meridiem: 'AM', second_meridiem: 'AM' },
        { id: 2, name: '02:00', second_name: '04:00', value: 2, meridiem: 'AM', second_meridiem: 'AM' },
        { id: 3, name: '04:00', second_name: '06:00', value: 4, meridiem: 'AM', second_meridiem: 'AM' },
        { id: 4, name: '06:00', second_name: '08:00', value: 6, meridiem: 'AM', second_meridiem: 'AM' },
        { id: 5, name: '08:00', second_name: '10:00', value: 8, meridiem: 'AM', second_meridiem: 'AM' },
        { id: 6, name: '10:00', second_name: '12:00', value: 10, meridiem: 'AM', second_meridiem: 'PM' },
        { id: 7, name: '12:00', second_name: '02:00', value: 12, meridiem: 'PM', second_meridiem: 'PM' },
        { id: 8, name: '02:00', second_name: '04:00', value: 14, meridiem: 'PM', second_meridiem: 'PM' },
        { id: 9, name: '04:00', second_name: '06:00', value: 16, meridiem: 'PM', second_meridiem: 'PM' },
        { id: 10, name: '06:00', second_name: '08:00', value: 18, meridiem: 'PM', second_meridiem: 'PM' },
        { id: 11, name: '08:00', second_name: '10:00', value: 20, meridiem: 'PM', second_meridiem: 'PM' },
        { id: 12, name: '10:00', second_name: '12:00', value: 22, meridiem: 'PM', second_meridiem: 'AM' },
    ],
    tipTierOptions:                     [],
    unreadNotes:                        0,
    updateEnabledPlatformStoriesByUser: [],
    regex:                              {
        cta: {
            // Used to find the emoji in the text area.
            pillHtml: /<pill\b[^>]*?>(.*?)<\/pill>/g,

            // Used to find mentions in the text area.
            action: /@\[([0-9]+)\]/g,

            // Used to find the call to action pill inside the pill.
            actionInside:     /\${(.*?)}/g,
            actionStructure:  /\$\{([^}]+)\}/g,
            actionStructure2: /\${([^}]+)}/,

            // Used to find the call to action pill inside the pill.
            mentionsHtml: /<a[^>]*>(.*?)<\/a>/,
            mentions:     /\B@(\w+)\b(?!.*\B@\w+\b)/,

            // Used to find the image pill inside the pill.
            actionImage: /<img(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g,
        },
    },
    isUploadingAsset: false,
};

const today = new Date();

export const DEFAULT_STORY = {
    additional_image_urls:     [],
    approval:                  null,
    archived_at:               null,
    brandManagers:             [],
    campaign_id:               null,
    campaigns:                 [],
    classifiers:               [],
    contents:                  null,
    created_at:                null,
    distribute_by_owner_ids:   [],
    exclude_from_sync:         [],
    expires_at:                new Date(new Date().setMonth(today.getMonth() + 6)),
    fallback_image_url:        '',
    firstLoad:                 true,
    id:                        null,
    image_url:                 '',
    is_copied:                 false,
    is_distribute_by_owner:    false,
    is_filled_completely:      false,
    keywords:                  [],
    languages:                 [],
    message:                   '',
    old_expires_at:            new Date(new Date().setMonth(today.getMonth() + 6)),
    original_url:              null,
    override_with_all_content: true,
    platformCampaignDisabled:  [],
    platformStories:           [],
    platforms:                 [],
    pool_id:                   null,
    pools:                     [],
    post_type:                 'PHOTO',
    priority:                  null,
    published_at:              today,
    region_names:              [],
    regions:                   [],
    scheduled_at:              null,
    scheduled_date:            today,
    scheduled_time:            null,
    selectedCampaign:          '',
    setCaretAtTheEnd:          false,
    source:                    null,
    timezone_long_name:        Intl.DateTimeFormat('en', {timeZoneName: 'long'}).formatToParts().find(p => p.type === 'timeZoneName').value,
    timezone_short_name:       Intl.DateTimeFormat().resolvedOptions().timeZone,
    title:                     null,
    url:                       null,
    video_metadata:            null,
    video_thumbnail_url:       null,
    video_url:                 null,
};

export const DEFAULT_PLATFORM_STORIES_ERRORS = {
    media: {
        photo: {
            limit:       false,
            aspectRatio: [],
            format:      [],
            resolution:  [],
            size:        [],
            min:         false,
        },
        video: {
            limit:       false,
            aspectRatio: [],
            codecs:      [],
            duration:    [],
            format:      [],
            resolution:  [],
            size:        [],
            bitRate:     [],
            framerate:   [],
            min:         false,
        },
        mixed: {
            enabled: false,
            min:     false,
        },
    },
    char: {
        limit:                false,
        link:                 false, // currently unused
        emoji:                false,
        hashtag:              false, // currently unused
        mention:              false,
        min:                  false,
        brandRequiresDynamic: false,
    },
};


const storyOptions = JSON.parse(JSON.stringify(DEFAULT_STORY_OPTIONS));
const story = JSON.parse(JSON.stringify(DEFAULT_STORY));

export default {
    story,
    ...storyOptions,
};

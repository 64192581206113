<template>
    <div :class="computedClass">
        <div class="row">
            <div class="col-xs-6 text-left">
                <div class="form-group">
                    <h5>
                        <b>{{ invoice.addressee.name }}</b>
                    </h5>
                    <div>ATTN: Accounts Payable</div>
                    <div>{{ address.address }}</div>
                    <div>{{ address.address2 }}</div>
                    <div>
                        {{ address.city }}, {{ address.state }}
                        {{ address.zip }}
                    </div>
                </div>
            </div>
            <div class="col-xs-6 text-right">
                <div class="form-group">
                    <h5>
                        <b>Invoice #{{ invoice.id }}</b>
                    </h5>
                    <div>Invoice Date: {{ formatDate(invoice.invoice_date) }}</div>
                    <div v-if="!invoice.is_paid">Invoice Due: Upon receipt</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="pull-right">
                    <div class="form-group">
                        <a
                            class="btn btn-default btn-xs"
                            :href="invoice.pdfUrl"
                            target="_blank"
                        ><i class="fa fa-print" /> Print</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="panel panel-default">
                    <div class="panel-heading text-left"><b>Products / Services</b></div>
                    <div class="panel-body">
                        <table class="table table-condensed">
                            <thead>
                                <th>Item</th>
                                <th />
                                <th width="50%;" />
                                <th class="text-right">Amount</th>
                            </thead>
                            <tbody>
                                <template v-for="(line, index) in orderedLineItems" :key="index">
                                    <tr>
                                        <td>
                                            {{ line.description }}
                                            <template v-if="invoice.reseller">
                                                <span class="text-danger">
                                                    &nbsp;({{ invoice.reseller.name }})
                                                </span>
                                            </template>
                                            <template v-else>
                                                <span v-if="invoice.addressee && invoice.addressee.reseller" class="text-danger">
                                                    &nbsp;({{ invoice.addressee.reseller.name }})
                                                </span>
                                            </template>
                                        </td>
                                        <td />
                                        <td />
                                        <td class="text-right">
                                            <template v-if="!invoice.should_hide_line_item_amount">
                                                <i class="fa fa-dollar" />{{ parseFloat(line.amount).toFixed(2) }}
                                            </template>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tfoot class="text-right">
                                <tr>
                                    <td />
                                    <td />
                                    <td><b>Total</b></td>
                                    <td><i class="fa fa-dollar" />{{ total.toFixed(2) }}</td>
                                </tr>
                                <tr>
                                    <td />
                                    <td />
                                    <td><b>Amount Due</b></td>
                                    <td>
                                        <i class="fa fa-dollar" />
                                        {{ invoice.total_amount_due.toFixed(2) }}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="payments.length > 0" class="row">
            <div class="col-xs-12">
                <div class="well">
                    <h5><b>Notes</b></h5>
                    <ul>
                        <li v-for="(payment, index) in payments" :key="index">
                            Payment of <i class="fa fa-dollar" />{{ payment.amount }} was made
                            at {{ payment.created_at }}
                            <span v-if="payment.card_suffix">
                                with {{ payment.card ? payment.card : "Card" }} ending in
                                {{ payment.card_suffix }}</span>.
                            {{
                                payment.reference_number
                                    ? "(ref #: " + payment.reference_number + ")"
                                    : ""
                            }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

export default {
    props: {
        invoice: {
            type: Object,
            required: true,
        },
        paymentProfile: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {
        payments() {
            return this.invoice.payments.filter(function(payment) {
                return payment.cybersource_decision !== 'REJECT';
            });
        },
        computedClass() {
            return 'invoice';
        },
        total() {
            let total = 0.0;
            for (let i = 0; i < this.invoice.line_items.length; i++) {
                total = total + parseFloat(this.invoice.line_items[i].amount);
            }

            return total;
        },
        /**
     * On a Billing invoice, any line items that say "Social Media"
     * should be grouped as a single line item. All other line items
     * should be individual lines as usual.
     */
        collapsedLineItems() {
            if (this.invoice.type === 'billing') {
                return this.collapse(this.invoice.line_items);
            } else {
                return this.invoice.line_items;
            }
        },
        orderedLineItems() {
            if (this.invoice.addressee_type === 'reseller' && this.invoice.type === 'billing') {
                let ordered = Array.from(this.collapsedLineItems);
                ordered.sort((a, b) => a.description - b.description);
                return ordered;
            } else {
                return this.collapsedLineItems;
            }
        },
        address() {
            return {
                address:  this.paymentProfile?.address || this.invoice.addressee.address,
                address2: this.paymentProfile?.address_2 || this.invoice.addressee.address_2,
                city:     this.paymentProfile?.city || this.invoice.addressee.city,
                state:    this.paymentProfile?.state || this.invoice.addressee.state,
                zip:      this.paymentProfile?.zip || this.invoice.addressee.zip,
            };
        },
    },
    methods: {
        formatDate: function(date) {
            if (!date) {
                return 'n/a';
            }
            return dayjs(date).format('L');
        },
        collapse() {
            let results = [];
            this.invoice.line_items.forEach(line_item => {
                if (/Social Media/.test(line_item.description)) {
                    results.push(line_item);
                } else {
                    results.push(line_item);
                }
            });
            return results;
        },
    },
};
</script>

<style>
.invoice {

    .totals {
        padding: 5px;
    }

    .totals .form-group {
        margin-bottom: 0;
    }

    .modal-body,
    table.table {
        font-size: 13px;
    }

    table.table thead th {
        padding: 5px;
    }

    table.table tbody tr td {
        padding-top: 8px;
    }

    table.table tbody tr td > .btn {
        margin-top: -3px;
    }

    table.table tbody tr td > input[type="checkbox"],
    input[type="radio"] {
        margin: 3px 0 0;
    }
}
</style>

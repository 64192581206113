<template>
    <div class="step-progress-indicator" :style="{'padding-left': scale * width / 2 + 'px'}">
        <template v-for="(step, index) in steps" :key="index">
            <div class="step" :style="stepStyle">
                <h2 :style="textStyle[index]">{{ step }}</h2>
                <template v-if="index > 0">
                    <div class="connector" :style="connectorStyle[index]" />
                </template>
                <div class="border" :style="borderStyle[index]" />
                <div :data-step="index" class="circle" :style="circleStyle[index]" />
            </div>
        </template>
    </div>
</template>

<script>
import ScreenSize from '@/mixins/ScreenSize.js';

export default {
    mixins: [
        ScreenSize,
    ],
    props: {
        /* Data */
        currentStep: {
            type:     Number,
            required: true,
        },
        steps: {
            type:     Array,
            required: true,
        },
        /* Size */
        borderWidth: {
            type:     Number,
            required: false,
            default:  7,
        },
        connectorWidth: {
            type:     Number,
            required: false,
            default:  13,
        },
        radius: {
            type:     Number,
            required: false,
            default:  40,
        },
        width: {
            type:     Number,
            required: false,
            default:  132,
        },
        /* Colors */
        borderColor: {
            type:     String,
            required: false,
            default:  '#ebebed',
        },
        activeColor: {
            type:     String,
            required: false,
            default:  '#1c66ff',
        },
        activeTextColor: {
            type:     String,
            required: false,
            default:  '#1c66ff',
        },
        finalBorderColor: {
            type:     String,
            required: false,
            default:  '#40ba87',
        },
        finalColor: {
            type:     String,
            required: false,
            default:  '#1c66ff',
        },
        finalTextColor: {
            type:     String,
            required: false,
            default:  '#40ba87',
        },
        inactiveColor: {
            type:     String,
            required: false,
            default:  '#fff',
        },
        inactiveTextColor: {
            type:     String,
            required: false,
            default:  '#afb1b6',
        },
    },
    computed: {
        scale() {
            let scale = this.screenSizeX / 900
            return (scale > 1) ? 1 : scale;
        },
        borderStyle() {
            let styles = [];
            this.steps.forEach((step, index)=> {
                let backgroundColor;
                if (index == this.steps.length - 1 && index == this.currentStep) {
                    backgroundColor = this.finalBorderColor;
                } else {
                    backgroundColor = this.borderColor;
                }
                styles.push({
                    'background-color': backgroundColor,
                    'height':           this.scale * (this.radius  + (2 * this.borderWidth)) + 'px',
                    'width':            this.scale * (this.radius + (2 * this.borderWidth)) + 'px',
                });
            });
            return styles;
        },
        circleStyle() {
            let styles = [];
            this.steps.forEach((step, index)=> {
                let backgroundColor;
                if (index == this.steps.length - 1 && index == this.currentStep) {
                    backgroundColor = this.finalColor;
                } else if (index <= this.currentStep) {
                    backgroundColor = this.activeColor;
                } else {
                    backgroundColor = this.inactiveColor;
                }
                styles.push({
                    'background-color': backgroundColor,
                    'height':           this.scale * this.radius + 'px',
                    'margin':           this.scale * this.borderWidth + 'px',
                    'width':            this.scale * this.radius + 'px',
                });
            });
            return styles;
        },
        connectorStyle() {
            let styles = [];
            this.steps.forEach((step, index)=> {
                let backgroundColor;
                if (index <= this.currentStep) {
                    backgroundColor = this.activeColor;
                } else {
                    backgroundColor = this.borderColor;
                }
                styles.push({
                    'background-color': backgroundColor,
                    'bottom':           this.scale * -(this.borderWidth + (this.radius / 2) + this.connectorWidth / 2) + 'px',
                    'height':           this.scale * this.connectorWidth + 'px',
                    'left':             this.scale * (this.borderWidth + (this.radius / 2) - this.width) + 'px',
                    'width':            this.scale * this.width + 'px',
                });
            });
            return styles;
        },
        stepStyle() {
            return {
                'margin-bottom': this.scale * (this.radius + (2 * this.borderWidth)) + 'px',
                'width':         this.scale * this.width + 'px',
            };
        },
        textStyle() {
            let styles = [];
            let fontSize = `${this.scale * 16}pt`;

            this.steps.forEach((step, index)=> {
                let color;
                if (index == this.steps.length - 1 && index == this.currentStep) {
                    color = this.finalTextColor;
                } else if (index <= this.currentStep) {
                    color = this.activeTextColor;
                } else {
                    color = this.inactiveTextColor;
                }

                styles.push({
                    'color':     color,
                    'font-size': fontSize,
                    'left':      this.scale * (this.borderWidth + (this.radius / 2) - (this.width / 2)) + 'px',
                });
            });
            return styles;
        },

        tooltipTitle() {
            if (this.currentStep == 0) {
                return 'Start';
            } else if (this.currentStep == this.steps.length - 1) {
                return 'Connected';
            }

            return `Step ${this.currentStep}`;
        },
    },
    watch: {
        currentStep(value) {
            if (this.screenSizeX <= 600) {
                window.$('.circle').tooltip('hide');
                window.$('[data-step="'+value+'"]').attr('title', this.tooltipTitle).tooltip().mouseover();
            }
        },
        screenSizeX(value) {
            if (value <= 600) {
                window.$('[data-step="'+(this.currentStep)+'"]').attr('title', this.tooltipTitle).tooltip().mouseover();
            } else {
                window.$('.circle').tooltip('hide');
            }
        },
    },
};
</script>

<style lang="scss">
.step-progress-indicator {
    .step {
        display: inline-block;
        position: relative;

        h2 {
            font-family: Arial;
            position: relative;
            text-align: center;
            text-transform: uppercase;
        }

        .border {
            border-radius: 100%;
            position: absolute;
            z-index: 1;
        }

        .circle {
            border-radius: 100%;
            position: absolute;
            z-index: 3;
        }

        .connector {
            position: absolute;
            z-index: 2;
        }

        .tooltip-arrow {
            display: none !important;
        }
    }
}
</style>

export default {
    name: 'All',
    char() {
        return {
            limit:   63206,
            link:    true,
            emoji:   true,
            hashtag: true,
            mention: false,
        };
    },
    media(vm) {
        let limits = {
            photo: {
                limit: 1000,
            },
            video: {
                limit: 1,
            },
        };
        return Object.assign(
            limits,
            {
                mixed: {
                    enabled: false,
                },
            },
        );
    },
    misc: {},
};

import LinkerConfig from '@/linker_config.js';
import { uniqueHash } from '@/unique-hash.js';
import axios from '@/axios';

function calculateWindowString() {
    let options = {
        popup:  1,
        width:  768,
        height: 512,
        left:   (window.innerWidth - 768) / 2,
        top:    (window.innerHeight - 512) / 2,
    };

    return Object.keys(options).map(key => {
        return `${key}=${options[key]}`;
    }).join(',');
}

export default {
    async login(platformId, linkerUrl, closeHandler) {
        return LinkerConfig[platformId].handlers[0].getAccounts(linkerUrl)
            .catch(() => {
                console.log('Please ignore this console error. It is expected/caught and unsuppressable.');

                // Need to log in
                window.addEventListener('message', closeHandler);
                setTimeout(() => {
                    window.open(
                        linkerUrl,
                        'platformLinker',
                        calculateWindowString(),
                    );
                }, 0);

                return Promise.reject(false);
            });
    },
    async selectAccount(accounts, platformId, pageId, linkerUrl, handlerIndex) {
        let page = accounts.find(p => p.external_id == pageId);

        if (LinkerConfig[platformId].handlers.length > handlerIndex + 1) {

            const locations = await LinkerConfig[platformId].handlers[handlerIndex + 1].getAccounts(linkerUrl, page);
            let out = [];

            locations.forEach(loc => {
                out.push({
                    name:        loc.name + (loc.address ? ` (${loc.address})` : ''),
                    external_id: loc.external_id,
                });
            });

            return out;
        }

        return pageId;
    },
    async linkDealership(dealershipId, platformId, externalId, noLogin = false) {
        let external = noLogin ? '/external': '';
        return axios.post(`/api/dealerships/${dealershipId}/platforms/${platformId}${external}`, {
            data: {
                platform_id: platformId,
                external_id: externalId,
                unique_hash: uniqueHash(),
                no_login:    noLogin ? '1' : null,
            },
        });
    },
    async unlinkDealership(dealershipId, platformId, noLogin = false) {
        let q = noLogin ? 'no_login=1' : '';
        let url = noLogin
            ? `/api/dealerships/${dealershipId}/platforms/${platformId}/external?${q}`
            : `/api/dealerships/${dealershipId}/platforms/${platformId}?${q}`;

        return axios.delete(url);
    },
    async linkPotential(connectId, potentialId, platformId, externalId) {
        return axios.post(`/api/connects/${connectId}/potentials/${potentialId}/platforms/${platformId}/link`, {
            external_id: externalId,
            unique_hash: uniqueHash(),
        });
    },
    async unlinkPotential(connectId, potentialId, platformId) {
        return axios.post(`/api/connects/${connectId}/potentials/${potentialId}/platforms/${platformId}/unlink`);
    },
};

<template>
    <div class="generic-icon-container" :style="divStyle">
        <div
            :data-placement="direction"
            :scale="scale"
            :style="iconStyle"
            :title="title"
            data-html="true"
            data-toggle="tooltip"
        >
            <slot name="content" />
        </div>
    </div>
</template>

<script>
export default {
    name:  'GenericIcon',
    props: {
        iconStyle: {
            type:     [String, Object],
            required: false,
            default:  '',
        },
        divStyle: {
            type:     String,
            required: false,
            default:  '',
        },
        direction: {
            type:     String,
            required: false,
            default:  'top',
        },
        title: {
            type:     String,
            required: false,
            default:  '',
        },
        scale: {
            type:     Number,
            required: false,
            default:  1,
        },
    },
};
</script>


<style lang="scss">
.generic-icon-container {
    &:not(.no-flex) > div {
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .tooltip-inner {
        background: #252934;
        border-radius: 8px;
        color:white;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 15px !important;
        line-height: 1.428571429;
        margin-bottom: 10px;
        max-width: 200px !important;
        min-width:150px !important;
        padding: 12px 9px !important;
        width: 200px;
    }
    .tooltip.top .tooltip-arrow {
        bottom: 4px;
        left: 79px !important;
        margin-left: unset !important;
        border-width: 0;
        border-right-color: none;
    }
    .tooltip-arrow {
        display:flex;
        width: 40px !important;
        height: 12px !important;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='7' viewBox='0 0 24 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.17158 5.17158L6.34315 2.34315C4.84286 0.842855 2.80802 0 0.686292 0H23.3137C21.192 0 19.1571 0.842852 17.6569 2.34314L14.8284 5.17157C13.2663 6.73367 10.7337 6.73367 9.17158 5.17158Z' fill='%23262933'/%3E%3C/svg%3E") !important;
        background-repeat: no-repeat no-repeat !important;
        background-position: center center !important;
        background-size: contain !important;
    }
}
</style>

<template>
    <popup-modal
        class="popup-modal-component"
        :padding-top="popupModalPaddingTop"
        :hide-no-button="true"
        :hide-yes-button="true"
        :static-backdrop="true"
        @close="$emit('close')"
    >
        <template #icon>
            <img src="/images/popup-icons/information_icon.svg">
        </template>
        <template #header>
            <a v-if="slide > 1" class="previous-slide" href="#" @click.prevent="() => setSlide(slide-1)">
                <blue-arrow-left-icon :scale="0.6" />
            </a>
            <h1>{{ isSnoozed ? 'Resume' : 'Snooze' }}</h1>
        </template>
        <template #content>
            <div v-show="isLoading">
                <ts-loading-spinner :show-loading-string="false" />
            </div>
            <div v-show="!isLoading" class="text-center-content">
                <div v-if="isSnoozed" class="text-center-content-area">
                    <div class="notes-title-container">
                        <div class="text-center-content-area-header">Notes: </div>
                        <span class="required-text">* Required</span>
                    </div>
                    <textarea v-model="message" />
                    <span class="note-limit" :class="{red: message.length > 250}">
                        {{ message.length }}/250
                    </span>
                    <div class="date-buttons">
                        <button class="btn btn-primary" :disabled="!message" @click="saveActivity">Resume</button>
                    </div>
                </div>
                <div v-else>
                    <div v-if="slide === 1" class="text-center-content-area">
                        <div class="notes-title-container">
                            <div class="text-center-content-area-header">Notes: </div>
                            <span class="required-text">* Required</span>
                        </div>
                        <textarea v-model="message" />
                        <template v-if="!message">
                            <div class="note-placeholder">
                                Let us know why you want to Snooze.
                                <br> See you soon! &#128075;
                            </div>
                        </template>
                        <span class="note-limit" :class="{red: message.length > 250}">
                            {{ message.length }}/250
                        </span>
                        <div class="date-buttons">
                            <button class="btn btn-primary" :disabled="!message" @click="() => setSlide(2)">Next</button>
                        </div>
                    </div>
                    <div v-if="slide === 2" class="text-center-content-area">
                        <div class="date-range-wrapper">
                            <template v-if="formattedDates">
                                <div class="date-range">{{ formattedDates }}</div>
                            </template>
                            <template v-else>
                                <div class="date-range-placeholder">Select Date Range</div>
                            </template>
                        </div>
                        <div class="date-picker-container">
                            <div class="date-picker-header">
                                <span class="pause-until-label">Pause until:</span>
                                <a class="pause-indefinitely" href="#" @click.prevent="pauseIndefinitely">
                                    Pause<br>Indefinitely
                                </a>
                            </div>
                            <div class="quick-date-buttons">
                                <template v-for="(date) in quickFutureDates" :key="date.name">
                                    <button
                                        v-if="date.id !== 0"
                                        class="btn btn-default btn-sm quick-date-button"
                                        :class="{'quick-date-button-highlighted': selectedQuickDateId === date.id}"
                                        @click.prevent="handleSelectDate(date)"
                                    >
                                        {{ date.name }}
                                    </button>
                                </template>
                            </div>
                            <date-picker
                                :model-value="dateRange"
                                :model-modifiers="{range: true}"
                                :min-date="now"
                                :columns="2"
                                @update:model-value="handleSelectDate"
                            />
                        </div>
                        <div class="date-buttons">
                            <button class="btn btn-primary" :disabled="!formattedDates" @click="() => setSlide(3)">Next</button>
                        </div>
                    </div>
                    <div v-if="slide === 3" class="text-center-content-area">
                        <span class="text-center-content-area-header">Dates:</span>
                        <div class="date-range-wrapper">
                            <div class="date-range"><span class="select-month-pill">{{ formattedDates }}<i class="fa fa-close" @click="setSlide(2)" /></span></div>
                            <a class="adjust-dates" href="#" @click.prevent="() => setSlide(2)">Adjust Date</a>
                        </div>
                        <div class="notes-title-container">
                            <div class="text-center-content-area-header">Notes: </div>
                            <span class="required-text-gray">* Required</span>
                        </div>
                        <pause-note :note="message" :created="formattedStart" :user="userStore.name" format="wide" />
                        <div class="date-buttons">
                            <button class="btn btn-primary" :disabled="!message" @click="saveActivity">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </popup-modal>
</template>

<script>
import axios from '@/axios';
import ButtonGroup from '@/components/app/ButtonGroup.vue';
import DateRange from '@/components/app/DateRange.vue';
import PopupModal from '@/components/app/PopupModal.vue';
import TsLoadingSpinner from '@/components/app/TsLoadingSpinner.vue';
import BlueArrowLeftIcon from '@/components/icons/BlueArrowLeftIcon.vue';
import PauseNote from '@/components/retailer_experience/PauseNote.vue';
import { userStore } from '@/stores/pinia';
import dayjs from 'dayjs';
import { mapStores } from 'pinia';
import { DatePicker } from 'v-calendar';

export default {
    components: {
        PauseNote,
        BlueArrowLeftIcon,
        PopupModal,
        TsLoadingSpinner,
        ButtonGroup,
        DateRange,
        DatePicker,
    },
    props: {
        dealershipId: {
            type:     [String, Number],
            required: true,
        },
        isSnoozed: {
            type:     Boolean,
            required: true,
            default:  false,
        },
        platformId: {
            type:     Number,
            required: true,
        },
        popupModalPaddingTop: {
            type:     String,
            required: false,
            default:  '0px',
        },
        external: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    data() {
        return {
            buttonSelect: !this.isSnoozed ? 0 : 1,
            isLoading:    false,
            customDate:   null,
            dateRange:    {
                start: dayjs().format(),
                end:   dayjs().add(29, 'days').format(),
            },
            minDate:          new Date(),
            message:          '',
            quickFutureDates: [
                {
                    name:  'Custom Range',
                    start: null,
                    end:   null,
                    id:    0,
                },
                {
                    name:  '7 days',
                    start: dayjs().format(),
                    end:   dayjs().add(6, 'days').format(),
                    id:    1,
                },
                {
                    name:  '30 days',
                    start: dayjs().format(),
                    end:   dayjs().add(29, 'days').format(),
                    id:    2,
                },
                {
                    name:  '60 days',
                    start: dayjs().format(),
                    end:   dayjs().add(59, 'days').format(),
                    id:    3,
                },
                {
                    name:  '90 days',
                    start: dayjs().format(),
                    end:   dayjs().add(89, 'days').format(),
                    id:    4,
                },
            ],
            selectedQuickDateId: 2,
            disabled:            false,
            slide:               1,
        };
    },
    computed: {
        ...mapStores(userStore),
        formattedStart() {
            if (this.dateRange?.start) {
                return dayjs(this.dateRange.start).format('L');
            }
            return '';
        },
        formattedEnd() {
            if (this.dateRange?.end) {
                return dayjs(this.dateRange.end).format('L');
            }
            return '';
        },
        formattedDates() {
            if (this.formattedStart) {
                if (this.formattedEnd) {
                    return `${this.formattedStart} - ${this.formattedEnd}`;
                }
                return `${this.formattedStart} - Indefinite`;
            }
            return '';
        },
        now() {
            return dayjs().format('L LT');
        },
    },
    mounted() {
        this.minDate = dayjs().format('MM/DD/YYYY');
    },
    methods: {
        handleSelectedQuickDate(event) {
            this.customDate = event.selectedRange;
            this.selectedQuickDateId = event.id;
        },
        saveActivity() {
            this.disabled = true;
            const data = {
                type:        this.isSnoozed ? 'resume' : 'snooze',
                description: this.message,
                start:       this.dateRange.start,
                end:         this.dateRange.end,
            };

            axios.post(`/api/dealerships/${this.dealershipId}/platforms/${this.platformId}/snoozeresume`, data)
                .then((response) => {
                    if (!response.data.status) {
                        throw new Error('An error has occured.');
                    }

                    window.flash.show({
                        type: 'success',
                        text: `Platform was ${this.buttonSelect === 0 ? 'snoozed' : 'resumed'}.`,
                    });

                    this.$emit('close', true);

                }).catch((error) => {
                    window.flash.show({
                        type: 'error',
                        text: 'Error while trying to snooze/resume. Try again later.',
                    });

                    throw error;
                });
        },
        setSlide(slide) {
            // Reset calendar dates if going back
            if (slide < this.slide) {
                this.dateRange = null;
            }
            this.slide = slide;
        },
        cancel() {
            this.$emit('close');
        },
        handleSelectDate($event) {
            if ($event.end && dayjs($event.start).isSame(dayjs($event.end))) {
                return;
            }
            this.dateRange = $event;
        },
        pauseIndefinitely() {
            this.dateRange = {
                start: new Date(),
                end:   null,
            };
        },
    },
};
</script>

<style lang="scss">
.popup-modal-component {
    .popup-modal-body-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text-center {
            margin-right: 30px;
            margin-left: 30px;

            .text-center-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .notes-title-container {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .required-text {
                        color: #D10000;
                    }

                    .required-text-gray {
                        color: #85878F;
                    }
                }

                .date-buttons {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    padding-top: 20px;
                }

                .date-range-component-container {
                    background: #fff;
                    border: 2px solid #D6D9DB;
                    border-radius: 5px;
                    padding: 5px;
                    position: relative;
                    margin-top: 1rem;

                    .date-range-container {
                        top: 0px;
                        left: 0px;
                        margin-top: 55px;
                        z-index: 10;

                        .quick-date-buttons {
                            justify-content: flex-start;
                            margin-left: 2rem;
                            align-items: center;

                            .quick-date-label {
                                margin-right: 0.5rem;
                                font-weight: 700;
                                font-size: 14px;
                            }
                        }
                    }

                    .input-group {
                        height: 100%;
                        display: flex;

                        .purple-caret-down {
                            margin-right: 10px;
                        }
                    }

                    .placeholder-label {
                        margin-left: 0px;
                    }

                    .form-control {
                        background: #0166FF;
                        border-radius: 6px !important;
                        border: none;
                        color: #eee;
                        font-size: 16px;
                        font-weight: bold;
                        padding: 7px 1ch;
                        text-align: center;
                        width: 133px;
                    }

                    @media only screen and (max-width: 1800px) {

                        .form-control,
                        .placeholder-label {
                            font-size: 14px;
                            width: 30%;
                        }
                    }

                    .form-control:focus {
                        box-shadow: none;
                    }

                    .form-control::placeholder {
                        color: #fff;
                    }

                    .reverse-input .selected-regions {
                        justify-content: space-between;
                    }

                    .row-right-container .date-input-container {
                        padding: 3px 5px 4px 5px;
                    }

                    .purple-caret-down {
                        margin-right: 10px;
                    }

                    .row-right-container .modal-date-picker-input-container {
                        border-radius: 6px;
                        padding: 4px 0px;
                    }

                    .row-right-container .modal-date-picker-input {
                        height: 2.8rem;
                    }

                    .placeholder-label {
                        color: #000;
                        font-size: 16px;
                        padding-left: 12px;
                        margin-right: auto;
                        white-space: nowrap;
                    }
                }

                .btn-primary {
                    border-radius: 4px;
                    border: 2px solid #06F;
                    background: #06F;
                }

                textarea {
                    background-color: white;
                    border: 1px solid #d6d9db;
                    border-radius: 4px;
                    height: 150px;
                    margin-bottom: 5px;
                    padding: 5px;
                    resize: none;
                    width: 488px;
                }

                .note-limit {
                    color: #adb0b3;
                    font-size: 10px;
                    position: absolute;
                    right: 60px;
                    top: 310px;
                    z-index: 2;

                    &.red {
                        color: red;
                    }
                }

                .note-placeholder {
                    color: #adb0b3;
                    font-size: 12px;
                    left: 50%;
                    pointer-events: none;
                    position: absolute;
                    top: 240px;
                    transform: translateX(-50%);
                }

                .full-height {
                    textarea {
                        height: 227px;
                    }

                    .note-limit {
                        top: 207px;
                    }

                    .note-placeholder {
                        top: 93px;
                    }
                }

                .notes {
                    height: 120px;
                    margin-bottom: 20px;
                    overflow-y: scroll;
                    width: 488px;

                    .notes::-webkit-scrollbar {
                        width: 8px;
                    }

                    .notes::-webkit-scrollbar-track {
                        background: #fff;
                        border-radius: 5px;
                    }

                    .notes::-webkit-scrollbar-thumb {
                        background-color: #90BBFA;
                        border-radius: 5px;
                    }

                    &.tall {
                        height: 159px;
                    }
                }

                .pause-note {
                    min-width: 450px;
                }
            }

            .btn-group-container {
                font-weight: normal;

                .btn-group {
                    width: 90px;
                    font-weight: normal;
                }

                .inactive {
                    border-color: #0066FF;
                    color: #0066FF;
                }
            }

            .text-center-content-area {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 2rem;

                .text-center-content-area-header {
                    font-weight: 700;
                    font-size: 14px;
                }

                .text-area {
                    display: flex;
                    width: 500px;
                    height: 300px;
                    margin-top: 1rem;
                    border: 2px solid #D6D9DB;
                    padding: 10px;
                    border-radius: 4px;
                }

                textarea {
                    resize: vertical;
                    min-height: 20vh;
                    max-height: 50vh;
                }

                .notes-title-container {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .required-text {
                        color: #D10000;
                    }

                    .required-text-gray {
                        color: #85878F;
                    }
                }

                .date-buttons {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    padding-top: 20px;
                }

                .date-range-wrapper {
                    border-radius: 4px;
                    border: 1px solid #D6D9DB;
                    background: var(--White-Pure, #FFF);
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    justify-content: space-between;

                    .date-range {
                        padding-left: 10px;

                        .select-month-pill {
                            display: inline-block;
                            background-color: #0166FF;
                            color: #eee;
                            font-weight: bold;
                            margin: 0.25em;
                            padding: 0.25em 0.5em;
                            border-radius: 5px;
                            font-size: 0.8em;

                            .fa {
                                padding-left: 5px;
                                cursor: pointer;
                            }
                        }
                    }

                    .adjust-dates {
                        padding-right: 5px;
                    }
                }

                .date-picker-container {
                    border-radius: 4px;
                    border: 2px solid #D6D9DB;
                    background: #FFF;

                    .date-picker-header {
                        display: flex;
                        justify-content: space-between;

                        .pause-until-label {
                            padding-top: 10px;
                            padding-left: 20px;
                        }

                        .pause-indefinitely {
                            color: #D10000;
                            font-size: 10px;
                            font-style: italic;
                            font-weight: 400;
                            line-height: normal;
                            padding-top: 10px;
                            padding-right: 20px;
                        }
                    }

                    .vc-container {
                        border: 0;
                    }
                }
            }
        }

        .btn-save {
            background-color: #0066FF;
            color: #fff;
            border-radius: 4px;
            width: 80px;
            margin-top: 4rem;
            font-weight: 700;
        }
    }

    .previous-slide {
        background-color: rgba(0, 102, 255, 0.5);
        border-radius: 100%;
        cursor: pointer;
        left: 50px;
        padding: 4px 5px 0 5px;
        position: absolute;
        top: 50px;
    }

    img {
        height: 28px;
        width: 28px;
    }
}
</style>

<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper" @mousedown.self="closeFromBackdrop">
                <div class="modal-container" :class="modalPosition" :style="{top: positionFromTop, 'padding-top' : paddingTop }" @mousedown.self="closeFromBackdrop">
                    <div class="modal-dialog" :class="{['modal-' + modalSize]: true}" role="document">
                        <div class="modal-content" :style="contentShadowStyle">
                            <slot name="header-container" />
                            <div v-if="header" class="modal-header">
                                <button
                                    v-if="closeButton" type="button" class="close"
                                    :class="{'close-safari': browserName === 'safari'}"
                                    aria-label="Close" @click="close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="flex">
                                    <h5 class="modal-title" :class="centerTitle ? 'centered' : ''" v-text="title" />
                                </div>
                            </div>
                            <slot v-else name="close">
                                <button
                                    v-if="closeButton"
                                    id="closeModalButton" type="button" class="close"
                                    aria-label="Close"
                                    :style="modalCloseStyles"
                                    :class="{'close-safari': browserName === 'safari',
                                             'close-custom-modal-default': !hasHeaderContainerSlots && enableCustomCloseButton,
                                             'close-custom-modal-with-header': hasHeaderContainerSlots,
                                             'hidden-class': isLoading === true}"
                                    @click="close"
                                >
                                    <circle-cross-icon v-if="!showGradientIcons" :scale="modalCloseScale" />
                                    <circle-cross-icon-with-gradient v-else :scale="modalCloseScale" />
                                </button>
                            </slot>
                            <slot name="fixed" />
                            <div :class="{ 'modal-body': defaultBodyClass }" :style="modalBodyStyles">
                                <slot name="sidebar" />
                                <slot name="body" />
                            </div>
                            <div v-if="footer" class="modal-footer">
                                <slot name="footer">
                                    <button
                                        v-if="closeButton" type="button" class="btn btn-default" aria-label="Close"
                                        @click="close"
                                    >
                                        {{ buttonText }}
                                    </button>
                                </slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import BrowserName from '@/browser_name.js';
import CircleCrossIcon from '@/components/icons/CircleCrossIcon.vue';
import CircleCrossIconWithGradient from '@/components/icons/CircleCrossIconWithGradient.vue';

export default {
    components: {
        CircleCrossIcon,
        CircleCrossIconWithGradient,
    },
    emits: ['mount', 'close'],
    props: {
        title:       undefined,
        closeButton: {
            default: true,
        },
        modalCloseScale: {
            default: 1.4,
        },
        header: {
            default: true,
        },
        footer: {
            default: true,
        },
        modalSize: {
            default: 'lg',
        },
        centerTitle: {
            default: true,
        },
        staticBackdrop: {
            default: false,
        },
        positionFromTop: {
            type:          String,
            default:       '',
            modalPosition: 'null',
        },
        defaultBodyClass: {
            type:    Boolean,
            default: true,
        },
        enableCustomCloseButton: {
            default: true,
        },
        modalBodyStyles: {
            type:    String,
            default: '',
        },
        modalCloseStyles: {
            type:    String,
            default: '',
        },
        contentShadowStyle: {
            type:    String,
            default: '',
        },
        paddingTop: {
            type:    String,
            default: '0px',
        },
        showGradientIcons: {
            type:    Boolean,
            Default: false,
        },
    },
    data() {
        return {
            browserName:   '',
            height:        0,
            modalPosition: null,
        };
    },
    computed: {
        ...mapGetters('StoryStore', [
            'isLoading',
        ]),
        buttonText() {
            if (this.closeButton && typeof this.closeButton === 'string') {
                return this.closeButton;
            } else {
                return 'Close';
            }
        },
        isInMacOS() {
            /*
                This is a complete hack, just to try to fix a scenario where the
                MacOS is not rendering the X of the content modal as expected, the
                same CSS, but in Windows or Linux, works as expected.
            */
            const regexp = /\w*mac\w*/g;
            return regexp.test(navigator.platform.toLowerCase());
        },
        hasHeaderContainerSlots() {
            return !!this.$slots['header-container'];
        },
    },
    mounted() {
        window.document.body.classList.add('modal-open');

        this.$emit('mount');
        if (this.positionFromTop != '') {
            this.modalPosition = 'modal-position';
        }

        this.browserName = BrowserName;
    },
    methods: {
        closeFromBackdrop() {
            if (this.staticBackdrop) {
                return;
            } else {
                this.close();
            }
        },
        close() {
            window.document.body.classList.remove('modal-open');
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 9000;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vh);
        display: table;
        transition: opacity .125s ease;
        background-color: rgb(0, 0, 0, 0.25);

    }
    .close {
        position: absolute;
        top: 35px;
        line-height: 0;
        right: 30px;
        z-index: 1;  /* Needed to get the element to be displayed, slightly higher than `.modal-mask`. */
    }
    .close > span {
        font-size: 3rem;
        font-weight: 400;
    }
    .close-safari > span {
        font-size: 3rem;
        font-weight: 200;
    }
    .close-custom-modal-default {
        border-radius:50%;
        opacity: 1;
        top: 20px;
        right: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 34px;
    }
    .close-custom-modal-mac-hack {
        padding-bottom: 6px;
    }
    .close-custom-modal-default > span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4rem;
        font-weight: 400;
        color: #2e71ff;
        text-shadow: 0 0 black;
    }
    .close-custom-modal-default.close-safari {
        background: #90bbfa;
        border-radius: 50% !important;
        opacity: 1;
    }
    .close-custom-modal-default.close-safari > span {
        font-size: 3rem;
        font-weight: 400;
        padding-bottom: 6px;
    }
    .modal-position{
        position: fixed;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .modal-dialog {
        max-height: calc(100vh - 30px);
    }

    .modal-content{
        box-shadow: 0px 0px 5000px 5000px rgba(0,0,0,.7);
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .modal-content {
            box-shadow: none;
        }

        .modal-mask {
            background-color: rgba(0,0, 0, 0.25);
        }
    }

    @supports (-ms-ime-align: auto) {
        .modal-content {
            box-shadow: none !important;
        }

        .modal-mask {
            background-color: rgba(0,0, 0, 0.25)
        }
    }

    .modal-body {
        max-height: 80vh;
        overflow-y: auto;
        overscroll-behavior: contain;
        -ms-scroll-chaining: none;
    }

    .modal-wrapper {
        display: table-cell;
        margin: 30px auto;
        overflow-y: auto;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .glow {
        width: 20px;
        border-radius: 5px;
        margin-left: 5px;
    }

    .flex {
        display: flex;
    }
    .centered {
        justify-content: center;
    }
    .hidden-class {
        display: none;
    }
    .close-custom-modal-with-header {
        border-radius:50%;
        opacity: 1;
        right: 15px;
        display: flex;
        height: 35px;
        width: 35px;
    }
</style>

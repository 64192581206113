<template>
    <popup-modal
        class="viewing-wizard-modal"
        modal-size="md"
        hide-no-button
        hide-yes-button
        :hide-footer="true"
        :modal-close-scale="0.6"
        @close="cancel"
    >
        <template #content>
            <p class="modal-heading">Viewing Mode</p>
            <template v-if="slide">
                <div class="viewing-modal-body">
                    <template v-if="slide === 1">
                        <div class="welcome-text">
                            <div class="viewing-mode-text">
                                <p>
                                    Viewing Mode
                                    <brown-eye-icon :scale="0.9" />
                                </p>
                            </div>
                            <div class="questions-body">
                                <p>Answer a few questions to begin.</p>
                            </div>
                            <div class="button-container">
                                <button class="btn btn-primary btn-blue" @click="() => setSlide(2)">Get Started</button>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="slide === 2">
                        <a class="previous-slide" href="#" @click.prevent="() => setSlide(1)">
                            <previous-arrow class="previous-arrow-container" />
                        </a>
                        <div class="role-select-body">
                            <div class="role-text">
                                <p>Which role type would you like to view as?</p>
                            </div>
                            <div class="brand-reseller-button-group">
                                <button
                                    class="btn-initial" :class="{'btn-selected':wizardData.roleType === 'Brand'}"
                                    @click="setWizardData(2, 'Brand')"
                                >
                                    Brand
                                </button>
                                <button
                                    class="btn-initial" :class="{'btn-selected':wizardData.roleType === 'Retailer'}"
                                    @click="setWizardData(2, 'Retailer')"
                                >
                                    Retailer
                                </button>
                            </div>
                        </div>

                        <div class="link-to-all-users-role-type">
                            <a href="/users">Link to All Users</a>
                        </div>
                        <div class="horizontal-divider-modal" />
                        <div class="date-buttons">
                            <div v-if="slideTwoComplete" style="margin-top: 10px;">
                                <arrow-pointer :is-animated="true" />
                            </div>
                            <button
                                :disabled="!slideTwoComplete"
                                :style="slideTwoComplete && 'opacity: 1; background-color: #0066FF'"
                                class="btn btn-primary btn-next" @click="() => setSlide(3)"
                            >
                                Next
                            </button>
                        </div>
                    </template>
                    <template v-if="slide === 3 && wizardData.roleType === 'Brand'">
                        <a class="previous-slide" href="#" @click.prevent="() => setSlide(2)">
                            <previous-arrow class="previous-arrow-container" />
                        </a>
                        <div class="brand-select-body">
                            <div>
                                <p class="brand-select-body-text">Which Brand would you like to view as?</p>
                            </div>
                            <div>
                                <filter-dropdown
                                    v-if="wizardData.resellersOptions.length > 0"
                                    option-key="reseller_id"
                                    v-model:selected="wizardData.brandWizard.brandId"
                                    :placeholder="'Select Brand Name'"
                                    :options="wizardData.resellersOptions || []"
                                    :is-loading="!wizardData.resellersOptions || !wizardData.resellersOptions.length"
                                    :multiple="false"
                                    @update:selected="$event => setWizardData(3, $event)"
                                />
                                <ts-loading-spinner v-else width="40" />
                            </div>
                        </div>
                        <div class="link-to-all-users">
                            <a href="/users">Link to All Users</a>
                        </div>
                        <div class="horizontal-divider-modal" />
                        <div v-if="wizardData.brandWizard.brandId" class="brand-select-info-text">
                            <success-icon :scale="0.6" color="#0066FF" />
                            <div class="brand-selected-text">Brand Selected: {{ wizardData.displayInfo.brandName }}</div>
                        </div>
                        <div class="date-buttons">
                            <div v-if="slideThreeComplete" style="margin-top: 10px;">
                                <arrow-pointer :is-animated="true" />
                            </div>
                            <button
                                :disabled="!slideThreeComplete"
                                :style="slideThreeComplete && 'opacity: 1; background-color: #0066FF'"
                                class="btn btn-primary btn-next" @click="() => setSlide(4)"
                            >
                                Next
                            </button>
                        </div>
                    </template>
                    <template v-else-if="slide === 3 && wizardData.roleType === 'Retailer'">
                        <a class="previous-slide" href="#" @click.prevent="() => setSlide(2)">
                            <previous-arrow class="previous-arrow-container" />
                        </a>
                        <div class="brand-select-body">
                            <div class="brand-select-body-text">
                                <p>Please select a Brand associated with a Retailer</p>
                            </div>
                            <div>
                                <filter-dropdown
                                    option-key="reseller_id"
                                    v-model:selected="wizardData.retailerWizard.brandId"
                                    :placeholder="'Select a Brand'"
                                    :options="wizardData.resellersOptions || []"
                                    :is-loading="!wizardData.resellersOptions || !wizardData.resellersOptions.length"
                                    :multiple="false"
                                    @update:selected="$event => setWizardData(3, $event)"
                                />
                            </div>
                        </div>
                        <div class="link-to-all-users">
                            <a href="/users">Link to All Users</a>
                        </div>
                        <div class="horizontal-divider-modal" />
                        <div v-if="wizardData.retailerWizard.brandId" class="brand-select-info-text">
                            <success-icon :scale="0.6" color="#0066FF" />
                            <div class="brand-selected-text">Brand Selected: {{ wizardData.displayInfo.brandName }}</div>
                        </div>
                        <div class="date-buttons">
                            <div v-if="slideThreeRetailerComplete" style="margin-top: 10px;">
                                <arrow-pointer :is-animated="true" />
                            </div>
                            <button
                                :disabled="!slideThreeRetailerComplete"
                                :style="slideThreeRetailerComplete && 'opacity: 1; background-color: #0066FF'"
                                class="btn btn-primary btn-next" @click="() => setSlide(4)"
                            >
                                Next
                            </button>
                        </div>
                    </template>

                    <template v-if="slide === 4 && wizardData.roleType === 'Brand'">
                        <a class="previous-slide" href="#" @click.prevent="() => setSlide(3)">
                            <previous-arrow class="previous-arrow-container" />
                        </a>
                        <div class="brand-user-select-body">
                            <p class="brand-user-select">Which User would you like to view as?</p>

                            <div class="user-table-container">
                                <user-table :loading="wizardData.brandWizard.userListLoading" v-model:items="wizardData.brandWizard.brandUsers" @userSelected="(id, name, email) => setWizardData(4, id, name, email)" />
                            </div>
                        </div>
                        <div class="brand-info-text">
                            <div v-if="!wizardData.brandWizard.userListLoading" class="brand-user-select-container">
                                <div class="brand-user-select">
                                    <success-icon :scale="0.6" color="#0066FF" />
                                    <div class="brand-selected-text">Brand Selected: {{ wizardData.displayInfo.brandName }}</div>
                                </div>
                                <div v-if="wizardData.brandWizard.userId" class="brand-user-select">
                                    <success-icon :scale="0.6" color="#0066FF" />
                                    <div class="brand-selected-text">User Selected: {{ wizardData.displayInfo.userName }} ({{ wizardData.displayInfo.userEmail }})</div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!wizardData.brandWizard.userListLoading" class="link-to-all-users-select-user">
                            <a href="/users">Link to All Users</a>
                        </div>
                        <div class="horizontal-divider-modal-user-select" />
                        <div class="date-buttons-users">
                            <div v-if="slideFourComplete" style="margin-top: 10px;">
                                <arrow-pointer :is-animated="true" />
                            </div>
                            <button
                                :disabled="!slideFourComplete"
                                :style="slideFourComplete && 'opacity: 1; background-color: #0066FF'"
                                class="btn btn-primary btn-next" @click="wizardComplete"
                            >
                                Start
                            </button>
                        </div>
                    </template>
                    <template v-if="slide === 4 && wizardData.roleType === 'Retailer'">
                        <a class="previous-slide" href="#" @click.prevent="() => setSlide(3)">
                            <previous-arrow class="previous-arrow-container" />
                        </a>
                        <div class="brand-select-body">
                            <div class="brand-select-body-text">
                                <p>Which Retailer would you like to view as?</p>
                            </div>
                            <div>
                                <filter-dropdown
                                    v-if="wizardData.retailerWizard.retailersOptions.length > 0"
                                    option-key="id"
                                    v-model:selected="wizardData.retailerWizard.retailer"
                                    v-model:pauses="pauses"
                                    v-model:items="wizardData.brandWizard.brandUsers"
                                    :placeholder="'Select a Retailer'"
                                    :options="wizardData.retailerWizard.retailersOptions || []"
                                    :is-loading="wizardData.retailerWizard.retailerListLoading"
                                    :multiple="false"
                                    @update:selected="$event => setWizardData(4, $event)"
                                />
                                <ts-loading-spinner v-else width="40" />
                            </div>
                        </div>
                        <div class="link-to-all-users">
                            <a href="/users">Link to All Users</a>
                        </div>
                        <div class="horizontal-divider-modal" />
                        <div class="retailer-info-text">
                            <div v-if="wizardData.retailerWizard.brandId" class="brand-select-info-text">
                                <success-icon :scale="0.6" color="#0066FF" />
                                <div class="brand-selected-text">Brand Selected: {{ wizardData.displayInfo.brandName }}</div>
                            </div>
                            <div v-if="wizardData.displayInfo.retailerName" class="brand-select-info-text">
                                <success-icon :scale="0.6" color="#0066FF" />
                                <div class="retailer-selected-text">Retailer Selected: {{ wizardData.displayInfo.retailerName }}</div>
                            </div>
                        </div>
                        <div class="date-buttons">
                            <div v-if="slideFourRetailerComplete" style="margin-top: 10px;">
                                <arrow-pointer :is-animated="true" />
                            </div>
                            <button
                                :disabled="!slideFourRetailerComplete"
                                :style="slideFourRetailerComplete && 'opacity: 1; background-color: #0066FF'"
                                class="btn btn-primary btn-next" @click="() => setSlide(5)"
                            >
                                Next
                            </button>
                        </div>
                    </template>

                    <template v-if="slide === 5 && wizardData.roleType === 'Retailer'">
                        <a class="previous-slide" href="#" @click.prevent="() => setSlide(4, true)">
                            <previous-arrow class="previous-arrow-container" />
                        </a>

                        <div class="brand-user-select-body">
                            <div>
                                <p class="brand-user-select">Which User would you like to view as?</p>
                            </div>

                            <div class="user-table-container">
                                <user-table :loading="wizardData.retailerWizard.userListLoading" v-model:items="wizardData.retailerWizard.retailerUsers" @userSelected="(id, name, email) => setWizardData(5, id, name, email)" />
                            </div>
                        </div>
                        <div v-if="!wizardData.retailerWizard.userListLoading" class="link-to-all-users-select-user-retailer">
                            <a href="/users">Link to All Users</a>
                        </div>
                        <div class="horizontal-divider-modal-user-select" />
                        <div v-if="!wizardData.retailerWizard.userListLoading" class="retailer-info-text-users">
                            <div v-if="wizardData.retailerWizard.brandId" class="brand-select-info-text">
                                <success-icon :scale="0.6" color="#0066FF" />
                                <div class="brand-selected-text">Brand Selected: {{ wizardData.displayInfo.brandName }}</div>
                            </div>
                            <div v-if="wizardData.displayInfo.retailerName" class="brand-select-info-text">
                                <success-icon :scale="0.6" color="#0066FF" />
                                <div class="retailer-selected-text">Retailer Selected: {{ wizardData.displayInfo.retailerName }}</div>
                            </div>
                            <div v-if="wizardData.retailerWizard.userId" class="brand-select-info-text">
                                <success-icon :scale="0.6" color="#0066FF" />
                                <div class="retailer-selected-text">User Selected: {{ wizardData.displayInfo.userName }} - ({{ wizardData.displayInfo.userEmail }})</div>
                            </div>
                        </div>
                        <div class="date-buttons-users">
                            <div v-if="slideFiveRetailerComplete" style="margin-top: 10px;">
                                <arrow-pointer :is-animated="true" />
                            </div>
                            <button
                                :disabled="!slideFiveRetailerComplete"
                                :style="slideFiveRetailerComplete && 'opacity: 1; background-color: #0066FF'"
                                class="btn btn-primary btn-next" @click="wizardComplete"
                            >
                                Start
                            </button>
                        </div>
                    </template>
                </div>
            </template>
        </template>
    </popup-modal>
</template>

<script>
import PopupModal from '@/components/app/PopupModal.vue';
import ArrowPointer from '@/components/app/ArrowPointer.vue';
import UserTable from '@/components/profile_menu/UserTable.vue';
import axios from 'axios';
import BrownEyeIcon from '@/components/icons/BrownEyeIcon.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import PreviousArrow from '@/components/icons/PreviousArrow.vue';
import SuccessIcon from '@/components/icons/SuccessIcon.vue';
import TsLoadingSpinner from '@/components/app/TsLoadingSpinner.vue';


export default {
    name:       'ViewingWizardModal',
    components: {
        TsLoadingSpinner,
        SuccessIcon,
        FilterDropdown,
        BrownEyeIcon,
        UserTable,
        ArrowPointer,
        PreviousArrow,
        PopupModal,
    },
    data() {
        return {
            slide:   1,
            columns: {
                name:  'Name',
                email: 'Email',
            },
            wizardData: {
                resellersOptions: [],
                roleType:         '',
                retailerWizard:   {
                    brandId:             '',
                    retailer:            [],
                    userId:              '',
                    retailersOptions:    [],
                    retailerUsers:       [],
                    userListLoading:     false,
                    retailerListLoading: false,
                },
                brandWizard: {
                    userId:          '',
                    brandId:         '',
                    brandUsers:      [],
                    userListLoading: false,
                },
                displayInfo: {
                    brandName:    '',
                    userName:     '',
                    userEmail:    '',
                    retailerName: '',
                },
            },
            currentUrl: null,
        };
    },
    computed: {
        slideTwoComplete() {
            return this.wizardData.roleType !== '';
        },
        slideThreeComplete() {
            return this.wizardData.brandWizard.brandId !== '';
        },
        slideFourComplete() {
            return this.wizardData.brandWizard.userId !== '';
        },
        slideThreeRetailerComplete() {
            return this.wizardData.retailerWizard.brandId !== '';
        },
        slideFourRetailerComplete() {
            return this.wizardData.retailerWizard.retailer !== null;
        },
        slideFiveRetailerComplete() {
            return this.wizardData.retailerWizard.userId !== null;
        },
    },
    async mounted() {
        await axios.get('/api/oem-managers?show_disabled=true&show_reseller_id=true')
            .then(response => {
                this.wizardData.resellersOptions = response.data.filter(item => item.enabled === true);
                this.setSelected();
            })
            .catch(error => {
                console && console.log && console.log('Error when getting resellers list: ', error);
            });

        this.currentUrl = window.location.href;
    },
    methods: {
        setSlide(slide, back = false) {
            switch (slide) {
                case 2:
                    this.wizardData.roleType = '';
                    break;
                case 3:
                    this.wizardData.brandWizard.brandId = '';
                    this.wizardData.displayInfo.brandName = '';
                    this.wizardData.brandWizard.brandUsers = [];
                    this.wizardData.brandWizard.userId = '';
                    break;
                case 4:
                    if (back) {
                        this.wizardData.displayInfo.retailerName = '';
                    }
                    this.wizardData.retailerWizard.retailer = null;
                    this.wizardData.retailerWizard.retailerUsers = [];
                    break;
                case 5:
                    this.wizardData.retailerWizard.userId = null;
                    break;
                default:
                    break;
            }
            this.slide = slide;
        },
        cancel() {
            this.$emit('$cancel');
        },
        async wizardComplete() {
            let userId = this.wizardData.roleType === 'Brand' ? this.wizardData.brandWizard.userId : this.wizardData.retailerWizard.userId;
            try {
                const response = await axios.post(`/users/view-as/${userId}?current_url=${this.currentUrl}`);
                window.location.href = window.location.origin + '/' + response.data.redirect_url;
            } catch (error) {
                console.error('Error:', error);
            }
        },
        async setWizardData(slide, data) {
            if (this.wizardData.roleType === 'Brand') {
                switch (slide) {
                    case 2:
                        this.wizardData.roleType = data;
                        break;
                    case 3:
                        //find the brand name and store in our displayInfo
                        this.wizardData.displayInfo.brandName = this.wizardData.resellersOptions
                            .find((reseller) => reseller.reseller_id === this.wizardData.brandWizard.brandId)
                            .name;
                        this.wizardData.brandWizard.userListLoading = true;
                        await axios.get(`/users/retailer-user-list?reseller_id=${this.wizardData.brandWizard.brandId}`)
                            .then(response => {
                                this.wizardData.brandWizard.brandUsers = response.data.items.filter(item => item.is_active === true && item.role.type_id !== 1 && item.role.id !== 7);
                            })
                            .catch(error => {
                                console.error(error);
                            }).finally(() => {
                                this.wizardData.brandWizard.userListLoading = false;
                            });
                        break;
                    case 4:
                        this.wizardData.brandWizard.userId = data.id;
                        this.wizardData.displayInfo.userName = data.name;
                        this.wizardData.displayInfo.userEmail = data.email;
                        break;
                    default:
                        break;
                }
            } else {
                //retailer wizard slides
                switch (slide) {
                    case 2:
                        this.wizardData.roleType = data;
                        break;
                    case 3:
                        this.wizardData.displayInfo.brandName = this.wizardData.resellersOptions
                            .find((reseller) => reseller.reseller_id === this.wizardData.retailerWizard.brandId)
                            .name;
                        this.wizardData.retailerWizard.retailerListLoading = true;
                        axios.get(`/api/dealerships?reseller_id=${this.wizardData.retailerWizard.brandId}`)
                            .then((response) => {
                                this.wizardData.retailerWizard.retailersOptions = response.data.dealerships;
                            })
                            .catch(error => {
                                console.error(error);
                            }).finally(() => {
                                this.wizardData.retailerWizard.retailerListLoading = false;
                            });
                        break;
                    case 4:
                        this.wizardData.retailerWizard.userId = data.id;
                        let retailerName = this.wizardData.retailerWizard.retailersOptions
                            .find((retailer) => retailer.id === this.wizardData.retailerWizard.retailer)
                            .name;
                        this.wizardData.displayInfo.retailerName = retailerName;
                        this.wizardData.retailerWizard.userListLoading = true;
                        //let's get the users belonging to the retailer
                        await axios.get(`/users/retailer-user-list?dealership_id=${this.wizardData.retailerWizard.retailer}&simple=true`)
                            .then(response => {
                                this.wizardData.retailerWizard.retailerUsers = response.data.items.filter(item => item.is_active === true && item.role.type_id !== 1 && item.role.type_id === 4);
                            })
                            .catch(error => {
                                console.error(error);
                            }).finally(() => {
                                this.wizardData.retailerWizard.userListLoading = false;
                            });
                        break;
                    case 5:
                        this.wizardData.retailerWizard.userId = data.id;
                        this.wizardData.displayInfo.userName = data.name;
                        this.wizardData.displayInfo.userEmail = data.email;
                        break;

                    default:
                        break;
                }
            }
        },
    },
};
</script>


<style>
.viewing-wizard-modal.popup-modal .modal-content {
  border-top: 40px solid #F0F3F6;
  border-bottom: 30px solid #F0F3F6;
  border-right: 15px solid #F0F3F6;
  border-left: 15px solid #F0F3F6;
  background-color: white;
}

.modal-heading {
  position: absolute;
  top: -33px;
  left: -1px;
  color: black;
  font-size: 14px;
  font-family: Arial;
  font-weight: 700;
  line-height: 25.20px;
  word-wrap: break-word
}

.previous-slide {
  position: absolute;
    top: -70px;
    left: -80px;
}

.viewing-wizard-modal {
  .filter-dropdown.show-selected {
    max-width: 405px;
    font-size: 12px;
    text-align: left;
    min-width: 190px;
  }

  .filter-dropdown-options-list::-webkit-scrollbar {
    width: 8px !important;
  }
  .filter-dropdown-options-list::-webkit-scrollbar-track {
    background: #fff !important;
    border-radius: 5px !important;
  }
  .filter-dropdown-options-list::-webkit-scrollbar-thumb {
    background-color: #90BBFA !important;
    border-radius: 5px !important;
  }


  #closeModalButton {
     border-radius: 50%;
     opacity: 1;
     top: -36px;
     right: -10px;
     display: flex;
     justify-content: center;
     align-items: center;
     height: 35px;
     width: 35px;
   }
  .horizontal-divider-modal {
    width: 100%;
    height: 100%;
    border: 1px #F0F3F6 solid;
    margin-top: 200px
  }

  .horizontal-divider-modal-user-select {
    width: 110%;
    height: 100%;
    border: 1px #F0F3F6 solid;
    margin-top: 5px;
    margin-left: -20px;
  }

  .viewing-modal-body {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    width: 488px;


    .link-to-all-users {
      position: absolute;
      top: 249px;
      right: 2px;
      font-size: 10px;
      font-family: Arial;
      font-weight: 400;
      a {
        color: #0066FF !important;
      }
    }

    .link-to-all-users-role-type {
      position: absolute;
      top: 241px;
      right: 2px;
      font-size: 10px;
      font-family: Arial;
      font-weight: 400;
      a {
        color: #0066FF !important;
      }
    }


    .link-to-all-users-select-user {
        position: relative;
        font-size: 10px;
        font-family: Arial;
        font-weight: 400;
        text-align: right;
        margin-right: -25px;
      a {
        color: #0066FF !important;
      }
    }

    .eye-icon-container {
      display: inline-block;
    }

    .welcome-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      margin-left: 30px;

      .questions-body {
        margin-top: -10px;
        margin-left: 7px;
        color: #000;

        font-family: Arial;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 28px */
      }

      .viewing-mode-text {
        margin-right: 11px;
        margin-top: 5px;

        .brown-eye-icon-container {
          display: inline-block;
        }

        p {
          color: #000;
          font-family: Arial;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 50.4px */
        }
      }
    }

    .button-container {
      height: 246px;
    }

    .btn-blue {
      margin-top: 20px;
      align-self: flex-end;
      background: #0066FF;
      border-radius: 6px;
      border: none;
      color: white;
      font-size: 14px;
      font-family: Arial;
      font-weight: 700;
      word-wrap: break-word;
    }

    .role-select-body {
      margin-top: 20px;

      .role-text {
        text-align: left;
        margin-left: 20px;
        color: black;
        font-size: 18px;
        font-family: Arial;
        font-weight: 700;
        word-wrap: break-word
      }

      .btn-initial {
        width: 17%;
        background: white;
        border-radius: 4px;
        border: 2px #D6D9DB solid;
        justify-content: center;
        align-items: center;
        gap: 4px;
        display: inline-flex;
        color: black;
        font-family: Arial;
        font-weight: 700;
        margin-right: 8px;
        word-wrap: break-word;
      }

      .btn-selected {
        width: 17%;
        background: #0066FF;
        border-radius: 4px;
        border: 2px #0066FF solid;
        justify-content: center;
        align-items: center;
        gap: 4px;
        display: inline-flex;
        color: white;
        font-family: Arial;
        font-weight: 700;
        word-wrap: break-word
      }

      .brand-reseller-button-group {
        text-align: left;
        margin-left: 20px;
      }
    }

    .date-buttons {
      margin-top: 23px;
      display: flex;
      justify-content: flex-end;
        .btn {
            background: #0066FF;
        }
    }
    .date-buttons-users {
        margin-top: 23px;
        position: relative;
        left: 225px;
        .btn {
            background: #0066FF;
        }
        .pulsing-arrow-container {
            position: relative;
            right: 290px;
            top: 7px;
        }
    }
  }
    .modal-dialog {
        width: 700px;
    }
}

.btn-next {
  color: white;
  width: 20%;
  font-size: 24px;
  font-family: Arial;
  font-weight: 700;
  word-wrap: break-word;
  opacity: 0.50;
  background: #0066FF;
  border-radius: 4px;
  border: 2px #0066FF solid;
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: inline-flex
}

.brand-select-body {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-top: 20px;
  margin-left: 20px;

  .brand-select-body-text {
    color: black;
    font-size: 18px;
    font-family: Arial;
    font-weight: 700;
    word-wrap: break-word;
  }
}

.brand-retailer-select-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.brand-user-select-body {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-top: 20px;
  width: 70%;
  margin-left: 40px;
  margin-bottom: 50px;

  .div-input-search{
    width: 100%;
    border-left: 1px #D6D9DB solid;
    border-radius: 3px;
    border-right: 1px #D6D9DB solid;
    margin-bottom: 7px;
  }
  .input-search{
    width: 100%;
  }

  .brand-user-select {
    color: black;
    font-size: 18px;
    font-family: Arial;
    font-weight: 700;
    word-wrap: break-word;
    margin-left: -48px;
  }
    .user-table-container{
        width: 150%;
        margin-left: -47px;
        min-height: 200px;
    }
}

.brand-info-text {
  display: flex;
  align-items: center;
  position: absolute;
  top: 315px;
  left: 0;
  text-align: left;
  color: black;
  font-size: 10px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
    .brand-user-select-container {
        display: flex;
        flex-direction: column;
        .brand-user-select{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: -20px;
            .brand-selected-text {
                padding-left: 5px;
                width:405px;
                padding-bottom: 4px;
            }
        }
    }
}

.retailer-info-text{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  left: 0;
  text-align: left;
  color: black;
  font-size: 10px;
  font-family: Arial;
  font-weight: 400;
  word-wrap: break-word;
  .brand-selected-text {
    padding-left: 5px;
    padding-bottom: 4px;
  }
    .retailer-selected-text {
        padding-left: 5px;
    }
}

.retailer-info-text-users {
    margin-left: -20px;
}

.brand-select-info-text {
    display: flex;
    align-items: center;
    padding-top: 5px;
    left: 0;
    text-align: left;
    color: black;
    font-size: 10px;
    font-family: Arial;
    font-weight: 400;
    word-wrap: break-word;
    .brand-selected-text {
        padding-left: 5px;
        padding-bottom: 4px;
    }
    .retailer-selected-text {
        padding-left: 5px;
        margin-bottom: 4px;
    }
}

.link-to-all-users-select-user-retailer {
    position: relative;
    font-size: 10px;
    font-family: Arial;
    font-weight: 400;
    text-align: right;
    margin-right: -25px;
    a {
      color: #0066FF !important;
    }
}

.viewing-wizard-modal .filter-dropdown .filter-dropdown-menu.open{
  max-height: 250px !important;
}

</style>

<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="exports-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.66804 0V3.75H10.0022L6.66804 0ZM14.7976 8.64258L12.7138 6.29883C12.4696 6.02417 12.074 6.02417 11.8297 6.29883C11.5854 6.57349 11.5855 7.01836 11.8297 7.29316L12.87 8.4375H5.62613C5.28229 8.4375 5.00098 8.75391 5.00098 9.14062C5.00098 9.52734 5.28099 9.84375 5.62613 9.84375H12.87L11.8534 10.9872C11.7291 11.124 11.6692 11.3027 11.6692 11.4844C11.6692 11.666 11.7303 11.8441 11.8524 11.9815C12.0966 12.2562 12.4921 12.2562 12.7364 11.9815L14.8203 9.63779C15.0633 9.36328 15.0633 8.91797 14.7976 8.64258Z" fill="#85878F" />
                <path d="M10.0024 9.84375V13.5938C10.0024 14.3704 9.44262 15 8.75209 15H1.2503C0.559769 15 0 14.3701 0 13.5938V1.40625C0 0.62959 0.559769 0 1.2503 0H6.66826V3.75H10.0024V8.4375H5.62634C5.28251 8.4375 5.00119 8.75391 5.00119 9.14062C5.00119 9.52734 5.28121 9.84375 5.62634 9.84375H10.0024Z" fill="#DEE0E2" />
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'ExportsIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>

<style lang="scss">
.exports-icon-container{
}
</style>

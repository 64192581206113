const getGeneralViewSettings = () => {
    return [
        // Parent Brand Manager
        {
            showKey:   'brand_user',
            titleData: {
                title:       'Parent Brand Manager',
                description: '',
                id:          'parent-brand-manager',
            },
            columnsData: [
                {
                    ['enable-parent-brand-manager']: {
                        type:     'slot',
                        slotName: 'enable-parent-brand-manager',
                    },
                    ['brand-manager-accounts']: {
                        id:          'brand-manager-accounts',
                        label:       'BRAND MANAGER ACCOUNTS',
                        type:        'dropdown',
                        subType:     'multiple',
                        placeholder: 'Select Brand Managers Accounts',
                        options:     [], // Array of objects for filter dropdown with name and id
                        value:       [], // Array of ids
                    },
                },
            ],
        },
        // Brand Name and Logo
        {
            id:        'brand-name-and-logo',
            titleData: {
                title:       'Brand Name and Logo',
                description: 'Permitted formats include PNG and JPG. Optimal logo dimensions are 120px x 50px. ',
            },
            columnsData: [
                {
                    ['brand-name']: {
                        id:          'brand-name',
                        label:       'BRAND NAME',
                        type:        'text',
                        subType:     null,
                        placeholder: 'This is My Brand',
                        options:     null,
                        value:       '',
                    },
                    ['upload-logo']: {
                        type:     'slot',
                        slotName: 'upload-logo',
                    },
                },
            ],
        },
        // Brand Address
        {
            id:        'brand-address',
            titleData: {
                title:       'Brand Address',
                description: 'This is the address for the Brand’s official headquarters.',
            },
            columnsData: [
                {
                    ['brand-address-1']: {
                        id:          'brand-address-1',
                        label:       'ADDRESS',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Address 1',
                        options:     null,
                        value:       '',
                    },
                    ['brand-address-2']: {
                        id:          'brand-address-2',
                        label:       'ADDRESS',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Address 2',
                        options:     null,
                        value:       '',
                    },
                    ['brand-city']: {
                        id:          'brand-city',
                        label:       'CITY',
                        type:        'text',
                        subType:     null,
                        placeholder: 'City',
                        options:     null,
                        value:       '',
                    },
                    ['brand-state-province-and-zip-code']: [
                        {
                            id:          'brand-state-province',
                            label:       'STATE/PROVINCE',
                            type:        'dropdown',
                            subType:     'single',
                            placeholder: 'State/Province',
                            options:     [],
                            value:       '',
                        },
                        {
                            id:          'brand-zip-code',
                            label:       'ZIP CODE',
                            type:        'text',
                            subType:     null,
                            placeholder: 'Zip Code',
                            options:     null,
                            value:       '',
                        },
                    ],
                    ['brand-country']: {
                        id:          'brand-country',
                        label:       'COUNTRY',
                        type:        'dropdown',
                        subType:     'single',
                        placeholder: 'Country',
                        options:     [],
                        value:       [],
                    },
                    ['brand-time-zone']: {
                        id:          'brand-time-zone',
                        label:       'TIME ZONE',
                        type:        'dropdown',
                        subType:     'single',
                        placeholder: 'Time Zone',
                        options:     [], // Array of objects for filter dropdown with name and id
                        value:       [], // Array of ids
                    },
                    ['brand-phone']: {
                        id:          'brand-phone',
                        label:       'PHONE', // All caps
                        type:        'phone', // types: dropdown, phone, text.
                        placeholder: '(XXX)XXX-XXXX',
                        value:       '', // Phone format from input: tel:+5072222222
                        isValid:     false,
                    },
                },
            ],
        },
        // Social and Web Links
        {
            id:        'social-and-web-links',
            titleData: {
                title:       'Social and Web Links',
                description: 'These are the links to the Brand’s official social media pages.',
            },
            columnsData: [
                {
                    ['brand-website']: {
                        id:          'brand-website',
                        label:       'BRAND WEBSITE',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Brand Website URL',
                        options:     null,
                        value:       '',
                    },
                    ['brand-facebook']:
                    {
                        id:          'brand-facebook',
                        label:       'FACEBOOK',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Facebook URL',
                        options:     null,
                        value:       '',
                    },
                    ['brand-instagram']:
                    {
                        id:          'brand-instagram',
                        label:       'INSTAGRAM',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Instagram URL',
                        options:     null,
                        value:       '',
                    },
                    ['brand-twitter']:
                    {
                        id:          'brand-twitter',
                        label:       'TWITTER',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Twitter URL',
                        options:     null,
                        value:       '',
                    },
                    ['brand-linkedin']:
                    {
                        id:          'brand-linkedin',
                        label:       'LINKEDIN',
                        type:        'text',
                        subType:     null,
                        placeholder: 'LinkedIn URL',
                        options:     null,
                        value:       '',
                    },
                },
            ],
        },
        // Program Name and Brand Profile Doc
        {
            id:        'program-name-and-brand-profile-doc',
            titleData: {
                title:       'Program Name and Brand Profile Doc',
                description: '',
            },
            columnsData: [
                {
                    ['brand-program-name']:
                    {
                        id:          'brand-program-name',
                        label:       'PROGRAM NAME',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Program Name',
                        options:     null,
                        value:       '',
                    },
                    ['brand-profile-document']:
                    {
                        id:          'brand-profile-document',
                        label:       'BRAND PROFILE DOCUMENT',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Brand Profile Document URL',
                        options:     null,
                        value:       '',
                    },
                },
            ],
        },
    ];
};

export default getGeneralViewSettings();

<template>
    <div class="connect-textarea" :class="cssClass" @click="handleClick">
        <div v-if="edit" style="pointer-events: auto" :class="{'align-bottom': alignBottom, 'align-center': alignCenter}" v-html="formattedContent" />
        <textarea
            v-else
            :maxlength="limit"
            rows="1"
            :class="{'align-bottom': alignBottom, 'align-center': alignCenter}"
            :tabindex="focusable ? 0 : -1"
            :value="content"
            @input="handleInput"
            @blur="handleBlur"
        />
        <div class="character-limit">{{ characterLimit }}</div>
    </div>
</template>

<script>
import ScreenSize from '@/mixins/ScreenSize.js';
export default {
    mixins: [
        ScreenSize,
    ],
    props: {
        content: {
            type:     String,
            required: true,
        },
        limit: {
            type:     Number,
            required: true,
        },
        focusable: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        alignBottom: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        alignCenter: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        edit: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        valid: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        name: {
            type:     String,
            required: true,
        },
    },
    computed: {
        characterLimit() {
            if (this.limit <= 0) {
                return '';
            }
            return `${this.content.length} / ${this.limit}`;
        },
        cssClass() {
            return {
                invalid: this.invalid,
                valid:   !this.invalid,
                static:  this.edit,
            };
        },
        cssVarName() {
            return `--connect-textarea-${this.name}-height`;
        },
        invalid() {
            return !this.content.length || !this.valid;
        },
        formattedContent() {
            const phoneRegex = /(\(?\+?\d{1,4}?[-. ]?(\(?\d{1,3}?\)?[-. ]?\d{1,4}[-. ]?\d{1,4}[-. ]?\d{1,4}[-. ]?\d{1,4}))/g;
            const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
            const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;

            let style = (this.name == 'footer-bar' || this.name == 'cost-bar') ? 'color:white; text-decoration-color: white;' : '';

            let content = this.content.replace(phoneRegex, function(match) {
                return `<a style="${style}" href="tel:${match.replace(/\D/g, '')}">${match}</a>`;
            });

            content = content.replace(emailRegex, function(match) {
                return `<a style="${style}" href="mailto:${match}">${match}</a>`;
            });

            content = content.replace(urlRegex, function(match) {
                let url = match;
                if (!/^https?:\/\//i.test(match)) {
                    url = 'https://' + match;
                }

                return `<a style="${style}" href="${url}" target="_blank">${match}</a>`;
            });

            return content;
        },
    },
    watch: {
        content() {
            this.$nextTick(this.autoSize);
        },
        screenSizeX() {
            this.autoSize();
        },
        screenSizeY() {
            this.autoSize();
        },
    },
    mounted() {
        document.querySelector(':root').style.setProperty(this.cssVarName, 'initial');
        this.$el.children[0].style.height = `var(${this.cssVarName})`;
        this.autoSize();
    },
    methods: {
        autoSize() {
            document.querySelector(':root').style.setProperty(this.cssVarName, '0');

            let contentHeight = this.$el.children[0].scrollHeight - this.paddingHeight();
            let contentRows = Math.round(contentHeight / this.rowHeight());

            this.setHeightFromRows(contentRows);
        },
        handleBlur() {
            this.$emit('commit', this.content);
        },
        handleClick(event) {
            let textareaCoords = this.$el.children[0].getBoundingClientRect();

            if (event.y < textareaCoords.top && (this.alignBottom || this.alignCenter)) {
                this.$el.children[0].select();
                this.$el.children[0].selectionStart = 0;
                this.$el.children[0].selectionEnd = 0;
            } else if (event.y > textareaCoords.bottom && (!this.alignBottom || this.alignCenter)) {
                this.$el.children[0].select();
                this.$el.children[0].selectionStart = this.content.length;
                this.$el.children[0].selectionEnd = this.content.length;
            }
        },
        handleInput(event) {
            this.autoSize();
            this.$emit('update:content', event.target.value);
        },
        paddingHeight() {
            let style = window.getComputedStyle(this.$el.children[0]);
            return (parseFloat(style.paddingTop) + parseFloat(style.paddingBottom)) || 0;
        },
        rowHeight() {
            let style = window.getComputedStyle(this.$el.children[0]);
            return parseFloat(style.lineHeight);
        },
        setHeightFromRows(rows) {
            let height = rows * this.rowHeight() + this.paddingHeight();
            document.querySelector(':root').style.setProperty(this.cssVarName, `${height}px`);
        },
    },
};
</script>

<style lang="scss">
.connect-textarea {
    cursor: text;
    position: relative;
    padding: 3px 10px;

    &.static {
        border: none !important;

        div {
            margin-top: 4px;
            white-space: pre-wrap;
        }

        .character-limit {
            display: none;
        }
    }

    .align-bottom {
        bottom: 10px;
        left: 10px;
        position: absolute;
    }

    .align-center {
        top: 50%;
    }



    textarea {
        border: none;
        overflow: hidden;
        resize: none;
        width: calc(100% - 20px);

        &:focus-visible {
            outline: none;
        }
    }

    .character-limit {
        bottom: 0;
        font-size: 12px;
        font-weight: normal;
        position: absolute;
        right: 4px;
    }
}
</style>

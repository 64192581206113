import Curator from '@/curator.js';
import { uniqueHash } from '@/unique-hash.js';
import axios from '@/axios';
import _ from 'lodash';

export default {
    addClassifier(classifier) {
        if (!this.classifiers.includes(classifier)) {
            this.classifiers.push(classifier);
        }
    },
    async createOwner() {
        await axios.post(`/api/connects/${this.connectId}/potentials/${this.potentialId}/owner`).then(response => {
            this.ownerId = response.data.lister_owner_id;
        }).finally(() => this.loadOwnerId = true );
    },
    async linkAccount(platformId, externalId) {
        await axios.post(`/api/connects/${this.connectId}/potentials/${this.potentialId}/platforms/${platformId}/link`, {
            external_id: externalId,
            unique_hash: uniqueHash(),
        }).then(response => {
            let accounts = response.data.data;
            this.$patch({ platformLinks: accounts });

            this.businessPhone = Object.keys(this.platformLinks).reduce((acc, cur) => acc || this.platformLinks[cur]?.phone, this.businessPhone);
        });
    },
    async loadRegionOptions(poolId) {
        this.regionOptions = (await Curator.get(`/pool/${poolId}/regions`)).data.regions;
    },
    async loadLanguageOptions() {

        const oemManager = axios.get(`/api/oem-managers/${this.oemManagerId}/posting-defaults`).then(response => response.data);
        this.oemManager = await oemManager;
        this.defaultRetailerLanguageOptions = this.oemManager.languages?.toString().split(',').map(Number);
        axios({
            method:  'get',
            url:     window.Lotvantage.curatorEndpoint + '/languages',
            headers: {
                'Token':        window.Lotvantage.curatorToken,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            const languages = response.data.languages;
            this.languageOptions = languages.filter(item => this.defaultRetailerLanguageOptions.includes(item.id));
        });

        this.languages = this.oemManager.default_languages;
    },
    removeClassifier(classifier) {
        if (this.classifiers.includes(classifier)) {
            this.classifiers.splice(this.classifiers.indexOf(classifier), 1);
        }
    },
    async savePotential(createDealership = false) {
        let url = this.potentialId
            ? `/api/connects/${this.connectId}/potentials/${this.potentialId}`
            : `/api/connects/${this.connectId}/potentials/`;

        let saveData = _.cloneDeep(this.$state);
        // Just the tip
        saveData.classifiers = saveData.classifiers.filter(c => !saveData.classifiers.find(c2 => c2.parentId == c.id));

        if (this.potentialId) {
            await axios.post(url, {data: saveData, createDealership: createDealership})
                .then((res) => {
                    this.dealershipId = res.data.potential.dealership_id;
                });
        } else {
            await axios.post(url, {data: saveData})
                .then((res) => {
                    this.potentialId = res.data.potential.id;
                });
        }
    },
    unlinkAccount(platformId) {
        axios.post(`/api/connects/${this.connectId}/potentials/${this.potentialId}/platforms/${platformId}/unlink`).then(() => {
            this.$patch({
                platformLinks: { [platformId]: null},
            });
        });
    },
};

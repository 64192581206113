import filestack_config from '@/filestack_config.js';
import * as filestack from 'filestack-js';
import md5 from 'md5';

const client = filestack.init(filestack_config.key);

/*
|--------------------------------------------------------------------------
| VideoUploader
|--------------------------------------------------------------------------
|
| This uploads videos in the way that we seem to always want. This code was
| duplicated across three different Vue.js files, so now it lives here in
| a cool, DRY place.
|
*/

class MediaUploader {
    constructor() {
        this.instanceConfig = {
            storeOptions: {
                path: md5((new Date).toISOString() + Math.random().toString()),
            },
        };
        this.max_duration = 240 * 60; // 4 Hours
        this.max_size = 5 * 1024 * 1024 * 1024; // 5 GB
        this.url = null;
        this.urls = [];
        this.metadata = null;
        this.stat = null;
        this.video_name = null;
    }

    loadFilestack(config) {
        return client.picker(config).open();
    }

    getClient() {
        return client;
    }

    validateVideo() {
        return new Promise((resolve, reject) => {
            var errors = [];

            if (this.metadata.length === 0) {
                errors.push('The video metadata was proccessed but had errors.');
            } else {
                if (this.isVideoFormatUnsupported()) {
                    errors.push('The video format is not supported.');
                }
                if (this.isVideoDurationUnsupported()) {
                    errors.push('The video length is over the 45 minute limit.');
                }
                if (this.isVideoSizeUnsupported()) {
                    errors.push('The video size is over the 1 GB limit.');
                }
            }

            if (errors.length > 0) {
                reject(errors.join('\n'));
            } else {
                resolve();
            }
        });
    }

    isVideoFormatUnsupported() {
        var supported = ['3g2', '3gp', '3gpp', 'asf', 'avi', 'dat', 'divx', 'dv', 'f4v', 'flv', 'm2ts', 'm4v', 'mkv', 'mod', 'mov', 'mp4', 'mpe', 'mpeg', 'mpeg4', 'mpg', 'mts', 'nsv', 'ogm', 'ogv', 'qt', 'tod', 'ts', 'vob', 'wmv'];
        var formats = this.metadata.format.format_name.split(',');
        var common = supported.filter(function (value) {
            return formats.indexOf(value) > -1;
        });

        return common.length > 0;
    }

    isVideoDurationUnsupported() {
        return this.metadata.format.duration > this.max_duration;
    }

    isVideoSizeUnsupported() {
        return this.metadata.format.size > this.max_size;
    }
}
MediaUploader.KEY = filestack_config.key;
MediaUploader.MAX_SIZE = filestack_config.max_size;
MediaUploader.USER_CANCELLED = 'User cancelled.';

MediaUploader.Video = {
    fromSources: [
        'local_file_system',
        'url',
        'video',
        'instagram',
        'facebook',
        'onedrive',
        'googledrive',
        'dropbox',
        'gmail',
        'box',
    ],
    accept:  ['video/*'],
    maxSize: 5 * 1024 * 1024 * 1024, //5gb
    storeTo: {
        access: 'public',
    },
};

MediaUploader.Photo = {
    fromSources: [
        'local_file_system',
        'url',
        'imagesearch',
        'instagram',
        'facebook',
        'onedrive',
        'googledrive',
        'dropbox',
        'gmail',
        'box',
    ],
    accept:          ['image/*'],
    maxFiles:        10,
    maxSize:         30 * 1024 * 1024, // 30mb
    disableTransformer: true,
    storeTo: {
        access: 'public',
    },
};

MediaUploader.Pdf = {

    fromSources: [
        'local_file_system',
        'url',
        'onedrive',
        'googledrive',
        'dropbox',
        'gmail',
        'box',
    ],
    accept:  ['.pdf'],
    maxSize: 10 * 1024 * 1024,
    storeTo: {
        access: 'public',
    },
};

export default MediaUploader;

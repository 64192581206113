<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="succes-icon-container"
    >
        <template #content>
            <svg v-if="type === 'thin'" :width="width" :height="height" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.5" cy="7.5" r="7" :stroke="color" />
                <line x1="4.41421" y1="8" x2="6" y2="9.58579" :stroke="color" stroke-width="2" stroke-linecap="round" />
                <line x1="11" y1="5.41421" x2="6.41421" y2="10" :stroke="color" stroke-width="2" stroke-linecap="round" />
            </svg>
            <svg v-else :width="width" :height="height" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7.5" cy="7.5" r="6.5" fill="white" :stroke="color" stroke-width="2" />
                <line x1="5.26086" y1="7.86658" x2="6.4002" y2="9.00592" :stroke="color" stroke-width="1.5" stroke-linecap="round" />
                <line x1="10.0664" y1="5.99407" x2="6.72707" y2="9.33341" :stroke="color" stroke-width="1.5" stroke-linecap="round" />
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'SuccessIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
    props:      {
        type: {
            type:     String,
            required: false,
            default:  'thin',
        },
        color: {
            type:     String,
            required: false,
            default:  '#5CB85B',
        },
    },
};

</script>
<style lang='scss'>
.succes-icon-container{
    > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>

<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="notes-icon-gray-container"
    >
        <template #content>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.0001 11.4377L11.4287 16V11.4377H16.0001Z" fill="#85878F"/>
                <path d="M14.2857 0H1.71429C0.7675 0 0 0.76596 0 1.71085V14.257C0 15.2337 0.7675 16 1.71429 16H11.4286V11.4377H16V1.74292C16 0.798038 15.2321 0 14.2857 0Z" fill="#85878F" />
            </svg>
        </template>
    </generic-icon>
</template>


<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'NotesIconGray',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>
<style lang='scss'>
.notes-icon-gray-container {}
</style>

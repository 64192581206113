<template>
    <div class="posts-toggle-container">
        <div class="title-and-toggle-container">
            <strong>{{ title }}</strong>
            <toggle-switch :model-value="value" switch-style="slide" @update:modelValue="(payload) => $emit('input', payload)" />
        </div>
        <span class="explanation-text">{{ explanationText }}</span>
    </div>
</template>
<script>
import ToggleSwitch from '@/components/app/ToggleSwitch.vue';

export default {
    components: {
        ToggleSwitch,
    },
    emits: ['input'],
    props:      {
        explanationText: {
            type:     String,
            required: true,
        },
        title: {
            type:     String,
            required: true,
        },
        value: {
            type:    Boolean,
            default: false,
        },
    },
};
</script>
<style lang="scss">
.posts-toggle-container {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    background: #F0F3F6;
    border-radius: 4px;
    min-width: 255px;
    width: 255px;
    padding: 10px;
    height: max-content;
    .title-and-toggle-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .explanation-text {
        padding-top: 10px;
    }
}
</style>

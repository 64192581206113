<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        :scale="scale"
        class="google-icon-container"
    >
        <template #content>
            <svg :width="scale * 25" :height="scale * 25" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34.2563 17.8938C34.2563 16.7417 34.1542 15.648 33.9792 14.5834H17.5V21.1605H26.9354C26.5125 23.3188 25.2729 25.1417 23.4354 26.3813V30.7563H29.0646C32.3604 27.7084 34.2563 23.2167 34.2563 17.8938Z" fill="#4285F4" />
                <path d="M17.4986 35C22.2236 35 26.1757 33.425 29.0632 30.7563L23.434 26.3813C21.859 27.4313 19.8611 28.0729 17.4986 28.0729C12.9341 28.0729 9.06947 24.9958 7.68405 20.8396H1.87988V25.3458C4.7528 31.0625 10.659 35 17.4986 35Z" fill="#34A853" />
                <path d="M7.68542 20.8395C7.32083 19.7895 7.13125 18.6665 7.13125 17.4999C7.13125 16.3332 7.33542 15.2103 7.68542 14.1603V9.65405H1.88125C0.685415 12.0166 0 14.6707 0 17.4999C0 20.329 0.685415 22.9832 1.88125 25.3457L7.68542 20.8395Z" fill="#FBBC05" />
                <path d="M17.4986 6.92708C20.0799 6.92708 22.3841 7.81667 24.207 9.55209L29.1945 4.56458C26.1757 1.73542 22.2236 0 17.4986 0C10.6591 0 4.7528 3.9375 1.87988 9.65417L7.68405 14.1604C9.06947 10.0042 12.9341 6.92708 17.4986 6.92708Z" fill="#EA4335" />
            </svg>
        </template>
    </generic-icon>
</template>


<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'GoogleIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>
<style lang='scss'>
.google-icon-container{}
</style>

<template>
    <div v-if="originalItems.length > 0 || searchTerm !== ''" class="table-container">
        <input v-model="searchTerm" class="user-search" type="text" placeholder="Search Name" @input="searchUser">
        <div class="table-scroll">
            <table class="table-body">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>User Role</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in originalItems" :key="item.id" :class="{ 'white-row': index % 2 === 0, 'grey-row': index % 2 === 1, 'selected': item.id === selectedRow, 'selected-color':item.id === selectedRow }" @click="selectUser(item.id, item.name, item.email)">
                        <td>{{ item.name }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.role.name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-else>
        <div v-if="originalItems.length === 0 && !loading" class="no-user-alert">
            No Users Attached to this Brand
        </div>
        <TsLoadingSpinner v-if="loading" width="40" />
    </div>
</template>

<script>
import TsLoadingSpinner from '@/components/app/TsLoadingSpinner.vue'; // Make sure to install axios via npm

export default {
    name:       'UserTable',
    components: {TsLoadingSpinner},
    props:      {
        items: {
            type:     Array,
            required: true,
        },
        loading: {
            type:    Boolean,
            default: false,
        },
    },
    data() {
        return {
            searchTerm:    '',
            selectedRow:   null,
            originalItems: this.items,
        };
    },

    watch: {
        items() {
            this.originalItems = this.items;
        },
    },
    methods: {
        searchUser() {
            this.originalItems = this.items.filter(item => {
                return item.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                item.email.toLowerCase().includes(this.searchTerm.toLowerCase());
            });
        },
        selectUser(id, name, email) {
            this.selectedRow = id;
            this.$emit('userSelected', {id, name, email});
        },
    },
};
</script>

<style scoped>
.table-container {
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;


  .table-body tbody tr td {
    text-align: left;
    padding: 7px;
    max-width: 190px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

    .table-scroll {
        max-height: 200px;
        overflow-y: auto;
        border: 1px #D6D9DB solid;
        border-radius: 4px;
        border-collapse: collapse;

        .table-body {
            width: 100%;
            max-height: 200px;
            overflow-y: auto;
            font-size: 12px;
        }

        .selected {
            background-color: #0066FF !important;
        }

        .grey-row {
            background-color: #F0F3F6;
        }

        .white-row {
            background-color: #FFFFFF;
        }

        table thead {
            position: sticky;
            top: 0;
        }

        table thead th {
            background-color: #F0F3F6;
            padding: 10px;
        }
        table thead th:nth-child(-n+2) {
            border-right: 1px solid #D6D9DB;
        }

        table tbody tr td {
            text-align: left;
            padding: 7px;
        }

        table tbody tr:hover {
            cursor: pointer;
        }

        table tbody tr td:nth-child(-n+2) {
            text-align: left;
            padding: 7px;
            border-right: 1px solid #D6D9DB;
        }

        table tbody tr td {
            color: #0066FF;
        }

        .selected td {
            color: white !important;
        }

        table thead th:nth-child(2) {
            padding-left: 7px;
        }
    }

  .user-search {
    margin-bottom: 10px;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: sticky;
    top: 0;
  }
}
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
}

.no-user-alert {
  width: 80%;
  color: #000;
  background-color: #F0F3F6;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  line-height: 16.80px;
  word-wrap: break-word;
  padding: 5px;
  margin-bottom: 170px;
}

.table-scroll::-webkit-scrollbar {
  width: 8px;
}
.table-scroll::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
}
.table-scroll::-webkit-scrollbar-thumb {
  background-color: #90BBFA;
  border-radius: 5px;
}
</style>

<template>
    <div class="destination-logins">
        <template v-for="platformId in sortedPlatforms" :key="platformId">
            <template v-if="platforms[platformId]">
                <destination-login
                    :class="platformClasses[platformId]"
                    :type="type"
                    :dealership-id="id"
                    :external="external"
                    v-bind="platforms[platformId]"
                    :connect-help-link="connectHelpLinks[platformId]"
                    @update:badImage="() => handleBadImage(platformId)"
                    @link="() => link(platformId)"
                    @unlink="() => toggleUnlinkModal(platformId)"
                    @update:selectedAccount="event => selectAccount(event, platformId)"
                    @openFbInfoModal="toggleFbModal"
                    @toggleNewPlatformModal="() => toggleNewPlatformModal(platformId)"
                    @toggleSnoozeResumeModal="() => toggleSnoozeResumeModal(platformId)"
                />
            </template>
        </template>
        <template v-if="snoozeModalPlatform">
            <snooze-resume-modal
                :is-snoozed="platforms[snoozeModalPlatform].snoozed"
                :dealership-id="id"
                :platform-id="snoozeModalPlatform"
                :popup-modal-padding-top="popupModalPaddingTop"
                @close="toggleSnoozeResumeModal"
            />
        </template>
        <template v-else-if="newServicePlatform">
            <new-platform-popup-modal
                :selected-service="newServicePlatform"
                :should-display-brand-manager-info="false"
                @close="toggleNewPlatformModal"
            />
        </template>
        <template v-if="openFbModal">
            <popup-modal
                class="fb-modal"
                modal-size="md"
                :hide-yes-button="true"
                :display-no-text="'Close'"
                @close="toggleFbModal"
                @on-no-button-click="toggleFbModal"
            >
                <template #icon>
                    <circle-info-icon :scale="1.3" />
                </template>
                <template #header>
                    <span class="popup-header">Learn More</span>
                </template>
                <template #content>
                    <div class="popup-content">
                        <span class="content-title">How to Connect your Facebook Business Page:</span>
                        <br>
                        <br>
                        <ol>
                            <li>Click blue 'Connect' button</li>
                            <li>Login with your personal Facebook credentials</li>
                            <ol type="a">
                                <li>Completing this action is not linking your personal account, it is only confirming admin status. Your private data is never stored or shared.</li>
                            </ol>
                            <li>Click 'Continue as' button</li>
                            <li>Select one of the 'Opt In' options for desired FB Businesses and click 'Continue'</li>
                            <li>Select one of the 'Opt In' options for desired FB Pages and click 'Continue'</li>
                            <li>Select one of the 'Opt In' options for desired Instagram Accounts and click 'Continue', if applicable</li>
                            <li>Click 'Save' on next prompt</li>
                            <li>Click 'Got it', you will be brought back to ThumbStopper's page</li>
                            <li>Select the Business Page from the dropdown you want to connect</li>
                        </ol>
                    </div>
                </template>
            </popup-modal>
        </template>
        <template v-if="unlinkPlatform">
            <popup-modal
                class="unlink-modal"
                modal-size="md"
                :display-no-text="'Close'"
                :padding-top="popupModalPaddingTop"
                @close="toggleUnlinkModal"
                @on-yes-button-click="() => unlink(unlinkPlatform)"
                @on-no-button-click="toggleUnlinkModal"
            >
                <template #icon>
                    <circle-info-icon :scale="1.3" />
                </template>
                <template #header>
                    <span class="popup-header">Disconnect Destination</span>
                </template>
                <template #content>
                    <div class="popup-content" v-html="unlinkText" />
                </template>
            </popup-modal>
        </template>
        <template v-if="openUnauthorizedModal">
            <popup-modal
                class="unauthorized-modal"
                modal-size="md"
                :hide-yes-button="true"
                :hide-no-button="true"
                @close="toggleUnauthorizedModal"
            >
                <template #icon>
                    <circle-info-icon :scale="1.3" />
                </template>
                <template #header>
                    <span class="popup-header">Unauthorized Connection</span>
                </template>
                <template #content>
                    <div class="popup-content">
                        <p>
                            You are currently not an admin of any Facebook Business page.
                            In order to connect the account, you must be assigned as Admin of the Facebook Business page.
                        </p>
                        <p>
                            <a href="https://www.facebook.com/help/187316341316631?helpref=faq_content" target="_blank">Learn More</a>
                        </p>
                    </div>
                </template>
            </popup-modal>
        </template>
    </div>
</template>

<script>
import axios from '@/axios';
import Linker from '@/linker.js';
import Lister from '@/lister.js';
import Platforms from '@/platform.js';
import PositionUtils from '@/positionUtils.js';
import { uniqueHash } from '@/unique-hash.js';

import ScreenSize from '@/mixins/ScreenSize.js';

import CircleInfoIcon from '@/components/icons/CircleInfoIcon.vue';
import DestinationLogin from '@/components/platforms/DestinationLogin.vue';
import PopupModal from '@/components/app/PopupModal.vue';
import NewPlatformPopupModal from '@/components/content_modal/popup_modals/NewPlatformPopupModal.vue';
import SnoozeResumeModal from '@/components/content_modal/popup_modals/SnoozeResumeModal.vue';
import { userStore } from '@/stores/pinia.js';
import {mapStores} from 'pinia';

export default {
    components: {
        CircleInfoIcon,
        DestinationLogin,
        PopupModal,
        NewPlatformPopupModal,
        SnoozeResumeModal,
    },
    mixins: [
        ScreenSize,
    ],
    props: {
        id: {
            type:     Number,
            required: true,
        },
        type: {
            type:     String,
            required: false,
            default:  'dealership',
        },
        connectId: {
            type:     Number,
            required: false,
            default:  null,
        },
        platformIds: {
            type:     Array,
            required: false,
            default:  () => Object.keys(Platforms).filter(key => parseInt(key)),
        },
        narrow: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        external: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        hidePause: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        popupModalPaddingTop: {
            type:     String,
            required: false,
            default:  '0px',
        },
    },
    data() {
        return {
            icons: {
                1: 'FacebookIcon',
                2: 'TwitterIcon',
                3: 'InstagramIcon',
                4: 'GoogleIcon',
                5: 'LinkedinIcon',
            },
            listerOwnerId:         null,
            platforms:             {},
            newServicePlatform:    null,
            snoozeModalPlatform:   null,
            openFbModal:           false,
            openUnauthorizedModal: false,
            unlinkPlatform:        null,
            unlinkText:            null,
        };
    },
    computed: {
        ...mapStores(userStore),
        connectHelpLinks() {
            let links = {
                [Platforms.FACEBOOK]:  '',
                [Platforms.TWITTER]:   '',
                [Platforms.INSTAGRAM]: '',
                [Platforms.GBP]:       '',
                [Platforms.LINKEDIN]:  '',
            };
            let fb = this.platforms[Platforms.FACEBOOK];
            let ig = this.platforms[Platforms.INSTAGRAM];
            if (fb?.linked && (ig?.hasService || this.type == 'potential') && !ig?.linked) {
                links[Platforms.INSTAGRAM] = 'https://accountscenter.facebook.com/profiles';
            }
            return links;
        },
        platformClasses() {
            let classes = {};
            this.platformIds.forEach(platformId => {
                if (this.platforms[platformId]) {
                    classes[platformId] = {
                        narrow:   this.narrow,
                        linked:   this.platforms[platformId].linked,
                        snoozed:  this.platforms[platformId].snoozed,
                        unlinked: !this.platforms[platformId].linked
                            && (this.platforms[platformId].hasService || this.type == 'potential'),
                        unavailable: !this.platforms[platformId].hasService && this.type != 'potential',
                    };
                }
            });
            return classes;
        },
        sortedPlatforms() {
            return this.platformIds.toSorted((a, b) => {
                if (a == Platforms.FACEBOOK) {
                    return -1;
                } else if (b == Platforms.FACEBOOK) {
                    return 1;
                } else if (a == Platforms.INSTAGRAM) {
                    return -1;
                } else if (b == Platforms.INSTAGRAM) {
                    return 1;
                }
                return a - b;
            });
        },
        platformsConnected() {
            return this.platformIds.filter(platformId => this.platforms[platformId]?.linked).length;
        },
    },
    watch: {
        $props: {
            handler() {
                this.load();
            },
            immediate: true,
            deep:      true,
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        emitLinked(platformId, linked, externalId = null) {
            this.$emit('update:linked', {
                platformId: platformId,
                linked:     linked,
                externalId: externalId,
            });
        },
        linkerUrl(platformId) {
            let hash = uniqueHash();
            return `${window.Lotvantage.listerEndpoint}owner/${this.listerOwnerId}/platform/${platformId}/login`
                + `?token=${window.Lotvantage.listerToken}&unique_hash=${hash}`;
        },
        handleBadImage(platformId) {
            this.platforms[platformId].badImage = true;
        },
        async link(platformId) {
            switch (this.type) {
                case 'potential':
                    return this.linkPotential(platformId);
                case 'dealership':
                case 'retailer':
                    return this.linkDealership(platformId);
                default:
                    return Promise.reject(`Unknown type: ${this.type}`);
            }
        },
        async linkDealership(platformId) {
            let handler = event => {
                if (`${event.origin}/` != window.Lotvantage.listerEndpoint) {
                    return;
                }

                if (event.data.action == 'openLinker') {
                    window.removeEventListener('message', handler);

                    if (event.data?.error_message) {
                        if (event.data.error_message != 'Invalid code/state request') {
                            console.error(event.data.error_message);
                        }
                    } else {
                        this.linkDealership(platformId);
                    }
                }
            };
            let linkPlatformId = Platforms.PARENT_PLATFORMS[platformId] || platformId;
            return Linker.login(linkPlatformId, this.linkerUrl(linkPlatformId), handler)
                .then(accounts => {
                    if (!accounts) {
                        return;
                    }
                    if (linkPlatformId == Platforms.FACEBOOK && !accounts.length) {
                        this.toggleUnauthorizedModal();
                        return;
                    }
                    accounts = accounts.map(account => {
                        let name = account.name;
                        if (account.instagram_business_account?.name) {
                            name += ` (${account.instagram_business_account.name})`;
                        }
                        return {
                            ...account,
                            name: name,
                        };
                    });
                    this.platforms[platformId].availableAccounts = accounts;
                });
        },
        async linkPotential(platformId) {
            let handler = event => {
                if (`${event.origin}/` != window.Lotvantage.listerEndpoint) {
                    return;
                }

                if (event.data.action == 'openLinker') {
                    window.removeEventListener('message', handler);

                    if (event.data?.error_message) {
                        if (event.data.error_message != 'Invalid code/state request') {
                            console.error(event.data.error_message);
                        }
                    } else {
                        this.linkPotential(platformId);
                    }
                }
            };
            let linkPlatformId = Platforms.PARENT_PLATFORMS[platformId] || platformId;
            return Linker.login(linkPlatformId, this.linkerUrl(linkPlatformId), handler)
                .then(accounts => {
                    if (!accounts) {
                        return;
                    }
                    if (linkPlatformId == Platforms.FACEBOOK && !accounts.length) {
                        this.toggleUnauthorizedModal();
                        return;
                    }
                    accounts = accounts.map(account => {
                        let name = account.name;
                        if (account.instagram_business_account?.name) {
                            name += ` (${account.instagram_business_account.name})`;
                        }
                        return {
                            ...account,
                            name: name,
                        };
                    });
                    this.platforms[platformId].availableAccounts = accounts;
                });
        },
        async unlink(platformId) {
            if (Platforms.PARENT_PLATFORMS[platformId]) {
                return this.unlink(Platforms.PARENT_PLATFORMS[platformId]);
            }
            switch (this.type) {
                case 'potential':
                    return this.unlinkPotential(platformId);
                case 'dealership':
                case 'retailer':
                    return this.unlinkDealership(platformId);
                default:
                    return Promise.reject(`Unknown type: ${this.type}`);
            }
        },
        async unlinkDealership(platformId) {
            return Linker.unlinkDealership(this.id, platformId, this.external)
                .then(() => this.emitLinked(platformId, false))
                .then(this.loadDealership)
                .finally(() => {
                    this.userStore.hasTokenSet = this.platformsConnected > 0;
                });
        },
        async unlinkPotential(platformId) {
            return Linker.unlinkPotential(this.connectId, this.id, platformId)
                .then(() => this.emitLinked(platformId, false))
                .then(this.loadPotential);
        },
        async relink(platformId) {
            return this.unlink(platformId).then(() => this.link(platformId));
        },
        async load() {
            switch (this.type) {
                case 'potential':
                    return this.loadPotential();
                case 'dealership':
                case 'retailer':
                    return this.loadDealership();
                default:
                    return Promise.reject(`Unknown type: ${this.type}`);
            }
        },
        async loadDealership() {
            let token = (new URLSearchParams(window.location.search)).get('token');
            let q = this.external ? 'no_login' : '';
            let external = this.external ? '/external' : '';

            let promises = [
                axios.get(`/api/dealerships/${this.id}${external}?token=${token}`)
                    .then(response => this.listerOwnerId = response.data.lister.owner_id),
                axios.get(`/api/dealerships/${this.id}/platforms${external}?${q}`)
                    .then(response => response.data.platforms),
            ];

            return Promise.all(promises).then(results => this.loadPlatforms(results[1]));
        },
        async loadPlatforms(platformData = null) {
            if (!this.listerOwnerId) {
                return Promise.reject('No lister owner id found');
            }

            let promises = this.platformIds.map(platformId => {
                return Lister.get(`/owner/${this.listerOwnerId}/platform/${platformId}`)
                    .then(response => {
                        return {
                            platformId: platformId,
                            ...response.data.data,
                            ...(platformData ? platformData.find(p => p.id == platformId) : {}),
                        };
                    })
                    .then(this.setPlatform);
            });
            return Promise.all(promises);
        },
        async loadPotential() {
            if (this.id === 0) {
                return Promise.all(this.platformIds.map(id => this.setPlatform({ platformId: id })));
            }
            return axios.get(`/api/connects/${this.connectId}/potentials/${this.id}`)
                .then(response => this.listerOwnerId = response.data.lister_owner_id)
                .then(() => this.loadPlatforms());
        },
        async selectAccount(event, platformId) {
            platformId = Platforms.PARENT_PLATFORMS[platformId] || platformId;
            let externalId = event.value;
            let handlerIndex = event.handlerIndex;

            if (!externalId) {
                return Promise.reject('No externalId selected');
            }
            let accounts = this.platforms[platformId].availableAccounts;

            let result = await Linker.selectAccount(accounts, platformId, externalId, this.linkerUrl(platformId), handlerIndex);
            if (result instanceof Array) {
                this.platforms[platformId].availableLocations = result;
                return Promise.resolve();
            }

            switch (this.type) {
                case 'potential':
                    return Linker.linkPotential(this.connectId, this.id, platformId, externalId)
                        .then(() => this.emitLinked(platformId, true, externalId))
                        .then(this.loadPotential);
                case 'dealership':
                case 'retailer':
                    return Linker.linkDealership(this.id, platformId, externalId, this.external)
                        .then(() => this.emitLinked(platformId, true, externalId))
                        .then(this.loadDealership)
                        .finally(() => {
                            this.userStore.hasTokenSet = this.platformsConnected > 0;
                        });
                default:
                    return Promise.reject(`Unknown type: ${this.type}`);
            }
        },
        setPlatform(data) {
            let platformId = parseInt(data.platformId);
            let platform = {
                platformId:         platformId,
                platformName:       Platforms[platformId],
                name:               data.account?.name || '-',
                linked:             (data.linked_at && !data.unlinked_at) || false,
                badImage:           false,
                picture:            data.account?.picture || '',
                externalId:         data.account?.external_id || '',
                username:           data.account?.username || '',
                agency:             data.token?.is_agency || false,
                hasService:         data.has_service || false,
                snoozed:            data.snoozed || false,
                snoozedFrom:        data.snoozed_from || '',
                snoozedTo:          data.snoozed_to || '',
                pauses:             data.pauses || [],
                availableAccounts:  [],
                availableLocations: [],
                parentPlatform:     Platforms.PARENT_PLATFORMS[platformId] || NaN,
                canLink:            data.has_service || this.type == 'potential',
                href:               '#',
                icon:               this.icons[platformId],
                hidePause:          this.hidePause,
                nonce:              0,
            };

            if (platformId == Platforms.FACEBOOK) {
                platform.href = `https://facebook.com/${platform.externalId}`;
            } else if (platformId == Platforms.TWITTER) {
                platform.href = `https://twitter.com/${platform.username}`;
            } else if (platformId == Platforms.INSTAGRAM) {
                platform.href = `https://instagram.com/${platform.username}`;
            } else if (platformId == Platforms.LINKEDIN) {
                platform.href = `https://linkedin.com/company/${platform.externalId}`;
            }
            this.platforms[platformId] = platform;
        },
        async setModalMaskPosition() {
            // I know nextTick is frowned upon, but I need to wait for the DOM to re-render
            return this.$nextTick().then(() => {
                let modal = document.querySelector('.modal-mask');
                if (modal) {
                    modal.style = '';
                    let calculated = PositionUtils.calculatePosition(modal);
                    for (const key in calculated) {
                        modal.style[key] = calculated[key];
                    }
                }
            });
        },
        toggleFbModal() {
            this.$emit('modal-triggered');
            this.openFbModal = !this.openFbModal;
            this.setModalMaskPosition();
        },
        toggleUnlinkModal(platformId = null) {
            this.$emit('modal-triggered');
            if (this.unlinkPlatform) {
                this.platforms[this.unlinkPlatform].nonce++;
                this.unlinkPlatform = null;
                this.unlinkText = null;
                return;
            }
            let platformName = Platforms[platformId];
            let parentPlatform = Platforms.PARENT_PLATFORMS[platformId];
            let parentPlatformName = parentPlatform ? Platforms[parentPlatform] : '';
            let unlinkText = '<p>';
            unlinkText += `You are about to <b>disconnect</b> your ${platformName} account.`;
            if (parentPlatformName) {
                unlinkText += ` This will also <b>disconnect</b> your ${parentPlatformName} account.`;
            }
            unlinkText += '</p><p>Do you want to proceed?</p>';
            this.unlinkPlatform = platformId;
            this.unlinkText = unlinkText;
            this.setModalMaskPosition();
        },
        toggleNewPlatformModal(platformId = null) {
            let platform = platformId;
            if (platformId) {
                platform = this.platforms[platformId].platformName;
            }
            this.newServicePlatform = platform;
            this.setModalMaskPosition();
        },
        toggleSnoozeResumeModal(platformId = null) {
            platformId = parseInt(platformId);
            if (isNaN(platformId)) {
                this.load();
            }
            this.snoozeModalPlatform = platformId;
            this.setModalMaskPosition();
        },
        toggleUnauthorizedModal() {
            this.$emit('modal-triggered');
            this.openUnauthorizedModal = !this.openUnauthorizedModal;
            if (!this.openUnauthorizedModal) {
                this.platforms[Platforms.FACEBOOK].nonce++;
                this.platforms[Platforms.INSTAGRAM].nonce++;
            }
        },
        $resizeEventHandler() {
            this.setModalMaskPosition();
        },
    },
};
</script>

<style lang="scss">
.destination-logins {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;

    .fb-modal {
        .modal-content {
            height: 480px;
            width: 510px;
        }

        .modal-md {
            margin: 200px auto;
        }

        .popup-modal-body-container {
            .popup-header {
                font-style: normal;
                font-weight: 900;
                font-size: 16px;
                line-height: 140%;
                left: 0;
                position: inherit;
            }

            .popup-content {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;
                text-align: left;
                position: absolute;
                left: 40px;
                margin-right: 50px;

                .content-title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 140%;
                    margin-left: 75px;
                }
            }

            .footer {
                top: 288px;
                position: relative;
            }

            .m-t-5 {
                margin-top: 3rem;
            }

            .m-t-1 {
                margin-top: 0rem;
            }
        }
    }
    .unlink-modal {
        .header {
            font-size: 16px;
            font-weight: bold;
        }
        .popup-content {
            p {
                padding: 0 150px;
            }
        }
        .btn-yes {
            background-color: white;
            border-color: #ccc;
            color: #333;
        }
        .btn-no {
            background-color: #0066ff;
            border-color: #0084bd;
            color: white;
        }
    }
    .unauthorized-modal {
        .modal-dialog {
            width: 375px;

            .header {
                font-size: 16px;
                font-weight: bold;
            }
            .popup-content {
                p {
                    padding: 0 50px;
                }
            }
        }
    }
}
@media(max-width: 950px) {
    .destination-logins {
        width: 305px;
        .platform-body {
            .platform-image {
                .connect-button-wrapper{
                     .connect-button {
                        width: auto;
                    }
                }
            }
        }
        .platform-footer {
            height: auto;
        }
        .platform-header {
            left: 54px;
        }
    }
}
</style>

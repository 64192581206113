export default {
    bucketsConfig: {
        'info-required': {
            text:                 'Info Required',
            maxStories:           25,
            editable:             true,
            showInformationModal: false,
            defaultBucketToMove:  { text: 'Move Content' },
        },
        'ready-for-approval': {
            text:                 'Ready for Approval',
            maxStories:           25,
            editable:             true,
            showInformationModal: false,
            defaultBucketToMove:  { text: 'Move Content' },
        },
        'approved-for-distribution': {
            text:                 'Approved for Distribution',
            maxStories:           25,
            editable:             true,
            showInformationModal: false,
            defaultBucketToMove:  { text: 'Move Content' },
        },
        'denied': {
            text:                 'Denied',
            maxStories:           25,
            editable:             false,
            showInformationModal: true,
            defaultBucketToMove:  { text: 'Info Required' },
        },
        'expired': {
            text:                 'Expired',
            maxStories:           25,
            editable:             false,
            showInformationModal: true,
            defaultBucketToMove:  { text: 'Info Required' },
        },
        'archived': {
            text:                 'Archived',
            maxStories:           25,
            editable:             false,
            showInformationModal: true,
            defaultBucketToMove:  { text: 'Info Required' },
        },
    },
    canMultiSelect: false,
    customValues:   {
        dates:             null,
        products:          null,
        retailer_grouping: null,
        languages:         null,
    },
    isCancelling:       false,
    keepExistingValues: {
        dates:             false,
        products:          false,
        retailer_grouping: false,
        languages:         false,
    },
    multiSelectActive:      false,
    saveConfirmationText:   '',
    selectedPostType:       null,
    selectedStories:        [],
    selectedBucketToMoveTo: { text: 'Move Content' },
    showBucketChangeAlert:  false,
    showSaveConfirmation:   false,
};

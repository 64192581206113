export default {
    getContacts(state) {
        if (state.contacts[0].id === 'empty') {
            return [];
        }
        let contacts = state.contacts;
        contacts.forEach(contact => {
            if (!contact.email) {
                contact.cc_invoice = false;
            }
        });
        return contacts;
    },
    getStateAbbreviation(state) {
        const stateId = state.inputValues['brand-state-province'];
        return state.states.find(state => state.id === stateId)?.abbreviation;
    },
};

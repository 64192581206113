const getContactInformationViewSettings = () => {
    return [
        // Brand Contact(s)
        {
            titleData: {
                title:       'Retailer Contact(s)',
                description: 'These are the main points of contact that ThumbStopper can reach out to with inquiries.',
                id:          'retailer-contacts',
            },
            columnsData: [
                {
                    ['contact-type']: {
                        id:          'contact-type',
                        label:       '',
                        type:        'dropdown',
                        subType:     'single',
                        placeholder: '',
                        options:     [
                            {
                                name: 'General',
                                id:   1,
                            },
                            {
                                name: 'Legal',
                                id:   2,
                            },
                            {
                                name: 'Sales',
                                id:   3,
                            },
                            {
                                name: 'Support',
                                id:   4,
                            },
                            {
                                name: 'Tech',
                                id:   5,
                            },
                        ],
                        value: 1,
                    },
                    ['contact-first-name']: {
                        id:          'contact-first-name',
                        label:       'FIRST NAME',
                        type:        'text',
                        subType:     'add-contact',
                        placeholder: 'First Name',
                        options:     null,
                        value:       '',
                    },
                    ['contact-last-name']: {
                        id:          'contact-last-name',
                        label:       'LAST NAME',
                        type:        'text',
                        subType:     'add-contact',
                        placeholder: 'Last Name',
                        options:     null,
                        value:       '',
                    },
                    ['contact-email']: {
                        id:          'contact-email',
                        label:       'EMAIL',
                        type:        'text',
                        subType:     'add-contact',
                        placeholder: 'Email',
                        options:     null,
                        value:       '',
                    },
                    ['contact-phone']: {
                        id:          'contact-phone',
                        label:       'PHONE',
                        type:        'text',
                        subType:     'add-contact',
                        placeholder: '(XXX)XXX-XXXX',
                        value:       '',
                        isValid:     false,
                    },
                    ['add-contact-button']: {
                        type:     'slot',
                        slotName: 'add-contact-button',
                    },
                },
                {
                    ['contacts-table']: {
                        type:     'slot',
                        slotName: 'contacts-table',
                    },
                },
            ],
        },
        // Brand Billing Contact
        {
            titleData: {
                title:       'Retailer Billing Contact',
                description: 'This is the point of contact that ThumbStopper can reach out to with all billing inquiries.',
            },
            columnsData: [
                {
                    ['billing-contact-first-name']: {
                        id:          'billing-contact-first-name',
                        label:       'FIRST NAME',
                        type:        'text',
                        subType:     null,
                        placeholder: 'First Name',
                        options:     null,
                        value:       '',
                    },
                    ['billing-contact-last-name']: {
                        id:          'billing-contact-last-name',
                        label:       'LAST NAME',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Last Name',
                        options:     null,
                        value:       '',
                    },
                    ['billing-contact-email']: {
                        id:          'billing-contact-email',
                        label:       'EMAIL',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Email',
                        options:     null,
                        value:       '',
                    },
                    ['billing-contact-phone']: {
                        id:          'billing-contact-phone',
                        label:       'PHONE',
                        type:        'text',
                        placeholder: '(XXX)XXX-XXXX',
                        value:       '',
                        isValid:     false,
                    },
                },
            ],
        },
        // ThumbStopper Support Contact
        {
            titleData: {
                title:       'ThumbStopper Support Contact',
                description: 'This is the ThumbStopper contact that you can reach out to with questions or concerns.',
            },
            hideKey:     'show-admin',
            columnsData: [
                {
                    ['support-first-name']: {
                        id:          'support-first-name',
                        label:       'FIRST NAME',
                        type:        'text',
                        subType:     null,
                        placeholder: 'First Name',
                        options:     null,
                        value:       '',
                        showKey:     'show-admin',
                    },
                    ['support-last-name']: {
                        id:          'support-last-name',
                        label:       'LAST NAME',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Last Name',
                        options:     null,
                        value:       '',
                        showKey:     'show-admin',
                    },
                    ['support-contact-email']: {
                        id:          'support-contact-email',
                        label:       'EMAIL',
                        type:        'text',
                        subType:     null,
                        placeholder: 'Email',
                        options:     null,
                        value:       '',
                    },
                    ['support-contact-phone']: {
                        id:          'support-contact-phone',
                        label:       'PHONE',
                        type:        'text',
                        placeholder: '(XXX)XXX-XXXX',
                        value:       '',
                        isValid:     false,
                    },
                },
            ],
        },
        // ThumbStopper Account Managers
        {
            showKey:   'is-admin',
            titleData: {
                title:       'ThumbStopper Account Managers',
                description: 'These are the ThumbStopper contacts assigned to this retailer account.',
            },
            columnsData: [
                {
                    ['customer-success-representative-contact-name']: {
                        id:          'customer-success-representative-contact-name',
                        label:       'CUSTOMER SUCCESS REPRESENTATIVE',
                        type:        'dropdown',
                        subType:     'single',
                        placeholder: 'Select a CS Manager',
                        options:     [],
                        value:       '',
                        enabledKey:  'is-admin',
                    },
                    ['customer-success-representative-contact-email']: {
                        id:          'customer-success-representative-contact-email',
                        label:       'EMAIL',
                        type:        'text',
                        subType:     null,
                        placeholder: '',
                        options:     null,
                        value:       '',
                        enabledKey:  'disabled',
                    },
                },
                {
                    ['account-executive-contact-name']:
                    {
                        id:          'account-executive-contact-name',
                        label:       'ACCOUNT EXECUTIVE',
                        type:        'dropdown',
                        subType:     'single',
                        placeholder: 'Select an AE',
                        options:     [],
                        value:       '',
                        enabledKey:  'is-admin',
                    },
                    ['account-executive-contact-email']:
                    {
                        id:          'account-executive-contact-email',
                        label:       'EMAIL',
                        type:        'text',
                        subType:     null,
                        placeholder: '',
                        options:     null,
                        value:       '',
                        enabledKey:  'disabled',
                    },
                },
            ],
        },
    ];
};
export default getContactInformationViewSettings();

<template>
    <div class="connect-layout" :class="{ 'disabled-page': pageDisabled }">
        <div v-if="pageDisabled" class="disabled-excluded-text">EXCLUDED</div>
        <div class="connect-header">
            <div class="connect-logo-wrapper" :class="logoValidClass">
                <template v-if="step == -1">
                    <connect-image :url="connectStore.logoUrl" :disabled="edit" @update:url="updateLogoUrl" />
                </template>
                <template v-else>
                    <img :src="connectStore.logoUrl">
                </template>
            </div>
            <step-progress-indicator :current-step="step" :steps="steps" :radius="26" :width="140" v-bind="stepProgressColors" />
        </div>
        <div class="connect-bar connect-cost-bar">
            <connect-textarea
                name="cost-bar"
                :limit="130"
                :content="connectStore.costText"
                :valid="costTextValid"
                :focusable="!edit && step == -1"
                :edit="edit || step != -1"
                @update:content="updateCostText"
                @commit="commitCostText"
            />
        </div>
        <div class="connect-body">
            <div class="connect-body-wrapper" :class="{'connect-body-wrapper-page': currentPage === 'ContactInfoPage'}">
                <slot ref="body" />
            </div>
        </div>

        <div
            :class="{
                'connect-footer-page': currentPage === 'ContactInfoPage',
                'connect-footer': connectNotEditorPage
            }"
            class="connect-bar connect-footer-bar"
        >
            <template v-if="!hideFooterButtons">
                <div class="next-back-footer">
                    <div class="buttons-wrapper">
                        <button class="btn btn-default live-only" tabindex="11" @click="back">Back</button>
                        <button :disabled="blockNextButton" class="btn btn-primary live-only" tabindex="12" @click="next">Next</button>
                    </div>
                </div>
            </template>
            <template v-if="hideFooterButtons && currentPage !== 'ThankYouPage'">
                <div class="next-back-footer">
                    <div class="buttons-wrapper get-started">
                        <button class="btn btn-primary live-only get-started" @click="next">Get Started </button>

                        <a
                            v-if="connectStore.profileDocument"
                            :href="connectStore.profileDocument"
                            class="btn learn-more-btn live-only learn-more-btn-link"
                            target="_blank"
                        >
                            Learn More
                            <blue-popout-icon :scale="0.5" :style="{'margin-left': '12px'}" />
                        </a>
                    </div>
                </div>
            </template>
            <connect-textarea
                name="footer-bar"
                :limit="130"
                :content="connectStore.footerText"
                :focusable="!edit && step == -1"
                :edit="edit || step != -1"
                @update:content="updateFooterText"
                @commit="commitFooterText"
            />
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { connectStore } from '@/stores/pinia.js';
import DefaultConnectState from '@/stores/connect/state.js';
import ConnectImage from '@/components/connects/ConnectImage.vue';
import ConnectTextarea from '@/components/connects/ConnectTextarea.vue';

import StepProgressIndicator from '@/components/app/StepProgressIndicator.vue';
import BluePopoutIcon from '@/components/icons/BluePopoutIcon.vue';
import ScreenSize from '@/mixins/ScreenSize.js';

export default {
    components: {
        ConnectImage,
        ConnectTextarea,
        StepProgressIndicator,
        BluePopoutIcon,
    },
    mixins: [
        ScreenSize,
    ],
    props: {
        edit: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        hideFooterButtons: {
            type:    Boolean,
            default: false,
        },
        step: {
            type:     Number,
            required: true,
        },
        stepCount: {
            type:    Number,
            default: 6,
        },
        currentPage: {
            type:     String,
            default:  'WelcomePage',
            required: false,
        },
        blockNextButton: {
            type:    Boolean,
            default: false,
        },
        connectNotEditorPage: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    data() {
        return {
            stepProgressColors: {
                activeColor:      '#0066ff',
                activeTextColor:  '#85878f',
                borderColor:      '#0066ff',
                finalBorderColor: '#0066ff',
                finalTextColor:   '#85878f',
            },
            isKeyboardActive: false,
        };
    },
    computed: {
        bodyStyle() {
            if (this.hideFooterButtons) {
                return {height: 'calc(100% - 206px)'};
            } else {
                return {height: 'calc(100% - 322px)'};
            }
        },
        costTextValid() {
            return this.connectStore.costText != DefaultConnectState().costText;
        },
        logoValidClass() {
            return {
                valid:   this.step == -1 && this.connectStore.logoUrl,
                invalid: this.step == -1 && !this.connectStore.logoUrl,
            };
        },
        steps() {
            let start = this.screenSizeX <= 600 ? 0 : 'Start';
            let steps = [start];
            for (let i = 1; i < this.stepCount; i++) {
                if (this.screenSizeX <= 600) {
                    steps.push(`${i}`);
                } else {
                    steps.push(`Step ${i}`);
                }
            }
            steps[steps.length - 1] = this.screenSizeX <= 600 ? steps.length - 1 : 'Connected';
            return steps;
        },
        pageDisabled() {
            if (this.currentPage === 'DestinationLoginsPage') {
                return !this.connectStore.destinationLoginsPage.enabled;
            }
            if (this.currentPage === 'CreditCardPage') {
                return !this.connectStore.creditCardPage.enabled;
            }
            if (this.currentPage === 'ProductSelectionPage') {
                return !this.connectStore.productSelectionPage.enabled;
            }
            return false;
        },
        ...mapStores(connectStore),
    },
    mounted() {
        window.addEventListener('keydown', this.handleEscapeKeyPress);
    },
    methods: {
        handleEscapeKeyPress(e) {
            if (e.key === 'Enter') {
                this.$emit('next');
            }
        },
        handleKeyUp() {
            this.isKeyboardActive = false;
        },
        back() {
            this.$emit('back');
        },
        commitCostText(value) {
            this.connectStore.$patch({ costText: value });
        },
        commitFooterText(value) {
            this.connectStore.$patch({ footerText: value });
        },
        next() {
            this.$emit('next');
        },
        updateCostText(value) {
            this.connectStore.costText = value;
        },
        updateFooterText(value) {
            this.connectStore.footerText = value;
        },
        updateLogoUrl(value) {
            this.connectStore.$patch({ logoUrl: value });
        },
    },
};
</script>

<style lang="scss">
@font-face{
    font-family: Geomanist;
    src: url('/fonts/templates/geomanist/geomanist-regular-webfont.ttf');
}
@font-face{
    font-family: Geomanist-Bold;
    src: url('/fonts/templates/geomanist/geomanist-bold-webfont.ttf');
}
@media(min-width: 950px) {
    .connect-layout {
        font-family: Geomanist;
        width: 100%;
        height: 100%;
        position: relative;

        &.disabled-page {
            opacity: 0.4;
            pointer-events: none;

            .disabled-excluded-text {
                font-family: Geomanist-Bold;
                font-style: normal;
                font-weight: 900;
                font-size: 160px;
                line-height: 36px;
                position: absolute;
                top: 375px;
                left: 275px;
                z-index: 3;
            }
        }

        .connect-header {
            height: 135px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .connect-logo-wrapper {
                height: 81px;
                width: 195px;
                padding-left: 30px;

                &.valid {
                    img {
                        border: 2px dashed #5cb85b;
                    }
                }

                &.invalid {
                    border: 2px dashed #e80061;
                }

                * {
                    height: 100%;
                }
            }

            .step-progress-indicator {
                z-index: 2;

                .step {
                    h2 {
                        font-size: 12px !important;
                        top: 8px;
                    }
                }
            }
        }

        .connect-bar {
            background-color: #7b7b7b;
            color: #ffffff;
            text-align: center;

            &.connect-cost-bar {
                height: 39px;
                font-size: 20px;
                padding-top: 5px;
                width: auto;

                .connect-textarea {
                    height: 39px;
                    top: -5px;

                    &.valid {
                        border: 2px dashed #5cb85b;
                        border-radius: 10px;
                    }

                    &.invalid {
                        border: 2px dashed #e80061;
                        border-radius: 10px;
                    }

                    textarea {
                        background: transparent;
                        padding-top: 1px;
                        text-align: center;
                    }
                }
            }
            &.connect-footer {
                padding-top: 0px !important;
                padding-bottom: 15px !important;
                width: 192rem !important;
            }
            &.connect-footer-bar {
                font-size: 16px;
                padding-top: 3px;
                padding-bottom: 20px;
                position: fixed;
                width: 100%;
                bottom: 0;
                z-index: 2;

                .connect-textarea {
                    height: 32px;
                    top: -3px;

                    &.valid {
                        border: 2px dashed #5cb85b;
                        border-radius: 10px;
                    }

                    &.invalid {
                        border: 2px dashed #e80061;
                        border-radius: 10px;
                    }

                    textarea {
                        background: transparent;
                        margin-top: -4px;
                        text-align: center;
                    }
                }
            }
        }

        .connect-body {
            min-height: 400px;
            position: relative;
            z-index: 2;

            .connect-body-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                height:89vh;

                .connect-textarea {
                    border-width: 2px;
                    border-style: dashed;
                    border-radius: 10px;
                    resize: none;

                    &.valid {
                        border-color: #5cb85b;
                    }

                    &.invalid {
                        border-color: #e80061;
                    }
                }
            }
        }

        .next-back-footer {
            display: flex;
            justify-content: center;
            padding: 1.4rem 3rem;

            hr {
                margin: 0;
            }

            .buttons-wrapper {
                display: flex;
                gap: 1rem;

                .btn {
                    font-family: Geomanist-Bold;
                    border-radius: 4px;
                    font-weight: bold;
                    text-transform: uppercase;

                    &.btn-default {
                        border: 2px solid #afafaf;
                        color: #afafaf;
                    }

                    &.btn-primary {
                        background: #0066ff;
                    }
                }

                .learn-more-btn-link {
                    display: flex;
                    align-items: center;
                    background-color: #fff;
                    border-radius: 4px;
                    border: 2px solid #0066ff;
                    color: #0066ff;
                    padding-left: 22px;
                }
            }
            .learn-more {
                display: flex;
                align-items: baseline;
                font-size: 14px;
                color:#0066ff;
                border: 2px solid  #0066FF;
                border-radius: 4px;
                padding: 3px 12px;
                background: white;
                .learn-more-btn {
                    color: #0066FF;
                }
            }
        }
        .next-back-footer-connect {
            padding: 1.4rem 1rem;
        }

        .live-only {
            pointer-events: var(--live-element-events, initial);
        }
    }
}
//inbetween phones and desktop (eg. tablets)
@media (max-width: 950px) and (min-width: 425px) {
    .connect-cost-bar {
        top: 95px !important;
    }
}
@media (max-width:1750px) {
    .connect-layout {
        .connect-body {
            .connect-body-wrapper {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 15px;
                min-height: 54vh;
            }
        }
    }
}
@media (max-height: 1094px) {
    .connect-layout {
        .connect-body {
            .connect-body-wrapper {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 15px;
                min-height: 54vh;
            }
            .connect-header {
                min-height: 180px;
            }
        }
    }
}
@media(max-width: 950px) {
    .connect-layout {
        font-family: Geomanist;
        width: 100%;
        height: 100%;
        position: relative;

        &.disabled-page {
            opacity: 0.4;
            pointer-events: none;

            .disabled-excluded-text {
                font-family: Geomanist-Bold;
                font-style: normal;
                font-weight: 900;
                font-size: 160px;
                line-height: 36px;
                position: absolute;
                top: 375px;
                z-index: 3;
            }
        }

        .connect-header {
            min-height: 180px;

            .connect-logo-wrapper {
                position: relative;
                top: 110px;
                padding-left: 10px;
                padding-right: 10px;
                padding-top: 10px;
                text-align: center;
                img {
                    max-width:50%;
                    max-height:100%;
                }
                .connect-image {
                    img {
                        max-width:50%;
                        max-height:100%;
                    }
                }
            }

            .step-progress-indicator {
                display: flex;
                position: absolute;
                top: 40px;
                padding-left: 60px !important;
                width: 100%;
                justify-content: center;

            .step {
                h2 {
                    font-size: 12px !important;
                    top: 8px;
                }
            }
        }
    }
    .connect-footer-page {
        position: relative !important;
    }
    .connect-bar {
        background-color: #7b7b7b;
        color: #ffffff;
        text-align: center;

        &.connect-cost-bar {
            font-size: 16px;
            padding-top: 8px;
            position: relative;
            top: 30px;

            .connect-textarea {
                top: -5px;

                &.valid {
                    border: 2px dashed #5cb85b;
                    border-radius: 10px;
                }

                &.invalid {
                    border: 2px dashed #e80061;
                    border-radius: 10px;
                }

                textarea {
                    background: transparent;
                    padding-top: 1px;
                    text-align: center;
                }
            }
        }

        &.connect-footer-bar {
            font-size: 16px;
            padding-top: 1rem;
            bottom: 0;
            width: 100%;
            position: fixed;
            z-index: 2;
        }

    }

    .connect-body {
        .connect-body-wrapper-page {
            padding-bottom: 0px !important;
        }
        .connect-body-wrapper {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            height: 100%;
            padding: 15px;
            min-height: 54vh;
            padding-bottom: 180px;

            .connect-textarea {
                border-width: 2px;
                border-style: dashed;
                border-radius: 10px;
                resize: none;
                margin-bottom: 10px;

                &.valid {
                    border-color: #5cb85b;
                }

                &.invalid {
                    border-color: #e80061;
                }

                textarea {
                    background: transparent;
                }
            }
        }
    }
    .next-back-footer {
        display: flex;
        justify-content: center;

        hr {
            margin: -19px;
        }

        .buttons-wrapper {
            display: flex;
            gap: 1rem;

            .btn {
                font-family: Geomanist-Bold;
                border-radius: 4px;
                font-weight: bold;
                text-transform: uppercase;

                &.btn-default {
                    border: 2px solid #afafaf;
                    color: #afafaf;
                }

                &.btn-primary {
                    background: #0066ff;
                }
            }
            .learn-more {
                font-family: Geomanist-Bold;
                display: flex;
                align-items: center;
                margin-left: 20px;
                border: 2px solid  #0066ff;
                border-radius: 4px;
                padding: 3px 12px;
                background: white;

                .learn-more-btn {
                    color: #0066ff;
                }
            }

            .learn-more-btn-link {
                display: flex;
                align-items: center;
                background-color: #fff;
                border-radius: 4px;
                border: 2px solid #0066ff;
                color: #0066ff;
                padding-left: 22px;
            }
        }
    }
    .live-only {
        pointer-events: var(--live-element-events, initial);
    }
}
.localize-widget-wrapper {
    right: 10%;
    position: absolute;
}
}
</style>

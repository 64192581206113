export default () => ({
    billing: {
        type:   'match',
        custom: {
            amount:   0,
            lineItem: '',
            start:    0,
            term:     1,
        },
        fee: {
            enabled:  false,
            amount:   0,
            lineItem: '',
        },
    },
    forceBillToRetailer: false,
    costText:            '$/Month - % Co-Op Approved for Qualified Distributors',
    logoUrl:             '',
    footerText:          'Please reach out to the [Program Name] team with any questions at 1.866.986.0003 or [Support Email]',
    programName:         '[Program Name]',
    supportEmail:        '[Support Email]',
    welcomePage:         {
        enabled: true,
        themeId: 1,
        header:  'Welcome to [Program Name]',
        text:    'You\'re moments away from enabling your business\'s social media pages to receive eye-catching content from [Brand Name].'
        + ' Let\'s start by logging into your Facebook account and confirming your business connection.',
        images: ['', '', '', '', '', ''],
    },
    businessNamePage: {
        enabled: true,
        header:  'What is your business name?',
    },
    destinationLoginsPage: {
        enabled:  true,
        header:   'Connect your Social Media Accounts to ThumbStopper',
        services: [],
        text:     'Login to connect any unlinked social media accounts you would like to link. '
            + 'Once all desired business pages are linked, click "Next" to proceed.',
    },
    contactInfoPage: {
        enabled:                  true,
        terms:                    'Type terms & conditions here',
        termsEnabled:             false,
        termsUpdated:             false,
        firstNameText:            'First Name',
        lastNameText:             'Last Name',
        businessNameText:         'Business Name',
        dealerNumberEnabled:      true,
        dealerNumberRequired:     false,
        dealerNumberText:         'Dealer Number (If Applicable) - Not Required',
        businessEmailText:        'Business Contact Email',
        businessPhoneText:        'Business Phone Number',
        otherPhoneText:           'Other Phone Number - Not Required',
        retailerGroupingEnabled:  false,
        retailerGroupingRequired: false,
        retailerGroupingText:     'Retailer Grouping',
        languageEnabled:          false,
        languageRequired:         true,
        languageText:             'Languages',
    },
    productSelectionPage: {
        enabled:          true,
        header:           'Choose your products',
        products:         [],
        text:             'Select the products that you would like to see posted about on your social media pages.',
        selectAllEnabled: true,
    },
    creditCardPage: {
        enabled: true,
        terms:   'I understand and agree that I will be charged $ USD for 1 month of service, beginning the first of the calendar month after enrollment.'
            + ' After 1 month of service, I will be automatically charged to continue using the service monthly. I agree to the terms, the privacy policy,'
            + ' and the end user agreement.',
        termsUpdated: false,
    },
    thankYouPage: {
        enabled: true,
        header:  'Thank you for signing up for [Program Name]',
        text:    'You will automatically receive social media content from [Brand Name] to your business\'s social pages within the next seven days.',
    },
    savedClassifiers:      [],
    collapseClassifiersTo: 0,
    completed_at:          null,
    saveButton:            {
        text:   'Save',
        status: null,
    },
    resellerId:      null,
    resellerName:    '',
    profileDocument: '',
    productTree:     [],
});

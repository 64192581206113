<template>
    <div
        class="wide-tooltip"
        data-toggle="tooltip"
        :data-placement="placement"
        :title="title"
    >
        <svg :width="width" :height="height" viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="500" cy="500" r="500" fill="#8A8A8A" />
            <circle cx="491.5" cy="215.5" r="87.5" fill="white" />
            <rect x="428.5" y="359.5" width="142" height="494" fill="white" stroke="white" />
            <rect x="374" y="777" width="251" height="95" fill="white" />
            <rect x="374" y="359" width="197" height="95" fill="white" />
        </svg>
    </div>
</template>

<script>
import IconMixin from './IconMixin.js';
export default {
    name:   'CircleInfoGrayIcon',
    mixins: [ IconMixin ],
    props:  {
        placement: {
            type:    String,
            default: 'right',
        },
    },
};
</script>

<template>
    <div class="general-error-body">
        <div class="general-error-container">
            <div class="content">
                <div class="left-container">
                    <div class="oops-svg-text-container">
                        <svg viewBox="0 0 450 70">
                            <text class="oops-svg-text" x="165" y="55">{{ title }}</text>
                        </svg>
                    </div>
                    <h4>{{ subtitle }}</h4>
                    <span v-for="(line, i) in errorVerboseLines" :key="'text_line_' + i" class="error-text">
                        {{ line }}
                    </span>
                    <button @click="userStore.goToDashboard({ payload: { activeSession }})">{{ buttonText }}</button>
                </div>
                <div class="right-container">
                    <img class="thumbstopper-tack" src="/images/thumbstopper_tack2.svg">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapStores } from 'pinia';
import { userStore } from '@/stores/pinia.js';

export default {
    name:  'GeneralError',
    props: {
        title: {
            type:    String,
            default: 'Oops!',
        },
        subtitle: {
            type:    String,
            default: 'An error has occurred.',
        },
        errorVerboseLines: {
            type:    Array,
            default: () => [],
        },
        activeSession: {
            type:    Boolean,
            default: false,
        },
        user: {
            type:     Object,
            required: false,
            default:  () => ({}),
        },
        dealership: {
            type:     Object,
            required: false,
            default:  () => ({}),
        },
    },
    computed: {
        buttonText() {
            if (this.activeSession) {
                return 'Return to Thumbstopper';
            }

            return 'Login';
        },
        ...mapStores(userStore),
    },
    mounted() {
        this.userStore.setUser({ payload: { ...this.user, selectedDealership: this.dealership }});
    },
};
</script>
<style lang="scss">
.general-error-body {
    width: 100vw;
    height: 100vh;
    background-color: #5000b8;
}
.general-error-container {
    background: #5000B8;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    .content {
        background: #5000B8;
        display: flex;
        height: calc(100vh - 61px);
        justify-content: flex-end;
        width: 100%;
        .map {
            float: right;
            height: 100%;
        }
        .thumbstopper-tack{
            position: absolute;
            left: 63%;
            top: 35%;
        }
        .left-container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 50vw;
            position: absolute;
            top: 30%;
            left: 0;
            .oops-svg-text-container {
                width: 100%;
            }
            h4 {
                color: white;
                font-size: 36px;
                margin-bottom: 50px;
            }
           .oops-svg-text {
                fill: #5000B8;
                stroke: #A36CEB;
                stroke-width: 5px;
                stroke-linejoin: round;
                paint-order: stroke;
                font-style: normal;
                font-weight: 700;
                font-size: 38px;
                letter-spacing: 2px;
            }
            .error-text {
                color: white;
                font-size: 24px;
            }
            button {
                background: #FFFFFF;
                border-radius: 4px;
                height: 54px;
                left: 335px;
                margin-top: 50px;
                top: 778px;
                padding: 0 15px 0 15px;
                font-weight: bold;
                font-size: 22px;
            }
        }
        .right-container {
            width: 65vw;
                background-image: radial-gradient(50% 73.98% at 50% 50%, rgba(80, 0, 184, 0) 0%, #5000B8 100%), url(/images/map_purple.svg);
            background-repeat: no-repeat;
            background-size: 100%;
        }
    }
}
</style>

<template>
    <export-modal
        class="export-options-retailer-total"
        :subtitle-info="subtitleInfo"
        :sidebar-filters="sidebarFilters"
        :body-filters="bodyFilters"
        :current-step="currentStep"
        :previous-button-title="currentStep === 3 ? 'Back to selection' : ''"
        :next-step-button-disabled="nextStepButtonDisabled"
        :show-arrow-pointer="true"
        :show-preview="showPreview"
        :welcome-preview="welcomePreview"
        :next-step-button-title="currentStep === 3 ? 'Export' : 'Next'"
        :is-loading="isLoading"
        :selected-filters="selectedFilters"
        :preview-data="previewData"
        :user-radio="userRadio"
        :exporting="exporting"
        :loading-headers="loadingHeaders"
        export-type="Brand/Retailer Data"
        :title="showPreview ? 'Export Preview' : 'Export Creator'"
        @close="close"
        @next-step="handleNextStep()"
        @update:selected-filter="updateSelectedFilters($event)"
        @previous-step="handlePreviusStep()"
        @from-preview="handlePreviusStep('from-preview')"
        @handle-select-deselect-all="handleSelectDeselectAll($event)"
    >
        <template #starter>
            <div class="date-range-component-container">
                <ts-date-range
                    :highlight-selected-quick-date="true"
                    :ignore-selected-date-range-watch="true"
                    :placeholder="'Select Date Range'"
                    :quick-future-dates="quickFutureDates"
                    :range="range"
                    :selected-quick-date-id="selectedQuickDateId"
                    :show-adjust-date="false"
                    :show-calendar-icon="false"
                    :show-label="false"
                    :single-input="true"
                    :columns="2"
                    :is-range="true"
                    label="Select Date Range"
                    @update:range="(event) => $emit('update:range', event)"
                    @update:selected-quick-date="(event) => $emit('update:selected-quick-date',event)"
                />
            </div>
            <brand-manager-filter-dropdown
                v-if="hasRoleAccess"
                :select-options="oemManagerOptions"
                :selected-brands="selectedBrands"
                :combo-box="selectedBrands.length > 1 || selectedBrands.length === oemManagerOptions.length ? 'Brands' : 'Brand'"
                :label="selectedBrands.length > 1 ? 'Brands' : 'Brand'"
                @selected-brands="handleSelectedBrands($event)"
            />
            <div class="checkbox-input-container custom-tooltip-container-sidebar">
                <div class="retailer-status-container">
                    <label class="label-modal">
                        <ts-checkbox
                            :checked="active_retailer"
                            :scale=".35"
                            @update:checked="getRetailersCount('active')"
                        />
                        <span class="span-status">Active Retailers ({{ activeRetailerCount }})</span>
                    </label>
                    <label class="label-modal">
                        <ts-checkbox
                            :checked="inactive_retailer"
                            :scale=".35"
                            @update:checked="getRetailersCount('inactive')"
                        />
                        <span class="span-status">Inactive Retailers ({{ inactiveRetailerCount }})</span>
                    </label>
                </div>
            </div>
        </template>
        <template #body />
    </export-modal>
</template>

<script>
import axios from '@/axios';
import dayjs from 'dayjs';
import BrandManagerFilterDropdown from '@/components/app/BrandManagerFilterDropdown.vue';
import ToggleSwitch from '@/components/app/ToggleSwitch.vue';
import ExportModal from '@/components/app/exports/ExportModal.vue';
import { userStore } from '@/stores/pinia.js';
import TsCheckbox from '@/components/app/TsCheckbox.vue';
import TsDateRange from '@/components/app/TsDateRange.vue';
import _ from 'lodash';
import { mapStores } from 'pinia';

export default {
    components: {
        TsDateRange,
        ExportModal,
        ToggleSwitch,
        BrandManagerFilterDropdown,
        TsCheckbox,
    },
    props: {
        oemManagerOptions: {
            type:    Array,
            default: () => [],
        },
        filters: {
            type:    Object,
            default: () => {},
        },
        range: {
            type:     Object,
            required: true,
        },
        quickFutureDates: {
            type:     Array,
            required: true,
        },
        selectedQuickDateId: {
            type:     Number,
            required: true,
        },
        brands: {
            type:     Array,
            required: true,
            default:  () => [],
        },
        oemManagerId: {
            type:     String,
            default:  '',
            required: true,
        },
        userRole: {
            type:     String,
            required: false,
            default:  '',
        },
        sidebarCall: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        welcomePreview: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    data() {
        return {
            isLoading:              true,
            sidebarFilters:         {},
            bodyFilters:            [],
            selectedBrands:         this.brands,
            showPreview:            false,
            selectedFilters:        {},
            previewData:            [[], []],
            queryString:            '',
            userRadio:              'Default User',
            exporting:              false,
            loadingHeaders:         false,
            all_tiers_column_count: 0,
            top_tiers_column_count: 0,
            active_retailer:        true,
            inactive_retailer:      true,
            retailerCount:          '0 Retailers',
            activeRetailerCount:    '0 Retailers',
            inactiveRetailerCount:  '0 Retailers',
        };
    },
    computed: {
        ...mapStores(userStore),
        currentStep() {
            if (this.showPreview) {
                return 3;
            }
            return 1;
        },
        nextStepButtonDisabled() {
            return this.loadingHeaders;
        },
        isActive() {
            if (this.active_retailer && this.inactive_retailer) {
                return null;
            } else if (this.active_retailer) {
                return true;
            } else if (this.inactive_retailer) {
                return false;
            } else {
                return null;
            }
        },
        subtitleInfo() {
            if (this.active_retailer && this.inactive_retailer) {
                return '(Active and Inactive)';
            } else if (this.active_retailer) {
                return '(Active)';
            } else if (this.inactive_retailer) {
                return '(Inactive)';
            } else {
                return '-';
            }
        },
        hasRoleAccess() {
            return (this.userStore.isAdmin || this.userStore.isBrand || this.userStore.isParentBrand) ?? false;
        },
    },
    mounted() {
        this.isLoading = true;
        if (this.welcomePreview) {
            this.handleSelectDeselectAll({value: 'select'});
            this.showPreview = true;
        }

        this.setSidebarFilters();
        this.setBodyFilters();
        this.setInitialSelectedFilters();
        if (!this.welcomePreview) {
            this.setPreviewData();
        }
        this.getRetailersCount();
        this.getInactiveRetailersCount();
        this.getActiveRetailersCount();
        if (this.hasRoleAccess) {
            this.validateSelectedBrands();
        }
        if (this.userStore.isAdmin || this.userStore.isBrand || this.userStore.isParentBrand) {
            this.inactive_retailer = false;
        }
        this.isLoading = false;
    },
    methods: {
        close() {
            this.$emit('close');
        },
        validateSelectedBrands() {
            if (this.selectedBrands.length > 1) {
                this.sidebarFilters.business_info.options.brand_tiers.disabledCheck = true;
                this.filters.retailer_grouping.options[1].options.status.products_counts.disabledCheck = true;
                this.filters.retailer_grouping.options[1].options.status.all_products_status.disabledCheck = true;

                this.filters.retailer_grouping.options[0].options.status.languages.disabledCheck = true;
                this.filters.retailer_grouping.options[0].options.status.all_tiers.disabledCheck = true;
                this.filters.retailer_grouping.options[0].options.status.retailer_grouping.disabledCheck = true;
                const clearRetailerGroupingBrandValues = this.selectedFilters.retailer_grouping.filters.brand.filters.filter(object => {
                    return object.id != 'retailer_grouping' && object.id != 'products_counts';
                });
                const clearRetailerGroupingRetailerValues = this.selectedFilters.retailer_grouping.filters.retailer.filters.filter(object => {
                    return object.id != 'all_tiers' && object.id != 'languages' && object.id != 'all_products_status';
                });
                const clearSidebarValues = this.selectedFilters.business_info.filters.filter(object => {
                    return object.id != 'brand_tiers';
                });
                this.selectedFilters.retailer_grouping.filters.brand.filters = clearRetailerGroupingBrandValues;
                this.selectedFilters.retailer_grouping.filters.retailer.filters = clearRetailerGroupingRetailerValues;
                this.selectedFilters.business_info.filters = clearSidebarValues;
            } else {
                this.sidebarFilters.business_info.options.brand_tiers.disabledCheck = false;
                this.filters.retailer_grouping.options[1].options.status.products_counts.disabledCheck = false;
                this.filters.retailer_grouping.options[1].options.status.all_products_status.disabledCheck = false;
                this.filters.retailer_grouping.options[0].options.status.languages.disabledCheck = false;
                this.filters.retailer_grouping.options[0].options.status.all_tiers.disabledCheck = false;
                this.filters.retailer_grouping.options[0].options.status.retailer_grouping.disabledCheck = false;

                if (!this.selectedFilters.business_info.filters.find(f => f.id === 'brand_tiers')) {
                    this.selectedFilters.business_info.filters.push({id: 'brand_tiers', title: 'Brand Tier(s)'});
                }
            }
        },
        setSidebarFilters() {
            this.sidebarFilters = {
                business_info: this.filters.business_info,
            };
        },
        handleSelectedBrands(event) {
            if (event.value === 'all') {
                if (this.selectedBrands.length === this.oemManagerOptions.length) {
                    this.selectedBrands = [];
                } else {
                    this.selectedBrands = this.oemManagerOptions.map(option => option.id);
                }
            } else {
                this.selectedBrands = event.value;
            }
            this.validateSelectedBrands();
            this.getRetailersCount();
        },
        getActiveRetailersCount() {
            // this.active_retailer = true;

            if (this.selectedBrands.length || this.oemManagerId !== '') {
                axios.get('/api/brands', {
                    params: {
                        oem_manager_ids: this.selectedBrands.length > 0 ? this.selectedBrands : this.oemManagerId.split(','),
                        status:          true,
                    },
                }).then((response) => {
                    let count = 0;

                    response.data.resellers.map(function(r) {
                        count += r.retailers_count;
                    });

                    this.all_tiers_column_count = response.data.all_tiers_column_count || 0;
                    this.top_tiers_column_count = response.data.top_tiers_column_count || 0;
                    this.activeRetailerCount = count + (count === 1 ? ' Retailer' : ' Retailers');
                    this.loadingHeaders = false;
                });
            } else {
                this.activeRetailerCount = '0 Retailers';
            }
        },
        getRetailersCount(status = null) {
            if (status === 'active') {
                this.active_retailer = !this.active_retailer;
            }

            if (status === 'inactive') {
                this.inactive_retailer = !this.inactive_retailer;
            }

            if (!this.active_retailer && !this.inactive_retailer) {
                this.headerInfo = '0 Retailers';
                return;
            }

            this.loadingHeaders = true;
            if (this.selectedBrands.length || (this.oemManagerId !== '' && this.selectedBrands.length >= 1) ) {
                axios.get('/api/brands', {
                    params: {
                        oem_manager_ids: this.selectedBrands.length > 0 ? this.selectedBrands : this.oemManagerId.split(','),
                        is_active:       this.isActive,
                    },
                }).then((response) => {
                    let count = 0;

                    response.data.resellers.map(function(r) {
                        count += r.retailers_count;
                    });

                    this.all_tiers_column_count = response.data.all_tiers_column_count || 0;
                    this.top_tiers_column_count = response.data.top_tiers_column_count || 0;
                    this.headerInfo = count + (count === 1 ? ' Retailer' : ' Retailers');
                    this.loadingHeaders = false;
                });
            } else {
                this.headerInfo = '0 Retailers';
                this.loadingHeaders = false;
            }
        },
        getInactiveRetailersCount() {
            // this.inactive_retailer = true;

            if (this.selectedBrands.length || this.oemManagerId !== '') {
                axios.get('/api/brands', {
                    params: {
                        oem_manager_ids: this.selectedBrands.length > 0 ? this.selectedBrands : this.oemManagerId.split(','),
                        status:          false,
                    },
                }).then((response) => {
                    let count = 0;

                    response.data.resellers.map(function(r) {
                        count += r.retailers_count;
                    });

                    this.all_tiers_column_count = response.data.all_tiers_column_count || 0;
                    this.top_tiers_column_count = response.data.top_tiers_column_count || 0;
                    this.inactiveRetailerCount = count + (count === 1 ? ' Retailer' : ' Retailers');
                    this.loadingHeaders = false;
                });
            } else {
                this.inactiveRetailerCount = '0 Retailers';
            }
        },
        setBodyFilters() {
            this.bodyFilters = [
                {
                    type: 'row',
                    data: {
                        overall_metrics: this.filters.overall_metrics,
                    },
                },
                {
                    type: 'column',
                    data: {
                        platforms: this.filters.platforms,
                    },
                },
                {
                    type: 'column',
                    data: {
                        retailer_grouping: this.filters.retailer_grouping,
                    },
                },
            ];
        },
        setInitialSelectedFilters() {
            for (const [key, value] of Object.entries(this.filters)) {
                if (key === 'retailer_grouping') {
                    this.selectedFilters.retailer_grouping = {filters: {}};
                    value.options.forEach(option => {
                        this.selectedFilters.retailer_grouping.filters[option.name.toLowerCase()] = {filters: [], title: option.name};
                    });
                } else if (key === 'platforms') {
                    this.selectedFilters.platforms = {filters: {}};
                    value.options.forEach(option => {
                        this.selectedFilters.platforms.filters[option.name.toLowerCase()] = {filters: [], title: option.name};
                    });
                } else {
                    this.selectedFilters[key] = {title: value.title, filters: []};
                }
                for (const [key1, value1] of Object.entries(this.filters[key].options)) {
                    if (value1.disabled && !value1.disabledCheck) {
                        this.selectedFilters[key].filters.push({title: value1.title, id: key1});
                    }
                }
            }
        },
        updateSelectedFilters(filterInfo) {
            if (filterInfo.filterType === 'radio') {
                this.userRadio = filterInfo.data.filter.title;
            } else if (filterInfo.filterType === 'column') {
                if (!this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()]) {
                    this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()] = {title: filterInfo.data.type.title, filters: []};
                }
                const filterIsSelected = this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.findIndex(f => f.id === filterInfo.data.filter.id);
                if (filterIsSelected >= 0) {
                    this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.splice(filterIsSelected, 1);
                } else {
                    this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.push(filterInfo.data.filter);
                }
            } else {
                const filterIsSelected = this.selectedFilters[filterInfo.data.type.id].filters.findIndex(f => f.id === filterInfo.data.filter.id);
                if (filterIsSelected >= 0) {
                    this.selectedFilters[filterInfo.data.type.id].filters.splice(filterIsSelected, 1);
                } else {
                    this.selectedFilters[filterInfo.data.type.id].filters.push(filterInfo.data.filter);
                }
            }
            this.setPreviewData();
        },
        setPreviewData() {
            let data = [{}, {}, {}];
            for (const key of Object.keys(this.selectedFilters)) {
                if (key === 'platforms') {
                    for (const k of Object.keys(this.selectedFilters[key].filters)) {
                        data[1][k] = (this.selectedFilters[key].filters[k]);
                    }
                } else if (key === 'retailer_grouping') {
                    for (const k of Object.keys(this.selectedFilters[key].filters)) {
                        data[2][k] = (this.selectedFilters[key].filters[k]);
                    }
                } else {
                    data[0][key] = (this.selectedFilters[key]);
                }
            }
            this.previewData = data;
        },
        handleNextStep() {
            let start = dayjs(this.range.start).format('YYYY-MM-DD');
            let end = dayjs(this.range.end).format('YYYY-MM-DD');
            let query = `/api/reports/show?report=retailer-total&oem_manager_id=${this.selectedBrands.join() || this.oemManagerId}&start=${start}&end=${end}&columns=`;
            let allUsersInfo = '';
            if (!this.showPreview) {
                this.$emit('next-view');
                let brand_tiers = this.selectedFilters.business_info.filters.find(e => e.id === 'brand_tiers');
                if (brand_tiers) {
                    brand_tiers.title = 'Brand Tier(s)';
                }
                this.showPreview = true;
            } else {
                if (this.exporting === true) {
                    return;
                }
                this.exporting = true;
                for (const  [key, value, i] of Object.entries(this.selectedFilters)) {
                    if (key === 'platforms') {
                        for (const [key1, value1] of Object.entries(value.filters)) {
                            value1.filters.forEach(f => {
                                query += `${f.map},`;
                            });
                        }
                    } else if (key === 'retailer_grouping') {
                        for (const [key1, value1] of Object.entries(value.filters)) {
                            value1.filters.forEach(f => {
                                query += `${f.map},`;
                            });
                        }
                    } else {
                        if (key ==='users_info' && this.userRadio === 'All Users') {
                            query += 'all_users,';
                            if (value.filters.length) {
                                value.filters.forEach(f => {
                                    allUsersInfo += `${f.map ? f.map : f.id},`;
                                });
                            }
                        } else {
                            value.filters.forEach(f => {
                                query += `${f.map ? f.map : f.id},`;
                            });
                        }
                    }
                }
                query = query.slice(0, query.length-1);
                if (this.userRadio === 'All Users' && allUsersInfo) {
                    query += `&all_users_columns=${allUsersInfo.slice(0, query.length-1)}`;
                    query = query.slice(0, query.length - 1);
                }
                if (this.isActive !== null) {
                    query += `&is_active=${this.isActive}`;
                }
                this.queryString = query + `&sort_field=name&sort_direction=D&sidebar=${this.sidebarCall}`;
                axios({
                    url:          this.queryString,
                    method:       'GET',
                    responseType: 'blob',
                }).then((response) => {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'retailers_export.csv');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    this.close();
                    this.$emit('next-view');
                });
            }
        },
        handleSelectDeselectAll(event) {
            this.setInitialSelectedFilters();
            const avoid = ['all_users', 'brand_name', 'ts_retailer_id', 'program_name', 'brand_tiers', 'retailer_name'];
            if (event.value === 'select') {
                for (const [key, value] of Object.entries(this.selectedFilters)) {
                    if (key === 'platforms') {
                        this.filters[key].options.forEach((platform) => {
                            for (const [key1, value1] of Object.entries(platform.options)) {
                                for (const [key2, value2] of Object.entries(value1)) {
                                    if (!avoid.find(key => key === key2)) {
                                        this.selectedFilters[key].filters[platform.name.toLowerCase()].filters.push({ id: key2, title: value2.title, map: value2.map ? value2.map : '', hide: value2.hide });
                                    }
                                }
                            }
                        });
                    } else if (key === 'retailer_grouping') {
                        this.filters[key].options.forEach((retailerGroup) => {
                            for (const [key1, value1] of Object.entries(retailerGroup.options)) {
                                for (const [key2, value2] of Object.entries(value1)) {
                                    if (!avoid.find(key => key === key2)) {
                                        if (!value2.disabledCheck) {
                                            this.selectedFilters[key].filters[retailerGroup.name.toLowerCase()].filters.push({ id: key2, title: value2.title, map: value2.map ? value2.map : '', hide: value2.hide });
                                        }
                                    }
                                }
                            }
                        });
                    } else {
                        for (const [key1, value1] of Object.entries(this.filters[key].options)) {
                            if (!avoid.find(key => key === key1)) {
                                const title = key1 === 'retailer_grouping' ? 'Include' : value1.title;
                                this.selectedFilters[key].filters.push({ id: key1, title, map: value1.map ? value1.map : '', hide: value1.hide });
                            }
                        }
                    }
                }
            }
            this.selectedFilters = _.cloneDeep(this.selectedFilters);
            this.setPreviewData();
        },
        handlePreviusStep(fromPreview = null) {
            this.showPreview = false;
            this.exporting = false;
            if (fromPreview) {
                this.$emit('from-preview');
            } else {
                this.$emit('previous-view');
            }
        },
    },
};
</script>

<style lang="scss">
    .export-options-retailer-total {
        .date-range-component-container{
            width: 95%;
        }
        .retailer-status-container{
            display: flex;
            margin-top: 5px;
            flex-direction: column;

            .span-status {
                display: block;
                word-wrap:break-word;
                white-space: normal;
            }
        }
    }
</style>

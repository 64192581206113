<template>
    <div class="btn-group-container" :class="{ disabled }">
        <template v-for="(buttonFromList, index) in list" :key="index">
            <slot name="buttonGroup" :index="index" :buttonFromList="buttonFromList" :onSelect="onSelect" :borderStyle="borderStyle">
                <button
                    class="btn btn-group"
                    :class="{ 'active': index === selected, 'inactive': index !== selected }"
                    :style="borderStyle(index)"
                    :disabled="disabledIndividualButton[buttonFromList] === true"
                    @click.prevent="onSelect(index)"
                >
                    {{ buttonFromList }}
                </button>
            </slot>
        </template>
    </div>
</template>

<script>
export default {
    name:  'ButtonGroup',
    props: {
        list: {
            type:    Array,
            default: () => ['Randomize', 'Schedule'],
        },
        selected: {
            type:    Number,
            default: 0,
        },
        disabled: {
            type:    Boolean,
            default: false,
        },
        disabledIndividualButton: {
            type:    Object,
            default: () => ({
                Randomize: false,
                Schedule:  false,
            }),
        },
    },
    methods: {
        onSelect(buttonId) {
            this.$emit('update:selected', buttonId);
        },
        borderStyle (index) {
            let style = '';
            if (index == 0) {
                style = 'border-radius: 4px 0px 0px 4px;';
            } else if (index == this.list.length - 1) {
                style = 'border-radius: 0px 4px 4px 0px;';
            }
            return style;
        },
    },
};
</script>

<style lang='scss'>

.btn-group-container{

    .btn-group{
        width: 115px;
        background-color: #ffffff;
        color: #000000;
        font-weight: bold;
    }

    .active{
        color: #ffffff;
        background-color: #0166FF;
        border: 2px solid #0166FF;
    }

    .inactive {
        border: 2px solid #D6D9DB;
    }

    .btn:focus {
        outline: none;
    }
}

</style>

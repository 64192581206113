<template>
    <div class="btn-gradient-container">
        <div class="btn-gradient-inner-container">
            <button
                class="btn-wrapper"
                :data="text"
                :disabled="disabled"
                :class="[color, buttonSize, { selected, disabled }]"
                :style="buttonStyle"
                @mouseover="hovering = true"
                @mouseleave="hovering = false"
                @click="$emit('click', $event)"
            >
                <slot name="leftIcon" />
                <span :style="spanStyle">
                    <slot name="buttonName" />
                </span>
                <slot name="rightIcon" />
            </button>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';

const PRIMARY_THEME = {
    'background': `
        linear-gradient(#fff, #fff) padding-box,
        linear-gradient(246.96deg, rgba(0, 102, 255, 1) 0%, rgba(80, 0, 184, 1) 100%) border-box`,
    'border':        '3px solid transparent',
    'border-radius': '6px',
    '>span':         {
        'background':              'linear-gradient(266.29deg, #0066FF, #5000B8 100%)',
        'background-clip':         'text',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
    },
    '&:hover': {
        'background': 'linear-gradient(246.96deg, rgba(0, 102, 255, 0.5) 0%, rgba(80, 0, 184, 0.5) 100%)',
        'border':     'none',
        'color':      'white',
        '>span':      {
            'background':              'white',
            'color':                   'white',
            'background-clip':         'text',
            '-webkit-text-fill-color': 'unset',
        },
    },
    '&.selected': {
        'background': 'linear-gradient(69deg, #5000B8 7.48%, #06F 94.09%)',
        'border':     'unset',
        'width':      'unset',
        'color':      'white',
        '>span':      {
            'background-clip':         'text',
            'color':                   'white',
            '-webkit-text-fill-color': 'unset',
        },
    },
};

const SECONDARY_THEME = {
    'border':        '3px solid #CFD8DC',
    'border-radius': '6px',
    'background':    'transparent',
    'color':         '#000',
    '&:hover':       {
        'background': `
            linear-gradient(#fff, #fff) padding-box,
            linear-gradient(246.96deg, rgba(0, 102, 255, 0.5) 0%, rgba(80, 0, 184, 0.5) 100%) border-box`,
        'border': '3px solid transparent',
        '>span':  {
            'background':              'transparent',
            'color':                   '#000',
            '-webkit-text-fill-color': '#000',
        },
    },
    '&.selected': {
        'background': `
            linear-gradient(#fff, #fff) padding-box,
            linear-gradient(246.96deg, #0066FF 0%, #5000B8 100%) border-box`,
        'border': '3px solid transparent',
        'width':  'unset',
        '>span':  {
            color: '#000',
        },
    },
};

export default {
    name:  'TsButton',
    props: {
        activateOnSelected: {
            type:    Boolean,
            default: true,
        },
        showGradientText: {
            type:    Boolean,
            default: true,
        },
        selected: {
            type:    Boolean,
            default: false,
        },
        size: {
            type:    String,
            default: 'sm',
            validator(value) {
                return ['sm', 'md', 'lg'].includes(value);
            },
        },
        color: {
            type:    String,
            default: 'primary',
            validator(value) {
                return ['primary', 'secondary'].includes(value);
            },
        },
        disabled: {
            type:    Boolean,
            default: false,
        },
        overrideTheme: {
            type:    Object,
            default: () => ({}),
        },
        text: {
            type:     String,
            default:  '',
            required: false,
        },
    },
    data() {
        return {
            hovering: false,
            theme:    {
                primary:   PRIMARY_THEME,
                secondary: SECONDARY_THEME,
            },
        };
    },
    emits: ['click'],
    computed: {
        buttonSize() {
            if (this.size === 'lg') {
                return 'btn-lg';
            } else if (this.size === 'sm') {
                return 'btn-sm';
            } else {
                return 'btn-md';
            }
        },
        buttonStyle() {
            let style = { ...this.currentTheme };
            if (this.selected) {
                style = { ...style, ...this.currentTheme['&.selected'] };
            }
            if (this.hovering && !this.disabled) {
                style = { ...style, ...this.currentTheme['&:hover'] };
            }
            return style;
        },
        spanStyle() {
            let style = this.currentTheme['>span'];
            if (this.selected) {
                style = { ...style, ...this.currentTheme['&.selected']?.['>span'] };
            }
            if (this.hovering  && !this.disabled) {
                style = { ...style, ...this.currentTheme['&:hover']?.['>span'] };
            }
            return style;
        },
        currentTheme() {
            let baseTheme = this.theme[this.color];
            if (this.overrideTheme) {
                baseTheme = _.merge({}, baseTheme, this.overrideTheme);
            }
            return baseTheme;
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../sass/variables.scss';

.btn-gradient-container {
    padding: 10px 5px;
    display: flex;
    .btn-gradient-inner-container {
        border: 0;
        padding: 4px;

        .btn-wrapper {
            font-weight: 500;
            background: #F0F3F6;
            display: flex;
            align-items: center;
        }
        .btn-lg {
            padding: 19px 21px;
            font-size: 16px;
            line-height: 1.5;
        }
        .btn-lg.primary {
            &:hover {
                padding: 22px 24px;
            }
        }
        .btn-md {
            display: inline-flex;
            padding: 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
        }
        .btn-md.primary {
            &:hover {
                padding: 13px;
            }
        }
        .btn-sm {
            display: inline-flex;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            gap: 5px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .btn-sm.primary {
            &:hover {
                padding: 8px 13px;
            }
        }
    }
}
</style>

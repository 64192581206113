<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="previous-arrow-icon-container"
    >
        <template #content>
            <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2845 2.80645e-06C9.84593 2.76811e-06 9.40711 0.146487 9.07285 0.439456L0.502188 7.93945C-0.167395 8.52539 -0.167395 9.47461 0.502188 10.0605L9.07285 17.5605C9.74243 18.1465 10.8272 18.1465 11.4967 17.5605C12.1663 16.9746 12.1663 16.0254 11.4967 15.4395L4.13776 9L11.4978 2.55938C12.1674 1.97344 12.1674 1.02422 11.4978 0.438283C11.163 0.145314 10.7238 2.84485e-06 10.2845 2.80645e-06Z" fill="#999999" />
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'PreviousArrow',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};

</script>
<style lang='scss'>
.previous-arrow-icon-container{
}
</style>

import TYPES from '@/stores/role/types.js';

const INVALID_WRITE_PERMISSIONS = ['view_only'];

const COMMON_WRITE_PERMISSIONS = ['content_editing', 'content_approval'];

export default {
    hasAccess:
        (state) =>
            ({ permission, options, ignoreOptions }) => {
                const permissionOptions = Object.keys(state.viewAs).length > 0 ? state.viewAs.assignments[permission]: state.assignments[permission];
                if (!permissionOptions) {
                    return false;
                }
                const hasEmptyOptions = Array.isArray(permissionOptions) && permissionOptions.filter((po) => po !== 'toggle').length === 0;
                if (hasEmptyOptions || ignoreOptions) {
                    return true;
                }
                if (permissionOptions.some((option) => INVALID_WRITE_PERMISSIONS.includes(option))) {
                    return false;
                }
                return options ? permissionOptions.length > 0 && permissionOptions.some((option) => options.includes(option)) : false;
            },
    hasReadAccess:
        (state) =>
            ({ permission }) =>
                state.hasAccess({ permission, ignoreOptions: true }),
    hasWriteAccess:
        (state) =>
            ({ permission, options = COMMON_WRITE_PERMISSIONS }) =>
                state.hasAccess({ permission, options }),
    isAdmin:         (state) => state.role_type === TYPES.ADMIN && !Object.keys(state.viewAs).length,
    isParentBrand:   (state) => state.role_type === TYPES.PARENT_BRAND || state.role_type === TYPES.ADMIN,
    isBrand:         (state) => state.role_type === TYPES.BRAND || state.role_type === TYPES.ADMIN,
    isRetailer:      (state) => state.role_type === TYPES.RETAILER || state.role_type === TYPES.ADMIN,
    roleTypeVerbose: (state) => {
        switch (state.role_type) {
            case TYPES.ADMIN:
                return 'admin';
            case TYPES.PARENT_BRAND:
                return 'parent-brand';
            case TYPES.BRAND:
                return 'brand';
            case TYPES.RETAILER:
                return 'retailer';
            default:
                return '';
        }
    },
};

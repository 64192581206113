<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="caret-down-purple-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.24363 3.8051C5.1012 3.91904 4.8988 3.91904 4.75637 3.8051L0.868174 0.694539C0.5803 0.46424 0.743146 0 1.1118 0H8.8882C9.25685 0 9.4197 0.46424 9.13183 0.694539L5.24363 3.8051Z" :fill="`url(#paint0_linear_${UUID})`" />
                <defs>
                    <linearGradient :id="`paint0_linear_${UUID}`" x1="-33.5293" y1="-2.09524" x2="-33.5293" y2="-7.42857" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#5000B8" />
                        <stop offset="1" stop-color="#0066FF" />
                    </linearGradient>
                </defs>
            </svg>
        </template>
    </generic-icon>
</template>

<script>

import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'CaretDownPurpleIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
    data() {
        return {
            UUID: Math.random().toString(),
        };
    },
};

</script>
<style lang='scss'>
.caret-down-purple-icon-container {}
</style>

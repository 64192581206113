
<template>
    <div class="exports-done-modal-container">
        <div class="done-inner-container">
            <div class="done-body-container-title">
                <h2>Congratulations</h2>
                <h3>You did it! 🎉</h3>
            </div>
            <div class="done-body-container-content">
                Your Export has been downloaded to your computer!
            </div>
            <div class="done-body-container-footer">
                <ts-button @click="close">
                    <template #buttonName>Done</template>
                </ts-button>
            </div>
        </div>
    </div>
</template>

<script>
import ArrowPointer from '@/components/app/ArrowPointer.vue';
import TsButton from '@/components/app/TsButton.vue';

export default {
    components: {
        TsButton,
        ArrowPointer,
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss">
    .done-inner-container {
        .done-body-container-title{
            margin-top: 20px;
        }

        .done-body-container-content {
            font-size: 20px;
        }

        .done-body-container-footer {
            margin-top: 5px;
            margin-bottom: 30px;
            display: flex;
            justify-content: center;

        }
    }
    .modal-lg {
        //width: 700px;
        //top: 200px;
    }
</style>

import _ from 'lodash';

export default {
    setLogoUrl(state, url) {
        state.originalLogoUrl = state.logoUrl;
        state.logoUrl = url;
    },
    setReseller(state, data) {
        state.reseller = data;
    },
    updateContacts(state) {
        if (state.contacts[0].id === 'empty') {
            state.contacts.pop();
        }
        state.contacts.push({
            cc_invoice: false,
            primary:    state.contacts.length === 0 ? true : false,
            first_name: state.inputValues['contact-first-name'],
            last_name:  state.inputValues['contact-last-name'],
            email:      state.inputValues['contact-email'],
            phone:      state.inputValues['contact-phone'],
            type:       state.contactOptions.find(option => option.id === state.inputValues['contact-type']).name,
        });
        state.inputValues['contact-first-name'] = '';
        state.inputValues['contact-last-name'] = '';
        state.inputValues['contact-type'] = 1;
        state.inputValues['contact-phone'] = '';
        state.inputValues['contact-email'] = '';
        state.newContactIsValid = false;
        state.updateComponent += 1;
    },
    updateContactInfoValue(state, { event }) {
        state.inputValues[event.inputIndex] = event.value;
        // We need first and last name to be filled. But, if none are filled,
        // it should also enable the Add Contact button if email or phone is filled.
        const validFullName = Boolean(state.inputValues['contact-first-name']) === Boolean(state.inputValues['contact-last-name']);
        if (validFullName && state.inputValues['contact-first-name']) {
            state.newContactIsValid = true;
        } else if (validFullName && (state.inputValues['contact-email'] || state.inputValues['contact-phone'])) {
            state.newContactIsValid = true;
        } else {
            state.newContactIsValid = false;
        }
    },
    updateContactInfoIsValidPhone(state, { event }) {
        if (event.inputIndex === 'contact-phone') {
            state.newContactPhoneIsValid = event.isValid;
        }
    },
    updateGeneralValue(state, { event, sectionIndex }) {
        state.inputValues[event.id] = event.value;
        if (event.id === 'brand-country') {
            const countryId = event.value;
            const filteredStates = state.states.filter(country => country.country_id === countryId);
            state.generalViewSettings[2].columnsData[0]['brand-state-province-and-zip-code'][0].options = filteredStates;
        }
        state.updateComponent += 1;
    },
    updateGeneralIsValidPhone(state, {event, sectionIndex}) {
        state.generalViewSettings[sectionIndex].columnsData[event.columnIndex][event.inputIndex].isValid = event.isValid;
    },
    populateInputs(state) {
        const phoneFormatter = (phone) => {
            if (!phone) {
                return '';
            }
            if (!phone.includes('tel')) {
                return `tel:+1${phone}`;
            }
            return phone;
        };

        const validateCountry = (country) => {
            if (country) {
                let selectedCountry = '';
                if (country === 'US') {
                    selectedCountry = state.countries.find(country => country.name === 'United States of America');
                } else if (country === 'CA') {
                    selectedCountry = state.countries.find(country => country.name === 'Canada');
                } else {
                    selectedCountry = state.countries.find(country => country.name === state.resellerSettings.country);
                }
                const filteredStates = state.states.filter(country => country.country_id === selectedCountry.id);
                state.generalViewSettings[2].columnsData[0]['brand-state-province-and-zip-code'][0].options = filteredStates;
                return selectedCountry.id;
            }
            return '';
        };
        const validateState = (stateName) => {
            if (stateName) {
                const selectedState = state.states.find(s => s.abbreviation === stateName);
                return selectedState.id;
            }
            return '';
        };
        const validateId = (id) => {
            if (id) {
                return parseInt(id) ?? '';
            }
            return null;
        };
        state.inputValues['brand-name'] = state.reseller.name ?? '';
        state.inputValues['upload-logo'] = state.logoUrl ?? '';
        state.inputValues['brand-manager-accounts'] = state.oemManagerChildren.map(child => child.id) ?? [];
        state.inputValues['brand-address-1'] = state.reseller.address ?? '';
        state.inputValues['brand-address-2'] = state.reseller.address_2 ?? '';
        state.inputValues['brand-city'] = state.reseller.city ?? '';
        state.inputValues['brand-state-province'] = validateState(state.reseller.state);
        state.inputValues['brand-zip-code'] = state.reseller.zip ?? '';
        state.inputValues['brand-country'] = validateCountry(state.resellerSettings.country);
        state.inputValues['brand-time-zone'] = state.reseller.timezone_id ?? null;
        state.inputValues['brand-phone'] = phoneFormatter(state.reseller.phone);
        state.inputValues['brand-website'] = state.socials.website ?? '';
        state.inputValues['brand-facebook'] = state.socials.facebook ?? '';
        state.inputValues['brand-instagram'] = state.socials.instagram ?? '';
        state.inputValues['brand-twitter'] = state.socials.twitter ?? '';
        state.inputValues['brand-linkedin'] = state.socials.linkedin ?? '';
        state.inputValues['brand-program-name'] = state.resellerSettings.program_name ?? '';
        state.inputValues['brand-profile-document'] = state.resellerSettings.profile_document ?? '';
        state.inputValues['billing-contact-first-name'] = state.resellerSettings.billing_contact_first_name ?? '';
        state.inputValues['billing-contact-last-name'] = state.resellerSettings.billing_contact_last_name ?? '';
        state.inputValues['billing-contact-email'] = state.resellerSettings.billing_email ?? '';
        state.inputValues['billing-contact-phone'] = phoneFormatter(state.resellerSettings.billing_phone);
        state.inputValues['support-contact-name'] = state.resellerSettings.support_name ?? '';
        state.inputValues['support-contact-phone'] = phoneFormatter(state.resellerSettings.support_phone);
        state.inputValues['support-contact-email'] = state.resellerSettings.support_email ?? '';
        state.inputValues['customer-success-manager-contact-name'] = validateId(state.resellerSettings.customer_success_manager_contact_id);
        state.inputValues['customer-success-manager-contact-email'] = state.resellerSettings.customer_success_manager_contact_email ?? '';
        state.inputValues['account-executive-contact-name'] = validateId(state.resellerSettings.account_executive_contact_id);
        state.inputValues['account-executive-contact-email'] = state.resellerSettings.account_executive_contact_email ?? '';
        state.inputValues['contact-type'] = 1;
        state.inputValues['contact-name'] = '';
        state.inputValues['contact-email'] = '';
        state.inputValues['contact-phone'] = '';
        state.inputValues.sales_email = state.resellerSettings.sales_email ?? '';
        state.inputValues.sales_phone = state.resellerSettings.sales_phone ?? '';
        state.updateComponent += 1; // Force re-render
    },
    setChildren(state, children) {
        state.oemManagerChildren = children;
    },
    setSocials(state, payload) {
        state.socials.website = payload.website;
        state.socials.facebook = payload.facebook;
        state.socials.instagram = payload.instagram;
        state.socials.twitter = payload.twitter;
        state.socials.linkedin = payload.linkedin;
    },
    setOemManagers(state, oemManagers) {
        state.generalViewSettings[0].columnsData[0]['brand-manager-accounts'].options = oemManagers;
        state.oemManagers = oemManagers;
    },
    setOemManagerId(state, id) {
        state.oemManagerId = id;
    },
    setStates(state, states) {
        state.states = states;
        state.generalViewSettings[2].columnsData[0]['brand-state-province-and-zip-code'][0].options = states;
    },
    setTimeZones(state, timeZones) {
        state.generalViewSettings[2].columnsData[0]['brand-time-zone'].options = timeZones;
    },
    setLanguages(state, languages) {
        state.languages = languages;
    },
    setCountries(state, countries) {
        state.countries = countries;
        state.generalViewSettings[2].columnsData[0]['brand-country'].options = countries;
    },
    setCustomerSuccessManagers(state, customerSuccessManagers) {
        state.contactInfoViewSettings[3].columnsData[0]['customer-success-manager-contact-name'].options = customerSuccessManagers;
    },
    setProductCottonText(state, ProductText) {
       state.productCottonText = ProductText;
    },
    setRetailerGroupingCottonText(state, retailerGroupingText) {
       state.retailerGroupingCottonText = retailerGroupingText;
    },
    setAccountExecutives(state, accountExecutives) {
        state.contactInfoViewSettings[3].columnsData[1]['account-executive-contact-name'].options = accountExecutives;
    },
    handleDefaultContact(state, index) {
        if (!state.contacts[index].primary) {
            state.contacts.forEach((contact, i) => {
                contact.primary = index === i ? true : false;
            });
        }
    },
    handleCcContact(state, index) {
        if (state.contacts[index].email) {
            state.contacts[index].cc_invoice = !state.contacts[index].cc_invoice;
        }
    },
    setIsParent(state, isParent) {
        state.isParent = isParent !== undefined ? isParent : state.oemManagers.find(om => om.id === state.oemManagerId).is_parent;
    },
    setResellerSettings(state, resellerSettings) {
        state.resellerSettings = resellerSettings;
    },
    updateComponents(state) {
        state.updateComponent += 1;
    },
    handleContactSelected(state, index) {
        state.selectedContactIndex = index;
        state.inputValues['contact-type'] = state.contactOptions.find(option => option.name === state.contacts[index].type).id;
        state.inputValues['contact-first-name'] = state.contacts[index].first_name;
        state.inputValues['contact-last-name'] = state.contacts[index].last_name;
        state.inputValues['contact-email'] = state.contacts[index].email;
        state.inputValues['contact-phone'] = state.contacts[index].phone;
        state.updateComponent += 1; // Force re-render
    },
    resetContactInputs(state) {
        state.inputValues['contact-first-name'] = '';
        state.inputValues['contact-last-name'] = '';
        state.inputValues['contact-type'] = 1;
        state.inputValues['contact-phone'] = '';
        state.inputValues['contact-email'] = '';
        state.selectedContactIndex = null;
        state.newContactIsValid = false;
        state.updateComponent += 1;
    },
    handleDeleteContact(state) {
        const isDeletedContactPrimary = state.contacts[state.selectedContactIndex].primary;
        state.contacts.splice(state.selectedContactIndex, 1);
        state.selectedContactIndex = null;
        state.inputValues['contact-first-name'] = '';
        state.inputValues['contact-last-name'] = '';
        state.inputValues['contact-type'] = 1;
        state.inputValues['contact-phone'] = '';
        state.inputValues['contact-email'] = '';
        if (!state.contacts.length) {
            state.contacts.push(
                {
                    first_name: '',
                    last_name:  '',
                    type:       '',
                    primary:    false,
                    cc_invoice: false,
                    email:      '',
                    phone:      '',
                    id:         'empty',
                },
            );
        }
        if (isDeletedContactPrimary && state.contacts.length) {
            state.contacts[0].primary = true;
        }
        state.updateComponent += 1;
    },
    handleContactJson(state) {
        if (!state.resellerSettings.contacts?.length) {
            return;
        }
        state.contacts = _.cloneDeep(state.resellerSettings.contacts);
        if (state.contacts.length === 1) {
            state.contacts[0].primary = true;
        }
    },
    handleContactEdit(state) {
        if (state.selectedContactIndex !== null && state.selectedView === 'contact-information') {
            state.contacts[state.selectedContactIndex].first_name = state.inputValues['contact-first-name'];
            state.contacts[state.selectedContactIndex].last_name = state.inputValues['contact-last-name'];
            state.contacts[state.selectedContactIndex].type = state.contactOptions.find(option => option.id === parseInt(state.inputValues['contact-type'])).name;
            state.contacts[state.selectedContactIndex].phone = state.inputValues['contact-phone'];
            state.contacts[state.selectedContactIndex].email = state.inputValues['contact-email'];
        }
    },
    setIsSaving(state, value) {
        state.isSaving = value;
    },
    handleContactEmailEdit(state) {
        if (!state.inputValues['contact-email']) {
            state.contacts[state.selectedContactIndex].cc_invoice = false;
        }
        state.updateComponent += 1;
    },
    handleUpdateSelectedView(state, view) {
        state.selectedView = view;
    },
    handleSelectedView(state, payload) {
        if (payload.isBrand) {
            if (payload.externalSelectedView) {
                state.selectedView = payload.externalSelectedView;
            } else {
                state.selectedView = 'general';
            }
        }
    },
    saveContactEdit(state) {
        const contactIndex = state.selectedContactIndex;
        const contactValues = {
            first_name: state.inputValues['contact-first-name'],
            last_name:  state.inputValues['contact-last-name'],
            type:       state.contactOptions.find(type => type.id === state.inputValues['contact-type']).name,
            phone:      state.inputValues['contact-phone'],
            email:      state.inputValues['contact-email'],
        };
        Object.assign(state.contacts[contactIndex], contactValues);
    },
    restorePrimaryContact(state) {
        const contactIndex = state.resellerSettings.contacts.findIndex(contact => contact.primary);

        if (!state.contacts[contactIndex].primary) {
            state.contacts.forEach((contact, i) => {
                contact.primary = contactIndex === i ? true : false;
            });
        }
    },
    updateCurrentContacts(state, contacts) {
        state.resellerSettings.contacts = contacts;
    },
};

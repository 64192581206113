<template>
    <div class="user-tray" :class="{visible: open}">
        <div class="closer" @click="close" />
        <div class="arrow" />
        <div class="settings">
            <div class="account-role-info">
                <div class="account-role-name">{{ roleNewNames(user.role_id, user.role_name) }}</div>
            </div>
            <div class="user-badge">
                <img v-if="user.image_url" class="initials profile-picture" :src="user.image_url">
                <div v-else class="initials">{{ initials }}</div>
            </div>
            <div class="user-info">
                <div class="user-name">{{ user.name }}</div>
                <div class="user-email">
                    <p class="email"> {{ user.email }} </p>
                </div>
            </div>
            <div :class="roleStore.viewMode ? 'links-disabled' : 'links'">
                <a :href="roleStore.viewMode ? '#' : getProfileRoute">
                    <MyProfileIcon />
                    My Profile
                </a>
            </div>
            <div v-if="userStore.hasReadAccess({ permission: 'admin-other-viewing-mode' })" class="links">
                <button @click="startViewingWizard">
                    <EyeIcon />
                    Viewing Mode
                </button>
            </div>
            <div class="login-actions">
                <div class="logout">
                    <a href="/login/logout">
                        LOGOUT
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from '@/mixins/ClickOutside.js';
import RolesMixin from './RolesMixin.js';
import MyProfileIcon from '@/components/icons/MyProfileIcon.vue';
import EyeIcon from '@/components/icons/EyeIcon.vue';
import ViewingWizardModal from '@/components/profile_menu/ViewingWizardModal.vue';
import ShowModal from '@/mixins/ShowModal';
import { mapStores } from 'pinia';
import { userStore, roleStore } from '@/stores/pinia.js';

export default {
    components: {
        EyeIcon,
        MyProfileIcon,
    },
    mixins: [
        ClickOutside,
        ShowModal,
    ],
    props: {
        open: {
            type:     Boolean,
            required: true,
        },
        user: {
            type:     Object,
            required: true,
        },
        brandId: {
            type:     Number,
            required: false,
            default:  null,
        },
        dealershipId: {
            type:     Number,
            required: false,
            default:  null,
        },
    },
    data() {
        return {
            isClosable: false,
        };
    },
    computed: {
        ...mapStores(userStore, roleStore),
        initials() {
            return this.user.name.split(' ').map(name => name[0]).join('');
        },
        getProfileRoute() {
            if (roleStore.viewMode){
                return null;
            } else {
                return '/users/profile';
            }
        },
    },
    watch: {
        // This async/await/nextTick stuff is needed to prevent ClickOutside
        // from auto-closing the tray when you click to open it
        async open(val) {
            this.isClosable = false;
            await this.$nextTick();
            if (val) {
                this.isClosable = true;
            }
        },
    },
    methods: {
        close() {
            if (this.open && this.isClosable) {
                this.$emit('update:open', false);
            }
        },
        handleClickOutside() {
            this.close();
        },
        roleNewNames(roleId, roleName) {
            return RolesMixin.methods.getRoleName(roleId, roleName);
        },
        startViewingWizard() {
            let props = {
                brandId:      this.brandId,
                dealershipId: this.dealershipId,
                user:         this.user,
                resellers:    this.resellers,
            };

            return this.$showModal(ViewingWizardModal, props)
                .catch(value => console.log('catch', value));
        },
    },
};
</script>

<style lang="scss" scoped>
.user-tray {
    display: none;

    &.visible {
        display: block;
        position: fixed;
        z-index: 13;

        .closer {
            cursor: pointer;
            display: block;
            height: 66px;
            position: fixed;
            right: 0;
            top: 0;
            width: 50px;
            z-index: 13;
        }

        .arrow {
            border-color: transparent;
            border-bottom-color: #5000B8;
            border-style: solid;
            border-width: 0 5px 5px 5px;
            position: fixed;
            right: 25px;
            top: 61px;
            z-index: 13;
        }

        .settings {
            background-color: white;
            border-radius: 5px;
            box-shadow: rgb(0 0 0 / 25%) 3px 3px 10px 0;
            position: fixed;
            right: 10px;
            top: 66px;
            width: 303px;

            .user-badge {
                background-color: transparent;
                position: absolute;
                display: flex;
                margin: 20px 0 0 10px;
                border-radius: 5px;
                border: 3px solid white;

                .initials {
                    background: linear-gradient(0, #5000b8, blue);
                    color: white;
                    display: block;
                    font-size: 24pt;
                    font-weight: 700;
                    height: 2em;
                    text-align: center;
                    padding: 6px;
                    width: 2em;
                }
                .profile-picture {
                    padding: 0px;
                }
            }

            .user-info {
                margin-top: 35px;
                width: 303px;
                margin-bottom: 20px;
            }

            .user-name {
                font-size: 14px;
                font-weight: 700;
                display: flex;
                padding: 5px 0 0 90px;
            }

            .user-email {
                font-size: 14px;
                font-weight: 400;
                display: flex;
                padding-left:  90px;
                color: #85878F;
                width: 303px;
            }

            .email {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .account-role-info {
                position: absolute;
                width: 303px;
                height: 36px;
                color: white;
                background: #5000B8;
                border-radius: 4px 4px 0px 0px;

                .account-role-name {
                    font-size: 10px;
                    font-weight: 700;
                    letter-spacing: 0.1em;
                    color: #CBA4FF;
                    padding: 16px 0 0 90px;
                    text-transform: uppercase;
                }

            }
            .links {
                list-style: none;
                padding: 0;
                border-top: 1px solid #DEE0E2;
                height: 49px;

                button {
                  color: #333333;
                  display: block;
                  height: 100%;
                  padding: 15px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 16px;
                  text-decoration: none;
                  width: 100%;
                  text-align: center;
                  border: none;
                  background-color: white;

                  &:hover {
                    background-color: #F5F5F5;
                  }
                }

                a {
                    color: #333333;
                    display: block;
                    height: 100%;
                    padding: 15px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    text-decoration: none;
                    width: 100%;
                    text-align: center;
                }
                &:hover {
                    background-color: #F5F5F5;
                }
            }
            .links-disabled {
                list-style: none;
                padding: 0;
                border-top: 1px solid #DEE0E2;
                height: 49px;

                a {
                    color: #333333;
                    display: block;
                    height: 100%;
                    padding: 15px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    text-decoration: none;
                    width: 100%;
                    text-align: center;
                    cursor: not-allowed;
                }
            }

            .login-actions {
                border-top: 1px solid #DEE0E2;
                line-height: 24px;
                background: #5000B8;
                border-radius: 0px 0px 4px 4px;

                a {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 11px;
                    letter-spacing: 0.1em;
                    color: #CBA4FF;
                        &:hover {
                        color: #e3ceff;
                    }
                }
            }
        }

        li {
            width: 100%;
        }

        a {
            color: #333333;
            display: block;
            height: 100%;
            padding: 10px;
            text-decoration: none;
            width: 100%;
            text-align: center;

        }

        .my-profile-menu-icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 100px;
        }
        .eye-icon-container {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              left: 80px;
              top: 173px;
          }
    }
}
</style>

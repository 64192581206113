<template>
    <div>
        <filter-dropdown
            v-if="availableLanguages.length"
            :multiple="false"
            :options="availableLanguages"
            option-key="code"
            tabindex="-1"
            v-model:selected="selectedLanguage"
        />
    </div>
</template>
<script>
import FilterDropdown from '@/components/FilterDropdown.vue';

export default {
    components: {
        FilterDropdown,
    },
    data() {
        return {
            availableLanguages: [],
            selectedLanguage:   null,
        };
    },
    watch: {
        selectedLanguage(value) {
            window.Localize.setLanguage(value);
        },
    },
    mounted() {
        this.polyfillLocalize();

        window.Localize.initialize({
            key:              '4Ar3qEhLIW4ai',
            rememberLanguage: true,
        // other options go here, separated by commas
        });

        window.Localize.on('widgetLoaded', this.loadAvailableLanguages);

        window.Localize.on('setLanguage', (data) => {
            if (!this.availableLanguages.length) {
                this.loadAvailableLanguages();
            }
            this.selectedLanguage = data.to;
        });
    },
    methods: {
        loadAvailableLanguages() {
            window.Localize.getAvailableLanguages((error, languages) => {
                if (error) {
                    console.error(error);
                }
                this.availableLanguages = languages;
            });
        },
        polyfillLocalize() {
            if (!window.Localize) {
                window.Localize = {
                    translate:             () => {},
                    untranslate:           () => {},
                    phrase:                () => {},
                    initialize:            () => {},
                    translatePage:         () => {},
                    setLanguage:           () => {},
                    getLanguage:           () => {},
                    detectLanguage:        () => {},
                    getAvailableLanguages: () => {},
                    untranslatePage:       () => {},
                    bootstrap:             () => {},
                    prefetch:              () => {},
                    on:                    () => {},
                    off:                   () => {},
                    hideWidget:            () => {},
                    showWidget:            () => {},
                };
            }
        },
    },
};
</script>
<style lang="scss">
    .localize-widget-wrapper {
        position: fixed;
        right: 210px;
        top: 13px;
        z-index: 11;
        div {
            background-color: white;
            padding-left: 4px;
        }
    }
    #localize-widget {
        display: none !important;
    }
</style>


<template>
    <div class="export-brand-welcome-container-internal">
        <div class="body-container-title">
            <span>Choose Export Type</span>
        </div>
        <div class="body-container-content">
            <div class="body-container-content-grid">
                <div class="body-container-content-left">
                    <arrow-pointer
                        v-if="buttonSelectInternal === null"
                        class="arrow-pointer-container"
                        :pointing-to="'right'"
                        :is-animated="true"
                    />
                    <ts-button :selected="buttonSelectInternal === 0" @click="handleGetPath(0)">
                        <template #buttonName>Brand</template>
                    </ts-button>
                    <div class="content-left-info">
                        <span class="content-left-info-text">Brand/User Info</span>
                        <span class="content-left-info-text">Program Status</span>
                        <span class="content-left-info-text">Destination Metrics</span>
                    </div>
                    <div v-if="buttonSelectInternal === 0">
                        <div class="brand-select-container-content">
                            <brand-manager-filter-dropdown
                                :select-options="selectOptions"
                                :selected-brands="selectedBrands"
                                :combo-box="selectedBrands.length > 1 || selectedBrands.length === selectOptions.length ? 'Brands' : 'Brand'"
                                :label="selectedBrands.length > 1 ? 'Brands' : 'Brand'"
                                @selected-brands="handleSelectedBrands($event)"
                            />
                        </div>
                    </div>
                </div>
                <div class="divider" />
                <div class="body-container-content-right">
                    <arrow-pointer
                        v-if="buttonSelectInternal === null"
                        class="arrow-pointer-container"
                        :pointing-to="'right'"
                        :is-animated="true"
                    />
                    <ts-button :selected="buttonSelectInternal === 1" :button-disabled="false" @click="handleGetPath(1)">
                        <template #buttonName>Individual Retailer</template>
                    </ts-button>
                    <div class="content-right-info">
                        <span class="content-right-info-text">Contact Info</span>
                        <span class="content-right-info-text">Destination Status</span>
                        <span class="content-right-info-text">Retailer Settings</span>
                    </div>
                    <div v-if="buttonSelectInternal === 1">
                        <div class="brand-select-container-content">
                            <brand-manager-filter-dropdown
                                :select-options="selectOptions"
                                :selected-brands="selectedBrands"
                                :combo-box="selectedBrands.length > 1 || selectedBrands.length === selectOptions.length ? 'Brands' : 'Brand'"
                                :label="selectedBrands.length > 1 ? 'Brands' : 'Brand'"
                                @selected-brands="handleSelectedBrands($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="body-container-content-grid">
                <div class="body-container-content-bottom-left">
                    <arrow-pointer
                        v-if="buttonSelectInternal === null"
                        class="arrow-pointer-container"
                        :pointing-to="'right'"
                        :is-animated="true"
                    />
                    <ts-button :selected="buttonSelectInternal === 2" :button-disabled="false" @click="handleGetPath(2)">
                        <template #buttonName>Brand/Retailer Data</template>
                    </ts-button>
                    <div class="content-left-info">
                        <span class="content-left-info-text">Destination Activity</span>
                        <span class="content-left-info-text">Business Info</span>
                        <span class="content-left-info-text">Content Demand</span>
                    </div>
                    <div v-if="buttonSelectInternal === 2">
                        <div class="brand-select-container-content">
                            <brand-manager-filter-dropdown
                                :select-options="selectOptions"
                                :combo-box="selectedBrands.length > 1 || selectedBrands.length === selectOptions.length ? 'Brands' : 'Brand'"
                                :label="selectedBrands.length > 1 ? 'Brands' : 'Brand'"
                                :selected-brands="selectedBrands"
                                :highlight="false"
                                @selected-brands="handleSelectedBrands($event)"
                            />
                            <arrow-pointer
                                v-if="buttonSelectInternal === 2 && selectedBrands.length > 0 && range.start === '' && range.end === ''"
                                class="content-data-arrow-pointer-container"
                                :pointing-to="'right'"
                                :is-animated="true"
                            />
                            <ts-date-range
                                class="brand-date-select"
                                :highlight-selected-quick-date="false"
                                :ignore-selected-date-range-watch="true"
                                :placeholder="'Select Date Range'"
                                :quick-future-dates="quickFutureDates"
                                :range="range"
                                quick-date-label="Quick Actions"
                                :selected-quick-date-id="selectedQuickDateId"
                                :show-adjust-date="false"
                                :show-calendar-icon="false"
                                :show-label="false"
                                :single-input="true"
                                :columns="2"
                                :is-range="true"
                                label="Select Date Range"
                                @update:range="(event) => handleDateRangeChange(event)"
                                @update:selected-quick-date="(event) => handleSelectedQuickDate(event)"
                            />
                        </div>
                    </div>
                </div>
                <div class="divider" />
                <div class="body-container-content-bottom-right">
                    <arrow-pointer
                        v-if="buttonSelectInternal === null"
                        class="arrow-pointer-container"
                        :pointing-to="'right'"
                        :is-animated="true"
                    />
                    <ts-button :selected="buttonSelectInternal === 3" :button-disabled="false" @click="handleGetPath(3)">
                        <template #buttonName>Content Data</template>
                    </ts-button>
                    <div class="content-right-info">
                        <span class="content-right-info-text">Brand Content</span>
                        <span class="content-right-info-text">Post Metrics</span>
                        <span class="content-right-info-text">Destination Data</span>
                    </div>
                    <div v-if="buttonSelectInternal === 3">
                        <div class="brand-select-container-content">
                            <brand-manager-filter-dropdown
                                :select-options="selectOptions"
                                :combo-box="selectedBrands.length > 1 || selectedBrands.length === selectOptions.length ? 'Brands' : 'Brand'"
                                :label="selectedBrands.length > 1 ? 'Brands' : 'Brand'"
                                :selected-brands="selectedBrands"
                                :highlight="false"
                                @selected-brands="handleSelectedBrands($event)"
                            />
                            <arrow-pointer
                                v-if="buttonSelectInternal === 3 && selectedBrands.length > 0 && range.start === '' && range.end === ''"
                                class="content-data-arrow-pointer-container"
                                :pointing-to="'right'"
                                :is-animated="true"
                            />
                            <ts-date-range
                                class="brand-date-select"
                                :highlight-selected-quick-date="false"
                                quick-date-label="Quick Actions"
                                :ignore-selected-date-range-watch="true"
                                :placeholder="'Select Date Range'"
                                :quick-future-dates="quickFutureDates"
                                :range="range"
                                :selected-quick-date-id="selectedQuickDateId"
                                :show-adjust-date="false"
                                :show-calendar-icon="false"
                                :show-label="false"
                                :single-input="true"
                                :columns="2"
                                :is-range="true"
                                label="Select Date Range"
                                @update:range="(event) => handleDateRangeChange(event)"
                                @update:selected-quick-date="(event) => handleSelectedQuickDate(event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArrowPointer from '@/components/app/ArrowPointer.vue';
import BrandManagerFilterDropdown from '@/components/app/BrandManagerFilterDropdown.vue';
import TsButton from '@/components/app/TsButton.vue';
import TsDateRange from '@/components/app/TsDateRange.vue';

export default {
    name:       'ExportInternal',
    components: {BrandManagerFilterDropdown, ArrowPointer, TsDateRange, TsButton},
    props:      {
        buttonSelect: {
            type:     Number,
            required: true,
        },
        selectOptions: {
            type:     Array,
            required: true,
        },
        selectedBrands: {
            type:     Array,
            required: true,
        },
        range: {
            type:    Object,
            default: () => {},
        },
        quickFutureDates: {
            type:     Array,
            required: true,
        },
        selectedQuickDateId: {
            type:     Number,
            required: false,
        },
        oemManagerOptions: {
            type:     Array,
            required: true,
        },
    },
    emits:      ['get-path', 'selected-brands', 'update:range', 'update:selected-quick-date'],
    data() {
        return {
            buttonSelectInternal: null,
        };
    },
    methods: {
        handleGetPath(path) {
            this.buttonSelectInternal = path;
            this.$emit('get-path', path);
        },
        handleSelectedBrands(event) {
            this.$emit('selected-brands', event);
        },
        handleDateRangeChange(event) {
            this.$emit('update:range', event);
        },
        handleSelectedQuickDate(event) {
            this.$emit('update:selected-quick-date', event);
        },
    },
};
</script>

<style lang="scss">
.export-brand-welcome-container-internal {
    .ts-date-group .date-range-container {
        z-index: 10 !important;
        position: absolute;
        top: -23px;
    }
    .base-date-group .input-group .form-control-value {
        margin-left: 5px !important;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    .body-container-content {
        .body-container-content-grid {
            display: flex;
            justify-content: center;
            .body-container-content-left {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 150px;

                .arrow-pointer-container {
                    position: absolute;
                    left: 285px;
                    top: 150px;
                }

                .content-left-info {
                    display: flex;
                    flex-direction: row;
                    align-items: center;


                    .content-left-info-text {
                        color: #000;
                        text-align: center;
                        font-family: "Open Sans";
                        font-size: 12.143px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 17px */
                        margin-left: 3px;
                    }
                }

                .content-left-info span:not(:last-child)::after {
                    content: "|";
                    color: #CFD8DC;
                    margin: 0 10px;
                }

                .preview-image {
                    margin-top: 2rem;
                    width: 100%;

                    img {
                        width: 100%;
                        height: auto;
                        margin-bottom: 30px;
                    }
                }

                .preview-button {
                    button {
                        border-radius: 5px;
                        border: 2px solid #CFD8DC;
                        background: #FFF;
                        color: #000;
                        padding: 4px;
                        text-align: center;
                        font-family: "Open Sans";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                }

                .brand-select-container-content {
                    display: flex;
                    flex-direction: column;
                    margin-top: 2rem;
                    width: 102%;

                    .brand-manager-filter-dropdown {
                        margin-bottom: 10px;
                        .pulsing-arrow-container {
                            position: absolute;
                            left: 235px;
                            bottom: 233px;
                        }
                        .filter-dropdown {
                            width: 100% !important;
                        }
                    }
                    .brand-date-select {
                        .input-group {
                            justify-content: space-between;
                            .form-control{
                                width: 100%;
                                border: none;
                                font-size: 10px !important;
                            }
                        }
                        .arrow-pointer-container {
                            position: absolute;
                            bottom: 145px;
                            left: 150px;
                        }
                    }
                }
                .btn-sm.selected{
                    padding: 8px 13px;
                }
            }

            .body-container-content-bottom-left {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-height: 192px;

                .arrow-pointer-container {
                    position: absolute;
                    left: 250px;
                    top: 300px;
                }

                .content-left-info {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .content-left-info-text {
                        color: #000;
                        text-align: center;
                        font-family: "Open Sans";
                        font-size: 12.143px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 17px */
                    }
                }

                .content-left-info span:not(:last-child)::after {
                    content: "|";
                    color: #CFD8DC;
                    margin: 0 10px;
                }

                .preview-image {
                    margin-top: 2rem;
                    width: 100%;

                    img {
                        width: 100%;
                        height: auto;
                        margin-bottom: 30px;
                    }
                }

                .preview-button {
                    button {
                        border-radius: 5px;
                        border: 2px solid #CFD8DC;
                        background: #FFF;
                        color: #000;
                        padding: 4px;
                        text-align: center;
                        font-family: "Open Sans";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                }

                .brand-select-container-content {
                    margin-top: 2rem;
                    display: flex;
                    flex-direction: column;
                    width: 102%;

                    .brand-manager-filter-dropdown {
                        margin-bottom: 10px;
                        .pulsing-arrow-container {
                            position: absolute;
                            left: 230px !important;
                            bottom: 170px;
                        }
                        .filter-dropdown {
                            width: 100% !important;
                        }
                    }
                    .brand-date-select {
                        .input-group {
                            justify-content: space-between;
                            .form-control{
                                width: 100%;
                                border: none;
                                font-size: 10px !important;
                            }
                        }
                        .pulsing-arrow-container {
                            position: absolute;
                            bottom: 141px;
                            left: 210px !important;
                        }
                    }
                    .content-data-arrow-pointer-container{
                        position: absolute;
                        bottom: 142px;
                    }
                }
                .btn-sm.selected{
                    padding: 8px 13px;
                }
            }


            .body-container-content-right {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 150px;

                .arrow-pointer-container {
                    position: absolute;
                    right: 400px;
                    top: 150px;
                }

                .content-right-info {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .content-right-info-text {
                        color: #000;
                        text-align: center;
                        font-family: "Open Sans";
                        font-size: 12.143px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 17px */
                    }
                }

                .content-right-info span:not(:last-child)::after {
                    content: "|";
                    color: #CFD8DC;
                    margin: 0 10px;
                }

                .preview-image {
                    margin-top: 2rem;
                    width: 100%;

                    img {
                        width: 100%;
                        height: 240px;
                        margin-bottom: 30px;
                    }
                }

                .preview-button {
                    button {
                        border-radius: 5px;
                        border: 2px solid #CFD8DC;
                        background: #FFF;
                        color: #000;
                        padding: 4px;
                        text-align: center;
                        font-family: "Open Sans";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                }

                .brand-select-container-content {
                    display: flex;
                    flex-direction: column;
                    margin-top: 2rem;
                    width: 102%;
                    .brand-manager-filter-dropdown {
                        margin-bottom: 10px;
                        .pulsing-arrow-container {
                            position: absolute;
                            right: 400px;
                            bottom: 233px;
                        }
                        .filter-dropdown {
                            width: 100% !important;
                        }
                    }
                    .brand-date-select {
                        .input-group {
                            justify-content: space-between;
                            .form-control{
                                width: 100%;
                                border: none;
                                font-size: 10px !important;
                            }
                        }
                        .arrow-pointer-container {
                            position: absolute;
                            bottom: 145px;
                            right: 370px;
                        }
                    }
                }
                .btn-sm.selected{
                    padding: 8px 13px;
                }
            }
            .body-container-content-bottom-right {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-height: 192px;

                .arrow-pointer-container {
                    position: absolute;
                    right: 400px;
                    top: 300px;
                }

                .content-right-info {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .content-right-info-text {
                        color: #000;
                        text-align: center;
                        font-family: "Open Sans";
                        font-size: 12.143px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%; /* 17px */
                    }
                }

                .content-right-info span:not(:last-child)::after {
                    content: "|";
                    color: #CFD8DC;
                    margin: 0 10px;
                }

                .preview-image {
                    margin-top: 2rem;
                    width: 100%;

                    img {
                        width: 100%;
                        height: 240px;
                        margin-bottom: 30px;
                    }
                }

                .preview-button {
                    button {
                        border-radius: 5px;
                        border: 2px solid #CFD8DC;
                        background: #FFF;
                        color: #000;
                        padding: 4px;
                        text-align: center;
                        font-family: "Open Sans";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                }

                .brand-select-container-content {
                    display: flex;
                    flex-direction: column;
                    margin-top: 2rem;
                    width: 102%;

                    .brand-manager-filter-dropdown {
                        margin-bottom: 10px;
                        .pulsing-arrow-container {
                            position: absolute;
                            right: 415px;
                            bottom: 170px;
                        }
                        .filter-dropdown {
                            width: 100% !important;
                        }
                    }
                    .brand-date-select {
                        .input-group {
                            justify-content: space-between;
                            .form-control{
                                width: 100%;
                                border: none;
                                font-size: 10px !important;
                            }
                        }
                        .pulsing-arrow-container {
                            position: absolute;
                            bottom: 138px;
                            right: 460px;
                        }
                    }
                    .content-data-arrow-pointer-container{
                        position: absolute;
                        bottom: 142px;
                    }

                }
                .btn-sm.selected{
                    padding: 8px 13px;
                }
            }

            .divider {
                border-left: 2px solid #CFD8DC;
                margin: 0 60px;
            }
        }
    }
}
</style>

export function countriesList() {
    let countries = [
        {
            id:   1,
            name: 'United States of America',
            code: 'US',
            slug: 'US',
        },
        {
            id:   2,
            name: 'Canada',
            code: 'CA',
            slug: 'CA',
        },
        {
            id:   3,
            name: 'Argentina',
            code: 'AR',
        },
        {
            id:   4,
            name: 'Perú',
            code: 'PE',
            slug: 'Peru',
        },
        {
            id:   5,
            name: 'Chile',
            code: 'CL',
        },
        {
            id:   6,
            name: 'Costa Rica',
            code: 'CR',
        },
        {
            id:   7,
            name: 'Brazil',
            code: 'BR',
        },
        {
            id:   8,
            name: 'México',
            code: 'MX',
            slug: 'Mexico',
        },
        {
            id:   9,
            name: 'Germany',
            code: 'DE',
        },
        {
            id:   10,
            name: 'Switzerland',
            code: 'CH',
        },
        {
            id:   11,
            name: 'Austria',
            code: 'AT',
        },
        {
            id:   12,
            name: 'Poland',
            code: 'PL',
        },
        {
            id:   13,
            name: 'United Kingdom',
            code: 'UK',
            slug: 'UK',
        },
        {
            id:   14,
            name: 'Australia',
            code: 'AU',
        },
        {
            id:   15,
            name: 'New Zealand',
            code: 'NZ',
        },
        {
            id:   16,
            name: 'France',
            code: 'FR',
        },
        {
            id:   17,
            name: 'Colombia',
            code: 'CO',
        },
        {
            id:   18,
            name: 'Honduras',
            code: 'HO',
        },
        {
            id:   19,
            name: 'Nicaragua',
            code: 'NI',
        },
        {
            id:   20,
            name: 'Saint Vincent and the Grenadines',
            code: 'VC',
        },
        {
            id:   21,
            name: 'Guatemala',
            code: 'GT',
        },
        {
            id:   22,
            name: 'Denmark',
            code: 'DK',
        },
        {
            id:   23,
            name: 'Finland',
            code: 'FI',
        },
        {
            id:   24,
            name: 'Norway',
            code: 'NO',
        },
        {
            id:   25,
            name: 'Sweden',
            code: 'SE',
        },
        {
            id:   26,
            name: 'Bulgaria',
            code: 'BG',
        },
        {
            id:   27,
            name: 'Panamá',
            code: 'PA',
            slug: 'Panama',
        },
        {
            id:   28,
            name: 'Ecuador',
            code: 'EC',
        },
        {
            id:   29,
            name: 'El Salvador',
            code: 'SV',
        },
        {
            id:   30,
            name: 'Belgium',
            code: 'Belgium',
        },
        {
            id:   31,
            name: 'Luxembourg',
            code: 'Luxembourg',
        },
        {
            id:   32,
            name: 'Netherlands',
            code: 'Netherlands',
        },
        {
            id:   33,
            name: 'Italy',
            code: 'Italy',
        },
        {
            id:   34,
            name: 'Philippines',
            code: 'PHL',
        },
        {
            id:   35,
            name: 'India',
            code: 'IND',
        },
        {
            id:   36,
            name: 'Indonesia',
            code: 'IDN',
        },
        {
            id:   37,
            name: 'Singapore',
            code: 'SGP',
        },
    ];
    return countries;
}

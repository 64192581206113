import axios from '@/axios';

export default {
    async getStates({ commit }) {
        const states = await axios
            .get('/api/states')
            .then(response => response.data.items)
            .catch(err => {
                console.error('Error when getting states', err);
            });
        commit('setStates', states);
    },
    async getTimeZones({ commit }) {
        const timeZones = await axios
            .get('/api/timezones')
            .then(response => response.data.items)
            .catch(err => {
                console.error('Error when getting timezone list', err);
            });
        commit('setTimeZones', timeZones);
    },
    async getLanguages({ commit }) {
        axios({
            method:  'get',
            url:     window.Lotvantage.curatorEndpoint + '/languages',
            headers: {
                'Token':        window.Lotvantage.curatorToken,
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            const languages = response.data.languages;
            commit('setLanguages', languages);
        }).catch((error) => {
            console.error('Error when getting languages', error);
        });
    },
    async saveCottonText({ commit, state}, payload) {
        const settings = await axios
            .post(`/reseller/settings/${state.reseller.id}/store`, payload)
            .then(response => response.data.items)
            .catch(err => {
                console.error('Error when getting states', err);
            });
        if (payload.productsCottonText) {
            commit('setProductCottonText', settings.productsCottonText);
        } else if (payload.retailerGroupingCottonText) {
            commit('setRetailerGroupingCottonText', settings.retailerGroupingCottonText);
        }
    },
};

export default {
    data() {
        return {
            screenSizeX: window.innerWidth,
            screenSizeY: window.innerHeight,
        };
    },
    mounted() {
        window.addEventListener('resize', this.$resizeEventHandler);
    },
    unmounted() {
        window.removeEventListener('resize', this.$resizeEventHandler);
    },
    methods: {
        $resizeEventHandler(event) {
            this.screenSizeX = event.target.innerWidth;
            this.screenSizeY = event.target.innerHeight;
        },
    },
}
<template>
    <export-modal
        :use-sidebar="currentStep !== 3"
        :sidebar-filters="sidebarFilters"
        :body-filters="bodyFilters"
        :current-step="currentStep"
        :block-modal="currentStep === 1 || exporting"
        :previous-button-title="currentStep === 3 ? 'Back to selection' : ''"
        :next-step-button-disabled="nextStepButtonDisabled"
        :show-preview="showPreview"
        :next-step-button-title="currentStep === 3 ? 'Export' : 'Next'"
        :is-loading="isLoading"
        :preview-data="previewData"
        :selected-filters="selectedFilters"
        :exporting="exporting"
        :title="showPreview ? 'Export Preview' : 'Export Creator'"
        :show-arrow-pointer="true"
        :brands-selected="selectedBrands"
        :oem-manager-options="oemManagerOptions"
        export-type="Brand"
        @close="close"
        @next-step="handleNextStep()"
        @previous-step="handlePreviusStep()"
        @update:selected-filter="updateSelectedFilters($event)"
        @handle-select-deselect-all="handleSelectDeselectAll($event)"
    >
        <template #starter>
            <brand-manager-filter-dropdown
                v-if="!isLoading && hasRoleAccess"
                :select-options="oemManagerOptions"
                :selected-brands="selectedBrands"
                :combo-box="selectedBrands.length > 1 || selectedBrands.length === oemManagerOptions.length ? 'Brands' : 'Brand'"
                :label="selectedBrands.length > 1 ? 'Brands' : 'Brand'"
                @selected-brands="handleSelectedBrands($event)"
            />
        </template>
        <template #body />
    </export-modal>
</template>

<script>
import _ from 'lodash';
import axios from '@/axios';
import { mapStores } from 'pinia';
import { userStore } from '@/stores/pinia.js';
import ExportModal from '@/components/app/exports/ExportModal.vue';
import BrandManagerFilterDropdown from '@/components/app/BrandManagerFilterDropdown.vue';

export default {
    components: {
        ExportModal,
        BrandManagerFilterDropdown,
    },
    props: {
        oemManagerOptions: {
            type:    Array,
            default: () => [],
        },
        filters: {
            type:    Object,
            default: () => {},
        },
        brands: {
            type:     Array,
            required: true,
            default:  () => [],
        },
        oemManagerId: {
            type:     String,
            required: true,
        },
        userRole: {
            type:     String,
            required: false,
            default:  '',
        },
        sidebarCall: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    data() {
        return {
            isLoading:       true,
            sidebarFilters:  {},
            bodyFilters:     [],
            selectedBrands:  this.brands,
            showPreview:     false,
            selectedFilters: {},
            previewData:     [],
            queryString:     '',
            exporting:       false,
            connect:         0,
        };
    },
    computed: {
        ...mapStores(userStore),
        currentStep() {
            if (this.showPreview) {
                return 3;
            }
            if (this.selectedBrands.length) {
                return 2;
            }
            return 1;
        },
        headerInfo() {
            return `${this.selectedBrands.length} ${this.selectedBrands.length === 1 ? 'Brand' : 'Brands'}`;
        },
        nextStepButtonDisabled() {
            return this.currentStep === 1 || this.loadingHeaders;
        },
        hasRoleAccess() {
            return (this.userStore.isAdmin || this.userStore.isBrand || this.userStore.isParentBrand) ?? false;
        },
    },
    mounted() {
        this.isLoading = true;
        this.setSidebarFilters();
        this.setBodyFilters();
        this.setInitialSelectedFilters();
        this.setPreviewData();
        this.getConnectCount();
        if (this.userStore.isAdmin) {
            this.validateSelectedBrands();
        }
        this.isLoading = false;
    },
    methods: {
        close() {
            this.$emit('close');
        },
        validateSelectedBrands() {
            if (this.selectedBrands.length > 1) {
                this.sidebarFilters.brand_info.options.connect.disabledCheck = true;
                const clearValue = this.selectedFilters.brand_info.filters.filter(object => {
                    return object.id != 'connect';
                });
                this.selectedFilters.brand_info.filters = clearValue;
            } else {
                this.sidebarFilters.brand_info.options.connect.disabledCheck = false;
                this.sidebarFilters.brand_info.options.connect.disabled = false;
            }
        },
        setSidebarFilters() {
            if (this.userRole == 'brand') {
                this.sidebarFilters = {
                    brand_info: this.filters.business_info,
                };
            } else {
                this.sidebarFilters = {
                    brand_info: this.filters.brand_info,
                    users_info: this.filters.users_info,
                };
            }
        },
        handleSelectedBrands(event) {
            if (event.value === 'all') {
                if (this.selectedBrands.length === this.oemManagerOptions.length) {
                    this.selectedBrands = [];
                } else {
                    this.selectedBrands = this.oemManagerOptions.map(option => option.id);
                }
            } else {
                this.selectedBrands = event.value;
            }
            if (this.userStore.isAdmin) {
                this.validateSelectedBrands();
            }
        },
        getConnectCount() {
            if (this.selectedBrands.length == 1) {
                this.loadingHeaders = true;
                axios.get('/api/brands', {
                    params: {
                        oem_manager_ids: this.selectedBrands.length > 0 ? this.selectedBrands : [...this.oemManagerId],
                    },
                }).then((response) => {
                    this.connect  = response.data.connects;
                    this.loadingHeaders = false;
                });
            }
        },
        setBodyFilters() {
            if (this.userRole == 'brand') {
                this.bodyFilters = [
                    {
                        type: 'row',
                        data: {
                            global_coverage: {
                                options: {
                                    global_coverage: this.filters.global_coverage.options,
                                },
                                title: '',
                            },
                            impressions_mtd: {
                                options: {
                                    impressions_mtd: this.filters.impressions_mtd.options,
                                },
                                title: '',
                            },
                        },
                        bold: true,
                    },
                    {
                        type: 'column',
                        data: {
                            platforms: this.filters.platforms,
                        },
                        space: true,
                    },
                ];
            } else {
                this.bodyFilters = [
                    {
                        type: 'row',
                        data: {
                            global_reach: this.filters.global_reach,
                        },
                        bold: true,
                    },
                    {
                        type: 'column',
                        data: {
                            platforms: this.filters.platforms,
                        },
                        space: true,
                    },
                ];
            }
        },
        setInitialSelectedFilters() {
            for (const [key, value] of Object.entries(this.filters)) {
                if (key === 'platforms') {
                    this.selectedFilters.platforms = {filters: {}};
                    value.options.forEach(option => {
                        this.selectedFilters.platforms.filters[option.name.toLowerCase()] = {filters: [], title: option.name};
                    });
                } else {
                    this.selectedFilters[key] = {title: value.title ? value.title : value.options.title, filters: []};
                }
                for (const [key1, value1] of Object.entries(this.filters[key].options)) {
                    if (value1.disabled) {
                        this.selectedFilters[key].filters.push({title: value1.title, id: key1});
                    }
                }
            }
        },
        updateSelectedFilters(filterInfo) {
            if (filterInfo.filterType === 'column') {
                if (!this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()]) {
                    this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()] = {title: filterInfo.data.type.title, filters: []};
                }
                const filterIsSelected = this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.findIndex(f => f.id === filterInfo.data.filter.id);
                if (filterIsSelected >= 0) {
                    this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.splice(filterIsSelected, 1);
                } else {
                    this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.push(filterInfo.data.filter);
                }
            } else {
                const topFilters = ['contracted_active_linked_retailers', 'global_coverage', 'impressions_mtd'];
                const filterIsSelected = this.selectedFilters[filterInfo.data.type.id].filters.findIndex(f => f.id === filterInfo.data.filter.id);
                if (filterIsSelected >= 0) {
                    this.selectedFilters[filterInfo.data.type.id].filters.splice(filterIsSelected, 1);
                } else {
                    if (topFilters.find(f => f === filterInfo.data.type.id)) {
                        this.selectedFilters[filterInfo.data.type.id].filters.push({id: filterInfo.data.type.id, title: 'Include'});
                    } else {
                        this.selectedFilters[filterInfo.data.type.id].filters.push(filterInfo.data.filter);
                    }
                }
            }
            this.setPreviewData();
        },
        setPreviewData() {
            let data = [{}, {}];
            for (const key of Object.keys(this.selectedFilters)) {
                if (key === 'platforms') {
                    for (const k of Object.keys(this.selectedFilters[key].filters)) {
                        data[1][k] = (this.selectedFilters[key].filters[k]);
                    }
                } else {
                    data[0][key] = (this.selectedFilters[key]);
                }
            }
            this.previewData = data;
        },
        handleNextStep() {
            let query = `/api/reports/show?report=brand&oem_manager_id=${this.selectedBrands.join() || this.oemManagerId}&columns=`;
            let allUsersInfo = '';
            let usersInfo;
            if (!this.showPreview) {
                let connect = this.userStore.isAdmin ? this.selectedFilters.brand_info.filters.find(i => i.id === 'connect') : null;
                if (connect) {
                    connect.title =  'Enrollment Page';
                }
                this.showPreview = true;
                this.$emit('next-view');
            } else {
                if (this.exporting === true) {
                    return;
                }
                this.exporting = true;
                for (const  [key, value, i] of Object.entries(this.selectedFilters)) {
                    if (key === 'platforms') {
                        for (const [key1, value1] of Object.entries(value.filters)) {
                            value1.filters.forEach(f => {
                                if (f.map.endsWith('_linked_active')) {
                                    let pname = f.map.slice(0, -14);
                                    query += `${pname + '_active,' + pname +  '_linked'},`;
                                } else {
                                    query += `${f.map},`;
                                }
                            });
                        }
                    } else {
                        if (key ==='users_info') {
                            usersInfo = value.filters.length;
                            if (value.filters.length) {
                                query +='all_users,';
                            }
                            value.filters.forEach(f => {
                                allUsersInfo += `${f.map},`;
                            });
                        } else {
                            value.filters.forEach(f => {
                                query += `${f.id},`;
                            });
                        }
                    }
                }
                query = query.slice(0, query.length-1);
                if (usersInfo) {
                    query += `&all_users_columns=${allUsersInfo.slice(0, query.length-1)}`;
                    query = query.slice(0, query.length - 1);
                }
                this.queryString = query + `&sort_field=name&sort_direction=A&sidebar=${this.sidebarCall}`;
                axios({
                    url:          this.queryString,
                    method:       'GET',
                    responseType: 'blob',
                }).then((response) => {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'brands_export.csv');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    this.close();
                    this.$emit('next-view');
                });
            }
        },
        handleSelectDeselectAll(event) {
            this.setInitialSelectedFilters();
            const avoid = ['brand_name', 'program_name'];
            if (event.value === 'select') {
                for (const [key, value] of Object.entries(this.selectedFilters)) {
                    const topFilters = ['contracted_active_linked_retailers', 'global_coverage', 'impressions_mtd'];
                    if (topFilters.find(filter => filter === key)) {
                        this.selectedFilters[key].filters.push({ id: key, title: 'Include' });
                    } else if (key === 'platforms') {
                        this.filters[key].options.forEach((platform) => {
                            for (const [key1, value1] of Object.entries(platform.options)) {
                                for (const [key2, value2] of Object.entries(value1)) {
                                    this.selectedFilters[key].filters[platform.name.toLowerCase()].filters.push({ id: key2, title: value2.title, map: value2.map ? value2.map : '' });
                                }
                            }
                        });
                    } else {
                        for (const [key1, value1] of Object.entries(this.filters[key].options)) {
                            if (!avoid.find(key => key === key1)) {
                                if (key1 == 'connect' && this.sidebarFilters.brand_info.options.connect.disabledCheck) {
                                    const clearValue = this.selectedFilters.brand_info.filters.filter(object => {
                                        return object.id != 'connect';
                                    });
                                    this.selectedFilters[key].filters = clearValue;
                                } else {
                                    this.selectedFilters[key].filters.push({ id: key1, title: value1.title, map: value1.map ? value1.map : '' });
                                }
                            }
                        }
                    }
                }
            }
            this.selectedFilters = _.cloneDeep(this.selectedFilters);
            this.setPreviewData();
        },
        handlePreviusStep() {
            this.showPreview = false;
            this.exporting = false;
            this.$emit('previous-view');
        },
    },
};
</script>

<style>
.horizontal-divider-brand {
    border-bottom: 2px solid #F0F3F6;
    margin: 1em 0 2em 0;
}
</style>

<template>
    <div class="horizontal-list nav-bar-horizontal-list">
        <ul role="tablist">
            <template v-for="menuItem in getMenu" :key="menuItem.name">
                <template v-if="!menuItem.actions.length">
                    <li
                        :class="{'error-page-tab': ['General Error', 'Page Not Found'].includes(menuItem.name)}"
                        role="presentation"
                    >
                        <a
                            :href="menuItem.href"
                            :class="{active: getCurrent && menuItem.name == getCurrent.action.name, disabled: !menuItem.conditions() || !menuItem.hasAccess}"
                            @click="handleClick($event, menuItem)"
                        >
                            {{ menuItem.text }}
                        </a>
                    </li>
                </template>
                <template v-else>
                    <li
                        :class="{'error-page-tab': ['General Error', 'Page Not Found'].includes(menuItem.name) }"
                        role="presentation" class="dropdown"
                    >
                        <a
                            data-toggle="dropdown"
                            :class="{active: getCurrent && menuItem.actions.some(action => action.name == getCurrent.action.name),
                                     disabled: disabledParentTab(menuItem)}"
                            :aria-controls="menuItem.name"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            class="dropdown-toggle nav-bar-toggle"
                        >
                            <img v-if="menuItem.image != null" style="width:17px;margin-top:-4px" :src="menuItem.image">
                            {{ menuItem.text }}
                            <span class="caret nav-bar-caret" />
                        </a>
                        <ul class="dropdown-menu">
                            <template v-for="action in menuItem.actions" :key="action.name">
                                <li v-if="action.divider" class="divider" />
                                <li v-if="action.group" class="action-group">
                                    <span>
                                        <img v-if="action.groupImage != null" :src="action.groupImage">
                                        {{ action.group }}
                                    </span>
                                </li>
                                <li :class="{hidden: action.hidden, disabled: !action.hasAccess}">
                                    <a
                                        :href="action.href"
                                        class="nav-bar-item"
                                        :class="{active: getCurrent && action.name == getCurrent.action.name, disabled: action.disabled}"
                                        data-toggle="tooltip"
                                        :title="action.tooltip"
                                        @click="handleClick($event, action)"
                                    >
                                        <i v-if="action.name === 'Add Content'" class="fa fa-plus" style="color: rgb(0, 102, 255);" />
                                        {{ action.text }}
                                    </a>
                                </li>
                            </template>
                        </ul>
                    </li>
                </template>
            </template>
        </ul>
        <multi-select-keep-existing-popup-modal
            v-if="showMultiSelectMenuChangeConfirmation"
            class="keep-existing-multi-select"
            @close="closeMultiSelect"
            @on-yes-button-click="confirmChangeMenu"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import TYPES from '@/stores/modules/StoryStore/types';
import MultiSelectKeepExistingPopupModal from '../content_modal/popup_modals/MultiSelectKeepExistingPopupModal.vue';
import { mapStores } from 'pinia';
import { userStore } from '@/stores/pinia';

export default {
    components: {
        MultiSelectKeepExistingPopupModal,
    },
    props: {
        dealershipId: {
            type:    Number,
            default: null,
        },
    },
    data() {
        return {
            showMultiSelectMenuChangeConfirmation: false,
            selectedAction:                        '',
            timer:                                 null,
        };
    },
    computed: {
        ...mapGetters('NavigationStore', [
            'getCurrent',
            'getNavigationMenu',
            'findAction',
        ]),
        ...mapState('MultiSelectStoryStore', {
            canMultiSelect:    state => state.canMultiSelect,
            multiSelectActive: state => state.multiSelectActive,
        }),
        ...mapStores(userStore),
        getMenu() {
            this.getNavigationMenu.forEach(menu => {
                if (menu.actions.length > 0) {
                    menu.actions.map(action => {
                        action.hasAccess = this.getPermission(action);
                    });
                } else {
                    menu.hasAccess = this.getPermission(menu);
                }
            });
            return this.getNavigationMenu;
        },
    },
    mounted() {
        if (window.location.pathname.lastIndexOf('randomized') > -1) {
            this.timer = setInterval(() => {
                // I am doing this in this way because the data that is needed in the 'NavigatorStore'
                // takes time to load and is needed to know in which position of the 'Navbar' we are in order
                // to select the 'Add Content' button that uses the getCurrent information that is in the 'NavigationStore'
                try {
                    this.callAction({name: 'Randomized'});
                    clearInterval(this.timer);
                } catch (error) {
                    //
                }
            }, 100);
        }
    },
    methods: {
        ...mapActions('NavigationStore', [
            'callAction',
        ]),
        ...mapMutations('StoryStore', [
            TYPES.MUTATIONS.PARTIAL_UPDATE,
            TYPES.MUTATIONS.PARTIAL_STORY_UPDATE,
        ]),
        ...mapMutations('MultiSelectStoryStore', [
            'clearMultiSelect',
        ]),
        checkAndCall(action) {
            if (action.call) {
                this.callAction({name: action.name});
            }
        },
        getPermission(menu) {
            if (!menu.access) {
                return true;
            }

            if (Array.isArray(menu.access)) {
                return menu.access.some(access => this.userStore.hasReadAccess({ permission: access }));
            }

            return this.userStore.hasReadAccess({ permission: menu.access });
        },
        openContentModal() {
            const {action} = this.getCurrent;

            if (action.name === 'Randomized') {
                this[TYPES.MUTATIONS.PARTIAL_UPDATE]({ selectedStoryId: 'new' });
            } else if (action.name === 'Scheduled' || action.name === 'Calendar') {
                const date = new Date();
                let year = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date);
                let month = new Intl.DateTimeFormat('en-US', { month: '2-digit' }).format(date);
                let day = new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(date);
                const scheduled_at = `${year}-${month}-${day}`;
                this[TYPES.MUTATIONS.PARTIAL_STORY_UPDATE]({ scheduled_at });
                this[TYPES.MUTATIONS.PARTIAL_UPDATE]({ selectedStoryId: 'new' });
            } else {
                const randomizedAction = this.findAction({name: 'Randomized'});
                window.location.href = randomizedAction.handleLink + '?selectedStoryId=new';
            }
        },
        handleClick(event, action) {
            if (action.call) {
                event.preventDefault();
            }
            if (this.canMultiSelect || this.multiSelectActive) {
                this.showMultiSelectMenuChangeConfirmation = true;
                this.selectedAction = action;
            } else {
                return action.name === 'Add Content'
                    ? this.openContentModal()
                    : this.checkAndCall(action);
            }
        },
        confirmChangeMenu() {
            this.checkAndCall(this.selectedAction);
            this.clearMultiSelect();
        },
        closeMultiSelect() {
            this.showMultiSelectMenuChangeConfirmation = false;
        },
        disabledParentTab(menu) {
            return menu.actions && menu.actions.every(action => {
                return (action.disabled && !action.access) || action.hasAccess === false ;
            })
        },
    },
};
</script>

<style lang="scss">
.nav-bar-horizontal-list {
    margin-left: 23px;
}
.horizontal-list {
    display: inline-block;

    li {
        display: inline-block;
        .hidden {
            display: none;
        }
        a {
            color: black;
            padding: 8px 16px;
            text-transform: capitalize;

            &.active {
                color: #0066FF;
                border-bottom: 2px solid #0066FF;
                text-decoration: none;
                z-index : 1;
            }

            &:hover {
                text-decoration: none;
            }

            &.disabled {
                color: #808080
            }
        }

        .dropdown-menu {
            padding: 12px 0px;
            li {
                width: 100%;

                .active {
                    color: #0066FF;
                    border: none;

                }
            }
            .nav-bar-item {
                margin: 0 5px;
                font-size: 16px;
                border-radius: 5px;

                &:hover {
                    background-color: #eee;
                }
            }
            img {
                height: 10px;
                margin-bottom: 2px;
            }
        }
    }
    .action-group {
        padding-top: 3px;
        span {
            color: #848484;
            font-size: 12px;
            padding: 8px 20px;
        }
    }
    .nav-bar-toggle {
        font-size: 14px;
        .nav-bar-caret {
            color: #c4c4c4;
        }
    }
    .keep-existing-multi-select {
        .footer {
           .btn-no {
                border-radius: 4px;
           }
           .btn-yes {
                border-radius: 4px;
           }
        }
    }
}
</style>

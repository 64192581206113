export default {
    name: 'Instagram',
    char() {
        return {
            limit:                2200,
            link:                 false,
            emoji:                true,
            hashtag:              true,
            mention:              false,
            brandRequiresDynamic: false,
        };
    },
    videoChar() {
        return {
            limit: 2200,
        };
    },
    media(vm = null) {
        return {
            photo: {
                resolution: {
                    min: {
                        width:  400,
                        height: 400,
                    },
                },
                aspectRatio: {
                    min: 4/5,
                    max: 1.91/1,
                },
                size:    8 * 1024 * 1024, // 8 mb
                formats: [
                    'jpeg',
                    'png',
                    'jpg',
                ],
                limit:    10,
                min:      (vm && vm.video && vm.video.length ? 0 : (vm && vm.postType==='TEXT' ? 0 : 1)),
                messages: {
                    limit: 'Instagram only allows for ten photos per post.',
                    min:   'Instagram should have at least 1 photo per post.',
                },
            },
            video: {
                resolution: {
                    max: {
                        width: 1920,
                    },
                },
                aspectRatio: {
                    min: 0.01/1,
                    max: 10/1,
                },
                size:      1 * 1024 * 1024 * 1024, // 1gb
                bitRate:   25 * 1024 * 1024, // 25mb
                framerate: {
                    min: 23,
                    max: 60,
                },
                duration: {
                    min: 3, // 3 sec
                    max: 15 * 60, // 15 min
                },
                formats: [
                    'mp4',
                    'mov',
                ],
                codecs: [
                    'h264',
                    'aac',
                ],
                limit:    1,
                min:      vm && vm.postType === 'VIDEO' ? 1 : 0,
                messages: {
                    limit: 'Instagram automation is limited to one video per post.',
                    min:   'Instagram should have at least 1 video per post.',
                },
            },
            mixed: {
                enabled: false,
                min:     1,
            },
        };
    },
    misc: {},
};

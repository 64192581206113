export default () => ({
    apiKey: '',
    config: {
        height:                       800,
        width:                        '100%',
        placeholder:                  'Type here...',
        menubar:                      false,
        statusbar:                    false,
        link_assume_external_targets: true,
        plugins:                      'lists link',
        toolbar:                      false,
        toolbar_location:             'bottom',
        link_default_target:          '_blank',
    },
    buttons: [],
    icons:   [
        {
            name:    'bold',
            content: '<svg width=\'8\' height=\'9\' viewBox=\'0 0 8 9\'><path opacity=\'0.4\' d=\'M6.74463 4.22672C7.09224 3.77874 7.30995 3.20621 7.30995 2.5714C7.30995 1.15333 6.25945 3.05176e-05 4.96819 3.05176e-05L1.45556 0.000231372C1.13228 0.000231372 0.870117 0.287703 0.870117 0.643074C0.870117 0.998446 1.13228 1.28592 1.45556 1.28592H1.74828V7.71434H1.45556C1.13228 7.71434 0.870117 8.00182 0.870117 8.35719C0.870117 8.71256 1.13228 9.00003 1.45556 9.00003H5.55363C6.84489 9.00003 7.89539 7.84673 7.89539 6.42866C7.89539 5.49031 7.4307 4.67671 6.74463 4.22672ZM2.91915 1.28592H4.96819C5.614 1.28592 6.13907 1.86287 6.13907 2.5716C6.13907 3.28034 5.614 3.85729 4.96819 3.85729H2.91915V1.28592ZM5.55363 7.71414H2.91915V5.14277H5.55363C6.19944 5.14277 6.72451 5.71952 6.72451 6.41038C6.72451 7.10123 6.19944 7.71414 5.55363 7.71414Z\' fill=\'#ADB0B3\'/></svg>',
        },
        {
            name:    'italic',
            content: '<svg width=\'8\' height=\'9\' viewBox=\'0 0 8 9\'><path opacity=\'0.4\' d=\'M7.95301 0.642888C7.95301 0.998267 7.6912 1.28574 7.36757 1.28574H6.2942L3.85548 7.71432H5.02581C5.34945 7.71432 5.61125 8.00179 5.61125 8.35717C5.61125 8.71255 5.34945 9.00003 5.02581 9.00003H1.51317C1.18954 9.00003 0.927734 8.71255 0.927734 8.35717C0.927734 8.00179 1.18954 7.71432 1.51317 7.71432H2.58654L5.02526 1.28574H3.85493C3.53129 1.28574 3.26949 0.998267 3.26949 0.642888C3.26949 0.287508 3.53129 3.05176e-05 3.85493 3.05176e-05H7.36757C7.69139 3.05176e-05 7.95301 0.287709 7.95301 0.642888Z\' fill=\'#ADB0B3\'/></svg>',
        },
        {
            name:    'underline',
            content: '<svg width=\'9\' height=\'9\' viewBox=\'0 0 9 9\'><path d=\'M8.01087 8.43753C8.01087 8.74849 7.78647 9.00003 7.50906 9.00003H1.4874C1.21 9.00003 0.985596 8.74849 0.985596 8.43753C0.985596 8.12657 1.21 7.87503 1.4874 7.87503H7.50906C7.78662 7.87503 8.01087 8.1264 8.01087 8.43753Z\' fill=\'#ADB0B3\'/><path opacity=\'0.4\' d=\'M1.73813 1.12521H1.98904V3.93771C1.98904 5.48845 3.11465 6.73439 4.49806 6.73439C5.88147 6.73439 7.00709 5.47263 7.00709 3.93771V1.12521H7.25799C7.53539 1.12521 7.75979 0.873488 7.75979 0.562706C7.75979 0.251925 7.53539 0.0160266 7.25799 0.0160266L5.75257 0.0159405C5.47517 0.0159405 5.25077 0.267659 5.25077 0.57844C5.25077 0.889222 5.47517 1.14094 5.75257 1.14094H6.00348V3.95344C6.00348 4.88403 5.32823 5.62512 4.49806 5.62512C3.66789 5.62512 2.99265 4.85335 2.99265 3.93753V1.12503H3.24355C3.52095 1.12503 3.74535 0.873488 3.74535 0.562531C3.74535 0.251573 3.52095 3.05176e-05 3.24355 3.05176e-05L1.73813 0.00011665C1.46073 0.00011665 1.23633 0.25166 1.23633 0.546796C1.23633 0.841933 1.46073 1.12521 1.73813 1.12521Z\' fill=\'#ADB0B3\'/></svg>',
        },
        {
            name:    'strike-through',
            content: '<svg width=\'12\' height=\'9\' viewBox=\'0 0 12 9\'><path d=\'M10.5656 4.17633H0.560821C0.275126 4.17633 0.043335 4.39228 0.043335 4.64036C0.043335 4.90653 0.275126 5.1044 0.560821 5.1044H10.5656C10.8513 5.1044 11.083 4.88845 11.083 4.64036C11.083 4.39328 10.8523 4.17633 10.5656 4.17633Z\' fill=\'#0066FF\'/><path opacity=\'0.4\' d=\'M7.20648 5.78333C7.57777 6.0278 7.68795 6.31586 7.60774 6.75177C7.54239 7.10512 7.37315 7.34116 7.07473 7.49483C6.31058 7.89157 4.73526 7.73509 3.06421 7.0995C2.71189 6.96519 2.3089 7.12272 2.16465 7.45164C2.0204 7.78056 2.19027 8.15573 2.54263 8.28952C3.22722 8.54925 4.60589 9.00003 5.94079 9.00003C6.57946 9.00003 7.20885 8.89646 7.74596 8.61916C8.41028 8.27586 8.83354 7.70596 8.96895 6.96973C9.05515 6.50228 9.0157 6.11438 8.89374 5.78353H7.20648V5.78333ZM6.76014 4.17629C6.41839 4.06353 6.07383 3.96858 5.7532 3.8826C3.9103 3.38803 3.36198 3.09816 3.51723 2.24884C3.57113 1.95696 3.7363 1.72735 4.00712 1.56524C4.44849 1.30108 5.38837 1.08634 7.35784 1.57215C7.72634 1.66317 8.10367 1.45857 8.20156 1.11534C8.29926 0.772039 8.07961 0.420498 7.71103 0.329499C5.74221 -0.15623 4.24602 -0.104805 3.2628 0.483253C2.66985 0.838052 2.27742 1.38806 2.15861 2.03088C1.96822 3.06341 2.37013 3.7223 3.0019 4.17629H6.76014Z\' fill=\'#0066FF\'/></svg>',
        },
        {
            name:    'unordered-list',
            content: '<svg width=\'11\' height=\'9\' viewBox=\'0 0 11 9\'><path d=\'M1.68362 3.05176e-05H0.429107C0.255749 3.05176e-05 0.115479 0.154934 0.115479 0.346184V1.7308C0.115479 1.92214 0.255749 2.07695 0.429107 2.07695H1.68362C1.8569 2.07695 1.99725 1.92118 1.99725 1.7308V0.346184C1.99725 0.154934 1.8569 3.05176e-05 1.68362 3.05176e-05ZM1.68362 3.46157H0.429107C0.255749 3.46157 0.115479 3.61734 0.115479 3.80772V5.19234C0.115479 5.38368 0.255749 5.53849 0.429107 5.53849H1.68362C1.8569 5.53849 1.99725 5.38272 1.99725 5.19234V3.80772C1.99725 3.61734 1.8569 3.46157 1.68362 3.46157ZM1.68362 6.92311H0.429107C0.255749 6.92311 0.115479 7.07888 0.115479 7.26926V8.65388C0.115479 8.84521 0.255749 9.00003 0.429107 9.00003H1.68362C1.8569 9.00003 1.99725 8.84426 1.99725 8.65388V7.26926C1.99725 7.07888 1.8569 6.92311 1.68362 6.92311Z\' fill=\'#0066FF\'/><path opacity=\'0.4\' d=\'M3.87897 1.73081H9.52427C9.87064 1.73081 10.1515 1.42078 10.1515 1.05797C10.1515 0.695158 9.87122 0.346191 9.52427 0.346191H3.87897C3.53201 0.346191 3.25171 0.656215 3.25171 1.01903C3.25171 1.38184 3.53201 1.73081 3.87897 1.73081ZM9.52427 7.26927H3.87897C3.5326 7.26927 3.25171 7.57929 3.25171 7.96158C3.25171 8.34386 3.5326 8.65388 3.87897 8.65388H9.52427C9.87064 8.65388 10.1515 8.34386 10.1515 7.96158C10.1515 7.57929 9.87122 7.26927 9.52427 7.26927ZM9.52427 3.80773H3.87897C3.53201 3.80773 3.25171 4.1171 3.25171 4.50004C3.25171 4.88297 3.5326 5.19235 3.87897 5.19235H9.52427C9.87064 5.19235 10.1515 4.88232 10.1515 4.50004C10.1515 4.11775 9.87122 3.80773 9.52427 3.80773Z\' fill=\'#0066FF\'/></svg>',
        },
        {
            name:    'ordered-list',
            content: '<svg width=\'12\' height=\'9\' viewBox=\'0 0 12 9\'><path d=\'M1.04683 3.85716H2.77171C3.05847 3.85716 3.28918 3.6422 3.28918 3.35694C3.28918 3.07167 3.05739 2.87479 2.77171 2.87479H2.42673V0.481951C2.42673 0.309304 2.32768 0.149875 2.16671 0.0638922C2.00629 -0.0218893 1.8075 -0.0210857 1.64859 0.0652984L0.958641 0.440366C0.711983 0.574764 0.628326 0.869876 0.772569 1.0999C0.898701 1.3028 1.16132 1.38818 1.3918 1.31164V2.89287H1.04683C0.761142 2.89287 0.52936 3.10883 0.52936 3.37502C0.52936 3.6412 0.761142 3.85716 1.04683 3.85716ZM3.12316 8.03574H2.05718L2.842 7.38324C3.48711 6.86433 3.56516 5.94967 3.01554 5.34417C2.74152 5.02435 2.35558 4.85761 1.92846 4.82547C1.50543 4.79533 1.08844 4.91989 0.764807 5.18105L0.495294 5.39801C0.278821 5.57191 0.254457 5.87634 0.440809 6.07824C0.627787 6.28034 0.953962 6.30203 1.16914 6.12878L1.43887 5.91202C1.55185 5.82099 1.69976 5.77798 1.84767 5.78835C1.997 5.79934 2.13227 5.86336 2.22757 5.96853C2.41522 6.17505 2.38898 6.47599 2.16166 6.6588L0.357001 8.15887C0.196932 8.29196 0.141779 8.5036 0.217782 8.69063C0.294755 8.87548 0.486454 9.00003 0.700986 9.00003H3.12445C3.4097 9.00003 3.64105 8.78407 3.64105 8.53597C3.64062 8.25271 3.40776 8.03574 3.12316 8.03574Z\' fill=\'#0066FF\'/><path opacity=\'0.4\' d=\'M10.5338 3.85718H5.01417C4.63254 3.85718 4.32422 4.14445 4.32422 4.50004C4.32422 4.85562 4.63319 5.14289 5.01417 5.14289H10.5338C10.9148 5.14289 11.2238 4.85501 11.2238 4.50004C11.2238 4.14506 10.9154 3.85718 10.5338 3.85718ZM5.01417 1.9286H10.5338C10.9148 1.9286 11.2238 1.64072 11.2238 1.28574C11.2238 0.930764 10.9148 0.642883 10.5338 0.642883H5.01417C4.63254 0.642883 4.32422 0.930764 4.32422 1.28574C4.32422 1.64072 4.63254 1.9286 5.01417 1.9286ZM10.5338 7.07147H5.01417C4.63319 7.07147 4.32422 7.35935 4.32422 7.71433C4.32422 8.06931 4.63319 8.35719 5.01417 8.35719H10.5338C10.9148 8.35719 11.2238 8.06931 11.2238 7.71433C11.2238 7.35935 10.9154 7.07147 10.5338 7.07147Z\' fill=\'#0066FF\'/></svg>',
        },
        {
            name:    'link',
            content: '<svg width=\'5\' height=\'9\' viewBox=\'0 0 5 9\'><path d=\'M2.95052e-07 6.75003L2.36041e-07 5.40003C2.25168e-07 5.15126 0.201516 4.95003 0.45 4.95003C0.698485 4.95003 0.9 5.15126 0.9 5.40003L0.9 6.75003C0.9 7.4945 1.50553 8.10003 2.25 8.10003C2.99447 8.10003 3.6 7.4945 3.6 6.75003L3.6 5.40003C3.6 5.15126 3.80152 4.95003 4.05 4.95003C4.29848 4.95003 4.5 5.15126 4.5 5.40003L4.5 6.75003C4.5 7.99062 3.49059 9.00003 2.25 9.00003C1.00941 9.00003 3.49268e-07 7.99034 2.95052e-07 6.75003ZM3.6 3.60003L3.6 2.25003C3.6 1.50556 2.99447 0.90003 2.25 0.90003C1.50553 0.90003 0.9 1.50556 0.9 2.25003L0.9 3.60003C0.9 3.8488 0.698485 4.05003 0.45 4.05003C0.201516 4.05003 1.68241e-07 3.84894 1.57361e-07 3.60003L9.83506e-08 2.25003C4.41226e-08 1.00944 1.00969 3.04734e-05 2.25 3.04192e-05C3.49031 3.0365e-05 4.5 1.00944 4.5 2.25003L4.5 3.60003C4.5 3.8488 4.29848 4.05003 4.05 4.05003C3.80152 4.05003 3.6 3.84894 3.6 3.60003Z\' fill=\'#0066FF\'/><path opacity=\'0.4\' d=\'M2.25009 2.73218C2.51632 2.73218 2.73223 2.92982 2.73223 3.17414L2.73223 5.82593C2.73223 6.07025 2.51632 6.26789 2.25009 6.26789C1.98385 6.26789 1.76794 6.07025 1.76794 5.82593L1.76794 3.17414C1.76794 2.92968 1.9834 2.73218 2.25009 2.73218Z\' fill=\'#0066FF\'/></svg>',
        },
        {
            name:    'remove',
            content: '<svg width=\'9\' height=\'12\' viewBox=\'0 0 9 12\'><path d=\'M8.67857 0.750118H6.42857L6.19594 0.20731C6.14152 0.0802798 6.0302 3.05176e-05 5.90846 3.05176e-05H3.09174C2.96998 3.05176e-05 2.85871 0.0802798 2.80426 0.20731L2.57143 0.750118H0.321429C0.14392 0.750118 0 0.917976 0 1.12511V1.87511C0 2.0822 0.14392 2.2501 0.321429 2.2501H8.67857C8.85612 2.2501 9 2.0822 9 1.87511V1.12511C9 0.917929 8.85536 0.750118 8.67857 0.750118Z\' fill=\'#0066FF\'/><path opacity=\'0.4\' d=\'M0.6427 2.25012V10.875C0.6427 11.4961 1.07462 12 1.60699 12H7.3927C7.92507 12 8.35699 11.4961 8.35699 10.875V2.25012H0.6427ZM2.8927 9.75006C2.8927 9.95806 2.74956 10.1251 2.57127 10.1251C2.39298 10.1251 2.24984 9.95865 2.24984 9.75006V4.5001C2.24984 4.2921 2.39298 4.12511 2.57127 4.12511C2.74956 4.12511 2.8927 4.29151 2.8927 4.5001V9.75006ZM4.82127 9.75006C4.82127 9.95806 4.67814 10.1251 4.49984 10.1251C4.32155 10.1251 4.17841 9.95865 4.17841 9.75006V4.5001C4.17841 4.2921 4.32155 4.12511 4.49984 4.12511C4.67814 4.12511 4.82127 4.2921 4.82127 4.5001V9.75006ZM6.74984 9.75006C6.74984 9.95806 6.60671 10.1251 6.42841 10.1251C6.25012 10.1251 6.10699 9.95806 6.10699 9.75006V4.5001C6.10699 4.2921 6.25012 4.12511 6.42841 4.12511C6.60671 4.12511 6.74984 4.2921 6.74984 4.5001V9.75006Z\' fill=\'#0066FF\'/></svg>',
        },

    ],
    generateSetup: function(options, events = {}) {
        const icons = [...this.icons, ...(options.extraIcons ?? [])];
        const buttons = [...this.buttons, ...(options.extraButtons ?? [])];
        return function(editor) {
            editor.on('init', options.init);
            Object.keys(events).forEach(event => {
                editor.on(event, events[event]);
            });
            for (let i = 0; i < icons.length; i++) {
                editor.ui.registry.addIcon(icons[i].name, icons[i].content);
            }
            for (let i = 0; i < buttons.length; i++) {
                editor.ui.registry.addButton(buttons[i].name, buttons[i].content);
            }
        };
    },
});
<template>
    <export-modal
        class="export-options-retailer-cc"
        :header-info="headerInfo"
        :subtitle-info="subtitleInfo"
        :sidebar-filters="sidebarFilters"
        :body-filters="bodyFilters"
        :current-step="currentStep"
        :previous-button-title="currentStep === 3 ? 'Back to selection' : ''"
        :next-step-button-disabled="nextStepButtonDisabled"
        :show-arrow-pointer="true"
        :show-preview="showPreview"
        :next-step-button-title="currentStep === 3 ? 'Export' : 'Next'"
        :is-loading="isLoading"
        :selected-filters="selectedFilters"
        :preview-data="previewData"
        :user-radio="userRadio"
        :exporting="exporting"
        :brands-selected="selectedBrands"
        :loading-headers="loadingHeaders"
        :title="showPreview ? 'Export Preview' : 'Export Creator'"
        export-type="Individual Retailer"
        @close="close"
        @next-step="handleNextStep()"
        @update:selected-filter="updateSelectedFilters($event)"
        @previous-step="handlePreviusStep()"
        @handle-select-deselect-all="handleSelectDeselectAll($event)"
    >
        <template #starter>
            <brand-manager-filter-dropdown
                v-if="!isLoading && hasRoleAccess"
                :select-options="oemManagerOptions"
                :selected-brands="selectedBrands"
                :combo-box="selectedBrands.length > 1 || selectedBrands.length === oemManagerOptions.length ? 'Brands' : 'Brand'"
                :label="selectedBrands.length > 1 ? 'Brands' : 'Brand'"
                @selected-brands="handleSelectedBrands($event)"
            />
            <div class="checkbox-input-container custom-tooltip-container-sidebar">
                <div class="retailer-status-container">
                    <label class="label-modal">
                        <ts-checkbox
                            :checked="active_retailer"
                            @update:checked="getRetailersCount('active')"
                        />
                        <span class="span-status">Active Retailers ({{ activeRetailerCount }})</span>
                    </label>
                    <label class="label-modal">
                        <ts-checkbox
                            :checked="inactive_retailer"
                            @update:checked="getRetailersCount('inactive')"
                        />
                        <span class="span-status">Inactive Retailers ({{ inactiveRetailerCount }})</span>
                    </label>
                </div>
            </div>
        </template>
        <template #body />
    </export-modal>
</template>
<script>
import axios from '@/axios';
import BrandManagerFilterDropdown from '@/components/app/BrandManagerFilterDropdown.vue';
import ToggleSwitch from '@/components/app/ToggleSwitch.vue';
import ExportModal from '@/components/app/exports/ExportModal.vue';
import { userStore } from '@/stores/pinia.js';
import _ from 'lodash';
import { mapStores } from 'pinia';
import TsCheckbox from '../TsCheckbox.vue';

export default {
    components: {
        TsCheckbox,
        BrandManagerFilterDropdown,
        ExportModal,
        ToggleSwitch,
    },
    props: {
        oemManagerOptions: {
            type:    Array,
            default: () => [],
        },
        filters: {
            type:    Object,
            default: () => {},
        },
        brands: {
            type:     Array,
            required: true,
            default:  () => [],
        },
        userRole: {
            type:     String,
            required: false,
            default:  '',
        },
        oemManagerId: {
            type:     String,
            default:  '',
            required: true,
        },
        sidebarCall: {
            type:     Boolean,
            required: false,
            default:  false,
        },
    },
    data() {
        return {
            isLoading:              true,
            sidebarFilters:         {},
            bodyFilters:            [],
            selectedBrands:         this.brands,
            showPreview:            false,
            headerInfo:             '0 Retailers',
            selectedFilters:        {},
            previewData:            [[], []],
            queryString:            '',
            userRadio:              'Default User',
            exporting:              false,
            loadingHeaders:         false,
            all_tiers_column_count: 0,
            top_tiers_column_count: 0,
            active_retailer:        true,
            activeRetailerCount:    '0 Retailers',
            inactive_retailer:      true,
            inactiveRetailerCount:  '0 Retailers',
        };
    },
    computed: {
        ...mapStores(userStore),
        currentStep() {
            if (this.showPreview) {
                return 3;
            }
            if (this.selectedBrands.length || this.userRole === 'brand') {
                return 2;
            }
            return 1;
        },
        nextStepButtonDisabled() {
            return this.currentStep === 1 || this.loadingHeaders || (!this.active_retailer && !this.inactive_retailer);
        },
        isActive() {
            if (this.active_retailer && this.inactive_retailer) {
                return 'all';
            } else if (this.active_retailer) {
                return true;
            } else if (this.inactive_retailer) {
                return false;
            } else {
                return null;
            }
        },
        subtitleInfo() {
            if (this.active_retailer && this.inactive_retailer) {
                return '(Active and Inactive)';
            } else if (this.active_retailer) {
                return '(Active)';
            } else if (this.inactive_retailer) {
                return '(Inactive)';
            } else {
                return '-';
            }
        },
        hasRoleAccess() {
            return (this.userStore.isAdmin || this.userStore.isBrand || this.userStore.isParentBrand) ?? false;
        },
    },
    mounted() {
        this.isLoading = true;

        if (this.userStore.isAdmin || this.userStore.isBrand || this.userStore.isParentBrand) {
            this.inactive_retailer = false;
        }

        this.setSidebarFilters();
        this.setBodyFilters();
        this.setInitialSelectedFilters();
        this.setPreviewData();
        this.getRetailersCount();
        this.getAactiveRetailersCount();
        this.getInactiveRetailersCount();
        if (this.hasRoleAccess) {
            this.validateSelectedBrands();
        }
        this.isLoading = false;
    },
    methods: {
        close() {
            this.$emit('close');
        },
        validateSelectedBrands() {
            const key = this.userStore.isAdmin ? 3 : 2;
            if (this.selectedBrands.length > 1) {
                this.bodyFilters[key].data.tiers.options.all_tiers.disabledCheck = true;
                this.bodyFilters[key].data.languages.options.languages.disabledCheck = true;
                this.bodyFilters[key].data.retailer_grouping.options.retailer_grouping.disabledCheck = true;
                this.sidebarFilters.business_info.options.brand_tiers.disabledCheck = true;
                const clearBodyValues = this.selectedFilters.tiers.filters.filter(object => {
                    return object.id != 'all_tiers';
                });
                const clearSidebarValues = this.selectedFilters.business_info.filters.filter(object => {
                    return object.id != 'brand_tiers';
                });
                this.selectedFilters.tiers.filters = clearBodyValues;
                this.selectedFilters.business_info.filters = clearSidebarValues;
                for ( const [key, value] of Object.entries(this.selectedFilters)) {
                    if (key === 'languages' || key === 'retailer_grouping') {
                        value.filters = [];
                    }
                }
            } else {
                this.bodyFilters[key].data.tiers.options.all_tiers.disabledCheck = false;
                this.bodyFilters[key].data.languages.options.languages.disabledCheck = false;
                this.bodyFilters[key].data.retailer_grouping.options.retailer_grouping.disabledCheck = false;
                this.sidebarFilters.business_info.options.brand_tiers.disabledCheck = false;
                if (!this.selectedFilters.business_info.filters.find(f => f.id === 'brand_tiers')) {
                    this.selectedFilters.business_info.filters.push({id: 'brand_tiers', title: 'Brand Tier(s)'});
                }
            }
        },
        setSidebarFilters() {
            if (this.userRole == 'brand') {
                this.sidebarFilters = {
                    business_info:       this.filters.business_info,
                    contact_information: this.filters.contact_information,
                };
            } else {
                this.sidebarFilters = {
                    business_info: this.filters.business_info,
                    users_info:    this.filters.users_info,
                };
            }

        },
        handleSelectedBrands(event) {
            if (event.value === 'all') {
                if (this.selectedBrands.length === this.oemManagerOptions.length) {
                    this.selectedBrands = [];
                } else {
                    this.selectedBrands = this.oemManagerOptions.map(option => option.id);
                }
            } else {
                this.selectedBrands = event.value;
            }
            this.validateSelectedBrands();
            this.getRetailersCount();
        },
        getRetailersCount(status = null) {
            if (status === 'active') {
                this.active_retailer = !this.active_retailer;
            }

            if (status === 'inactive') {
                this.inactive_retailer = !this.inactive_retailer;
            }

            if (!this.active_retailer && !this.inactive_retailer) {
                this.headerInfo = '0 Retailers';
                return;
            }

            this.loadingHeaders = true;
            if (this.selectedBrands.length || (this.oemManagerId !== '' && this.selectedBrands.length >= 1) ) {
                axios.get('/api/brands', {
                    params: {
                        oem_manager_ids: this.selectedBrands.length > 0 ? this.selectedBrands : this.oemManagerId.split(','),
                        is_active:       this.isActive,
                    },
                }).then((response) => {
                    let count = 0;

                    response.data.resellers.map(function(r) {
                        count += r.retailers_count;
                    });

                    this.all_tiers_column_count = response.data.all_tiers_column_count || 0;
                    this.top_tiers_column_count = response.data.top_tiers_column_count || 0;
                    this.headerInfo = count + (count === 1 ? ' Retailer' : ' Retailers');
                    this.loadingHeaders = false;
                });
            } else {
                this.headerInfo = '0 Retailers';
                this.loadingHeaders = false;
            }
        },
        getAactiveRetailersCount() {
            if (this.selectedBrands.length || this.oemManagerId !== '') {
                axios.get('/api/brands', {
                    params: {
                        oem_manager_ids: this.selectedBrands.length > 0 ? this.selectedBrands : this.oemManagerId.split(','),
                        status:          true,
                    },
                }).then((response) => {
                    let count = 0;

                    response.data.resellers.map(function(r) {
                        count += r.retailers_count;
                    });

                    this.all_tiers_column_count = response.data.all_tiers_column_count || 0;
                    this.top_tiers_column_count = response.data.top_tiers_column_count || 0;
                    this.activeRetailerCount = count + (count === 1 ? ' Retailer' : ' Retailers');
                    this.loadingHeaders = false;
                });
            } else {
                this.inactiveRetailerCount = '0 Retailers';
            }
        },
        getInactiveRetailersCount() {
            if (this.selectedBrands.length || this.oemManagerId !== '') {
                axios.get('/api/brands', {
                    params: {
                        oem_manager_ids: this.selectedBrands.length > 0 ? this.selectedBrands : this.oemManagerId.split(','),
                        status:          false,
                    },
                }).then((response) => {
                    let count = 0;

                    response.data.resellers.map(function(r) {
                        count += r.retailers_count;
                    });

                    this.all_tiers_column_count = response.data.all_tiers_column_count || 0;
                    this.top_tiers_column_count = response.data.top_tiers_column_count || 0;
                    this.inactiveRetailerCount = count + (count === 1 ? ' Retailer' : ' Retailers');
                    this.loadingHeaders = false;
                });
            } else {
                this.inactiveRetailerCount = '0 Retailers';
            }
        },
        setBodyFilters() {
            if (this.userRole == 'brand') {
                this.bodyFilters = [
                    {
                        type: 'column',
                        data: {
                            platforms: this.filters.platforms,
                        },
                    },
                    {
                        type: 'row',
                        data: {
                            scheduled_posts: this.filters.scheduled_posts,
                        },
                    },
                    {
                        type: 'row',
                        data: {
                            tiers:             this.filters.tiers,
                            languages:         this.filters.languages,
                            retailer_grouping: this.filters.retailer_grouping,
                        },
                    },
                    {
                        type: 'row',
                        data: {
                            pause_status: this.filters.pause_status,
                        },
                    },
                ];
            } else {
                this.bodyFilters = [
                    {
                        type: 'row',
                        data: {
                            program_status: this.filters.program_status,
                        },
                    },
                    {
                        type: 'column',
                        data: {
                            platforms: this.filters.platforms,
                        },
                    },
                    {
                        type: 'row',
                        data: {
                            scheduled_posts: this.filters.scheduled_posts,
                        },
                    },
                    {
                        type: 'row',
                        data: {
                            tiers:             this.filters.tiers,
                            languages:         this.filters.languages,
                            retailer_grouping: this.filters.retailer_grouping,
                        },
                    },
                    {
                        type: 'row',
                        data: {
                            pause_status: this.filters.pause_status,
                        },
                    },
                ];
            }
        },
        setInitialSelectedFilters() {
            for (const [key, value] of Object.entries(this.filters)) {
                const includeArray = ['languages', 'retailer_grouping', 'scheduled_posts'];
                if (includeArray.includes(key)) {
                    this.selectedFilters[key] = {
                        title: value.options[key].title, filters: [],
                    };
                } else if (key === 'platforms') {
                    this.selectedFilters.platforms = {filters: {}};
                    value.options.forEach(option => {
                        this.selectedFilters.platforms.filters[option.name.toLowerCase()] = {filters: [], title: option.name};
                    });
                } else {
                    this.selectedFilters[key] = {title: value.title, filters: []};
                }
                for (const [key1, value1] of Object.entries(this.filters[key].options)) {
                    if (value1.disabled) {
                        this.selectedFilters[key].filters.push({title: value1.title, id: key1});
                    }
                }
            }
        },
        updateSelectedFilters(filterInfo) {
            if (filterInfo.filterType === 'radio') {
                this.userRadio = filterInfo.data.filter.title;
            } else if (filterInfo.filterType === 'column') {
                if (!this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()]) {
                    this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()] = {title: filterInfo.data.type.title, filters: []};
                }
                const filterIsSelected = this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.findIndex(f => f.id === filterInfo.data.filter.id);
                if (filterIsSelected >= 0) {
                    this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.splice(filterIsSelected, 1);
                } else {
                    this.selectedFilters[filterInfo.data.type.id].filters[filterInfo.data.type.title.toLowerCase()].filters.push(filterInfo.data.filter);
                }
            } else {
                const filterIsSelected = this.selectedFilters[filterInfo.data.type.id].filters.findIndex(f => f.id === filterInfo.data.filter.id);
                if (filterIsSelected >= 0) {
                    this.selectedFilters[filterInfo.data.type.id].filters.splice(filterIsSelected, 1);
                } else {
                    const includeArray = ['languages', 'retailer_grouping', 'scheduled_posts'];
                    if (includeArray.includes(filterInfo.data.type.id)) {
                        this.selectedFilters[filterInfo.data.type.id].title = filterInfo.data.filter.title;
                        this.selectedFilters[filterInfo.data.type.id].filters.push({id: filterInfo.data.type.id, title: 'Included'});
                    } else {
                        this.selectedFilters[filterInfo.data.type.id].filters.push(filterInfo.data.filter);
                    }
                }
            }
            this.selectedFilters = Object.assign({}, this.selectedFilters);
            this.setPreviewData();
        },
        setPreviewData() {
            let data = [{}, {}, {}];
            for (const key of Object.keys(this.selectedFilters)) {
                if (key === 'platforms') {
                    for (const k of Object.keys(this.selectedFilters[key].filters)) {
                        data[1][k] = (this.selectedFilters[key].filters[k]);
                    }
                } else if (key === 'tiers' || key === 'languages' || key === 'retailer_grouping' || key === 'scheduled_posts') {
                    data[2][key] = (this.selectedFilters[key]);
                } else {
                    data[0][key] = (this.selectedFilters[key]);
                }
            }
            this.previewData = data;
        },
        handleNextStep() {
            let query = `/api/reports/show?report=retailer&oem_manager_id=${this.selectedBrands.join() || this.oemManagerId}&columns=`;
            let allUsersInfo = '';
            if (!this.showPreview) {
                let all_tiers = this.selectedFilters.tiers.filters.find(e => e.id === 'all_tiers');
                let brand_tiers = this.selectedFilters.business_info.filters.find(e => e.id === 'brand_tiers');
                if (all_tiers) {
                    all_tiers.title = 'All Content Classifiers (Status)';
                }
                if (brand_tiers) {
                    brand_tiers.title = 'Brand Tier(s)';
                }
                this.$emit('next-view');
                this.showPreview = true;
            } else {
                if (this.exporting === true) {
                    return;
                }
                this.exporting = true;
                for (const  [key, value, i] of Object.entries(this.selectedFilters)) {
                    if (key === 'platforms') {
                        for (const [key1, value1] of Object.entries(value.filters)) {
                            value1.filters.forEach(f => {
                                query += `${f.map},`;
                            });
                        }
                    } else {
                        if (key ==='users_info' && this.userRadio === 'All Users') {
                            query += 'all_users,';
                            if (value.filters.length) {
                                value.filters.forEach(f => {
                                    allUsersInfo += `${f.map ? f.map : f.id},`;
                                });
                            }
                        } else {
                            value.filters.forEach(f => {
                                query += `${f.map ? f.map : f.id},`;
                            });
                        }
                    }
                }
                query = query.slice(0, query.length-1);
                if (this.userRadio === 'All Users' && allUsersInfo) {
                    query += `&all_users_columns=${allUsersInfo.slice(0, query.length-1)}`;
                    query = query.slice(0, query.length - 1);
                }

                if (this.isActive !== null) {
                    query += `&is_active=${this.isActive}`;
                }


                this.queryString = query + `&sort_field=brand_name%2Cretailer_name&sort_direction=A&sidebar=${this.sidebarCall}`;
                axios({
                    url:          this.queryString,
                    method:       'GET',
                    responseType: 'blob',
                }).then((response) => {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'retailers_export.csv');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    this.close();
                    this.$emit('next-view');
                });
            }
        },
        handleSelectDeselectAll(event) {
            this.setInitialSelectedFilters();
            const avoid = ['all_users', 'brand_name', 'ts_retailer_id', 'program_name', 'brand_tiers', 'retailer_name'];
            if (event.value === 'select') {
                for (const [key, value] of Object.entries(this.selectedFilters)) {
                    if (key === 'platforms') {
                        this.filters[key].options.forEach((platform) => {
                            for (const [key1, value1] of Object.entries(platform.options)) {
                                for (const [key2, value2] of Object.entries(value1)) {
                                    this.selectedFilters[key].filters[platform.name.toLowerCase()].filters.push({ id: key2, title: value2.title, map: value2.map ? value2.map : '' });
                                }
                            }
                        });
                    } else {
                        for (const [key1, value1] of Object.entries(this.filters[key].options)) {
                            if (!avoid.find(key => key === key1)) {
                                if (this.userRole == 'brand') {
                                    const title = key1 === 'active_status' ? 'Included' : value1.title;
                                    const disabledKeys = ['all_tiers', 'languages', 'retailer_grouping'];
                                    if ((disabledKeys.includes(key) || disabledKeys.includes(key1)) && this.selectedBrands.length > 1) {
                                        return;
                                    } else {
                                        this.selectedFilters[key].filters.push({ id: key1, title, map: value1.map ? value1.map : '' });
                                    }
                                } else {
                                    const includeArray = ['languages', 'retailer_grouping', 'scheduled_posts'];
                                    const title = (includeArray.includes(key1)) ? 'Included' : value1.title;
                                    if ((key1 == 'languages' && this.bodyFilters[3].data.languages.options.languages.disabledCheck) ||
                                    (key1 == 'retailer_grouping' && this.bodyFilters[3].data.retailer_grouping.options.retailer_grouping.disabledCheck) ) {
                                        this.selectedFilters[key] = {filters: []};
                                    } else
                                    if (key1 == 'all_tiers' && this.bodyFilters[3].data.tiers.options.all_tiers.disabledCheck) {
                                        const clearValue = this.selectedFilters.tiers.filters.filter(object => {
                                            return object.id != 'all_tiers';
                                        });
                                        this.selectedFilters[key].filters = clearValue;
                                    } else {
                                        this.selectedFilters[key].filters.push({ id: key1, title, map: value1.map ? value1.map : '' });
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.selectedFilters = _.cloneDeep(this.selectedFilters);
            this.setPreviewData();
        },
        handlePreviusStep() {
            this.showPreview = false;
            this.exporting = false;
            this.$emit('previous-view');
        },
    },
};
</script>

<style lang="scss">
    .export-options-retailer-cc {
        .retailer-status-container{
            display: flex;
            flex-direction: column;
            margin-top: 5px;

            .label-modal {
                display: block !important;
            }
            .span-status {
                word-wrap:break-word;
                width: 50px;
                margin-bottom: 2px;
                white-space: normal;
                font-size: 12px;
                padding-top: 23px;
            }
        }
    }
</style>

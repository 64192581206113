<template>
    <div class="date-group">
        <div v-if="showLabel" class="line-break">
            <label>{{ label ? `${label}:` : '' }}</label>
        </div>
        <div v-if="singleInput" class="input-group" @click="handleOpenDropdown">
            <div v-show="useDividedInput" class="input-left-container">
                <img src="/images/calendar-blue.svg">
                {{ leftContainerTitle }}
                <div class="vertical-divider">|</div>
            </div>
            <input
                v-if="singleInputValue !== ''"
                :value="singleInputValue"
                type="text"
                class="form-control"
                placeholder="Select a Date"
            >
            <div v-if="showAdjustDate" class="placeholder-label">{{ dateInputLabel }}</div>
            <caret-down-purple-icon
                v-if="showCaretDown"
                class="purple-caret-down"
                :scale="0.6"
            />
            <div v-if="showCalendarIcon" class="input-group-btn">
                <button class="btn btn-default" @click.prevent="open = !open">
                    <i class="fa fa-calendar" />
                </button>
            </div>
        </div>
        <div v-if="clearInput" class="clear-search-button">
            <button type="button" class="btn btn-link" @click="clear">
                <i class="fa fa-close" />
            </button>
        </div>
        <div v-if="!singleInput" class="input-group">
            <input :value="selectedRange.start" type="text" class="form-control" :placeholder="placeholder" @click="open = true" @input="handleStartInput">
            <div class="input-group-btn">
                <button class="btn btn-default" @click.prevent="open = true">
                    <i class="fa fa-calendar" />
                </button>
            </div>
        </div>
        <div v-if="!singleInput" class="input-group">
            <input :value="selectedRange.end" type="text" class="form-control" :placeholder="placeholder" @click="open = true" @input="handleEndInput">
            <div class="input-group-btn">
                <button class="btn btn-default" @click.prevent="open = true">
                    <i class="fa fa-calendar" />
                </button>
            </div>
        </div>
        <div v-show="open" class="date-range-container" :class="direction">
            <div class="quick-date-buttons">
                <div v-if="quickDateLabel !== ''" class="quick-date-label">
                    {{ quickDateLabel }}
                </div>
                <template v-for="(date) in quickDates" :key="date.name">
                    <button
                        v-if="date.id !== 0"
                        class="btn btn-default btn-sm quick-date-button"
                        :class="{'quick-date-button-highlighted': highlightSelectedQuickDate && selectedQuickDateId === date.id}"
                        @click.prevent="handleQuickDate(date)"
                    >
                        {{ date.name }}
                    </button>
                </template>
            </div>
            <date-picker
                ref="datePicker"
                :class="{'disable-custom-date-range': disableCustomDateRange}"
                :attributes="attrs"
                :model-value="selectedRange"
                :model-modifiers="{range: true}"
                :min-date="minDate"
                :max-date="maxDate"
                :disabled-dates="blackouts"
                :model-config="{type: 'string', mask: 'YYYY-MM-DD'}"
                :columns="2"
                @update:model-value="handlePickerInput"
            />
            <div class="date-range-buttons">
                <button class="btn btn-default" @click.prevent="cancel">Cancel</button>
                <button class="btn btn-primary" @click.prevent="apply">Select</button>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);
dayjs.extend(quarterOfYear);

import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

import ClickOutside from '@/mixins/ClickOutside.js';
import CaretDownPurpleIcon from '@/components/icons/CaretDownPurpleIcon.vue';

export default {
    components: {
        CaretDownPurpleIcon,
        DatePicker,
    },
    emits: [
        'update:range',
        'update:selected-quick-date',
    ],
    mixins: [
        ClickOutside,
    ],
    props: {
        attrs: {
            type:     Array,
            default:  () => [],
            required: false,
        },
        minDate: {
            type:     [Date, Object, String],
            default:  null,
            required: false,
        },
        maxDate: {
            type:     [Date, Object, String],
            default:  null,
            required: false,
        },
        format: {
            type:     String,
            required: false,
            default:  'MM/DD/YYYY',
        },
        label: {
            type:     String,
            required: false,
            default:  '',
        },
        leftContainerTitle: {
            type:     String,
            required: false,
            default:  'Date',
        },
        quickFutureDates: {
            type:     Array,
            required: false,
            default:  () => [],
        },
        range: {
            type:     Object,
            required: true,
        },
        openDropdown: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        placeholder: {
            type:     String,
            required: false,
            default:  'Select a Date',
        },
        singleInput: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        showAdjustDate: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        showCalendarIcon: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        showCaretDown: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        useDividedInput: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        showLabel: {
            type:     Boolean,
            required: false,
            default:  true,
        },
        ignoreSelectedDateRangeWatch: {
            type:    Boolean,
            require: false,
            default: false,
        },
        clearInput: {
            type:    Boolean,
            require: false,
            default: false,
        },
        highlightSelectedQuickDate: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        selectedQuickDateId: {
            type:     Number,
            required: false,
            default:  2,
        },
        dateInputLabel: {
            type:     String,
            required: false,
            default:  'Adjust Date',
        },
        replaceDateWithLabel: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        disableCustomDateRange: {
            type:     Boolean,
            required: false,
            default:  false,
        },
        quickDateLabel: {
            type:     String,
            required: false,
            default:  '',
        },
        onlyReadStartDate: {
            type:    Boolean,
            default: false,
        },
        disabled: {
            type:    Boolean,
            default: false,
        },
        blackoutDates: {
            type:    Array,
            default: () => [],
        },
    },
    data() {
        return {
            datePattern: /\d{4}-\d{2}-\d{2}/,
            open:        false,
            quickDates:  [
                {
                    name:  'Today',
                    start: dayjs(),
                    end:   dayjs(),
                },
                {
                    name:  'Month-to-Date',
                    start: dayjs().startOf('month'),
                    end:   dayjs(),
                },
                {
                    name:  'Last 30 Days',
                    start: dayjs().subtract(30, 'days'),
                    end:   dayjs(),
                },
                {
                    name:  'Last Month',
                    start: dayjs().subtract(1, 'month').startOf('month'),
                    end:   dayjs().subtract(1, 'month').endOf('month'),
                },
                {
                    name:  'Last Quarter',
                    start: dayjs().subtract(1, 'quarter').startOf('quarter'),
                    end:   dayjs().subtract(1, 'quarter').endOf('quarter'),
                },
                {
                    name:  'Year-to-Date',
                    start: dayjs().startOf('year'),
                    end:   dayjs(),
                },
            ],
            selectedRange: {
                start: this.range.start,
                end:   this.range.end,
            },
            rangeChanged:              false,
            previousQuickDateSelected: 2,
            quickDateUsed:             false,
        };
    },
    computed: {
        singleInputValue: function() {
            if (this.replaceDateWithLabel) {
                const selected = this.quickFutureDates.find(qfd => qfd.id === this.selectedQuickDateId);
                return selected.name;
            }
            if (this.selectedRange.start.length !== 0 && this.selectedRange.end.length !== 0 && this.range.start.length !== 0 && this.range.end.length !== 0) {
                const start = dayjs(this.selectedRange.start).format(this.format);
                const end = this.selectedRange.end ? dayjs(this.selectedRange.end).format(this.format) : '';
                return `${start} - ${end}`;
            }
            return this.placeholder;
        },
        direction() {
            // Re-cache computed prop if this value changes
            this.open;

            if (!this.$el) {
                return 'below';
            }

            let boundingRect   = this.$el.getBoundingClientRect();
            let viewportHeight = window.innerHeight;

            let availableBelow = viewportHeight - boundingRect.top;
            let availableAbove = boundingRect.top;

            if (availableAbove > availableBelow) {
                return 'above';
            } else {
                return 'below';
            }
        },
        blackouts() {
            return this.blackoutDates.map(item => new Date(item.pause_at));
        },
    },
    watch: {
        range(value) {
            this.selectedRange = Object.assign({}, value);
            this.rangeChanged = false;
        },
        selectedRange(value) {
            if (value.start && value.end && !this.ignoreSelectedDateRangeWatch) {
                this.$refs.datePicker.value_ = {
                    start: dayjs(value.start).valueOf(),
                    end:   dayjs(value.end).valueOf(),
                };
            }
        },
        openDropdown() {
            this.open = !this.open;
        },
    },
    mounted() {
        if (this.quickFutureDates.length > 0) {
            this.quickDates = this.quickFutureDates.filter(i => i.name !== '');
            this.previousQuickDateSelected = this.selectedQuickDateId;
        }
    },
    methods: {
        apply() {
            this.open = false;
            this.previousQuickDateSelected = this.selectedQuickDateId;
            this.$emit('update:range', this.selectedRange);
        },
        cancel() {
            this.open = false;
            this.selectedRange = Object.assign({}, this.range);
            this.rangeChanged = false;
            this.$emit('update:selected-quick-date', {
                id:            this.previousQuickDateSelected,
                selectedRange: this.selectedRange,
            });
        },
        handleClickOutside() {
            if (this.rangeChanged) {
                this.apply();
                return;
            }

            this.cancel();
        },
        handleStartInput(value) {
            this.rangeChanged = true;
            if (value.match(this.datePattern)) {
                this.selectedRange = {
                    start: value,
                    end:   this.selectedRange.end,
                };
            }
        },
        handleEndInput(value) {
            this.rangeChanged = true;
            if (this.range.start.match(this.datePattern)) {
                this.selectedRange = {
                    start: this.selectedRange.end,
                    end:   value,
                };
            }
        },
        handlePickerInput(value) {
            if (this.onlyReadStartDate) {
                this.$refs.datePicker.value_.start = new Date();
                value.start = this.range.start;
            }
            if (!this.quickDateUsed && this.open) {
                this.$emit('update:selected-quick-date', {
                    id:            0,
                    selectedRange: {
                        start: value !== null ? value.start : dayjs(),
                        end:   value !== null ? value.end : dayjs(),
                    },
                });
                this.selectedRange = {
                    start: value.start,
                    end:   value.end,
                };
            }

            this.quickDateUsed = false;
        },
        handleQuickDate(date) {
            this.quickDateUsed = true;
            this.$emit('update:selected-quick-date', {
                id:            date.id,
                selectedRange: {
                    start: date.start,
                    end:   date.end,
                },
            });
            this.selectedRange = {
                start: date.start.startOf('day').format('YYYY-MM-DD'),
                end:   date.end.endOf('day').format('YYYY-MM-DD'),
            };
            this.rangeChanged = !((this.range.start === this.selectedRange.start && this.range.end === this.selectedRange.end) ?? false);
        },
        clear() {
            let clearObject = {
                start: '',
                end:   '',
            };
            this.$refs.datePicker.value_ = clearObject;
            this.selectedRange = clearObject;
            this.$emit('update:range', clearObject);
        },
        handleOpenDropdown() {
            if (this.disabled) {
                return;
            }
            this.open = !this.open;
            this.previousQuickDateSelected = this.selectedQuickDateId;
        },
    },
};
</script>


<style lang="scss">
.date-group {
    .input-group {
        .input-left-container {
            align-items: center;
            cursor: pointer;
            display: flex;
            font-size: 12px;
            font-weight: bold;
            height: 100%;
            justify-content: center;
            vertical-align: middle;

            img {
                height: 20px;
                margin: 10px;
            }
        }
        .vertical-divider {
            color: #ebebed;
            font-size: 34px;
            font-weight: 300;
            margin-left: 10px;
        }
        .clear-search-button {
            z-index: 10;
        }
    }
    .clear-search-button {
        position:relative;
        z-index: 2;
        left: 323px;
        top: -39px;
        button {
            text-decoration: none;
            padding: 0px;
            .fa {
                color: #84878d;
                padding: 4px;
            }
            .fa:hover {
                background: #f1f4f7;
                color: #84878d;
            }
        }
    }
    .placeholder-label {
        color: #ADB0B3;
        cursor: pointer;
        font-weight: normal;
        margin: auto 0px auto 25px;
        padding: 0px 20px;
        text-align: end;
    }
    .purple-caret-down {
        align-self: center;
    }
    .disable-custom-date-range.vc-container {
        pointer-events: none;
    }
    .date-range-container {
        left: 5px;
        background: #fff;
        border: 1px solid #cbd5e0;
        position: absolute;
        top: 60px;
        .vc-container {
            border: none;
            margin: auto;
            padding: 0px 30px;

            .vc-pane {
                font-family: "Helvetica Neue";
                &:not(:first-of-type) {
                    padding-left: 20px;
                }
                &:not(:last-of-type) {
                    padding-right: 20px;
                }

                .vc-title {
                    font-family: "Helvetica Neue";
                    font-size: 16px;
                    font-weight: bold;
                    padding-bottom: 8px;
                }

                .vc-day.is-not-in-month {
                    * {
                        opacity: 0.25;
                    }
                }
                .vc-highlight-base-middle, .vc-highlight-base-start, .vc-highlight-base-end {
                    background: #EFF3F6 !important;
                    height: 32px !important;
                    padding-left: 36px;
                }
                .vc-highlight-base-middle {
                    border-radius: 50%;
                }
                .vc-highlight-base-start {
                    border-radius: 50% 0 0 50% !important;
                }
                .vc-highlight-base-end {
                    border-radius: 0 50% 50% 0 !important;
                }
                .vc-highlight {
                    width: 28px;
                    height: 28px;
                }
                .vc-highlight:not(.vc-highlight-base-middle):not(.vc-highlight-base-start):not(.vc-highlight-base-end) {
                    background: #0066FF;
                }
                .vc-weeks {
                    gap: 4px 0px;
                    .vc-day {
                        padding: 0px 4px;
                        height: 32px;
                        .vc-day-content {
                            font-weight: 500 !important;
                            font-size: 14px;
                        }
                    }
                    .is-first-day, .weekday-1 {
                        .vc-day-layer {
                            border-radius: 50% 0 0 50%;
                        }
                    }
                    .weekday-7 {
                        .vc-day-layer {
                            border-radius: 0 50% 50% 0;
                        }
                    }
                    .is-last-day {
                        .vc-day-layer {
                            border-top-right-radius: 50% !important;
                            border-bottom-right-radius: 50% !important;
                        }
                    }
                    .vc-weekday {
                        border-bottom: 2px solid #999;
                        font-size: 14px;
                        color: #000;
                        font-weight: 400 !important;
                        padding-bottom: 10px;
                    }
                }
            }
            .vc-arrows-container {
                top: 7px;
            }
        }

        .quick-date-buttons {
            left: 15px;
            top: 25px;
            font-size: 18px;
            display: flex;
            justify-content: center;
            left: unset;
            padding: 20px 10px;
            width: 100%;
            .btn-sm {
                margin-left: 5px;
            }
            .quick-date-button-highlighted {
                background: #0066FF;
                border: none;
                color: #fff;
                border: 1px solid #0066ff;
            }
            .quick-date-button {
                border-radius: 8px;
                border: 3px solid #D6D9DB;
                font-size: 14px;
                font-weight: bold;
                margin: 0px 4px;
                padding: 2px 7px;
            }
        }

        .date-range-buttons {
            bottom: 25px;
            display: flex;
            gap: 20px;
            justify-content: center;
            padding: 25px;

            .btn-primary {
                background-color: #0066ff;
            }
            .btn-default {
                font-weight: bold;
            }
            .btn {
                font-size: 13px;
                padding: 6px 18px;
            }
        }
        .disabled-input {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }
}
</style>

<template>
    <div class="retailer-product-tiers-container">
        <div v-if="loading">
            <ts-loading-spinner />
        </div>
        <div v-else class="retailer-product-tier-content">
            <h3>Product Tiers</h3>
            <span>Manage and view your product tiers.</span>
            <div class="horizontal-divider" />
            <retailer-product-tiers-content
                ref="story_content"
                tab="oem_direct"
                :pool="pool"
                :dealership-id="dealershipId"
                :region-id="regionId"
                :user-regions="userRegions"
                :languages="languages"
                :selected-languages="selectedLanguages"
                :has-organic-content-facebook="hasOrganicContentFacebook"
                :exclusions="exclusions"
                :selected-classifiers="selectedClassifiers"
                :dealership="dealership"
                @input-updated="inputUpdated = true"
            />
            <div class="horizontal-divider" />
            <div v-if="userStore.isAdmin" class="retailer-access-rules-wrapper">
                <div class="title-wrapper">
                    <strong>Retailer Access <span class="retailer-access-red-text">(Admin Only)</span></strong>
                </div>
                <div class="retailer-access-toggles-wrapper">
                    <posts-toggle
                        title="Content Classifiers Access"
                        explanation-text="Gives retailers the ability to view their Content Classifier settings."
                        :switch-enabled="true"
                        :value="dealership.has_product_tiers_access"
                        @input="handleAccessRulesToggle({type: 'has_product_tiers_access', value: $event})"
                    />
                </div>
            </div>
            <button
                class="save-settings-button"
                :class="!hasAccess || !inputUpdated || saving || roleStore.viewMode? 'disabled-button' : ''"
                :disabled="!hasAccess || !inputUpdated || saving || roleStore.viewMode"
                @click="save"
            >
                <i v-if="saving" class="fa fa-spin fa-spinner" />
                Save Changes
            </button>
        </div>
    </div>
</template>

<script>
import axios from '@/axios';
import TsLoadingSpinner from '@/components/app/TsLoadingSpinner.vue';
import PostsToggle from "@/components/retailer_experience/PostsToggle.vue";
import RetailerProductTiersContent from '@/components/retailer_experience/settings/RetailerProductTiersContent.vue';
import { roleStore, userStore } from '@/stores/pinia';
import { mapStores } from 'pinia';
import { mapGetters, mapMutations } from 'vuex';

export default {
    components: {
        PostsToggle,
        TsLoadingSpinner,
        RetailerProductTiersContent,
    },
    emits: ['checkLinkedPlatforms'],
    props: {
        retailerId: {
            type:     Number,
            required: false,
            default:  null,
        },
        retailerToken: {
            type:     String,
            required: false,
            default:  null,
        },
        dealership: {
            type:     Object,
            required: false,
            default:  null,
        },
        from: {
            type:     String,
            required: false,
            default:  '',
        },
    },
    data() {
        return {
            loading:                   true,
            hasChanges:                false,
            saving:                    false,
            schedules:                 [],
            dealershipId:              null,
            hasOrganicContentFacebook: null,
            platforms:                 null,
            languages:                 null,
            selectedLanguages:         null,
            userRegions:               null,
            regionId:                  null,
            pool:                      null,
            exclusions:                null,
            selectedClassifiers:       null,
            inputUpdated:              false,
            accessRulesChanged:        false,
        };
    },
    computed: {
        ...mapStores(userStore,roleStore),
        ...mapGetters('NavigationStore', [
            'getCurrent',
        ]),
        currentPage() {
            if (this.getCurrent && this.getCurrent.action && this.getCurrent.action.name) {
                return this.getCurrent.action.name;
            }
            if (this.pageTitle) {
                return this.pageTitle;
            }
            return '';
        },
        hasAccess() {
            // this is being used in spice stuff
            if (this.from === 'external') {
                return true;
            }

            return this.userStore.hasWriteAccess({ permission: 'retailer-settings-content-tiers' });
        },
    },
    mounted() {
        let p1 = this.getInitialData();
        Promise.all([p1]).finally(() => this.loading = false);
    },
    methods: {
        ...mapMutations('RetailerSettingsStore', ['setAccessRules']),
        getInitialData() {
            let querystring = '';
            if (this.retailerId) {
                querystring = '&dealership_id=' + this.retailerId;
            }
            if (this.retailerToken) {
                querystring += '&token=' + this.retailerToken;
            }
            return axios.get(`/settings/social_media/data/${this.from}?d=${querystring}`).then((response) => {
                for (let key in response.data) {
                    if (!Object.keys(this.$props).includes(key)) {
                        this[key] = response.data[key];
                    }
                }
                this.$emit('checkLinkedPlatforms', false);
            });
        },
        save() {
            if (this.saving) {
                return;
            }

            this.saving = true;

            let validateComponents = [
                this.$refs.story_content,
            ];

            for (let i = 0; i < validateComponents.length; i++) {
                let message = validateComponents[i].hasErrors();
                if (message) {
                    window.flash.show({
                        type: 'error',
                        text: message === true ? 'An error occurred while saving' : message,
                    });
                    this.saving = false;
                    return;
                }
            }

            let story_content = this.$refs.story_content.save();
            let schedules     = [];
            let strategies    = {};

            let postData = {
                classifiers:  story_content.classifiers,
                languages:    story_content.selectedLanguages,
                pos_keywords: story_content.positiveKeywords,
                neg_keywords: story_content.negativeKeywords,
                region_id:    story_content.regionId,
                schedules:    schedules,
                strategies:   strategies,
            };

            if (this.retailerId) {
                postData.dealership_id = this.retailerId;
            }
            if (this.retailerToken) {
                postData.token = this.retailerToken;
            }

            if (!postData.languages.length) {
                this.saving = false;
                window.flash.show({
                    type: 'error',
                    text: 'You must have at least one language selected',
                });
                return;
            }

            axios.post(`/settings/social_media/${this.from}`, postData)
                .then(() => {
                    this.saving = false;
                    window.flash.show({
                        type: 'success',
                        text: 'Changes saved successfully',
                    });
                })
                .catch(() => {
                    this.saving = false;
                    window.flash.show({
                        type: 'error',
                        text: 'An error occurred while saving',
                    });
                });
            if (this.accessRulesChanged) {
                this.getAccessRulesRequest();
            }
            this.inputUpdated = false;
        },
        updateSaveButton() {
            this.hasChanges = this.$refs.story_content.hasChanges();
        },
        handleAccessRulesToggle(payload) {
            this.setAccessRules(payload);
            this.accessRulesChanged = true;
            this.inputUpdated = true;
        },
        getAccessRulesRequest() {
            const url = `/api/dealerships/${this.dealershipId}`;
            let data = {
                dealership:               this.dealership,
                dealership_change_action: null,
            };

            return axios.post(url, data);
        },
    },
};
</script>
<style lang="scss">
.retailer-product-tiers-container {
    .retailer-product-tier-content {
        display: flex;
        flex-direction: column;
        margin: 32px;
        margin-top: 8px;
        padding: 24px;
        h3 {
            font-family: Arial;
            font-weight: 700;
            margin: 0;
            padding-bottom: 4px;
        }
        .horizontal-divider {
            border-bottom: 2px solid #F0F3F6;
            margin: 2em 0;
        }
        .container-fluid {
            margin-top: 20px;
        }
        .filter-bar {
            display: flex;
            align-items: center;
        }
        .filter-bar .brand-label {
            transform: none !important;
        }
        .filter-dropdown .selected-options .selected-option {
            display: inline-block;
            background-color: #0166FF;
            color: #eee;
            font-weight: bold;
            margin: 0.25em;
        }
    }
    .save-settings-button {
        align-self: flex-end;
        background: #0066FF;
        border-radius: 6px;
        border: none;
        color: white;
        font-family: Arial;
        font-size: 13px;
        padding: 1em 1.2em;
    }
    .disabled-button {
        opacity: 40%;
        cursor: not-allowed;
    }
    .retailer-access-rules-wrapper {
        display: flex;
        gap: 30px;
        margin: 20px 0px;
        flex-direction: column;
        .retailer-access-toggles-wrapper {
            display: flex;
            gap: 20px;
        }
        .title-wrapper {
            width: 255px;

            .retailer-access-red-text {
                color: #D10000;
            }
        }
    }
}
</style>


<template>
    <popup-modal
        class="new-platform-modal"
        display-no-text="Close"
        display-yes-text="Confirm"
        :button-yes-width="buttonYesWidth"
        :padding-top="paddingTop"
        @close="$emit('close')"
        @on-no-button-click="() => sendEmail('Nevermind')"
        @on-yes-button-click="() => sendEmail('Yes')"
    >
        <template #icon>
            <img src="/images/popup-icons/information_icon.svg">
        </template>
        <template #header>
            <h1>New {{ isService ? 'Service' : 'Destination' }}</h1>
        </template>
        <template #content>
            <div v-if="isService" class="text-center">
                <span>
                    Would you like us to contact you about enabling <b>{{ selectedService }}</b> for your retailers?
                </span>
                <br><br>
                <span>
                    Is this the best email?
                </span>
                <br><br>
                <center>
                    <input v-model="userEmail" type="text" class="form-control">
                </center>
            </div>
            <div v-else>
                <p>
                    If you would like us to contact you about enabling<br>
                    <b>{{ selectedService }}</b> as a service{{ shouldDisplayBrandManagerInfo ? ' for your retailers' : '' }},
                    please confirm your email address.
                </p>
                <center>
                    <input v-model="userEmail" type="text" class="form-control" style="width: 80%">
                </center>
                <p class="more-information">Get more information?</p>
            </div>
        </template>
        <template #footer />
    </popup-modal>
</template>

<script>
import axios from '@/axios';
import PopupModal from '@/components/app/PopupModal.vue';
import { userStore } from '@/stores/pinia.js';
import { mapStores } from 'pinia';

export default {
    components: {
        PopupModal,
    },
    props: {
        selectedService: {
            type:     String,
            required: true,
        },
        isService: {
            default: false,
        },
        shouldDisplayBrandManagerInfo: {
            required: false,
            default:  false,
        },
        buttonYesWidth: {
            required: false,
            default:  70,
        },
        paddingTop: {
            type:     String,
            required: false,
            default:  '0px',
        },
    },
    data() {
        return {
            userEmail:      '',
            isSendingEmail: false,
        };
    },
    computed: {
        ...mapStores(userStore),
    },
    mounted() {
        this.userEmail = this.userStore.email;
    },
    methods: {
        sendEmail(flagText) {
            this.isSendingEmail = true;
            const data = {
                from:    'Thumbstopper <notifications@thumbstopper.com>',
                to:      'support@thumbstopper.com',
                subject:
                    this.userStore.selectedDealership.reseller_name +
                    ' user ' +
                    this.userStore.name +
                    ' inquired about ' +
                    this.selectedService +
                    ' and clicked ' +
                    flagText +
                    ' for more info',
                body:
                    'The following user clicked on a disabled Brand level service <br><br>' +
                    'Brand: ' +
                    this.userStore.selectedDealership.reseller_name +
                    ' <br>' +
                    'User: ' +
                    this.userStore.name +
                    ' <br>' +
                    'Email: ' +
                    this.userEmail +
                    ' <br>' +
                    'Service: ' +
                    this.selectedService +
                    ' <br>' +
                    'Inquiry: ' +
                    flagText +
                    ' <br>',
            };
            axios.post('/api/email', data).then(() => {
                this.isSendingEmail = false;
                this.$emit('close');
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.more-information {
    margin-top: 2rem;
}
</style>

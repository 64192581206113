export default {
    name: 'Google',
    char() {
        return {
            limit:                1500,
            link:                 true,
            emoji:                true,
            hashtag:              false,
            mention:              false,
            brandRequiresDynamic: false,
        };
    },
    media(vm = null) {
        return {
            photo: {
                resolution: {
                    min: {
                        width:  400,
                        height: 400,
                    },
                },
                formats: [
                    'jpeg',
                    'png',
                    'jpg',
                ],
                limit:    1,
                messages: {
                    limit: 'Google only allows for one image per post.',
                },
                min: vm && vm.postType==='TEXT' ? 0 : 1,
            },
            video: {
                limit:    0,
                messages: {
                    limit: 'Video content is not supported, please include an image.',
                },
            },
            mixed: {
                enabled: false,
            },
        };
    },
    misc: {
        cta: {
            type:    'checkbox',
            label:   'Include Call Now button',
            save:    (val) => val ? 'CALL' : null,
            load:    (val) => val == 'CALL',
            default: () => 'CALL',
        },
        /*
        // This is the original Call To Action with the dropdown
        cta: {
            type:    'select',
            options: {
                BOOK:       'Book',
                ORDER:      'Order',
                SHOP:       'Shop',
                LEARN_MORE: 'Learn More',
                SIGN_UP:    'Sign Up',
                CALL:       'Call Now',
            },
            label: 'Add a button (optional)',
        },
        url: {
            if:          (vm) => (!!vm.misc.cta),
            type:        'text',
            placeholder: 'URL',
            icon:        'fa-link',
        },
        */
    },
};

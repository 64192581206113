<template>
    <generic-icon
        :icon-style="iconStyle"
        :direction="direction"
        :title="title"
        class="caret-up-icon-container"
    >
        <template #content>
            <svg :width="width" :height="height" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.84337 4.6756C4.65066 4.85767 4.34934 4.85767 4.15663 4.6756L0.6508 1.36345C0.322 1.05282 0.54184 0.500001 0.994173 0.500001L8.00583 0.5C8.45816 0.5 8.678 1.05281 8.3492 1.36345L4.84337 4.6756Z" fill="#85878F" />
            </svg>
        </template>
    </generic-icon>
</template>
<script>
import IconMixin from '@/components/icons/IconMixin.js';
import GenericIcon from '@/components/app/GenericIcon.vue';
export default {
    name:       'CaretUpIcon',
    components: { GenericIcon },
    mixins:     [ IconMixin ],
};
</script>
<style lang='scss'>
.caret-up-icon-container{}
</style>
